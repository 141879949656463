package com.ecosave.watch.portal.components.esg.generaldisclosures.governance

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val Disclosure_2_13 = FC<EsgSubFormComponentProps> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.governance?.let { state ->

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_2_13
        }

        Box {
            label {
                +"Describe how the highest governance body delegates responsibility for managing the organization’s impacts on the economy, environment, and people."
            }
            ESGTextAreaFieldWrapper {
                name = state::section213A1.name
                value = state.section213A1 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }


        Box {
            className = ESGCommonStyles.LEFT_INDENT.cssClass
            label {
                +"Whether it has appointed any senior executives with responsibility for the management of impacts."
            }
            ESGTextAreaFieldWrapper {
                name = state::section213A2.name
                value = state.section213A2 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            className = ESGCommonStyles.LEFT_INDENT.cssClass
            label {
                +"Whether it has delegated responsibility for the management of impacts to other employees."
            }
            ESGTextAreaFieldWrapper {
                name = state::section213A3.name
                value = state.section213A3 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"Describe the process and frequency for senior executives or other employees to report back to the highest governance body on the management of the organization’s impacts on the economy, environment, and people."
            }
            ESGTextAreaFieldWrapper {
                name = state::section213A4.name
                value = state.section213A4 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}