package com.ecosave.watch.portal.components.esg.materials

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val GRI_301_1 = FC<EsgSubFormComponentProps> { props ->
    val onChangeDecimalNumber = props.onChangeDecimalNumber
    val onBlurDecimalNumber = props.onBlurDecimalNumber
    val onChangeText = props.onChangeText
    val onBlurText = props.onBlurText

    props.esgReportState.report?.materials?.let { state ->
        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_301_1
        }

        Box {
            className = ESGCommonStyles.FOUR_COLUMN_TABLE_6_2_2_2.cssClass

            TableHeader {
                header = ""
            }
            TableHeader {
                header = "Unit (weight or volume)"
            }
            TableHeader {
                header = "% Internally Sourced"
            }
            TableHeader {
                header = "% Externally Sourced"
            }

            label {
                +"Raw materials used"
            }
            ESGTextFieldWrapper {
                name = state::section3011A1.name
                value = state.section3011A1
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section3011B1.name
                value = state.section3011B1
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section3011C1.name
                value = state.section3011C1
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Total non-renewable materials used"
            }
            ESGTextFieldWrapper {
                name = state::section3011A2.name
                value = state.section3011A2
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section3011B2.name
                value = state.section3011B2
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section3011C2.name
                value = state.section3011C2
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Total renewable materials used"
            }
            ESGTextFieldWrapper {
                name = state::section3011A3.name
                value = state.section3011A3
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section3011B3.name
                value = state.section3011B3
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section3011C3.name
                value = state.section3011C3
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }
        }
    }
}