package com.ecosave.watch.portal.models.billing

data class UtilityAccountState(
    var facilityId: Int? = null,
    var utilityAccountId: Int? = null,
    var utilityType: String = "",
    var utilityServiceProviderId: String = "",
    var serviceName: String = "",
    var serviceAddress: String = "",
    var accountNumber: String = "",
    var meterNumber: String = "",
    var isActive: Boolean? = null,
    var surchargeBillExists: Boolean? = null,
    var coolingSteamUsed: Boolean? = null,
    var heatingSteamUsed: Boolean? = null,
    var solarTotalInstalledCapacity: Float? = null,
    var solarCapacityUnit: String = "",
    var solarTotalAnnualGeneration: Float? = null,
    var solarUsageUnit: String = "",
    var solarPPAInPlace: Boolean? = null,
    var solarPPAServiceProvider: String = "",
    val solarServiceStartDate: String = "",
    val solarTotalLengthOfPPA: Int? = null,
    val solarCurrentPPARate: Float? = null,
    val solarNetMetered: Boolean? = null,
    var solarSystemInUse: Boolean? = null,
    var utilityServiceProviderNameOther: String = "",
    var utilityServiceProviderWebsiteUrl: String = "",
    var collectionMethod: String = "",
    var collectionStatus: String = "",
    var energyStarMeterId: String? = null,
    var userId: String? = null,
    var password: String? = null,
    var historyRequired: Boolean? = null
)