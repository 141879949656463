package com.ecosave.watch.portal.components.esg.customerhealthnsafety

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.*
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange


val GRI_416_1 = FC<EsgSubFormComponentProps>("GRI 416-1") { props ->
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.customerHealthAndSafety?.let { state ->

        DisclosureSubHeading {
            subHeading = "416-1 ASSESSMENT OF THE HEALTH AND SAFETY IMPACTS OF PRODUCT AND SERVICE CATEGORIES"
        }

        Box {
            label {
                   + "Percentage of [significant product and service category] for which health and safety impacts are assessed for improvement?"
            }
            ESGTextAreaFieldWrapper {
                name = state::section4161A1.name
                value = state.section4161A1
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}