package com.ecosave.watch.portal.components.energymanagement

import com.ecosave.watch.portal.components.common.ApiFailedMessage
import com.ecosave.watch.portal.components.common.ApiLazyRender
import com.ecosave.watch.portal.components.common.EmptyDataMessage
import com.ecosave.watch.portal.components.npm.Cell
import com.ecosave.watch.portal.components.npm.Legend
import com.ecosave.watch.portal.components.npm.Pie
import com.ecosave.watch.portal.components.npm.PieChart
import com.ecosave.watch.portal.components.npm.ResponsiveContainer
import com.ecosave.watch.portal.components.npm.Tooltip
import com.ecosave.watch.portal.helpers.Colors
import com.ecosave.watch.portal.helpers.billing.UtilityType
import com.ecosave.watch.portal.helpers.energymanagement.RadioLabels
import com.ecosave.watch.portal.models.energymanagement.TotalEnergyUsageNCO2
import com.ecosave.watch.portal.models.energymanagement.TotalUtilityData
import com.ecosave.watch.portal.styles.EnergyManagementStyles
import js.core.jso
import js.core.push
import mui.material.Box
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import web.cssom.TextAlign
import com.ecosave.watch.portal.styles.EnergyManagementStyles as styles

external interface UtilityTypePieChartProps : Props {
    var dataFetched: Boolean
    var data: List<TotalEnergyUsageNCO2>
    var radioLabel: RadioLabels
    var dataError: Boolean
}

val UtilityTypePieChart = FC<UtilityTypePieChartProps> { props ->
    var isUsageEmpty = true
    var isCostEmpty = true
    var isCo2Empty = true
    var convertedData = arrayOf<TotalUtilityData>()

    for (item in props.data) {

        if (item.utilityUsageInMMBtu > 0) {
            isUsageEmpty = false
        }
        if (item.utilityCo2Emission > 0) {
            isCo2Empty = false
        }
        if (item.utilityCost > 0) {
            isCostEmpty = false
        }
        convertedData.push(
            jso {
                utilityType = item.utilityType.type
                utilityUsage = item.utilityUsage
                utilityUsageInMMBtu = item.utilityUsageInMMBtu
                utilityCo2Emission = item.utilityCo2Emission
                utilityCost = item.utilityCost
            }
        )
    }
    Typography {
        variant = TypographyVariant.h6
        sx {
            textAlign = TextAlign.center
        }
        +props.radioLabel.percent
    }
    Box {
        className = styles.SUMMARY_PIE_CHART.cssClass
        ApiLazyRender {
            dataFetched = props.dataFetched
            ResponsiveContainer {
                width = "100%"
                height = "100%"
                PieChart {
                    width = "100%"
                    height = "100%"
                    Pie {
                        data = JSON.parse(JSON.stringify(convertedData))
                        cx = "50%"
                        cy = "50%"
                        nameKey = "utilityType"
                        labelLine = false
                        outerRadius = "80%"
                        innerRadius = "50%"
                        paddingAngle = 5
                        dataKey = props.radioLabel.totalTableKey
                        props.data.forEachIndexed { index, pieData ->
                            Cell {
                                key = "cell-$index"
                                fill = when (pieData.utilityType) {
                                    UtilityType.ELECTRIC -> Colors.LIGHT_CYAN_BLUE
                                    UtilityType.NATURAL_GAS -> Colors.BRIGHT_GREEN
                                    UtilityType.STEAM -> Colors.GOLDENROD
                                    else -> ""
                                }
                                stroke = "0"
                            }
                        }
                    }
                    Tooltip {
                        +props
                        content = UtilityTypePieChartTooltip
                        labelStyle = jso {
                            color = Colors.BLACK
                        }
                    }
                    Legend {
                        iconSize = 20
                        iconType = "wye"
                    }
                }
            }
        }
        if (props.dataFetched) {
            Box {
                className = EnergyManagementStyles.SUMMARY_TITLE_CENTER.cssClass
                if (props.dataError) {
                    ApiFailedMessage {

                    }
                } else {
                    if (props.radioLabel.totalTableKey == "utilityUsageInMMBtu" && isUsageEmpty) {
                        EmptyDataMessage {

                        }
                    } else if (props.radioLabel.totalTableKey == "utilityCo2Emission" && isCo2Empty) {
                        EmptyDataMessage {

                        }
                    } else if (props.radioLabel.totalTableKey == "utilityCost" && isCostEmpty) {
                        EmptyDataMessage {

                        }
                    }
                }
            }
        }
    }
}