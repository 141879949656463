package com.ecosave.watch.portal.services.assetmanagement

import com.ecosave.watch.portal.helpers.BackendServerURL
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.getAccessTokenFromLocalStorage
import com.ecosave.watch.portal.models.assetmanagement.Asset
import com.ecosave.watch.portal.models.assetmanagement.AssetApiResponse
import com.ecosave.watch.portal.models.assetmanagement.FacilityAndAssets
import com.ecosave.watch.portal.services.httpClient
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.request.forms.*
import io.ktor.client.statement.*
import io.ktor.http.*
import js.core.jso
import js.promise.await
import kotlin.js.json
import kotlinx.browser.window
import kotlinx.coroutines.await
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.decodeFromDynamic
import org.w3c.fetch.RequestInit
import web.buffer.Blob
import web.buffer.BlobPropertyBag
import web.file.File
import web.http.FormData

suspend fun getFacilitiesAndAssets(): List<FacilityAndAssets> {
    try {
        val response: HttpResponse = httpClient.get("$BackendServerURL/asset-management/get-all")
        return if (response.status == HttpStatusCode.OK) {
            response.body()
        } else {
            return emptyList()
        }
    } catch (e: dynamic) {
        console.log("Error: ${e.message}")
        return emptyList()
    }
}

suspend fun addAsset(asset: Asset): AssetApiResponse {
    try {

        val response = httpClient.submitFormWithBinaryData(
            url = "$BackendServerURL/asset-management/",
            formData = formData {
                append("asset", Json.encodeToString(asset))
            })

        if (response.status == HttpStatusCode.Created) {
            return AssetApiResponse(
                httpStatusCode = HttpStatusCode.Created,
                asset = response.body()
            )
        }

    } catch (e: dynamic) {
        console.log("Error: ${e.message}")
    }
    return AssetApiResponse(
        httpStatusCode = null,
        asset = null
    )
}

@OptIn(ExperimentalSerializationApi::class)
suspend fun addAssetWithDocument(asset: Asset, file: File): AssetApiResponse {

    try {
        val accessToken = getAccessTokenFromLocalStorage()

        val formData = FormData()
        formData.append("asset", Json.encodeToString(asset))

        val buffer = file.arrayBuffer().await()
        val options: BlobPropertyBag = jso {
            type = file.type
        }
        val blob = Blob(arrayOf(buffer), options)
        formData.append("uploadDocument", blob, file.name);

        val response = window.fetch(
            "$BackendServerURL/asset-management/",
            RequestInit(
                "POST",
                headers = json(
                    "authorization" to "Bearer $accessToken",
                ),
                body = formData
            )
        ).await()

        if (response.status == 201.toShort()) {
            val asset: Asset = Json.decodeFromDynamic(response.json().await())
            return AssetApiResponse(
                httpStatusCode = HttpStatusCode.Created,
                asset = asset
            )

        }

    } catch (e: dynamic) {
        console.log("Error: $e")
    }
    return AssetApiResponse(
        httpStatusCode = null,
        asset = null
    )
}

suspend fun updateAsset(asset: Asset): AssetApiResponse {
    try {

        val response = httpClient.put("$BackendServerURL/asset-management/") {
            setBody(
                MultiPartFormDataContent(
                    formData {
                        append("asset", Json.encodeToString(asset))
                    }
                )
            )
        }
        if (response.status == HttpStatusCode.OK) {
            return AssetApiResponse(
                httpStatusCode = HttpStatusCode.OK,
                asset = response.body()
            )
        }

    } catch (e: dynamic) {
        console.log("Error: ${e.message}")
    }
    return AssetApiResponse(
        httpStatusCode = null,
        asset = null
    )
}

@OptIn(ExperimentalSerializationApi::class)
suspend fun updateAssetWithDocument(asset: Asset, file: File): AssetApiResponse {

    try {
        val accessToken = getAccessTokenFromLocalStorage()

        val formData = FormData()
        formData.append("asset", Json.encodeToString(asset))

        val buffer = file.arrayBuffer().await()
        val options: BlobPropertyBag = jso {
            type = file.type
        }
        val blob = Blob(arrayOf(buffer), options)
        formData.append("uploadDocument", blob, file.name);

        val response = window.fetch(
            "$BackendServerURL/asset-management/",
            RequestInit(
                "PUT",
                headers = json(
                    "authorization" to "Bearer $accessToken",
                ),
                body = formData
            )
        ).await()

        if (response.status == 200.toShort()) {
            val asset: Asset = Json.decodeFromDynamic(response.json().await())
            return AssetApiResponse(
                httpStatusCode = HttpStatusCode.OK,
                asset = asset
            )

        }

    } catch (e: dynamic) {
        console.log("Error: $e")
    }
    return AssetApiResponse(
        httpStatusCode = null,
        asset = null
    )
}


suspend fun deleteAsset(assetId: Int): ApiCallStatus {
    try {
        val response: HttpResponse = httpClient.delete("$BackendServerURL/asset-management?assetId=$assetId")
        if (response.status == HttpStatusCode.NoContent) {
            return ApiCallStatus.SUCCESS
        }
    } catch (e: dynamic) {
        console.log("Error: ${e.message}")
    }
    return ApiCallStatus.FAILURE
}