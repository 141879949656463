package com.ecosave.watch.portal.components.esg.formcontrols

import kotlin.reflect.KClass


open class GenericFieldMapper<T : Any>(
    val label: ListHeaderMapper,
    val name: String,
    val value: T? = null,
    val kClass: KClass<out T>
)

open class TextAreaFieldMapper(
    label: String,
    name: String,
    value: String? = null,
    kClass: KClass<String> = String::class,
    listHeaders: List<String> = listOf()
) : GenericFieldMapper<String>(
    label = ListHeaderMapper(label, listHeaders),
    name = name,
    value = value,
    kClass = kClass
)

open class NumberFieldMapper(
    label: String,
    name: String,
    value: Long? = null,
    kClass: KClass<Long> = Long::class,
    listHeaders: List<String> = listOf()
) : GenericFieldMapper<Long>(
    label = ListHeaderMapper(label, listHeaders),
    name = name,
    value = value,
    kClass = kClass
)

open class DecimalFieldMapper(
    label: String,
    name: String,
    value: Double? = null,
    kClass: KClass<Double> = Double::class,
    listHeaders: List<String> = listOf()
) : GenericFieldMapper<Double>(
    label = ListHeaderMapper(label, listHeaders),
    name = name,
    value = value,
    kClass = kClass
)

data class ListHeaderMapper(
    val mainHeader: String,
    val listHeaders: List<String>,
)
