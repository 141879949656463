package com.ecosave.watch.portal.pages

import com.ecosave.watch.portal.components.energymanagement.EnergyManagementHeader
import com.ecosave.watch.portal.components.energymanagement.RangeController
import com.ecosave.watch.portal.components.energymanagement.UtilityCompareSection
import com.ecosave.watch.portal.components.energymanagement.UtilityTotalSection
import com.ecosave.watch.portal.helpers.common.PageTitles
import com.ecosave.watch.portal.helpers.energymanagement.RadioLabels
import com.ecosave.watch.portal.helpers.energymanagement.getYLabel
import com.ecosave.watch.portal.models.energymanagement.ActionCount
import com.ecosave.watch.portal.models.energymanagement.BaselineSelectionStatus
import com.ecosave.watch.portal.models.energymanagement.EnergyUsageCostNCO2PerFacility
import com.ecosave.watch.portal.models.energymanagement.FacilityDetails
import com.ecosave.watch.portal.models.energymanagement.RankingDataResponse
import com.ecosave.watch.portal.services.energymanagement.getEnergyUsageCostNCO2PerGroup
import com.ecosave.watch.portal.services.energymanagement.getRankingPerGroup
import com.ecosave.watch.portal.useGlobalState
import kotlinx.coroutines.launch
import mui.material.Box
import react.FC
import react.Props
import react.useEffectOnce
import react.useState
import com.ecosave.watch.portal.styles.EnergyManagementStyles as styles

val PortfolioOverviewPage = FC<Props> {
    var (active, setActive) = useState(RadioLabels.USAGE)

    var fetchedData by useState(EnergyUsageCostNCO2PerFacility())
    var isFetched by useState(false)
    var isFetchedError by useState(false)

    var rankingData by useState(RankingDataResponse(emptyList(), emptyList()))
    var isFetchedRanking by useState(false)
    var isRankingError by useState(false)

    var selectedGroupName by useState<String?>("All Facilities")
    var selectedGroupId by useState(1)
    var selectedDateRange by useState("")

    val facilityDetail = fetchedData.facilities.firstOrNull() ?: FacilityDetails()

    val startDate1 = fetchedData.searchCriteriaOne?.startDate ?: ""
    val endDate1 = fetchedData.searchCriteriaOne?.endDate ?: ""
    val startDate2 = fetchedData.searchCriteriaTwo?.startDate ?: ""
    val endDate2 = fetchedData.searchCriteriaTwo?.endDate ?: ""
    val actionStatusCountOne = fetchedData.searchCriteriaOne?.actionsStatusCount ?: ActionCount()
    val actionStatusCountTwo = fetchedData.searchCriteriaTwo?.actionsStatusCount ?: ActionCount()
    val energyTotalOne = fetchedData.searchCriteriaOne?.energyTotal ?: emptyList()
    val energyTotalTwo = fetchedData.searchCriteriaTwo?.energyTotal ?: emptyList()
    val utilityBrakeDownsOne = fetchedData.searchCriteriaOne?.utilityBrakeDowns ?: emptyList()
    val utilityBrakeDownsTwo = fetchedData.searchCriteriaTwo?.utilityBrakeDowns ?: emptyList()
    val firstRankingData = rankingData.firstPeriod
    var secondRankingData = rankingData.secondPeriod
    val globalState = useGlobalState()

    useEffectOnce {
        globalState.updatePageTitle(PageTitles.PORTFOLIO_OVERVIEW.title)
    }

    Box {
        className = styles.CONTAINER.cssClass

        EnergyManagementHeader {
            facility = facilityDetail
            activeRadio = active
            setActiveRadio = setActive
            baselineStatus = BaselineSelectionStatus.NOT_AVAILABLE
            isGroup = true
            groupName = selectedGroupName
            selectGroup = { groupId, name ->
                mainScope.launch {
                    isFetched = false
                    isFetchedError = false
                    isFetchedRanking = false
                    isRankingError = false

                    var params = "groupId=${groupId}" + selectedDateRange

                    val response = getEnergyUsageCostNCO2PerGroup(params)
                    fetchedData = response.data
                    isFetched = response.fetched
                    isFetchedError = response.isError

                    val rankingResponse = getRankingPerGroup(params)
                    rankingData = rankingResponse.data
                    isFetchedRanking = rankingResponse.fetched
                    isRankingError = rankingResponse.isError

                    selectedGroupId = groupId
                    selectedGroupName = name
                }
            }
        }
        RangeController {
            baselineStatus = BaselineSelectionStatus.NOT_AVAILABLE
            reset = {
                mainScope.launch {
                    isFetched = false
                    isFetchedError = false
                    isFetchedRanking = false
                    isRankingError = false
                    val response = getEnergyUsageCostNCO2PerGroup("groupId=${selectedGroupId}")
                    fetchedData = response.data
                    isFetched = response.fetched
                    isFetchedError = response.isError
                    val rankingResponse = getRankingPerGroup("groupId=${selectedGroupId}")
                    rankingData = rankingResponse.data
                    isFetchedRanking = rankingResponse.fetched
                    isRankingError = rankingResponse.isError
                    selectedDateRange = ""
                }
            }
            compare = {
                mainScope.launch {
                    isFetched = false
                    isFetchedError = false
                    isFetchedRanking = false
                    isRankingError = false

                    var params = "groupId=${selectedGroupId}" + it
                    val response = getEnergyUsageCostNCO2PerGroup(params)
                    fetchedData = response.data
                    isFetched = response.fetched
                    isFetchedError = response.isError

                    val rankingResponse = getRankingPerGroup(params)
                    rankingData = rankingResponse.data
                    isFetchedRanking = rankingResponse.fetched
                    isRankingError = rankingResponse.isError

                    selectedDateRange = it
                    isFetched = true
                }
            }
        }
        UtilityTotalSection {
            title = "$startDate1 - $endDate1"
            energyData = energyTotalOne
            dataFetched = isFetched
            radioLabel = active
            actionStatus = actionStatusCountOne
            isRanking = true
            ranking = firstRankingData
        }
        if (!energyTotalTwo.isNullOrEmpty()) {
            UtilityTotalSection {
                title = "$startDate2 - $endDate2"
                energyData = energyTotalTwo
                dataFetched = isFetched
                radioLabel = active
                actionStatus = actionStatusCountTwo
                isRanking = true
                ranking = secondRankingData
            }
        }
        for ((index, utilityBrakeDown) in utilityBrakeDownsOne.withIndex()) {
            UtilityCompareSection {
                dataFetched = isFetched
                utilityType = utilityBrakeDown.utilityType
                firstUtilityData = utilityBrakeDown.energyUsageNCO2
                secondUtilityData = utilityBrakeDownsTwo.getOrNull(index)?.energyUsageNCO2 ?: emptyList()
                activeRadio = active
                yLabel = getYLabel(active.label, utilityBrakeDown.utilityType.name)
                firstDuration = "$startDate1 - $endDate1"
                secondDuration = "$startDate2 - $endDate2"
                firstPeriod = "$startDate1 - $endDate1"
                secondPeriod = "$startDate2 - $endDate2"
            }
        }
    }
}