package com.ecosave.watch.portal.components.energymanagement

import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.components.common.DialogSpinner
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.assetmanagement.FacilityClass
import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.services.energymanagement.uploadFacilityImage
import emotion.react.css
import kotlin.js.Date
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Input
import mui.system.PropsWithSx
import mui.system.sx
import react.FC
import react.StateSetter
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.label
import react.useState
import web.cssom.ClassName
import web.cssom.ObjectFit
import web.cssom.Position
import web.cssom.Visibility
import web.cssom.px
import web.html.HTMLInputElement

external interface UploadPhotoProps : PropsWithSx {
    var facilityChild: FacilityClass
    var setFacilityChild: StateSetter<FacilityClass>
    var photoUploaded: Boolean
    var setPhotoUploaded: StateSetter<Boolean>
}

val UploadPhoto = FC<UploadPhotoProps> { props ->
    val (openPhoto, setOpenPhoto) = useState(false)
    var openSpinner by useState(false)
    var spinnerMessage by useState("")
    val (notificationState, setNotificationState) = useState(NotificationState())

    Box {
        sx {
            visibility = Visibility.hidden
            position = Position.absolute
        }
        Input {
            className = ClassName("upload-photo-input")
            id = "file-upload-test"
            type = "file"
            onChange = {
                val target = it.target as HTMLInputElement
                if (target.files?.length != 0) {
                    mainScope.launch {
                        spinnerMessage = "Uploading Image..."
                        openSpinner = true
                        props.facilityChild.facilityId?.let {
                            val returnedFacility = uploadFacilityImage(it, target.files)
                            props.setFacilityChild(
                                props.facilityChild.copy(
                                    imageUrl = "${returnedFacility.imageUrl}?${Date.now()}"
                                )
                            )
                        }
                        openSpinner = false
                    }
                    props.setPhotoUploaded(true)
                }
            }
        }
    }

    if (!props.facilityChild.imageUrl.isNullOrEmpty()) {
        Button {
            variant = ButtonVariant.outlined
            onClick = {
                setOpenPhoto(true)
            }
            +"Manage Facility Image"
        }
    } else {
        Button {
            variant = ButtonVariant.outlined
            label {
                htmlFor = "file-upload-test"
                +"Upload Facility Image"
            }
        }
    }
    props.facilityChild.imageUrl?.let { url ->
        Box {
            img {
                css {
                    height = 150.px
                    width = 150.px
                    marginTop = 15.px
                    right = 65.px
                    objectFit = ObjectFit.cover
                    position = Position.absolute
                }
                src
                key = url
                src = url
                alt = "Facility Image"
            }
        }
    }
    DialogSpinner {
        open = openSpinner
        message = spinnerMessage
    }
    AlertNotifications {
        open = notificationState.visible
        status = notificationState.status
        message = notificationState.message
        closeNotification = {
            setNotificationState(
                notificationState.copy(
                    visible = false
                )
            )
        }
    }
    PhotoDialog {
        open = openPhoto
        setOpen = setOpenPhoto
        facilityChild = props.facilityChild
        setFacilityChild = props.setFacilityChild
    }
}