package com.ecosave.watch.portal.components.utilityaccounts

import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.components.common.ConfirmationDialog
import com.ecosave.watch.portal.helpers.billing.BillingConstants
import com.ecosave.watch.portal.helpers.billing.UsageUnit
import com.ecosave.watch.portal.helpers.billing.UtilityType
import com.ecosave.watch.portal.helpers.billing.clearBillStartAndEndDate
import com.ecosave.watch.portal.helpers.billing.getUtilityTypeDescription
import com.ecosave.watch.portal.helpers.billing.isBillingHotOrChilledWaterFormValid
import com.ecosave.watch.portal.helpers.billing.mainScope
import com.ecosave.watch.portal.services.billing.createBill
import com.ecosave.watch.portal.styles.BillingInputFormStyles
import com.ecosave.watch.portal.useGlobalState
import com.ecosave.watch.portal.models.billing.utilitybillstate.ElectricAdditional
import com.ecosave.watch.portal.models.billing.ElectricBillValidationState
import com.ecosave.watch.portal.models.billing.utilitybillstate.NaturalGasAdditional
import com.ecosave.watch.portal.models.billing.NaturalGasBillValidationState
import com.ecosave.watch.portal.models.billing.utilitybillstate.SolarBillAdditional
import com.ecosave.watch.portal.models.billing.SolarBillValidationState
import com.ecosave.watch.portal.models.billing.utilitybillstate.SteamBillAdditional
import com.ecosave.watch.portal.models.billing.SteamBillValidationState
import com.ecosave.watch.portal.models.billing.utilitybillstate.UtilityBillState
import com.ecosave.watch.portal.models.billing.UtilityBillValidationState
import com.ecosave.watch.portal.models.billing.utilitybillstate.WaterBillAdditional
import com.ecosave.watch.portal.models.billing.WaterBillValidationState
import com.ecosave.watch.portal.styles.CommonStyles
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import react.FC
import react.Props
import react.StateSetter
import react.useState

external interface HotOrChilledWaterFormProps : Props {
    var setShowInputForm: StateSetter<Boolean>
    var showNotification: (NotificationStatus, String) -> Unit
}

val HotOrChilledWaterForm = FC<HotOrChilledWaterFormProps> { props ->
    val globalState = useGlobalState()
    val (formValidationState, setFormValidationState) = useState(
        UtilityBillValidationState(
            electricBillValidationState = ElectricBillValidationState(),
            naturalGasBillValidationState = NaturalGasBillValidationState(),
            waterBillValidationState = WaterBillValidationState(),
            steamBillValidationState = SteamBillValidationState(),
            solarBillValidationState = SolarBillValidationState()
        )
    )
    val (formState, setFormState) = useState(
        UtilityBillState(
            utilityAccountId = globalState.utilityAccountData.accountId,
            utilityType = globalState.utilityAccountData.utilityType.toString(),
            usageUnit = UsageUnit.KBTU.name,
            electricAdditional = ElectricAdditional(),
            naturalGasAdditional = NaturalGasAdditional(),
            waterBillAdditional = WaterBillAdditional(),
            steamBillAdditional = SteamBillAdditional(),
            solarBillAdditional = SolarBillAdditional()
        )
    )
    var isSubmitting by useState(false)
    var submitButtonText by useState("Submit")
    val (openDialog, setOpenDialog) = useState(false)

    Box {
        InputFormTitleSector {
            title =
                "Add ${getUtilityTypeDescription(globalState.utilityAccountData.utilityType.toString())} Bill"
            subtitle = "Account Number: ${globalState.utilityAccountData.accountNumber}"
        }
        Box {
            className = BillingInputFormStyles.MAIN_FORM.cssClass
            Box {
                className = BillingInputFormStyles.INPUT_WRAPPER.cssClass
                BillStartDate {
                    state = formState
                    setState = setFormState
                    validation = formValidationState
                    setValidation = setFormValidationState
                    isSolarStartDate = false
                    setDialogState = setOpenDialog
                }

                BillEndDate {
                    state = formState
                    setState = setFormState
                    validation = formValidationState
                    setValidation = setFormValidationState
                    isSolarEndDate = false
                    setDialogState = setOpenDialog
                }

                AddTotalUsage {
                    state = formState
                    setState = setFormState
                    validation = formValidationState
                    setValidation = setFormValidationState
                }

                AddUsageUnit {
                    stateUsageUnit = formState
                    setStateUsageUnit = setFormState
                    usageUnits =
                        if (globalState.utilityAccountData.utilityType == UtilityType.HOT_WATER)
                            UtilityType.HOT_WATER.usageUnits else
                            UtilityType.CHILLED_WATER.usageUnits
                }

                AddTotalBillCost {
                    state = formState
                    setState = setFormState
                    validation = formValidationState
                    setValidation = setFormValidationState
                }

                Box {
                    className = BillingInputFormStyles.INPUT_ITEM.cssClass
                }
                Box {
                    className = BillingInputFormStyles.INPUT_ITEM.cssClass
                }
                Box {
                    className = BillingInputFormStyles.INPUT_ITEM.cssClass
                }
            }
        }
        Box {
            className = CommonStyles.CENTERED_BUTTON.cssClass
            Button {
                className = CommonStyles.MARGIN_RIGHT_BUTTON.cssClass
                +"Cancel"
                onClick = {
                    props.setShowInputForm(false)
                }
            }
            Button {
                +submitButtonText
                disabled = isSubmitting
                variant = ButtonVariant.contained
                onClick = {
                    if (isBillingHotOrChilledWaterFormValid(
                            formState,
                            formValidationState,
                            setFormValidationState
                        )
                    ) {
                        mainScope.launch {
                            isSubmitting = true
                            submitButtonText = "Submitting..."
                            val apiCallStatus = createBill(formState)
                            if (apiCallStatus == ApiCallStatus.SUCCESS) {
                                props.showNotification(
                                    NotificationStatus.SUCCESS,
                                    BillingConstants.BILL_SUBMITTED_NOTIFICATION_MESSAGE
                                )
                                props.setShowInputForm(false)
                            } else {
                                props.showNotification(NotificationStatus.ERROR, Constants.NOTIFICATION_ERROR_MESSAGE)
                            }
                            submitButtonText = "Submit"
                            isSubmitting = false
                        }
                    }
                }
            }
        }
    }
    ConfirmationDialog {
        open = openDialog
        title = "Confirmation"
        body = BillingConstants.BILL_START_END_DATE_DIFF_DIALOG_MESSAGE
        actionResult = { value ->
            setOpenDialog(false)
            if (!value) {
                clearBillStartAndEndDate(formState, setFormState)
            }
        }
    }
}