package com.ecosave.watch.portal.helpers.facilitygroupmanagement

import com.ecosave.watch.portal.currentTheme
import com.ecosave.watch.portal.helpers.Colors
import js.core.jso
import mui.material.TableCellProps
import mui.material.ToolbarProps
import mui.system.sx
import web.cssom.Color

object GroupManagementConstants {

    val muiTableToolbarColor: ToolbarProps = jso {
        sx {
            backgroundColor = Color(currentTheme.palette.background.paper)
        }
    }

    val muiTableCellColor: TableCellProps = jso {
        sx {
            backgroundColor = Color(currentTheme.palette.background.paper)
        }
    }

    val headCellProps: TableCellProps = jso {
        sx {
            backgroundColor = Color(currentTheme.palette.background.paper)
            color = Color(Colors.GRAY)
        }
    }

}