package com.ecosave.watch.portal.components.resourcecenter.controlcenter

import com.ecosave.watch.portal.components.resourcecenter.ResourcesSection
import com.ecosave.watch.portal.helpers.resourcecenter.ControlCenterImages
import com.ecosave.watch.portal.styles.ResourceCenterStyles
import mui.material.Box
import mui.material.List
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML
import web.cssom.px

external interface ControlCenterComponentProps : Props {
    var listPadding: Number
}

val ControlCenterComponent = FC<ControlCenterComponentProps> {
    List {
        sx {
            paddingLeft = it.listPadding.px
        }
        Box {
            className = ResourceCenterStyles.RESOURCES_SUBSECTION.cssClass
            id = "control-center-overview"
            Typography {
                className = ResourceCenterStyles.RESOURCES_MAIN_TITLE.cssClass
                variant = TypographyVariant.h4
                component = ReactHTML.h4
                +"Control Center Overview"
            }
            for (sent in controlCenterSentences) {
                ResourcesSection {
                   sentence = sent
                }
            }
            ReactHTML.img {
                src = ControlCenterImages.ControlCenterOverviewImage
                className = ResourceCenterStyles.RESOURCES_DISPLAY_OVERVIEW.cssClass
            }
        }
    }
}