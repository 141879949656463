package com.ecosave.watch.portal.components.esg.generaldisclosures.strategypoliciesandpractices

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val Disclosure_2_26 = FC<EsgSubFormComponentProps> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.strategyPoliciesPractices?.let { state ->

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_2_26
        }

        Box {
            label {
                +"Describe the mechanism for individuals to seek advice on implementing the organization’s policies and practices for responsible business conduct."
            }
            ESGTextAreaFieldWrapper {
                name = state::section226A1.name
                value = state.section226A1 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"Describe the mechanism for individuals to raising concerns about the organization’s business conduct."
            }
            ESGTextAreaFieldWrapper {
                name = state::section226A2.name
                value = state.section226A2 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}