package com.ecosave.watch.portal.helpers.billing

import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.components.npm.isURL
import com.ecosave.watch.portal.models.billing.UtilityAccountState
import com.ecosave.watch.portal.models.billing.UtilityAccountValidationState
import react.ReactNode
import react.StateSetter

fun validateAccountType(
    state: UtilityAccountState,
    validation: UtilityAccountValidationState,
    setValidation: StateSetter<UtilityAccountValidationState>
) {
    if (state.utilityType.isBlank()) {
        validation.accountTypeErrorState = true
        validation.accountTypeErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.accountTypeErrorState = false
        validation.accountTypeErrorMessage = null
    }

    updateUtilityAccountValidationState(validation, setValidation)
}

fun validateUtilityServiceProvider(
    state: UtilityAccountState,
    validation: UtilityAccountValidationState,
    setValidation: StateSetter<UtilityAccountValidationState>
) {
    if (state.utilityServiceProviderId == "") {
        validation.utilityServiceProviderErrorState = true
        validation.utilityServiceProviderErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.utilityServiceProviderErrorState = false
        validation.utilityServiceProviderErrorMessage = null
    }

    updateUtilityAccountValidationState(validation, setValidation)
}

fun validateServiceName(
    state: UtilityAccountState,
    validation: UtilityAccountValidationState,
    setValidation: StateSetter<UtilityAccountValidationState>
) {
    if (state.serviceName.isBlank()) {
        validation.serviceNameErrorState = true
        validation.serviceNameErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.serviceNameErrorState = false
        validation.serviceNameErrorMessage = null
    }

    updateUtilityAccountValidationState(validation, setValidation)
}

fun validateServiceAddress(
    state: UtilityAccountState,
    validation: UtilityAccountValidationState,
    setValidation: StateSetter<UtilityAccountValidationState>
) {
    if (state.serviceAddress.isBlank()) {
        validation.serviceAddressErrorState = true
        validation.serviceAddressErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.serviceAddressErrorState = false
        validation.serviceAddressErrorMessage = null
    }

    updateUtilityAccountValidationState(validation, setValidation)
}

fun validateAccountNumber(
    state: UtilityAccountState,
    validation: UtilityAccountValidationState,
    setValidation: StateSetter<UtilityAccountValidationState>
) {
    if (state.accountNumber.isBlank()) {
        validation.accountNumberErrorState = true
        validation.accountNumberErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else if (state.accountNumber.contains("-")) {
        validation.accountNumberErrorState = true
        validation.accountNumberErrorMessage = ReactNode("Please remove hypen from account number")
    } else if (state.accountNumber.toLongOrNull() == null) {
        validation.accountNumberErrorState = true
        validation.accountNumberErrorMessage = ReactNode("Please enter a valid integer value")
    } else if (state.accountNumber.length > 15) {
        validation.accountNumberErrorState = true
        validation.accountNumberErrorMessage = ReactNode("Account Number should be less than 16 digits")
    } else {
        validation.accountNumberErrorState = false
        validation.accountNumberErrorMessage = null
    }

    updateUtilityAccountValidationState(validation, setValidation)
}

fun validateSurchargeBillExists(
    state: UtilityAccountState,
    validation: UtilityAccountValidationState,
    setValidation: StateSetter<UtilityAccountValidationState>
) {
    if (state.surchargeBillExists == null) {
        validation.surchargeBillExistsErrorState = true
        validation.surchargeBillExistsErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.surchargeBillExistsErrorState = false
        validation.surchargeBillExistsErrorMessage = null
    }

    updateUtilityAccountValidationState(validation, setValidation)
}

fun validateTotalInstalledSolarCapacity(
    state: UtilityAccountState,
    validation: UtilityAccountValidationState,
    setValidation: StateSetter<UtilityAccountValidationState>
) {
    if (getDigitsCountBeforeDecimal(state.solarTotalInstalledCapacity.toString()) > BillingConstants.MAX_VALUE_DIGITS) {
        validation.totalInstalledSolarCapacityErrorState = true
        validation.totalInstalledSolarCapacityErrorMessage = ReactNode(BillingConstants.INVALID_VALUE_ERROR_MESSAGE)
    } else {
        validation.totalInstalledSolarCapacityErrorState = false
        validation.totalInstalledSolarCapacityErrorMessage = null
    }

    updateUtilityAccountValidationState(validation, setValidation)
}

fun validateTotalAnnualGenerationSolar(
    state: UtilityAccountState,
    validation: UtilityAccountValidationState,
    setValidation: StateSetter<UtilityAccountValidationState>
) {
    if (state.solarTotalAnnualGeneration == null) {
        validation.totalAnnualGenerationSolarErrorState = true
        validation.totalAnnualGenerationSolarErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else if (getDigitsCountBeforeDecimal(state.solarTotalAnnualGeneration.toString()) > BillingConstants.MAX_VALUE_DIGITS) {
        validation.totalAnnualGenerationSolarErrorState = true
        validation.totalAnnualGenerationSolarErrorMessage = ReactNode(BillingConstants.INVALID_VALUE_ERROR_MESSAGE)
    } else {
        validation.totalAnnualGenerationSolarErrorState = false
        validation.totalAnnualGenerationSolarErrorMessage = null
    }

    updateUtilityAccountValidationState(validation, setValidation)
}

fun validatePpaInPlaceSolar(
    state: UtilityAccountState,
    validation: UtilityAccountValidationState,
    setValidation: StateSetter<UtilityAccountValidationState>
) {
    if (state.solarPPAInPlace == null) {
        validation.ppaInPlaceSolarErrorState = true
        validation.ppaInPlaceSolarErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.ppaInPlaceSolarErrorState = false
        validation.ppaInPlaceSolarErrorMessage = null
    }

    updateUtilityAccountValidationState(validation, setValidation)
}

fun validateIsSolarNetMetered(
    state: UtilityAccountState,
    validation: UtilityAccountValidationState,
    setValidation: StateSetter<UtilityAccountValidationState>
) {
    if (state.solarNetMetered == null) {
        validation.isSolarNetMeteredErrorState = true
        validation.isSolarNetMeteredErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.isSolarNetMeteredErrorState = false
        validation.isSolarNetMeteredErrorMessage = null
    }

    updateUtilityAccountValidationState(validation, setValidation)
}

fun validatePpaServiceProviderSolar(
    state: UtilityAccountState,
    validation: UtilityAccountValidationState,
    setValidation: StateSetter<UtilityAccountValidationState>
) {
    if (state.solarPPAServiceProvider.isBlank()) {
        validation.ppaServiceProviderSolarErrorState = true
        validation.ppaServiceProviderSolarErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.ppaServiceProviderSolarErrorState = false
        validation.ppaServiceProviderSolarErrorMessage = null
    }

    updateUtilityAccountValidationState(validation, setValidation)
}

fun validateTotalLengthOfPPASolar(
    state: UtilityAccountState,
    validation: UtilityAccountValidationState,
    setValidation: StateSetter<UtilityAccountValidationState>
) {
    if (state.solarTotalLengthOfPPA == null) {
        validation.totalLengthOfPPASolarErrorState = true
        validation.totalLengthOfPPASolarErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else if (getDigitsCountBeforeDecimal(state.solarTotalLengthOfPPA.toString()) > BillingConstants.MAX_VALUE_DIGITS) {
        validation.totalLengthOfPPASolarErrorState = true
        validation.totalLengthOfPPASolarErrorMessage = ReactNode(BillingConstants.INVALID_VALUE_ERROR_MESSAGE)
    } else {
        validation.totalLengthOfPPASolarErrorState = false
        validation.totalLengthOfPPASolarErrorMessage = null
    }

    updateUtilityAccountValidationState(validation, setValidation)
}

fun validateCurrentPPARateSolar(
    state: UtilityAccountState,
    validation: UtilityAccountValidationState,
    setValidation: StateSetter<UtilityAccountValidationState>
) {
    if (state.solarCurrentPPARate == null) {
        validation.currentPPARateSolarErrorState = true
        validation.currentPPARateSolarErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else if (getDigitsCountBeforeDecimal(state.solarCurrentPPARate.toString()) > BillingConstants.MAX_VALUE_DIGITS) {
        validation.currentPPARateSolarErrorState = true
        validation.currentPPARateSolarErrorMessage = ReactNode(BillingConstants.INVALID_VALUE_ERROR_MESSAGE)
    } else {
        validation.currentPPARateSolarErrorState = false
        validation.currentPPARateSolarErrorMessage = null
    }

    updateUtilityAccountValidationState(validation, setValidation)
}

fun validateSolarUsageUnit(
    state: UtilityAccountState,
    validation: UtilityAccountValidationState,
    setValidation: StateSetter<UtilityAccountValidationState>
) {
    if (state.solarUsageUnit.isBlank()) {
        validation.solarUsageUnitErrorState = true
        validation.solarUsageUnitErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.solarUsageUnitErrorState = false
        validation.solarUsageUnitErrorMessage = null
    }

    updateUtilityAccountValidationState(validation, setValidation)
}

fun validateOtherUtilityServiceProvider(
    state: UtilityAccountState,
    validation: UtilityAccountValidationState,
    setValidation: StateSetter<UtilityAccountValidationState>
) {
    if (state.utilityServiceProviderNameOther.isBlank()) {
        validation.otherUtilityServiceProviderErrorState = true
        validation.otherUtilityServiceProviderErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.otherUtilityServiceProviderErrorState = false
        validation.otherUtilityServiceProviderErrorMessage = null
    }

    updateUtilityAccountValidationState(validation, setValidation)
}

fun validateUtilityServiceProviderWebsiteLink(
    state: UtilityAccountState,
    validation: UtilityAccountValidationState,
    setValidation: StateSetter<UtilityAccountValidationState>
) {
    if (state.utilityServiceProviderWebsiteUrl.isBlank()) {
        validation.utilityServiceProviderWebsiteLinkErrorState = true
        validation.utilityServiceProviderWebsiteLinkErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else if (!isURL(state.utilityServiceProviderWebsiteUrl)) {
        validation.utilityServiceProviderWebsiteLinkErrorState = true
        validation.utilityServiceProviderWebsiteLinkErrorMessage = ReactNode(Constants.VALID_URL_ERROR_MESSAGE)
    } else {
        validation.utilityServiceProviderWebsiteLinkErrorState = false
        validation.utilityServiceProviderWebsiteLinkErrorMessage = null
    }

    updateUtilityAccountValidationState(validation, setValidation)
}

fun validateCollectionMethod(
    state: UtilityAccountState,
    validation: UtilityAccountValidationState,
    setValidation: StateSetter<UtilityAccountValidationState>
) {
    if (state.collectionMethod.isBlank()) {
        validation.collectionMethodErrorState = true
        validation.collectionMethodErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.collectionMethodErrorState = false
        validation.collectionMethodErrorMessage = null
    }

    updateUtilityAccountValidationState(validation, setValidation)
}

fun validateUserId(
    state: UtilityAccountState,
    validation: UtilityAccountValidationState,
    setValidation: StateSetter<UtilityAccountValidationState>
) {
    if (state.userId.isNullOrBlank()) {
        validation.userIdErrorState = true
        validation.userIdErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.userIdErrorState = false
        validation.userIdErrorMessage = null
    }

    updateUtilityAccountValidationState(validation, setValidation)
}

fun validatePassword(
    state: UtilityAccountState,
    validation: UtilityAccountValidationState,
    setValidation: StateSetter<UtilityAccountValidationState>
) {
    if (state.password.isNullOrBlank()) {
        validation.passwordErrorState = true
        validation.passwordErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.passwordErrorState = false
        validation.passwordErrorMessage = null
    }

    updateUtilityAccountValidationState(validation, setValidation)
}

fun updateUtilityAccountValidationState(
    validation: UtilityAccountValidationState,
    setValidation: StateSetter<UtilityAccountValidationState>
) {
    setValidation(validation.copy())
}

fun isUtilityAccountFormValid(
    state: UtilityAccountState,
    validation: UtilityAccountValidationState,
    setValidation: StateSetter<UtilityAccountValidationState>
): Boolean {

    val utilityType = UtilityType.valueOf(state.utilityType)

    if (utilityType == UtilityType.WATER) {
        validateSurchargeBillExists(state, validation, setValidation)
    }

    if (utilityType != UtilityType.SOLAR) {
        validateAccountNumber(state, validation, setValidation)
        validateUtilityServiceProvider(state, validation, setValidation)
        validateUtilityServiceProviderWebsiteLink(state, validation, setValidation)
        if (state.utilityServiceProviderId == BillingConstants.OTHER_UTILITY_SERVICE_PROVIDER_ID.toString()) {
            validateOtherUtilityServiceProvider(state, validation, setValidation)
        }
    }

    if (utilityType == UtilityType.SOLAR) {
        validateTotalInstalledSolarCapacity(state, validation, setValidation)
        validateTotalAnnualGenerationSolar(state, validation, setValidation)
        validatePpaInPlaceSolar(state, validation, setValidation)
        validateIsSolarNetMetered(state, validation, setValidation)
        validateSolarUsageUnit(state, validation, setValidation)

        if (state.solarPPAInPlace == true) {
            validatePpaServiceProviderSolar(state, validation, setValidation)
            validateTotalLengthOfPPASolar(state, validation, setValidation)
            validateCurrentPPARateSolar(state, validation, setValidation)
        }

        if (state.solarNetMetered == true) {
            validateAccountNumber(state, validation, setValidation)
        }
    }

    if (state.collectionMethod == UtilityDataCollectionMethod.ECOSAVE_WATCH.name) {
        validateUserId(state, validation, setValidation)
        validatePassword(state, validation, setValidation)
    }

    validateAccountType(state, validation, setValidation)
    validateServiceName(state, validation, setValidation)
    validateServiceAddress(state, validation, setValidation)
    validateCollectionMethod(state, validation, setValidation)

    return isUtilityAccountFormFieldsValid(utilityType, state, validation)
}

fun isUtilityAccountFormFieldsValid(
    utilityType: UtilityType,
    state: UtilityAccountState,
    validation: UtilityAccountValidationState
): Boolean {
    var additionalFieldValidationInWaterForm = true
    var fieldsValidationWhenNotSolar = true
    var commonFieldsValidationWhenSolar = true
    var ppaFieldsValidationSolar = true
    var solarNetMeteredFieldsValidation = true
    var utilityProviderOtherFields = true
    var ecosaveWatchAutomaticCollectionFields = true

    if (utilityType == UtilityType.WATER) {
        additionalFieldValidationInWaterForm = !validation.surchargeBillExistsErrorState
    }

    if (utilityType != UtilityType.SOLAR) {
        fieldsValidationWhenNotSolar =
            !(validation.accountNumberErrorState || validation.utilityServiceProviderErrorState || validation.utilityServiceProviderWebsiteLinkErrorState)

        if (state.utilityServiceProviderId == BillingConstants.OTHER_UTILITY_SERVICE_PROVIDER_ID.toString()) {
            utilityProviderOtherFields = !validation.otherUtilityServiceProviderErrorState
        }
    }

    if (utilityType == UtilityType.SOLAR) {

        commonFieldsValidationWhenSolar = !(validation.totalInstalledSolarCapacityErrorState ||
                validation.totalAnnualGenerationSolarErrorState ||
                validation.ppaInPlaceSolarErrorState ||
                validation.isSolarNetMeteredErrorState ||
                validation.solarUsageUnitErrorState)

        if (state.solarPPAInPlace == true) {

            ppaFieldsValidationSolar = !(validation.ppaServiceProviderSolarErrorState ||
                    validation.totalLengthOfPPASolarErrorState ||
                    validation.currentPPARateSolarErrorState)
        }

        if (state.solarNetMetered == true) {
            solarNetMeteredFieldsValidation = !(validation.accountNumberErrorState)
        }
    }

    if (state.collectionMethod == UtilityDataCollectionMethod.ECOSAVE_WATCH.name) {
        ecosaveWatchAutomaticCollectionFields = !(validation.userIdErrorState || validation.passwordErrorState)
    }

    val commonFieldsValidation = !(validation.accountTypeErrorState ||
            validation.serviceNameErrorState ||
            validation.serviceAddressErrorState || validation.collectionMethodErrorState
            )

    return (additionalFieldValidationInWaterForm &&
            commonFieldsValidation && fieldsValidationWhenNotSolar &&
            commonFieldsValidationWhenSolar && ppaFieldsValidationSolar &&
            solarNetMeteredFieldsValidation && utilityProviderOtherFields && ecosaveWatchAutomaticCollectionFields)
}