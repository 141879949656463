package com.ecosave.watch.portal.components.esg.biodiversity

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.TextAreaFieldMapper
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val GRI_304_4 = FC<EsgSubFormComponentProps> { props ->
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.biodiversity?.let { state ->
        DisclosureName {
            disclosure = com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles.GRI_304_4
        }

        DisclosureSubHeading{
            subHeading = "Total number of IUCN Red List species and national conservation list species with " +
                "habitats in areas affected by the operations of the organization, by level of extinction " +
                "risk:"
        }

        listOf(
            TextAreaFieldMapper(
                "Critically endangered",
                state::section3044A1.name,
                state.section3044A1
            ),
            TextAreaFieldMapper(
                "Endangered",
                state::section3044A2.name,
                state.section3044A2
            ),
            TextAreaFieldMapper(
                "Vulnerable",
                state::section3044A3.name,
                state.section3044A3
            ),
            TextAreaFieldMapper(
                "Near threatened",
                state::section3044A4.name,
                state.section3044A4
            ),
            TextAreaFieldMapper(
                "Least concern",
                state::section3044A5.name,
                state.section3044A5
            ),
        ).forEach {
            label {
                +it.label.mainHeader
            }
            ESGTextAreaFieldWrapper {
                name = it.name
                value = it.value
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
                minimumRows = 2
            }
        }
    }
}