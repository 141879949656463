package com.ecosave.watch.portal.services.energymanagement

import com.ecosave.watch.portal.helpers.BackendServerURL
import com.ecosave.watch.portal.models.energymanagement.*
import com.ecosave.watch.portal.services.ApiResponse
import com.ecosave.watch.portal.services.httpClient
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*

suspend fun getEnergyUsageCostNCO2PerFacility(params: String): ApiResponse<EnergyUsageCostNCO2PerFacility> {
    try {
        val response: HttpResponse = httpClient.get("$BackendServerURL/energy-management/usage?${params}")
        if (response.status == HttpStatusCode.OK) {
            return ApiResponse(fetched = true, isError = false, response.body())
        }
    } catch (e: dynamic) {
        console.log(e)
    }
    return ApiResponse(fetched = true, isError = true, EnergyUsageCostNCO2PerFacility())
}

suspend fun getEnergyUsageCostNCO2PerGroup(params: String): ApiResponse<EnergyUsageCostNCO2PerFacility> {
    try {
        val response: HttpResponse = httpClient.get("$BackendServerURL/energy-management/group-usage?${params}")
        if (response.status == HttpStatusCode.OK) {
            return ApiResponse(fetched = true, isError = false, response.body())
        }
    } catch (e: dynamic) {
        console.log(e)
    }
    return ApiResponse(fetched = true, isError = true, EnergyUsageCostNCO2PerFacility())
}

suspend fun getRankingPerGroup(params: String): ApiResponse<RankingDataResponse> {
    try {
        val response: HttpResponse = httpClient.get("$BackendServerURL/energy-management/ranking?${params}")
        if (response.status == HttpStatusCode.OK) {
            return ApiResponse(fetched = true, isError = false, response.body())
        }
    } catch (e: dynamic) {
        console.log(e)
    }
    return ApiResponse(fetched = true, isError = true, RankingDataResponse(emptyList(), emptyList()))
}