package com.ecosave.watch.portal.components.esg.generaldisclosures.stakeholderengagement

import com.ecosave.watch.portal.components.esg.EsgCoreComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.GRIDisclosureGroupName
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.isValidDecimalNumber
import com.ecosave.watch.portal.helpers.common.isValidLongNumber
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.EsgSubSection
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import com.ecosave.watch.portal.helpers.esg.failureErrorMessage
import com.ecosave.watch.portal.helpers.esg.omitDisclosureFromUI
import com.ecosave.watch.portal.helpers.esg.resetPreviousPatchState
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.esg.CategoriesOfStakeholders
import com.ecosave.watch.portal.models.esg.CurrentInputState
import com.ecosave.watch.portal.models.esg.EsgCollectionAddRow
import com.ecosave.watch.portal.models.esg.RowPatch
import com.ecosave.watch.portal.models.esg.StakeholderEngagementState
import com.ecosave.watch.portal.services.esg.addDynamicRowOrSection
import com.ecosave.watch.portal.services.esg.deleteDynamicRowOrSection
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import kotlinx.coroutines.launch
import kotlinx.serialization.json.JsonPrimitive
import mui.material.Box
import react.FC
import react.dom.events.FocusEvent
import react.dom.events.FormEvent
import web.html.HTMLDivElement
import web.html.HTMLElement
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement

val StakeholderEngagementComponent = FC<EsgCoreComponentProps> { props ->

    val reportState = props.reportState
    val setReportState = props.setReportState
    val disclosureGroup = EsgSubSection.STAKEHOLDER_ENGAGEMENT

    reportState.report?.stakeholderEngagement?.let { state ->

        fun stateSetter(state: StakeholderEngagementState) {
            setReportState(
                reportState.copy(
                    report = reportState.report.copy(
                        stakeholderEngagement = state
                    )
                )
            )
        }

        val addRowOrSection: (String) -> Unit = { fieldName ->
            resetPreviousPatchState(props.setInputState)
            mainScope.launch {
                props.setAddDeleteRowOrSection(DynamicTableAction.ADD)
                props.setAddDeleteRowOrSectionApiCallInProgress(true)
                val apiCallStatus = addDynamicRowOrSection(
                    EsgCollectionAddRow(
                        objectName = disclosureGroup.name,
                        reportFileName = reportState.reportFileName,
                        patchCollection = RowPatch(
                            pathFirst = fieldName
                        )
                    )
                )

                val newState = state.copy()

                if (apiCallStatus == ApiCallStatus.SUCCESS) {
                    when (fieldName) {
                        state::categoriesOfStakeholdersList.name -> {
                            newState.categoriesOfStakeholdersList.add(CategoriesOfStakeholders())
                            props.setNotificationMessage(ReportConstants.ADD_ROW_NOTIFICATION_MESSAGE)
                        }
                    }

                    stateSetter(newState)

                    props.setNotificationStatus(NotificationStatus.SUCCESS)
                    props.setOpenAlertNotifications(true)
                } else {
                    failureErrorMessage(
                        props.setNotificationMessage,
                        props.setNotificationStatus,
                        props.setOpenAlertNotifications
                    )
                }
                props.setAddDeleteRowOrSectionApiCallInProgress(false)
            }
        }

        val deleteRowOrSection: (String, Int) -> Unit = { fieldName, index ->
            resetPreviousPatchState(props.setInputState)
            mainScope.launch {
                props.setAddDeleteRowOrSection(DynamicTableAction.DELETE)
                props.setAddDeleteRowOrSectionApiCallInProgress(true)
                val apiCallStatus = deleteDynamicRowOrSection(
                    objectPatchEnum = disclosureGroup.name,
                    reportFileName = reportState.reportFileName,
                    pathFirst = fieldName,
                    indexAt = index
                )

                val newState = state.copy()

                if (apiCallStatus == ApiCallStatus.SUCCESS) {
                    when (fieldName) {
                        state::categoriesOfStakeholdersList.name -> {
                            newState.categoriesOfStakeholdersList.removeAt(index)
                            props.setNotificationMessage(ReportConstants.DELETE_ROW_NOTIFICATION_MESSAGE)
                        }
                    }

                    stateSetter(newState)

                    props.setNotificationStatus(NotificationStatus.SUCCESS)
                    props.setOpenAlertNotifications(true)
                } else {
                    failureErrorMessage(
                        props.setNotificationMessage,
                        props.setNotificationStatus,
                        props.setOpenAlertNotifications
                    )
                }
                props.setAddDeleteRowOrSectionApiCallInProgress(false)
            }
        }

        val onChangeTextArea: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, listName ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value

            val newState = state.copy()

            if (listName == state::categoriesOfStakeholdersList.name && changeIndex != null) {
                newState.categoriesOfStakeholdersList[changeIndex].let {
                    when (propertyName) {
                        it::section229A1.name -> it.section229A1 = propertyValue
                        it::section229A2.name -> it.section229A2 = propertyValue
                        it::section229A3.name -> it.section229A3 = propertyValue
                    }
                }
            } else {
                when (propertyName) {
                    state::section229Field1.name -> newState.section229Field1 = propertyValue

                    state::section230Field1.name -> newState.section230Field1 = propertyValue
                }
            }

            stateSetter(newState)

            props.setInputState(
                CurrentInputState(
                    parentPath = listName,
                    childPath = propertyName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue),
                    objectName = disclosureGroup.name,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurTextArea: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value
            props.onBlurApiCall(
                propertyName,
                JsonPrimitive(propertyValue),
                changeIndex,
                field,
                disclosureGroup.name,
            )
        }

        val onChangeNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, listName ->
            val target = event.target as HTMLInputElement
            if (isValidLongNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toLongOrNull()

                val newState = state.copy()

                when (propertyName) {
                    state::section230A1.name -> newState.section230A1 = propertyValue
                    state::section230A2.name -> newState.section230A2 = propertyValue
                }

                stateSetter(newState)

                props.setInputState(
                    CurrentInputState(
                        parentPath = listName,
                        childPath = propertyName,
                        index = changeIndex,
                        value = JsonPrimitive(propertyValue),
                        objectName = disclosureGroup.name,
                        reportName = reportState.reportFileName
                    )
                )
            }
        }

        val onBlurNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidLongNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toLongOrNull()
                props.onBlurApiCall(
                    propertyName,
                    JsonPrimitive(propertyValue),
                    changeIndex,
                    field,
                    disclosureGroup.name,
                )
            }
        }

        val onChangeDecimalNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidDecimalNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toDoubleOrNull()

                val newState = state.copy()

                when (propertyName) {
                    state::section230A3.name -> newState.section230A3 = propertyValue
                }

                stateSetter(newState)

                props.setInputState(
                    CurrentInputState(
                        parentPath = field,
                        childPath = propertyName,
                        index = changeIndex,
                        value = JsonPrimitive(propertyValue),
                        objectName = disclosureGroup.name,
                        reportName = reportState.reportFileName
                    )
                )
            }
        }

        val onBlurDecimalNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidDecimalNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toDoubleOrNull()
                props.onBlurApiCall(
                    propertyName,
                    JsonPrimitive(propertyValue),
                    changeIndex,
                    field,
                    disclosureGroup.name,
                )
            }
        }

        Box {
            className = ESGCommonStyles.SECTION_MAIN_LAYOUT.cssClass

            GRIDisclosureGroupName {
                griDisclosureGroup = disclosureGroup
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_2_29)) {
                Disclosure_2_29 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.addRowOrSection = addRowOrSection
                    this.deleteRowOrSection = deleteRowOrSection
                }
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_2_30)) {
                Disclosure_2_30 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeNumber = onChangeNumber
                    this.onBlurNumber = onBlurNumber
                    this.onChangeDecimalNumber = onChangeDecimalNumber
                    this.onBlurDecimalNumber = onBlurDecimalNumber
                }
            }
        }
    }
}