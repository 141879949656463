package com.ecosave.watch.portal.components.resourcecenter

import mui.material.Box
import mui.material.Typography
import mui.material.TypographyAlign
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import web.cssom.FontWeight
import web.cssom.px

external interface ModuleContentTitleProps : Props {
    var title: String
}

val ModuleContentTitle = FC<ModuleContentTitleProps> {
    Box {
        Typography {
            sx {
                fontWeight = FontWeight.bolder
            }
            variant = TypographyVariant.h4
            align = TypographyAlign.center
            +it.title
        }
    }
}