package com.ecosave.watch.portal.models.billing

import react.ReactNode

data class UtilityBillValidationState(
    var billStartDateErrorState: Boolean = false,
    var billStartDateErrorMessage: ReactNode? = null,
    var billEndDateErrorState: Boolean = false,
    var billEndDateErrorMessage: ReactNode? = null,
    var totalUsageErrorState: Boolean = false,
    var totalUsageErrorMessage: ReactNode? = null,
    var totalBillCostErrorState: Boolean = false,
    var totalBillCostErrorMessage: ReactNode? = null,
    var billEstimatedErrorState: Boolean = false,
    var billEstimatedErrorMessage: ReactNode? = null,
    var billAdjustedErrorState: Boolean = false,
    var billAdjustedErrorMessage: ReactNode? = null,
    var electricBillValidationState: ElectricBillValidationState,
    var naturalGasBillValidationState: NaturalGasBillValidationState,
    var waterBillValidationState: WaterBillValidationState,
    var steamBillValidationState: SteamBillValidationState,
    var solarBillValidationState: SolarBillValidationState
)

data class ElectricBillValidationState(
    var totalPeakBilledDemandErrorState: Boolean = false,
    var totalPeakBilledDemandErrorMessage: ReactNode? = null,
    var peakDemandChargesErrorState: Boolean = false,
    var peakDemandChargesErrorMessage: ReactNode? = null,
    var onPeakUsageErrorState: Boolean = false,
    var onPeakUsageErrorMessage: ReactNode? = null,
    var onPeakUsageChargesErrorState: Boolean = false,
    var onPeakUsageChargesErrorMessage: ReactNode? = null,
    var offPeakUsageErrorState: Boolean = false,
    var offPeakUsageErrorMessage: ReactNode? = null,
    var offPeakUsageChargesErrorState: Boolean = false,
    var offPeakUsageChargesErrorMessage: ReactNode? = null,
    var onPeakDemandErrorState: Boolean = false,
    var onPeakDemandErrorMessage: ReactNode? = null,
    var offPeakDemandErrorState: Boolean = false,
    var offPeakDemandErrorMessage: ReactNode? = null,
    var totalDeliveryDistributionErrorState: Boolean = false,
    var totalDeliveryDistributionErrorMessage: ReactNode? = null
)

data class NaturalGasBillValidationState(
    var totalCommoditySupplyErrorState: Boolean = false,
    var totalCommoditySupplyErrorMessage: ReactNode? = null,
    var totalDeliveryDistributionErrorState: Boolean = false,
    var totalDeliveryDistributionErrorMessage: ReactNode? = null
)

data class WaterBillValidationState(
    var serviceChargesErrorState: Boolean = false,
    var serviceChargesErrorMessage: ReactNode? = null,
    var stormWaterChargesErrorState: Boolean = false,
    var stormWaterChargesErrorMessage: ReactNode? = null,
    var industrialSurchargeErrorState: Boolean = false,
    var industrialSurchargeErrorMessage: ReactNode? = null,
    var flowErrorState: Boolean = false,
    var flowErrorMessage: ReactNode? = null,
    var srfErrorState: Boolean = false,
    var srfErrorMessage: ReactNode? = null,
    var flowUsedForBillingErrorState: Boolean = false,
    var flowUsedForBillingErrorMessage: ReactNode? = null,
    var bioChemicalOxygenDemandErrorState: Boolean = false,
    var bioChemicalOxygenDemandErrorMessage: ReactNode? = null,
    var bodSurchargeFactorErrorState: Boolean = false,
    var bodSurchargeFactorErrorMessage: ReactNode? = null,
    var bodChargesErrorState: Boolean = false,
    var bodChargesErrorMessage: ReactNode? = null,
    var totalSuspendedSolidsErrorState: Boolean = false,
    var totalSuspendedSolidsErrorMessage: ReactNode? = null,
    var tssSurchargeFactorErrorState: Boolean = false,
    var tssSurchargeFactorErrorMessage: ReactNode? = null,
    var tssChargesErrorState: Boolean = false,
    var tssChargesErrorMessage: ReactNode? = null,
    var totalSurchargeBillErrorState: Boolean = false,
    var totalSurchargeBillErrorMessage: ReactNode? = null
)

data class SteamBillValidationState(
    var demandMeasuredErrorState: Boolean = false,
    var demandMeasuredErrorMessage: ReactNode? = null,
    var demandBilledErrorState: Boolean = false,
    var demandBilledErrorMessage: ReactNode? = null,
    var totalHeatingSteamChargesErrorState: Boolean = false,
    var totalHeatingSteamChargesErrorMessage: ReactNode? = null,
    var heatingDemandChargesErrorState: Boolean = false,
    var heatingDemandChargesErrorMessage: ReactNode? = null,
    var heatingSteamUsageErrorState: Boolean = false,
    var heatingSteamUsageErrorMessage: ReactNode? = null,
    var heatingUsageChargesErrorState: Boolean = false,
    var heatingUsageChargesErrorMessage: ReactNode? = null,
    var coolingSteamUsageErrorState: Boolean = false,
    var coolingSteamUsageErrorMessage: ReactNode? = null,
    var totalCoolingSteamChargesErrorState: Boolean = false,
    var totalCoolingSteamChargesErrorMessage: ReactNode? = null
)

data class SolarBillValidationState(
    var electricityExportedToGridErrorState: Boolean = false,
    var electricityExportedToGridErrorMessage: ReactNode? = null,
    var exportCostErrorState: Boolean = false,
    var exportCostErrorMessage: ReactNode? = null,
    var totalSolarGeneratedErrorState: Boolean = false,
    var totalSolarGeneratedErrorMessage: ReactNode? = null,
    var totalSolarCostErrorState: Boolean = false,
    var totalSolarCostErrorMessage: ReactNode? = null,
    var solarExportedErrorState: Boolean = false,
    var solarExportedErrorMessage: ReactNode? = null,
    var solarExportedCreditErrorState: Boolean = false,
    var solarExportedCreditErrorMessage: ReactNode? = null
)
