package com.ecosave.watch.portal.components.esg.economicperformance

import com.ecosave.watch.portal.components.esg.EsgCoreComponentProps
import com.ecosave.watch.portal.components.esg.GenericSection_3_3
import com.ecosave.watch.portal.components.esg.formcontrols.GRIStandardName
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.isValidDecimalNumber
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.EsgSection
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import com.ecosave.watch.portal.helpers.esg.failureErrorMessage
import com.ecosave.watch.portal.helpers.esg.omitDisclosureFromUI
import com.ecosave.watch.portal.helpers.esg.resetPreviousPatchState
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.esg.CountriesAndValues
import com.ecosave.watch.portal.models.esg.CurrentInputState
import com.ecosave.watch.portal.models.esg.EconomicPerformanceState
import com.ecosave.watch.portal.models.esg.EsgCollectionAddRow
import com.ecosave.watch.portal.models.esg.EsgCollectionDeleteRow
import com.ecosave.watch.portal.models.esg.FinancialOpportunities
import com.ecosave.watch.portal.models.esg.FinancialRisks
import com.ecosave.watch.portal.models.esg.RetirementPlans
import com.ecosave.watch.portal.models.esg.RowPatch
import com.ecosave.watch.portal.services.esg.addDynamicRowOrSection
import com.ecosave.watch.portal.services.esg.deleteDynamicRowOrSection
import kotlinx.coroutines.launch
import kotlinx.serialization.json.JsonPrimitive
import mui.material.Box
import react.FC
import react.dom.events.FocusEvent
import react.dom.events.FormEvent
import web.html.HTMLDivElement
import web.html.HTMLElement
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles as styles

val EconomicPerformanceComponent = FC<EsgCoreComponentProps> { props ->

    val reportState = props.reportState
    val setReportState = props.setReportState

    reportState.report?.economicPerformance?.let { state ->

        fun stateSetter(state: EconomicPerformanceState) {
            setReportState(
                reportState.copy(
                    report = reportState.report.copy(
                        economicPerformance = state
                    )
                )
            )
        }

        val addRowOrSection: (String) -> Unit = { fieldName ->
            resetPreviousPatchState(props.setInputState)
            mainScope.launch {
                props.setAddDeleteRowOrSection(DynamicTableAction.ADD)
                props.setAddDeleteRowOrSectionApiCallInProgress(true)
                val apiCallStatus = addDynamicRowOrSection(
                    EsgCollectionAddRow(
                        objectName = EsgSection.ECONOMIC_PERFORMANCE.name,
                        reportFileName = reportState.reportFileName,
                        patchCollection = RowPatch(
                            pathFirst = fieldName
                        )
                    )
                )

                val newState = state.copy()

                if (apiCallStatus == ApiCallStatus.SUCCESS) {
                    when (fieldName) {
                        state::financialRisksList.name -> {
                            newState.financialRisksList.add(FinancialRisks())
                            props.setNotificationMessage("A new Risk section has been added successfully.")
                        }

                        state::financialOpportunitiesList.name -> {
                            newState.financialOpportunitiesList.add(FinancialOpportunities())
                            props.setNotificationMessage("A new Opportunity section has been added successfully.")
                        }

                        state::retirementPlansList.name -> {
                            newState.retirementPlansList.add(RetirementPlans())
                            props.setNotificationMessage("A new Retirement Plan section has been added successfully.")
                        }

                        state::countriesAndValuesList.name -> {
                            newState.countriesAndValuesList.add(CountriesAndValues())
                            props.setNotificationMessage(ReportConstants.ADD_ROW_NOTIFICATION_MESSAGE)
                        }
                    }

                    stateSetter(newState)

                    props.setNotificationStatus(NotificationStatus.SUCCESS)
                    props.setOpenAlertNotifications(true)
                } else {
                    failureErrorMessage(
                        props.setNotificationMessage,
                        props.setNotificationStatus,
                        props.setOpenAlertNotifications
                    )
                }
                props.setAddDeleteRowOrSectionApiCallInProgress(false)
            }
        }

        val deleteRowOrSection: (String, Int) -> Unit = { fieldName, index ->
            resetPreviousPatchState(props.setInputState)
            mainScope.launch {
                props.setAddDeleteRowOrSection(DynamicTableAction.DELETE)
                props.setAddDeleteRowOrSectionApiCallInProgress(true)
                val apiCallStatus = deleteDynamicRowOrSection(
                    EsgCollectionDeleteRow(
                        objectPatchEnum = EsgSection.ECONOMIC_PERFORMANCE,
                        reportFileName = reportState.reportFileName,
                        pathFirst = fieldName,
                        indexAt = index
                    )
                )

                val newState = state.copy()

                if (apiCallStatus == ApiCallStatus.SUCCESS) {
                    when (fieldName) {
                        state::financialRisksList.name -> {
                            newState.financialRisksList.removeAt(index)
                            props.setNotificationMessage(ReportConstants.DELETE_SECTION_NOTIFICATION_MESSAGE)
                        }

                        state::financialOpportunitiesList.name -> {
                            newState.financialOpportunitiesList.removeAt(index)
                            props.setNotificationMessage(ReportConstants.DELETE_SECTION_NOTIFICATION_MESSAGE)
                        }

                        state::retirementPlansList.name -> {
                            newState.retirementPlansList.removeAt(index)
                            props.setNotificationMessage(ReportConstants.DELETE_SECTION_NOTIFICATION_MESSAGE)
                        }

                        state::countriesAndValuesList.name -> {
                            newState.countriesAndValuesList.removeAt(index)
                            props.setNotificationMessage(ReportConstants.DELETE_ROW_NOTIFICATION_MESSAGE)
                        }
                    }

                    stateSetter(newState)

                    props.setNotificationStatus(NotificationStatus.SUCCESS)
                    props.setOpenAlertNotifications(true)
                } else {
                    failureErrorMessage(
                        props.setNotificationMessage,
                        props.setNotificationStatus,
                        props.setOpenAlertNotifications
                    )
                }
                props.setAddDeleteRowOrSectionApiCallInProgress(false)
            }
        }

        val onChangeDecimalNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidDecimalNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toDoubleOrNull()

                val newState = state.copy()

                if (field == state::retirementPlansList.name && changeIndex != null) {
                    newState.retirementPlansList[changeIndex].let {
                        when (propertyName) {
                            it::section2013A7.name -> it.section2013A7 = propertyValue
                            it::section2013A8.name -> it.section2013A8 = propertyValue
                        }
                    }
                } else if (field == state::countriesAndValuesList.name && changeIndex != null) {
                    newState.countriesAndValuesList[changeIndex].let {
                        when (propertyName) {
                            it::section2014Part2A2.name -> it.section2014Part2A2 = propertyValue
                            it::section2014Part2A3.name -> it.section2014Part2A3 = propertyValue
                            it::section2014Part2A4.name -> it.section2014Part2A4 = propertyValue
                            it::section2014Part2A5.name -> it.section2014Part2A5 = propertyValue
                            it::section2014Part2A6.name -> it.section2014Part2A6 = propertyValue
                            it::section2014Part2A7.name -> it.section2014Part2A7 = propertyValue
                            it::section2014Part2A8.name -> it.section2014Part2A8 = propertyValue
                            it::section2014Part2A9.name -> it.section2014Part2A9 = propertyValue
                        }
                    }
                } else {
                    when (propertyName) {
                        state::section2013A1.name -> newState.section2013A1 = propertyValue
                        state::section2014A1.name -> newState.section2014A1 = propertyValue
                        state::section2014A2.name -> newState.section2014A2 = propertyValue
                        state::section2014A3.name -> newState.section2014A3 = propertyValue
                        state::section2014A4.name -> newState.section2014A4 = propertyValue
                        state::section2014A5.name -> newState.section2014A5 = propertyValue
                        state::section2014A6.name -> newState.section2014A6 = propertyValue
                        state::section2014A7.name -> newState.section2014A7 = propertyValue
                        state::section2014A8.name -> newState.section2014A8 = propertyValue
                    }
                }

                stateSetter(newState)

                props.setInputState(
                    CurrentInputState(
                        parentPath = field,
                        childPath = propertyName,
                        index = changeIndex,
                        value = JsonPrimitive(propertyValue),
                        objectName = EsgSection.ECONOMIC_PERFORMANCE.name,
                        reportName = reportState.reportFileName
                    )
                )
            }
        }

        val onBlurDecimalNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidDecimalNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toDoubleOrNull()
                props.onBlurApiCall(
                    propertyName,
                    JsonPrimitive(propertyValue),
                    changeIndex,
                    field,
                    EsgSection.ECONOMIC_PERFORMANCE.name
                )
            }
        }

        val onChangeTextArea: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value

            val newState = state.copy()

            if (field == state::financialRisksList.name && changeIndex != null) {
                newState.financialRisksList[changeIndex].let {
                    when (propertyName) {
                        it::section2012RiskA1.name -> it.section2012RiskA1 = propertyValue
                        it::section2012RiskA2.name -> it.section2012RiskA2 = propertyValue
                        it::section2012RiskA3.name -> it.section2012RiskA3 = propertyValue
                        it::section2012RiskA4.name -> it.section2012RiskA4 = propertyValue
                        it::section2012RiskA5.name -> it.section2012RiskA5 = propertyValue
                    }
                }
            } else if (field == state::financialOpportunitiesList.name && changeIndex != null) {
                newState.financialOpportunitiesList[changeIndex].let {
                    when (propertyName) {
                        it::section2012OpportunityA1.name -> it.section2012OpportunityA1 = propertyValue
                        it::section2012OpportunityA2.name -> it.section2012OpportunityA2 = propertyValue
                        it::section2012OpportunityA3.name -> it.section2012OpportunityA3 = propertyValue
                        it::section2012OpportunityA4.name -> it.section2012OpportunityA4 = propertyValue
                        it::section2012OpportunityA5.name -> it.section2012OpportunityA5 = propertyValue
                    }
                }
            } else {
                when (propertyName) {
                    state::section2011A1.name -> newState.section2011A1 = propertyValue
                    state::section2011A2.name -> newState.section2011A2 = propertyValue
                    state::section2011A3.name -> newState.section2011A3 = propertyValue
                    state::section2011A4.name -> newState.section2011A4 = propertyValue
                    state::section2011A5.name -> newState.section2011A5 = propertyValue
                    state::section2011A6.name -> newState.section2011A6 = propertyValue
                    state::section2011A7.name -> newState.section2011A7 = propertyValue
                    state::section2011Field1.name -> newState.section2011Field1 = propertyValue
                    state::section2011Field2.name -> newState.section2011Field2 = propertyValue

                    state::section2012Field1.name -> newState.section2012Field1 = propertyValue

                    state::section2013A2.name -> newState.section2013A2 = propertyValue
                    state::section2013A3.name -> newState.section2013A3 = propertyValue
                    state::section2013A4.name -> newState.section2013A4 = propertyValue
                    state::section2013A5.name -> newState.section2013A5 = propertyValue

                    state::section2014Part2Field1.name -> newState.section2014Part2Field1 = propertyValue

                    state::section3_3_201A1.name -> newState.section3_3_201A1 = propertyValue
                    state::section3_3_201A2.name -> newState.section3_3_201A2 = propertyValue
                    state::section3_3_201A3.name -> newState.section3_3_201A3 = propertyValue
                    state::section3_3_201A4.name -> newState.section3_3_201A4 = propertyValue
                    state::section3_3_201A5.name -> newState.section3_3_201A5 = propertyValue
                    state::section3_3_201A6.name -> newState.section3_3_201A6 = propertyValue
                }
            }

            stateSetter(newState)

            props.setInputState(
                CurrentInputState(
                    parentPath = field,
                    childPath = propertyName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue),
                    objectName = EsgSection.ECONOMIC_PERFORMANCE.name,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurTextArea: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value
            props.onBlurApiCall(
                propertyName,
                JsonPrimitive(propertyValue),
                changeIndex,
                field,
                EsgSection.ECONOMIC_PERFORMANCE.name
            )
        }

        val onChangeText: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            val propertyName = target.name
            val propertyValue = target.value

            val newState = state.copy()

            if (field == state::retirementPlansList.name && changeIndex != null) {
                newState.retirementPlansList[changeIndex].let {
                    when (propertyName) {
                        it::section2013A6.name -> it.section2013A6 = propertyValue
                        it::section2013A9.name -> it.section2013A9 = propertyValue
                        it::section2013A10.name -> it.section2013A10 = propertyValue
                        it::section2013A11.name -> it.section2013A11 = propertyValue
                    }
                }
            } else if (field == state::countriesAndValuesList.name && changeIndex != null) {
                newState.countriesAndValuesList[changeIndex].let {
                    when (propertyName) {
                        it::section2014Part2A1.name -> it.section2014Part2A1 = propertyValue
                    }
                }
            }

            stateSetter(newState)

            props.setInputState(
                CurrentInputState(
                    parentPath = field,
                    childPath = propertyName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue),
                    objectName = EsgSection.ECONOMIC_PERFORMANCE.name,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurText: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            val propertyName = target.name
            val propertyValue = target.value
            props.onBlurApiCall(
                propertyName,
                JsonPrimitive(propertyValue),
                changeIndex,
                field,
                EsgSection.ECONOMIC_PERFORMANCE.name
            )
        }

        Box {
            className = styles.SECTION_MAIN_LAYOUT.cssClass

            GRIStandardName {
                griStandard = EsgSection.ECONOMIC_PERFORMANCE
            }

            GenericSection_3_3 {
                this.onBlurTextArea = onBlurTextArea
                this.onChangeTextArea = onChangeTextArea
                this.materialTopicName = EsgSection.ECONOMIC_PERFORMANCE.description

                this.section3_3_A1_name = state::section3_3_201A1.name
                this.section3_3_A1_value = state.section3_3_201A1

                this.section3_3_A2_name = state::section3_3_201A2.name
                this.section3_3_A2_value = state.section3_3_201A2

                this.section3_3_A3_name = state::section3_3_201A3.name
                this.section3_3_A3_value = state.section3_3_201A3

                this.section3_3_A4_name = state::section3_3_201A4.name
                this.section3_3_A4_value = state.section3_3_201A4

                this.section3_3_A5_name = state::section3_3_201A5.name
                this.section3_3_A5_value = state.section3_3_201A5

                this.section3_3_A6_name = state::section3_3_201A6.name
                this.section3_3_A6_value = state.section3_3_201A6
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_201_1)) {
                GRI_201_1 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                }
            }
            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_201_2)) {
                GRI_201_2 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.addRowOrSection = addRowOrSection
                    this.deleteRowOrSection = deleteRowOrSection
                }
            }
            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_201_3)) {
                GRI_201_3 {
                    this.esgReportState = reportState
                    this.onChangeDecimalNumber = onChangeDecimalNumber
                    this.onBlurDecimalNumber = onBlurDecimalNumber
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeText = onChangeText
                    this.onBlurText = onBlurText
                    this.addRowOrSection = addRowOrSection
                    this.deleteRowOrSection = deleteRowOrSection
                }
            }
            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_201_4)) {
                GRI_201_4 {
                    this.esgReportState = reportState
                    this.onChangeDecimalNumber = onChangeDecimalNumber
                    this.onBlurDecimalNumber = onBlurDecimalNumber
                    this.onChangeText = onChangeText
                    this.onBlurText = onBlurText
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.addRowOrSection = addRowOrSection
                    this.deleteRowOrSection = deleteRowOrSection
                }
            }
        }
    }
}
