package com.ecosave.watch.portal.components.privacypolicy

import react.FC
import react.Props
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.ul

val WhyWeCollectDataFromAndAboutYou = FC<Props> {
    HeadingWrapper {
        heading = "Why We Collect Data From and About You"
    }
    TypographyWrapper {
        +"We will not use your Personal Information for anything other than the following lawful purposes:"
    }
    HeadingWrapper {
        heading = "To establish and maintain contractual relationships with our customers:"
        subHeading = true
    }
    TypographyWrapper {
        ul {
            li {
                +"To establish and maintain relationships with customers"
            }
            li {
                +"To fulfill our obligations to current customers in the delivery of the Services"
            }
            li {
                +"To contact customers regarding account-related issues and business communications relating to the Services, including technical notices, updates, security alerts, and administrative messages"
            }
            li {
                +"To enable our customers to access and use our Services"
            }
            li {
                +"To comply with our legal obligations:"
            }
            li {
                +"To comply with legal obligations, including but not limited to complying with tax and financial reporting requirements"
            }
            li {
                +"To demonstrate compliance with applicable privacy and data security laws and regulations, such as HIPAA, CCPA and GDPR"
            }
            li {
                +"To comply with incident monitoring, reporting, assessment, and notification requirements"
            }
            li {
                +"To comply with other applicable criminal and civil law and regulatory requirements under federal, state, and international law"
            }
            li {
                +"To provide services and information that you request and consent to receive:"
            }
            li {
                +"To provide customer service and support"
            }
            li {
                +"To communicate with you, including responding to your comments, questions, and requests regarding our Services"
            }
            li {
                +"To process and complete transactions, and send you related information, including purchase confirmations and invoices"
            }
            li {
                +"To provide direct marketing, email, and other distributed information distribution"
            }
            li {
                +"To conduct business operations necessary for the continued operation of our business:"
            }
            li {
                +"To administer, operate, maintain, and secure our website and Services"
            }
            li {
                +"To monitor and analyze trends, usage, and activities in connection with our Services"
            }
            li {
                +"To investigate and prevent fraudulent transactions, unauthorized access to our Services, and other illegal activities"
            }
            li {
                +"To verify compliance with our internal policies and procedures"
            }
            li {
                +"For accounting, record keeping, backup, and administrative purposes"
            }
            li {
                +"To customize and improve the content of our communications, websites, and social media accounts"
            }
            li {
                +"To educate and train our workforce in data protection and customer support"
            }
            li {
                +"To provide, operate, maintain, improve, personalize, and promote our Services"
            }
            li {
                +"To develop new products, services, features, and functionality"
            }
            li {
                +"To market our products and services (first-party marketing only; we do not provide Personal Information for use in marketing any non-Ecosave, third-party goods or services)"
            }
            li {
                +"When possible, we will use anonymized data for these purposes, but if we do not, or if we combine it with Personal Information, we will treat it in accordance with this Privacy Statement."
            }
        }
    }
}
