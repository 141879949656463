package com.ecosave.watch.portal.components.utilityaccounts

import mui.material.Box
import mui.material.FormControlVariant
import mui.material.Size
import mui.material.TextField
import mui.material.Tooltip
import mui.system.sx
import react.FC
import react.ReactNode
import web.cssom.px

val UtilityAccountTextFieldWrapper = FC<TextFieldWrapperProps> { props ->
    Box {
        sx {
            margin = 20.px
        }
        Tooltip {
            title = ReactNode(props.annotation)
            TextField {
                +props
                fullWidth = true
                variant = FormControlVariant.outlined
                size = Size.medium
            }
        }
    }
}