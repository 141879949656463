package com.ecosave.watch.portal.components.privacypolicy

import react.FC
import react.Props

val AutomaticCollection = FC<Props> {
    HeadingWrapper {
        heading = "Automatic Collection"
    }
    TypographyWrapper {
        +"Web Logs and Analytics Information: We record certain information and store it in log files when you interact with our Services. This information may include Internet protocol (IP) or other device addresses or ID numbers as well as browser type, Internet service provider, URLs of referring/exit pages, operating system, date/time stamp, information that you search for, your locale and language preferences, your mobile carrier, and system configuration information. We and our analytics providers also collect and store analytics information when you use our Services to help us improve our Services."
    }
    HeadingWrapper {
        heading = "Cookies and Other Tracking Technologies:"
        subHeading = true
    }
    TypographyWrapper {
        +"We use various technologies to collect information, including cookies that we save to your computer or mobile device. Cookies are small data files stored on your hard drive or in device memory. We use cookies to improve and customize our Services and your experience; to allow you to access and use the Services without re-entering your username or password; and to count visits and understand which areas and features of the Services are most popular. We may also associate the information we store in cookies with Personal Information you submit while on our Services. You can instruct your browser, by changing its options, to stop accepting cookies or to prompt you before accepting a cookie from websites you visit. If you do not accept cookies, however, you may not be able to use all aspects of our Services."
    }
}
