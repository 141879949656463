package com.ecosave.watch.portal.components.esg.occupationhealthandsafety

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val GRI_403_8 = FC<EsgSubFormComponentProps> { props ->
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea
    val onChangeNumber = props.onChangeNumber
    val onBlurNumber = props.onBlurNumber
    val onChangeDecimalNumber = props.onChangeDecimalNumber
    val onBlurDecimalNumber = props.onBlurDecimalNumber

    props.esgReportState.report?.occupationalHealthAndSafety?.let { state ->
        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_403_8
        }
        Box {
            className = ESGCommonStyles.THREE_COLUMN_TABLE.cssClass

            TableHeader {
                header = "Occupational health and safety management system coverage"
            }
            TableHeader {
                header = "Number"
            }
            TableHeader {
                header = "Rate"
            }

            label {
                +"All employees and workers who are not employees but whose work and/or workplace is "
                +"controlled by the organization, who are covered by such a system."
            }
            ESGNumberFieldWrapper {
                name = state::section4038A1.name
                value = state.section4038A1
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section4038B1.name
                value = state.section4038B1
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"All employees and workers who are not employees but whose work and/or workplace is controlled "
                +"by the organization, who are covered by such a system that has been internally audited."
            }
            ESGNumberFieldWrapper {
                name = state::section4038A2.name
                value = state.section4038A2
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section4038B2.name
                value = state.section4038B2
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"All employees and workers who are not employees but whose work and/or workplace is controlled "
                +"by the organization, who are covered by such a system that has been "
                +"audited or certified by an external party."
            }
            ESGNumberFieldWrapper {
                name = state::section4038A3.name
                value = state.section4038A3
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section4038B3.name
                value = state.section4038B3
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }
        }
        Box {
            label {
                +"Whether and, if so, why any workers have been excluded from this disclosure, including the types of worker excluded."
            }
            ESGTextAreaFieldWrapper {
                name = state::section4038Why.name
                value = state.section4038Why
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
        Box {
            label {
                +"Provide any contextual information necessary to understand how the data have been compiled, such as "
                +"any standards, methodologies, and assumptions used."
            }
            ESGTextAreaFieldWrapper {
                name = state::section4038Field1.name
                value = state.section4038Field1
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}