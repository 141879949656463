package com.ecosave.watch.portal.components.esg.marketingandlabeling

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val GRI_402_1 = FC<EsgSubFormComponentProps> { props ->

    val onChangeText = props.onChangeText
    val onBlurText = props.onBlurText
    val onChangeNumber = props.onChangeNumber
    val onBlurNumber = props.onBlurNumber

    props.esgReportState.report?.laborOrManagementRelations?.let { state ->
        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_402_1
        }

        Box {
            className = ESGCommonStyles.TWO_COLUMN_TABLE_8_4.cssClass
            label {
                +"Minimum number of weeks’ notice typically provided to employees and their representatives prior to the implementation of "
                +"significant operation changes that could substantially affect them."
            }
            ESGNumberFieldWrapper {
                name = state::section4021A1.name
                value = state.section4021A1
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            label {
                +"If organization has collective bargaining agreements, are the notice period and provisions for consultation and negotiation "
                +"specified in the agreements?"
            }
            ESGTextFieldWrapper {
                name = state::section4021A2.name
                value = state.section4021A2
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
        }
    }
}