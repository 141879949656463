package com.ecosave.watch.portal.components.esg.generaldisclosures.governance

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val Disclosure_2_10 = FC<EsgSubFormComponentProps> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.governance?.let { state ->

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_2_10
        }

        Box {
            label {
                +"Describe the nomination and selection processes for the highest governance body and it's committees."
            }
            ESGTextAreaFieldWrapper {
                name = state::section210A1.name
                value = state.section210A1 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        DisclosureSubHeading {
            subHeading = "How the following are taken into consideration when nominating and selecting highest governance body members."
        }

        Box {
            label {
                +"Views of shareholders (including shareholders)"
            }
            ESGTextAreaFieldWrapper {
                name = state::section210A2.name
                value = state.section210A2 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"Diversity"
            }
            ESGTextAreaFieldWrapper {
                name = state::section210A3.name
                value = state.section210A3 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"Independence"
            }
            ESGTextAreaFieldWrapper {
                name = state::section210A4.name
                value = state.section210A4 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"Competencies relevant to the impacts of the organization."
            }
            ESGTextAreaFieldWrapper {
                name = state::section210A5.name
                value = state.section210A5 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}