package com.ecosave.watch.portal.pages

import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.components.common.ConfirmationDialog
import com.ecosave.watch.portal.components.common.DialogSpinner
import com.ecosave.watch.portal.components.common.DialogWrapper
import com.ecosave.watch.portal.components.common.Loading
import com.ecosave.watch.portal.components.common.TableHeader
import com.ecosave.watch.portal.components.usermanagement.UserTextFieldWrapper
import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.FormMode
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.PageTitles
import com.ecosave.watch.portal.helpers.common.ScreenBreakPoints
import com.ecosave.watch.portal.helpers.common.sm
import com.ecosave.watch.portal.helpers.common.xs
import com.ecosave.watch.portal.helpers.usermanagement.FieldLabel
import com.ecosave.watch.portal.helpers.usermanagement.UserRole
import com.ecosave.watch.portal.helpers.usermanagement.getUserIndex
import com.ecosave.watch.portal.helpers.usermanagement.isUserFormValid
import com.ecosave.watch.portal.helpers.usermanagement.setStateForToBeDeletedUser
import com.ecosave.watch.portal.helpers.usermanagement.tableHeadersList
import com.ecosave.watch.portal.helpers.usermanagement.userRoleLists
import com.ecosave.watch.portal.helpers.usermanagement.validateUserEmail
import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.models.usermanagement.InvitedUser
import com.ecosave.watch.portal.models.usermanagement.InvitedUserDetail
import com.ecosave.watch.portal.models.usermanagement.UserActionsData
import com.ecosave.watch.portal.models.usermanagement.UserState
import com.ecosave.watch.portal.services.usermanagement.deleteUser
import com.ecosave.watch.portal.services.usermanagement.editUserRole
import com.ecosave.watch.portal.services.usermanagement.getUsers
import com.ecosave.watch.portal.services.usermanagement.inviteUsers
import com.ecosave.watch.portal.services.usermanagement.reassignActionsToAdminAndDeleteUser
import com.ecosave.watch.portal.services.usermanagement.resendInvitationEmail
import com.ecosave.watch.portal.styles.CommonStyles
import com.ecosave.watch.portal.styles.UserManagementStyles
import com.ecosave.watch.portal.useGlobalState
import io.ktor.http.*
import js.core.jso
import kotlinx.coroutines.launch
import mui.icons.material.Add
import mui.icons.material.Delete
import mui.icons.material.Edit
import mui.icons.material.Email
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Dialog
import mui.material.DialogActions
import mui.material.DialogContent
import mui.material.DialogContentText
import mui.material.DialogTitle
import mui.material.FormControl
import mui.material.Grid
import mui.material.IconButton
import mui.material.InputLabel
import mui.material.MenuItem
import mui.material.Select
import mui.material.Size
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableCellAlign
import mui.material.TableContainer
import mui.material.TableHead
import mui.material.TableRow
import mui.material.Tooltip
import mui.material.Typography
import mui.material.TypographyAlign
import mui.material.styles.TypographyVariant
import mui.material.useMediaQuery
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.dom.html.ReactHTML
import react.dom.onChange
import react.router.useNavigate
import react.useEffectOnce
import react.useState
import web.cssom.AlignItems
import web.cssom.AlignSelf
import web.cssom.JustifyContent
import web.cssom.TextTransform
import web.cssom.px
import web.cssom.rem
import web.html.HTMLInputElement
import web.html.InputType

val UserManagement = FC<Props> {
    var userList: MutableList<InvitedUserDetail> by useState(mutableListOf())
    var reRenderInviteUsersDialog by useState(false)
    var openAddOrEditUserForm by useState(false)
    var formMode by useState(FormMode.ADD)
    var showConfirmationDialog by useState(false)
    val isMobile = useMediaQuery("(max-width:${ScreenBreakPoints.small})")
    var isLoading by useState(true)
    var isSending by useState(false)
    var isSubmitting by useState(false)
    val (editUserState, setEditUserState) = useState(InvitedUser("", UserRole.USER))
    val (notificationState, setNotificationState) = useState(NotificationState())
    var deleteUserEmail by useState("")
    var spinnerMessage by useState("")
    var openUserDeletionConflictDialog by useState(false)
    val globalState = useGlobalState()
    val navigate = useNavigate()
    var toBeDeletedUserState by useState(
        setStateForToBeDeletedUser()
    )
    val (userState, setUserState) = useState(mutableListOf(UserState()))

    useEffectOnce {
        globalState.updatePageTitle(PageTitles.USER_MANAGEMENT.title)
        mainScope.launch {
            isLoading = true
            val users = getUsers()
            if (users == null) {
                setNotificationState(
                    notificationState.copy(
                        status = NotificationStatus.ERROR,
                        message = Constants.NOTIFICATION_ERROR_MESSAGE,
                        visible = true
                    )
                )
            } else {
                userList = users
            }
            isLoading = false
        }
    }

    fun removeUserFromUserList() {
        val index = getUserIndex(userList, deleteUserEmail)
        val updatedUserList = userList
        updatedUserList.removeAt(index)
        userList = updatedUserList

        setNotificationState(
            notificationState.copy(
                status = NotificationStatus.SUCCESS,
                message = "User Deleted Successfully.",
                visible = true
            )
        )
    }

    Box {
        Box {
            sx {
                marginBottom = 10.px
            }
            Button {
                variant = ButtonVariant.contained
                onClick = {
                    formMode = FormMode.ADD
                    openAddOrEditUserForm = true
                }
                startIcon = startIcon.also {
                    Add()
                }
                +"INVITE USERS"
            }
        }
        Box {
            TableContainer {
                className = CommonStyles.TABLE_HEIGHT.cssClass
                Table {
                    stickyHeader = true
                    TableHead {
                        TableRow {
                            hover = true
                            for (header in tableHeadersList) {
                                TableHeader {
                                    tableHeaderName = header.description
                                }
                            }
                            TableHeader {
                                tableHeaderName = "Actions"
                            }
                        }
                    }
                    TableBody {
                        if (isLoading) {
                            TableRow {
                                TableCell {
                                    className = CommonStyles.REMOVED_CELL_BOTTOM_BORDER.cssClass
                                    colSpan = 6
                                    align = TableCellAlign.center
                                    Box {
                                        sx {
                                            height = 400.px
                                        }
                                        Loading()
                                    }
                                }
                            }
                        } else {
                            for (user in userList) {
                                TableRow {
                                    hover = true
                                    TableCell {
                                        +"${user.firstName ?: ""} ${user.lastName ?: ""}"
                                    }
                                    TableCell {
                                        +user.emailAddress
                                    }
                                    TableCell {
                                        +user.userRole.description
                                    }
                                    TableCell {
                                        if (user.jobTitle == null) +"" else +user.jobTitle
                                    }
                                    TableCell {
                                        if (user.userRegistrationCompleted) +"Active" else +"Pending Registration"
                                    }

                                    TableCell {
                                        if (globalState.userData.emailAddress != user.emailAddress) {
                                            if (!user.userRegistrationCompleted) {
                                                Tooltip {
                                                    title = ReactNode("Resend Email")
                                                    IconButton {
                                                        onClick = {
                                                            mainScope.launch {
                                                                spinnerMessage = "Sending Email..."
                                                                isSending = true
                                                                val apiCallStatus =
                                                                    resendInvitationEmail(user.emailAddress)
                                                                if (apiCallStatus == ApiCallStatus.SUCCESS) {
                                                                    setNotificationState(
                                                                        notificationState.copy(
                                                                            status = NotificationStatus.SUCCESS,
                                                                            message = "Email Sent Successfully.",
                                                                            visible = true
                                                                        )
                                                                    )
                                                                } else {
                                                                    setNotificationState(
                                                                        notificationState.copy(
                                                                            status = NotificationStatus.ERROR,
                                                                            message = Constants.NOTIFICATION_ERROR_MESSAGE,
                                                                            visible = true
                                                                        )
                                                                    )
                                                                }
                                                                isSending = false
                                                                spinnerMessage = ""
                                                            }

                                                        }
                                                        Email()
                                                    }
                                                }
                                            }
                                            Tooltip {
                                                title = ReactNode("Edit User Role")
                                                IconButton {
                                                    onClick = {
                                                        formMode = FormMode.EDIT
                                                        setEditUserState(
                                                            editUserState.copy(
                                                                emailAddress = user.emailAddress,
                                                                userRole = user.userRole
                                                            )
                                                        )
                                                        openAddOrEditUserForm = true
                                                    }
                                                    Edit()
                                                }
                                            }
                                            Tooltip {
                                                title = ReactNode("Delete User")
                                                IconButton {
                                                    onClick = {
                                                        deleteUserEmail = user.emailAddress
                                                        showConfirmationDialog = true
                                                    }
                                                    Delete()
                                                }
                                            }
                                        } else {
                                            +"NA"
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    DialogWrapper {
        open = openAddOrEditUserForm
        dialogMaxWidth = if (formMode == FormMode.ADD) 800 else 600
        dialogTitle = if (formMode == FormMode.ADD) "Invite Users" else "Edit User Role"
        DialogContent {
            Grid {
                container = true
                List(userState.size) { index ->
                    if (formMode == FormMode.ADD) {
                        Grid {
                            item = true
                            sm = 2
                            xs = 12
                            sx {
                                alignSelf = AlignSelf.center
                            }
                            Typography {
                                sx {
                                    fontSize = 1.rem
                                    marginLeft = 20.px
                                }
                                component = ReactHTML.h6
                                variant = TypographyVariant.h6
                                align = TypographyAlign.center
                                +if (userState.size > 1) "USER ${index + 1}" else "USER"
                            }
                        }
                        Grid {
                            item = true
                            sm = 5
                            xs = 12
                            Box {
                                sx {
                                    margin = 20.px
                                }
                                UserTextFieldWrapper {
                                    label = ReactNode("${FieldLabel.EMAIL.description} *")
                                    type = InputType.text
                                    value = userState[index].email
                                    error = userState[index].emailErrorState
                                    helperText = userState[index].emailErrorMessage
                                    onChange = {
                                        val target = it.target as HTMLInputElement
                                        userState[index].email = target.value
                                        setUserState(userState)
                                        reRenderInviteUsersDialog = !reRenderInviteUsersDialog
                                    }
                                    onBlur = {
                                        validateUserEmail(userState[index])
                                        reRenderInviteUsersDialog = !reRenderInviteUsersDialog
                                    }
                                }
                            }
                        }
                    }
                    if (formMode == FormMode.EDIT) {
                        Grid {
                            item = true
                            xs = 1
                        }
                    }
                    Grid {
                        item = true
                        sm = if (formMode == FormMode.ADD) 4 else 10
                        xs = 12
                        FormControl {
                            sx {
                                margin = 20.px
                            }
                            InputLabel {
                                +"${FieldLabel.ROLE.description} *"
                            }
                            fullWidth = true
                            size = Size.medium
                            Select {
                                sx {
                                    marginRight = 40.px
                                }
                                label = ReactNode("${FieldLabel.ROLE.description} *")
                                value = if (formMode == FormMode.ADD) userState[index].role else editUserState.userRole
                                for (role in userRoleLists) {
                                    MenuItem {
                                        value = role.name
                                        +role.description
                                    }
                                }
                                onChange = { event, _ ->
                                    if (formMode == FormMode.ADD) {
                                        userState[index].role = event.target.value
                                        setUserState(userState)
                                    } else {
                                        setEditUserState(
                                            editUserState.copy(
                                                userRole = UserRole.valueOf(event.target.value)
                                            )
                                        )
                                    }
                                    reRenderInviteUsersDialog = !reRenderInviteUsersDialog
                                }
                            }
                        }
                    }
                    if (formMode == FormMode.EDIT) {
                        Grid {
                            item = true
                            xs = 1
                        }
                    }
                    Grid {
                        item = true
                        sm = 1
                        xs = 12
                        if (userState.size > 1) {
                            if (isMobile) {
                                Box {
                                    sx {
                                        marginRight = 40.px
                                    }
                                    Button {
                                        sx {
                                            margin = 20.px
                                        }
                                        fullWidth = true
                                        variant = ButtonVariant.contained
                                        onClick = {
                                            userState.removeAt(index)
                                            reRenderInviteUsersDialog = !reRenderInviteUsersDialog
                                        }
                                        +"Remove User ${index + 1}"
                                    }
                                }

                            } else {
                                Box {
                                    IconButton {
                                        sx {
                                            marginTop = 25.px
                                        }
                                        onClick = {
                                            userState.removeAt(index)
                                            reRenderInviteUsersDialog = !reRenderInviteUsersDialog
                                        }
                                        Delete()
                                    }
                                }
                            }
                        }
                    }
                }
                if (formMode == FormMode.ADD) {
                    Grid {
                        item = true
                        xs = 12
                        Button {
                            sx {
                                margin = 20.px
                                marginLeft = 45.px
                            }
                            variant = ButtonVariant.outlined
                            onClick = {
                                reRenderInviteUsersDialog = !reRenderInviteUsersDialog
                                userState.add(UserState())
                            }
                            startIcon = startIcon.also {
                                Add()
                            }
                            +"Add another user"
                        }
                    }
                }
            }
        }
        DialogActions {
            sx {
                justifyContent = JustifyContent.center
            }
            Button {
                +"Cancel"
                onClick = {
                    openAddOrEditUserForm = false
                    setEditUserState(InvitedUser("", UserRole.USER))
                    setUserState(mutableListOf(UserState()))
                }
            }
            Button {
                disabled = isSubmitting
                if (formMode == FormMode.ADD) {
                    if (isSubmitting) +"Inviting..." else +"Invite"
                } else {
                    if (isSubmitting) +"Submitting..." else +"Submit"
                }
                variant = ButtonVariant.contained
                onClick = {
                    if (formMode == FormMode.ADD) {
                        if (isUserFormValid(userState)) {
                            val invitedUsers: MutableList<InvitedUser> = mutableListOf()
                            userState.forEach { userState ->
                                invitedUsers.add(
                                    InvitedUser(
                                        userState.email,
                                        UserRole.valueOf(userState.role)
                                    )
                                )
                            }
                            mainScope.launch {
                                isSubmitting = true
                                when (inviteUsers(invitedUsers)) {
                                    HttpStatusCode.Accepted -> {
                                        val updatedUserList = userList
                                        invitedUsers.forEach { invitedUser ->
                                            updatedUserList.add(
                                                InvitedUserDetail(
                                                    null,
                                                    null,
                                                    null,
                                                    invitedUser.emailAddress,
                                                    invitedUser.userRole,
                                                    false
                                                )
                                            )
                                        }
                                        userList = updatedUserList
                                        setNotificationState(
                                            notificationState.copy(
                                                status = NotificationStatus.SUCCESS,
                                                message = "An invitation email has been sent to the user(s) for account registration.",
                                                visible = true
                                            )
                                        )
                                        setUserState(mutableListOf(UserState()))
                                        openAddOrEditUserForm = false
                                    }

                                    HttpStatusCode.Conflict -> {
                                        setNotificationState(
                                            notificationState.copy(
                                                status = NotificationStatus.ERROR,
                                                message = "User(s) you are trying to invite is/are already in the system, please verify the email address for user(s) once again.",
                                                visible = true
                                            )
                                        )
                                    }

                                    null -> {
                                        setNotificationState(
                                            notificationState.copy(
                                                status = NotificationStatus.ERROR,
                                                message = Constants.NOTIFICATION_ERROR_MESSAGE,
                                                visible = true
                                            )
                                        )
                                    }
                                }
                                isSubmitting = false
                            }
                        }
                    } else {
                        mainScope.launch {
                            isSubmitting = true
                            val apiCallStatus = editUserRole(editUserState)
                            if (apiCallStatus == ApiCallStatus.SUCCESS) {

                                val index = getUserIndex(userList, editUserState.emailAddress)
                                val updatedUserList = userList
                                updatedUserList[index].userRole = editUserState.userRole
                                userList = updatedUserList

                                setNotificationState(
                                    notificationState.copy(
                                        status = NotificationStatus.SUCCESS,
                                        message = "User Role Updated Successfully.",
                                        visible = true
                                    )
                                )
                                openAddOrEditUserForm = false

                            } else {
                                setNotificationState(
                                    notificationState.copy(
                                        status = NotificationStatus.ERROR,
                                        message = Constants.NOTIFICATION_ERROR_MESSAGE,
                                        visible = true
                                    )
                                )
                            }
                            isSubmitting = false
                        }
                    }
                    reRenderInviteUsersDialog = !reRenderInviteUsersDialog
                }
            }
        }
    }
    ConfirmationDialog {
        open = showConfirmationDialog
        title = "Confirmation"
        body = "Are you sure you want to delete this user?"
        actionResult = { value ->
            if (value) {
                mainScope.launch {
                    spinnerMessage = "Deleting User..."
                    isSending = true
                    val response = deleteUser(deleteUserEmail)
                    if (response == null) {
                        setNotificationState(
                            notificationState.copy(
                                status = NotificationStatus.ERROR,
                                message = Constants.NOTIFICATION_ERROR_MESSAGE,
                                visible = true
                            )
                        )
                    } else {
                        if (response.httpStatusCode == HttpStatusCode.NoContent) {
                            removeUserFromUserList()
                        } else if (response.httpStatusCode == HttpStatusCode.Found) {
                            toBeDeletedUserState = response.userDeletionState!!
                            openUserDeletionConflictDialog = true
                        }
                    }
                    isSending = false
                    spinnerMessage = ""
                }

            } else {
                deleteUserEmail = ""
            }
            showConfirmationDialog = false
        }
    }
    AlertNotifications {
        open = notificationState.visible
        status = notificationState.status
        message = notificationState.message
        closeNotification = {
            setNotificationState(
                notificationState.copy(
                    visible = false
                )
            )
        }
    }
    DialogSpinner {
        open = isSending
        message = spinnerMessage
    }
    Dialog {
        PaperProps = jso {
            className = CommonStyles.DIALOG_BORDER_RADIUS.cssClass
        }
        open = openUserDeletionConflictDialog
        fullWidth = true
        DialogTitle {
            Typography {
                component = ReactHTML.h5
                variant = TypographyVariant.h5
                align = TypographyAlign.center
                +"User cannot be deleted"
            }
        }
        DialogContent {
            DialogContentText {
                sx {
                    marginLeft = 10.px
                }
                Typography {
                    variant = TypographyVariant.h6
                    +"${toBeDeletedUserState.username} has actions linked with his/her account."
                }
                Typography {
                    sx {
                        marginTop = 10.px
                    }
                    variant = TypographyVariant.body1
                    +"Number of Actions Assigned: ${toBeDeletedUserState.actionAssigned}"
                }
                Typography {
                    variant = TypographyVariant.body1
                    +"Number of Actions In Progress: ${toBeDeletedUserState.actionInProgress}"
                }
                Typography {
                    sx {
                        marginTop = 10.px
                    }
                    variant = TypographyVariant.h6
                    +"Click on the appropriate button below to continue."
                }
            }
            DialogActions {
                sx {
                    alignItems = AlignItems.center
                    justifyContent = JustifyContent.center
                }
                Box {
                    className = UserManagementStyles.DELETION_CONFLICT_BOX.cssClass
                    Button {
                        fullWidth = true
                        variant = ButtonVariant.contained
                        sx {
                            textTransform = TextTransform.capitalize
                        }
                        disabled = isSubmitting
                        if (isSubmitting) +"Assigning actions and deleting user..." else +"Assign Actions To Yourself And Delete User"
                        onClick = {
                            mainScope.launch {
                                isSubmitting = true
                                val status = reassignActionsToAdminAndDeleteUser(toBeDeletedUserState.emailAddress)
                                if (status == ApiCallStatus.SUCCESS) {
                                    removeUserFromUserList()
                                    openUserDeletionConflictDialog = false
                                } else {
                                    setNotificationState(
                                        notificationState.copy(
                                            status = NotificationStatus.ERROR,
                                            message = Constants.NOTIFICATION_ERROR_MESSAGE,
                                            visible = true
                                        )
                                    )
                                }
                                isLoading = false
                            }
                        }
                    }
                    Button {
                        className = UserManagementStyles.DELETION_CONFLICT_BUTTON.cssClass
                        fullWidth = true
                        variant = ButtonVariant.contained
                        disabled = isSubmitting
                        +"Re-assign Actions to others and delete user later"
                        onClick = {
                            globalState.updateUserActionsData(
                                UserActionsData(
                                    username = toBeDeletedUserState.username,
                                    email = toBeDeletedUserState.emailAddress,
                                    navigatedFromUserManagement = true
                                )
                            )
                            navigate.invoke("/action-management")
                        }
                    }
                    Button {
                        className = UserManagementStyles.DELETION_CONFLICT_BUTTON.cssClass
                        fullWidth = true
                        variant = ButtonVariant.contained
                        +"Cancel User Deletion"
                        disabled = isSubmitting
                        onClick = {
                            deleteUserEmail = ""
                            setStateForToBeDeletedUser()
                            openUserDeletionConflictDialog = false
                        }
                    }
                }
            }
        }
    }
}