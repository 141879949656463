package com.ecosave.watch.portal.components.esg.occupationhealthandsafety

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val GRI_403_2 = FC<EsgSubFormComponentProps> { props ->
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.occupationalHealthAndSafety?.let { state ->
        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_403_2
        }

        Box {
            label {
                +"Description of the processes used to identify workrelated hazards and "
                +"assess risks on a routine and non-routine basis and to apply the hierarchy "
                +"of controls in order to eliminate hazards and minimize risks."
            }
            ESGTextAreaFieldWrapper {
                name = state::section4032A1.name
                value = state.section4032A1
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
        Box {
            className = ESGCommonStyles.LEFT_INDENT.cssClass
            label {
                +"Explain how the organization ensures the quality of these processes, "
                +"including the competency of persons who carry them out."
            }
            ESGTextAreaFieldWrapper {
                name = state::section4032A2.name
                value = state.section4032A2
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
        Box {
            className = ESGCommonStyles.LEFT_INDENT.cssClass
            label {
                +"Explain how the results of these processes are used to evaluate and "
                +"continually improve the occupational health and safety management system."
            }
            ESGTextAreaFieldWrapper {
                name = state::section4032A3.name
                value = state.section4032A3
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
        Box {
            label {
                +"A description of the processes for workers to report work-related hazards "
                +"and hazardous situations, and an explanation of how workers are "
                +"protected against reprisals."
            }
            ESGTextAreaFieldWrapper {
                name = state::section4032A4.name
                value = state.section4032A4
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
        Box {
            label {
                +"A description of the policies and processes for workers to remove "
                +"themselves from work situations that they believe could cause injury or ill "
                +"health, and an explanation of how workers are protected against reprisals."
            }
            ESGTextAreaFieldWrapper {
                name = state::section4032A5.name
                value = state.section4032A5
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
        Box {
            label {
                +"A description of the processes used to investigate work-related incidents, "
                +"including the processes to identify hazards and assess risks relating to "
                +"the incidents, to determine corrective actions using the hierarchy of "
                +"controls, and to determine improvements needed in the occupational "
                +"health and safety management system."
            }
            ESGTextAreaFieldWrapper {
                name = state::section4032A6.name
                value = state.section4032A6
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}