package com.ecosave.watch.portal.models.esg
import kotlinx.serialization.Serializable
@Serializable
data class BiodiversityState(
    var biodiversityGeographicalLocationList: MutableList<BiodiversityGeographicalLocation> = mutableListOf(BiodiversityGeographicalLocation()),
    var significantImpactsBiodiversityList: MutableList<SignificantImpactsBiodiversity> = mutableListOf(SignificantImpactsBiodiversity()),
    var significantDirectAndIndirectList: MutableList<SignificantDirectAndIndirect> = mutableListOf(SignificantDirectAndIndirect()),
    var habitatsProtectedOrRestoredList: MutableList<HabitatsProtectedOrRestored> = mutableListOf(HabitatsProtectedOrRestored()),


    var section3042A12: String? = null,
    var section3042A13: String? = null,
    var section3042A14: String? = null,
    var section3042A15: String? = null,
    var section3042Field1: String? = null,
    var section3042Field2: String? = null,
    var section3042Field3: String? = null,

    var section3044A1: String? = null,
    var section3044A2: String? = null,
    var section3044A3: String? = null,
    var section3044A4: String? = null,
    var section3044A5: String? = null,
    var section3044A6: String? = null,

    var section3_3_304A1: String? = null,
    var section3_3_304A2: String? = null,
    var section3_3_304A3: String? = null,
    var section3_3_304A4: String? = null,
    var section3_3_304A5: String? = null,
    var section3_3_304A6: String? = null
)

@Serializable
data class BiodiversityGeographicalLocation(
    var section3041A1: String? = null,
    var section3041A2: String? = null,
    var section3041A3: String? = null,
    var section3041A4: String? = null,
    var section3041A5: Long? = null,
    var section3041A6: String? = null,
    var section3041A7: String? = null,
    var section3041A8: String? = null,
) : DynamicTableItemKey()

@Serializable
data class HabitatsProtectedOrRestored(
    var section3043A1: String? = null,
    var section3043A2: Double? = null,
    var section3043A3: String? = null,
    var section3043A4: String? = null,
    var section3043A5: String? = null,
    var section3043A6: String? = null,
) : DynamicTableItemKey()

@Serializable
data class SignificantImpactsBiodiversity(
    var section3042A1: String? = null,
    var section3042A2: String? = null,
    var section3042A3: String? = null,
    var section3042A4: String? = null,
    var section3042A5: String? = null,
    var section3042A6: String? = null,
    var section3042A7: String? = null,
) : DynamicTableItemKey()

@Serializable
data class SignificantDirectAndIndirect(
    var section3042A8: String? = null,
    var section3042A9: String? = null,
    var section3042A10: String? = null,
    var section3042A11: String? = null,
) : DynamicTableItemKey()