package com.ecosave.watch.portal.helpers.facilitymanagement

enum class BuildingType(val description: String, val buildingCategoryList: List<BuildingCategory>) {
    BANK_BRANCH("Bank Branch", listOf(BuildingCategory.BANKING_FINANCIAL)),
    FINANCIAL_OFFICE("Financial Office", listOf(BuildingCategory.BANKING_FINANCIAL)),

    ADULT_EDUCATION("Adult Education", listOf(BuildingCategory.EDUCATION)),
    COLLEGE_UNIVERSITY("College/University", listOf(BuildingCategory.EDUCATION)),
    K_12_SCHOOL("K-12 School", listOf(BuildingCategory.EDUCATION)),
    PRE_SCHOOL_DAYCARE("Pre-school/Daycare", listOf(BuildingCategory.EDUCATION)),
    VOCATIONAL_SCHOOL("Vocational School", listOf(BuildingCategory.EDUCATION)),
    OTHER_EDUCATION("Other - Education", listOf(BuildingCategory.EDUCATION)),

    AQUARIUM("Aquarium", listOf(BuildingCategory.ENTERTAINMENT_PUBLIC)),
    BAR_NIGHTCLUB("Bar/Nightclub", listOf(BuildingCategory.ENTERTAINMENT_PUBLIC, BuildingCategory.FOOD)),
    BOWLING_ALLEY("Bowling Alley", listOf(BuildingCategory.ENTERTAINMENT_PUBLIC)),
    CASINO("Casino", listOf(BuildingCategory.ENTERTAINMENT_PUBLIC)),
    CONVENTION_CENTER("Convention Center", listOf(BuildingCategory.ENTERTAINMENT_PUBLIC)),
    FITNESS_CENTER("Fitness Center/Health Club/Gym", listOf(BuildingCategory.ENTERTAINMENT_PUBLIC)),
    ICE_CURLING_RINK("Ice/Curling Rink", listOf(BuildingCategory.ENTERTAINMENT_PUBLIC)),
    INDOOR_ARENA("Indoor Arena", listOf(BuildingCategory.ENTERTAINMENT_PUBLIC)),
    MOVIE_THEATER("Movie Theater", listOf(BuildingCategory.ENTERTAINMENT_PUBLIC)),
    MUSEUM("Museum", listOf(BuildingCategory.ENTERTAINMENT_PUBLIC)),
    PERFORMING_ARTS("Performing Arts", listOf(BuildingCategory.ENTERTAINMENT_PUBLIC)),
    RACE_TRACK("Race Track", listOf(BuildingCategory.ENTERTAINMENT_PUBLIC)),
    ROLLER_RINK("Roller Rink", listOf(BuildingCategory.ENTERTAINMENT_PUBLIC)),
    STADIUM_CLOSED("Stadium Closed", listOf(BuildingCategory.ENTERTAINMENT_PUBLIC)),
    STADIUM_OPEN("Stadium Open", listOf(BuildingCategory.ENTERTAINMENT_PUBLIC)),
    SWIMMING_POOL("Swimming Pool", listOf(BuildingCategory.ENTERTAINMENT_PUBLIC)),
    ZOO("Zoo", listOf(BuildingCategory.ENTERTAINMENT_PUBLIC)),
    OTHER_ENTERTAINMENT_PUBLIC_ASSEMBLY(
        "Other - Entertainment/Public Assembly",
        listOf(BuildingCategory.ENTERTAINMENT_PUBLIC)
    ),
    OTHER_RECREATION("Other - Recreation", listOf(BuildingCategory.ENTERTAINMENT_PUBLIC)),
    OTHER_STADIUM("Other - Stadium", listOf(BuildingCategory.ENTERTAINMENT_PUBLIC)),

    FAST_FOOD_RESTAURANT("Fast Food Restaurant", listOf(BuildingCategory.FOOD)),
    FOOD_SALES("Food Sales", listOf(BuildingCategory.FOOD)),
    FOOD_SERVICE("Food Service", listOf(BuildingCategory.FOOD)),
    RESTAURANT("Restaurant", listOf(BuildingCategory.FOOD)),

    SUPERMARKET_GROCERY_STORE("Supermarket/Grocery Store", listOf(BuildingCategory.FOOD, BuildingCategory.RETAIL)),
    WHOLESALE_CLUB_SUPER_CENTER("Wholesale Club/Supercenter", listOf(BuildingCategory.FOOD)),
    OTHER_RESTAURANT_BAR("Other - Restaurant/Bar", listOf(BuildingCategory.FOOD)),

    HOSPITAL_GENERAL_MEDICAL_SURGICAL("Hospital General Medical & Surgical", listOf(BuildingCategory.HEALTHCARE)),
    MEDICAL_OFFICE("Medical Office", listOf(BuildingCategory.HEALTHCARE, BuildingCategory.OFFICE)),
    OUTPATIENT_REHABILITATION_PHYSICAL_THERAPY(
        "Outpatient Rehabilitation/Physical Therapy",
        listOf(BuildingCategory.HEALTHCARE)
    ),
    RESIDENTIAL_CARE_FACILITY("Residential Care Facility", listOf(BuildingCategory.HEALTHCARE)),
    URGENT_CARE_CLINIC_OTHER_OUTPATIENT("Urgent Care/Clinic/Other Outpatient", listOf(BuildingCategory.HEALTHCARE)),
    OTHER_SPECIALTY_HOSPITAL("Other - Specialty Hospital", listOf(BuildingCategory.HEALTHCARE)),

    BARRACKS("Barracks", listOf(BuildingCategory.LODGING_RESIDENTIAL)),
    HOTEL("Hotel", listOf(BuildingCategory.LODGING_RESIDENTIAL)),
    MULTIFAMILY_HOUSING("Multifamily Housing", listOf(BuildingCategory.LODGING_RESIDENTIAL)),

    RESIDENCE_HALL_DORMITORY("Residence Hall/Dormitory", listOf(BuildingCategory.LODGING_RESIDENTIAL)),
    SENIOR_LIVING_COMMUNITY(
        "Senior Living Community",
        listOf(BuildingCategory.LODGING_RESIDENTIAL, BuildingCategory.HEALTHCARE)
    ),
    SINGLE_FAMILY_HOME("Single Family Home", listOf(BuildingCategory.LODGING_RESIDENTIAL)),
    OTHER_LODGING_RESIDENTIAL("Other - Lodging/Residential", listOf(BuildingCategory.LODGING_RESIDENTIAL)),

    MANUFACTURING_INDUSTRIAL_PLANT("Manufacturing/Industrial Plant", listOf(BuildingCategory.MANUFACTURING_INDUSTRIAL)),

    MIXED_USE_PROPERTY("Mixed Use Property", listOf(BuildingCategory.MIXED_USE)),

    OFFICE("Office", listOf(BuildingCategory.OFFICE)),
    VETERINARY_OFFICE("Veterinary Office", listOf(BuildingCategory.OFFICE)),
    OTHER_OFFICE("Other - Office", listOf(BuildingCategory.OFFICE)),

    PARKING("Parking", listOf(BuildingCategory.PARKING)),

    COURTHOUSE("Courthouse", listOf(BuildingCategory.PUBLIC_SERVICES)),

    FIRE_STATION("Fire Station", listOf(BuildingCategory.PUBLIC_SERVICES)),
    LIBRARY("Library", listOf(BuildingCategory.PUBLIC_SERVICES)),
    MAILING_CENTER_POST_OFFICE("Mailing Center/Post Office", listOf(BuildingCategory.PUBLIC_SERVICES)),
    POLICE_STATION("Police Station", listOf(BuildingCategory.PUBLIC_SERVICES)),
    PRISON_INCARCERATION(
        "Prison/Incarceration",
        listOf(BuildingCategory.PUBLIC_SERVICES, BuildingCategory.LODGING_RESIDENTIAL)
    ),
    SOCIAL_MEETING_HALL(
        "Social/Meeting Hall",
        listOf(BuildingCategory.PUBLIC_SERVICES, BuildingCategory.ENTERTAINMENT_PUBLIC)
    ),
    TRANSPORTATION_TERMINAL_STATION("Transportation Terminal/Station", listOf(BuildingCategory.PUBLIC_SERVICES)),

    WASTEWATER_TREATMENT_PLANT(
        "Wastewater Treatment Plant",
        listOf(BuildingCategory.PUBLIC_SERVICES, BuildingCategory.UTILITY)
    ),
    OTHER_PUBLIC_SERVICE("Other - Public Service", listOf(BuildingCategory.PUBLIC_SERVICES)),

    WORSHIP_FACILITY("Worship Facility", listOf(BuildingCategory.RELIGIOUS_WORSHIP)),

    AUTOMOBILE_DEALERSHIP("Automobile Dealership", listOf(BuildingCategory.RETAIL)),
    CONVENIENCE_STORE_WITH_GAS_STATION(
        "Convenience Store with Gas Station",
        listOf(BuildingCategory.RETAIL, BuildingCategory.FOOD)
    ),
    CONVENIENCE_STORE_WITHOUT_GAS_STATION(
        "Convenience Store without Gas Station",
        listOf(BuildingCategory.RETAIL, BuildingCategory.FOOD)
    ),
    ENCLOSED_MALL("Enclosed Mall", listOf(BuildingCategory.RETAIL)),
    LIFESTYLE_CENTER("Lifestyle Center", listOf(BuildingCategory.RETAIL)),
    RETAIL_STORE("Retail Store", listOf(BuildingCategory.RETAIL)),
    STRIP_MALL("Strip Mall", listOf(BuildingCategory.RETAIL)),
    WHOLESALE_CLUB_SUPERCENTER("Wholesale Club/Supercenter", listOf(BuildingCategory.RETAIL)),
    OTHER_RETAIL_MALL("Other - Retail/Mall", listOf(BuildingCategory.RETAIL)),

    DATA_CENTER("Data Center", listOf(BuildingCategory.SERVICES, BuildingCategory.TECHNOLOGY_SCIENCE)),
    PERSONAL_SERVICES("Personal Services (Health/Beauty, Dry Cleaning, etc)", listOf(BuildingCategory.SERVICES)),
    REPAIR_SERVICES("Repair Services (Vehicle, Shoe, Locksmith, etc)", listOf(BuildingCategory.SERVICES)),
    OTHER_SERVICES("Other - Services", listOf(BuildingCategory.SERVICES)),

    LABORATORY("Laboratory", listOf(BuildingCategory.TECHNOLOGY_SCIENCE)),
    OTHER_TECHNOLOGY_SCIENCE("Other - Technology/Science", listOf(BuildingCategory.TECHNOLOGY_SCIENCE)),

    DRINKING_WATER_TREATMENT_DISTRIBUTION(
        "Drinking Water Treatment & Distribution",
        listOf(BuildingCategory.UTILITY, BuildingCategory.PUBLIC_SERVICES)
    ),
    POWER_STATION("Energy/Power Station", listOf(BuildingCategory.UTILITY)),
    OTHER_UTILITY("Other - Utility", listOf(BuildingCategory.UTILITY)),

    SELF_STORAGE_FACILITY("Self-Storage Facility", listOf(BuildingCategory.WAREHOUSE_STORAGE)),
    DISTRIBUTION_CENTER("Distribution Center", listOf(BuildingCategory.WAREHOUSE_STORAGE)),
    NON_REFRIGERATED_WAREHOUSE("Non-Refrigerated Warehouse", listOf(BuildingCategory.WAREHOUSE_STORAGE)),
    REFRIGERATED_WAREHOUSE("Refrigerated Warehouse", listOf(BuildingCategory.WAREHOUSE_STORAGE));

    companion object {
        fun getAllBuildingTypes(buildingCategory: BuildingCategory) =
            values().filter { buildingType ->
                buildingType.buildingCategoryList.contains(buildingCategory)
            }
    }
}