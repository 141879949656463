package com.ecosave.watch.portal.components.energymanagement

import com.ecosave.watch.portal.components.common.ApiFailedMessage
import com.ecosave.watch.portal.helpers.billing.UtilityType
import com.ecosave.watch.portal.helpers.common.removeDecimalAndAddComma
import com.ecosave.watch.portal.models.energymanagement.SummaryData
import com.ecosave.watch.portal.helpers.energymanagement.UtilityUnit
import mui.material.Paper
import mui.material.TableContainer
import mui.material.Table
import mui.material.TableRow
import mui.material.TableCell
import mui.material.TableHead
import mui.material.TableBody
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML
import web.cssom.*
import com.ecosave.watch.portal.styles.EnergyManagementStyles as styles

external interface UtilitySumarySectionProps: Props {
    var utilityType: UtilityType
    var firstDuration: String
    var seconDuration: String
    var first: SummaryData
    var second: SummaryData
    var firstColor: String
    var secondColor: String
    var hidden: Boolean
    var unit: UtilityUnit
    var keyPoint: String
    var dataError: Boolean
}

val UtilitySummarySection = FC<UtilitySumarySectionProps> {props ->
    Paper {
        className = if (props.hidden) {
            styles.CHART_SUMMARY_SECTION_ONE.cssClass
        } else {
            styles.CHART_SUMMARY_SECTION_TWO.cssClass
        }
        Typography {
            sx {
                marginTop = 20.px
                textAlign = TextAlign.center
            }
            variant = TypographyVariant.h5
            component = ReactHTML.h5
            +"${props.utilityType.type} ${props.keyPoint} Summary"
        }
        if (props.dataError) {
            ApiFailedMessage {

            }
        } else {
            TableContainer {
                sx {
                    marginTop = 15.px
                }
                Table {
                    TableHead {
                        TableRow {
                            TableCell {
                                sx {
                                    padding = 0.px
                                }
                            }
                            TableCell {
                                sx {
                                    color = Color(props.firstColor)
                                    padding = 0.px
                                }
                                +props.firstDuration
                            }
                            if (!props.hidden) {
                                TableCell {
                                    sx {
                                        color = Color(props.secondColor)
                                        padding = 0.px
                                    }
                                    +props.seconDuration
                                }
                            }
                        }
                    }
                    TableBody {
                        TableRow {
                            hover = true
                            TableCell {
                                className = styles.NON_BORDER_CELL.cssClass
                                +"Sum of range"
                            }
                            TableCell {
                                className = styles.NON_BORDER_CELL.cssClass
                                Typography {
                                    sx {
                                        color = Color(props.firstColor)
                                    }
                                    +"${props.unit.prefix}${removeDecimalAndAddComma(props.first.sum)} ${props.unit.surfix}"
                                }
                            }
                            if (!props.hidden) {
                                TableCell {
                                    className = styles.NON_BORDER_CELL.cssClass
                                    Typography {
                                        sx {
                                            color = Color(props.secondColor)
                                        }
                                        +"${props.unit.prefix}${removeDecimalAndAddComma(props.second.sum)} ${props.unit.surfix}"
                                    }
                                }
                            }
                        }
                        TableRow {
                            hover = true
                            TableCell {
                                className = styles.NON_BORDER_CELL.cssClass
                                +"Highest Month"
                            }
                            TableCell {
                                className = styles.NON_BORDER_CELL.cssClass
                                Typography {
                                    sx {
                                        color = Color(props.firstColor)
                                    }
                                    +props.first.highestMonth
                                }
                                Typography {
                                    sx {
                                        color = Color(props.firstColor)
                                    }
                                    +"${props.unit.prefix}${removeDecimalAndAddComma(props.first.highestAmount)} ${props.unit.surfix}"
                                }
                            }
                            if (!props.hidden) {
                                TableCell {
                                    className = styles.NON_BORDER_CELL.cssClass
                                    Typography {
                                        sx {
                                            color = Color(props.secondColor)
                                        }
                                        +props.second.highestMonth
                                    }
                                    Typography {
                                        sx {
                                            color = Color(props.secondColor)
                                        }
                                        +"${props.unit.prefix}${removeDecimalAndAddComma(props.second.highestAmount)} ${props.unit.surfix}"
                                    }
                                }
                            }
                        }
                        TableRow {
                            hover = true
                            TableCell {
                                className = styles.NON_BORDER_CELL.cssClass
                                +"Last Month"
                            }
                            TableCell {
                                className = styles.NON_BORDER_CELL.cssClass
                                Typography {
                                    sx {
                                        color = Color(props.firstColor)
                                    }
                                    +props.first.lastMonth
                                }
                                Typography {
                                    sx {
                                        color = Color(props.firstColor)
                                    }
                                    +"${props.unit.prefix}${removeDecimalAndAddComma(props.first.lastAmount)} ${props.unit.surfix}"
                                }
                            }
                            if (!props.hidden) {
                                TableCell {
                                    className = styles.NON_BORDER_CELL.cssClass
                                    Typography {
                                        sx {
                                            color = Color(props.secondColor)
                                        }
                                        +props.second.lastMonth
                                    }
                                    Typography {
                                        sx {
                                            color = Color(props.secondColor)
                                        }
                                        +"${props.unit.prefix}${removeDecimalAndAddComma(props.second.lastAmount)} ${props.unit.surfix}"
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}