package com.ecosave.watch.portal.components.privacypolicy

import com.ecosave.watch.portal.components.privacypolicy.TermsOfServiceConstants.ecosaveTosEmail
import com.ecosave.watch.portal.helpers.privacypolicy.getMailToWithSubject
import react.FC
import react.Props

val Miscellaneous = FC<Props> {
    HeadingWrapper {
        heading = "18. Miscellaneous"
    }
    HeadingWrapper {
        heading = "18.1. Non-Assignability"
        subHeading = true
    }
    TypographyWrapper {
        +"Ecosave may assign or delegate these Terms of Service and/or the Privacy Statement, in whole or in part, to any person or entity at any time with or without your consent, including the license grant in Section 11.2. You may not delegate, assign, or transfer this Agreement or any of its rights and obligations under this Agreement, and any attempt to do so will be void."
    }
    HeadingWrapper {
        heading = "18.2. Governing Law"
        subHeading = true
    }
    TypographyWrapper {
        +"Except to the extent applicable law provides otherwise, this Agreement between you and Ecosave and any access to or use of the Website or the Service are governed by the federal laws of the United States of America and the laws of the Commonwealth of Pennsylvania, without regard to conflict of law provisions. You and Ecosave agree to submit to the exclusive jurisdiction and venue of the courts located in the city of Philadelphia, Pennsylvania."
    }
    HeadingWrapper {
        heading = "18.3. Federal Use"
        subHeading = true
    }
    TypographyWrapper {
        +"The Service is provided to the U.S. Government as \"commercial items,\" \"commercial computer software,\" \"commercial computer software documentation,\" and \"technical data” with the same rights and restrictions generally applicable to the Service. If you or any Authorized User is using the Service on behalf of the U.S. Government and these terms fail to meet the U.S. Government's needs or are inconsistent in any respect with federal law, you and your Authorized Users must immediately discontinue use of the Service. The terms listed above are defined in the Federal Acquisition Regulation and the Defense Federal Acquisition Regulation Supplement."
    }
    HeadingWrapper {
        heading = "18.4. Import-Export Controls"
        subHeading = true
    }
    TypographyWrapper {
        +"In connection with this Agreement, you will comply with all applicable import, re-import, export, and re-export control laws and regulations, including the Export Administration Regulations, the International Traffic in Arms Regulations, and country-specific economic sanctions programs implemented by the Office of Foreign Assets Control. For clarity, you are solely responsible for compliance related to the manner in which you choose to use the Service, including the transfer and processing of Customer Data."
    }
    HeadingWrapper {
        heading = "18.5. Obligations of Parties"
        subHeading = true
    }
    TypographyWrapper {
        +"The parties expressly understand and agree that their relationship is that of independent contractors. Nothing in this Agreement will constitute one party as an employee, agent, joint venture partner, or servant of another."
    }
    TypographyWrapper {
        +"Ecosave will have no liability to you, Authorized Users, or third parties for any failure or delay in performing any obligation under this Agreement due to circumstances beyond its reasonable control, including without limitation acts of God or nature, fires, floods, strikes, civil disturbances or terrorism, or interruptions in power, communications, satellites, the Internet, or any other network that are beyond its reasonable control."
    }
    HeadingWrapper {
        heading = "18.6. Communications"
        subHeading = true
    }
    TypographyWrapper {
        +"Ecosave may send you, in electronic form, information about the service, additional information, and any information the law requires Ecosave to provide. You acknowledge and agree that Ecosave may provide notices to you by email at the address you specified in its Subscription or by access to a website that Ecosave identifies. Notices emailed to you will be deemed given and received when the email is sent. If you do not consent to receiving notices electronically, you and your Authorized Users must stop using the Service. (Please note that these provisions relate to the business relationship and are distinct from marketing and similar emails covered by the \"Opt-Out\" provisions of the Privacy Policy.) You may provide legal notices to Ecosave by email to "
        CustomLink {
            to = TermsOfServiceConstants.ecosaveLegalEmail.getMailToWithSubject("Legal")
            text = TermsOfServiceConstants.ecosaveLegalEmail
        }
        +". You must specify in all such notices that the notice is being given under this Agreement."
    }
    HeadingWrapper {
        heading = "18.7. Severability, No Waiver, and Survival"
        subHeading = true
    }
    TypographyWrapper {
        +"If any part of this Agreement is held invalid or unenforceable, that portion of the Agreement will be construed to reflect the parties' original intent. The remainder of the Agreement will remain in full force and effect. Any failure on the part of Ecosave to enforce any provision of this Agreement will not be considered a waiver of our right to enforce such provision. Our rights under this Agreement will survive any termination of this Agreement."
    }
    HeadingWrapper {
        heading = "18.8. Complete Agreement"
        subHeading = true
    }
    TypographyWrapper {
        +"These Terms of Service, together with the Privacy Policy, represent the complete and exclusive statement of the Agreement between you and Ecosave. This Agreement supersedes any proposal or prior agreements, oral or written, and any other communications between you and Ecosave relating to the subject matter of these terms."
    }
    TypographyWrapper {
        +"If any information posted on the Website conflicts with any provision of this Agreement, the applicable provision of this Agreement will control. Any terms and conditions of any other arrangement issued by you in connection with this Agreement, which are in addition to, inconsistent with, or different from the terms and conditions of this Agreement, will be of no force or effect. Any affiliate of yours will be deemed a third party for purposes of this Agreement. This Agreement can only be modified only by a written arrangement duly executed by authorized representatives of the parties."
    }
    HeadingWrapper {
        heading = "Contact Us"
    }
    TypographyWrapper {
        +"If you have any questions or concerns about these Terms of Service, please email us at "
        CustomLink {
            to = ecosaveTosEmail.getMailToWithSubject("Terms Of Service")
            text = ecosaveTosEmail
        }
    }
}