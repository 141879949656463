package com.ecosave.watch.portal.helpers.assetmanagement

import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.models.assetmanagement.AssetState
import com.ecosave.watch.portal.models.assetmanagement.AssetValidationState
import react.ReactNode
import react.StateSetter

fun validateFacility(
    state: AssetState,
    validation: AssetValidationState,
    setValidation: StateSetter<AssetValidationState>
) {
    if (state.facilityId.isBlank()) {
        validation.facilityErrorState = true
        validation.facilityErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.facilityErrorState = false
        validation.facilityErrorMessage = null
    }

    updateAssetValidationState(validation, setValidation)
}

fun validateEquipmentId(
    state: AssetState,
    validation: AssetValidationState,
    setValidation: StateSetter<AssetValidationState>
) {
    if (state.equipmentId.isBlank()) {
        validation.equipmentIdErrorState = true
        validation.equipmentIdErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.equipmentIdErrorState = false
        validation.equipmentIdErrorMessage = null
    }

    updateAssetValidationState(validation, setValidation)
}

fun validateEquipmentType(
    state: AssetState,
    validation: AssetValidationState,
    setValidation: StateSetter<AssetValidationState>
) {
    if (state.equipmentType.isBlank()) {
        validation.equipmentTypeErrorState = true
        validation.equipmentTypeErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.equipmentTypeErrorState = false
        validation.equipmentTypeErrorMessage = null
    }

    updateAssetValidationState(validation, setValidation)
}

fun validateEquipmentLocation(
    state: AssetState,
    validation: AssetValidationState,
    setValidation: StateSetter<AssetValidationState>
) {
    if (state.equipmentLocation.isBlank()) {
        validation.equipmentLocationErrorState = true
        validation.equipmentLocationErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.equipmentLocationErrorState = false
        validation.equipmentLocationErrorMessage = null
    }

    updateAssetValidationState(validation, setValidation)
}

fun validateQuantity(
    state: AssetState,
    validation: AssetValidationState,
    setValidation: StateSetter<AssetValidationState>
) {
    if (state.quantity == null) {
        validation.quantityErrorState = true
        validation.quantityErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.quantityErrorState = false
        validation.quantityErrorMessage = null
    }

    updateAssetValidationState(validation, setValidation)
}

fun validateManufacturer(
    state: AssetState,
    validation: AssetValidationState,
    setValidation: StateSetter<AssetValidationState>
) {
    if (state.manufacturer.isBlank()) {
        validation.manufacturerErrorState = true
        validation.manufacturerErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.manufacturerErrorState = false
        validation.manufacturerErrorMessage = null
    }

    updateAssetValidationState(validation, setValidation)
}

fun validateEquipmentMaintenanceProvider(
    state: AssetState,
    validation: AssetValidationState,
    setValidation: StateSetter<AssetValidationState>
) {
    if (state.equipmentMaintenanceProvider.isBlank()) {
        validation.equipmentMaintenanceProviderErrorState = true
        validation.equipmentMaintenanceProviderErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.equipmentMaintenanceProviderErrorState = false
        validation.equipmentMaintenanceProviderErrorMessage = null
    }

    updateAssetValidationState(validation, setValidation)
}

fun validateModel(
    state: AssetState,
    validation: AssetValidationState,
    setValidation: StateSetter<AssetValidationState>
) {
    if (state.model.isBlank()) {
        validation.modelErrorState = true
        validation.modelErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.modelErrorState = false
        validation.modelErrorMessage = null
    }

    updateAssetValidationState(validation, setValidation)
}

fun validateSerialNumber(
    state: AssetState,
    validation: AssetValidationState,
    setValidation: StateSetter<AssetValidationState>
) {
    if (state.serialNumber.isBlank()) {
        validation.serialNumberErrorState = true
        validation.serialNumberErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.serialNumberErrorState = false
        validation.serialNumberErrorMessage = null
    }

    updateAssetValidationState(validation, setValidation)
}

fun validateSystemType(
    state: AssetState,
    validation: AssetValidationState,
    setValidation: StateSetter<AssetValidationState>
) {
    if (state.systemType.isBlank()) {
        validation.systemTypeErrorState = true
        validation.systemTypeErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.systemTypeErrorState = false
        validation.systemTypeErrorMessage = null
    }

    updateAssetValidationState(validation, setValidation)
}

fun validateYearInstalled(
    state: AssetState,
    validation: AssetValidationState,
    setValidation: StateSetter<AssetValidationState>
) {
    if (state.yearInstalled.isBlank()) {
        validation.yearInstalledErrorState = true
        validation.yearInstalledErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.yearInstalledErrorState = false
        validation.yearInstalledErrorMessage = null
    }

    updateAssetValidationState(validation, setValidation)
}

fun validateWarrantyEndDate(
    state: AssetState,
    validation: AssetValidationState,
    setValidation: StateSetter<AssetValidationState>
) {
    if (state.warrantyEndDate.isBlank()) {
        validation.warrantyEndDateErrorState = true
        validation.warrantyEndDateErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.warrantyEndDateErrorState = false
        validation.warrantyEndDateErrorMessage = null
    }

    updateAssetValidationState(validation, setValidation)
}

fun validateOmDocument(
    state: AssetState,
    validation: AssetValidationState,
    setValidation: StateSetter<AssetValidationState>
) {
    if (state.selectedFile != null && state.selectedFile.size > 26214400) {
        validation.omDocumentErrorState = true
        validation.omDocumentErrorMessage = ReactNode("File size limit: 25 MB or less.")
    } else {
        validation.omDocumentErrorState = false
        validation.omDocumentErrorMessage = null
    }

    updateAssetValidationState(validation, setValidation)
}

fun updateAssetValidationState(
    validation: AssetValidationState,
    setValidation: StateSetter<AssetValidationState>
) {
    setValidation(
        validation.copy()
    )
}

fun isAssetFormValid(
    state: AssetState,
    validation: AssetValidationState,
    setValidation: StateSetter<AssetValidationState>
): Boolean {
    validateAssetForm(state, validation, setValidation)
    return isAssetFormFieldsValid(validation)
}

fun validateAssetForm(
    state: AssetState,
    validation: AssetValidationState,
    setValidation: StateSetter<AssetValidationState>
) {
    validateFacility(state, validation, setValidation)
    validateEquipmentId(state, validation, setValidation)
    validateEquipmentType(state, validation, setValidation)
    validateEquipmentLocation(state, validation, setValidation)
    validateQuantity(state, validation, setValidation)
    validateManufacturer(state, validation, setValidation)
    validateEquipmentMaintenanceProvider(state, validation, setValidation)
    validateModel(state, validation, setValidation)
    validateSerialNumber(state, validation, setValidation)
    validateSystemType(state, validation, setValidation)
    validateYearInstalled(state, validation, setValidation)
    validateWarrantyEndDate(state, validation, setValidation)
    validateOmDocument(state, validation, setValidation)
}

fun isAssetFormFieldsValid(validation: AssetValidationState): Boolean {

    return !(validation.facilityErrorState ||
            validation.equipmentIdErrorState ||
            validation.equipmentIdErrorState ||
            validation.equipmentLocationErrorState ||
            validation.quantityErrorState ||
            validation.manufacturerErrorState ||
            validation.equipmentMaintenanceProviderErrorState ||
            validation.modelErrorState ||
            validation.serialNumberErrorState ||
            validation.systemTypeErrorState ||
            validation.yearInstalledErrorState ||
            validation.warrantyEndDateErrorState ||
            validation.omDocumentErrorState)
}