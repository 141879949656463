package com.ecosave.watch.portal.components.utilityaccounts

import mui.material.Box
import mui.material.Typography
import mui.material.TypographyAlign
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import web.cssom.FontWeight
import web.cssom.Margin
import web.cssom.px

external interface InputFormTitleSectorProps : Props {
    var title: String
    var subtitle: String
}

val InputFormTitleSector = FC<InputFormTitleSectorProps> { props ->
    Box {
        Typography {
            sx {
                margin = Margin(10.px, 0.px, (-10).px, 0.px)
                fontWeight = FontWeight.bold
            }
            variant = TypographyVariant.h5
            align = TypographyAlign.center
            +props.title
        }
    }
    Box {
        sx {
            marginLeft = 30.px
            marginBottom = 20.px
        }
        Typography {
            sx {
                margin = Margin(10.px, 0.px, (-10).px, 0.px)
            }
            variant = TypographyVariant.h6
            align = TypographyAlign.left
            +props.subtitle
        }
    }
}

