package com.ecosave.watch.portal.components.energymanagement

import com.ecosave.watch.portal.components.common.ApiFailedMessage
import com.ecosave.watch.portal.components.common.EmptyDataMessage
import com.ecosave.watch.portal.components.common.Loading
import com.ecosave.watch.portal.components.common.TableHeader
import com.ecosave.watch.portal.helpers.common.removeDecimalAndAddComma
import com.ecosave.watch.portal.helpers.energymanagement.getUnitForUtilityType
import com.ecosave.watch.portal.models.energymanagement.TotalEnergyUsageNCO2
import mui.material.Box
import mui.material.Paper
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableCellAlign
import mui.material.TableContainer
import mui.material.TableHead
import mui.material.TableRow
import mui.material.Typography
import mui.system.sx
import react.FC
import react.Props
import web.cssom.px
import com.ecosave.watch.portal.styles.EnergyManagementStyles as styles

external interface EnergySummaryTableProps : Props {
    var energyData: List<TotalEnergyUsageNCO2>
    var dataFetched: Boolean
    var dataError: Boolean
}

val EnergySummaryTable = FC<EnergySummaryTableProps> { props ->
    val totalValue = TotalEnergyUsageNCO2()
    for (item in props.energyData) {
        totalValue.utilityCost = totalValue.utilityCost + item.utilityCost
        totalValue.utilityUsage = totalValue.utilityUsage + item.utilityUsage
        totalValue.utilityCo2Emission = totalValue.utilityCo2Emission + item.utilityCo2Emission
    }
    Paper {
        className = styles.SUMMARY_TABLE.cssClass
        TableContainer {
            Table {
                TableHead {
                    TableRow {
                        TableHeader {
                            align = TableCellAlign.right
                            tableHeaderName = "Utility"
                        }
                        TableHeader {
                            align = TableCellAlign.right
                            tableHeaderName = "Energy"
                        }
                        TableHeader {
                            align = TableCellAlign.right
                            tableHeaderName = "CO₂(Metric Tonnes)"
                        }
                        TableHeader {
                            align = TableCellAlign.right
                            tableHeaderName = "Cost($)"
                        }
                    }
                }
                if (props.dataFetched) {
                    if (props.dataError) {
                        TableBody {
                            TableRow {
                                TableCell {
                                    className = styles.SUMMARY_TABLE_TOTAL_CELL.cssClass
                                    colSpan = 12
                                    align = TableCellAlign.center
                                    Box {
                                        sx {
                                            height = 210.px
                                        }
                                        ApiFailedMessage {

                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        if (props.energyData.isNotEmpty()) {
                            TableBody {
                                for (energy in props.energyData) {
                                    TableRow {
                                        hover = true
                                        TableCell {
                                            align = TableCellAlign.right
                                            Typography {
                                                +energy.utilityType.type
                                            }
                                        }
                                        TableCell {
                                            align = TableCellAlign.right
                                            Typography {
                                                +"${removeDecimalAndAddComma(energy.utilityUsage)} ${getUnitForUtilityType(energy.utilityType.type)}"
                                            }
                                        }
                                        TableCell {
                                            align = TableCellAlign.right
                                            Typography {
                                                +removeDecimalAndAddComma(energy.utilityCo2Emission)
                                            }
                                        }
                                        TableCell {
                                            align = TableCellAlign.right
                                            Typography {
                                                +"$${removeDecimalAndAddComma(energy.utilityCost)}"
                                            }
                                        }
                                    }
                                }
                                TableRow {
                                    hover = true
                                    TableCell {
                                        align = TableCellAlign.right
                                        className = styles.SUMMARY_TABLE_TOTAL_CELL.cssClass
                                        Typography {
                                            +"Total"
                                        }
                                    }
                                    TableCell {
                                        className = styles.SUMMARY_TABLE_TOTAL_CELL.cssClass
                                        +""
                                    }
                                    TableCell {
                                        className = styles.SUMMARY_TABLE_TOTAL_CELL.cssClass
                                        align = TableCellAlign.right
                                        Typography {
                                            +removeDecimalAndAddComma(totalValue.utilityCo2Emission)
                                        }
                                    }
                                    TableCell {
                                        className = styles.SUMMARY_TABLE_TOTAL_CELL.cssClass
                                        align = TableCellAlign.right
                                        Typography {
                                            +"$${removeDecimalAndAddComma(totalValue.utilityCost)}"
                                        }
                                    }
                                }
                            }
                        } else {
                            TableBody {
                                TableRow {
                                    TableCell {
                                        className = styles.SUMMARY_TABLE_TOTAL_CELL.cssClass
                                        colSpan = 12
                                        align = TableCellAlign.center
                                        Box {
                                            sx {
                                                height = 210.px
                                            }
                                            EmptyDataMessage {

                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                } else {
                    TableBody {
                        TableRow {
                            TableCell {
                                className = styles.SUMMARY_TABLE_TOTAL_CELL.cssClass
                                colSpan = 12
                                align = TableCellAlign.center
                                Box {
                                    sx {
                                        height = 210.px
                                    }
                                    Loading {

                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}