package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class DiversityAndEqualOpportunityState(
    var section4051A1: Double? = null,
    var section4051A2: Double? = null,
    var section4051A3: Double? = null,
    var section4051A4: Double? = null,
    var section4051A5: Double? = null,
    var section4051A6: Double? = null,
    var section4051A7: Double? = null,
    var section4051A8: Double? = null,
    var section4051A9: Double? = null,
    var section4051A10: Double? = null,
    var section4051A11: Double? = null,
    var section4051A12: Double? = null,

    var section4051A13: Double? = null,
    var section4051A14: Double? = null,
    var section4051A15: Double? = null,
    var section4051A16: Double? = null,
    var section4051A17: Double? = null,
    var section4051A18: Double? = null,
    var section4051A19: Double? = null,
    var section4051A20: Double? = null,
    var section4051A21: Double? = null,

    var section4052Field1: String? = null,

    var basicSalaryAndRemunerationRatioList: MutableList<BasicSalaryAndRemunerationRatio> = mutableListOf(),

    var section3_3_405A1: String? = null,
    var section3_3_405A2: String? = null,
    var section3_3_405A3: String? = null,
    var section3_3_405A4: String? = null,
    var section3_3_405A5: String? = null,
    var section3_3_405A6: String? = null

)

@Serializable
data class BasicSalaryAndRemunerationRatio(
    var section4052A1: String? = null,
    var section4052A2: String? = null,
    var section4052A3: Double? = null,
    var section4052A4: Double? = null,
) : DynamicTableItemKey()
