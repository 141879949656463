package com.ecosave.watch.portal.helpers.usermanagement

import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.components.npm.isEmail
import com.ecosave.watch.portal.models.usermanagement.UserState
import react.ReactNode

fun validateUserEmail(
    state: UserState,
) {
    if (state.email.isBlank()) {
        state.emailErrorState = true
        state.emailErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else if (!isEmail(state.email)) {
        state.emailErrorState = true
        state.emailErrorMessage = ReactNode(Constants.VALID_EMAIL_ERROR_MESSAGE)
    } else {
        state.emailErrorState = false
        state.emailErrorMessage = null
    }

}

fun validateUserForm(state: MutableList<UserState>) {
    state.forEach { user ->
        validateUserEmail(user)
    }
}

fun isUserFormValid(state: MutableList<UserState>): Boolean {
    validateUserForm(state)
    return isUserFormFieldsValid(state)
}

fun isUserFormFieldsValid(state: MutableList<UserState>): Boolean {
    state.forEach { user ->
        if (user.emailErrorState) {
            return false
        }
    }
    return true
}


