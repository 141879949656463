package com.ecosave.watch.portal.components.resourcecenter.workorders

val observationSummarySentences = listOf(
    "The comprehensive Observation Summary serves as a valuable tool for monitoring all active observations across your facilities.",
    "Each observation is accompanied by its corresponding severity level, visually represented through intuitive color coding, enabling immediate identification of critical issues.",
    "Simply click the Filter button to refine your observations based on specific parameters.",
    "For further action, click Action Management. This will direct you to a dedicated page where you can manage assigned tasks related to these observations.",
)
val actionManagementOverviewSentences = listOf(
    "The Action Management Page complements the Observation Summary, enabling you to efficiently manage corrective actions.",
    "Utilize the checkboxes next to each action or choose the \"Select All\" checkbox at the top for bulk selection.",
    "Once actions are chosen, click the \"Assign Actions\" button (currently unavailable until a selection is made).",
    "Similar to the Observation Summary, customize your view by applying relevant filters.",
)