package com.ecosave.watch.portal.helpers.workorder

import com.ecosave.watch.portal.helpers.billing.UtilityType
import com.ecosave.watch.portal.helpers.common.AutoCompleteOption
import com.ecosave.watch.portal.models.common.TableCell

object WorkOrderConstants {
    val observationsTableHeaderList = listOf(
        "Severity",
        "Facility",
        "Utility",
        "Account Number",
        "Event",
        "Observation",
        "Generation Date",
        "Actions Generated",
        "Actions In-Progress",
        "Actions Closed",
        "View Actions"
    )

    val workForceTableHeaderList = listOf(
        "Work Force",
        "Actions Assigned",
        "Actions In-Progress"
    )

    val actionsTableHeaderList = listOf(
        TableCell("status", "Status"),
        TableCell("generatedDate", "Generated Date"),
        TableCell("username", "Assigned To"),
        TableCell("closedBy","Closed By"),
        TableCell("closedDate", "Closed Date")
    )

    val actionStatusList = listOf(
        ActionStatus.COMPLETED,
        ActionStatus.IN_PROGRESS,
    )

    val statusOptions = arrayOf(
        AutoCompleteOption(ActionStatus.UNASSIGNED.description, ActionStatus.UNASSIGNED.name),
        AutoCompleteOption(ActionStatus.ASSIGNED.description, ActionStatus.ASSIGNED.name),
        AutoCompleteOption(ActionStatus.IN_PROGRESS.description, ActionStatus.IN_PROGRESS.name),
        AutoCompleteOption(ActionStatus.COMPLETED.description, ActionStatus.COMPLETED.name)
    )

    val ActionsUtilityTypeOptions = arrayOf(
        AutoCompleteOption(UtilityType.ELECTRIC.type, UtilityType.ELECTRIC.name),
        AutoCompleteOption(UtilityType.NATURAL_GAS.type, UtilityType.NATURAL_GAS.name),
        AutoCompleteOption(UtilityType.WATER.type, UtilityType.WATER.name),
        AutoCompleteOption(UtilityType.STEAM.type, UtilityType.STEAM.name),
        AutoCompleteOption(UtilityType.HOT_WATER.type, UtilityType.HOT_WATER.name),
        AutoCompleteOption(UtilityType.CHILLED_WATER.type, UtilityType.CHILLED_WATER.name)
    )

    val SeverityOptions = arrayOf(
        AutoCompleteOption(ActionSeverity.MAJOR.description, ActionSeverity.MAJOR.name),
        AutoCompleteOption(ActionSeverity.MODERATE.description, ActionSeverity.MODERATE.name),
        AutoCompleteOption(ActionSeverity.MINOR.description, ActionSeverity.MINOR.name)
    )

}