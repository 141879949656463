package com.ecosave.watch.portal.components.privacypolicy

import react.FC
import react.Props

val IntellectualProperty = FC<Props> {
    HeadingWrapper {
        heading = "12. Intellectual Property"
    }
    TypographyWrapper {
        +"As used in this Agreement, \"Technology\" means any and all know-how, processes, methodologies, specifications, designs, inventions, functionality, graphics, techniques, methods, applications, computer programs, libraries, user manuals, documentation, products or other technology and materials of any kind, or any enhancement thereto, used by Ecosave in connection with the performance of the Service or the Support, or made available by Ecosave to you, any Authorized User, or any third party in connection with the Service or the Support. Without limiting the above, Technology includes the products, services, and technology made available through the Website, the Agents, the Service interface, and any Ecosave branded or co-branded websites (including sub-domains, widgets, and mobile versions)."
    }
    TypographyWrapper {
        +"The Service and Technology constitute or otherwise involve valuable intellectual property rights of Ecosave, and Ecosave will own all right, title, and interest in and to the above. No title to or ownership of the Service or Technology, or any intellectual property rights associated therewith, is transferred to you, any Authorized User, or any third party under this Agreement. Sections 8-9 describe the entirety of your limited rights to access and use the Service and Agents and to make the Service and Agents available to Authorized Users. Except with respect to certain of the Agents, in no event will you be entitled to access or review any object code or source code. Ecosave reserves all rights to the Service and Technology not otherwise expressly granted herein."
    }
}
