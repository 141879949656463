package com.ecosave.watch.portal.models.billing

import com.ecosave.watch.portal.helpers.common.AutoCompleteOption

data class UtilityAccountFiltersDataClass(
    var facility: MutableList<AutoCompleteOption> = mutableListOf(),
    var street: MutableList<AutoCompleteOption> = mutableListOf(),
    var city: MutableList<AutoCompleteOption> = mutableListOf(),
    var zipcode: MutableList<AutoCompleteOption> = mutableListOf(),
    var state: MutableList<AutoCompleteOption> = mutableListOf()
)

