package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class ProcurementPracticesState(
    var section2041A1: Double? = null,
    var section2041A2: String? = null,
    var section2041A3: String? = null,

    var section3_3_204A1: String? = null,
    var section3_3_204A2: String? = null,
    var section3_3_204A3: String? = null,
    var section3_3_204A4: String? = null,
    var section3_3_204A5: String? = null,
    var section3_3_204A6: String? = null
)