package com.ecosave.watch.portal.components.esg.energy

import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val GRI_302_5 = FC<EsgSubFormComponentProps> { props ->
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea
    val onChangeText = props.onChangeText
    val onBlurText = props.onBlurText
    val onChangeDecimalNumber = props.onChangeDecimalNumber
    val onBlurDecimalNumber = props.onBlurDecimalNumber

    props.esgReportState.report?.energy?.let { state ->
        val energyReductionERListName = state::energyReductionERList.name

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_302_5

        }

        Box {
            className = ESGCommonStyles.TABLE_WRAPPER.cssClass
            DynamicTableWrapper {
                addRowOrSection = { props.addRowOrSection(energyReductionERListName) }
                items = state.energyReductionERList
                action = DynamicTableAction.ADD
                addRowOrSectionBtnText = ReportConstants.ADD_ROW_MESSAGE
                Box {
                    className = ESGCommonStyles.THREE_COLUMN_TABLE_8_2_2.cssClass
                    TableHeader {
                        header = "Product/Service(s)"
                    }
                    TableHeader {
                        header = "Unit"
                    }
                    TableHeader {
                        header = "Value"
                    }
                }
            }
            state.energyReductionERList.forEachIndexed { index, it ->
                DynamicTableWrapper {
                    key = it.key
                    deleteRowOrSection = { props.deleteRowOrSection(energyReductionERListName, index) }
                    items = state.energyReductionERList
                    action = DynamicTableAction.DELETE
                    variant = DynamicTableVariant.TABLE
                    Box {
                        className = ESGCommonStyles.THREE_COLUMN_TABLE_8_2_2.cssClass
                        ESGTextFieldWrapper {
                            name = it::section3025productNService.name
                            value = it.section3025productNService
                            onChange = { event -> onChangeText(event, index, energyReductionERListName) }
                            onBlur = { event -> onBlurText(event, index, energyReductionERListName) }
                        }
                        ESGTextFieldWrapper {
                            name = it::section3025ReductionERUnit.name
                            value = it.section3025ReductionERUnit
                            onChange = { event -> onChangeText(event, index, energyReductionERListName) }
                            onBlur = { event -> onBlurText(event, index, energyReductionERListName) }
                        }
                        ESGNumberFieldWrapper {
                            name = it::section3025ReductionERCurrent.name
                            value = it.section3025ReductionERCurrent
                            onChange = { event -> onChangeDecimalNumber(event, index, energyReductionERListName) }
                            onBlur = { event -> onBlurDecimalNumber(event, index, energyReductionERListName) }
                        }
                    }
                }
            }
        }

        Box {
            label {
                +"What is the basis for calculating reductions in energy consumption, such as base year or baseline, including the rationale for choosing it."
            }
            ESGTextAreaFieldWrapper {
                name = state::section3025Field1.name
                value = state.section3025Field1
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"What were the standards, methodologies, assumptions, and/or calculation tools used?"
            }
            ESGTextAreaFieldWrapper {
                name = state::section3025Field2.name
                value = state.section3025Field2
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}