package com.ecosave.watch.portal.components.common

import mui.material.Box
import mui.material.Popover
import mui.material.PopoverProps
import react.FC
import web.cssom.ClassName

external interface PopoverWrapperProps : PopoverProps

val PopoverWrapper = FC<PopoverWrapperProps> { props ->
    Popover {
        +props
        Box {
            className = ClassName("dialog-gradient-strip")
        }
        +props.children
    }
}