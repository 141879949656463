package com.ecosave.watch.portal.components.privacypolicy

import mui.material.Typography
import mui.material.TypographyProps
import mui.system.sx
import react.FC
import web.cssom.FontWeight
import web.cssom.rem

external interface TypographyWrapperProps : TypographyProps

val TypographyWrapper = FC<TypographyWrapperProps> { props ->
    Typography {
        +props
        paragraph = true
        sx {
            fontSize = 1.2.rem
            lineHeight = 1.8.rem
            fontWeight = FontWeight.lighter
        }
        +props.children
    }
}