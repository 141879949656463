package com.ecosave.watch.portal.styles

import web.cssom.ClassName

enum class ResourceCenterStyles(val cssClass: ClassName) {
    LOCK_POSITIONING(ClassName("lock-positioning")),
    RESOURCE_SIDEBAR(ClassName("resource-sidebar")),
    RESOURCES_DISPLAY_OVERVIEW(ClassName("resources-display-overview")),
    RESOURCES_DISPLAY_OVERVIEW_BOX(ClassName("resources-display-overview-box")),
    RESOURCES_DISPLAY_OVERVIEW_SMALLER(ClassName("resources-display-overview-smaller")),
    RESOURCES_MAIN_DISPLAY(ClassName("resources-main-display")),
    RESOURCES_MAIN_TITLE(ClassName("resources-main-title")),
    RESOURCES_CATEGORY(ClassName("resources-category")),
    RESOURCES_SUBCATEGORY(ClassName("resources-subcategory")),
    RESOURCES_SENTENCE(ClassName("resources-sentence")),
    RESOURCES_TITLE_UNDERLINED(ClassName("resources-title-underlined")),
    RESOURCES_SUBSECTION(ClassName("resources-subsection"))
}
