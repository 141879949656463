package com.ecosave.watch.portal.models.facilitygroupmanagement

import com.ecosave.watch.portal.helpers.facilitymanagement.BuildingCategory
import kotlinx.serialization.Serializable

@Serializable
data class FacilitySummary(
    var facilityId: Int,
    var facilityName: String,
    var address: Address,
    var buildingCategory: BuildingCategory
)

@Serializable
data class Address(
    val street: String,
    val city: String,
    val state: String,
    val zipcode: String
)