package com.ecosave.watch.portal.helpers.common

import com.ecosave.watch.portal.StateContextProps
import com.ecosave.watch.portal.components.npm.jwtDecode
import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.helpers.Constants.Companion.DECIMAL_NUMBER_MAX_LENGTH
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import com.ecosave.watch.portal.helpers.usermanagement.OnBoardingStatus
import com.ecosave.watch.portal.helpers.usermanagement.Subscription
import com.ecosave.watch.portal.helpers.usermanagement.UserRole
import com.ecosave.watch.portal.models.auth.TokenData
import com.ecosave.watch.portal.models.auth.UserData
import com.ecosave.watch.portal.models.auth.UserInfo
import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.services.getHttpClient
import com.ecosave.watch.portal.services.httpClient
import js.core.jso
import kotlin.js.Date
import kotlinx.browser.localStorage
import kotlinx.browser.window
import react.StateSetter
import react.router.NavigateFunction
import web.html.HTMLInputElement

val yesNoDropdown = mapOf("Yes" to "true", "No" to "false")
val currentYear = Date().getFullYear()

external interface AutoCompleteOption {
    var label: String
    var value: String
}

fun AutoCompleteOption(label: String, value: String = ""): AutoCompleteOption = jso {
    this.label = label
    this.value = value
}

fun generateParamsFromOptions(options: MutableList<AutoCompleteOption>): String {
    var params = ""
    for (option in options) {
        if (params.isEmpty()) {
            params = option.value
        } else {
            params += ",${option.value}"
        }
    }
    return params
}


fun handleNotificationStatus(
    status: ApiCallStatus,
    notificationState: NotificationState,
    notificationStateSetter: StateSetter<NotificationState>,
    operationType: OperationType
) {
    val (successMessage, failedMessage) = when (operationType) {
        OperationType.DOWNLOAD -> Pair(ReportConstants.DOWNLOAD_SUCCESS, ReportConstants.DOWNLOAD_FAILED)
        OperationType.DELETE -> Pair(ReportConstants.DELETED_SUCCESS, ReportConstants.DELETED_FAILED)
    }

    when (status) {
        ApiCallStatus.SUCCESS -> {
            notificationStateSetter(
                notificationState.copy(
                    status = NotificationStatus.SUCCESS,
                    message = successMessage,
                    visible = true
                )
            )
        }

        else -> {
            notificationStateSetter(
                notificationState.copy(
                    status = NotificationStatus.ERROR,
                    message = failedMessage,
                    visible = true
                )
            )
        }
    }
}

enum class OperationType {
    DOWNLOAD, DELETE
}

val responsivePagesRoutes: MutableList<String> =
    mutableListOf(
        PageRoutes.SIGN_IN.route,
        "/register",
        "/user-management",
        "/facility-groups",
        "/resource-center",
        "/registration/:token",
        "/verification/:token",
        PageRoutes.USER_PROFILE.route,
        PageRoutes.USER_PREFERENCES.route
    )

fun isPageResponsive(browserWidth: Int): Boolean {
    return (((browserWidth <= ScreenBreakPoints.tablet) && (browserWidth > ScreenBreakPoints.mobile) &&
            !(responsivePagesRoutes.contains(window.location.pathname)) && !(currentRouteContainsResponsivePageRoute(
        window.location.pathname
    ))) ||
            (browserWidth <= ScreenBreakPoints.mobile))
}

fun currentRouteContainsResponsivePageRoute(route: String): Boolean {
    responsivePagesRoutes.forEach {
        if (route.contains(it)) {
            return true
        }
    }
    return false
}

fun isOnboardingCompleted(onBoardingStatus: OnBoardingStatus): Boolean {
    return onBoardingStatus == OnBoardingStatus.DONE
}

/**
 * Required to deal with Decimals in Chrome correctly
 */
fun validateDecimalNumber(value: String): Boolean {
    if (value.isBlank()) return true

    val regex = """^\d+(\.\d{1,2})?$""".toRegex()
    return value.matches(regex)
}

fun isValidDecimalNumber(target: HTMLInputElement): Boolean {
    return !target.validity.badInput && validateDecimalNumber(target.value) && target.value.length <= DECIMAL_NUMBER_MAX_LENGTH
}

fun isValidInteger(target: HTMLInputElement, maximumDigitsAllowed: Int = Constants.NUMBER_MAX_LENGTH): Boolean {
    if (target.value.isBlank()) {
        return true
    }
    return !target.validity.badInput && target.value.toIntOrNull() != null && target.value.length <= maximumDigitsAllowed
}

fun isValidLongNumber(target: HTMLInputElement, maximumDigitsAllowed: Long = Constants.LONG_NUMBER_MAX_LENGTH): Boolean {
    if (target.value.isBlank()) {
        return true
    }
    return !target.validity.badInput && target.value.toLongOrNull() != null && target.value.length <= maximumDigitsAllowed
}

fun getAccessTokenFromLocalStorage(): String? {
    return localStorage.getItem("access_token")
}

fun getRefreshTokenFromLocalStorage(): String? {
    return localStorage.getItem("refresh_token")
}

fun isRefreshTokenValid(): Boolean {
    val refreshToken = getRefreshTokenFromLocalStorage()
    return if (refreshToken == null) {
        false
    } else {
        val decodedToken = jwtDecode(refreshToken)
        val expirationEpochTime = decodedToken.exp
        val currentEpochTime = (Date().getTime() / 1000).toLong()
        expirationEpochTime.toString().toLong() - currentEpochTime > 0
    }
}

fun getAuthenticateState(): Boolean {
    val token = getAccessTokenFromLocalStorage()
    return token is String
}

fun setUserContext(tokenData: TokenData, onBoardingStatus: OnBoardingStatus, globalState: StateContextProps, navigation: NavigateFunction) {

    httpClient = getHttpClient()

    val userInfo = decodeAccessToken(tokenData.accessToken)
    setTokensInLocalStorage(tokenData.accessToken, tokenData.refreshToken)

    globalState.handleUpdateUserData(
        UserData(
            userInfo.firstName,
            userInfo.lastName,
            userInfo.emailAddress,
            userInfo.userRole,
            onBoardingStatus,
            userInfo.subscriptions
        )
    )
    globalState.updateIsAuthenticated(true)
    navigation.invoke("/")
}

fun showNotification(
    message: String,
    status: NotificationStatus,
    notificationState: NotificationState,
    setNotificationState: StateSetter<NotificationState>
) {
    setNotificationState(
        notificationState.copy(
            status = status,
            message = message,
            visible = true
        )
    )
}

fun decodeAccessToken(accessToken: String): UserInfo {

    val decodedToken = jwtDecode(accessToken)

    val roles: Array<String> = decodedToken["resource_access"]["ecosave-watch-service"]["roles"] as Array<String>

    val userRole = if (roles.contains("admin")) {
        UserRole.ADMIN.name
    } else {
        UserRole.USER.name
    }

    val subscriptions = mutableListOf<Subscription>()
    if (roles.contains("base-portal")) {
        subscriptions.add(Subscription.BASE_PORTAL)
    }
    if (roles.contains("add-esg")) {
        subscriptions.add(Subscription.ADD_ESG)
    }

    return UserInfo(
        firstName = decodedToken["given_name"] as String,
        lastName = decodedToken["family_name"] as String,
        emailAddress = decodedToken["email"] as String,
        userRole = userRole,
        subscriptions = subscriptions
    )
}

fun setTokensInLocalStorage(accessToken: String, refreshToken: String) {
    localStorage.setItem("access_token", accessToken)
    localStorage.setItem("refresh_token", refreshToken)
}

