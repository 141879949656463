package com.ecosave.watch.portal.models.billing

import com.ecosave.watch.portal.helpers.billing.DemandUnit
import com.ecosave.watch.portal.helpers.billing.UsageUnit
import com.ecosave.watch.portal.helpers.billing.UtilityDataCollectionMethod
import com.ecosave.watch.portal.helpers.billing.UtilityType
import com.ecosave.watch.portal.models.facilitymanagement.Address
import io.ktor.http.*
import kotlinx.datetime.LocalDateTime
import kotlinx.serialization.Serializable

@Serializable
data class FacilityAccountSummary(
    val facilityId: Int,
    val facilityName: String,
    val buildingAddress: Address,
    val buildingTotalArea: Float,
    val energyStarSubscription: Boolean,
    var utilityAccounts: List<UtilityAccountSummary>,
    val cancellationEffectiveOn: LocalDateTime?
)

@Serializable
data class UtilityAccountSummary(
    val utilityAccountId: Int?,
    val facilityId: Int,
    val utilityType: UtilityType,
    val serviceName: String,
    val accountNumber: String?,
    val meterNumber: String?,
    val serviceAddress: String,
    val utilityServiceProviderId: Int?,
    val utilityServiceProviderNameOther: String?,
    val utilityServiceProviderWebsiteUrl: String?,
    val surchargeBillExists: Boolean?,
    val coolingSteamUsed: Boolean?,
    val heatingSteamUsed: Boolean?,
    val isActive: Boolean?,
    val solarTotalInstalledCapacity: Float?,
    val solarCapacityUnit: DemandUnit?,
    val solarTotalAnnualGeneration: Float?,
    val solarUsageUnit: UsageUnit?,
    val solarPPAInPlace: Boolean?,
    val solarPPAServiceProvider: String?,
    val solarServiceStartDate: String?,
    val solarTotalLengthOfPPA: Int?,
    val solarNetMetered: Boolean?,
    val solarSystemInUse: Boolean?,
    val solarCurrentPPARate: Float?,
    val collectionMethod: UtilityDataCollectionMethod,
    val collectionStatus: String,
    val collectionStatusDetail: String?,
    val energyStarMeterId: String?,
    val userId: String?,
    val password: String?,
    val historyRequired: Boolean?,
    val cancellationEffectiveOn: LocalDateTime?,
    val historyRequestedOn: String?,
    val selectedUtilityServiceProviderName: String?,
    val latestBillMonth: String?,
)

@Serializable
data class UtilityServiceProviderSummary(
    val utilityServiceProviderId: Int,
    val utilityServiceProviderName: String,
)

@Serializable
data class UtilityAccountRegistration(
    val utilityAccount: UtilityAccount,
    val userId: String?,
    val password: String?,
    val historyRequired: Boolean?
)

@Serializable
data class UtilityAccount(
    val utilityAccountId: Int?,
    val facilityId: Int,
    val utilityType: String,
    val serviceName: String,
    val accountNumber: String?,
    val meterNumber: String?,
    val serviceAddress: String,
    val utilityServiceProviderId: Int?,
    val utilityServiceProviderNameOther: String?,
    val utilityServiceProviderWebsiteUrl: String?,
    val surchargeBillExists: Boolean?,
    val coolingSteamUsed: Boolean?,
    val heatingSteamUsed: Boolean?,
    val isActive: Boolean?,
    val solarTotalInstalledCapacity: Float?,
    val solarCapacityUnit: String?,
    val solarTotalAnnualGeneration: Float?,
    val solarUsageUnit: String?,
    val solarPPAInPlace: Boolean?,
    val solarPPAServiceProvider: String?,
    val solarServiceStartDate: String?,
    val solarTotalLengthOfPPA: Int?,
    val solarCurrentPPARate: Float?,
    val solarNetMetered: Boolean?,
    val solarSystemInUse: Boolean?,
    val collectionMethod: String,
    val energyStarMeterId: String?
)

data class UtilityAccountApiResponse(
    val utilityAccountSummary: UtilityAccountSummary?,
    val statusCode: HttpStatusCode?
)