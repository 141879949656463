package com.ecosave.watch.portal.components.common

import mui.material.Box
import mui.material.Paper
import mui.material.PaperProps
import react.FC
import web.cssom.ClassName

external interface PaperWrapperProps : PaperProps

val PaperWrapper = FC<PaperWrapperProps> { props ->
    Paper {
        +props
        Box {
            className = ClassName("paper-gradient-strip")
        }
        +props.children
    }
}