package com.ecosave.watch.portal.helpers.usermanagement

import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.components.npm.isEmail
import com.ecosave.watch.portal.components.npm.isStrongPassword
import com.ecosave.watch.portal.models.usermanagement.RegisterCompanyOrUserState
import com.ecosave.watch.portal.models.usermanagement.RegisterCompanyOrUserValidationState
import react.ReactNode
import react.StateSetter


fun validateFirstName(
    state: RegisterCompanyOrUserState,
    validation: RegisterCompanyOrUserValidationState,
    setValidation: StateSetter<RegisterCompanyOrUserValidationState>
) {
    if (state.firstName.isBlank()) {
        validation.firstNameErrorState = true
        validation.firstNameErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.firstNameErrorState = false
        validation.firstNameErrorMessage = null
    }

    updateRegisterValidationState(validation, setValidation)
}

fun validateLastName(
    state: RegisterCompanyOrUserState,
    validation: RegisterCompanyOrUserValidationState,
    setValidation: StateSetter<RegisterCompanyOrUserValidationState>
) {
    if (state.lastName.isBlank()) {
        validation.lastNameErrorState = true
        validation.lastNameErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.lastNameErrorState = false
        validation.lastNameErrorMessage = null
    }

    updateRegisterValidationState(validation, setValidation)
}

fun validateJobTitle(
    state: RegisterCompanyOrUserState,
    validation: RegisterCompanyOrUserValidationState,
    setValidation: StateSetter<RegisterCompanyOrUserValidationState>
) {
    if (state.jobTitle.isBlank()) {
        validation.jobTitleErrorState = true
        validation.jobTitleErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.jobTitleErrorState = false
        validation.jobTitleErrorMessage = null
    }

    updateRegisterValidationState(validation, setValidation)
}

fun validateCompanyName(
    state: RegisterCompanyOrUserState,
    validation: RegisterCompanyOrUserValidationState,
    setValidation: StateSetter<RegisterCompanyOrUserValidationState>
) {
    if (state.companyName.isBlank()) {
        validation.companyNameErrorState = true
        validation.companyNameErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.companyNameErrorState = false
        validation.companyNameErrorMessage = null
    }

    updateRegisterValidationState(validation, setValidation)
}

fun validateRegisterCompanyEmailAddress(
    state: RegisterCompanyOrUserState,
    validation: RegisterCompanyOrUserValidationState,
    setValidation: StateSetter<RegisterCompanyOrUserValidationState>
) {
    if (state.emailAddress.isBlank()) {
        validation.emailAddressErrorState = true
        validation.emailAddressErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else if (!isEmail(state.emailAddress)) {
        validation.emailAddressErrorState = true
        validation.emailAddressErrorMessage = ReactNode(Constants.VALID_EMAIL_ERROR_MESSAGE)
    } else {
        validation.emailAddressErrorState = false
        validation.emailAddressErrorMessage = null
    }

    updateRegisterValidationState(validation, setValidation)
}

fun validateRegisterCompanyConfirmEmailAddress(
    state: RegisterCompanyOrUserState,
    validation: RegisterCompanyOrUserValidationState,
    setValidation: StateSetter<RegisterCompanyOrUserValidationState>
) {
    if (state.confirmEmailAddress.isBlank()) {
        validation.confirmEmailAddressErrorState = true
        validation.confirmEmailAddressErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else if (!isEmail(state.confirmEmailAddress)) {
        validation.confirmEmailAddressErrorState = true
        validation.confirmEmailAddressErrorMessage = ReactNode(Constants.VALID_EMAIL_ERROR_MESSAGE)
    } else if(state.emailAddress != state.confirmEmailAddress) {
        validation.confirmEmailAddressErrorState = true
        validation.confirmEmailAddressErrorMessage = ReactNode("Email addresses do not match.")
    } else {
        validation.confirmEmailAddressErrorState = false
        validation.confirmEmailAddressErrorMessage = null
    }

    updateRegisterValidationState(validation, setValidation)
}


fun validateRegisterUserPassword(
    state: RegisterCompanyOrUserState,
    validation: RegisterCompanyOrUserValidationState,
    setValidation: StateSetter<RegisterCompanyOrUserValidationState>
) {
    if (state.password.isBlank()) {
        validation.passwordErrorState = true
        validation.passwordErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else if (!isStrongPassword(state.password)) {
        validation.passwordErrorState = true
        validation.passwordErrorMessage =
            ReactNode("Password should be at least 8 characters containing one capital letter, one number and one special character.")
    } else {
        validation.passwordErrorState = false
        validation.passwordErrorMessage = null
    }

    updateRegisterValidationState(validation, setValidation)
}

fun validateRegisterUserConfirmPassword(
    state: RegisterCompanyOrUserState,
    validation: RegisterCompanyOrUserValidationState,
    setValidation: StateSetter<RegisterCompanyOrUserValidationState>
) {
    if (state.confirmPassword.isBlank()) {
        validation.confirmPasswordErrorState = true
        validation.confirmPasswordErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else if (state.confirmPassword != state.password) {
        validation.confirmPasswordErrorState = true
        validation.confirmPasswordErrorMessage = ReactNode("Passwords do not match.")
    } else {
        validation.confirmPasswordErrorState = false
        validation.confirmPasswordErrorMessage = null
    }

    updateRegisterValidationState(validation, setValidation)
}

fun validateAgreementCheckBox(
    state: RegisterCompanyOrUserState,
    validation: RegisterCompanyOrUserValidationState,
    setValidation: StateSetter<RegisterCompanyOrUserValidationState>
) {
    if (!state.agreeTerms) {
        validation.agreeTermsErrorState = true
        validation.agreeTermsErrorMessage = ReactNode("Please check privacy policy and terms of service")
    } else {
        validation.agreeTermsErrorState = false
        validation.agreeTermsErrorMessage = null
    }

    updateRegisterValidationState(validation, setValidation)
}


fun updateRegisterValidationState(
    validation: RegisterCompanyOrUserValidationState,
    setValidation: StateSetter<RegisterCompanyOrUserValidationState>
) {
    setValidation(
        validation.copy()
    )
}

fun isUserInvitationFormValid(
    state: RegisterCompanyOrUserState,
    validation: RegisterCompanyOrUserValidationState,
    setValidation: StateSetter<RegisterCompanyOrUserValidationState>
): Boolean {
    validateUserForm(state, validation, setValidation)
    return isUserFormFieldsValid(validation)
}

fun validateUserForm(
    state: RegisterCompanyOrUserState,
    validation: RegisterCompanyOrUserValidationState,
    setValidation: StateSetter<RegisterCompanyOrUserValidationState>
) {
    validateFirstName(state, validation, setValidation)
    validateLastName(state, validation, setValidation)
    validateJobTitle(state, validation, setValidation)
    validateCompanyName(state, validation, setValidation)
    validateRegisterCompanyEmailAddress(state, validation, setValidation)
    validateRegisterUserPassword(state, validation, setValidation)
    validateRegisterUserConfirmPassword(state, validation, setValidation)
}

fun isUserFormFieldsValid(validation: RegisterCompanyOrUserValidationState): Boolean {
    return !(
            validation.firstNameErrorState ||
                    validation.lastNameErrorState ||
                    validation.jobTitleErrorState ||
                    validation.confirmPasswordErrorState ||
                    validation.passwordErrorState ||
                    validation.companyNameErrorState ||
                    validation.emailAddressErrorState
            )
}

fun isAdminRegistrationFormValid(
    state: RegisterCompanyOrUserState,
    validation: RegisterCompanyOrUserValidationState,
    setValidation: StateSetter<RegisterCompanyOrUserValidationState>
): Boolean {
    validateUserForm(state, validation, setValidation)
    validateAgreementCheckBox(state, validation, setValidation)
    validateRegisterCompanyConfirmEmailAddress(state, validation, setValidation)
    return isUserFormFieldsValid(validation) && !validation.agreeTermsErrorState && !validation.confirmEmailAddressErrorState
}