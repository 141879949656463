package com.ecosave.watch.portal.components.esg.waterandeffluents

object WaterConstants {
    val CATEGORY_HEADER = "Category"
    val FRESH_WATER = "Fresh Water"
    val OTHER_WATER = "Other Water"
    val TOTAL_WATER = "Total Water"
    val SURFACE_WATER = "Surface Water"
    val GROUND_WATER = "Ground Water"
    val SEA_WATER = "Sea Water"
    val PRODUCED_WATER = "Produced Water"
    val THIRD_PARTY_WATER = "Third-Party Water"

    val headers = listOf(CATEGORY_HEADER, FRESH_WATER, OTHER_WATER, TOTAL_WATER)
    val shortHeaders = listOf(CATEGORY_HEADER, TOTAL_WATER)

}