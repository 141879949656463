package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Transient
import kotlinx.uuid.UUID
import kotlinx.uuid.generateUUID
import kotlin.random.Random

/**
 * adding author https://github.com/Ecosave/ecosave-watch-services/blob/d2700e2c23ac92b655ee0ae1e116ad463f57a108/web/ecosave-watch-web-portal/src/main/kotlin/com/ecosave/watch/portal/models/esg/EsgRowState.kt
 * UUID based key used to make rows unique
 */
open class DynamicTableItemKey {
    @Transient
    val key: String = UUID.generateUUID(Random).toString()
}