package com.ecosave.watch.portal.components.esg.rightsofindigenouspeoples

import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import mui.system.sx
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange
import web.cssom.px

val GRI_411_1 = FC<EsgSubFormComponentProps> { props ->
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea
    val onChangeNumber = props.onChangeNumber
    val onBlurNumber = props.onBlurNumber

    props.esgReportState.report?.rightsOfIndigenousPeoples?.let { state ->
        val actionAndStatusOfIncidentsListName = state::actionAndStatusOfIncidentsList.name

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_411_1
        }

        Box {
            className = ESGCommonStyles.TWO_COLUMN_TABLE_8_4.cssClass
            label {
                +"Total number of identified incidents of violations involving the rights of indigenous peoples during the reporting period"
            }
            ESGNumberFieldWrapper {
                name = state::section4111Field1.name
                value = state.section4111Field1
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
        }

        DisclosureSubHeading {
            subHeading = "Status of the incidents and actions taken with reference to the following by organization."
        }
        Box {
            className = ESGCommonStyles.STICKY_HEADER.cssClass
            DynamicTableWrapper {
                addRowOrSection = { props.addRowOrSection(actionAndStatusOfIncidentsListName) }
                items = state.actionAndStatusOfIncidentsList
                action = DynamicTableAction.ADD
                variant = DynamicTableVariant.TABLE
                addRowOrSectionBtnText = "Add a new incident"
                Box {
                    sx {
                        padding = 20.px
                    }
                    DisclosureSubHeading {
                        subHeading = "Incidents"
                    }
                }
            }
        }
        state.actionAndStatusOfIncidentsList.forEachIndexed { index, it ->
            DynamicTableWrapper {
                key = it.key
                deleteRowOrSection = { props.deleteRowOrSection(actionAndStatusOfIncidentsListName, index) }
                items = state.actionAndStatusOfIncidentsList
                action = DynamicTableAction.DELETE
                Box {
                    className = ESGCommonStyles.DYNAMIC_TABLE_SECTION_LAYOUT.cssClass
                    DisclosureSubHeading {
                        subHeading = "Incident ${index + 1}"
                    }
                    Box {
                        label {
                            className = ESGCommonStyles.LABEL.cssClass
                            +"Incident"
                        }
                        ESGTextAreaFieldWrapper {
                            name = it::section4111A1.name
                            value = it.section4111A1
                            onChange = { event -> onChangeTextArea(event, index, actionAndStatusOfIncidentsListName) }
                            onBlur = { event -> onBlurTextArea(event, index, actionAndStatusOfIncidentsListName) }
                        }
                    }
                    Box {
                        label {
                            className = ESGCommonStyles.LABEL.cssClass
                            +"Incident reviewed"
                        }
                        ESGTextAreaFieldWrapper {
                            name = it::section4111A2.name
                            value = it.section4111A2
                            onChange = { event -> onChangeTextArea(event, index, actionAndStatusOfIncidentsListName) }
                            onBlur = { event -> onBlurTextArea(event, index, actionAndStatusOfIncidentsListName) }
                        }
                    }
                    Box {
                        label {
                            className = ESGCommonStyles.LABEL.cssClass
                            +"Remediation plans being implemented"
                        }
                        ESGTextAreaFieldWrapper {
                            name = it::section4111A3.name
                            value = it.section4111A3
                            onChange = { event -> onChangeTextArea(event, index, actionAndStatusOfIncidentsListName) }
                            onBlur = { event -> onBlurTextArea(event, index, actionAndStatusOfIncidentsListName) }
                        }
                    }
                    Box {
                        label {
                            className = ESGCommonStyles.LABEL.cssClass
                            +"Results reviewed through routine internal management review"
                        }
                        ESGTextAreaFieldWrapper {
                            name = it::section4111A4.name
                            value = it.section4111A4
                            onChange = { event -> onChangeTextArea(event, index, actionAndStatusOfIncidentsListName) }
                            onBlur = { event -> onBlurTextArea(event, index, actionAndStatusOfIncidentsListName) }
                        }
                    }
                }
            }
        }
    }
}