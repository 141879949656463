package com.ecosave.watch.portal.models.assetmanagement

import web.file.File

data class AssetState(
    val facilityId: String = "",
    val equipmentId: String = "",
    val equipmentType: String = "",
    val equipmentLocation: String = "",
    val quantity: Int? = null,
    val serves: String = "",
    val ownership: String = "",
    val manufacturer: String = "",
    val equipmentMaintenanceProvider: String = "",
    val model: String = "",
    val serialNumber: String = "",
    val systemType: String = "",
    val yearInstalled: String = "",
    val warrantyEndDate: String = "",
    val comments: String = "",
    val selectedFile: File? = null,
    // needed by backend only
    val fileType: String = "",
    val uploadDocumentName: String = "",
    val documentUrl: String = ""
)