package com.ecosave.watch.portal.components.utilityaccounts

import com.ecosave.watch.portal.components.common.ConfirmationDialog
import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.helpers.billing.BillingConstants
import com.ecosave.watch.portal.helpers.billing.BillingSolarHeaders
import com.ecosave.watch.portal.helpers.billing.UsageUnit
import com.ecosave.watch.portal.helpers.billing.UtilityType
import com.ecosave.watch.portal.helpers.billing.clearBillStartAndEndDate
import com.ecosave.watch.portal.helpers.billing.isBillingSolarFormValid
import com.ecosave.watch.portal.helpers.billing.mainScope
import com.ecosave.watch.portal.helpers.billing.validateSolarExported
import com.ecosave.watch.portal.helpers.billing.validateSolarExportedCredit
import com.ecosave.watch.portal.helpers.billing.validateTotalSolarCost
import com.ecosave.watch.portal.helpers.billing.validateTotalSolarGenerated
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.models.billing.ElectricBillValidationState
import com.ecosave.watch.portal.models.billing.NaturalGasBillValidationState
import com.ecosave.watch.portal.models.billing.SolarBillValidationState
import com.ecosave.watch.portal.models.billing.SteamBillValidationState
import com.ecosave.watch.portal.models.billing.UtilityBillValidationState
import com.ecosave.watch.portal.models.billing.WaterBillValidationState
import com.ecosave.watch.portal.models.billing.utilitybillstate.ElectricAdditional
import com.ecosave.watch.portal.models.billing.utilitybillstate.NaturalGasAdditional
import com.ecosave.watch.portal.models.billing.utilitybillstate.SolarBillAdditional
import com.ecosave.watch.portal.models.billing.utilitybillstate.SteamBillAdditional
import com.ecosave.watch.portal.models.billing.utilitybillstate.UtilityBillState
import com.ecosave.watch.portal.models.billing.utilitybillstate.WaterBillAdditional
import com.ecosave.watch.portal.services.billing.createBill
import com.ecosave.watch.portal.styles.BillingInputFormStyles
import com.ecosave.watch.portal.styles.CommonStyles
import com.ecosave.watch.portal.useGlobalState
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import react.FC
import react.Props
import react.ReactNode
import react.StateSetter
import react.dom.onChange
import react.useState
import web.html.HTMLInputElement
import web.html.InputType

external interface SolarFormProps : Props {
    var setShowInputForm: StateSetter<Boolean>
    var showNotification: (NotificationStatus, String) -> Unit
}

val SolarForm = FC<SolarFormProps> { props ->
    val globalState = useGlobalState()
    val (formValidationState, setFormValidationState) = useState(
        UtilityBillValidationState(
            electricBillValidationState = ElectricBillValidationState(),
            naturalGasBillValidationState = NaturalGasBillValidationState(),
            waterBillValidationState = WaterBillValidationState(),
            steamBillValidationState = SteamBillValidationState(),
            solarBillValidationState = SolarBillValidationState()
        )
    )
    val (formState, setFormState) = useState(
        UtilityBillState(
            utilityAccountId = globalState.utilityAccountData.accountId,
            utilityType = UtilityType.SOLAR.name,
            usageUnit = UsageUnit.KWH.name,
            electricAdditional = ElectricAdditional(),
            naturalGasAdditional = NaturalGasAdditional(),
            waterBillAdditional = WaterBillAdditional(),
            steamBillAdditional = SteamBillAdditional(),
            solarBillAdditional = SolarBillAdditional()
        )
    )
    var isSubmitting by useState(false)
    var submitButtonText by useState("Submit")
    val (openDialog, setOpenDialog) = useState(false)

    Box {
        InputFormTitleSector {
            title =
                if (globalState.utilityAccountData.isSolarNetMetered) "Add Solar Bill" else "Add Solar Usage"
            subtitle =
                if (globalState.utilityAccountData.isSolarNetMetered) "Account Number: ${globalState.utilityAccountData.accountNumber}" else ""
        }
        Box {
            className = BillingInputFormStyles.MAIN_FORM.cssClass
            Box {
                className = BillingInputFormStyles.INPUT_WRAPPER.cssClass

                if (globalState.utilityAccountData.isSolarNetMetered) {
                    BillingCommonFormFields {
                        commonFormState = formState
                        setCommonFormState = setFormState
                        commonFormValidationState = formValidationState
                        setCommonFormValidationState = setFormValidationState
                        hideFieldsNotNeededInSolarForm = true
                    }
                } else {
                    BillStartDate {
                        state = formState
                        setState = setFormState
                        validation = formValidationState
                        setValidation = setFormValidationState
                        isSolarStartDate = true
                        setDialogState = setOpenDialog
                    }
                    BillEndDate {
                        state = formState
                        setState = setFormState
                        validation = formValidationState
                        setValidation = setFormValidationState
                        isSolarEndDate = true
                        setDialogState = setOpenDialog
                    }
                }

                TextFieldWrapper {
                    label = ReactNode(BillingSolarHeaders.TOTAL_SOLAR_GENERATED.header)
                    type = InputType.number
                    value = formState.solarBillAdditional.totalSolarGenerated
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setFormState(
                            formState.copy(
                                solarBillAdditional = formState.solarBillAdditional.copy(
                                    totalSolarGenerated = target.value.toFloatOrNull()
                                )
                            )
                        )
                    }
                    error =
                        formValidationState.solarBillValidationState.totalSolarGeneratedErrorState
                    helperText =
                        formValidationState.solarBillValidationState.totalSolarGeneratedErrorMessage
                    onBlur = {
                        validateTotalSolarGenerated(
                            formState,
                            formValidationState,
                            setFormValidationState
                        )
                    }
                }

                AddUsageUnit {
                    stateUsageUnit = formState
                    setStateUsageUnit = setFormState
                    usageUnits = UtilityType.SOLAR.usageUnits
                }

                if (globalState.utilityAccountData.isTherePPAInPlaceSolar) {
                    TextFieldWrapper {
                        label = ReactNode("${BillingSolarHeaders.TOTAL_SOLAR_COST.header} ($) *")
                        type = InputType.number
                        value = formState.solarBillAdditional.totalSolarCost
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setFormState(
                                formState.copy(
                                    solarBillAdditional = formState.solarBillAdditional.copy(
                                        totalSolarCost = target.value.toFloatOrNull()
                                    )
                                )
                            )
                        }
                        error = formValidationState.solarBillValidationState.totalSolarCostErrorState
                        helperText =
                            formValidationState.solarBillValidationState.totalSolarCostErrorMessage
                        onBlur = {
                            validateTotalSolarCost(
                                formState,
                                formValidationState,
                                setFormValidationState
                            )
                        }
                    }
                }

                if (globalState.utilityAccountData.isSolarNetMetered) {
                    TextFieldWrapper {
                        label = ReactNode(BillingSolarHeaders.SOLAR_EXPORTED.header)
                        type = InputType.number
                        value = formState.solarBillAdditional.solarExported
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setFormState(
                                formState.copy(
                                    solarBillAdditional = formState.solarBillAdditional.copy(
                                        solarExported = target.value.toFloatOrNull()
                                    )
                                )
                            )
                        }
                        error =
                            formValidationState.solarBillValidationState.solarExportedErrorState
                        helperText =
                            formValidationState.solarBillValidationState.solarExportedErrorMessage
                        onBlur = {
                            validateSolarExported(
                                formState,
                                formValidationState,
                                setFormValidationState
                            )
                        }
                    }

                    TextFieldWrapper {
                        label = ReactNode("${BillingSolarHeaders.SOLAR_EXPORTED_CREDIT.header} ($) *")
                        type = InputType.number
                        value = formState.solarBillAdditional.solarExportedCredit
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setFormState(
                                formState.copy(
                                    solarBillAdditional = formState.solarBillAdditional.copy(
                                        solarExportedCredit = target.value.toFloatOrNull()
                                    )
                                )
                            )
                        }
                        error =
                            formValidationState.solarBillValidationState.solarExportedCreditErrorState
                        helperText =
                            formValidationState.solarBillValidationState.solarExportedCreditErrorMessage
                        onBlur = {
                            validateSolarExportedCredit(
                                formState,
                                formValidationState,
                                setFormValidationState
                            )
                        }
                    }
                }
                Box {
                    className = BillingInputFormStyles.INPUT_ITEM.cssClass
                }
                Box {
                    className = BillingInputFormStyles.INPUT_ITEM.cssClass
                }
            }
        }
        Box {
            className = CommonStyles.CENTERED_BUTTON.cssClass
            Button {
                className = CommonStyles.MARGIN_RIGHT_BUTTON.cssClass
                +"Cancel"
                onClick = {
                    props.setShowInputForm(false)
                }
            }
            Button {
                +submitButtonText
                disabled = isSubmitting
                variant = ButtonVariant.contained
                onClick = {
                    if (isBillingSolarFormValid(
                            formState,
                            formValidationState,
                            setFormValidationState,
                            globalState.utilityAccountData.isSolarNetMetered,
                            globalState.utilityAccountData.isTherePPAInPlaceSolar
                        )
                    ) {
                        mainScope.launch {
                            isSubmitting = true
                            submitButtonText = "Submitting..."
                            val apiCallStatus = createBill(formState)
                            if (apiCallStatus == ApiCallStatus.SUCCESS) {
                                props.showNotification(
                                    NotificationStatus.SUCCESS,
                                    BillingConstants.BILL_SUBMITTED_NOTIFICATION_MESSAGE
                                )
                                props.setShowInputForm(false)
                            } else {
                                props.showNotification(NotificationStatus.ERROR, Constants.NOTIFICATION_ERROR_MESSAGE)
                            }
                            submitButtonText = "Submit"
                            isSubmitting = false
                        }
                    }
                }
            }
        }
    }
    ConfirmationDialog {
        open = openDialog
        title = "Confirmation"
        body = BillingConstants.SOLAR_START_END_DATE_DIFF_DIALOG_MESSAGE
        actionResult = { value ->
            setOpenDialog(false)
            if (!value) {
                clearBillStartAndEndDate(formState, setFormState)
            }
        }
    }
}