package com.ecosave.watch.portal

import mui.material.CssBaseline
import mui.system.ThemeProvider
import muix.pickers.AdapterMoment
import muix.pickers.LocalizationProvider
import react.FC
import react.Props

val currentTheme = Themes.darkTheme

val App = FC<Props> {
    GlobalContextProvider {
        ThemeProvider {
            theme = currentTheme
            CssBaseline {}
            LocalizationProvider {
                dateAdapter = AdapterMoment
                AppRoutes {}
            }
        }
    }
}