package com.ecosave.watch.portal.models.facilitygroupmanagement

import kotlinx.serialization.Serializable

@Serializable
data class FacilityGroup(
    var facilityGroupId: Int = 0,
    var facilityGroupName: String = "",
    var facilities: MutableList<FacilitySummary> = mutableListOf(),
    var childGroups: MutableList<FacilityGroup>? = null
)