package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class MarketingAndLabelingState(
    var section4171A1: String? = null,
    var section4171A2: String? = null,
    var section4171A3: String? = null,
    var section4171A4: String? = null,
    var section4171A5: String? = null,
    var section4171A6: Double? = null,

    var section4172A1: Long? = null,
    var section4172A2: Long? = null,
    var section4172A3: Long? = null,
    var section4172A4: Long? = null,

    var section4172B1: Long? = null,
    var section4172B2: Long? = null,
    var section4172B3: Long? = null,
    var section4172B4: Long? = null,

    var section4172Field1: String? = null,
    var section4172Field2: String? = null,

    var section4173A1: Long? = null,
    var section4173A2: Long? = null,
    var section4173A3: Long? = null,
    var section4173A4: Long? = null,

    var section4173B1: Long? = null,
    var section4173B2: Long? = null,
    var section4173B3: Long? = null,
    var section4173B4: Long? = null,
    var section4173Field1: String? = null,
    var section4173Field2: String? = null,

    var section3_3_417A1: String? = null,
    var section3_3_417A2: String? = null,
    var section3_3_417A3: String? = null,
    var section3_3_417A4: String? = null,
    var section3_3_417A5: String? = null,
    var section3_3_417A6: String? = null
)
