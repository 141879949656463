package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class ForcedOrCompulsoryLaborState(
    var section4091Field1: String? = null,
    var forcedOrCompulsoryLaborList: MutableList<ForcedOrCompulsoryLaborIncident> = mutableListOf(),

    var section3_3_409A1: String? = null,
    var section3_3_409A2: String? = null,
    var section3_3_409A3: String? = null,
    var section3_3_409A4: String? = null,
    var section3_3_409A5: String? = null,
    var section3_3_409A6: String? = null
)

@Serializable
data class ForcedOrCompulsoryLaborIncident(
    var section4091A1: String? = null,
    var section4091A2: String? = null,
) : DynamicTableItemKey()
