package com.ecosave.watch.portal.components.utilityaccounts

import com.ecosave.watch.portal.helpers.billing.BillingHeaders
import com.ecosave.watch.portal.helpers.billing.UsageUnit
import com.ecosave.watch.portal.models.billing.utilitybillstate.UtilityBillState
import com.ecosave.watch.portal.styles.BillingInputFormStyles
import mui.material.FormControl
import mui.material.InputLabel
import mui.material.MenuItem
import mui.material.Select
import mui.material.Size
import react.FC
import react.Props
import react.ReactNode
import react.StateSetter

external interface AddUsageUnitProps : Props {
    var stateUsageUnit: UtilityBillState
    var setStateUsageUnit: StateSetter<UtilityBillState>
    var usageUnits: List<UsageUnit>
}

val AddUsageUnit = FC<AddUsageUnitProps> { props ->

    val state = props.stateUsageUnit
    val setState = props.setStateUsageUnit
    val usageUnits = props.usageUnits

    FormControl {
        className = BillingInputFormStyles.INPUT_ITEM.cssClass
        InputLabel {
            +BillingHeaders.USAGE_UNIT.header
        }
        size = Size.medium
        Select {
            label = ReactNode(BillingHeaders.USAGE_UNIT.header)
            value = state.usageUnit
            onChange = { event, _ ->
                setState(
                    state.copy(
                        usageUnit = event.target.value
                    )
                )
            }
            for (unit in usageUnits) {
                MenuItem {
                    value = unit.name
                    +unit.description
                }
            }
        }
    }
}