package com.ecosave.watch.portal.components.esg.supplierenvironmentalassessment

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val GRI_308_2 = FC<EsgSubFormComponentProps> { props ->
    val onChangeDecimalNumber = props.onChangeDecimalNumber
    val onBlurDecimalNumber = props.onBlurDecimalNumber
    val onChangeNumber = props.onChangeNumber
    val onBlurNumber = props.onBlurNumber
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.supplierEnvironmentalAssessment?.let { state ->
        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_308_2
        }

        Box {
            className = ESGCommonStyles.TWO_COLUMN_TABLE_8_4.cssClass

            label {
                +"Number of suppliers assessed for environmental impacts."
            }
            ESGNumberFieldWrapper {
                name = state::section3082A1.name
                value = state.section3082A1
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            label {
                +"Number of suppliers identified as having significant actual and potential negative environmental impacts."
            }
            ESGNumberFieldWrapper {
                name = state::section3082A2.name
                value = state.section3082A2
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
        }
        Box {
            label {
                +"Significant actual and potential negative environmental impacts identified in the supply chain."
            }
            ESGTextAreaFieldWrapper {
                name = state::section3082A3.name
                value = state.section3082A3
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
        Box {
            className = ESGCommonStyles.TWO_COLUMN_TABLE_8_4.cssClass

            label {
                +"Percentage of suppliers identified as having significant actual and potential negative environmental impacts with which "
                +"improvements were agreed upon as a result of assessment."
            }
            ESGNumberFieldWrapper {
                name = state::section3082A4.name
                value = state.section3082A4
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }
            label {
                +"Percentage of suppliers identified as having significant actual and potential negative environmental impacts with which "
                +"relationships were terminated as a result of assessment, and why."
            }
            ESGNumberFieldWrapper {
                name = state::section3082A5.name
                value = state.section3082A5
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }
        }
    }
}