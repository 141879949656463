package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class StrategyPoliciesPracticesState(
    var section222A1: String? = null,

    var section223A1: String? = null,
    var section223A2: String? = null,
    var section223A3: String? = null,
    var section223A4: String? = null,
    var section223A5: String? = null,
    var section223Field1: String? = null,

    var embeddingPolicyCommitmentsList: MutableList<EmbeddingPolicyCommitments> = mutableListOf(),

    var section225A1: String? = null,
    var section225A2: String? = null,
    var section225A3: String? = null,
    var section225A4: String? = null,
    var section225A5: String? = null,

    var section226A1: String? = null,
    var section226A2: String? = null,

    var section227I1: Long? = null,
    var section227I2: Long? = null,
    var section227I3: Long? = null,

    var section227F1: Long? = null,
    var section227F2: Long? = null,
    var section227F3: Long? = null,

    var section227M1: Double? = null,
    var section227M2: Double? = null,
    var section227M3: Double? = null,

    var section227Field1: String? = null,
    var section227Field2: String? = null,

    var section228A1: String? = null,
)

@Serializable
data class EmbeddingPolicyCommitments(
    var section224A1: String? = null,
    var section224A2: String? = null,
) : DynamicTableItemKey()
