package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class OccupationalHealthAndSafetyState(
    var section4031Field1: String? = null,
    var section4031Field3: String? = null,
    var section4031List: MutableList<Section4031> = mutableListOf(),

    var section4032A1: String? = null,
    var section4032A2: String? = null,
    var section4032A3: String? = null,
    var section4032A4: String? = null,
    var section4032A5: String? = null,
    var section4032A6: String? = null,

    var section4033List: MutableList<Section4033> = mutableListOf(),

    var section4034A1: String? = null,
    var section4034A2: String? = null,

    var section4035Field1: String? = null,

    var section4036A1: String? = null,
    var section4036A2: String? = null,

    var section4037Field1: String? = null,

    var section4038A1: Long? = null,
    var section4038A2: Long? = null,
    var section4038A3: Long? = null,
    var section4038B1: Double? = null,
    var section4038B2: Double? = null,
    var section4038B3: Double? = null,
    var section4038Why: String? = null,
    var section4038Field1: String? = null,

    var section4039Number1: Long? = null,
    var section4039Number2: Long? = null,
    var section4039Number3: Long? = null,
    var section4039Number4: Long? = null,
    var section4039Number5: Long? = null,
    var section4039Number6: Long? = null,
    var section4039Rate1: Double? = null,
    var section4039Rate2: Double? = null,
    var section4039Rate3: Double? = null,
    var section4039Rate4: Double? = null,
    var section4039Rate5: Double? = null,
    var section4039Rate6: Double? = null,
    var section4039Main1: String? = null,
    var section4039Main2: String? = null,
    var section4039NumberOfHours1: Double? = null,
    var section4039NumberOfHours2: Double? = null,
    var section4039A1: String? = null,
    var section4039A2: String? = null,
    var section4039A3: String? = null,
    var section4039Field1: String? = null,
    var section4039Field2: String? = null,
    var section4039Field3: String? = null,
    var section4039Field4: String? = null,

    var section40310Number1: Long? = null,
    var section40310Number2: Long? = null,
    var section40310Number3: Long? = null,
    var section40310Number4: Long? = null,
    var section40310Number5: Long? = null,
    var section40310Number6: Long? = null,
    var section40310A1: String? = null,
    var section40310A2: String? = null,
    var section40310A3: String? = null,
    var section40310Field1: String? = null,
    var section40310Field2: String? = null,
    var section40310Field3: String? = null,

    var section3_3_403A1: String? = null,
    var section3_3_403A2: String? = null,
    var section3_3_403A3: String? = null,
    var section3_3_403A4: String? = null,
    var section3_3_403A5: String? = null,
    var section3_3_403A6: String? = null
)

@Serializable
data class Section4033(
    var section4033A1: String? = null,
    var section4033A2: String? = null,
    var section4033A3: String? = null,
) : DynamicTableItemKey()

@Serializable
data class Section4031(
    var section4031Field2: String? = null,
) : DynamicTableItemKey()