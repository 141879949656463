package com.ecosave.watch.portal.components.esg.generaldisclosures.activitiesandworkers

import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val Disclosure_2_8 = FC<EsgSubFormComponentProps> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea
    val onChangeNumber = props.onChangeNumber
    val onBlurNumber = props.onBlurNumber

    props.esgReportState.report?.activitiesAndWorkers?.let { state ->

        val section28ListName = state::section28list.name

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_2_8
        }

        Box {
            className = ESGCommonStyles.TWO_COLUMN_TABLE_8_4.cssClass
            label {
                +"Total # Of Workers Who Are Not Employees"
            }
            ESGNumberFieldWrapper {
                name = state::section28B1.name
                value = state.section28B1
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
        }

        Box {
            className = ESGCommonStyles.TABLE_WRAPPER.cssClass
            DynamicTableWrapper {
                addRowOrSection = {
                    props.addRowOrSection(section28ListName)
                }
                items = state.section28list
                action = DynamicTableAction.ADD
                addRowOrSectionBtnText = ReportConstants.ADD_ROW_MESSAGE
                Box {
                    className = ESGCommonStyles.THREE_COLUMN_TABLE.cssClass
                    TableHeader {
                        header = "Type Of Worker"
                    }
                    TableHeader {
                        header = "Contractual Relationship"
                    }
                    TableHeader {
                        header = "Type Of Work Done"
                    }
                }
            }
            state.section28list.forEachIndexed { index, it ->
                DynamicTableWrapper {
                    key = it.key
                    deleteRowOrSection = {
                        props.deleteRowOrSection(section28ListName, index)
                    }
                    items = state.section28list
                    action = DynamicTableAction.DELETE
                    variant = DynamicTableVariant.TABLE
                    Box {
                        className = ESGCommonStyles.THREE_COLUMN_TABLE.cssClass
                        ESGTextAreaFieldWrapper {
                            name = it::section28Field4.name
                            value = it.section28Field4 ?: ""
                            onChange = { event -> onChangeTextArea(event, index, section28ListName) }
                            onBlur = { event -> onBlurTextArea(event, index, section28ListName) }
                        }
                        ESGTextAreaFieldWrapper {
                            name = it::section28Field5.name
                            value = it.section28Field5 ?: ""
                            onChange = { event -> onChangeTextArea(event, index, section28ListName) }
                            onBlur = { event -> onBlurTextArea(event, index, section28ListName) }
                        }
                        ESGTextAreaFieldWrapper {
                            name = it::section28Field6.name
                            value = it.section28Field6
                            onChange = { event -> onChangeTextArea(event, index, section28ListName) }
                            onBlur = { event -> onBlurTextArea(event, index, section28ListName) }
                        }
                    }
                }
            }
        }

        DisclosureSubHeading {
            subHeading = "Describe the methodologies and assumptions used to compile the data, including whether the number of workers who are not employees is reported."
        }

        Box {
            label {
                +"Head count, full-time equivalent (FTE), or using another methodology."
            }
            ESGTextAreaFieldWrapper {
                name = state::section28Field1.name
                value = state.section28Field1 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"At the end of the reporting period, as an average across the reporting period, or using another methodology."
            }
            ESGTextAreaFieldWrapper {
                name = state::section28Field2.name
                value = state.section28Field2 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"Describe significant fluctuations in the number of workers who are not employees during the reporting period and between reporting periods."
            }
            ESGTextAreaFieldWrapper {
                name = state::section28Field3.name
                value = state.section28Field3 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}
