package com.ecosave.watch.portal.pages

import com.ecosave.watch.portal.components.esg.EsgMainComponent
import com.ecosave.watch.portal.components.esg.EsgReportsComponent
import com.ecosave.watch.portal.helpers.esg.EsgPages
import com.ecosave.watch.portal.models.esg.EsgReportState
import react.FC
import react.Props
import react.useState

val EsgSummaryPage = FC<Props> {

    val (visiblePage, setVisiblePage) = useState(EsgPages.ESG_REPORTS)
    val (reportState, setReportState) = useState(EsgReportState())

    if (visiblePage == EsgPages.ESG_REPORTS) {
        EsgReportsComponent {
            this.reportState = reportState
            this.setReportState = setReportState
            this.visiblePage = visiblePage
            this.setVisiblePage = setVisiblePage
        }
    } else {
        EsgMainComponent {
            this.reportState = reportState
            this.setReportState = setReportState
            this.visiblePage = visiblePage
            this.setVisiblePage = setVisiblePage
        }
    }
}