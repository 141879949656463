package com.ecosave.watch.portal.components.esg.anticorruption

import com.ecosave.watch.portal.components.esg.EsgCoreComponentProps
import com.ecosave.watch.portal.components.esg.GenericSection_3_3
import com.ecosave.watch.portal.components.esg.formcontrols.GRIStandardName
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.isValidDecimalNumber
import com.ecosave.watch.portal.helpers.common.isValidLongNumber
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.EsgSection
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import com.ecosave.watch.portal.helpers.esg.failureErrorMessage
import com.ecosave.watch.portal.helpers.esg.omitDisclosureFromUI
import com.ecosave.watch.portal.helpers.esg.resetPreviousPatchState
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.esg.AntiCorruptionState
import com.ecosave.watch.portal.models.esg.BusinessPartnerType
import com.ecosave.watch.portal.models.esg.CommunicationAndTraining
import com.ecosave.watch.portal.models.esg.CurrentInputState
import com.ecosave.watch.portal.models.esg.EmployeeCategory
import com.ecosave.watch.portal.models.esg.EmployeeCategoryTraining
import com.ecosave.watch.portal.models.esg.EsgCollectionAddRow
import com.ecosave.watch.portal.models.esg.EsgCollectionDeleteRow
import com.ecosave.watch.portal.models.esg.GovernanceBodyTraining
import com.ecosave.watch.portal.models.esg.LegalCasesOutcomes
import com.ecosave.watch.portal.models.esg.RowPatch
import com.ecosave.watch.portal.services.esg.addDynamicRowOrSection
import com.ecosave.watch.portal.services.esg.deleteDynamicRowOrSection
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import kotlinx.coroutines.launch
import kotlinx.serialization.json.JsonPrimitive
import mui.material.Box
import react.FC
import react.dom.events.FocusEvent
import react.dom.events.FormEvent
import web.html.HTMLDivElement
import web.html.HTMLElement
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement

val AntiCorruptionComponent = FC<EsgCoreComponentProps> { props ->

    val reportState = props.reportState
    val setReportState = props.setReportState
    val griStandard = EsgSection.ANTI_CORRUPTION

    reportState.report?.antiCorruption?.let { state ->

        fun stateSetter(state: AntiCorruptionState) {
            setReportState(
                reportState.copy(
                    report = reportState.report.copy(
                        antiCorruption = state
                    )
                )
            )
        }

        val addRowOrSection: (String) -> Unit = { fieldName ->
            resetPreviousPatchState(props.setInputState)
            mainScope.launch {
                props.setAddDeleteRowOrSection(DynamicTableAction.ADD)
                props.setAddDeleteRowOrSectionApiCallInProgress(true)
                val apiCallStatus = addDynamicRowOrSection(
                    EsgCollectionAddRow(
                        objectName = griStandard.name,
                        reportFileName = reportState.reportFileName,
                        patchCollection = RowPatch(
                            pathFirst = fieldName
                        )
                    )
                )

                val newState = state.copy()

                if (apiCallStatus == ApiCallStatus.SUCCESS) {
                    when (fieldName) {
                        state::legalCasesOutcomesList.name -> {
                            newState.legalCasesOutcomesList.add(LegalCasesOutcomes())
                        }

                        state::communicationAndTrainingList.name -> {
                            newState.communicationAndTrainingList.add(CommunicationAndTraining())
                        }

                        state::employeeCategoryList.name -> {
                            newState.employeeCategoryList.add(EmployeeCategory())
                        }

                        state::businessPartnerTypeList.name -> {
                            newState.businessPartnerTypeList.add(BusinessPartnerType())
                        }

                        state::governanceBodyTrainingList.name -> {
                            newState.governanceBodyTrainingList.add(GovernanceBodyTraining())
                        }

                        state::employeeCategoryTrainingList.name -> {
                            newState.employeeCategoryTrainingList.add(EmployeeCategoryTraining())
                        }
                    }

                    stateSetter(newState)

                    props.setNotificationMessage(ReportConstants.ADD_ROW_NOTIFICATION_MESSAGE)
                    props.setNotificationStatus(NotificationStatus.SUCCESS)
                    props.setOpenAlertNotifications(true)
                } else {
                    failureErrorMessage(
                        props.setNotificationMessage,
                        props.setNotificationStatus,
                        props.setOpenAlertNotifications
                    )
                }

                props.setAddDeleteRowOrSectionApiCallInProgress(false)
            }
        }

        val deleteRowOrSection: (String, Int) -> Unit = { fieldName, index ->
            resetPreviousPatchState(props.setInputState)
            mainScope.launch {
                props.setAddDeleteRowOrSection(DynamicTableAction.DELETE)
                props.setAddDeleteRowOrSectionApiCallInProgress(true)
                val apiCallStatus = deleteDynamicRowOrSection(
                    EsgCollectionDeleteRow(
                        objectPatchEnum = griStandard,
                        reportFileName = reportState.reportFileName,
                        pathFirst = fieldName,
                        indexAt = index
                    )
                )

                val newState = state.copy()

                if (apiCallStatus == ApiCallStatus.SUCCESS) {
                    when (fieldName) {
                        state::legalCasesOutcomesList.name -> {
                            newState.legalCasesOutcomesList.removeAt(index)
                        }

                        state::communicationAndTrainingList.name -> {
                            newState.communicationAndTrainingList.removeAt(index)
                        }

                        state::employeeCategoryList.name -> {
                            newState.employeeCategoryList.removeAt(index)
                        }

                        state::businessPartnerTypeList.name -> {
                            newState.businessPartnerTypeList.removeAt(index)
                        }

                        state::governanceBodyTrainingList.name -> {
                            newState.governanceBodyTrainingList.removeAt(index)
                        }

                        state::employeeCategoryTrainingList.name -> {
                            newState.employeeCategoryTrainingList.removeAt(index)
                        }
                    }

                    stateSetter(newState)

                    props.setNotificationMessage(ReportConstants.DELETE_ROW_NOTIFICATION_MESSAGE)
                    props.setNotificationStatus(NotificationStatus.SUCCESS)
                    props.setOpenAlertNotifications(true)
                } else {
                    failureErrorMessage(
                        props.setNotificationMessage,
                        props.setNotificationStatus,
                        props.setOpenAlertNotifications
                    )
                }
                props.setAddDeleteRowOrSectionApiCallInProgress(false)
            }
        }

        val onChangeTextArea: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value

            val newState = state.copy()

            if (field == state::legalCasesOutcomesList.name && changeIndex != null) {
                newState.legalCasesOutcomesList[changeIndex].let {
                    when (propertyName) {
                        it::section2053Case1.name -> it.section2053Case1 = propertyValue
                        it::section2053Outcome1.name -> it.section2053Outcome1 = propertyValue
                    }
                }
            } else {
                when (propertyName) {
                    state::section2051A3.name -> newState.section2051A3 = propertyValue

                    state::section2052Part4Field1.name -> newState.section2052Part4Field1 = propertyValue

                    state::section2053A1.name -> newState.section2053A1 = propertyValue

                    state::section3_3_205A1.name -> newState.section3_3_205A1 = propertyValue
                    state::section3_3_205A2.name -> newState.section3_3_205A2 = propertyValue
                    state::section3_3_205A3.name -> newState.section3_3_205A3 = propertyValue
                    state::section3_3_205A4.name -> newState.section3_3_205A4 = propertyValue
                    state::section3_3_205A5.name -> newState.section3_3_205A5 = propertyValue
                    state::section3_3_205A6.name -> newState.section3_3_205A6 = propertyValue
                }
            }


            stateSetter(newState)

            props.setInputState(
                CurrentInputState(
                    parentPath = field,
                    childPath = propertyName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue),
                    objectName = griStandard.name,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurTextArea: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value

            props.onBlurApiCall(
                propertyName,
                JsonPrimitive(propertyValue),
                changeIndex,
                field,
                griStandard.name
            )
        }

        val onChangeText: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, listName ->
            val target = event.target as HTMLInputElement
            val propertyName = target.name
            val propertyValue = target.value

            val newState = state.copy()

            if (listName == state::communicationAndTrainingList.name && changeIndex != null) {
                newState.communicationAndTrainingList[changeIndex].let {
                    when (propertyName) {
                        it::section2052Part2A1.name -> it.section2052Part2A1 = propertyValue
                    }
                }
            } else if (listName == state::employeeCategoryList.name && changeIndex != null) {
                newState.employeeCategoryList[changeIndex].let {
                    when (propertyName) {
                        it::section2052Part3A1.name -> it.section2052Part3A1 = propertyValue
                        it::section2052Part3B1.name -> it.section2052Part3B1 = propertyValue
                    }
                }
            } else if (listName == state::businessPartnerTypeList.name && changeIndex != null) {
                newState.businessPartnerTypeList[changeIndex].let {
                    when (propertyName) {
                        it::section2052Part4A1.name -> it.section2052Part4A1 = propertyValue
                        it::section2052Part4B1.name -> it.section2052Part4B1 = propertyValue
                    }
                }
            } else if (listName == state::governanceBodyTrainingList.name && changeIndex != null) {
                newState.governanceBodyTrainingList[changeIndex].let {
                    when (propertyName) {
                        it::section2052Part5A1.name -> it.section2052Part5A1 = propertyValue
                    }
                }
            } else if (listName == state::employeeCategoryTrainingList.name && changeIndex != null) {
                newState.employeeCategoryTrainingList[changeIndex].let {
                    when (propertyName) {
                        it::section2052Part6A1.name -> it.section2052Part6A1 = propertyValue
                        it::section2052Part6B1.name -> it.section2052Part6B1 = propertyValue
                    }
                }
            }

            stateSetter(newState)

            props.setInputState(
                CurrentInputState(
                    parentPath = listName,
                    childPath = propertyName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue),
                    objectName = griStandard.name,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurText: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            val propertyName = target.name
            val propertyValue = target.value
            props.onBlurApiCall(
                propertyName,
                JsonPrimitive(propertyValue),
                changeIndex,
                field,
                griStandard.name
            )
        }

        val onChangeNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, listName ->
            val target = event.target as HTMLInputElement
            if (isValidLongNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toLongOrNull()

                val newState = state.copy()

                if (listName == state::communicationAndTrainingList.name && changeIndex != null) {
                    newState.communicationAndTrainingList[changeIndex].let {
                        when (propertyName) {
                            it::section2052Part2B1.name -> it.section2052Part2B1 = propertyValue
                        }
                    }
                } else if (listName == state::employeeCategoryList.name && changeIndex != null) {
                    newState.employeeCategoryList[changeIndex].let {
                        when (propertyName) {
                            it::section2052Part3C1.name -> it.section2052Part3C1 = propertyValue
                        }
                    }
                } else if (listName == state::businessPartnerTypeList.name && changeIndex != null) {
                    newState.businessPartnerTypeList[changeIndex].let {
                        when (propertyName) {
                            it::section2052Part4C1.name -> it.section2052Part4C1 = propertyValue
                        }
                    }
                } else if (listName == state::governanceBodyTrainingList.name && changeIndex != null) {
                    newState.governanceBodyTrainingList[changeIndex].let {
                        when (propertyName) {
                            it::section2052Part5B1.name -> it.section2052Part5B1 = propertyValue
                        }
                    }
                } else if (listName == state::employeeCategoryTrainingList.name && changeIndex != null) {
                    newState.employeeCategoryTrainingList[changeIndex].let {
                        when (propertyName) {
                            it::section2052Part6C1.name -> it.section2052Part6C1 = propertyValue
                        }
                    }
                } else {
                    when (propertyName) {
                        state::section2051A1.name -> newState.section2051A1 = propertyValue

                        state::section2053A2.name -> newState.section2053A2 = propertyValue
                        state::section2053A3.name -> newState.section2053A3 = propertyValue
                    }
                }

                stateSetter(newState)

                props.setInputState(
                    CurrentInputState(
                        parentPath = listName,
                        childPath = propertyName,
                        index = changeIndex,
                        value = JsonPrimitive(propertyValue),
                        objectName = griStandard.name,
                        reportName = reportState.reportFileName
                    )
                )
            }
        }

        val onBlurNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidLongNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toLongOrNull()
                props.onBlurApiCall(
                    propertyName,
                    JsonPrimitive(propertyValue),
                    changeIndex,
                    field,
                    griStandard.name
                )
            }
        }

        val onChangeDecimalNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, listName ->
            val target = event.target as HTMLInputElement
            if (isValidDecimalNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toDoubleOrNull()

                val newState = state.copy()

                if (listName == state::communicationAndTrainingList.name && changeIndex != null) {
                    newState.communicationAndTrainingList[changeIndex].let {
                        when (propertyName) {
                            it::section2052Part2C1.name -> it.section2052Part2C1 = propertyValue
                        }
                    }
                } else if (listName == state::employeeCategoryList.name && changeIndex != null) {
                    newState.employeeCategoryList[changeIndex].let {
                        when (propertyName) {
                            it::section2052Part3D1.name -> it.section2052Part3D1 = propertyValue
                        }
                    }
                } else if (listName == state::businessPartnerTypeList.name && changeIndex != null) {
                    newState.businessPartnerTypeList[changeIndex].let {
                        when (propertyName) {
                            it::section2052Part4D1.name -> it.section2052Part4D1 = propertyValue
                        }
                    }
                } else if (listName == state::governanceBodyTrainingList.name && changeIndex != null) {
                    newState.governanceBodyTrainingList[changeIndex].let {
                        when (propertyName) {
                            it::section2052Part5C1.name -> it.section2052Part5C1 = propertyValue
                        }
                    }
                } else if (listName == state::employeeCategoryTrainingList.name && changeIndex != null) {
                    newState.employeeCategoryTrainingList[changeIndex].let {
                        when (propertyName) {
                            it::section2052Part6D1.name -> it.section2052Part6D1 = propertyValue
                        }
                    }
                } else {
                    when (propertyName) {
                        state::section2051A2.name -> newState.section2051A2 = propertyValue
                    }
                }

                stateSetter(newState)

                props.setInputState(
                    CurrentInputState(
                        parentPath = listName,
                        childPath = propertyName,
                        index = changeIndex,
                        value = JsonPrimitive(propertyValue),
                        objectName = griStandard.name,
                        reportName = reportState.reportFileName
                    )
                )
            }
        }

        val onBlurDecimalNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidDecimalNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toDoubleOrNull()
                props.onBlurApiCall(
                    propertyName,
                    JsonPrimitive(propertyValue),
                    changeIndex,
                    field,
                    griStandard.name
                )
            }
        }


        Box {
            className = ESGCommonStyles.SECTION_MAIN_LAYOUT.cssClass

            GRIStandardName {
                this.griStandard = griStandard
            }

            GenericSection_3_3 {
                this.onBlurTextArea = onBlurTextArea
                this.onChangeTextArea = onChangeTextArea
                this.materialTopicName = griStandard.description

                this.section3_3_A1_name = state::section3_3_205A1.name
                this.section3_3_A1_value = state.section3_3_205A1

                this.section3_3_A2_name = state::section3_3_205A2.name
                this.section3_3_A2_value = state.section3_3_205A2

                this.section3_3_A3_name = state::section3_3_205A3.name
                this.section3_3_A3_value = state.section3_3_205A3

                this.section3_3_A4_name = state::section3_3_205A4.name
                this.section3_3_A4_value = state.section3_3_205A4

                this.section3_3_A5_name = state::section3_3_205A5.name
                this.section3_3_A5_value = state.section3_3_205A5

                this.section3_3_A6_name = state::section3_3_205A6.name
                this.section3_3_A6_value = state.section3_3_205A6
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_205_1)) {
                Disclosure_205_1 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeNumber = onChangeNumber
                    this.onBlurNumber = onBlurNumber
                    this.onChangeDecimalNumber = onChangeDecimalNumber
                    this.onBlurDecimalNumber = onBlurDecimalNumber
                }
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_205_2)) {
                Disclosure_205_2 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeText = onChangeText
                    this.onBlurText = onBlurText
                    this.onChangeNumber = onChangeNumber
                    this.onBlurNumber = onBlurNumber
                    this.onChangeDecimalNumber = onChangeDecimalNumber
                    this.onBlurDecimalNumber = onBlurDecimalNumber
                    this.addRowOrSection = addRowOrSection
                    this.deleteRowOrSection = deleteRowOrSection
                }
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_205_3)) {
                Disclosure_205_3 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeNumber = onChangeNumber
                    this.onBlurNumber = onBlurNumber
                    this.addRowOrSection = addRowOrSection
                    this.deleteRowOrSection = deleteRowOrSection
                }
            }
        }

    }
}