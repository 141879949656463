package com.ecosave.watch.portal.components.utilityaccounts

import com.ecosave.watch.portal.helpers.billing.BillingHeaders
import com.ecosave.watch.portal.helpers.billing.DemandUnit
import com.ecosave.watch.portal.models.billing.utilitybillstate.UtilityBillState
import com.ecosave.watch.portal.styles.BillingInputFormStyles
import mui.material.FormControl
import mui.material.InputLabel
import mui.material.MenuItem
import mui.material.Select
import mui.material.Size
import react.FC
import react.Props
import react.ReactNode
import react.StateSetter

external interface AddDemandUnitProps : Props {
    var stateDemandUnit: UtilityBillState
    var setStateDemandUnit: StateSetter<UtilityBillState>
    var demandUnits: List<DemandUnit>
}

val AddDemandUnit = FC<AddDemandUnitProps> { props ->

    val state = props.stateDemandUnit
    val setState = props.setStateDemandUnit
    val demandUnits = props.demandUnits

    FormControl {
        className = BillingInputFormStyles.INPUT_ITEM.cssClass
        InputLabel {
            +BillingHeaders.DEMAND_UNIT.header
        }
        size = Size.medium
        Select {
            label = ReactNode(BillingHeaders.DEMAND_UNIT.header)
            value = state.demandUnit
            onChange = { event, _ ->
                setState(
                    state.copy(
                        demandUnit = event.target.value
                    )
                )
            }
            for (unit in demandUnits) {
                MenuItem {
                    value = unit.name
                    +unit.description
                }
            }
        }
    }
}