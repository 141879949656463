package com.ecosave.watch.portal.helpers.common

enum class YesNoEnum(val description: String) {
    YES("Yes"),
    NO("No")
}

enum class FormMode {
    ADD,
    EDIT
}

enum class PageTitles(val title: String) {
    CONTROL_CENTER("Control Center"),
    ASSET_MANAGEMENT("Asset Management"),
    FACILITY_OVERVIEW("Facility Overview"),
    PORTFOLIO_OVERVIEW("Portfolio Overview"),
    OBSERVATION_SUMMARY("Observation Summary"),
    ACTION_MANAGEMENT("Action Management"),
    UTILITY_ACCOUNTS("Utility Accounts"),
    UTILITY_BILLS("Utility Bills"),
    ESG_REPORTING("Esg Reports"),
    FACILITY_MANAGEMENT("Facility Management"),
    FACILITY_GROUP_MANAGEMENT("Facility Group Management"),
    USER_MANAGEMENT("User Management"),
    RESOURCE_CENTER("Resource Center"),
    USER_PROFILE("User Profile"),
    USER_PREFERENCES("User Preferences"),
    ABOUT_US("About Us"),
}

enum class PageRoutes(val route: String) {
    DEFAULT("/"),
    CONTROL_CENTER("/control-center"),
    ASSET_MANAGEMENT("/asset-management"),
    FACILITY_OVERVIEW("/facility-overview"),
    PORTFOLIO_OVERVIEW("/portfolio-overview"),
    OBSERVATION_SUMMARY("/observations"),
    ACTION_MANAGEMENT("/action-management"),
    UTILITY_ACCOUNTS("/utility-accounts"),
    UTILITY_BILLS("/utility-bills"),
    ESG_REPORTING("/summary"),
    ONBOARDING("/onboarding"),
    FACILITY_MANAGEMENT("/facility-management"),
    FACILITY_GROUP_MANAGEMENT("/facility-groups"),
    USER_MANAGEMENT("/user-management"),
    RESOURCE_CENTER("/resource-center"),
    USER_PROFILE("/user-profile"),
    USER_PREFERENCES("/user-preferences"),
    ABOUT_US("/about-us"),
    SUBSCRIPTION_PAYMENT("/subscription-payment"),
    SIGN_IN("/signin"),
    PAGE_NOT_FOUND("/page-not-found")
}

enum class NotificationStatus {
    SUCCESS,
    ERROR,
    INFO
}

enum class ApiCallStatus {
    SUCCESS,
    FAILURE
}