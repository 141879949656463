package com.ecosave.watch.portal.models.usermanagement

import com.ecosave.watch.portal.helpers.usermanagement.UserRole
import kotlinx.serialization.Serializable

@Serializable
data class InvitedUserDetail(
    var firstName: String?,
    var lastName: String?,
    var jobTitle: String?,
    var emailAddress: String,
    var userRole: UserRole,
    var userRegistrationCompleted: Boolean
)