package com.ecosave.watch.portal.components.esg.supplierenvironmentalassessment

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val GRI_308_1 = FC<EsgSubFormComponentProps> { props ->
    val onChangeDecimalNumber = props.onChangeDecimalNumber
    val onBlurDecimalNumber = props.onBlurDecimalNumber

    props.esgReportState.report?.supplierEnvironmentalAssessment?.let { state ->
        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_308_1
        }

        Box {
            className = ESGCommonStyles.TWO_COLUMN_TABLE_8_4.cssClass

            label {
                +"Percentage of new suppliers that were screened using environmental criteria."
            }
            ESGNumberFieldWrapper {
                name = state::section3081.name
                value = state.section3081
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }
        }
    }
}