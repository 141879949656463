package com.ecosave.watch.portal.components.esg.diversityandequalopportunity

import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val GRI_405_2 = FC<EsgSubFormComponentProps> { props ->
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea
    val onChangeDecimalNumber = props.onChangeDecimalNumber
    val onBlurDecimalNumber = props.onBlurDecimalNumber

    props.esgReportState.report?.diversityAndEqualOpportunity?.let { state ->
        val basicSalaryAndRemunerationRatioListName = state::basicSalaryAndRemunerationRatioList.name

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_405_2
        }
        DisclosureSubHeading {
            subHeading =
                "For each employee category within each significant location, calculate the following:"
        }

        Box {
            className = ESGCommonStyles.TABLE_WRAPPER.cssClass
            DynamicTableWrapper {
                addRowOrSection = {
                    props.addRowOrSection(basicSalaryAndRemunerationRatioListName)
                }
                items = state.basicSalaryAndRemunerationRatioList
                action = DynamicTableAction.ADD
                addRowOrSectionBtnText = ReportConstants.ADD_ROW_MESSAGE
                Box {
                    className = ESGCommonStyles.FOUR_COLUMN_TABLE.cssClass
                    TableHeader {
                        header = "Employee Category"
                    }
                    TableHeader {
                        header = "Significant Location"
                    }
                    TableHeader {
                        header = "Basic Salary Ratio (Women to Men)"
                    }
                    TableHeader {
                        header = "Remuneration Ratio (Women to Men)"
                    }
                }
            }
            state.basicSalaryAndRemunerationRatioList.forEachIndexed { index, item ->
                DynamicTableWrapper {
                    key = item.key
                    deleteRowOrSection = {
                        props.deleteRowOrSection(basicSalaryAndRemunerationRatioListName, index)
                    }
                    items = state.basicSalaryAndRemunerationRatioList
                    action = DynamicTableAction.DELETE
                    variant = DynamicTableVariant.TABLE
                    Box {
                        className = ESGCommonStyles.FOUR_COLUMN_TABLE.cssClass
                        ESGTextAreaFieldWrapper {
                            name = item::section4052A1.name
                            value = item.section4052A1
                            onChange = { event -> onChangeTextArea(event, index, basicSalaryAndRemunerationRatioListName) }
                            onBlur = { event -> onBlurTextArea(event, index, basicSalaryAndRemunerationRatioListName) }
                            minimumRows = 1
                            maximumRows = 1
                        }
                        ESGTextAreaFieldWrapper {
                            name = item::section4052A2.name
                            value = item.section4052A2
                            onChange = { event -> onChangeTextArea(event, index, basicSalaryAndRemunerationRatioListName) }
                            onBlur = { event -> onBlurTextArea(event, index, basicSalaryAndRemunerationRatioListName) }
                            minimumRows = 1
                            maximumRows = 1
                        }
                        ESGNumberFieldWrapper {
                            name = item::section4052A3.name
                            value = item.section4052A3
                            onChange = { event -> onChangeDecimalNumber(event, index, basicSalaryAndRemunerationRatioListName) }
                            onBlur = { event -> onBlurDecimalNumber(event, index, basicSalaryAndRemunerationRatioListName) }
                        }
                        ESGNumberFieldWrapper {
                            name = item::section4052A4.name
                            value = item.section4052A4
                            onChange = { event -> onChangeDecimalNumber(event, index, basicSalaryAndRemunerationRatioListName) }
                            onBlur = { event -> onBlurDecimalNumber(event, index, basicSalaryAndRemunerationRatioListName) }
                        }
                    }
                }
            }
        }

        Box {
            label {
                +"What's the definition used for ‘significant locations of operation’?"
            }
            ESGTextAreaFieldWrapper {
                name = state::section4052Field1.name
                value = state.section4052Field1
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}