package com.ecosave.watch.portal.components.esg.generaldisclosures.governance

import com.ecosave.watch.portal.components.esg.EsgCoreComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.GRIDisclosureGroupName
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.isValidDecimalNumber
import com.ecosave.watch.portal.helpers.common.isValidLongNumber
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.EsgSubSection
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import com.ecosave.watch.portal.helpers.esg.failureErrorMessage
import com.ecosave.watch.portal.helpers.esg.omitDisclosureFromUI
import com.ecosave.watch.portal.helpers.esg.resetPreviousPatchState
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.esg.CurrentInputState
import com.ecosave.watch.portal.models.esg.EsgCollectionAddRow
import com.ecosave.watch.portal.models.esg.GovernanceState
import com.ecosave.watch.portal.models.esg.RowPatch
import com.ecosave.watch.portal.models.esg.Section2_9
import com.ecosave.watch.portal.services.esg.addDynamicRowOrSection
import com.ecosave.watch.portal.services.esg.deleteDynamicRowOrSection
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import kotlinx.coroutines.launch
import kotlinx.serialization.json.JsonPrimitive
import mui.material.Box
import react.FC
import react.dom.events.FocusEvent
import react.dom.events.FormEvent
import web.html.HTMLDivElement
import web.html.HTMLElement
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement

val GovernanceComponent = FC<EsgCoreComponentProps> { props ->

    val reportState = props.reportState
    val setReportState = props.setReportState
    val disclosureGroupName = EsgSubSection.GOVERNANCE.name

    reportState.report?.governance?.let { state ->

        fun stateSetter(state: GovernanceState) {
            setReportState(
                reportState.copy(
                    report = reportState.report.copy(
                        governance = state
                    )
                )
            )
        }

        val addRowOrSection: (String) -> Unit = { fieldName ->
            resetPreviousPatchState(props.setInputState)
            mainScope.launch {
                props.setAddDeleteRowOrSection(DynamicTableAction.ADD)
                props.setAddDeleteRowOrSectionApiCallInProgress(true)
                val apiCallStatus = addDynamicRowOrSection(
                    EsgCollectionAddRow(
                        objectName = disclosureGroupName,
                        reportFileName = reportState.reportFileName,
                        patchCollection = RowPatch(
                            pathFirst = fieldName
                        )
                    )
                )

                val newState = state.copy()

                if (apiCallStatus == ApiCallStatus.SUCCESS) {
                    when (fieldName) {
                        state::section2_9List.name -> {
                            newState.section2_9List.add(Section2_9())
                            props.setNotificationMessage(ReportConstants.ADD_SECTION_NOTIFICATION_MESSAGE)
                        }
                    }

                    stateSetter(newState)

                    props.setNotificationStatus(NotificationStatus.SUCCESS)
                    props.setOpenAlertNotifications(true)
                } else {
                    failureErrorMessage(
                        props.setNotificationMessage,
                        props.setNotificationStatus,
                        props.setOpenAlertNotifications
                    )
                }
                props.setAddDeleteRowOrSectionApiCallInProgress(false)
            }
        }

        val deleteRowOrSection: (String, Int) -> Unit = { fieldName, index ->
            resetPreviousPatchState(props.setInputState)
            mainScope.launch {
                props.setAddDeleteRowOrSection(DynamicTableAction.DELETE)
                props.setAddDeleteRowOrSectionApiCallInProgress(true)
                val apiCallStatus = deleteDynamicRowOrSection(
                    objectPatchEnum = disclosureGroupName,
                    reportFileName = reportState.reportFileName,
                    pathFirst = fieldName,
                    indexAt = index
                )

                val newState = state.copy()

                if (apiCallStatus == ApiCallStatus.SUCCESS) {
                    when (fieldName) {
                        state::section2_9List.name -> {
                            newState.section2_9List.removeAt(index)
                            props.setNotificationMessage(ReportConstants.DELETE_SECTION_NOTIFICATION_MESSAGE)
                        }
                    }

                    stateSetter(newState)

                    props.setNotificationStatus(NotificationStatus.SUCCESS)
                    props.setOpenAlertNotifications(true)
                } else {
                    failureErrorMessage(
                        props.setNotificationMessage,
                        props.setNotificationStatus,
                        props.setOpenAlertNotifications
                    )
                }
                props.setAddDeleteRowOrSectionApiCallInProgress(false)
            }
        }

        val onChangeTextArea: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, listName ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value

            val newState = state.copy()

            if (listName == state::section2_9List.name && changeIndex != null) {
                newState.section2_9List[changeIndex].let {
                    when (propertyName) {
                        it::section29A6.name -> it.section29A6 = propertyValue
                        it::section29A8.name -> it.section29A8 = propertyValue
                        it::section29A9.name -> it.section29A9 = propertyValue
                        it::section29A10.name -> it.section29A10 = propertyValue
                    }
                }
            } else {
                when (propertyName) {
                    state::section29Field1.name -> newState.section29Field1 = propertyValue
                    state::section29Field2.name -> newState.section29Field2 = propertyValue

                    state::section210A1.name -> newState.section210A1 = propertyValue
                    state::section210A2.name -> newState.section210A2 = propertyValue
                    state::section210A3.name -> newState.section210A3 = propertyValue
                    state::section210A4.name -> newState.section210A4 = propertyValue
                    state::section210A5.name -> newState.section210A5 = propertyValue

                    state::section211A1.name -> newState.section211A1 = propertyValue
                    state::section211A2.name -> newState.section211A2 = propertyValue

                    state::section212A1.name -> newState.section212A1 = propertyValue
                    state::section212A2.name -> newState.section212A2 = propertyValue
                    state::section212A3.name -> newState.section212A3 = propertyValue
                    state::section212A4.name -> newState.section212A4 = propertyValue
                    state::section212A5.name -> newState.section212A5 = propertyValue

                    state::section213A1.name -> newState.section213A1 = propertyValue
                    state::section213A2.name -> newState.section213A2 = propertyValue
                    state::section213A3.name -> newState.section213A3 = propertyValue
                    state::section213A4.name -> newState.section213A4 = propertyValue

                    state::section214A1.name -> newState.section214A1 = propertyValue
                    state::section214A2.name -> newState.section214A2 = propertyValue

                    state::section215A6.name -> newState.section215A6 = propertyValue
                    state::section215A7.name -> newState.section215A7 = propertyValue
                    state::section215A1.name -> newState.section215A1 = propertyValue
                    state::section215A2.name -> newState.section215A2 = propertyValue
                    state::section215A3.name -> newState.section215A3 = propertyValue
                    state::section215A4.name -> newState.section215A4 = propertyValue

                    state::section216A1.name -> newState.section216A1 = propertyValue
                    state::section216A3.name -> newState.section216A3 = propertyValue

                    state::section217A1.name -> newState.section217A1 = propertyValue

                    state::section218A1.name -> newState.section218A1 = propertyValue
                    state::section218A2.name -> newState.section218A2 = propertyValue
                    state::section218A3.name -> newState.section218A3 = propertyValue

                    state::section219A8.name -> newState.section219A8 = propertyValue
                    state::section219A7.name -> newState.section219A7 = propertyValue

                    state::section220A1.name -> newState.section220A1 = propertyValue
                    state::section220A2.name -> newState.section220A2 = propertyValue
                    state::section220A3.name -> newState.section220A3 = propertyValue
                    state::section220A4.name -> newState.section220A4 = propertyValue
                    state::section220A5.name -> newState.section220A5 = propertyValue

                    state::section221Field1.name -> newState.section221Field1 = propertyValue
                }
            }

            stateSetter(newState)

            props.setInputState(
                CurrentInputState(
                    parentPath = listName,
                    childPath = propertyName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue),
                    objectName = disclosureGroupName,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurTextArea: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value
            props.onBlurApiCall(
                propertyName,
                JsonPrimitive(propertyValue),
                changeIndex,
                field,
                disclosureGroupName
            )
        }

        val onChangeText: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, listName ->
            val target = event.target as HTMLInputElement
            val propertyName = target.name
            val propertyValue = target.value

            val newState = state.copy()

            if (listName == state::section2_9List.name && changeIndex != null) {
                newState.section2_9List[changeIndex].let {
                    when (propertyName) {
                        it::section29A1.name -> it.section29A1 = propertyValue
                        it::section29A4.name -> it.section29A4 = propertyValue
                        it::section29A5.name -> it.section29A5 = propertyValue
                        it::section29A7.name -> it.section29A7 = propertyValue
                    }
                }
            }

            stateSetter(newState)

            props.setInputState(
                CurrentInputState(
                    parentPath = listName,
                    childPath = propertyName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue),
                    objectName = disclosureGroupName,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurText: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            val propertyName = target.name
            val propertyValue = target.value
            props.onBlurApiCall(
                propertyName,
                JsonPrimitive(propertyValue),
                changeIndex,
                field,
                disclosureGroupName
            )
        }

        val onChangeNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, listName ->
            val target = event.target as HTMLInputElement
            if (isValidLongNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toLongOrNull()

                val newState = state.copy()

                if (listName == state::section2_9List.name && changeIndex != null) {
                    newState.section2_9List[changeIndex].let {
                        when (propertyName) {
                            it::section29A2.name -> it.section29A2 = propertyValue
                            it::section29A3.name -> it.section29A3 = propertyValue
                        }
                    }
                } else {
                    when (propertyName) {
                        state::section216A2.name -> newState.section216A2 = propertyValue
                    }
                }

                stateSetter(newState)

                props.setInputState(
                    CurrentInputState(
                        parentPath = listName,
                        childPath = propertyName,
                        index = changeIndex,
                        value = JsonPrimitive(propertyValue),
                        disclosureGroupName,
                        reportName = reportState.reportFileName
                    )
                )
            }
        }

        val onBlurNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidLongNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toLongOrNull()
                props.onBlurApiCall(
                    propertyName,
                    JsonPrimitive(propertyValue),
                    changeIndex,
                    field,
                    disclosureGroupName
                )
            }
        }

        val onChangeDecimalNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidDecimalNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toDoubleOrNull()

                val newState = state.copy()

                when (propertyName) {
                    state::section221B1.name -> newState.section221B1 = propertyValue
                    state::section221B2.name -> newState.section221B2 = propertyValue
                    state::section221B4.name -> newState.section221B4 = propertyValue
                    state::section221B5.name -> newState.section221B5 = propertyValue
                }

                stateSetter(newState)

                props.setInputState(
                    CurrentInputState(
                        parentPath = field,
                        childPath = propertyName,
                        index = changeIndex,
                        value = JsonPrimitive(propertyValue),
                        objectName = disclosureGroupName,
                        reportName = reportState.reportFileName
                    )
                )
            }
        }

        val onBlurDecimalNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidDecimalNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toDoubleOrNull()
                props.onBlurApiCall(
                    propertyName,
                    JsonPrimitive(propertyValue),
                    changeIndex,
                    field,
                    disclosureGroupName
                )
            }
        }

        Box {
            className = ESGCommonStyles.SECTION_MAIN_LAYOUT.cssClass

            GRIDisclosureGroupName {
                griDisclosureGroup = EsgSubSection.GOVERNANCE
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_2_9)) {
                Disclosure_2_9 {
                    this.esgReportState = reportState
                    this.onChangeText = onChangeText
                    this.onBlurText = onBlurText
                    this.onChangeNumber = onChangeNumber
                    this.onBlurNumber = onBlurNumber
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.addRowOrSection = addRowOrSection
                    this.deleteRowOrSection = deleteRowOrSection
                }
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_2_10)) {
                Disclosure_2_10 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                }
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_2_11)) {
                Disclosure_2_11 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                }
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_2_12)) {
                Disclosure_2_12 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                }
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_2_13)) {
                Disclosure_2_13 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                }
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_2_14)) {
                Disclosure_2_14 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                }
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_2_15)) {
                Disclosure_2_15 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                }
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_2_16)) {
                Disclosure_2_16 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeNumber = onChangeNumber
                    this.onBlurNumber = onBlurNumber
                }
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_2_17)) {
                Disclosure_2_17 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                }
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_2_18)) {
                Disclosure_2_18 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                }
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_2_19)) {
                Disclosure_2_19 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                }
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_2_20)) {
                Disclosure_2_20 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                }
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_2_21)) {
                Disclosure_2_21 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeDecimalNumber = onChangeDecimalNumber
                    this.onBlurDecimalNumber = onBlurDecimalNumber
                }
            }
        }
    }
}