package com.ecosave.watch.portal.components.esg.biodiversity

import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.TextAreaFieldMapper
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML
import react.dom.onChange

val GRI_304_3 = FC<EsgSubFormComponentProps> { props ->
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.biodiversity?.let { state ->
        val listName = state::habitatsProtectedOrRestoredList.name

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_304_3
        }

        Box {
            className = ESGCommonStyles.STICKY_HEADER.cssClass
            DynamicTableWrapper {
                addRowOrSection = {
                    props.addRowOrSection(listName)
                }
                items = state.habitatsProtectedOrRestoredList
                action = DynamicTableAction.ADD
                variant = DynamicTableVariant.TABLE
                addRowOrSectionBtnText = "Add new habitat details"
            }
        }

        state.habitatsProtectedOrRestoredList.forEachIndexed { index, habitatsProtectedOrRestored ->
            DynamicTableWrapper {
                key = habitatsProtectedOrRestored.key
                deleteRowOrSection = { props.deleteRowOrSection(listName, index) }
                items = state.biodiversityGeographicalLocationList
                action = DynamicTableAction.DELETE
                variant = DynamicTableVariant.LIST
                Box {
                    className = ESGCommonStyles.DYNAMIC_TABLE_SECTION_LAYOUT.cssClass
                    DisclosureSubHeading {
                        subHeading = "Habitat information  ${index + 1}"
                    }

                    listOf(
                        TextAreaFieldMapper(
                            "Size and location of all habitat areas protected or restored, and whether the " +
                                "success of the restoration measure was or is approved by independent external " +
                                "professionals.",
                            habitatsProtectedOrRestored::section3043A1.name,
                            habitatsProtectedOrRestored.section3043A1
                        ),
                        TextAreaFieldMapper(
                            "Whether partnerships exist with third parties to protect or restore habitat areas " +
                                "distinct from where the organization has overseen and implemented restoration or " +
                                "protection measures.",
                            habitatsProtectedOrRestored::section3043A4.name,
                            habitatsProtectedOrRestored.section3043A4
                        ),
                        TextAreaFieldMapper(
                            "Status of each area based on its condition at the close of the reporting period.",
                            habitatsProtectedOrRestored::section3043A5.name,
                            habitatsProtectedOrRestored.section3043A5
                        ),
                        TextAreaFieldMapper(
                            "Standards, methodologies, and assumptions used.",
                            habitatsProtectedOrRestored::section3043A6.name,
                            habitatsProtectedOrRestored.section3043A6
                        ),
                    ).forEach { item ->
                        ReactHTML.label {
                            +item.label.mainHeader
                        }
                        ESGTextAreaFieldWrapper {
                            name = item.name
                            value = item.value
                            onChange = { event -> onChangeTextArea(event, index, listName) }
                            onBlur = { event -> onBlurTextArea(event, index, listName) }
                            minimumRows = 2
                        }
                    }
                }
            }
        }
    }
}