package com.ecosave.watch.portal.components.esg.formcontrols

import com.ecosave.watch.portal.helpers.Colors
import com.ecosave.watch.portal.helpers.esg.EsgSection
import com.ecosave.watch.portal.helpers.esg.EsgSubSection
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML
import web.cssom.Color
import web.cssom.TextAlign
import web.cssom.px

external interface DisclosureNameProps : Props {
    var disclosure: GriDisclosureTitles
}

val DisclosureName = FC<DisclosureNameProps> {
    ReactHTML.label {
        css {
            color = Color(Colors.AQUA)
            fontSize = 22.px
        }
        +it.disclosure.disclosureTitle
    }
}

external interface DisclosureSubHeadingProps : Props {
    var subHeading: String
}

val DisclosureSubHeading = FC<DisclosureSubHeadingProps> {
    ReactHTML.label {
        css {
            color = Color(Colors.HALF_GREEN)
            fontSize = 20.px
        }
        +it.subHeading
    }
}

external interface GRIStandardNameProps : Props {
    var griStandard: EsgSection
}

val GRIStandardName = FC<GRIStandardNameProps> {
    ReactHTML.label {
        css {
            color = Color(Colors.GREEN)
            fontSize = 30.px
            textAlign = TextAlign.center
            marginBottom = 5.px
        }
        +it.griStandard.description
    }
}

external interface GRIDisclosureGroupNameProps : Props {
    var griDisclosureGroup: EsgSubSection
}

val GRIDisclosureGroupName = FC<GRIDisclosureGroupNameProps> {
    ReactHTML.label {
        css {
            color = Color(Colors.GREEN)
            fontSize = 30.px
            textAlign = TextAlign.center
            marginBottom = 5.px
        }
        +it.griDisclosureGroup.subSection
    }
}