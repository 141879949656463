package com.ecosave.watch.portal.components.esg.generaldisclosures.activitiesandworkers

import com.ecosave.watch.portal.components.esg.EsgCoreComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.GRIDisclosureGroupName
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.isValidLongNumber
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.EsgSubSection
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import com.ecosave.watch.portal.helpers.esg.failureErrorMessage
import com.ecosave.watch.portal.helpers.esg.omitDisclosureFromUI
import com.ecosave.watch.portal.helpers.esg.resetPreviousPatchState
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.esg.ActivitiesAndWorkersState
import com.ecosave.watch.portal.models.esg.CurrentInputState
import com.ecosave.watch.portal.models.esg.EmployeesByRegion1
import com.ecosave.watch.portal.models.esg.EmployeesByRegion2
import com.ecosave.watch.portal.models.esg.EsgCollectionAddRow
import com.ecosave.watch.portal.models.esg.RowPatch
import com.ecosave.watch.portal.models.esg.Section28
import com.ecosave.watch.portal.services.esg.addDynamicRowOrSection
import com.ecosave.watch.portal.services.esg.deleteDynamicRowOrSection
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import kotlinx.coroutines.launch
import kotlinx.serialization.json.JsonPrimitive
import mui.material.Box
import react.FC
import react.dom.events.FocusEvent
import react.dom.events.FormEvent
import web.html.HTMLDivElement
import web.html.HTMLElement
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement

val ActivitiesAndWorkersComponent = FC<EsgCoreComponentProps> { props ->

    val reportState = props.reportState
    val setReportState = props.setReportState

    reportState.report?.activitiesAndWorkers?.let { state ->

        fun stateSetter(state: ActivitiesAndWorkersState) {
            setReportState(
                reportState.copy(
                    report = reportState.report.copy(
                        activitiesAndWorkers = state
                    )
                )
            )
        }

        val addRowOrSection: (String) -> Unit = { fieldName ->
            resetPreviousPatchState(props.setInputState)
            mainScope.launch {
                props.setAddDeleteRowOrSection(DynamicTableAction.ADD)
                props.setAddDeleteRowOrSectionApiCallInProgress(true)
                val apiCallStatus = addDynamicRowOrSection(
                    EsgCollectionAddRow(
                        objectName = EsgSubSection.ACTIVITIES_AND_WORKERS.name,
                        reportFileName = reportState.reportFileName,
                        patchCollection = RowPatch(
                            pathFirst = fieldName
                        )
                    )
                )

                val newState = state.copy()

                if (apiCallStatus == ApiCallStatus.SUCCESS) {
                    when (fieldName) {
                        state::employeesByRegionList1.name -> {
                            newState.employeesByRegionList1.add(EmployeesByRegion1())
                        }

                        state::employeesByRegionList2.name -> {
                            newState.employeesByRegionList2.add(EmployeesByRegion2())
                        }

                        state::section28list.name -> {
                            newState.section28list.add(Section28())
                        }
                    }

                    stateSetter(newState)

                    props.setNotificationMessage(ReportConstants.ADD_ROW_NOTIFICATION_MESSAGE)
                    props.setNotificationStatus(NotificationStatus.SUCCESS)
                    props.setOpenAlertNotifications(true)
                } else {
                    failureErrorMessage(
                        props.setNotificationMessage,
                        props.setNotificationStatus,
                        props.setOpenAlertNotifications
                    )
                }
                props.setAddDeleteRowOrSectionApiCallInProgress(false)
            }
        }

        val deleteRowOrSection: (String, Int) -> Unit = { fieldName, index ->
            resetPreviousPatchState(props.setInputState)
            mainScope.launch {
                props.setAddDeleteRowOrSection(DynamicTableAction.DELETE)
                props.setAddDeleteRowOrSectionApiCallInProgress(true)
                val apiCallStatus = deleteDynamicRowOrSection(
                    objectPatchEnum = EsgSubSection.ACTIVITIES_AND_WORKERS.name,
                    reportFileName = reportState.reportFileName,
                    pathFirst = fieldName,
                    indexAt = index
                )

                val newState = state.copy()

                if (apiCallStatus == ApiCallStatus.SUCCESS) {
                    when (fieldName) {
                        state::employeesByRegionList1.name -> {
                            newState.employeesByRegionList1.removeAt(index)
                        }

                        state::employeesByRegionList2.name -> {
                            newState.employeesByRegionList2.removeAt(index)
                        }

                        state::section28list.name -> {
                            newState.section28list.removeAt(index)
                        }
                    }

                    stateSetter(newState)

                    props.setNotificationMessage(ReportConstants.DELETE_ROW_NOTIFICATION_MESSAGE)
                    props.setNotificationStatus(NotificationStatus.SUCCESS)
                    props.setOpenAlertNotifications(true)
                } else {
                    failureErrorMessage(
                        props.setNotificationMessage,
                        props.setNotificationStatus,
                        props.setOpenAlertNotifications
                    )
                }
                props.setAddDeleteRowOrSectionApiCallInProgress(false)
            }
        }

        val onChangeTextArea: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, listName ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value

            val newState = state.copy()

            if (listName == state::section28list.name && changeIndex != null) {
                newState.section28list[changeIndex].let {
                    when (propertyName) {
                        it::section28Field4.name -> it.section28Field4 = propertyValue
                        it::section28Field5.name -> it.section28Field5 = propertyValue
                        it::section28Field6.name -> it.section28Field6 = propertyValue
                    }
                }
            } else {
                when (propertyName) {
                    state::section26Field1.name -> newState.section26Field1 = propertyValue
                    state::section26Field2.name -> newState.section26Field2 = propertyValue
                    state::section26Field3.name -> newState.section26Field3 = propertyValue
                    state::section26Field4.name -> newState.section26Field4 = propertyValue
                    state::section26Field5.name -> newState.section26Field5 = propertyValue
                    state::section26Field6.name -> newState.section26Field6 = propertyValue

                    state::section27Field1.name -> newState.section27Field1 = propertyValue
                    state::section27Field2.name -> newState.section27Field2 = propertyValue
                    state::section27Field3.name -> newState.section27Field3 = propertyValue

                    state::section28Field1.name -> newState.section28Field1 = propertyValue
                    state::section28Field2.name -> newState.section28Field2 = propertyValue
                    state::section28Field3.name -> newState.section28Field3 = propertyValue
                }
            }

            stateSetter(newState)

            props.setInputState(
                CurrentInputState(
                    parentPath = listName,
                    childPath = propertyName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue),
                    objectName = EsgSubSection.ACTIVITIES_AND_WORKERS.name,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurTextArea: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value
            props.onBlurApiCall(
                propertyName,
                JsonPrimitive(propertyValue),
                changeIndex,
                field,
                EsgSubSection.ACTIVITIES_AND_WORKERS.name
            )
        }

        val onChangeText: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, listName ->
            val target = event.target as HTMLInputElement
            val propertyName = target.name
            val propertyValue = target.value

            val newState = state.copy()

            if (listName == state::employeesByRegionList1.name && changeIndex != null) {
                newState.employeesByRegionList1[changeIndex].let {
                    when (propertyName) {
                        it::section27Part1A1.name -> it.section27Part1A1 = propertyValue
                        it::section27Part1A2.name -> it.section27Part1A2 = propertyValue
                    }
                }
            } else if (listName == state::employeesByRegionList2.name && changeIndex != null) {
                newState.employeesByRegionList2[changeIndex].let {
                    when (propertyName) {
                        it::section27Part2A1.name -> it.section27Part2A1 = propertyValue
                        it::section27Part2A2.name -> it.section27Part2A2 = propertyValue
                    }
                }
            }

            stateSetter(newState)

            props.setInputState(
                CurrentInputState(
                    parentPath = listName,
                    childPath = propertyName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue),
                    objectName = EsgSubSection.ACTIVITIES_AND_WORKERS.name,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurText: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            val propertyName = target.name
            val propertyValue = target.value
            props.onBlurApiCall(
                propertyName,
                JsonPrimitive(propertyValue),
                changeIndex,
                field,
                EsgSubSection.ACTIVITIES_AND_WORKERS.name
            )
        }

        val onChangeNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, listName ->
            val target = event.target as HTMLInputElement
            if (isValidLongNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toLongOrNull()

                val newState = state.copy()

                if (listName == state::employeesByRegionList1.name && changeIndex != null) {
                    newState.employeesByRegionList1[changeIndex].let {
                        when (propertyName) {
                            it::section27Part1C1.name -> it.section27Part1C1 = propertyValue
                        }
                    }
                } else if (listName == state::employeesByRegionList2.name && changeIndex != null) {
                    newState.employeesByRegionList2[changeIndex].let {
                        when (propertyName) {
                            it::section27Part2B1.name -> it.section27Part2B1 = propertyValue
                            it::section27Part2C1.name -> it.section27Part2C1 = propertyValue
                            it::section27Part2D1.name -> it.section27Part2D1 = propertyValue
                            it::section27Part2E1.name -> it.section27Part2E1 = propertyValue
                            it::section27Part2F1.name -> it.section27Part2F1 = propertyValue
                        }
                    }
                } else {
                    when (propertyName) {
                        state::section28B1.name -> newState.section28B1 = propertyValue
                    }
                }

                stateSetter(newState)

                props.setInputState(
                    CurrentInputState(
                        parentPath = listName,
                        childPath = propertyName,
                        index = changeIndex,
                        value = JsonPrimitive(propertyValue),
                        objectName = EsgSubSection.ACTIVITIES_AND_WORKERS.name,
                        reportName = reportState.reportFileName
                    )
                )
            }
        }

        val onBlurNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidLongNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toLongOrNull()
                props.onBlurApiCall(
                    propertyName,
                    JsonPrimitive(propertyValue),
                    changeIndex,
                    field,
                    EsgSubSection.ACTIVITIES_AND_WORKERS.name
                )
            }
        }

        Box {
            className = ESGCommonStyles.SECTION_MAIN_LAYOUT.cssClass

            GRIDisclosureGroupName {
                griDisclosureGroup = EsgSubSection.ACTIVITIES_AND_WORKERS
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_2_6)) {
                Disclosure_2_6 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                }
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_2_7)) {
                Disclosure_2_7 {
                    this.esgReportState = reportState
                    this.onChangeText = onChangeText
                    this.onBlurText = onBlurText
                    this.onChangeNumber = onChangeNumber
                    this.onBlurNumber = onBlurNumber
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.addRowOrSection = addRowOrSection
                    this.deleteRowOrSection = deleteRowOrSection
                }
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_2_8)) {
                Disclosure_2_8 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeNumber = onChangeNumber
                    this.onBlurNumber = onBlurNumber
                    this.addRowOrSection = addRowOrSection
                    this.deleteRowOrSection = deleteRowOrSection
                }
            }

        }
    }
}