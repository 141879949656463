package com.ecosave.watch.portal.components.esg.waterandeffluents

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.*
import com.ecosave.watch.portal.components.esg.waterandeffluents.WaterConstants.CATEGORY_HEADER
import com.ecosave.watch.portal.components.esg.waterandeffluents.WaterConstants.TOTAL_WATER
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.label
import react.dom.onChange


val GRI_303_5 = FC<EsgSubFormComponentProps> { props ->
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea
    val onChangeDecimalNumber = props.onChangeDecimalNumber
    val onBlurDecimalNumber = props.onBlurDecimalNumber

    props.esgReportState.report?.waterAndEffluents?.let { state ->
        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_303_5
        }

        Box {
            className = ESGCommonStyles.TWO_COLUMN_TABLE.cssClass

            TableHeader {
                header = ""
            }
            TableHeader {
                header = TOTAL_WATER
            }

            listOf(
                Triple(
                    "Total water consumption from all areas in megaliters.",
                    state::section3035Current1.name, state.section3035Current1
                ),
                Triple(
                    "Total water consumption from all areas with water stress in megaliters.",
                    state::section3035Current2.name,
                    state.section3035Current2
                ),
            ).forEach {
                label {
                    +it.first
                }
                ESGNumberFieldWrapper {
                    name = it.second
                    value = it.third
                    onChange = { event -> onChangeDecimalNumber(event, null, null) }
                    onBlur = { event -> onBlurDecimalNumber(event, null, null) }
                }
            }
        }

        Box {
            label {
                +("Change in water storage in megaliters, if water storage has been identified as having a " +
                    "significant water-related impact")
            }
            ESGTextAreaFieldWrapper {
                name = state::section3035Current3.name
                value = state.section3035Current3
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +("Any contextual information necessary to understand how the data have been compiled, " +
                    "such as any standards, methodologies, and assumptions used, including whether the " +
                    "information is calculated, estimated, modeled, or sourced from direct measurements, " +
                    "and the approach taken for this, such as the use of any sector-specific factors.")
            }
            ESGTextAreaFieldWrapper {
                name = state::section3035Current4.name
                value = state.section3035Current4
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}