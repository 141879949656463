package com.ecosave.watch.portal.components.userprofile

import mui.material.BaseTextFieldProps
import mui.material.FormControlVariant
import mui.material.Size
import mui.material.TextField
import react.FC

val UserProfileTextFieldWrapper = FC<BaseTextFieldProps> { props ->
    TextField {
        +props
        variant = FormControlVariant.outlined
        size = Size.medium
        fullWidth = true
    }
}