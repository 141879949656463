package com.ecosave.watch.portal.models.workorder

import com.ecosave.watch.portal.helpers.workorder.ActionSeverity
import com.ecosave.watch.portal.helpers.workorder.ActionStatus
import kotlinx.serialization.Serializable

@Serializable
data class ActionDataClass(
    val observationId: Int,
    val severity: ActionSeverity,
    val utility: String,
    val event: String,
    val facilityName: String,
    val accountNumber: String,
    val eventDescription: String,
    val observation: String,
    val actionId: Int,
    val action: String,
    var status: ActionStatus,
    var username: String?,
    var emailAddress: String?,
    var notes: String?,
    var generatedDate: String,
    var closedDate: String?,
    var closedByUsername: String?,
    var closedByEmailAddress: String?
)