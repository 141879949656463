package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class HumanRightsAssessmentState(
    var infrastureInvestmentList: MutableList<InfrastructureInvestments>? = mutableListOf(),
    var indirectEconomicImpactList: MutableList<IndirectEconomicImpactTable>? = mutableListOf(),

    var section3_3_203A1: String? = null,
    var section3_3_203A2: String? = null,
    var section3_3_203A3: String? = null,
    var section3_3_203A4: String? = null,
    var section3_3_203A5: String? = null,
    var section3_3_203A6: String? = null
)
