package com.ecosave.watch.portal.components.esg.supplierenvironmentalassessment

import com.ecosave.watch.portal.components.esg.EsgCoreComponentProps
import com.ecosave.watch.portal.components.esg.GenericSection_3_3
import com.ecosave.watch.portal.components.esg.formcontrols.GRIStandardName
import com.ecosave.watch.portal.helpers.common.isValidDecimalNumber
import com.ecosave.watch.portal.helpers.common.isValidLongNumber
import com.ecosave.watch.portal.helpers.esg.EsgSection
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.omitDisclosureFromUI
import com.ecosave.watch.portal.models.esg.CurrentInputState
import com.ecosave.watch.portal.models.esg.SupplierEnvironmentalAssessmentState
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import kotlinx.serialization.json.JsonPrimitive
import mui.material.Box
import react.FC
import react.dom.events.FocusEvent
import react.dom.events.FormEvent
import web.html.HTMLDivElement
import web.html.HTMLElement
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement

val SupplierEnvironmentalAssessmentComponent = FC<EsgCoreComponentProps> { props ->
    val reportState = props.reportState
    val setReportState = props.setReportState

    reportState.report?.supplierEnvironmentalAssessment?.let { state ->
        fun stateSetter(state: SupplierEnvironmentalAssessmentState) {
            setReportState(
                reportState.copy(
                    report = reportState.report.copy(
                        supplierEnvironmentalAssessment = state
                    )
                )
            )
        }

        val onChangeDecimalNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement

            if (isValidDecimalNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toDoubleOrNull()

                val newState = state.copy()

                newState.let {
                    when (propertyName) {
                        it::section3081.name -> it.section3081 = propertyValue
                        it::section3082A4.name -> it.section3082A4 = propertyValue
                        it::section3082A5.name -> it.section3082A5 = propertyValue
                    }
                }

                stateSetter(newState)

                props.setInputState(
                    CurrentInputState(
                        parentPath = field,
                        childPath = propertyName,
                        index = changeIndex,
                        value = JsonPrimitive(propertyValue),
                        objectName = EsgSection.SUPPLIER_ENVIRONMENTAL_ASSESSMENT.name,
                        reportName = reportState.reportFileName
                    )
                )
            }
        }

        val onBlurDecimalNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidDecimalNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toDoubleOrNull()
                props.onBlurApiCall(
                    propertyName,
                    JsonPrimitive(propertyValue),
                    changeIndex,
                    field,
                    EsgSection.SUPPLIER_ENVIRONMENTAL_ASSESSMENT.name
                )
            }
        }

        val onChangeNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidLongNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toLongOrNull()

                val newState = state.copy()

                newState.let {
                    when (propertyName) {
                        it::section3082A1.name -> it.section3082A1 = propertyValue
                        it::section3082A2.name -> it.section3082A2 = propertyValue
                    }
                }

                stateSetter(newState)

                props.setInputState(
                    CurrentInputState(
                        parentPath = field,
                        childPath = propertyName,
                        index = changeIndex,
                        value = JsonPrimitive(propertyValue),
                        objectName = EsgSection.SUPPLIER_ENVIRONMENTAL_ASSESSMENT.name,
                        reportName = reportState.reportFileName
                    )
                )
            }
        }

        val onBlurNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidLongNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toLongOrNull()
                props.onBlurApiCall(
                    propertyName,
                    JsonPrimitive(propertyValue),
                    changeIndex,
                    field,
                    EsgSection.SUPPLIER_ENVIRONMENTAL_ASSESSMENT.name
                )
            }
        }

        val onChangeTextArea: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value

            val newState = state.copy()

            newState.let {
                when (propertyName) {
                    it::section3082A3.name -> it.section3082A3 = propertyValue

                    it::section3_3_308A1.name -> it.section3_3_308A1 = propertyValue
                    it::section3_3_308A2.name -> it.section3_3_308A2 = propertyValue
                    it::section3_3_308A3.name -> it.section3_3_308A3 = propertyValue
                    it::section3_3_308A4.name -> it.section3_3_308A4 = propertyValue
                    it::section3_3_308A5.name -> it.section3_3_308A5 = propertyValue
                    it::section3_3_308A6.name -> it.section3_3_308A6 = propertyValue
                }
            }

            stateSetter(newState)

            props.setInputState(
                CurrentInputState(
                    parentPath = field,
                    childPath = propertyName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue),
                    objectName = EsgSection.SUPPLIER_ENVIRONMENTAL_ASSESSMENT.name,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurTextArea: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value

            props.onBlurApiCall(
                propertyName,
                JsonPrimitive(propertyValue),
                changeIndex,
                field,
                EsgSection.SUPPLIER_ENVIRONMENTAL_ASSESSMENT.name
            )
        }

        Box {
            className = ESGCommonStyles.SECTION_MAIN_LAYOUT.cssClass

            GRIStandardName {
                griStandard = EsgSection.SUPPLIER_ENVIRONMENTAL_ASSESSMENT
            }

            GenericSection_3_3 {
                this.onBlurTextArea = onBlurTextArea
                this.onChangeTextArea = onChangeTextArea
                this.materialTopicName = EsgSection.SUPPLIER_ENVIRONMENTAL_ASSESSMENT.description

                this.section3_3_A1_name = state::section3_3_308A1.name
                this.section3_3_A1_value = state.section3_3_308A1

                this.section3_3_A2_name = state::section3_3_308A2.name
                this.section3_3_A2_value = state.section3_3_308A2

                this.section3_3_A3_name = state::section3_3_308A3.name
                this.section3_3_A3_value = state.section3_3_308A3

                this.section3_3_A4_name = state::section3_3_308A4.name
                this.section3_3_A4_value = state.section3_3_308A4

                this.section3_3_A5_name = state::section3_3_308A5.name
                this.section3_3_A5_value = state.section3_3_308A5

                this.section3_3_A6_name = state::section3_3_308A6.name
                this.section3_3_A6_value = state.section3_3_308A6
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_308_1)) {
                GRI_308_1 {
                    this.esgReportState = reportState
                    this.onChangeDecimalNumber = onChangeDecimalNumber
                    this.onBlurDecimalNumber = onBlurDecimalNumber
                }
            }
            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_308_2)) {
                GRI_308_2 {
                    this.esgReportState = reportState
                    this.onChangeDecimalNumber = onChangeDecimalNumber
                    this.onBlurDecimalNumber = onBlurDecimalNumber
                    this.onChangeNumber = onChangeNumber
                    this.onBlurNumber = onBlurNumber
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                }
            }
        }
    }
}