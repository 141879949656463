package com.ecosave.watch.portal.services.billing

import com.ecosave.watch.portal.helpers.BackendServerURL
import com.ecosave.watch.portal.models.billing.FacilityAccountSummary
import com.ecosave.watch.portal.models.billing.UtilityAccountApiResponse
import com.ecosave.watch.portal.models.billing.UtilityAccountFiltersResponse
import com.ecosave.watch.portal.models.billing.UtilityAccountRegistration
import com.ecosave.watch.portal.models.billing.UtilityServiceProviderSummary
import com.ecosave.watch.portal.services.httpClient
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*

suspend fun getFacilitiesForAccountSummary(params: String = ""): List<FacilityAccountSummary>? {
    try {
        val response: HttpResponse = httpClient.get("$BackendServerURL/utility-account/get-by-company$params")
        if (response.status == HttpStatusCode.OK) {
            return response.body()
        }
    } catch (e: dynamic) {
        console.log("Error: ", e)
    }
    return null
}

suspend fun createUtilityAccount(
    utilityAccount: UtilityAccountRegistration
): UtilityAccountApiResponse {
    try {
        val response: HttpResponse = httpClient.post("$BackendServerURL/utility-account") {
            setBody(utilityAccount)
        }
        when (response.status) {
            HttpStatusCode.Created -> {
                return UtilityAccountApiResponse(
                    utilityAccountSummary = response.body(),
                    statusCode = HttpStatusCode.Created
                )
            }

            HttpStatusCode.NotFound -> {
                return UtilityAccountApiResponse(
                    utilityAccountSummary = null,
                    statusCode = HttpStatusCode.NotFound
                )
            }

            HttpStatusCode.Forbidden -> {
                return UtilityAccountApiResponse(
                    utilityAccountSummary = null,
                    statusCode = HttpStatusCode.Forbidden
                )
            }

            HttpStatusCode.ServiceUnavailable -> {
                return UtilityAccountApiResponse(
                    utilityAccountSummary = null,
                    statusCode = HttpStatusCode.ServiceUnavailable
                )
            }
        }
    } catch (e: dynamic) {
        console.log("Error: ", e)
    }
    return UtilityAccountApiResponse(
        utilityAccountSummary = null,
        statusCode = null
    )
}

suspend fun updateUtilityAccount(
    utilityAccount: UtilityAccountRegistration
): UtilityAccountApiResponse {
    try {
        val response: HttpResponse = httpClient.put("$BackendServerURL/utility-account") {
            setBody(utilityAccount)
        }
        when (response.status) {
            HttpStatusCode.OK -> {
                return UtilityAccountApiResponse(
                    utilityAccountSummary = response.body(),
                    statusCode = HttpStatusCode.OK
                )
            }

            HttpStatusCode.NotFound -> {
                return UtilityAccountApiResponse(
                    utilityAccountSummary = null,
                    statusCode = HttpStatusCode.NotFound
                )
            }

            HttpStatusCode.Forbidden -> {
                return UtilityAccountApiResponse(
                    utilityAccountSummary = null,
                    statusCode = HttpStatusCode.Forbidden
                )
            }

            HttpStatusCode.ServiceUnavailable -> {
                return UtilityAccountApiResponse(
                    utilityAccountSummary = null,
                    statusCode = HttpStatusCode.ServiceUnavailable
                )
            }
        }
    } catch (e: dynamic) {
        console.log("Error: ", e)
    }
    return UtilityAccountApiResponse(
        utilityAccountSummary = null,
        statusCode = null
    )
}

suspend fun getUtilityServiceProviders(): List<UtilityServiceProviderSummary> {
    try {
        val response: HttpResponse = httpClient.get("$BackendServerURL/utility-account/utility-providers")
        if (response.status == HttpStatusCode.OK) {
            return response.body()
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return emptyList()
}

suspend fun getFiltersValue(): UtilityAccountFiltersResponse {
    try {
        val response: HttpResponse = httpClient.get("$BackendServerURL/utility-account/filters")
        if (response.status == HttpStatusCode.OK) {
            return response.body()
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return UtilityAccountFiltersResponse()
}