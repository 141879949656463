package com.ecosave.watch.portal.components.esg.biodiversity

import com.ecosave.watch.portal.components.esg.EsgCoreComponentProps
import com.ecosave.watch.portal.components.esg.GenericSection_3_3
import com.ecosave.watch.portal.components.esg.formcontrols.GRIStandardName
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.isValidLongNumber
import com.ecosave.watch.portal.helpers.esg.*
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.esg.*
import com.ecosave.watch.portal.services.esg.addDynamicRowOrSection
import com.ecosave.watch.portal.services.esg.deleteDynamicRowOrSection
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import kotlinx.coroutines.launch
import kotlinx.serialization.json.JsonPrimitive
import mui.material.Box
import react.FC
import react.dom.events.FocusEvent
import react.dom.events.FormEvent
import web.html.HTMLDivElement
import web.html.HTMLElement
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement

val BiodiversityComponent = FC<EsgCoreComponentProps> { props ->
    val reportState = props.reportState
    val setReportState = props.setReportState
    val griStandardName = EsgSection.BIODIVERSITY

    reportState.report?.biodiversity?.let { state ->

        fun stateSetter(state: BiodiversityState) {
            setReportState(
                reportState.copy(
                    report = reportState.report.copy(
                        biodiversity = state
                    )
                )
            )
        }

        val addRowOrSection: (String) -> Unit = { fieldName ->
            resetPreviousPatchState(props.setInputState)
            mainScope.launch {
                props.setAddDeleteRowOrSection(DynamicTableAction.ADD)
                props.setAddDeleteRowOrSectionApiCallInProgress(true)
                val apiCallStatus = addDynamicRowOrSection(
                    EsgCollectionAddRow(
                        objectName = griStandardName.name,
                        reportFileName = reportState.reportFileName,
                        patchCollection = RowPatch(
                            pathFirst = fieldName
                        )
                    )
                )

                val newState = state.copy()

                if (apiCallStatus == ApiCallStatus.SUCCESS) {
                    when (fieldName) {
                        state::biodiversityGeographicalLocationList.name -> {
                            newState.biodiversityGeographicalLocationList.add(BiodiversityGeographicalLocation())
                            props.setNotificationMessage(ReportConstants.ADD_SECTION_NOTIFICATION_MESSAGE)
                        }

                        state::significantImpactsBiodiversityList.name -> {
                            newState.significantImpactsBiodiversityList.add(SignificantImpactsBiodiversity())
                            props.setNotificationMessage(ReportConstants.ADD_SECTION_NOTIFICATION_MESSAGE)
                        }

                        state::significantDirectAndIndirectList.name -> {
                            newState.significantDirectAndIndirectList.add(SignificantDirectAndIndirect())
                            props.setNotificationMessage(ReportConstants.ADD_SECTION_NOTIFICATION_MESSAGE)

                        }
                        state::habitatsProtectedOrRestoredList.name -> {
                            newState.habitatsProtectedOrRestoredList.add(HabitatsProtectedOrRestored())
                            props.setNotificationMessage(ReportConstants.ADD_SECTION_NOTIFICATION_MESSAGE)
                        }

                    }

                    stateSetter(newState)

                    props.setNotificationStatus(NotificationStatus.SUCCESS)
                    props.setOpenAlertNotifications(true)
                } else {
                    failureErrorMessage(
                        props.setNotificationMessage,
                        props.setNotificationStatus,
                        props.setOpenAlertNotifications
                    )
                }
                props.setAddDeleteRowOrSectionApiCallInProgress(false)
            }
        }

        val deleteRowOrSection: (String, Int) -> Unit = { fieldName, index ->
            resetPreviousPatchState(props.setInputState)
            mainScope.launch {
                props.setAddDeleteRowOrSection(DynamicTableAction.DELETE)
                props.setAddDeleteRowOrSectionApiCallInProgress(true)
                val apiCallStatus = deleteDynamicRowOrSection(
                    EsgCollectionDeleteRow(
                        objectPatchEnum = griStandardName,
                        reportFileName = reportState.reportFileName,
                        pathFirst = fieldName,
                        indexAt = index
                    )
                )

                val newState = state.copy()

                if (apiCallStatus == ApiCallStatus.SUCCESS) {
                    when (fieldName) {
                        state::biodiversityGeographicalLocationList.name -> {
                            newState.biodiversityGeographicalLocationList.removeAt(index)
                            props.setNotificationMessage(ReportConstants.DELETE_SECTION_NOTIFICATION_MESSAGE)
                        }

                        state::significantImpactsBiodiversityList.name -> {
                            newState.significantImpactsBiodiversityList.removeAt(index)
                            props.setNotificationMessage(ReportConstants.DELETE_SECTION_NOTIFICATION_MESSAGE)
                        }

                        state::significantDirectAndIndirectList.name -> {
                            newState.significantDirectAndIndirectList.removeAt(index)
                            props.setNotificationMessage(ReportConstants.DELETE_SECTION_NOTIFICATION_MESSAGE)

                        }

                        state::habitatsProtectedOrRestoredList.name -> {
                            newState.habitatsProtectedOrRestoredList.removeAt(index)
                            props.setNotificationMessage(ReportConstants.DELETE_SECTION_NOTIFICATION_MESSAGE)
                        }

                    }

                    stateSetter(newState)

                    props.setNotificationStatus(NotificationStatus.SUCCESS)
                    props.setOpenAlertNotifications(true)
                } else {
                    failureErrorMessage(
                        props.setNotificationMessage,
                        props.setNotificationStatus,
                        props.setOpenAlertNotifications
                    )
                }
                props.setAddDeleteRowOrSectionApiCallInProgress(false)
            }
        }


        val onChangeTextArea: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value

            val newState = state.copy()

            newState.let {
                when (propertyName) {
                    it::section3_3_304A1.name -> it.section3_3_304A1 = propertyValue
                    it::section3_3_304A2.name -> it.section3_3_304A2 = propertyValue
                    it::section3_3_304A3.name -> it.section3_3_304A3 = propertyValue
                    it::section3_3_304A4.name -> it.section3_3_304A4 = propertyValue
                    it::section3_3_304A5.name -> it.section3_3_304A5 = propertyValue
                    it::section3_3_304A6.name -> it.section3_3_304A6 = propertyValue
                }

                if (field == it::biodiversityGeographicalLocationList.name && changeIndex != null)
                {
                    newState.biodiversityGeographicalLocationList[changeIndex].let {
                        when (propertyName) {
                            it::section3041A1.name -> it.section3041A1 = propertyValue
                            it::section3041A2.name -> it.section3041A2 = propertyValue
                            it::section3041A3.name -> it.section3041A3 = propertyValue
                            it::section3041A4.name -> it.section3041A4 = propertyValue
                            it::section3041A6.name -> it.section3041A6 = propertyValue
                            it::section3041A7.name -> it.section3041A7 = propertyValue

                        }
                    }
                }
                if (field == it::significantImpactsBiodiversityList.name && changeIndex != null) {
                    newState.significantImpactsBiodiversityList[changeIndex].let {
                        when (propertyName) {
                            it::section3042A1.name -> it.section3042A1 = propertyValue
                            it::section3042A2.name -> it.section3042A2 = propertyValue
                            it::section3042A3.name -> it.section3042A3 = propertyValue
                            it::section3042A4.name -> it.section3042A4 = propertyValue
                            it::section3042A5.name -> it.section3042A5 = propertyValue
                            it::section3042A6.name -> it.section3042A6 = propertyValue
                            it::section3042A7.name -> it.section3042A7 = propertyValue
                        }
                    }
                }
                if (field == it::significantDirectAndIndirectList.name && changeIndex != null) {
                    newState.significantDirectAndIndirectList[changeIndex].let {
                        when (propertyName) {
                            it::section3042A8.name -> it.section3042A8 = propertyValue
                            it::section3042A9.name -> it.section3042A9 = propertyValue
                            it::section3042A10.name -> it.section3042A10 = propertyValue
                            it::section3042A11.name -> it.section3042A11 = propertyValue
                        }
                    }
                }
                if (field == it::habitatsProtectedOrRestoredList.name && changeIndex != null) {
                    newState.habitatsProtectedOrRestoredList[changeIndex].let {
                        when (propertyName) {
                            it::section3043A1.name -> it.section3043A1 = propertyValue
                            it::section3043A4.name -> it.section3043A4 = propertyValue
                            it::section3043A5.name -> it.section3043A5 = propertyValue
                            it::section3043A6.name -> it.section3043A6 = propertyValue
                        }
                    }
                }

            }

            stateSetter(newState)

            props.setInputState(
                CurrentInputState(
                    parentPath = field,
                    childPath = propertyName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue),
                    objectName = griStandardName.name,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurTextArea: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value

            props.onBlurApiCall(
                propertyName,
                JsonPrimitive(propertyValue),
                changeIndex,
                field,
                griStandardName.name
            )
        }

        val onChangeNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement

            if (isValidLongNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toLongOrNull()

                val newState = state.copy()

                if (field == newState::biodiversityGeographicalLocationList.name && changeIndex != null) {
                    newState.biodiversityGeographicalLocationList[changeIndex].let {
                        when (propertyName) {
                           it::section3041A5.name -> it.section3041A5 = propertyValue
                        }
                    }
                }



                stateSetter(newState)

                props.setInputState(
                    CurrentInputState(
                        parentPath = field,
                        childPath = propertyName,
                        index = changeIndex,
                        value = JsonPrimitive(propertyValue),
                        objectName = griStandardName.name,
                        reportName = reportState.reportFileName
                    )
                )
            }
        }


        val onBlurNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidLongNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toLongOrNull()
                props.onBlurApiCall(
                    propertyName,
                    JsonPrimitive(propertyValue),
                    changeIndex,
                    field,
                    griStandardName.name
                )
            }
        }


        Box {
            className = ESGCommonStyles.SECTION_MAIN_LAYOUT.cssClass
            GRIStandardName {
                griStandard = griStandardName
            }

            GenericSection_3_3 {
                this.onBlurTextArea = onBlurTextArea
                this.onChangeTextArea = onChangeTextArea
                this.materialTopicName = griStandardName.description

                this.section3_3_A1_name = state::section3_3_304A1.name
                this.section3_3_A1_value = state.section3_3_304A1
                this.section3_3_A2_name = state::section3_3_304A2.name
                this.section3_3_A2_value = state.section3_3_304A2
                this.section3_3_A3_name = state::section3_3_304A3.name
                this.section3_3_A3_value = state.section3_3_304A3
                this.section3_3_A4_name = state::section3_3_304A4.name
                this.section3_3_A4_value = state.section3_3_304A4
                this.section3_3_A5_name = state::section3_3_304A5.name
                this.section3_3_A5_value = state.section3_3_304A5
                this.section3_3_A6_name = state::section3_3_304A6.name
                this.section3_3_A6_value = state.section3_3_304A6
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_304_1)) {
                GRI_304_1 {
                    this.esgReportState = reportState
                    this.addRowOrSection = addRowOrSection
                    this.deleteRowOrSection = deleteRowOrSection
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeNumber = onChangeNumber
                    this.onBlurNumber = onBlurNumber

                }
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_304_2)) {
                GRI_304_2 {
                    this.esgReportState = reportState
                    this.addRowOrSection = addRowOrSection
                    this.deleteRowOrSection = deleteRowOrSection
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeNumber = onChangeNumber
                    this.onBlurNumber = onBlurNumber
                }
            }
            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_304_3)) {
                GRI_304_3 {
                    this.esgReportState = reportState
                    this.addRowOrSection = addRowOrSection
                    this.deleteRowOrSection = deleteRowOrSection
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeNumber = onChangeNumber
                    this.onBlurNumber = onBlurNumber
                }
            }
            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_304_4)) {
                GRI_304_4 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                }
            }
        }



    }
}