package com.ecosave.watch.portal.components.common

import com.ecosave.watch.portal.styles.CommonStyles
import web.cssom.px
import js.core.jso
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Dialog
import mui.material.DialogActions
import mui.material.DialogContent
import mui.material.DialogContentText
import mui.material.DialogTitle
import mui.system.sx
import react.FC
import react.Props
import web.cssom.ClassName
import web.cssom.FontWeight

external interface ConfirmationDialogProps : Props {
    var open: Boolean
    var title: String
    var body: String
    var actionResult: (Boolean) -> Unit
}

val ConfirmationDialog = FC<ConfirmationDialogProps> { props ->
    Dialog {
        Box {
            className = ClassName("dialog-gradient-strip")
        }
        PaperProps = jso {
            className = CommonStyles.DIALOG_BORDER_RADIUS.cssClass
            sx { width = 500.px }
        }
        open = props.open
        DialogTitle {
            sx {
                fontWeight = FontWeight.bold
            }
            +props.title
        }
        DialogContent {
            DialogContentText {
                +props.body
            }
            DialogActions {
                Button {
                    onClick = {
                        props.actionResult(false)
                    }
                    +"No"
                }
                Button {
                    variant = ButtonVariant.contained
                    onClick = {
                        props.actionResult(true)
                    }
                    +"Yes"
                }
            }
        }
    }
}

