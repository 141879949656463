package com.ecosave.watch.portal.components.utilityaccounts

import com.ecosave.watch.portal.components.common.ConfirmationDialog
import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.helpers.billing.BillingConstants
import com.ecosave.watch.portal.helpers.billing.BillingElectricAnnotations
import com.ecosave.watch.portal.helpers.billing.BillingHeaders
import com.ecosave.watch.portal.helpers.billing.DemandUnit
import com.ecosave.watch.portal.helpers.billing.UsageUnit
import com.ecosave.watch.portal.helpers.billing.UtilityType
import com.ecosave.watch.portal.helpers.billing.clearBillStartAndEndDate
import com.ecosave.watch.portal.helpers.billing.formatBillingLabels
import com.ecosave.watch.portal.helpers.billing.isBillingElectricFormValid
import com.ecosave.watch.portal.helpers.billing.mainScope
import com.ecosave.watch.portal.helpers.billing.validateOffPeakDemand
import com.ecosave.watch.portal.helpers.billing.validateOffPeakUsage
import com.ecosave.watch.portal.helpers.billing.validateOffPeakUsageCharges
import com.ecosave.watch.portal.helpers.billing.validateOnPeakDemand
import com.ecosave.watch.portal.helpers.billing.validateOnPeakUsage
import com.ecosave.watch.portal.helpers.billing.validateOnPeakUsageCharges
import com.ecosave.watch.portal.helpers.billing.validatePeakDemandCharges
import com.ecosave.watch.portal.helpers.billing.validateTotalDeliveryDistributionElectric
import com.ecosave.watch.portal.helpers.billing.validateTotalPeakBilledDemand
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.models.billing.ElectricBillValidationState
import com.ecosave.watch.portal.models.billing.NaturalGasBillValidationState
import com.ecosave.watch.portal.models.billing.SolarBillValidationState
import com.ecosave.watch.portal.models.billing.SteamBillValidationState
import com.ecosave.watch.portal.models.billing.UtilityBillValidationState
import com.ecosave.watch.portal.models.billing.WaterBillValidationState
import com.ecosave.watch.portal.models.billing.utilitybillstate.ElectricAdditional
import com.ecosave.watch.portal.models.billing.utilitybillstate.NaturalGasAdditional
import com.ecosave.watch.portal.models.billing.utilitybillstate.SolarBillAdditional
import com.ecosave.watch.portal.models.billing.utilitybillstate.SteamBillAdditional
import com.ecosave.watch.portal.models.billing.utilitybillstate.UtilityBillState
import com.ecosave.watch.portal.models.billing.utilitybillstate.WaterBillAdditional
import com.ecosave.watch.portal.services.billing.createBill
import com.ecosave.watch.portal.styles.BillingInputFormStyles
import com.ecosave.watch.portal.styles.CommonStyles
import com.ecosave.watch.portal.useGlobalState
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import react.FC
import react.Props
import react.ReactNode
import react.StateSetter
import react.dom.onChange
import react.useState
import web.html.HTMLInputElement
import web.html.InputType

external interface ElectricFormProps : Props {
    var setShowInputForm: StateSetter<Boolean>
    var showNotification: (NotificationStatus, String) -> Unit
}

val ElectricForm = FC<ElectricFormProps> { props ->
    val globalState = useGlobalState()
    val (formValidationState, setFormValidationState) = useState(
        UtilityBillValidationState(
            electricBillValidationState = ElectricBillValidationState(),
            naturalGasBillValidationState = NaturalGasBillValidationState(),
            waterBillValidationState = WaterBillValidationState(),
            steamBillValidationState = SteamBillValidationState(),
            solarBillValidationState = SolarBillValidationState()
        )
    )
    val (formState, setFormState) = useState(
        UtilityBillState(
            utilityAccountId = globalState.utilityAccountData.accountId,
            utilityType = UtilityType.ELECTRIC.name,
            usageUnit = UsageUnit.KWH.name,
            demandUnit = DemandUnit.KW.name,
            electricAdditional = ElectricAdditional(),
            naturalGasAdditional = NaturalGasAdditional(),
            waterBillAdditional = WaterBillAdditional(),
            steamBillAdditional = SteamBillAdditional(),
            solarBillAdditional = SolarBillAdditional()
        )
    )
    var isSubmitting by useState(false)
    var submitButtonText by useState("Submit")
    val (openDialog, setOpenDialog) = useState(false)

    Box {
        InputFormTitleSector {
            title = "Add Electric Bill"
            subtitle = "Account Number: ${globalState.utilityAccountData.accountNumber}"
        }
        Box {
            className = BillingInputFormStyles.MAIN_FORM.cssClass
            Box {
                className = BillingInputFormStyles.INPUT_WRAPPER.cssClass

                BillStartDate {
                    state = formState
                    setState = setFormState
                    validation = formValidationState
                    setValidation = setFormValidationState
                    isSolarStartDate = false
                    setDialogState = setOpenDialog
                }

                BillEndDate {
                    state = formState
                    setState = setFormState
                    validation = formValidationState
                    setValidation = setFormValidationState
                    isSolarEndDate = false
                    setDialogState = setOpenDialog
                }

                AddTotalUsage {
                    state = formState
                    setState = setFormState
                    validation = formValidationState
                    setValidation = setFormValidationState
                }

                AddUsageUnit {
                    stateUsageUnit = formState
                    setStateUsageUnit = setFormState
                    usageUnits = UtilityType.ELECTRIC.usageUnits
                }

                AddTotalBillCost {
                    state = formState
                    setState = setFormState
                    validation = formValidationState
                    setValidation = setFormValidationState
                }

                AddBillEstimated {
                    state = formState
                    setState = setFormState
                    validation = formValidationState
                    setValidation = setFormValidationState
                }

                AddBillAdjusted {
                    state = formState
                    setState = setFormState
                    validation = formValidationState
                    setValidation = setFormValidationState
                }

                TextFieldWrapper {
                    label = ReactNode(BillingHeaders.TOTAL_PEAK_BILLED_DEMAND.header)
                    type = InputType.number
                    annotation = BillingElectricAnnotations.TOTAL_PEAK_BILLED_DEMAND
                    value = formState.electricAdditional.totalPeakBilledDemand
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setFormState(
                            formState.copy(
                                electricAdditional = formState.electricAdditional.copy(
                                    totalPeakBilledDemand = target.value.toFloatOrNull()
                                )
                            )
                        )
                    }
                    error =
                        formValidationState.electricBillValidationState.totalPeakBilledDemandErrorState
                    helperText =
                        formValidationState.electricBillValidationState.totalPeakBilledDemandErrorMessage
                    onBlur = {
                        validateTotalPeakBilledDemand(
                            formState,
                            formValidationState,
                            setFormValidationState
                        )
                    }
                }

                AddDemandUnit {
                    stateDemandUnit = formState
                    setStateDemandUnit = setFormState
                    demandUnits = UtilityType.ELECTRIC.demandUnits ?: listOf()
                }

                TextFieldWrapper {
                    label = ReactNode(
                        "${BillingHeaders.PEAK_DEMAND_CHARGES.header} ($)"
                    )
                    type = InputType.number
                    annotation = BillingElectricAnnotations.PEAK_DEMAND_CHARGES
                    value = formState.electricAdditional.peakDemandCharges
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setFormState(
                            formState.copy(
                                electricAdditional = formState.electricAdditional.copy(
                                    peakDemandCharges = target.value.toFloatOrNull()
                                )
                            )
                        )
                    }
                    error =
                        formValidationState.electricBillValidationState.peakDemandChargesErrorState
                    helperText =
                        formValidationState.electricBillValidationState.peakDemandChargesErrorMessage
                    onBlur = {
                        validatePeakDemandCharges(
                            formState,
                            formValidationState,
                            setFormValidationState
                        )
                    }
                }
                TextFieldWrapper {
                    label = ReactNode(BillingHeaders.ON_PEAK_USAGE.header)
                    type = InputType.number
                    annotation = BillingElectricAnnotations.ON_PEAK_USAGE
                    value = formState.electricAdditional.onPeakUsage
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setFormState(
                            formState.copy(
                                electricAdditional = formState.electricAdditional.copy(
                                    onPeakUsage = target.value.toFloatOrNull()
                                )
                            )
                        )
                    }
                    error = formValidationState.electricBillValidationState.onPeakUsageErrorState
                    helperText =
                        formValidationState.electricBillValidationState.onPeakUsageErrorMessage
                    onBlur = {
                        validateOnPeakUsage(
                            formState,
                            formValidationState,
                            setFormValidationState
                        )
                    }
                }
                TextFieldWrapper {
                    label = ReactNode(
                        formatBillingLabels(BillingHeaders.ON_PEAK_USAGE_CHARGES, true)
                    )
                    type = InputType.number
                    annotation = BillingElectricAnnotations.ON_PEAK_USAGE_CHARGES
                    value = formState.electricAdditional.onPeakUsageCharges
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setFormState(
                            formState.copy(
                                electricAdditional = formState.electricAdditional.copy(
                                    onPeakUsageCharges = target.value.toFloatOrNull()
                                )
                            )
                        )
                    }
                    error =
                        formValidationState.electricBillValidationState.onPeakUsageChargesErrorState
                    helperText =
                        formValidationState.electricBillValidationState.onPeakUsageChargesErrorMessage
                    onBlur = {
                        validateOnPeakUsageCharges(
                            formState,
                            formValidationState,
                            setFormValidationState
                        )
                    }
                }
                TextFieldWrapper {
                    label = ReactNode(BillingHeaders.OFF_PEAK_USAGE.header)
                    type = InputType.number
                    annotation = BillingElectricAnnotations.OFF_PEAK_USAGE
                    value = formState.electricAdditional.offPeakUsage
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setFormState(
                            formState.copy(
                                electricAdditional = formState.electricAdditional.copy(
                                    offPeakUsage = target.value.toFloatOrNull()
                                )
                            )
                        )
                    }
                    error = formValidationState.electricBillValidationState.offPeakUsageErrorState
                    helperText =
                        formValidationState.electricBillValidationState.offPeakUsageErrorMessage
                    onBlur = {
                        validateOffPeakUsage(
                            formState,
                            formValidationState,
                            setFormValidationState
                        )
                    }
                }
                TextFieldWrapper {
                    label = ReactNode(
                        formatBillingLabels(BillingHeaders.OFF_PEAK_USAGE_CHARGES, true)
                    )
                    type = InputType.number
                    annotation = BillingElectricAnnotations.OFF_PEAK_USAGE_CHARGES
                    value = formState.electricAdditional.offPeakUsageCharges
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setFormState(
                            formState.copy(
                                electricAdditional = formState.electricAdditional.copy(
                                    offPeakUsageCharges = target.value.toFloatOrNull()
                                )
                            )
                        )
                    }
                    error =
                        formValidationState.electricBillValidationState.offPeakUsageChargesErrorState
                    helperText =
                        formValidationState.electricBillValidationState.offPeakUsageChargesErrorMessage
                    onBlur = {
                        validateOffPeakUsageCharges(
                            formState,
                            formValidationState,
                            setFormValidationState
                        )
                    }
                }
                TextFieldWrapper {
                    label = ReactNode(BillingHeaders.ON_PEAK_DEMAND.header)
                    type = InputType.number
                    annotation = BillingElectricAnnotations.ON_PEAK_DEMAND
                    value = formState.electricAdditional.onPeakDemand
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setFormState(
                            formState.copy(
                                electricAdditional = formState.electricAdditional.copy(
                                    onPeakDemand = target.value.toFloatOrNull()
                                )
                            )
                        )
                    }
                    error = formValidationState.electricBillValidationState.onPeakDemandErrorState
                    helperText =
                        formValidationState.electricBillValidationState.onPeakDemandErrorMessage
                    onBlur = {
                        validateOnPeakDemand(
                            formState,
                            formValidationState,
                            setFormValidationState
                        )
                    }
                }
                TextFieldWrapper {
                    label = ReactNode(BillingHeaders.OFF_PEAK_DEMAND.header)
                    type = InputType.number
                    annotation = BillingElectricAnnotations.OFF_PEAK_DEMAND
                    value = formState.electricAdditional.offPeakDemand
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setFormState(
                            formState.copy(
                                electricAdditional = formState.electricAdditional.copy(
                                    offPeakDemand = target.value.toFloatOrNull()
                                )
                            )
                        )
                    }
                    error = formValidationState.electricBillValidationState.offPeakDemandErrorState
                    helperText =
                        formValidationState.electricBillValidationState.offPeakDemandErrorMessage
                    onBlur = {
                        validateOffPeakDemand(
                            formState,
                            formValidationState,
                            setFormValidationState
                        )
                    }
                }
                TextFieldWrapper {
                    label = ReactNode(
                        formatBillingLabels(BillingHeaders.TOTAL_DELIVERY_DISTRIBUTION, true)
                    )
                    type = InputType.number
                    annotation = BillingElectricAnnotations.TOTAL_DELIVERY_DISTRIBUTION
                    value = formState.totalDeliveryDistribution
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setFormState(
                            formState.copy(
                                totalDeliveryDistribution = target.value.toFloatOrNull()
                            )
                        )
                    }
                    error =
                        formValidationState.electricBillValidationState.totalDeliveryDistributionErrorState
                    helperText =
                        formValidationState.electricBillValidationState.totalDeliveryDistributionErrorMessage
                    onBlur = {
                        validateTotalDeliveryDistributionElectric(
                            formState,
                            formValidationState,
                            setFormValidationState
                        )
                    }
                }
                Box {
                    className = BillingInputFormStyles.INPUT_ITEM.cssClass
                }
            }
        }
        Box {
            className = CommonStyles.CENTERED_BUTTON.cssClass
            Button {
                className = CommonStyles.MARGIN_RIGHT_BUTTON.cssClass
                +"Cancel"
                onClick = {
                    props.setShowInputForm(false)
                }
            }
            Button {
                +submitButtonText
                disabled = isSubmitting
                variant = ButtonVariant.contained
                onClick = {
                    if (isBillingElectricFormValid(
                            formState,
                            formValidationState,
                            setFormValidationState
                        )
                    ) {
                        mainScope.launch {
                            isSubmitting = true
                            submitButtonText = "Submitting..."
                            val apiCallStatus = createBill(formState)
                            if (apiCallStatus == ApiCallStatus.SUCCESS) {
                                props.showNotification(NotificationStatus.SUCCESS, BillingConstants.BILL_SUBMITTED_NOTIFICATION_MESSAGE)
                                props.setShowInputForm(false)
                            } else {
                                props.showNotification(NotificationStatus.ERROR, Constants.NOTIFICATION_ERROR_MESSAGE)
                            }
                            submitButtonText = "Submit"
                            isSubmitting = false
                        }
                    }
                }
            }
        }
    }
    ConfirmationDialog {
        open = openDialog
        title = "Confirmation"
        body = BillingConstants.BILL_START_END_DATE_DIFF_DIALOG_MESSAGE
        actionResult = { value ->
            setOpenDialog(false)
            if (!value) {
                clearBillStartAndEndDate(formState, setFormState)
            }
        }
    }
}