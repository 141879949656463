package com.ecosave.watch.portal.components.common

import web.cssom.AlignItems
import web.cssom.Display
import web.cssom.JustifyContent
import web.cssom.pct
import react.FC
import react.Props
import mui.material.Box
import mui.material.CircularProgress
import mui.material.CircularProgressColor
import mui.material.CircularProgressVariant
import mui.system.sx

val Loading = FC<Props> {
    Box {
        sx {
            width = 100.pct
            height = 100.pct
            display = Display.flex
            justifyContent = JustifyContent.center
            alignItems = AlignItems.center
        }
        CircularProgress {
            variant = CircularProgressVariant.indeterminate
            color = CircularProgressColor.primary
        }
    }
}