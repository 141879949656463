package com.ecosave.watch.portal.components.workorder

import com.ecosave.watch.portal.helpers.workorder.ActionSeverity
import mui.material.Chip
import mui.material.ChipColor
import mui.material.ChipVariant
import react.FC
import react.Props
import react.ReactNode

external interface ActionSeverityChipProps : Props {
    var label: String
    var severity: ActionSeverity
}

val ActionSeverityChip = FC<ActionSeverityChipProps> {
    Chip {
        label = ReactNode(it.label)
        variant = ChipVariant.filled
        color = when (it.severity) {
            ActionSeverity.MAJOR -> ChipColor.error
            ActionSeverity.MODERATE -> ChipColor.warning
            ActionSeverity.MINOR -> ChipColor.success
        }
    }
}