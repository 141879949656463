package com.ecosave.watch.portal.models.usermanagement

import com.ecosave.watch.portal.helpers.usermanagement.UserRole
import kotlinx.serialization.Serializable
import react.ReactNode

@Serializable
data class UserState(
    var email: String = "",
    var role: String = UserRole.USER.name,
    var emailErrorState: Boolean = false,
    var emailErrorMessage: ReactNode? = null
)