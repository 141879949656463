package com.ecosave.watch.portal.components.resourcecenter.energymanagement

import com.ecosave.watch.portal.components.resourcecenter.ResourcesSection
import com.ecosave.watch.portal.helpers.resourcecenter.EnergyManagementImages
import com.ecosave.watch.portal.styles.ResourceCenterStyles
import mui.material.Box
import mui.material.List
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML
import web.cssom.px

external interface EnergyManagementComponentProps : Props {
    var listPadding: Number
}

val EnergyManagementComponent = FC<EnergyManagementComponentProps> {
    List {
        sx {
            paddingLeft = it.listPadding.px
        }
        Box {
            id = "energy-management-default-view"
            className = ResourceCenterStyles.RESOURCES_SUBSECTION.cssClass
            Typography {
                className = ResourceCenterStyles.RESOURCES_MAIN_TITLE.cssClass
                variant = TypographyVariant.h4
                component = ReactHTML.h4
                +"Energy Management Overview - Default View"
            }
            for (defSent in defaultSentences) {
                ResourcesSection {
                    sentence = defSent
                }
            }
        }
        Box {
            className = ResourceCenterStyles.RESOURCES_SUBSECTION.cssClass

            ResourcesSection {
                title = "Main Areas"
                category = "Comparison Controls"
                subCategory = "Enables users to customize the displayed range and facilitate comparative analysis"
            }
            ResourcesSection {
                category = "Summary Table"
                subCategory = "Shows Energy, CO2, and Cost for the given date range"
            }
            ResourcesSection {
                category = "Utility Data Information"
                subCategory =
                    "This section displays utility data including type of service, sum of a given range, highest month of given range, and data for the previous month."
            }
            ResourcesSection {
                subCategory =
                    "Users can switch the metrics displayed to CO2 emissions and cost-related data by utilizing the toggle feature above. (Refer to Select View)"
            }
            ResourcesSection {
                title = "Action Buttons"
                category = "Set Savings Target"
                subCategory =
                    "The target(s) are set as a percentage reduction of your usage in comparison to how much you have used in the past 12 months. "

            }
            ResourcesSection {
                category = "Selection of Baseline"
                subCategory =
                    "An energy baseline is used to calculate your energy savings, a user selects a 12 month period and sets it as their baseline."

            }
            ReactHTML.img {
                src = EnergyManagementImages.EnergyManagementCustomComparisonImage
                className = ResourceCenterStyles.RESOURCES_DISPLAY_OVERVIEW.cssClass
            }
        }
        Box {
            id = "selection-of-baseline"
            className = ResourceCenterStyles.RESOURCES_SUBSECTION.cssClass
            ResourcesSection {
                title = "Selection of Baseline"
            }
            for (select in selectionOfBaselineSentences) {
                ResourcesSection {
                    subCategory = select
                }
            }
            ResourcesSection {
                note =
                    "Note, the baseline controls are only going to appear after more than 12 months of energy data has been added to the system"
            }
            ReactHTML.img {
                src = EnergyManagementImages.SelectBaselineImage
                className = ResourceCenterStyles.RESOURCES_DISPLAY_OVERVIEW.cssClass
            }
        }

        Box {
            id = "set-savings-target"
            className = ResourceCenterStyles.RESOURCES_SUBSECTION.cssClass

            ResourcesSection {
                title = "Set Savings Target"
                category = "To Set a Savings Target"
                subCategory = "On The Energy Management Page, click on Set Savings Target"
            }
            ResourcesSection {
                category = "Select a View (Energy, CO2 or $)"
                subCategory = "Input Values"
            }
            ResourcesSection {
                subCategory = "Click Submit"
            }
            ReactHTML.img {
                src = EnergyManagementImages.SetSavingsTargetImage
                className = ResourceCenterStyles.RESOURCES_DISPLAY_OVERVIEW.cssClass
            }
        }
        Box {
            id = "energy-management-overview-baseline-comparison"
            className = ResourceCenterStyles.RESOURCES_SUBSECTION.cssClass
            ResourcesSection {
                title = "Energy Management Overview - Baseline Comparison"
            }
            ReactHTML.img {
                src = EnergyManagementImages.EnergyManagementBaselineComparisonImage
                className = ResourceCenterStyles.RESOURCES_DISPLAY_OVERVIEW.cssClass
            }
        }
        Box {
            id = "energy-management-portfolio-overview"
            className = ResourceCenterStyles.RESOURCES_SUBSECTION.cssClass
            ResourcesSection {
                title = "Energy Management - Portfolio Overview"
            }
        }

        Box {
            id = "select-portfolio-overview"
            className = ResourceCenterStyles.RESOURCES_SUBSECTION.cssClass

            for (sentence in selectPortfolioSentences) {
                ResourcesSection {
                    subCategory = sentence
                }
            }

            ReactHTML.img {
                src = EnergyManagementImages.PortfolioOverviewImage
                className = ResourceCenterStyles.RESOURCES_DISPLAY_OVERVIEW.cssClass
            }
        }
        Box {
            id = "facility-group"
            className = ResourceCenterStyles.RESOURCES_SUBSECTION.cssClass
            Typography {
                className = ResourceCenterStyles.RESOURCES_TITLE_UNDERLINED.cssClass
                variant = TypographyVariant.h4
                component = ReactHTML.h4
                +"Facility Group"
            }
            for (sentence in facilityGroupSentences) {
                ResourcesSection {
                    subCategory = sentence
                }
            }

            ReactHTML.img {
                src = EnergyManagementImages.SelectFacilityGroupImage
                className = ResourceCenterStyles.RESOURCES_DISPLAY_OVERVIEW.cssClass
            }
        }
    }
}
