package com.ecosave.watch.portal.components.utilitybills

import mui.material.BaseTextFieldProps
import mui.material.FormControlVariant
import mui.material.Size
import mui.material.TextField
import react.FC

val EditableTextFieldWrapper = FC<BaseTextFieldProps> { props ->
    TextField {
        hiddenLabel = true
        variant = FormControlVariant.filled
        size = Size.small
        fullWidth = true
        +props
    }
}