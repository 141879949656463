package com.ecosave.watch.portal.components.controlcenter

import com.ecosave.watch.portal.styles.ControlCenterPageStyles
import com.ecosave.watch.portal.components.common.ApiFailedMessage
import com.ecosave.watch.portal.components.common.ApiLazyRender
import com.ecosave.watch.portal.helpers.Colors
import com.ecosave.watch.portal.helpers.workorder.ActionSeverity
import com.ecosave.watch.portal.models.controlcenter.ActionDataSet
import com.ecosave.watch.portal.models.controlcenter.ActionsData
import com.ecosave.watch.portal.useGlobalState
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.router.useNavigate
import web.cssom.Color
import web.cssom.FlexDirection
import web.cssom.JustifyContent
import web.cssom.pct

external interface GeneratedActionsComponentProps : Props {
    var actionDataFetched: Boolean
    var actionTileValue: ActionDataSet
    var facilityId: String
    var facilityName: String
    var isError: Boolean
}

val GeneratedActionsComponent = FC<GeneratedActionsComponentProps> { props ->

    val globalState = useGlobalState()
    val navigate = useNavigate()

    Typography {
        className = ControlCenterPageStyles.TEXT_CENTER.cssClass
        variant = TypographyVariant.h6
        +"Generated Actions"
    }
    ApiLazyRender {
        dataFetched = props.actionDataFetched
        Box {
            className = ControlCenterPageStyles.TILE_CONTENT.cssClass
            sx {
                flexDirection = FlexDirection.column
                justifyContent = JustifyContent.spaceAround
                width = 50.pct
            }
            if (props.isError) {
                ApiFailedMessage {

                }
            } else {
                Button {
                    className = ControlCenterPageStyles.BUTTON.cssClass
                    sx {
                        background = Color(Colors.CRIMSON_RED)
                    }
                    fullWidth = true
                    variant = ButtonVariant.contained
                    onClick = {
                        globalState.updateActionsData(
                                ActionsData(
                                        facilityId = props.facilityId,
                                        facilityName = props.facilityName,
                                        severity = ActionSeverity.MAJOR,
                                        navigatedFromControlCenter = true
                                )
                        )
                        navigate.invoke("/action-management")
                    }
                    +"Major - ${props.actionTileValue.major}"
                }
                Button {
                    className = ControlCenterPageStyles.BUTTON.cssClass
                    sx {
                        background = Color(Colors.TANGERINE_ORANGE)
                    }
                    fullWidth = true
                    variant = ButtonVariant.contained
                    onClick = {
                        globalState.updateActionsData(
                                ActionsData(
                                        facilityId = props.facilityId,
                                        facilityName = props.facilityName,
                                        severity = ActionSeverity.MODERATE,
                                        navigatedFromControlCenter = true
                                )
                        )
                        navigate.invoke("/action-management")
                    }
                    +"Moderate - ${props.actionTileValue.moderate}"
                }
                Button {
                    className = ControlCenterPageStyles.BUTTON.cssClass
                    sx {
                        background = Color(Colors.SUBTLE_LIME_GREEN)
                    }
                    fullWidth = true
                    variant = ButtonVariant.contained
                    onClick = {
                        globalState.updateActionsData(
                                ActionsData(
                                        facilityId = props.facilityId,
                                        facilityName = props.facilityName,
                                        severity = ActionSeverity.MINOR,
                                        navigatedFromControlCenter = true
                                )
                        )
                        navigate.invoke("/action-management")
                    }
                    +"Minor - ${props.actionTileValue.minor}"
                }
            }
        }
    }
}