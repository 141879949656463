package com.ecosave.watch.portal.models.facilitymanagement

import kotlinx.serialization.Serializable

@Serializable
data class EnergyStarCandidateFacility(
    val propertyId: Int,
    val facilityName: String,
    val address: String
)

@Serializable
data class EnergyStarSelectedCandidateFacility(
    val propertyId: Int,
    var energyStarSubscription: Boolean
)