package com.ecosave.watch.portal.components.facilitymanagement

import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.components.common.DialogWrapper
import com.ecosave.watch.portal.helpers.Colors
import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.helpers.billing.BillingConstants
import com.ecosave.watch.portal.helpers.common.ColorConstants
import com.ecosave.watch.portal.helpers.common.FormMode
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.ServicesCost
import com.ecosave.watch.portal.helpers.common.isValidInteger
import com.ecosave.watch.portal.helpers.common.md
import com.ecosave.watch.portal.helpers.common.sm
import com.ecosave.watch.portal.helpers.common.xs
import com.ecosave.watch.portal.helpers.facilitymanagement.BuildingCategory
import com.ecosave.watch.portal.helpers.facilitymanagement.BuildingType
import com.ecosave.watch.portal.helpers.facilitymanagement.FacilityConstants
import com.ecosave.watch.portal.helpers.facilitymanagement.FacilityFields
import com.ecosave.watch.portal.helpers.facilitymanagement.facilityOperatingScheduleRequiredFormatForEditForm
import com.ecosave.watch.portal.helpers.facilitymanagement.fetchZipCodeInfo
import com.ecosave.watch.portal.helpers.facilitymanagement.isBuildingTypeOther
import com.ecosave.watch.portal.helpers.facilitymanagement.isEnergyManagementPortalTrialActive
import com.ecosave.watch.portal.helpers.facilitymanagement.isEnergyStarSubscriptionCancelled
import com.ecosave.watch.portal.helpers.facilitymanagement.isFacilityFormValid
import com.ecosave.watch.portal.helpers.facilitymanagement.isValidZipCode
import com.ecosave.watch.portal.helpers.facilitymanagement.prepareFacilityRequestBody
import com.ecosave.watch.portal.helpers.facilitymanagement.validateBuildingCategory
import com.ecosave.watch.portal.helpers.facilitymanagement.validateBuildingTotalArea
import com.ecosave.watch.portal.helpers.facilitymanagement.validateBuildingType
import com.ecosave.watch.portal.helpers.facilitymanagement.validateBuildingYearBuilt
import com.ecosave.watch.portal.helpers.facilitymanagement.validateCity
import com.ecosave.watch.portal.helpers.facilitymanagement.validateFacilityName
import com.ecosave.watch.portal.helpers.facilitymanagement.validateOtherBuildingType
import com.ecosave.watch.portal.helpers.facilitymanagement.validatePropertyId
import com.ecosave.watch.portal.helpers.facilitymanagement.validateStreet
import com.ecosave.watch.portal.helpers.facilitymanagement.validateZipCode
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.models.facilitymanagement.Facility
import com.ecosave.watch.portal.models.facilitymanagement.FacilityOperatingScheduleState
import com.ecosave.watch.portal.models.facilitymanagement.FacilityState
import com.ecosave.watch.portal.models.facilitymanagement.FacilityValidationState
import com.ecosave.watch.portal.services.facilitymanagement.createFacility
import com.ecosave.watch.portal.services.facilitymanagement.getEnergyManagementPortalTrialEndDate
import com.ecosave.watch.portal.services.facilitymanagement.updateFacility
import emotion.react.css
import io.ktor.http.*
import kotlin.js.Date
import kotlinx.coroutines.launch
import kotlinx.datetime.LocalDateTime
import mui.icons.material.InfoOutlined
import mui.material.Alert
import mui.material.AlertColor
import mui.material.AlertTitle
import mui.material.AlertVariant
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Chip
import mui.material.ChipColor
import mui.material.ChipVariant
import mui.material.DialogActions
import mui.material.DialogContent
import mui.material.FormControl
import mui.material.FormControlLabel
import mui.material.FormHelperText
import mui.material.Grid
import mui.material.InputLabel
import mui.material.Link
import mui.material.MenuItem
import mui.material.Select
import mui.material.Slider
import mui.material.Switch
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.responsive
import mui.system.sx
import react.ChildrenBuilder
import react.FC
import react.Props
import react.ReactNode
import react.StateSetter
import react.create
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.label
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.ol
import react.dom.html.ReactHTML.span
import react.dom.onChange
import react.useEffect
import react.useEffectOnce
import react.useState
import web.cssom.AlignSelf
import web.cssom.Color
import web.cssom.Cursor
import web.cssom.Display
import web.cssom.FontWeight
import web.cssom.JustifyContent
import web.cssom.px
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement
import web.html.InputType

external interface AddOrEditFacilityProps : Props {
    var formMode: FormMode
    var openAddOrEditFacilityForm: Boolean
    var setOpenAddOrEditFacilityForm: StateSetter<Boolean>
    var facilities: MutableList<Facility>
    var setFacilities: StateSetter<MutableList<Facility>>
    var idOfFacilityToBeUpdated: Int?
    var setIdOfFacilityToBeUpdated: StateSetter<Int?>
}

val AddOrEditFacility = FC<AddOrEditFacilityProps> { props ->

    val (facilityState, setFacilityState) = useState(FacilityState())
    val (facilityOperatingScheduleState, setFacilityOperatingScheduleState) = useState(FacilityOperatingScheduleState())
    val (facilityValidationState, setFacilityValidationState) = useState(FacilityValidationState())
    var buildingTypeList by useState(mutableListOf<BuildingType>())
    val (notificationState, setNotificationState) = useState(NotificationState())
    var energyStarSubscriptionFromDB by useState<Boolean?>(null)
    var energyStarSubscriptionCancellationDate by useState<LocalDateTime?>(null)
    var isSaving by useState(false)
    var energyManagementPortalTrialEndDate by useState<LocalDateTime?>(null)
    val (openConnectEnergyStarAndImportFacilitiesForm, setOpenConnectEnergyStarAndImportFacilitiesForm) = useState(false)

    useEffectOnce {
        mainScope.launch {
            energyManagementPortalTrialEndDate = getEnergyManagementPortalTrialEndDate()
        }
    }

    // fetch building type list whenever building category changes
    useEffect(facilityState.buildingCategory) {
        if (facilityState.buildingCategory.isNotBlank()) {
            val buildingCategory = BuildingCategory.valueOf(facilityState.buildingCategory)
            buildingTypeList = buildingCategory.getAllBuildingTypes().toMutableList()
        }
    }

    // Populating facility state for updating the facility
    useEffect(props.idOfFacilityToBeUpdated) {
        props.idOfFacilityToBeUpdated?.let {

            if (props.formMode == FormMode.EDIT) {

                val facilityToBeUpdated = props.facilities.first { it.facilityId == props.idOfFacilityToBeUpdated }

                setFacilityState(
                    facilityState.copy(
                        facilityName = facilityToBeUpdated.facilityName,
                        buildingCategory = facilityToBeUpdated.buildingCategory.name,
                        buildingType = facilityToBeUpdated.buildingType.name,
                        otherBuildingType = facilityToBeUpdated.otherBuildingType ?: "",
                        buildingTotalArea = facilityToBeUpdated.buildingTotalArea,
                        street = facilityToBeUpdated.buildingAddress.street,
                        city = facilityToBeUpdated.buildingAddress.city,
                        state = facilityToBeUpdated.buildingAddress.state,
                        zipcode = facilityToBeUpdated.buildingAddress.zipcode,
                        buildingYearBuilt = facilityToBeUpdated.buildingYearBuilt,
                        facilityDescription = facilityToBeUpdated.facilityDescription ?: "",
                        propertyId = facilityToBeUpdated.propertyId,
                        energyStarSubscription = facilityToBeUpdated.energyStarSubscription,
                        buildingOccupancy = facilityToBeUpdated.buildingOccupancy
                    )
                )

                setFacilityOperatingScheduleState(
                    facilityOperatingScheduleState.copy(
                        isOperating24x7 = facilityToBeUpdated.is24x7,
                        facilityOperatingSchedule = facilityOperatingScheduleRequiredFormatForEditForm(facilityToBeUpdated.facilityDailyOperations)
                    )
                )

                energyStarSubscriptionFromDB = facilityToBeUpdated.energyStarSubscription
                energyStarSubscriptionCancellationDate = facilityToBeUpdated.cancellationEffectiveOn

            }
        }
    }

    // reset other building type if building type changes
    useEffect(facilityState.buildingType) {
        setFacilityState(
            facilityState.copy(
                otherBuildingType = if (!isBuildingTypeOther(facilityState.buildingType)) "" else facilityState.otherBuildingType
            )
        )
        setFacilityValidationState(
            facilityValidationState.copy(
                otherBuildingTypeErrorState = false,
                otherBuildingTypeErrorMessage = null
            )
        )
    }

    fun showNotification(message: String, status: NotificationStatus) {
        setNotificationState(
            notificationState.copy(
                status = status,
                message = message,
                visible = true
            )
        )
    }

    fun closeAddOrEditFacilityForm() {
        setFacilityState(FacilityState())
        setFacilityOperatingScheduleState(FacilityOperatingScheduleState())
        setFacilityValidationState(FacilityValidationState())
        props.setOpenAddOrEditFacilityForm(false)
        energyStarSubscriptionFromDB = null
        energyStarSubscriptionCancellationDate = null

        if (props.formMode == FormMode.EDIT) {
            props.setIdOfFacilityToBeUpdated(null)
        }
    }

    fun activeEnergyStarSubscription(): Boolean {
        return (energyStarSubscriptionFromDB == false || energyStarSubscriptionFromDB == null) && facilityState.energyStarSubscription
    }

    fun cancelEnergyStarSubscription(): Boolean {
        return !facilityState.energyStarSubscription && energyStarSubscriptionFromDB == true
    }

    DialogWrapper {
        dialogMaxWidth = 1400
        open = props.openAddOrEditFacilityForm
        dialogTitle = if (props.formMode == FormMode.ADD) "Add Facility" else "Edit Facility"
        DialogContent {
            Grid {
                sx {
                    padding = 20.px
                }
                container = true
                spacing = responsive(3)

                Grid {
                    item = true
                    xs = 12
                    sm = 6
                    md = 4
                    FacilityTextFieldWrapper {
                        label = ReactNode(FacilityFields.NAME.description)
                        value = facilityState.facilityName
                        type = InputType.text
                        error = facilityValidationState.facilityNameErrorState
                        helperText = facilityValidationState.facilityNameErrorMessage
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setFacilityState(
                                facilityState.copy(
                                    facilityName = target.value
                                )
                            )
                        }
                        onBlur = {
                            validateFacilityName(facilityState, facilityValidationState, setFacilityValidationState)
                        }
                    }
                }

                Grid {
                    item = true
                    xs = 12
                    sm = 6
                    md = 4
                    FormControl {
                        fullWidth = true
                        error = facilityValidationState.buildingCategoryErrorState
                        InputLabel {
                            +FacilityFields.BUILDING_CATEGORY.description
                        }
                        Select {
                            label = ReactNode(FacilityFields.BUILDING_CATEGORY.description)
                            value = facilityState.buildingCategory
                            onChange = { event, _ ->
                                val value = event.target.value

                                setFacilityState(
                                    facilityState.copy(
                                        buildingCategory = value,
                                        // reset building type if building category changes
                                        buildingType = if (value == facilityState.buildingCategory) facilityState.buildingType else ""
                                    )
                                )
                            }
                            onBlur = {
                                validateBuildingCategory(facilityState, facilityValidationState, setFacilityValidationState)
                            }
                            for (category in BuildingCategory.entries) {
                                MenuItem {
                                    value = category.name
                                    +category.description
                                }
                            }
                        }
                        FormHelperText {
                            +facilityValidationState.buildingCategoryErrorMessage
                        }
                    }
                }

                // show building type dropdown only when building category is selected
                if (facilityState.buildingCategory.isNotBlank()) {
                    Grid {
                        item = true
                        xs = 12
                        sm = 6
                        md = 4
                        FormControl {
                            fullWidth = true
                            error = facilityValidationState.buildingTypeErrorState
                            InputLabel {
                                +FacilityFields.BUILDING_TYPE.description
                            }
                            Select {
                                label = ReactNode(FacilityFields.BUILDING_TYPE.description)
                                value = facilityState.buildingType
                                onChange = { event, _ ->
                                    setFacilityState(
                                        facilityState.copy(
                                            buildingType = event.target.value
                                        )
                                    )
                                }
                                onBlur = {
                                    validateBuildingType(facilityState, facilityValidationState, setFacilityValidationState)
                                }
                                for (buildingType in buildingTypeList) {
                                    MenuItem {
                                        value = buildingType.name
                                        +buildingType.description
                                    }
                                }
                            }
                            FormHelperText {
                                +facilityValidationState.buildingTypeErrorMessage
                            }
                        }
                    }
                }

                if (isBuildingTypeOther(facilityState.buildingType)) {
                    Grid {
                        item = true
                        xs = 12
                        sm = 6
                        md = 4
                        FacilityTextFieldWrapper {
                            label = ReactNode(FacilityFields.OTHER_BUILDING_TYPE.description)
                            value = facilityState.otherBuildingType
                            type = InputType.text
                            error = facilityValidationState.otherBuildingTypeErrorState
                            helperText = facilityValidationState.otherBuildingTypeErrorMessage
                            onChange = {
                                val target = it.target as HTMLInputElement
                                setFacilityState(
                                    facilityState.copy(
                                        otherBuildingType = target.value
                                    )
                                )
                            }
                            onBlur = {
                                validateOtherBuildingType(facilityState, facilityValidationState, setFacilityValidationState)
                            }
                        }
                    }
                }

                Grid {
                    item = true
                    xs = 12
                    sm = 6
                    md = 4
                    FacilityTextFieldWrapper {
                        label = ReactNode(FacilityFields.GROSS_FLOOR_AREA.description)
                        value = facilityState.buildingTotalArea
                        type = InputType.number
                        error = facilityValidationState.buildingTotalAreaErrorState
                        helperText = facilityValidationState.buildingTotalAreaErrorMessage
                        onChange = {
                            val target = it.target as HTMLInputElement
                            if (isValidInteger(target)) {
                                setFacilityState(
                                    facilityState.copy(
                                        buildingTotalArea = target.value.toIntOrNull()
                                    )
                                )
                            }
                        }
                        onBlur = {
                            validateBuildingTotalArea(facilityState, facilityValidationState, setFacilityValidationState)
                        }
                    }
                }

                Grid {
                    item = true
                    xs = 12
                    sm = 6
                    md = 4
                    FacilityTextFieldWrapper {
                        label = ReactNode(FacilityFields.STREET.description)
                        value = facilityState.street
                        type = InputType.text
                        error = facilityValidationState.streetErrorState
                        helperText = facilityValidationState.streetErrorMessage
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setFacilityState(
                                facilityState.copy(
                                    street = target.value
                                )
                            )
                        }
                        onBlur = {
                            validateStreet(facilityState, facilityValidationState, setFacilityValidationState)
                        }
                    }
                }

                Grid {
                    item = true
                    xs = 12
                    sm = 6
                    md = 4
                    FacilityTextFieldWrapper {
                        label = ReactNode(FacilityFields.ZIPCODE.description)
                        value = facilityState.zipcode
                        type = InputType.number
                        error = facilityValidationState.zipcodeErrorState
                        helperText = facilityValidationState.zipcodeErrorMessage
                        onChange = {
                            val target = it.target as HTMLInputElement
                            if (isValidInteger(target, maximumDigitsAllowed = 5)) {
                                if (isValidZipCode(target.value)) {
                                    val zipCodeInfo = fetchZipCodeInfo(target.value)
                                    setFacilityState(
                                        facilityState.copy(
                                            zipcode = target.value,
                                            state = zipCodeInfo.state ?: facilityState.state,
                                            city = zipCodeInfo.city ?: facilityState.city
                                        )
                                    )
                                } else {
                                    setFacilityState(
                                        facilityState.copy(
                                            zipcode = target.value
                                        )
                                    )
                                }
                            }
                        }
                        onBlur = {
                            validateZipCode(facilityState, facilityValidationState, setFacilityValidationState)
                        }
                    }
                }

                Grid {
                    item = true
                    xs = 12
                    sm = 6
                    md = 4
                    FacilityTextFieldWrapper {
                        label = ReactNode(FacilityFields.CITY.description)
                        value = facilityState.city
                        type = InputType.text
                        error = facilityValidationState.cityErrorState
                        helperText = facilityValidationState.cityErrorMessage
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setFacilityState(
                                facilityState.copy(
                                    city = target.value
                                )
                            )
                        }
                        onBlur = {
                            validateCity(facilityState, facilityValidationState, setFacilityValidationState)
                        }
                    }
                }

                Grid {
                    item = true
                    xs = 12
                    sm = 6
                    md = 4
                    FacilityState {
                        state = facilityState
                        setState = setFacilityState
                        validationState = facilityValidationState
                        setValidationState = setFacilityValidationState
                    }
                }

                Grid {
                    item = true
                    xs = 12
                    sm = 6
                    md = 4
                    FormControl {
                        fullWidth = true
                        InputLabel {
                            +FacilityFields.YEAR_BUILT.description
                        }
                        error = facilityValidationState.buildingYearBuiltErrorState
                        Select {
                            label = ReactNode(FacilityFields.YEAR_BUILT.description)
                            value = facilityState.buildingYearBuilt
                            onChange = { event, _ ->
                                setFacilityState(
                                    facilityState.copy(
                                        buildingYearBuilt = event.target.value.toInt()
                                    )
                                )
                            }
                            onBlur = {
                                validateBuildingYearBuilt(facilityState, facilityValidationState, setFacilityValidationState)
                            }
                            for (date in Date().getFullYear() downTo 1600) {
                                MenuItem {
                                    value = date.toString()
                                    +date.toString()
                                }
                            }
                        }
                        FormHelperText {
                            +facilityValidationState.buildingYearBuiltErrorMessage
                        }
                    }
                }

                Grid {
                    item = true
                    xs = 12
                    FacilityTextFieldWrapper {
                        label = ReactNode(FacilityFields.DESCRIPTION.description)
                        value = facilityState.facilityDescription
                        type = InputType.text
                        multiline = true
                        minRows = Constants.TEXT_AREA_MIN_ROWS
                        maxRows = Constants.TEXT_AREA_MAX_ROWS
                        onChange = {
                            val target = it.target as HTMLTextAreaElement
                            setFacilityState(
                                facilityState.copy(
                                    facilityDescription = target.value
                                )
                            )
                        }
                    }
                }

                Grid {
                    item = true
                    xs = 12
                    FormControl {
                        error = facilityValidationState.buildingOccupancyErrorState
                        fullWidth = true
                        Box {
                            label {
                                +FacilityFields.OCCUPANCY.description
                            }
                            Box {
                                sx {
                                    display = Display.inlineFlex
                                    marginLeft = 20.px
                                }
                                ReactHTML.label {
                                    facilityState.buildingOccupancy?.let {
                                        +"$it %"
                                    }
                                }
                            }
                            Slider {
                                marks = true
                                value = facilityState.buildingOccupancy
                                onChange = { _, value, _ ->
                                    val sliderValue = value as Int
                                    setFacilityState(
                                        facilityState.copy(
                                            buildingOccupancy = sliderValue
                                        )
                                    )
                                }
                            }
                        }
                        FormHelperText {
                            +facilityValidationState.buildingOccupancyErrorMessage
                        }
                    }
                }

                Grid {
                    item = true
                    xs = 12
                    FormControlLabel {
                        label = Box.create {
                            sx {
                                display = Display.flex
                                gap = 10.px
                            }
                            Typography {
                                sx {
                                    fontWeight = FontWeight.bold
                                }
                                variant = TypographyVariant.h6
                                +FacilityConstants.ENERGY_STAR_PORTFOLIO_MANAGER
                            }
                            showEnergyStarSubscriptionStatus(energyStarSubscriptionFromDB, energyStarSubscriptionCancellationDate)
                        }
                        control = Switch.create {
                            checked = facilityState.energyStarSubscription
                            onChange = { _, value ->
                                setFacilityState(
                                    facilityState.copy(
                                        energyStarSubscription = value
                                    )
                                )
                            }
                        }
                    }
                    if (isEnergyManagementPortalTrialActive(energyManagementPortalTrialEndDate)) {
                        Box {
                            sx {
                                marginLeft = 45.px
                                display = Display.flex
                                gap = 5.px
                            }
                            Typography {
                                variant = TypographyVariant.body1
                                sx {
                                    color = Color(Colors.GREEN)
                                }
                                +"Included in your 365 days trial "
                            }
                            Typography {
                                variant = TypographyVariant.body2
                                sx {
                                    alignSelf = AlignSelf.center
                                }
                                +"(${ServicesCost.ENERGY_STAR}/month for this facility afterwards)"
                            }
                        }
                    } else {
                        Typography {
                            variant = TypographyVariant.body2
                            sx {
                                marginLeft = 45.px
                                color = Color(Colors.GREEN)
                            }
                            +"${ServicesCost.ENERGY_STAR}/month for this facility"
                        }
                    }
                    Typography {
                        variant = TypographyVariant.body2
                        sx {
                            marginTop = 20.px
                        }
                        +"Energy Star® and Portfolio Manager® are registered trademarks of the United States Environmental Protection Agency."
                    }
                }

                if (cancelEnergyStarSubscription()) {
                    Grid {
                        item = true
                        xs = 12
                        Alert {
                            color = AlertColor.warning
                            severity = AlertColor.warning
                            variant = AlertVariant.outlined
                            +"Subscription will be cancelled at the end of billing period, once you save."
                        }
                    }
                }

                if (facilityState.energyStarSubscription) {
                    if (activeEnergyStarSubscription()) {
                        Grid {
                            item = true
                            xs = 12
                            energyStarPortfolioManagerServiceUserGuide(setOpenConnectEnergyStarAndImportFacilitiesForm)
                        }
                    }

                    Grid {
                        item = true
                        xs = 4
                        FacilityTextFieldWrapper {
                            label = ReactNode(FacilityFields.PROPERTY_ID.description)
                            value = facilityState.propertyId
                            type = InputType.number
                            error = facilityValidationState.propertyIdErrorState
                            helperText = facilityValidationState.propertyIdErrorMessage
                            onChange = {
                                val target = it.target as HTMLInputElement
                                if (isValidInteger(target)) {
                                    setFacilityState(
                                        facilityState.copy(
                                            propertyId = target.value.toIntOrNull()
                                        )
                                    )
                                }
                            }
                            onBlur = {
                                validatePropertyId(facilityState, facilityValidationState, setFacilityValidationState)
                            }
                        }
                    }
                }

                Grid {
                    item = true
                    xs = 12
                    FacilityOperatingSchedule {
                        state = facilityOperatingScheduleState
                        setState = setFacilityOperatingScheduleState
                    }
                }
            }
        }
        DialogActions {
            sx {
                justifyContent = JustifyContent.center
            }
            Button {
                +"Cancel"
                onClick = {
                    closeAddOrEditFacilityForm()
                }
            }
            Button {
                if (isSaving) {
                    if (activeEnergyStarSubscription()) {
                        +"Saving and activating subscription..."
                    } else if (cancelEnergyStarSubscription()) {
                        +"Saving and cancelling subscription..."
                    } else {
                        +"Saving..."
                    }
                } else {
                    if (activeEnergyStarSubscription()) {
                        +"Save and activate subscription"
                    } else if (cancelEnergyStarSubscription()) {
                        +"Save and cancel subscription"
                    } else {
                        +"Save"
                    }
                }
                disabled = isSaving
                variant = ButtonVariant.contained
                onClick = {

                    if (isFacilityFormValid(facilityState, facilityValidationState, setFacilityValidationState)) {

                        if (props.formMode == FormMode.ADD) {
                            // register facility
                            val requestBody = prepareFacilityRequestBody(facilityState, facilityOperatingScheduleState)

                            mainScope.launch {
                                isSaving = true
                                val response = createFacility(requestBody)

                                when (response.statusCode) {

                                    null -> {
                                        showNotification("There has been an issue adding facility, please retry.", NotificationStatus.ERROR)
                                    }

                                    HttpStatusCode.NotFound -> {
                                        showNotification(FacilityConstants.INVALID_PROPERTY_ID_ERROR_MESSAGE, NotificationStatus.ERROR)
                                    }

                                    HttpStatusCode.Forbidden -> {
                                        showNotification(
                                            FacilityConstants.PROPERTY_ID_ACCESS_ISSUE_ERROR_MESSAGE,
                                            NotificationStatus.ERROR
                                        )
                                    }

                                    HttpStatusCode.ServiceUnavailable -> {
                                        showNotification(
                                            FacilityConstants.ENERGY_STAR_API_ERROR_MESSAGE,
                                            NotificationStatus.ERROR
                                        )
                                    }

                                    HttpStatusCode.Created -> {
                                        val facilities = props.facilities
                                        facilities.add(0, response.facility!!)
                                        props.setFacilities(facilities)
                                        showNotification("Facility has been added successfully.", NotificationStatus.SUCCESS)
                                        closeAddOrEditFacilityForm()
                                    }
                                }
                                isSaving = false
                            }
                        } else {
                            // update facility
                            val requestBody = prepareFacilityRequestBody(facilityState, facilityOperatingScheduleState, props.idOfFacilityToBeUpdated)

                            mainScope.launch {
                                isSaving = true
                                val response = updateFacility(requestBody)

                                when (response.statusCode) {

                                    null -> {
                                        showNotification("There has been an issue updating facility, please retry.", NotificationStatus.ERROR)
                                    }

                                    HttpStatusCode.Forbidden -> {
                                        showNotification(
                                            FacilityConstants.PROPERTY_ID_ACCESS_ISSUE_ERROR_MESSAGE,
                                            NotificationStatus.ERROR
                                        )
                                    }

                                    HttpStatusCode.NotFound -> {
                                        showNotification(FacilityConstants.INVALID_PROPERTY_ID_ERROR_MESSAGE, NotificationStatus.ERROR)
                                    }

                                    HttpStatusCode.ServiceUnavailable -> {
                                        showNotification(
                                            FacilityConstants.ENERGY_STAR_API_ERROR_MESSAGE,
                                            NotificationStatus.ERROR
                                        )
                                    }

                                    HttpStatusCode.OK -> {
                                        val facilities = props.facilities
                                        val index = facilities.indexOf(
                                            facilities.first { it.facilityId == response.facility!!.facilityId }
                                        )
                                        facilities[index] = response.facility!!
                                        props.setFacilities(facilities)

                                        showNotification("Facility has been updated successfully.", NotificationStatus.SUCCESS)
                                        closeAddOrEditFacilityForm()
                                    }
                                }
                                isSaving = false
                            }

                        }

                    } else {
                        console.error("form not valid.")
                    }

                }
            }
        }
    }
    ConnectEnergyStarAndImportFacilities {
        this.openConnectEnergyStarAndImportFacilitiesForm = openConnectEnergyStarAndImportFacilitiesForm
        this.setOpenConnectEnergyStarAndImportFacilitiesForm = setOpenConnectEnergyStarAndImportFacilitiesForm
        showConnectEnergyStarFormOnly = true
    }
    AlertNotifications {
        open = notificationState.visible
        status = notificationState.status
        message = notificationState.message
        closeNotification = {
            setNotificationState(
                notificationState.copy(
                    visible = false
                )
            )
        }
    }
}

fun ChildrenBuilder.energyStarPortfolioManagerServiceUserGuide(setOpenConnectEnergyStarAndImportFacilitiesForm: StateSetter<Boolean>) = Alert {
    sx {
        color = Color(Colors.WHITE)
        borderColor = Color(Colors.WHITE)
    }
    icon = InfoOutlined.create {
        sx {
            color = Color(Colors.WHITE)
        }
    }
    severity = AlertColor.info
    variant = AlertVariant.outlined
    AlertTitle {
        +"Subscription will be activated once you save."
    }
    +"Before we begin, please ensure that your ${FacilityConstants.ENERGY_STAR_PORTFOLIO_MANAGER} account is connected with us "
    Link {
        sx {
            cursor = Cursor.pointer
        }
        onClick = {
            setOpenConnectEnergyStarAndImportFacilitiesForm(true)
        }
        +"here."
    }
    ol {
        li {
            +"Log in to your ${FacilityConstants.ENERGY_STAR_PORTFOLIO_MANAGER} account."
        }
        li {
            +"Go to "
            span {
                css {
                    color = Color(ColorConstants.HIGHLIGHTED_TEXT)
                }
                +"Sharing "
            }
            +"and select "
            span {
                css {
                    color = Color(ColorConstants.HIGHLIGHTED_TEXT)
                }
                +"Share with your Utility or Service Provider for exchanging data."
            }
        }
        li {
            +"From the drop-down menu "
            span {
                css {
                    color = Color(ColorConstants.HIGHLIGHTED_TEXT)
                }
                +"Select Web Services Provider (Account), "
            }
            +"choose EcosaveWatch."
        }
        li {
            +"Click "
            span {
                css {
                    color = Color(ColorConstants.HIGHLIGHTED_TEXT)
                }
                +"Select Properties "
            }
            +"and choose all the properties you want to share with us."
        }
        li {
            +"Under "
            span {
                css {
                    color = Color(ColorConstants.HIGHLIGHTED_TEXT)
                }
                +"Choose Permissions, "
            }
            +"select "
            span {
                css {
                    color = Color(ColorConstants.HIGHLIGHTED_TEXT)
                }
                +"Bulk Sharing "
            }
            +"and then "
            span {
                css {
                    color = Color(ColorConstants.HIGHLIGHTED_TEXT)
                }
                +"Exchange Data Full Access."
            }
        }
        li {
            +"Click "
            span {
                css {
                    color = Color(ColorConstants.HIGHLIGHTED_TEXT)
                }
                +"Authorize Exchange "
            }
            +"to send requests for each of the properties and meters you selected to EcosaveWatch."
        }
        li {
            +"Finally, enter the ${BillingConstants.ENERGY_STAR} property ID representing this facility below."
        }
    }
}

fun ChildrenBuilder.showEnergyStarSubscriptionStatus(energyStarSubscriptionFromDB: Boolean?, energyStarSubscriptionCancellationDate: LocalDateTime?) {
    if (energyStarSubscriptionFromDB == true) {
        Chip {
            label = ReactNode("Active")
            variant = ChipVariant.filled
            color = ChipColor.success
        }
    }
    energyStarSubscriptionCancellationDate?.let {
        if (!isEnergyStarSubscriptionCancelled(it, energyStarSubscriptionFromDB)) {
            Chip {
                label = ReactNode("Cancelling on ${it.date}")
                variant = ChipVariant.filled
                color = ChipColor.warning
            }
        }
    }
}

