package com.ecosave.watch.portal.components.utilityaccounts

import com.ecosave.watch.portal.helpers.billing.BillingCommonAnnotations
import com.ecosave.watch.portal.helpers.billing.BillingConstants
import com.ecosave.watch.portal.helpers.billing.BillingHeaders
import com.ecosave.watch.portal.helpers.billing.BillingSolarHeaders
import com.ecosave.watch.portal.models.billing.utilitybillstate.UtilityBillState
import com.ecosave.watch.portal.models.billing.UtilityBillValidationState
import com.ecosave.watch.portal.helpers.billing.differenceBetweenBillStartAndEndDate
import com.ecosave.watch.portal.helpers.billing.validateBillEndDate
import react.FC
import react.Props
import react.ReactNode
import react.StateSetter
import react.dom.onChange
import kotlin.math.abs
import web.html.HTMLInputElement
import web.html.InputType

external interface BillEndDateProps : Props {
    var state: UtilityBillState
    var setState: StateSetter<UtilityBillState>
    var validation: UtilityBillValidationState
    var setValidation: StateSetter<UtilityBillValidationState>
    var isSolarEndDate: Boolean
    var setDialogState: StateSetter<Boolean>
}

val BillEndDate = FC<BillEndDateProps> { props ->

    TextFieldWrapper {
        label =
            if (props.isSolarEndDate) ReactNode(BillingSolarHeaders.END_DATE.header) else
                ReactNode("${BillingHeaders.BILL_END_DATE.header} *")
        type = InputType.date
        if (!props.isSolarEndDate) {
            annotation = BillingCommonAnnotations.BILL_END_DATE
        }
        InputLabelProps = js("({shrink: true})")
        value = props.state.billEndDate
        onChange = {
            val target = it.target as HTMLInputElement
            val value = target.value
            props.setState(
                props.state.copy(
                    billEndDate = value
                )
            )
        }
        error = props.validation.billEndDateErrorState
        helperText = props.validation.billEndDateErrorMessage
        onBlur = {
            val days = differenceBetweenBillStartAndEndDate(props.state.billStartDate, props.state.billEndDate)
            props.setDialogState(abs(days) > BillingConstants.BILL_START_AND_END_DATE_DAYS_DIFF)
            validateBillEndDate(props.state, props.validation, props.setValidation)
        }
    }

}