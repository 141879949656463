package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class WaterAndEffluentsState(
    var section3031A1: String? = null,
    var section3031A2: String? = null,
    var section3031A3: String? = null,
    var section3031A4: String? = null,
    var section3031A5: String? = null,

    var section3032B1: String? = null,

    var section3033Current1: Double? = null,
    var section3033Current2: Double? = null,
    var section3033Current3: Double? = null,
    var section3033Current4: Double? = null,
    var section3033Current5: Double? = null,
    var section3033Current6: Double? = null,
    var section3033Current7: Double? = null,
    var section3033Current8: Double? = null,
    var section3033Current9: Double? = null,
    var section3033Current10: Double? = null,
    var section3033Current11: Double? = null,
    var section3033Current12: Double? = null,
    var section3033Current13: Double? = null,
    var section3033Current14: Double? = null,
    var section3033Current15: Double? = null,
    var section3033Current16: Double? = null,
    var section3033Current17: Double? = null,
    var section3033Current18: Double? = null,
    var section3033Current19: Double? = null,
    var section3033Current20: Double? = null,
    var section3033Current21: Double? = null,
    var section3033Current22: Double? = null,
    var section3033Current23: Double? = null,
    var section3033Current24: Double? = null,
    var section3033Current25: Double? = null,
    var section3033Current26: Double? = null,
    var section3033Current27: Double? = null,
    var section3033Current28: Double? = null,
    var section3033Current29: Double? = null,
    var section3033Current30: Double? = null,
    var section3033Current31: Double? = null,
    var section3033Current32: Double? = null,
    var section3033Current33: Double? = null,
    var section3033Current34: Double? = null,
    var section3033Current35: Double? = null,
    var section3033Current36: Double? = null,

    var section3033Field1: String? = null,

    var section3034Current1: Double? = null,
    var section3034Current2: Double? = null,
    var section3034Current3: Double? = null,
    var section3034Current4: Double? = null,
    var section3034Current5: Double? = null,
    var section3034Current6: Double? = null,
    var section3034Current7: Double? = null,
    var section3034Current8: Double? = null,
    var section3034Current9: Double? = null,
    var section3034Current10: Double? = null,
    var section3034Current11: Double? = null,
    var section3034Current12: Double? = null,
    var section3034Current13: Double? = null,
    var section3034Current14: Double? = null,
    var section3034Current15: Double? = null,
    var section3034Current16: Double? = null,
    var section3034Current17: Double? = null,
    var section3034Current18: Double? = null,
    var section3034Current19: Double? = null,
    var section3034Current20: Double? = null,
    var section3034Current21: Double? = null,
    var section3034Current22: Double? = null,
    var section3034Current23: Double? = null,
    var section3034Current24: Double? = null,
    var section3034Current25: Double? = null,
    var section3034Current26: Double? = null,
    var section3034Current27: Double? = null,
    var section3034Current28: Double? = null,
    var section3034Current29: Double? = null,
    var section3034Current30: Double? = null,
    var section3034Current31: Double? = null,
    var section3034Current32: Double? = null,
    var section3034Current33: Double? = null,
    var section3034Current34: Double? = null,
    var section3034Current35: Double? = null,
    var section3034Current36: Double? = null,
    var section3034Current37: Double? = null,
    var section3034Current38: Double? = null,

    var section3034Field1: String? = null,
    var section3034Field2: String? = null,
    var section3034Field3: String? = null,
    var section3034Field4: String? = null,

    var section3035Current1: Double? = null,
    var section3035Current2: Double? = null,
    var section3035Current3: String? = null,
    var section3035Current4: String? = null,

    var section3_3_303A1: String? = null,
    var section3_3_303A2: String? = null,
    var section3_3_303A3: String? = null,
    var section3_3_303A4: String? = null,
    var section3_3_303A5: String? = null,
    var section3_3_303A6: String? = null
)
