package com.ecosave.watch.portal.components.privacypolicy

import com.ecosave.watch.portal.helpers.privacypolicy.getMailToWithSubject
import react.FC
import react.Props

val EUResidentsPrivacyPolicy = FC<Props> {
    HeadingWrapper {
        heading = "For those of you in the EU, please see our Notice to EU Residents:"
    }
    HeadingWrapper {
        heading = "Notice to EU Residents"
        subHeading = true
    }
    HeadingWrapper {
        heading = "Dispute resolution process"
        subHeading = true
    }
    TypographyWrapper {
        +"For residents of the European Union (“EU”), in the unlikely event that a dispute arises between you and Ecosave regarding our handling of your Personal Information, we will do our best to resolve the matter with you. If we cannot, we have selected to cooperate with the relevant EU Data Protection Authority, or a panel established by the European data protection authorities, for resolving disputes with EU individuals, and with the Swiss Federal Data Protection and Information Commissioner (FDPIC) for resolving disputes with Swiss individuals. Please contact us if you would like us to direct you to your data protection authority contacts."
    }
    TypographyWrapper {
        +"Additionally, if you are a resident of an EU member state, you have the right to file a complaint with your local supervisory authority."
    }
    TypographyWrapper {
        +"As Ecosave is an American company, we are subject to the jurisdiction of the U.S. Federal Trade Commission (FTC)."
    }
    HeadingWrapper {
        heading = "EU-US and Swiss-US Data Privacy Framework"
    }
    TypographyWrapper {
        +"Ecosave complies with the EU-U.S. Data Privacy program Framework (EU-U.S. DPF) and the Swiss-U.S. Data Privacy program Framework (Swiss-U.S. DPF) as set forth by the U.S. Department of Commerce.  Ecosave has certified to the U.S. Department of Commerce that it adheres to the EU-U.S. Data Privacy Framework program Principles (EU-U.S. DPF Principles) with regard to the processing of personal data received from the European Union in reliance on the EU-U.S. DPF. Ecosave has certified to the U.S. Department of Commerce that it adheres to the Swiss-U.S. Data Privacy Framework program Principles (Swiss-U.S. DPF Principles) with regard to the processing of personal data received from Switzerland in reliance on the Swiss-U.S. DPF.  If there is any conflict between the terms in this privacy policy and the EU-U.S. DPF Principles and/or the Swiss-U.S. DPF Principles, the Principles shall govern."
    }
    TypographyWrapper {
        +"To learn more about the Data Privacy Framework (DPF) program, and to view our certification, please visit "
        CustomLink {
            to = "https://www.dataprivacyframework.gov"
        }
    }
    TypographyWrapper {
        +"In compliance with the EU-US Data Privacy Framework Principles, Ecosave commits to resolve complaints about your privacy and our collection or use of your personal information transferred to the United States pursuant to the DPF Principles. European Union and Swiss individuals with DPF inquiries or complaints should first contact Ecosave at "
        CustomLink {
            to = TermsOfServiceConstants.ecosavePrivacyEmail.getMailToWithSubject("Privacy")
            text = TermsOfServiceConstants.ecosavePrivacyEmail
        }
    }
    TypographyWrapper {
        +"Ecosave has further committed to refer unresolved privacy complaints under the DPF Principles to an independent dispute resolution mechanism, Data Privacy Framework Services, operated by BBB National Programs. If you do not receive timely acknowledgment of your complaint, or if your complaint is not satisfactorily addressed, please visit "
        CustomLink {
            to = "https://bbbprograms.org/programs/all-programs/dpf-consumers/ProcessForConsumers"
        }
        +" for more information and to file a complaint. This service is provided free of charge to you."
    }
    TypographyWrapper {
        +"If your DPF complaint cannot be resolved through the above channels, under certain conditions, you may invoke binding arbitration for some residual claims not resolved by other redress mechanisms. See "
        CustomLink {
            to = "https://www.dataprivacyframework.gov/s/article/G-Arbitration-Procedures-dpf?tabset-35584=2"
        }
    }
}