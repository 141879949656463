package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class SupplierEnvironmentalAssessmentState(
    var section3081: Double? = null,
    var section3082A1: Long? = null,
    var section3082A2: Long? = null,
    var section3082A3: String? = null,
    var section3082A4: Double? = null,
    var section3082A5: Double? = null,

    var section3_3_308A1: String? = null,
    var section3_3_308A2: String? = null,
    var section3_3_308A3: String? = null,
    var section3_3_308A4: String? = null,
    var section3_3_308A5: String? = null,
    var section3_3_308A6: String? = null
)
