package com.ecosave.watch.portal.models.usermanagement

import react.ReactNode

data class RegisterCompanyOrUserValidationState(
    var firstNameErrorState: Boolean = false,
    var firstNameErrorMessage: ReactNode? = null,
    var lastNameErrorState: Boolean = false,
    var lastNameErrorMessage: ReactNode? = null,
    var emailAddressErrorState: Boolean = false,
    var emailAddressErrorMessage: ReactNode? = null,
    var confirmEmailAddressErrorState: Boolean = false,
    var confirmEmailAddressErrorMessage: ReactNode? = null,
    var jobTitleErrorState: Boolean = false,
    var jobTitleErrorMessage: ReactNode? = null,
    var passwordErrorState: Boolean = false,
    var passwordErrorMessage: ReactNode? = null,
    var confirmPasswordErrorState: Boolean = false,
    var confirmPasswordErrorMessage: ReactNode? = null,
    var companyNameErrorState: Boolean = false,
    var companyNameErrorMessage: ReactNode? = null,
    var agreeTermsErrorState: Boolean = false,
    var agreeTermsErrorMessage: ReactNode? = null
)
