package com.ecosave.watch.portal.components.energymanagement

import com.ecosave.watch.portal.models.energymanagement.*
import com.ecosave.watch.portal.helpers.energymanagement.RadioLabels
import mui.material.Box
import mui.material.Typography
import mui.material.Paper
import mui.material.styles.TypographyVariant
import react.FC
import react.Props
import mui.system.sx
import react.dom.html.ReactHTML
import web.cssom.*
import com.ecosave.watch.portal.styles.EnergyManagementStyles as styles

external interface UtilityTotalSectionProps: Props {
    var energyData: List<TotalEnergyUsageNCO2>
    var title: String
    var dataFetched: Boolean
    var dataError: Boolean
    var radioLabel: RadioLabels
    var actionStatus: ActionCount
    var isRanking: Boolean?
    var ranking: List<RankingKPIMetric>
}

val UtilityTotalSection = FC<UtilityTotalSectionProps> {props ->
    Box {
        Typography {
            sx {
                marginTop = 30.px
            }
            variant = TypographyVariant.h6
            component = ReactHTML.h6
            +props.title
        }
        Box {
            sx {
                marginTop = 5.px
            }
            className = styles.SUMMARY_CONTAINER.cssClass
            EnergySummaryTable {
                energyData = props.energyData
                dataFetched = props.dataFetched
            }
            Box {
                className=styles.SUMMARY_PIE_CONTAINER.cssClass
                Paper {
                    className = styles.SUMMARY_PIE.cssClass
                    UtilityTypePieChart {
                        dataFetched = props.dataFetched
                        data = props.energyData
                        radioLabel = props.radioLabel
                        dataError = props.dataError
                    }
                }
                if (props.isRanking == true) {
                    RankingComponent {
                        data = props.ranking
                        dataFetched = props.dataFetched
                        activeRadio = props.radioLabel
                        title = props.title
                    }
                } else {
                    Paper {
                        className = styles.SUMMARY_PIE.cssClass
                        EventPieChart {
                            dataFetched = props.dataFetched
                            data = props.actionStatus
                            dataError = props.dataError
                        }
                    }
                }
            }
        }
    }
}