package com.ecosave.watch.portal.pages

import com.ecosave.watch.portal.components.assetmanagement.AddOrEditAsset
import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.components.common.ConfirmationDialog
import com.ecosave.watch.portal.components.common.DialogSpinner
import com.ecosave.watch.portal.components.npm.MaterialReactTable
import com.ecosave.watch.portal.helpers.assetmanagement.getDataTableData
import com.ecosave.watch.portal.helpers.assetmanagement.headCellProps
import com.ecosave.watch.portal.helpers.assetmanagement.muiTableRowProps
import com.ecosave.watch.portal.helpers.assetmanagement.muiTableSkeleton
import com.ecosave.watch.portal.helpers.assetmanagement.muiTableTheme
import com.ecosave.watch.portal.helpers.assetmanagement.muiTableTopToolBarColor
import com.ecosave.watch.portal.helpers.assetmanagement.tableCellProps
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.FormMode
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.PageTitles
import com.ecosave.watch.portal.helpers.common.showNotification
import com.ecosave.watch.portal.models.assetmanagement.assetColumns
import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.services.assetmanagement.deleteAsset
import com.ecosave.watch.portal.services.assetmanagement.getFacilitiesAndAssets
import com.ecosave.watch.portal.styles.AssetManagementStyles
import com.ecosave.watch.portal.useGlobalState
import js.core.jso
import kotlinx.coroutines.launch
import mui.icons.material.Add
import mui.icons.material.Delete
import mui.icons.material.Edit
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.IconButton
import mui.material.Tooltip
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.create
import react.useEffectOnce
import react.useState
import web.cssom.pct
import web.cssom.rem

val AssetManagement = FC<Props> {

    var loading by useState(true)
    val (assets, setAssets) = useState(mutableListOf<dynamic>())
    var idOfAssetToBeDeleted by useState<Int?>(null)
    val globalState = useGlobalState()
    val (openAddOrEditAssetForm, setOpenAddOrEditAssetForm) = useState(false)
    val (formMode, setFormMode) = useState(FormMode.ADD)
    val (idOfAssetToBeUpdated, setIdOfAssetToBeUpdated) = useState<Int?>(null)
    var openAssetDeletionConfirmationDialog by useState(false)
    var assetDeletionConfirmationDialogBody by useState("")
    val (notificationState, setNotificationState) = useState(NotificationState())
    var deletingAsset by useState(false)

    useEffectOnce {
        globalState.updatePageTitle(PageTitles.ASSET_MANAGEMENT.title)
        mainScope.launch {
            setAssets(getDataTableData(getFacilitiesAndAssets()))
            loading = false
        }
    }

    Box {
        className = AssetManagementStyles.ASSET_MANAGEMENT_TABLE.cssClass
        sx {
            width = 100.pct
        }
        Box {
            MaterialReactTable {
                columns = assetColumns
                columnResizeMode = "onChange"
                data = assets.toTypedArray()
                globalFilterFn = "contains"
                editingMode = "modal"
                enableColumnOrdering = true
                enableEditing = true
                enablePagination = true
                enablePinning = true
                enableColumnPinning = true
                enableStickyHeader = true
                enableRowVirtualization = false
                enableTableFooter = false
                initialState = jso {
                    columnPinning = jso {
                        left = arrayOf("mrt-row-actions")
                    }
                }
                showSkeletons = loading
                showLoadingOverlay = loading
                showProgressBars = loading
                muiTablePaperProps = jso {
                    elevation = 0
                }
                muiSkeletonProps = muiTableSkeleton
                muiTableHeadProps = muiTableTheme
                muiTableFooterRowProps = muiTableTheme
                muiTableHeadRowProps = muiTableTheme
                muiTableBodyRowProps = muiTableRowProps
                muiTopToolbarProps = muiTableTopToolBarColor
                muiBottomToolbarProps = muiTableTopToolBarColor
                muiTableBodyCellProps = tableCellProps
                muiTableHeadCellProps = headCellProps
                enableDensityToggle = false
                enableColumnDragging = false
                enableClickToCopy = false
                state = jso {
                    isLoading = loading
                    density = "compact"
                }
                enableHiding = false
                enableRowActions = true
                renderRowActions = { cell, _, _ ->
                    cell.cell.column.columnDef.minSize = 120
                    cell.cell.column.columnDef.size = 120
                    Box.create {
                        sx {
                            gap = 1.rem
                        }
                        Tooltip {
                            arrow = true
                            title = ReactNode("Edit")
                            IconButton {
                                onClick = {
                                    val selectedAssetId: Int = cell.row.original.assetId
                                    setFormMode(FormMode.EDIT)
                                    setIdOfAssetToBeUpdated(selectedAssetId)
                                    setOpenAddOrEditAssetForm(true)
                                }
                                Edit {}
                            }
                        }
                        Tooltip {
                            arrow = true
                            title = ReactNode("Delete")
                            IconButton {
                                onClick = {
                                    assetDeletionConfirmationDialogBody =
                                        "Are you sure you want to delete ${cell.row.original.equipmentType} at ${cell.row.original.equipmentLocation}?"
                                    idOfAssetToBeDeleted = cell.row.original.assetId
                                    openAssetDeletionConfirmationDialog = true
                                }
                                Delete {}
                            }
                        }
                    }
                }
                renderTopToolbarCustomActions = { _ ->
                    Button.create {
                        variant = ButtonVariant.contained
                        onClick = {
                            setFormMode(FormMode.ADD)
                            setOpenAddOrEditAssetForm(true)
                        }
                        startIcon = startIcon.also { Add() }
                        +"Add New Asset"
                    }
                }
            }
        }
    }
    AddOrEditAsset {
        this.openAddOrEditAssetForm = openAddOrEditAssetForm
        this.setOpenAddOrEditAssetForm = setOpenAddOrEditAssetForm
        this.formMode = formMode
        this.assets = assets
        this.setAssets = setAssets
        this.idOfAssetToBeUpdated = idOfAssetToBeUpdated
        this.setIdOfAssetToBeUpdated = setIdOfAssetToBeUpdated
    }
    ConfirmationDialog {
        open = openAssetDeletionConfirmationDialog
        title = "Delete Asset"
        body = assetDeletionConfirmationDialogBody
        actionResult = { value ->
            if (value) {
                mainScope.launch {
                    idOfAssetToBeDeleted?.let { assetId ->
                        deletingAsset = true
                        val status = deleteAsset(assetId)
                        if (status == ApiCallStatus.SUCCESS) {

                            val index = assets.indexOf(
                                assets.first { it.assetId == assetId }
                            )
                            assets.removeAt(index)
                            setAssets(assets)

                            showNotification(
                                "Asset deleted successfully.",
                                NotificationStatus.SUCCESS,
                                notificationState,
                                setNotificationState
                            )
                        } else {
                            showNotification(
                                "Asset deletion failed.",
                                NotificationStatus.ERROR,
                                notificationState,
                                setNotificationState
                            )
                        }
                        deletingAsset = false
                    }
                }
            }
            openAssetDeletionConfirmationDialog = false
            idOfAssetToBeDeleted = null
            assetDeletionConfirmationDialogBody = ""
        }
    }
    AlertNotifications {
        open = notificationState.visible
        status = notificationState.status
        message = notificationState.message
        closeNotification = {
            setNotificationState(
                notificationState.copy(
                    visible = false
                )
            )
        }
    }
    DialogSpinner {
        open = deletingAsset
        message = "Deleting Asset..."
    }
}
