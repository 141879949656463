package com.ecosave.watch.portal.models.energymanagement

import com.ecosave.watch.portal.helpers.billing.UtilityType
import kotlinx.serialization.Serializable
@Serializable
data class EnergyUsageNCO2(
    val utilityType: UtilityType,
    val billingMonth: String,
    var utilityUsage: Double,
    var utilityCost: Double,
    var utilityCo2Emission: Double
)
@Serializable
data class EnergyUsageNCO2Details(
    val utilityType: UtilityType,
    val energyUsageNCO2: List<EnergyUsageNCO2>
)
@Serializable
data class TotalEnergyUsageNCO2(
    val utilityType: UtilityType = UtilityType.SOLAR,
    var utilityUsage: Double = 0.0,
    var utilityUsageInMMBtu: Double = 0.0,
    var utilityCost: Double = 0.0,
    var utilityCo2Emission: Double = 0.0
)
@Serializable
data class FacilityDetails(
    val facilityName: String = "",
    val facilityId: Int = 0,
    val facilityDescription: String? = ""
)
@Serializable
data class ActionCount(
    var actionAssigned: Int = 0,
    var actionUnassigned: Int = 0,
    var actionInProgress: Int = 0,
    var actionCompleted: Int = 0,
)
@Serializable
data class SearchCriteria(
    val startDate: String,
    val endDate: String,
    val energyTotal: List<TotalEnergyUsageNCO2>,
    val utilityBrakeDowns: List<EnergyUsageNCO2Details>,
    val actionsStatusCount: ActionCount? = null
)
@Serializable
data class EnergyUsageCostNCO2PerFacility(
    val facilities: List<FacilityDetails> = emptyList(),
    val searchCriteriaOne: SearchCriteria? = null,
    val searchCriteriaTwo: SearchCriteria? = null,
    val baselineStatus: BaselineSelectionStatus = BaselineSelectionStatus.NOT_AVAILABLE
)
@Serializable
data class UtilityBaseline(
    val utilityType: UtilityType,
    val selectedEnergyBaseline: List<EnergyUsageNCO2>
)
@Serializable
data class BaselineSummary(
    val startDate: String,
    val utilityBaselineList: List<UtilityBaseline>
)
@Serializable
data class EnergyKPIMetric(
        val co2Intensity: Double = 0.0,
        val siteEnergyUseIntensity: Double = 0.0,
        val costIntensity: Double = 0.0
)
@Serializable
data class RankingKPIMetric(
        val facilityDetails: FacilityDetails,
        val energyKPIMetric: EnergyKPIMetric
)

@Serializable
data class RankingDataResponse(
        val firstPeriod: List<RankingKPIMetric>,
        val secondPeriod: List<RankingKPIMetric>
)
@Serializable
data class TargetUsageAndCost(
        var targetPeriod: String,
        var targetUsage: Double = 0.0,
        var targetCost: Double = 0.0
)
@Serializable
data class UtilityTarget(
        val utilityType: UtilityType,
        val selectedEnergyTarget: List<EnergyUsageNCO2>? = emptyList()
)
@Serializable
data class TargetSummary(
        val startDate: String,
        val utilityTargetList: List<UtilityTarget>? = emptyList()
)

@Serializable
data class FacilityTargetSummary(
        val facilityId: Int,
        val targetMap: Map<String, Map<String, Int>>
)
