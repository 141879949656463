package com.ecosave.watch.portal.components.esg

import com.ecosave.watch.portal.helpers.esg.EsgPages
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.system.sx
import react.FC
import react.Props
import react.StateSetter
import web.cssom.Display
import web.cssom.JustifyContent
import web.cssom.px

external interface NavigationComponentProps : Props {
    var visiblePage: EsgPages
    var setVisiblePage: StateSetter<EsgPages>
}

val NavigationComponent = FC<NavigationComponentProps> { props ->

    Box {
        sx {
            display = Display.flex
            gap = 10.px
            justifyContent = JustifyContent.flexEnd
            marginBottom = 20.px
        }
        if (props.visiblePage == EsgPages.TABLE_OF_CONTENTS) {
            Button {
                variant = ButtonVariant.contained
                onClick = {
                    props.setVisiblePage(EsgPages.SELECT_SECTIONS)
                }
                +"Include/Exclude Sections"
            }
        }
        if (props.visiblePage == EsgPages.SELECTED_SECTION_FORM) {
            Button {
                variant = ButtonVariant.contained
                onClick = {
                    props.setVisiblePage(EsgPages.TABLE_OF_CONTENTS)
                }
                +"Table Of Contents"
            }
        }
        Button {
            variant = ButtonVariant.contained
            onClick = {
                props.setVisiblePage(EsgPages.ESG_REPORTS)
            }
            +"All Reports"
        }
    }

}