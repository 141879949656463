package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class ActivitiesAndWorkersState(
    var section26Field1: String? = null,
    var section26Field2: String? = null,
    var section26Field3: String? = null,
    var section26Field4: String? = null,
    var section26Field5: String? = null,
    var section26Field6: String? = null,

    var employeesByRegionList1: MutableList<EmployeesByRegion1> = mutableListOf(),

    var employeesByRegionList2: MutableList<EmployeesByRegion2> = mutableListOf(),

    var section27Field1: String? = null,
    var section27Field2: String? = null,
    var section27Field3: String? = null,

    var section28B1: Long? = null,
    var section28list: MutableList<Section28> = mutableListOf(),
    var section28Field1: String? = null,
    var section28Field2: String? = null,
    var section28Field3: String? = null,
)

@Serializable
data class Section28(
    var section28Field4: String? = null,
    var section28Field5: String? = null,
    var section28Field6: String? = null,
) : DynamicTableItemKey()

@Serializable
data class EmployeesByRegion2(
    var section27Part2A1: String? = null,
    var section27Part2A2: String? = null,
    var section27Part2B1: Long? = null,
    var section27Part2C1: Long? = null,
    var section27Part2D1: Long? = null,
    var section27Part2E1: Long? = null,
    var section27Part2F1: Long? = null,
) : DynamicTableItemKey()

@Serializable
data class EmployeesByRegion1(
    var section27Part1A1: String? = null,
    var section27Part1A2: String? = null,
    var section27Part1C1: Long? = null,
) : DynamicTableItemKey()
