package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class EmploymentState(
    var newHiresTurnoverList: MutableList<NewHiresTurnover> = mutableListOf(),
    var benefitsList: MutableList<EmployeeBenefits> = mutableListOf(),

    var section4013Current1: Long? = null,
    var section4013Current2: Long? = null,
    var section4013Current3: Long? = null,
    var section4013Current4: Long? = null,
    var section4013Current5: Long? = null,
    var section4013Current6: Long? = null,
    var section4013Current7: Long? = null,
    var section4013Current8: Long? = null,
    var section4013Current9: Long? = null,
    var section4013Current10: Long? = null,
    var section4013Current11: Long? = null,
    var section4013Current12: Long? = null,
    var section4013Current13: Long? = null,
    var section4013Current14: Long? = null,
    var section4013Current15: Long? = null,
    var section4013Current16: Long? = null,
    var section4013Current17: Long? = null,
    var section4013Current18: Long? = null,
    var section4013Current19: Long? = null,
    var section4013Current20: Long? = null,
    var section4013Current21: Long? = null,
    var section4013Current22: Long? = null,
    var section4013Current23: Long? = null,
    var section4013Current24: Long? = null,

    var section4013Previous1: Long? = null,
    var section4013Previous2: Long? = null,
    var section4013Previous3: Long? = null,
    var section4013Previous4: Long? = null,
    var section4013Previous5: Long? = null,
    var section4013Previous6: Long? = null,
    var section4013Previous7: Long? = null,
    var section4013Previous8: Long? = null,
    var section4013Previous9: Long? = null,
    var section4013Previous10: Long? = null,
    var section4013Previous11: Long? = null,
    var section4013Previous12: Long? = null,
    var section4013Previous13: Long? = null,
    var section4013Previous14: Long? = null,
    var section4013Previous15: Long? = null,
    var section4013Previous16: Long? = null,
    var section4013Previous17: Long? = null,
    var section4013Previous18: Long? = null,
    var section4013Previous19: Long? = null,
    var section4013Previous20: Long? = null,
    var section4013Previous21: Long? = null,
    var section4013Previous22: Long? = null,
    var section4013Previous23: Long? = null,
    var section4013Previous24: Long? = null,

    var section3_3_401A1: String? = null,
    var section3_3_401A2: String? = null,
    var section3_3_401A3: String? = null,
    var section3_3_401A4: String? = null,
    var section3_3_401A5: String? = null,
    var section3_3_401A6: String? = null
)

@Serializable
data class NewHiresTurnover(
    var emp401region: String? = null,
    var emp401ageGroup: String? = null,
    var emp401sexGroup: String? = null,
    var newHires401count: Long? = null,
    var turnover401count: Long? = null,
    var newHires401rate: Double? = null,
    var turnover401rate: Double? = null
) : DynamicTableItemKey()

@Serializable
data class EmployeeBenefits(
    var location: String? = null,
    var lifeInsurance: String? = null,
    var healthCare: String? = null,
    var disability: String? = null,
    var parentalLeave: String? = null,
    var retirementProvision: String? = null,
    var stockOwnership: String? = null,
    var otherBefenits: String? = null
) : DynamicTableItemKey()
