package com.ecosave.watch.portal.models.assetmanagement

import com.ecosave.watch.portal.helpers.assetmanagement.createFacilityTimeInitialData
import kotlinx.datetime.DayOfWeek
import kotlinx.serialization.Serializable

@Serializable
data class FacilityClass(
    val facilityId: Int? = null,
    val facilityName: String? = null,
    val facilityDescription: String? = null,
    val companyId: Int? = null,
    val is24x7: Boolean? = null,
    val facilityDailyOperations: MutableList<RegistrationDailyOperation>? = createFacilityTimeInitialData(),
    val buildingCategory: String? = null,
    val buildingType: String? = null,
    val buildingTotalArea: Float? = null,
    val buildingOccupancy: Int? = null,
    val buildingAddress: Address? = null,
    val buildingYearBuilt: Int? = null,
    val otherBuildingType: String? = null,
    val rulesExecuted: Boolean? = null,
    val baselineStartDate: String? = null,
    val expectedSavingsPercentage: Float? = null,
    val thresholdPercentage: Float? = null,
    var imageUrl: String? = null,
    val propertyId: Int? = null,
)

@Serializable
data class Address(
    var street: String? = "",
    var city: String? = "",
    var state: String? = "",
    var zipcode: String? = "",
)

@Serializable
data class RegistrationDailyOperation(
    var dayOfTheWeek: DayOfWeek,
    var startTime: String = "12:00:00",
    var endTime: String = "12:00:00"
)

@Serializable
data class FacilityAndAssets(
    val facilityId: Int,
    val facilityName: String,
    val assets: List<Asset> = listOf()
)

@Serializable
data class ResponseAndFacilityClass(
    var facilityClass: FacilityClass = FacilityClass(),
    var response: Boolean = false
)