package com.ecosave.watch.portal.models.controlcenter

import kotlinx.serialization.Serializable

@Serializable
data class ActionDataSet(
    val major: Int = 0,
    val moderate: Int = 0,
    val minor: Int = 0
)

@Serializable
data class EnergyKPIMetric(
    val score: Int = 0,
    val siteEnergyUseIntensity: Double = 0.0,
    val costIntensity: Double = 0.0
)

@Serializable
data class PieData(
        val name: String,
        val usage: Double = 0.0,
        val billCost: Double = 0.0
)

sealed external interface JSONPieData {
    var name: String
    var usage: Double
    var billCost: Double
}

enum class RadioLabels(val label: String, val value: String, val title: String) {
    BOTH("All", "both", "Energy & Cost Management Overview"),
    USAGE("Energy", "energy", "Energy Management Overview"),
    COST("Cost", "cost", "Cost Management Overview"),
}

enum class View(val value: String, val label: String, val title: String, val yLabel: String, val dataKey: String) {
    USAGE("Usage", "Energy", "Annual Usage Summary", "Energy (MMBtu)", "usage"),
    COST("Cost", "Cost", "Annual Cost Summary", "Cost ($)", "billCost")
}

enum class DataKeys(val value: String, val label: String, val prefix: String, val surfix: String) {
    STEAM("STEAM", "Steam", "", ""),
    NATURAL_GAS("NATURAL_GAS", "Natural Gas", "", ""),
    ELECTRIC("ELECTRIC", "Electric", "", ""),
    BASELINE_USAGE("baselineUsage", "Baseline Usage", "", "MMBtu"),
    STEAM_USAGE("steamUsage", "Steam Usage", "", "MMBtu"),
    NATURAL_GAS_USAGE("naturalGasUsage", "Natural Gas Usage", "", "MMBtu"),
    ELECTRIC_USAGE("electricUsage", "Electric Usage", "", "MMBtu"),
    BASELINE_COST("baselineCost", "Baseline Cost", "$", ""),
    STEAM_COST("steamCost", "Steam Cost", "$", ""),
    NATURAL_GAS_COST("naturalGasCost", "Natural Gas Cost", "$", ""),
    ELECTRIC_COST("electricCost", "Electric Cost", "$", ""),
    EMPTY("", "", "", "")
}
