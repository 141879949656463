package com.ecosave.watch.portal.components.utilityaccounts

import com.ecosave.watch.portal.helpers.Colors
import com.ecosave.watch.portal.helpers.billing.BillingConstants
import com.ecosave.watch.portal.helpers.billing.UtilityAccountFields
import com.ecosave.watch.portal.helpers.billing.UtilityDataCollectionMethod
import com.ecosave.watch.portal.helpers.billing.isHistoryAlreadyRequested
import com.ecosave.watch.portal.helpers.billing.validateCollectionMethod
import com.ecosave.watch.portal.helpers.common.ServicesCost
import com.ecosave.watch.portal.helpers.common.xs
import com.ecosave.watch.portal.models.billing.UtilityAccountState
import com.ecosave.watch.portal.models.billing.UtilityAccountSummary
import com.ecosave.watch.portal.models.billing.UtilityAccountValidationState
import kotlinx.datetime.LocalDateTime
import mui.material.Alert
import mui.material.AlertColor
import mui.material.AlertVariant
import mui.material.Box
import mui.material.FormControl
import mui.material.FormControlLabel
import mui.material.FormHelperText
import mui.material.Grid
import mui.material.InputLabel
import mui.material.MenuItem
import mui.material.Select
import mui.material.Size
import mui.material.Switch
import mui.material.Tooltip
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.StateSetter
import react.create
import react.useEffect
import react.useState
import web.cssom.Color
import web.cssom.Display
import web.cssom.Margin
import web.cssom.px

external interface DataCollectionOptionsProps : Props {
    var formStateProp: UtilityAccountState
    var formStateSetterProp: StateSetter<UtilityAccountState>
    var formValidationStateProp: UtilityAccountValidationState
    var formValidationStateSetterProp: StateSetter<UtilityAccountValidationState>
    var addUtilityAccount: Boolean
    var utilityAccountSummary: UtilityAccountSummary?
}

val DataCollectionOptions = FC<DataCollectionOptionsProps> { props ->

    val formState = props.formStateProp
    val formStateSetter = props.formStateSetterProp
    val formValidationState = props.formValidationStateProp
    val formValidationStateSetter = props.formValidationStateSetterProp
    var showHistoryRequiredSwitch by useState(false)

    useEffect(formState.collectionMethod) {

        showHistoryRequiredSwitch = formState.collectionMethod == UtilityDataCollectionMethod.ECOSAVE_WATCH.name

        if (formState.collectionMethod == UtilityDataCollectionMethod.MANUAL_UTILITY_DATA_ENTRY.name) {
            formStateSetter(
                formState.copy(
                    historyRequired = null,
                    userId = null,
                    password = null
                )
            )
            formValidationStateSetter(
                formValidationState.copy(
                    userIdErrorState = false,
                    userIdErrorMessage = null,
                    passwordErrorState = false,
                    passwordErrorMessage = null
                )
            )
        } else if (formState.collectionMethod == UtilityDataCollectionMethod.ECOSAVE_WATCH.name) {
            formStateSetter(
                formState.copy(
                    historyRequired = true
                )
            )
        }
    }

    Grid {
        container = true
        Grid {
            item = true
            xs = 12
            BillDataCollectionLabelComponent()
        }

        Grid {
            item = true
            xs = 12
            Box {
                sx {
                    margin = 20.px
                }
                FormControl {
                    fullWidth = true
                    size = Size.medium
                    InputLabel {
                        +UtilityAccountFields.COLLECTION_METHOD.field
                    }
                    error = formValidationState.collectionMethodErrorState
                    Select {
                        label = ReactNode(UtilityAccountFields.COLLECTION_METHOD.field)
                        value = formState.collectionMethod
                        onChange = { event, _ ->
                            val value = event.target.value
                            formStateSetter(
                                formState.copy(
                                    collectionMethod = value,
                                )
                            )
                        }
                        if (formState.utilityServiceProviderId == BillingConstants.OTHER_UTILITY_SERVICE_PROVIDER_ID.toString()) {
                            MenuItem {
                                value = UtilityDataCollectionMethod.MANUAL_UTILITY_DATA_ENTRY.name
                                +UtilityDataCollectionMethod.MANUAL_UTILITY_DATA_ENTRY.description
                            }
                        } else {
                            for (collectionMethods in BillingConstants.utilityDataCollectionMethodLists) {
                                MenuItem {
                                    value = collectionMethods.name
                                    +collectionMethods.description
                                }
                            }
                        }
                        onBlur = {
                            validateCollectionMethod(
                                formState,
                                formValidationState,
                                formValidationStateSetter
                            )
                        }
                    }
                    FormHelperText {
                        +formValidationState.collectionMethodErrorMessage
                    }
                }
            }
        }

        // During account edit if user switches from automatic data collection to manual
        if (props.utilityAccountSummary?.collectionMethod == UtilityDataCollectionMethod.ECOSAVE_WATCH && formState.collectionMethod == UtilityDataCollectionMethod.MANUAL_UTILITY_DATA_ENTRY.name) {
            Grid {
                item = true
                xs = 12
                Alert {
                    sx {
                        margin = Margin(0.px, 20.px)
                    }
                    color = AlertColor.warning
                    severity = AlertColor.warning
                    variant = AlertVariant.outlined
                    +"Subscription Cancellation: EcosaveWatch Automatic Data Collection Subscription of ${ServicesCost.ECOSAVE_WATCH_AUTOMATIC_DATA_COLLECTION} per month will be cancelled for this utility account at the end of billing period, once you save."
                }
            }
        }
        // While Account Registration if user chooses automatic data collection or during edit if user switches from manual to automatic
        if ((formState.utilityAccountId == null && formState.collectionMethod == UtilityDataCollectionMethod.ECOSAVE_WATCH.name) ||
            (formState.utilityAccountId != null && formState.collectionMethod == UtilityDataCollectionMethod.ECOSAVE_WATCH.name &&
                    props.utilityAccountSummary?.collectionMethod == UtilityDataCollectionMethod.MANUAL_UTILITY_DATA_ENTRY)
        ) {
            Grid {
                item = true
                xs = 12
                Alert {
                    sx {
                        margin = 20.px
                    }
                    color = AlertColor.warning
                    severity = AlertColor.info
                    variant = AlertVariant.outlined
                    +"Subscription Activation: EcosaveWatch Automatic Data Collection Subscription of ${ServicesCost.ECOSAVE_WATCH_AUTOMATIC_DATA_COLLECTION} per month will be active for this utility account once you save."
                }
            }
        }

        if (showHistoryRequiredSwitch) {
            Grid {
                item = true
                xs = 12
                Box {
                    sx {
                        margin = Margin(0.px, 20.px, 20.px, 20.px)
                    }
                    Tooltip {
                        props.utilityAccountSummary?.historyRequestedOn?.let {
                            title = ReactNode("Request for historical data was already made on ${LocalDateTime.parse(it).date}")
                        }
                        FormControlLabel {
                            disabled = isHistoryAlreadyRequested(props.utilityAccountSummary?.historyRequestedOn)
                            label = Box.create {
                                sx {
                                    display = Display.flex
                                    gap = 5.px
                                }
                                Typography {
                                    variant = TypographyVariant.body1
                                    +"Also, collect my historical data for a one time fee of"
                                }
                                Typography {
                                    variant = TypographyVariant.body1
                                    sx {
                                        if (!isHistoryAlreadyRequested(props.utilityAccountSummary?.historyRequestedOn)) {
                                            color = Color(Colors.GREEN)
                                        }
                                    }
                                    +"${ServicesCost.UTILITY_HISTORY_DATA}."
                                }
                            }
                            control = Switch.create {
                                checked = formState.historyRequired == true || props.utilityAccountSummary?.historyRequestedOn != null
                                onChange = { _, value ->
                                    formStateSetter(
                                        formState.copy(
                                            historyRequired = value
                                        )
                                    )
                                }
                            }
                        }
                        Typography {
                            variant = TypographyVariant.body2
                            sx {
                                marginLeft = 45.px
                                if (isHistoryAlreadyRequested(props.utilityAccountSummary?.historyRequestedOn)) {
                                    color = Color(Colors.TEXT_DISABLED)
                                }
                            }
                            +"All available historic bill data on your utility provider's website will be synced."
                        }
                    }
                }
            }
        }

        if (formState.collectionMethod == UtilityDataCollectionMethod.ECOSAVE_WATCH.name) {
            Grid {
                item = true
                xs = 12
                AutomaticDataCollectionRequiredFields {
                    this.formState = formState
                    setFormState = formStateSetter
                    this.formValidationState = formValidationState
                    setFormValidationState = formValidationStateSetter
                }
            }
        }

    }
}