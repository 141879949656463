package com.ecosave.watch.portal.services

import com.ecosave.watch.portal.helpers.BackendServerURL
import com.ecosave.watch.portal.models.billing.Facility
import com.ecosave.watch.portal.models.userprofile.UserProfileApiResponse
import com.ecosave.watch.portal.models.userprofile.UserProfileState
import com.ecosave.watch.portal.models.userprofile.UserProfileUpdateApiRequest
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*

suspend fun getUserProfile(): UserProfileApiResponse? {
    try {
        val response: HttpResponse = httpClient.get("$BackendServerURL/user-profile")
        if (response.status == HttpStatusCode.OK) {
            return response.body()
        }
    } catch (e: dynamic) {
        return null
    }
    return null
}

suspend fun saveUserProfile(userProfile: UserProfileState): HttpStatusCode? {
    try {
        val response: HttpResponse = httpClient.put("$BackendServerURL/user-profile") {
            setBody(
                UserProfileUpdateApiRequest(
                    firstName = userProfile.firstName,
                    lastName = userProfile.lastName,
                    jobTitle = userProfile.jobTitle
                )
            )
        }
        if (response.status == HttpStatusCode.OK) {
            return HttpStatusCode.OK
        }
    } catch (e: dynamic) {

    }
    return null
}

suspend fun saveFacilityPreference(id: Int, name: String): HttpStatusCode? {
    try {
        val response: HttpResponse = httpClient.put("$BackendServerURL/user-profile/default-facility") {
            setBody(
                Facility(
                    facilityId = id,
                    facilityName = name
                )
            )
        }
        if (response.status == HttpStatusCode.OK) {
            return HttpStatusCode.OK
        }
    } catch (e: dynamic) {

    }
    return null
}