package com.ecosave.watch.portal.components.onboarding

import com.ecosave.watch.portal.StateContextProps
import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.components.common.GlobalMessage
import com.ecosave.watch.portal.components.common.Loading
import com.ecosave.watch.portal.helpers.Colors
import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.PageRoutes
import com.ecosave.watch.portal.helpers.common.showNotification
import com.ecosave.watch.portal.helpers.onboarding.MainSubscriptionPayment
import com.ecosave.watch.portal.helpers.usermanagement.OnBoardingStatus
import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.models.onboarding.ProductsPaymentSummary
import com.ecosave.watch.portal.pages.mainScope
import com.ecosave.watch.portal.services.getOnboardingStatus
import com.ecosave.watch.portal.services.onboarding.getMainProducts
import com.ecosave.watch.portal.styles.CommonStyles
import com.ecosave.watch.portal.styles.FacilityManagementStyles
import com.ecosave.watch.portal.styles.SubscriptionPaymentPageStyles
import com.ecosave.watch.portal.useGlobalState
import emotion.react.css
import kotlinx.browser.localStorage
import kotlinx.coroutines.launch
import mui.icons.material.Logout
import mui.material.Alert
import mui.material.AlertColor
import mui.material.AlertVariant
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.IconButton
import mui.material.Paper
import mui.material.Tooltip
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.ChildrenBuilder
import react.FC
import react.Props
import react.ReactNode
import react.dom.html.ReactHTML
import react.router.useNavigate
import react.useEffectOnce
import react.useState
import web.cssom.Color
import web.cssom.FontWeight
import web.cssom.pct
import web.cssom.px

val SubscriptionPayment = FC<Props> {

    var productsPaymentSummary by useState<ProductsPaymentSummary?>(null)
    val navigate = useNavigate()
    val stateContextValue = useGlobalState()
    var subscriptionPaymentState by useState<MainSubscriptionPayment>()
    var isLoading by useState(false)
    val (notificationState, setNotificationState) = useState(NotificationState())

    useEffectOnce {
        isLoading = true
        if (stateContextValue.userData.onBoardingStatus != OnBoardingStatus.SUBSCRIPTION_PAYMENT_PENDING) {
            navigate.invoke(PageRoutes.PAGE_NOT_FOUND.route)
        } else {
            mainScope.launch {
                val response = getMainProducts()
                productsPaymentSummary = response.productsPaymentSummary
                subscriptionPaymentState = response.paymentStatus
            }
        }
        isLoading = false
    }

    if (isLoading || subscriptionPaymentState == null) {
        Loading()
    } else {
        Box {
            className = SubscriptionPaymentPageStyles.MAIN.cssClass
            Paper {
                className = SubscriptionPaymentPageStyles.PAPER.cssClass
                Box {
                    className = SubscriptionPaymentPageStyles.INNER.cssClass
                    Box {
                        className = FacilityManagementStyles.FACILITY_LOGO_BOX.cssClass
                        ReactHTML.img {
                            css {
                                width = 32.px
                                height = 32.px
                                marginRight = 10.px
                            }
                            src = "/images/logo/company-circle-logo.png"
                        }
                        Typography {
                            variant = TypographyVariant.h5
                            +"Subscription Payment"
                        }
                    }
                    Box {
                        className = SubscriptionPaymentPageStyles.CONTENT.cssClass
                        when (subscriptionPaymentState) {

                            MainSubscriptionPayment.PAYMENT_ALREADY_DONE -> {
                                Box {
                                    className = CommonStyles.MESSAGE_WRAPPER.cssClass
                                    Typography {
                                        sx {
                                            fontWeight = FontWeight.bolder
                                        }
                                        variant = TypographyVariant.h3
                                        +"Congratulations!"
                                    }
                                    Typography {
                                        sx {
                                            fontWeight = FontWeight.bolder
                                            color = Color(Colors.GREEN)
                                        }
                                        variant = TypographyVariant.h5
                                        +"Your subscription is active."
                                    }
                                    Button {
                                        variant = ButtonVariant.contained
                                        disabled = isLoading
                                        onClick = {
                                            mainScope.launch {
                                                isLoading = true
                                                val onboardingStatus = getOnboardingStatus()
                                                if (onboardingStatus != null) {
                                                    stateContextValue.handleUpdateUserData(
                                                        stateContextValue.userData.copy(
                                                            onBoardingStatus = onboardingStatus
                                                        )
                                                    )
                                                    navigate.invoke(PageRoutes.ONBOARDING.route)
                                                } else {
                                                    showNotification(
                                                        Constants.NOTIFICATION_ERROR_MESSAGE,
                                                        NotificationStatus.ERROR,
                                                        notificationState,
                                                        setNotificationState
                                                    )
                                                }
                                                isLoading = false
                                            }
                                        }
                                        if (isLoading) +"Redirecting..." else +"Start Onboarding"
                                    }
                                }
                            }

                            MainSubscriptionPayment.MAKE_PAYMENT -> {
                                Alert {
                                    sx {
                                        marginTop = 20.px
                                        marginBottom = 20.px
                                    }
                                    color = AlertColor.success
                                    severity = AlertColor.success
                                    variant = AlertVariant.outlined
                                    +"Your account has been created successfully."
                                }
                                ProductsTable {
                                    this.productsPaymentSummary = productsPaymentSummary
                                }
                            }

                            MainSubscriptionPayment.EXCEPTION -> {
                                GlobalMessage {
                                    title = "Oops!"
                                    message = Constants.GENERIC_EXCEPTION_MESSAGE
                                }
                            }

                            else -> {
                                GlobalMessage {
                                    title = "Oops!"
                                    message = Constants.GENERIC_EXCEPTION_MESSAGE
                                }
                            }
                        }
                    }
                }
            }
            Box {
                sx {
                    height = 90.pct
                    marginLeft = 10.px
                }
                signOut(stateContextValue)
            }
        }
    }
    AlertNotifications {
        open = notificationState.visible
        status = notificationState.status
        message = notificationState.message
        closeNotification = {
            setNotificationState(
                notificationState.copy(
                    visible = false
                )
            )
        }
    }
}

fun ChildrenBuilder.signOut(globalState: StateContextProps): Unit = Tooltip {
    Tooltip {
        title = ReactNode("Sign Out")
        IconButton {
            onClick = {
                localStorage.clear()
                globalState.updateIsAuthenticated(false)
            }
            Logout()
        }
    }
}