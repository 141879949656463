package com.ecosave.watch.portal.components.common

import react.ChildrenBuilder
import react.dom.html.OlType
import react.dom.html.ReactHTML.label
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.ol
import web.cssom.ClassName

fun ChildrenBuilder.LabelListWrapper(
    inOlType: OlType,
    title: String,
    list: List<String>,
    inClassName: ClassName? = null
): Unit = label {
    +title

    if (inClassName != null) {
        className = inClassName
    }

    ol {
        type = inOlType

        list.forEach { listItem ->
            li {
                +listItem
            }
        }
    }
}

fun ChildrenBuilder.RomanListWrapper (
    title: String,
    list: List<String>,
    inClassName: ClassName? = null
): Unit = LabelListWrapper(
    inOlType = OlType.i,
    title = title,
    list = list,
    inClassName = inClassName
)

fun ChildrenBuilder.AlphaListWrapper (
    title: String,
    list: List<String>,
    inClassName: ClassName? = null
): Unit = LabelListWrapper(
    inOlType = OlType.a,
    title = title,
    list = list,
    inClassName = inClassName
)