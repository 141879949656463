package com.ecosave.watch.portal.components.esg.waterandeffluents

import com.ecosave.watch.portal.components.common.RomanListWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.*
import com.ecosave.watch.portal.components.esg.waterandeffluents.WaterConstants.GROUND_WATER
import com.ecosave.watch.portal.components.esg.waterandeffluents.WaterConstants.PRODUCED_WATER
import com.ecosave.watch.portal.components.esg.waterandeffluents.WaterConstants.SEA_WATER
import com.ecosave.watch.portal.components.esg.waterandeffluents.WaterConstants.SURFACE_WATER
import com.ecosave.watch.portal.components.esg.waterandeffluents.WaterConstants.THIRD_PARTY_WATER
import com.ecosave.watch.portal.components.esg.waterandeffluents.WaterConstants.TOTAL_WATER
import com.ecosave.watch.portal.components.esg.waterandeffluents.WaterConstants.headers
import com.ecosave.watch.portal.helpers.Colors
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import emotion.react.css
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML
import react.dom.onChange
import web.cssom.Color
import web.cssom.px

val GRI_303_4 = FC<EsgSubFormComponentProps> { props ->
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea
    val onChangeDecimalNumber = props.onChangeDecimalNumber
    val onBlurDecimalNumber = props.onBlurDecimalNumber

    props.esgReportState.report?.waterAndEffluents?.let { state ->
        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_303_4
        }

        DisclosureSubHeading {
            subHeading = "Total water discharge to all areas in megaliters, and a breakdown of this total by the" +
                "following types of destination, if applicable:"
        }

        Box {
            css {
                color = Color(Colors.HALF_GREEN)
                fontSize = 20.px
            }
            RomanListWrapper(
                title = "A breakdown of total water discharge to all areas in megaliters by the following " +
                    "categories:",
                list = listOf(
                    "Freshwater (less than or equal to 1,000 mg/L Total Dissolved Solids);",
                    "Other water (greater then 1,000 mg/L Total Dissolved Solids)."
                )
            )
        }

        Box {
            className = ESGCommonStyles.FOUR_COLUMN_TABLE.cssClass

            headers.forEach {
                TableHeader {
                    header = it
                }
            }


            listOf(
                CrossTablePlacement(
                    SURFACE_WATER,
                    listOf(
                        Pair(state::section3034Current1.name, state.section3034Current1),
                        Pair(state::section3034Current2.name, state.section3034Current2),
                        Pair(state::section3034Current13.name, state.section3034Current13),
                    )
                ),
                CrossTablePlacement(
                    GROUND_WATER,
                    listOf(
                        Pair(state::section3034Current3.name, state.section3034Current3),
                        Pair(state::section3034Current4.name, state.section3034Current4),
                        Pair(state::section3034Current14.name, state.section3034Current14),
                    )
                ),
                CrossTablePlacement(
                    SEA_WATER,
                    listOf(
                        Pair(state::section3034Current5.name, state.section3034Current5),
                        Pair(state::section3034Current6.name, state.section3034Current6),
                        Pair(state::section3034Current15.name, state.section3034Current15),
                    ),
                ),
                CrossTablePlacement(
                    PRODUCED_WATER,
                    listOf(
                        Pair(state::section3034Current7.name, state.section3034Current7),
                        Pair(state::section3034Current8.name, state.section3034Current8),
                        Pair(state::section3034Current16.name, state.section3034Current16),
                    ),
                ),
                CrossTablePlacement(
                    THIRD_PARTY_WATER,
                    listOf(
                        Pair(state::section3034Current9.name, state.section3034Current9),
                        Pair(state::section3034Current10.name, state.section3034Current10),
                        Pair(state::section3034Current17.name, state.section3034Current17),
                    ),
                ),
                CrossTablePlacement(
                    TOTAL_WATER,
                    listOf(
                        Pair(state::section3034Current11.name, state.section3034Current11),
                        Pair(state::section3034Current12.name, state.section3034Current12),
                        Pair(state::section3034Current18.name, state.section3034Current18),
                    )
                )
            ).forEach {
                ReactHTML.label {
                    +it.title
                }
                it.values.forEach { valuePair ->
                    ESGNumberFieldWrapper {
                        name = valuePair.first
                        value = valuePair.second
                        onChange = { event -> onChangeDecimalNumber(event, null, null) }
                        onBlur = { event -> onBlurDecimalNumber(event, null, null) }
                    }
                }
            }
        }

        Box {
            css {
                color = Color(Colors.HALF_GREEN)
                fontSize = 20.px
            }
            RomanListWrapper(
                title = "Total water discharge to all areas with water stress in megaliters, and a breakdown of " +
                    "this total by the following categories:",
                list = listOf(
                    "Freshwater (less than or equal to 1,000 mg/L Total Dissolved Solids);",
                    "Other water (greater then 1,000 mg/L Total Dissolved Solids)."
                )
            )
        }

        Box {
            className = ESGCommonStyles.FOUR_COLUMN_TABLE.cssClass

            headers.forEach {
                TableHeader {
                    header = it
                }
            }


            listOf(
                CrossTablePlacement(
                    SURFACE_WATER,
                    listOf(
                        Pair(state::section3034Current19.name, state.section3034Current19),
                        Pair(state::section3034Current20.name, state.section3034Current20),
                        Pair(state::section3034Current31.name, state.section3034Current31),
                    )
                ),
                CrossTablePlacement(
                    GROUND_WATER,
                    listOf(
                        Pair(state::section3034Current21.name, state.section3034Current21),
                        Pair(state::section3034Current22.name, state.section3034Current22),
                        Pair(state::section3034Current32.name, state.section3034Current32),
                    )
                ),
                CrossTablePlacement(
                    SEA_WATER,
                    listOf(
                        Pair(state::section3034Current23.name, state.section3034Current23),
                        Pair(state::section3034Current24.name, state.section3034Current24),
                        Pair(state::section3034Current33.name, state.section3034Current33),                    ),
                ),
                CrossTablePlacement(
                    PRODUCED_WATER,
                    listOf(
                        Pair(state::section3034Current25.name, state.section3034Current25),
                        Pair(state::section3034Current26.name, state.section3034Current26),
                        Pair(state::section3034Current34.name, state.section3034Current34),                     ),
                ),
                CrossTablePlacement(
                    THIRD_PARTY_WATER,
                    listOf(
                        Pair(state::section3034Current27.name, state.section3034Current27),
                        Pair(state::section3034Current28.name, state.section3034Current28),
                        Pair(state::section3034Current35.name, state.section3034Current35),                      ),
                ),
                CrossTablePlacement(
                    TOTAL_WATER,
                    listOf(
                        Pair(state::section3034Current29.name, state.section3034Current29),
                        Pair(state::section3034Current30.name, state.section3034Current30),
                        Pair(state::section3034Current36.name, state.section3034Current36),                      ),
                    )
            ).forEach {
                ReactHTML.label {
                    +it.title
                }
                it.values.forEach { valuePair ->
                    ESGNumberFieldWrapper {
                        name = valuePair.first
                        value = valuePair.second
                        onChange = { event -> onChangeDecimalNumber(event, null, null) }
                        onBlur = { event -> onBlurDecimalNumber(event, null, null) }
                    }
                }
            }
        }
        DisclosureSubHeading{
            subHeading = "Priority substances of concern for which discharges are treated, including:"
        }
        Box {
            ReactHTML.label {
                +("How priority substances of concern were defined, and any international standard, authoritative " +
                    "list, or criteria used?")
            }

            ESGTextAreaFieldWrapper {
                name = state::section3034Field1.name
                value = state.section3034Field1
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
                minimumRows = 4
            }
        }
        Box {
            ReactHTML.label {
                +("What is the approach for setting discharge limits for priority substances of concern?")
            }

            ESGTextAreaFieldWrapper {
                name = state::section3034Field2.name
                value = state.section3034Field2
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
                minimumRows = 4
            }
        }
        Box {
            ReactHTML.label {
                +("What's the number of incidents of non-compliance with discharge limits?")
            }

            ESGTextAreaFieldWrapper {
                name = state::section3034Field3.name
                value = state.section3034Field3
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
                minimumRows = 4
            }
        }
        Box {
            ReactHTML.label {
                +("Provide any contextual information necessary to understand how the " +
                    "data have been compiled,such as any standards, methodologies, and " +
                    "assumptions used.")
            }

            ESGTextAreaFieldWrapper {
                name = state::section3034Field4.name
                value = state.section3034Field4
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
                minimumRows = 4
            }
        }
    }
}