package com.ecosave.watch.portal.helpers.esg

enum class DisclosureOmissionReason(val omissionReason: String, val requiredExplanation: String) {
    NOT_APPLICABLE("Not applicable", "Explain why the disclosure or the requirement is considered not applicable."),
    LEGAL_PROHIBITIONS("Legal prohibitions", "Describe the specific legal prohibitions."),
    CONFIDENTIALITY_CONSTRAINTS("Confidentiality constraints", "Describe the specific confidentiality constraints."),
    INFORMATION_UNAVAILABLE(
        "Information unavailable/incomplete",
        "Specify which information is unavailable or incomplete. When the information is incomplete, specify which part is missing (e.g., specify the entities for which the information is missing). Explain why the required information is unavailable or incomplete. Describe the steps being taken and the expected time frame to obtain the information."
    );
}