package com.ecosave.watch.portal.components.resourcecenter.assetmanagement

import com.ecosave.watch.portal.helpers.resourcecenter.AssetManagementImages.AddAssetImage
import com.ecosave.watch.portal.helpers.resourcecenter.AssetManagementImages.ColumnActionsImage
import com.ecosave.watch.portal.helpers.resourcecenter.AssetManagementImages.OverviewImage
import com.ecosave.watch.portal.helpers.resourcecenter.AssetManagementImages.SelectionImage
import com.ecosave.watch.portal.helpers.resourcecenter.AssetManagementImages.ShowHideColumnsImage
import com.ecosave.watch.portal.helpers.resourcecenter.AssetManagementImages.ShowHideFiltersImage
import com.ecosave.watch.portal.helpers.resourcecenter.AssetManagementImages.ShowHideSearchImage
import com.ecosave.watch.portal.styles.ResourceCenterStyles
import mui.material.List
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.Box
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML
import web.cssom.px

external interface AssetManagementComponentProps : Props {
    var listPadding: Number
}

val AssetManagementComponent = FC<AssetManagementComponentProps> {

    List {
        sx {
            paddingLeft = it.listPadding.px
        }
        Box {
            id = "asset-management-overview"
            className = ResourceCenterStyles.RESOURCES_SUBSECTION.cssClass
            Typography {
                className = ResourceCenterStyles.RESOURCES_MAIN_TITLE.cssClass
                variant = TypographyVariant.h4
                component = ReactHTML.h4
                +"Asset Management Overview"
            }
            Typography {
                className = ResourceCenterStyles.RESOURCES_SENTENCE.cssClass
                variant = TypographyVariant.h6
                component = ReactHTML.h6
                +"This section provides a detailed and organized presentation of your facilities, conveniently arranged in an intuitive table format"
            }
            Typography {
                className = ResourceCenterStyles.RESOURCES_SENTENCE.cssClass
                variant = TypographyVariant.h6
                component = ReactHTML.h6
                +"Users have the ability to seamlessly add assets, conduct thorough searches across all data fields, integrate various filters, and execute sorting functions, among other advanced features."
            }
            ReactHTML.img {
                src = OverviewImage
                className = ResourceCenterStyles.RESOURCES_DISPLAY_OVERVIEW.cssClass
            }
        }
        Box {
            id = "select-asset-management"

            Typography {
                className = ResourceCenterStyles.RESOURCES_TITLE_UNDERLINED.cssClass
                variant = TypographyVariant.h4
                component = ReactHTML.h4
                +"Select Asset Management"
            }
            ReactHTML.img {
                src = SelectionImage
                className = ResourceCenterStyles.RESOURCES_DISPLAY_OVERVIEW_BOX.cssClass
            }
        }
        Box {
            id = "add-asset"

            Typography {
                className = ResourceCenterStyles.RESOURCES_TITLE_UNDERLINED.cssClass
                variant = TypographyVariant.h4
                component = ReactHTML.h4
                +"Add Asset"
            }
            ReactHTML.img {
                src = AddAssetImage
                className = ResourceCenterStyles.RESOURCES_DISPLAY_OVERVIEW_BOX.cssClass
            }
        }
        Box {
            Typography {
                className = ResourceCenterStyles.RESOURCES_TITLE_UNDERLINED.cssClass
                variant = TypographyVariant.h4
                component = ReactHTML.h4
                +"Column Actions"
            }
            id = "column-actions"
            ReactHTML.img {
                src = ColumnActionsImage
                className = ResourceCenterStyles.RESOURCES_DISPLAY_OVERVIEW_BOX.cssClass
            }
        }
        Box {
            Typography {
                className = ResourceCenterStyles.RESOURCES_TITLE_UNDERLINED.cssClass
                variant = TypographyVariant.h4
                component = ReactHTML.h4
                +"Show/Hide Search"
            }
            id = "show-hide-search"
            ReactHTML.img {
                src = ShowHideSearchImage
                className = ResourceCenterStyles.RESOURCES_DISPLAY_OVERVIEW_BOX.cssClass
            }
        }
        Box {
            Typography {
                className = ResourceCenterStyles.RESOURCES_TITLE_UNDERLINED.cssClass
                variant = TypographyVariant.h4
                component = ReactHTML.h4
                +"Show/Hide Filters"
            }
            id = "show-hide-filters"
            ReactHTML.img {
                src = ShowHideFiltersImage
                className = ResourceCenterStyles.RESOURCES_DISPLAY_OVERVIEW_BOX.cssClass
            }
        }
        Box {
            Typography {
                className = ResourceCenterStyles.RESOURCES_TITLE_UNDERLINED.cssClass
                variant = TypographyVariant.h4
                component = ReactHTML.h4
                +"Show/Hide Columns"
            }
            id = "show-hide-columns"
            ReactHTML.img {
                src = ShowHideColumnsImage
                className = ResourceCenterStyles.RESOURCES_DISPLAY_OVERVIEW_BOX.cssClass
            }
        }
    }
}