package com.ecosave.watch.portal.components.esg.freedomofassociationandcollectivebargaining

import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val GRI_407_1 = FC<EsgSubFormComponentProps> { props ->
    val onChangeText = props.onChangeText
    val onBlurText = props.onBlurText

    props.esgReportState.report?.freedomOfAssociationAndCollectiveBargaining?.let { state ->
        val workerRightsOfAssociationAndCollectiveBargainingListName =
            state::workerRightsOfAssociationAndCollectiveBargainingList.name

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_407_1
        }
        DisclosureSubHeading {
            subHeading =
                "Operations and suppliers in which workers’ rights to exercise freedom of association or collective bargaining may be violated or at significant risk either in terms of:"
        }

        Box {
            className = ESGCommonStyles.TABLE_WRAPPER.cssClass
            DynamicTableWrapper {
                addRowOrSection = {
                    props.addRowOrSection(workerRightsOfAssociationAndCollectiveBargainingListName)
                }
                items = state.workerRightsOfAssociationAndCollectiveBargainingList
                action = DynamicTableAction.ADD
                addRowOrSectionBtnText = ReportConstants.ADD_ROW_MESSAGE
                Box {
                    className = ESGCommonStyles.TWO_COLUMN_TABLE.cssClass
                    TableHeader {
                        header = "Type of operation and supplier"
                    }
                    TableHeader {
                        header = "Countries or geographic areas with operations and suppliers considered at risk"
                    }
                }
            }
            state.workerRightsOfAssociationAndCollectiveBargainingList.forEachIndexed { index, item ->
                DynamicTableWrapper {
                    key = item.key
                    deleteRowOrSection = {
                        props.deleteRowOrSection(workerRightsOfAssociationAndCollectiveBargainingListName, index)
                    }
                    items = state.workerRightsOfAssociationAndCollectiveBargainingList
                    action = DynamicTableAction.DELETE
                    variant = DynamicTableVariant.TABLE
                    Box {
                        className = ESGCommonStyles.TWO_COLUMN_TABLE.cssClass
                        ESGTextFieldWrapper {
                            name = item::section4071A1.name
                            value = item.section4071A1
                            onChange = { event ->
                                onChangeText(event, index, workerRightsOfAssociationAndCollectiveBargainingListName)
                            }
                            onBlur = { event ->
                                onBlurText(event, index, workerRightsOfAssociationAndCollectiveBargainingListName)
                            }
                        }
                        ESGTextFieldWrapper {
                            name = item::section4071A2.name
                            value = item.section4071A2
                            onChange = { event ->
                                onChangeText(event, index, workerRightsOfAssociationAndCollectiveBargainingListName)
                            }
                            onBlur = { event ->
                                onBlurText(event, index, workerRightsOfAssociationAndCollectiveBargainingListName)
                            }
                        }
                    }
                }
            }
        }

        Box {
            label {
                +"What are the measures taken by the organization in the reporting period intended to support rights to "
                +"exercise freedom of association and collective bargaining?"
            }
            ESGTextAreaFieldWrapper {
                name = state::section4071Field1.name
                value = state.section4071Field1
                onChange = { event -> props.onChangeTextArea(event, null, null) }
                onBlur = { event -> props.onBlurTextArea(event, null, null) }
            }
        }
    }
}