package com.ecosave.watch.portal.components.esg.economicperformance

import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureSubHeading
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import mui.system.sx
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange
import web.cssom.px

val GRI_201_2 = FC<EsgSubFormComponentProps> { props ->
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.economicPerformance?.let { state ->
        val financialRisksListName = state::financialRisksList.name

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_201_2
        }
        DisclosureSubHeading {
            subHeading =
                "Risks and opportunities posed by climate change that have the potential to generate substantive changes in operations, revenue, or expenditure, including:"
        }

        Box {
            className = ESGCommonStyles.STICKY_HEADER.cssClass
            DynamicTableWrapper {
                addRowOrSection = { props.addRowOrSection(financialRisksListName) }
                items = state.financialRisksList
                action = DynamicTableAction.ADD
                variant = DynamicTableVariant.TABLE
                addRowOrSectionBtnText = "Add a new risk"
                Box {
                    sx {
                        padding = 20.px
                    }
                    DisclosureSubHeading {
                        subHeading = "Risks"
                    }
                }
            }
        }

        state.financialRisksList.forEachIndexed { index, it ->
            DynamicTableWrapper {
                key = it.key
                deleteRowOrSection = {
                    props.deleteRowOrSection(financialRisksListName, index)
                }
                items = state.financialRisksList
                action = DynamicTableAction.DELETE
                Box {
                    className = ESGCommonStyles.DYNAMIC_TABLE_SECTION_LAYOUT.cssClass
                    DisclosureSubHeading {
                        subHeading = "Risk ${index + 1}"
                    }
                    Box {
                        label {
                            className = ESGCommonStyles.LABEL.cssClass
                            +"Provide a description of the risk and its classification as either physical, regulatory, or other."
                        }
                        ESGTextAreaFieldWrapper {
                            name = it::section2012RiskA1.name
                            value = it.section2012RiskA1
                            onChange = { event -> onChangeTextArea(event, index, financialRisksListName) }
                            onBlur = { event -> onBlurTextArea(event, index, financialRisksListName) }
                        }
                    }
                    Box {
                        label {
                            className = ESGCommonStyles.LABEL.cssClass
                            +"Provide a description of the impact associated with the risk."
                        }
                        ESGTextAreaFieldWrapper {
                            name = it::section2012RiskA2.name
                            value = it.section2012RiskA2
                            onChange = { event -> onChangeTextArea(event, index, financialRisksListName) }
                            onBlur = { event -> onBlurTextArea(event, index, financialRisksListName) }
                        }
                    }
                    Box {
                        label {
                            className = ESGCommonStyles.LABEL.cssClass
                            +"What were or are the financial implications of the risk before action is taken?"
                        }
                        ESGTextAreaFieldWrapper {
                            name = it::section2012RiskA3.name
                            value = it.section2012RiskA3
                            onChange = { event -> onChangeTextArea(event, index, financialRisksListName) }
                            onBlur = { event -> onBlurTextArea(event, index, financialRisksListName) }
                        }
                    }
                    Box {
                        label {
                            className = ESGCommonStyles.LABEL.cssClass
                            +"What are the methods used to manage the risk?"
                        }
                        ESGTextAreaFieldWrapper {
                            name = it::section2012RiskA4.name
                            value = it.section2012RiskA4
                            onChange = { event -> onChangeTextArea(event, index, financialRisksListName) }
                            onBlur = { event -> onBlurTextArea(event, index, financialRisksListName) }
                        }
                    }
                    Box {
                        label {
                            className = ESGCommonStyles.LABEL.cssClass
                            +"What are the costs of actions taken to manage the risk?"
                        }
                        ESGTextAreaFieldWrapper {
                            name = it::section2012RiskA5.name
                            value = it.section2012RiskA5
                            onChange = { event -> onChangeTextArea(event, index, financialRisksListName) }
                            onBlur = { event -> onBlurTextArea(event, index, financialRisksListName) }
                        }
                    }
                }
            }
        }

        Box {
            className = ESGCommonStyles.STICKY_HEADER.cssClass
            DynamicTableWrapper {
                addRowOrSection = {
                    props.addRowOrSection(state::financialOpportunitiesList.name)
                }
                items = state.financialOpportunitiesList
                action = DynamicTableAction.ADD
                variant = DynamicTableVariant.TABLE
                addRowOrSectionBtnText = "Add a new opportunity"
                Box {
                    sx {
                        padding = 20.px
                    }
                    DisclosureSubHeading {
                        subHeading = "Opportunities"
                    }
                }
            }
        }

        state.financialOpportunitiesList.forEachIndexed { index, it ->
            DynamicTableWrapper {
                key = it.key
                deleteRowOrSection = {
                    props.deleteRowOrSection(state::financialOpportunitiesList.name, index)
                }
                items = state.financialOpportunitiesList
                action = DynamicTableAction.DELETE
                Box {
                    className = ESGCommonStyles.DYNAMIC_TABLE_SECTION_LAYOUT.cssClass
                    DisclosureSubHeading {
                        subHeading = "Opportunity ${index + 1}"
                    }
                    Box {
                        label {
                            className = ESGCommonStyles.LABEL.cssClass
                            +"Provide a description of the opportunity and its classification as either physical, regulatory, or other."
                        }
                        ESGTextAreaFieldWrapper {
                            name = it::section2012OpportunityA1.name
                            value = it.section2012OpportunityA1
                            onChange =
                                { event -> onChangeTextArea(event, index, state::financialOpportunitiesList.name) }
                            onBlur = { event -> onBlurTextArea(event, index, state::financialOpportunitiesList.name) }
                        }
                    }
                    Box {
                        label {
                            className = ESGCommonStyles.LABEL.cssClass
                            +"Provide a description of the impact associated with the opportunity."
                        }
                        ESGTextAreaFieldWrapper {
                            name = it::section2012OpportunityA2.name
                            value = it.section2012OpportunityA2
                            onBlur = { event -> onBlurTextArea(event, index, state::financialOpportunitiesList.name) }
                            onChange =
                                { event -> onChangeTextArea(event, index, state::financialOpportunitiesList.name) }
                        }
                    }
                    Box {
                        label {
                            className = ESGCommonStyles.LABEL.cssClass
                            +"What were or are the financial implications of the opportunity before action is taken?"
                        }
                        ESGTextAreaFieldWrapper {
                            name = it::section2012OpportunityA3.name
                            value = it.section2012OpportunityA3
                            onBlur = { event -> onBlurTextArea(event, index, state::financialOpportunitiesList.name) }
                            onChange =
                                { event -> onChangeTextArea(event, index, state::financialOpportunitiesList.name) }
                        }
                    }
                    Box {
                        label {
                            className = ESGCommonStyles.LABEL.cssClass
                            +"What are the methods used to manage the opportunity?"
                        }
                        ESGTextAreaFieldWrapper {
                            name = it::section2012OpportunityA4.name
                            value = it.section2012OpportunityA4
                            onBlur = { event -> onBlurTextArea(event, index, state::financialOpportunitiesList.name) }
                            onChange =
                                { event -> onChangeTextArea(event, index, state::financialOpportunitiesList.name) }
                        }
                    }
                    Box {
                        label {
                            className = ESGCommonStyles.LABEL.cssClass
                            +"What are the costs of actions taken to manage the opportunity?"
                        }
                        ESGTextAreaFieldWrapper {
                            name = it::section2012OpportunityA5.name
                            value = it.section2012OpportunityA5
                            onBlur = { event -> onBlurTextArea(event, index, state::financialOpportunitiesList.name) }
                            onChange =
                                { event -> onChangeTextArea(event, index, state::financialOpportunitiesList.name) }
                        }
                    }
                }
            }
        }

        Box {
            label {
                className = ESGCommonStyles.LABEL.cssClass
                +"If the organization does not have a system in place to calculate the financial implications or costs, or to make revenue projections, report its plans and timeline to develop the necessary systems."
            }
            ESGTextAreaFieldWrapper {
                name = state::section2012Field1.name
                value = state.section2012Field1
                onBlur = { event -> onBlurTextArea(event, null, null) }
                onChange = { event -> onChangeTextArea(event, null, null) }
            }
        }
    }
}