package com.ecosave.watch.portal.pages

import com.ecosave.watch.portal.components.utilitybills.Header
import com.ecosave.watch.portal.components.utilitybills.UtilityBillSummaryTable
import com.ecosave.watch.portal.helpers.common.PageTitles
import com.ecosave.watch.portal.useGlobalState
import kotlinx.coroutines.launch
import mui.material.Box
import react.FC
import react.Props
import react.useEffectOnce
import react.useState

val UtilityBills = FC<Props> {

    var currentFacilityId by useState("All")
    val stateContextValue = useGlobalState()

    useEffectOnce {
        stateContextValue.updatePageTitle(PageTitles.UTILITY_BILLS.title)
        mainScope.launch {
            if (stateContextValue.facilityIdForUtilityBills != null) {
                currentFacilityId = stateContextValue.facilityIdForUtilityBills.toString()
                stateContextValue.updateFacilityForUtilityBills(null)
            }
        }
    }

    Box {
        Header {
            facilityId = currentFacilityId
            onSelectFacilityId = { id ->
                currentFacilityId = id
            }
        }
        UtilityBillSummaryTable {
            facilityId = currentFacilityId
        }
    }
}