package com.ecosave.watch.portal.pages

import com.ecosave.watch.portal.Themes
import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.components.npm.isEmail
import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.PageRoutes
import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.services.sendPasswordResetEmail
import com.ecosave.watch.portal.styles.RecoverPasswordStyles
import emotion.react.css
import io.ktor.http.*
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.FormControlVariant
import mui.material.Paper
import mui.material.Size
import mui.material.TextField
import mui.material.Typography
import mui.material.styles.ThemeProvider
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.dom.html.ReactHTML.h4
import react.dom.html.ReactHTML.img
import react.dom.onChange
import react.router.useNavigate
import react.useState
import web.cssom.Position
import web.cssom.TextDecoration
import web.cssom.pct
import web.cssom.px
import web.html.HTMLInputElement
import web.html.InputType
import com.ecosave.watch.portal.styles.CommonStyles as commonStyles

val RecoverPasswordPage = FC<Props> {

    var emailValidationState by useState(true)
    var email by useState("")
    var emailValidationMessage by useState("")
    var disableButtonInitially by useState(true)
    var isSubmitting by useState(false)
    val (notificationState, notificationStateSetter) = useState(NotificationState())
    var emailSuccessfullySent by useState(false)
    val navigate = useNavigate()

    Box {
        className = RecoverPasswordStyles.CONTAINER.cssClass
        ThemeProvider {
            theme = Themes.lightTheme
            Paper {
                className = RecoverPasswordStyles.CENTER.cssClass
                sx {
                    position = Position.relative
                    if (emailSuccessfullySent) {
                        minHeight = 55.pct
                    }
                }
                img {
                    css {
                        width = 100.pct
                    }
                    src = "/images/logo/company-logo-dark.jpg"
                }
                Box {
                    className = RecoverPasswordStyles.FORM_SECTION.cssClass
                    Typography {
                        sx {
                            marginTop = 15.px
                        }
                        className = commonStyles.HEADING_TITLE.cssClass
                        variant = TypographyVariant.h4
                        +"Recover Password!"
                    }
                    if (!emailSuccessfullySent) {
                        Typography {
                            className = commonStyles.SUBTITLE.cssClass
                            variant = TypographyVariant.h6
                            +"Enter your registered email address and we will send you a password reset link."
                        }
                        TextField {
                            type = InputType.email
                            label = ReactNode("Email Address *")
                            fullWidth = true
                            variant = FormControlVariant.outlined
                            value = email
                            error = !emailValidationState
                            helperText = ReactNode(emailValidationMessage)
                            onChange = {
                                val target = it.target as HTMLInputElement
                                disableButtonInitially = false
                                email = target.value
                                if (!isEmail(target.value)) {
                                    emailValidationState = false
                                    emailValidationMessage = "Please enter a valid email address."
                                } else {
                                    emailValidationState = true
                                    emailValidationMessage = ""
                                }
                            }
                        }
                        Button {
                            sx {
                                marginTop = 20.px
                            }
                            variant = ButtonVariant.contained
                            fullWidth = true
                            size = Size.large
                            disabled = !emailValidationState || disableButtonInitially || isSubmitting
                            if (isSubmitting) +"Sending password reset email..." else +"Send password reset email"
                            onClick = {
                                mainScope.launch {
                                    isSubmitting = true
                                    val httpStatus = sendPasswordResetEmail(email)
                                    if (httpStatus == null) {
                                        notificationStateSetter(
                                            notificationState.copy(
                                                status = NotificationStatus.ERROR,
                                                visible = true,
                                                message = Constants.NOTIFICATION_ERROR_MESSAGE
                                            )
                                        )
                                    } else if (httpStatus == HttpStatusCode.OK) {
                                        emailSuccessfullySent = true
                                    } else if (httpStatus == HttpStatusCode.NotFound || httpStatus == HttpStatusCode.Forbidden) {
                                        emailValidationState = false
                                        emailValidationMessage =
                                            "This address is either invalid or is not associated with a user account."
                                    }
                                    isSubmitting = false
                                }
                            }
                        }
                        Button {
                            sx {
                                textDecoration = TextDecoration.underline
                            }
                            size = Size.large
                            variant = ButtonVariant.text
                            onClick = {
                                navigate(PageRoutes.SIGN_IN.route)
                            }
                            +"Go To Sign In"
                        }
                    } else {
                        Typography {
                            className = commonStyles.SUBTITLE.cssClass
                            variant = TypographyVariant.h6
                            component = h4
                            sx {
                                marginLeft = 20.px
                            }
                            +"Check your email for a link to reset your password. If it doesn't appear within a few minutes, check your spam folder."
                        }
                        Button {
                            className = RecoverPasswordStyles.BUTTON.cssClass
                            variant = ButtonVariant.contained
                            fullWidth = true
                            size = Size.large
                            onClick = {
                                navigate(PageRoutes.SIGN_IN.route)
                            }
                            +"Return to sign in"
                        }
                    }
                }
            }
        }
    }
    AlertNotifications {
        open = notificationState.visible
        status = notificationState.status
        message = notificationState.message
        closeNotification = {
            notificationStateSetter(
                notificationState.copy(
                    visible = false
                )
            )
        }
    }
}