package com.ecosave.watch.portal.helpers

import com.ecosave.watch.portal.components.resourcecenter.ModuleContent
import js.core.jso
import com.ecosave.watch.portal.process
import kotlinx.coroutines.MainScope
import react.router.RouteObject

val BackendReportingServiceURL = process.env.BACKEND_ESG_SERVER_URL

val BackendServerURL = process.env.BACKEND_SERVER_URL

val StripeCustomerPortalUrl = process.env.STRIPE_CUSTOMER_PORTAL_URL

val mainScope = MainScope()

fun createMainContentRoute(routePath: String, component: react.ComponentType<*>) = jso<RouteObject> {
    path = routePath
    Component = component
}

fun createModuleContentRoute(pathSuffix: String) = jso<RouteObject> {
    path = "resource-center/$pathSuffix"
    Component = ModuleContent
}
