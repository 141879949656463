package com.ecosave.watch.portal.components.facilitymanagement

import com.ecosave.watch.portal.components.common.TableHeader
import com.ecosave.watch.portal.helpers.Colors
import com.ecosave.watch.portal.helpers.billing.BillingConstants
import com.ecosave.watch.portal.helpers.common.ColorConstants
import com.ecosave.watch.portal.helpers.common.xs
import com.ecosave.watch.portal.helpers.facilitymanagement.FacilityConstants
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.models.facilitymanagement.EnergyStarCandidateFacility
import com.ecosave.watch.portal.models.facilitymanagement.EnergyStarSelectedCandidateFacility
import com.ecosave.watch.portal.services.facilitymanagement.getFacilitiesToImport
import emotion.react.css
import kotlinx.coroutines.launch
import mui.icons.material.InfoOutlined
import mui.icons.material.Refresh
import mui.material.Alert
import mui.material.AlertColor
import mui.material.AlertTitle
import mui.material.AlertVariant
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Checkbox
import mui.material.Grid
import mui.material.Switch
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableCellAlign
import mui.material.TableContainer
import mui.material.TableHead
import mui.material.TableRow
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.responsive
import mui.system.sx
import react.ChildrenBuilder
import react.FC
import react.Props
import react.StateSetter
import react.create
import react.dom.html.ReactHTML
import react.useEffectOnce
import react.useState
import web.cssom.Color
import web.cssom.FontWeight
import web.cssom.Padding
import web.cssom.px

external interface ImportFacilitiesProps : Props {
    var notificationState: NotificationState
    var setNotificationState: StateSetter<NotificationState>
    var facilitiesToBeImported: MutableList<EnergyStarSelectedCandidateFacility>
    var setFacilitiesToBeImported: StateSetter<MutableList<EnergyStarSelectedCandidateFacility>>
}

val ImportFacilities = FC<ImportFacilitiesProps> { props ->

    var facilityList by useState(emptyList<EnergyStarCandidateFacility>())
    var isLoading by useState(false)
    var helperMessage by useState("")
    val facilitiesToBeImported = props.facilitiesToBeImported
    val setFacilitiesToBeImported = props.setFacilitiesToBeImported

    fun fetchFacilities() {
        mainScope.launch {
            isLoading = true
            val facilities = getFacilitiesToImport()
            if (facilities == null) {
                helperMessage = "There has been an issue fetching facilities, please hit refresh again."
                facilityList = emptyList()
            } else if (facilities.isEmpty()) {
                helperMessage = "No facilities available to import."
            } else {
                val updatedFacilities: MutableList<EnergyStarSelectedCandidateFacility> = mutableListOf()
                facilities.forEach { energyStarCandidateFacility ->
                    updatedFacilities.add(EnergyStarSelectedCandidateFacility(energyStarCandidateFacility.propertyId, true))
                }
                facilityList = facilities
                setFacilitiesToBeImported(updatedFacilities)
            }
            isLoading = false
        }
    }

    useEffectOnce {
        fetchFacilities()
    }

    Grid {
        sx {
            padding = Padding(0.px, 20.px, 20.px, 20.px)
        }
        container = true
        spacing = responsive(3)
        Grid {
            item = true
            xs = 12
            Typography {
                sx {
                    marginBottom = 20.px
                    fontWeight = FontWeight.bolder
                }
                variant = TypographyVariant.h6
                +"Share Access to your Facilities and Meters"
            }
            propertiesAndMetersAccessShareInstructions()
        }
        Grid {
            item = true
            xs = 12
            Button {
                variant = ButtonVariant.contained
                startIcon = startIcon.also {
                    Refresh()
                }
                onClick = {
                    facilityList = emptyList()
                    setFacilitiesToBeImported(mutableListOf())
                    fetchFacilities()
                }
                +"Refresh"
            }
        }
        Grid {
            item = true
            xs = 12
            TableContainer {
                Table {
                    stickyHeader = true
                    TableHead {
                        TableRow {
                            TableCell {
                                if (facilityList.isNotEmpty()) {
                                    Checkbox {
                                        checked = facilitiesToBeImported.size == facilityList.size
                                        onChange = { _, checked ->
                                            if (checked) {
                                                val updatedFacilities: MutableList<EnergyStarSelectedCandidateFacility> = mutableListOf()
                                                facilityList.forEach { energyStarCandidateFacility ->
                                                    updatedFacilities.add(
                                                        EnergyStarSelectedCandidateFacility(
                                                            energyStarCandidateFacility.propertyId,
                                                            true
                                                        )
                                                    )
                                                }
                                                setFacilitiesToBeImported(updatedFacilities)
                                            } else {
                                                setFacilitiesToBeImported(mutableListOf())
                                            }
                                        }
                                    }
                                }
                            }
                            TableHeader {
                                tableHeaderName = "Facility Name"
                            }
                            TableHeader {
                                tableHeaderName = "Facility Address"
                            }
                            TableHeader {
                                tableHeaderName = "Subscribe to ${BillingConstants.ENERGY_STAR}"
                            }

                        }
                    }

                    TableBody {
                        if (isLoading || facilityList.isEmpty()) {
                            TableCell {
                                align = TableCellAlign.center
                                colSpan = 4
                                if (isLoading) +"Fetching Facilities..." else +helperMessage
                            }
                        } else {
                            for (energyStarCandidateFacility in facilityList) {
                                TableRow {
                                    hover = true
                                    TableCell {
                                        Checkbox {
                                            checked = facilitiesToBeImported.any { it.propertyId == energyStarCandidateFacility.propertyId }
                                            onChange = { _, checked ->
                                                val updatedFacilities = if (checked) {
                                                    facilitiesToBeImported.toMutableList().apply {
                                                        add(EnergyStarSelectedCandidateFacility(energyStarCandidateFacility.propertyId, true))
                                                    }
                                                } else {
                                                    facilitiesToBeImported.filter { it.propertyId != energyStarCandidateFacility.propertyId }
                                                        .toMutableList()
                                                }
                                                setFacilitiesToBeImported(updatedFacilities)
                                            }

                                        }
                                    }
                                    TableCell {
                                        +energyStarCandidateFacility.facilityName
                                    }
                                    TableCell {
                                        +energyStarCandidateFacility.address
                                    }
                                    TableCell {
                                        Switch {
                                            disabled = !facilitiesToBeImported.any { it.propertyId == energyStarCandidateFacility.propertyId }
                                            checked =
                                                facilitiesToBeImported.find { it.propertyId == energyStarCandidateFacility.propertyId }?.energyStarSubscription
                                            onChange = { _, checked ->
                                                val updatedFacilities = facilitiesToBeImported.toMutableList()
                                                val index = updatedFacilities.indexOf(updatedFacilities.first {
                                                    it.propertyId == energyStarCandidateFacility.propertyId
                                                })
                                                updatedFacilities[index].energyStarSubscription = checked
                                                setFacilitiesToBeImported(updatedFacilities)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

fun ChildrenBuilder.propertiesAndMetersAccessShareInstructions() = Alert {
    sx {
        color = Color(Colors.WHITE)
        borderColor = Color(Colors.WHITE)
    }
    icon = InfoOutlined.create {
        sx {
            color = Color(Colors.WHITE)
        }
    }
    severity = AlertColor.info
    variant = AlertVariant.outlined
    AlertTitle {
        +"Follow the below steps to share access of your properties and meters with EcosaveWatch"
    }
    ReactHTML.ol {
        ReactHTML.li {
            +"Log in to your ${FacilityConstants.ENERGY_STAR_PORTFOLIO_MANAGER} account."
        }
        ReactHTML.li {
            +"Go to "
            ReactHTML.span {
                css {
                    color = Color(ColorConstants.HIGHLIGHTED_TEXT)
                }
                +"Sharing "
            }
            +"and select "
            ReactHTML.span {
                css {
                    color = Color(ColorConstants.HIGHLIGHTED_TEXT)
                }
                +"Share with your Utility or Service Provider for exchanging data."
            }
        }
        ReactHTML.li {
            +"From the drop-down menu "
            ReactHTML.span {
                css {
                    color = Color(ColorConstants.HIGHLIGHTED_TEXT)
                }
                +"Select Web Services Provider (Account), "
            }
            +"choose EcosaveWatch."
        }
        ReactHTML.li {
            +"Click "
            ReactHTML.span {
                css {
                    color = Color(ColorConstants.HIGHLIGHTED_TEXT)
                }
                +"Select Properties "
            }
            +"and choose all the properties you want to share with us."
        }
        ReactHTML.li {
            +"Under "
            ReactHTML.span {
                css {
                    color = Color(ColorConstants.HIGHLIGHTED_TEXT)
                }
                +"Choose Permissions, "
            }
            +"select "
            ReactHTML.span {
                css {
                    color = Color(ColorConstants.HIGHLIGHTED_TEXT)
                }
                +"Bulk Sharing "
            }
            +"and then "
            ReactHTML.span {
                css {
                    color = Color(ColorConstants.HIGHLIGHTED_TEXT)
                }
                +"Exchange Data Full Access."
            }
        }
        ReactHTML.li {
            +"Click "
            ReactHTML.span {
                css {
                    color = Color(ColorConstants.HIGHLIGHTED_TEXT)
                }
                +"Authorize Exchange "
            }
            +"to send requests for each of the properties and meters you selected to EcosaveWatch."
        }
        ReactHTML.li {
            +"Finally, click refresh to see your shared properties here."
        }
    }
}
