package com.ecosave.watch.portal.components.esg.biodiversity

import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.NumberFieldMapper
import com.ecosave.watch.portal.components.esg.formcontrols.TextAreaFieldMapper
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import mui.system.sx
import react.FC
import react.dom.html.ReactHTML
import react.dom.onChange
import web.cssom.px

val GRI_304_1 = FC<EsgSubFormComponentProps> { props ->
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea
    val onChangeNumber = props.onChangeNumber
    val onBlurNumber = props.onBlurNumber

    props.esgReportState.report?.biodiversity?.let { state ->
        val geoListName = state::biodiversityGeographicalLocationList.name
        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_304_1
        }

        Box {
            className = ESGCommonStyles.STICKY_HEADER.cssClass
            DynamicTableWrapper {
                addRowOrSection = {
                    props.addRowOrSection(geoListName)
                }
                items = state.biodiversityGeographicalLocationList
                action = DynamicTableAction.ADD
                variant = DynamicTableVariant.TABLE
                addRowOrSectionBtnText = "Add a new operational site details"
                Box {
                    sx {
                        padding = 20.px
                    }
                    DisclosureSubHeading {
                        subHeading = "For each operational site owned, leased, managed in, or adjacent to, protected " +
                            "areas and areas of high biodiversity value outside protected areas, the following " +
                            "information:"
                    }
                }
            }
        }

        state.biodiversityGeographicalLocationList.forEachIndexed { index, geoLocation ->
            DynamicTableWrapper {
                key = geoLocation.key
                deleteRowOrSection = { props.deleteRowOrSection(geoListName, index) }
                items = state.biodiversityGeographicalLocationList
                action = DynamicTableAction.DELETE
                variant = DynamicTableVariant.LIST
                Box {
                    className = ESGCommonStyles.DYNAMIC_TABLE_SECTION_LAYOUT.cssClass
                    DisclosureSubHeading {
                        subHeading = "Operation site  ${index + 1}"
                    }

                    listOf(
                        TextAreaFieldMapper(
                            "Geographical Location",
                            geoLocation::section3041A1.name,
                            geoLocation.section3041A1,
                        ),
                       TextAreaFieldMapper(
                            "Subsurface and underground land that may be owned, leased, or managed by the" +
                                "organization",
                            geoLocation::section3041A2.name,
                            geoLocation.section3041A2,
                        ),
                       TextAreaFieldMapper(
                            "Position in relation to the protected area (in the area, adjacent to, or containing " +
                                "portions of the protected area) or the high biodiversity value area outside " +
                                "protected areas.",
                            geoLocation::section3041A3.name,
                            geoLocation.section3041A3,
                        ),
                       TextAreaFieldMapper(
                            "Type of operation (office, manufacturing or production, or extractive).",
                            geoLocation::section3041A4.name,
                            geoLocation.section3041A4,
                        ),
                        NumberFieldMapper(
                            "Size of operational site in km2 (or another unit, if appropriate).",
                            geoLocation::section3041A5.name,
                            geoLocation.section3041A5,
                        ),
                       TextAreaFieldMapper(
                            "Biodiversity value characterized by the attribute of the protected area or area of " +
                                "high biodiversity value outside the protected area (terrestrial, freshwater, or " +
                                "maritime ecosystem).",
                            geoLocation::section3041A6.name,
                            geoLocation.section3041A6,
                        ),
                       TextAreaFieldMapper(
                            "Biodiversity value characterized by listing of protected status (such as IUCN " +
                                "Protected Area Management Categories, Ramsar Convention, national legislation).",
                            geoLocation::section3041A7.name,
                            geoLocation.section3041A7,
                        ),
                    ).forEach {
                        ReactHTML.label {
                            +it.label.mainHeader
                        }
                        when (it.kClass) {
                            String::class -> {
                                ESGTextAreaFieldWrapper {
                                    name = it.name
                                    value = it.value
                                    onChange = { event -> onChangeTextArea(event, index, geoListName) }
                                    onBlur = { event -> onBlurTextArea(event, index, geoListName) }
                                    minimumRows = 2
                                }
                            }

                            Long::class -> {
                                ESGNumberFieldWrapper {
                                    name = it.name
                                    value = it.value
                                    onChange = { event -> onChangeNumber(event, index, geoListName) }
                                    onBlur = { event -> onBlurNumber(event, index, geoListName) }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
