package com.ecosave.watch.portal.services.controlcenter

import com.ecosave.watch.portal.helpers.BackendServerURL
import com.ecosave.watch.portal.models.controlcenter.ActionDataSet
import com.ecosave.watch.portal.models.controlcenter.EnergyKPIMetric
import com.ecosave.watch.portal.models.controlcenter.PieData
import com.ecosave.watch.portal.services.ApiResponse
import com.ecosave.watch.portal.services.httpClient
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*

suspend fun fetchEnergyData(facilityId: Number): ApiResponse<EnergyKPIMetric> {
    try {
        val response: HttpResponse = httpClient.get("$BackendServerURL/kpi-data-stream/property-metrics?facilityIds=${facilityId}")
        if (response.status == HttpStatusCode.OK) {
            return ApiResponse(fetched = true, isError = false, data = response.body())
        }
    } catch (e: dynamic) {
        console.log("Error message from server: ", e.message)
    }
    return ApiResponse(fetched = true, isError = true, data = EnergyKPIMetric())
}

suspend fun fetchActionData(facilityId: Number): ApiResponse<ActionDataSet> {
    try {
        val response: HttpResponse = httpClient.get("$BackendServerURL/kpi-data-stream/generated-action?facilityId=$facilityId")
        if (response.status == HttpStatusCode.OK) {
            return ApiResponse(fetched = true, isError = false, data = response.body())
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return ApiResponse(fetched = true, isError = true, data = ActionDataSet())
}

suspend fun fetchComposedData(facilityId: Number): ApiResponse<dynamic> {
    try {
        val response: HttpResponse = httpClient.get("$BackendServerURL/kpi-data-stream?facilityIds=${facilityId}")
        if (response.status.equals(HttpStatusCode.OK)) {
            return ApiResponse(fetched = true, isError = false, data = JSON.parse(response.bodyAsText()))
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return ApiResponse(fetched = true, isError = true, data = "")
}

suspend fun fetchPieData(facilityId: Number): ApiResponse<List<PieData>> {
    try {
        val response: HttpResponse = httpClient.get("$BackendServerURL/kpi-data-stream/pie-chart?facilityIds=${facilityId}")
        if (response.status.equals(HttpStatusCode.OK)) {
            return ApiResponse(fetched = true, isError = false, data = response.body())
        }
    } catch (e: dynamic) {
        console.log("Error message from server: ", e.message)
    }
    return ApiResponse(fetched = true, isError = true, data = emptyList())
}
