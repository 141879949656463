package com.ecosave.watch.portal.components.facilitygroupmanagement

import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.components.common.ConfirmationDialog
import com.ecosave.watch.portal.components.common.DialogSpinner
import com.ecosave.watch.portal.components.common.DialogWrapper
import com.ecosave.watch.portal.components.common.Loading
import com.ecosave.watch.portal.components.common.PaperWrapper
import com.ecosave.watch.portal.components.npm.MaterialReactTable
import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.FormMode
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.xs
import com.ecosave.watch.portal.helpers.facilitygroupmanagement.GroupManagementConstants
import com.ecosave.watch.portal.helpers.facilitygroupmanagement.addChildGroupToSelectedGroup
import com.ecosave.watch.portal.helpers.facilitygroupmanagement.createFacilitiesObjectDataArray
import com.ecosave.watch.portal.helpers.facilitygroupmanagement.editSelectedGroup
import com.ecosave.watch.portal.helpers.facilitygroupmanagement.facilitiesColumns
import com.ecosave.watch.portal.helpers.facilitygroupmanagement.isChildGroupValid
import com.ecosave.watch.portal.helpers.facilitygroupmanagement.removeGroupFromMainGroup
import com.ecosave.watch.portal.helpers.facilitygroupmanagement.validateChildGroupFacilities
import com.ecosave.watch.portal.helpers.facilitygroupmanagement.validateChildGroupName
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.models.facilitygroupmanagement.ChildGroupStateDataClass
import com.ecosave.watch.portal.models.facilitygroupmanagement.ChildGroupValidationStateDataClass
import com.ecosave.watch.portal.models.facilitygroupmanagement.FacilityGroup
import com.ecosave.watch.portal.models.facilitygroupmanagement.FacilitySummary
import com.ecosave.watch.portal.services.facilitygroupmanagement.createChildGroup
import com.ecosave.watch.portal.services.facilitygroupmanagement.deleteGroup
import com.ecosave.watch.portal.services.facilitygroupmanagement.editGroup
import js.core.jso
import kotlinx.coroutines.launch
import mui.icons.material.Add
import mui.icons.material.Delete
import mui.icons.material.Edit
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.DialogActions
import mui.material.DialogContent
import mui.material.FormControlVariant
import mui.material.Grid
import mui.material.IconButton
import mui.material.Size
import mui.material.TextField
import mui.material.Tooltip
import mui.material.Typography
import mui.material.TypographyAlign
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.StateSetter
import react.create
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.div
import react.dom.onChange
import react.useEffect
import react.useState
import web.cssom.Color
import web.cssom.FontWeight
import web.cssom.JustifyContent
import web.cssom.Margin
import web.cssom.Padding
import web.cssom.pct
import web.cssom.px
import web.html.HTMLInputElement
import web.html.InputType

external interface GroupActionsComponentProps : Props {
    var selectedGroupProp: FacilityGroup?
    var group: FacilityGroup
    var groupStateSetter: StateSetter<FacilityGroup>
    var selectedGroupIdStateSetterProps: StateSetter<Int>
    var isLoadingProp: Boolean
    var parentFacilities: List<FacilitySummary>
}

val GroupActionsComponent = FC<GroupActionsComponentProps> { props ->

    val selectedGroup = props.selectedGroupProp
    val parentFacilitiesList = props.parentFacilities
    val mainGroup = props.group
    val mainGroupStateSetter = props.groupStateSetter
    var isSubmitting by useState(false)
    var openAddOrEditGroupForm by useState(false)
    var selectedGroupFacilities by useState(emptyArray<dynamic>())
    var parentGroupFacilities by useState(emptyArray<dynamic>())
    val (childGroupState, childGroupStateSetter) = useState(ChildGroupStateDataClass())
    val (childGroupStateValidation, childGroupValidationStateSetter) = useState(ChildGroupValidationStateDataClass())
    var formMode by useState(FormMode.ADD)
    var showDeleteConfirmationDialog by useState(false)
    var spinnerMessage by useState("")
    val (notificationState, notificationStateSetter) = useState(NotificationState())
    var isDeleting by useState(false)
    val (checkedFacilities, checkedFacilitiesStateSetter) = useState<dynamic>(jso())
    var facilitiesErrorStateTrigger by useState(false)

    useEffect(selectedGroup?.facilities) {
        selectedGroup?.facilities?.let {
            selectedGroupFacilities = createFacilitiesObjectDataArray(it)
        }
    }

    useEffect(parentFacilitiesList) {
        parentGroupFacilities = createFacilitiesObjectDataArray(parentFacilitiesList.toMutableList())
    }

    useEffect(checkedFacilities) {
        val selectedFacilitiesList = mutableListOf<Int>()
        for (facilityId in js("Object").keys(checkedFacilities)) {
            selectedFacilitiesList.add(facilityId.toString().toInt())
        }
        childGroupStateSetter(
            childGroupState.copy(
                childGroupFacilitiesList = selectedFacilitiesList
            )
        )
        if (facilitiesErrorStateTrigger) {
            validateChildGroupFacilities(childGroupStateValidation, childGroupValidationStateSetter, selectedFacilitiesList)
        }
        facilitiesErrorStateTrigger = true
    }

    PaperWrapper {
        sx {
            height = 100.pct
        }
        Grid {
            sx {
                padding = Padding(20.px, 40.px)
            }
            container = true
            if (props.isLoadingProp) {
                Box {
                    sx {
                        width = 100.pct
                        margin = Margin(50.px, 0.px)
                    }
                    Loading()
                }
            } else {
                Grid {
                    item = true
                    xs = 12
                    sx {
                        marginBottom = 20.px
                    }
                    Typography {
                        sx {
                            fontWeight = FontWeight.bold
                        }
                        variant = TypographyVariant.h5
                        align = TypographyAlign.center
                        +"Selected Group: ${selectedGroup?.facilityGroupName}"
                    }
                }

                Grid {
                    item = true
                    xs = 12
                    MaterialReactTable {
                        columns = facilitiesColumns
                        data = selectedGroupFacilities
                        muiTopToolbarProps = GroupManagementConstants.muiTableToolbarColor
                        muiBottomToolbarProps = GroupManagementConstants.muiTableToolbarColor
                        muiTableBodyCellProps = GroupManagementConstants.muiTableCellColor
                        muiTableHeadCellProps = GroupManagementConstants.headCellProps
                        muiTablePaperProps = jso {
                            elevation = 0
                        }
                        renderTopToolbarCustomActions = { _ ->
                            div.create {
                                Tooltip {
                                    title = ReactNode("Add Child Group")
                                    IconButton {
                                        onClick = {
                                            formMode = FormMode.ADD
                                            selectedGroup?.facilityGroupId?.let { it1 ->
                                                childGroupStateSetter(
                                                    childGroupState.copy(
                                                        parentGroupId = it1
                                                    )
                                                )
                                            }

                                            openAddOrEditGroupForm = true
                                        }
                                        Add()
                                    }
                                }
                                if (selectedGroup?.facilityGroupId != mainGroup.facilityGroupId) {
                                    Tooltip {
                                        title = ReactNode("Edit Group")
                                        IconButton {
                                            onClick = {
                                                formMode = FormMode.EDIT


                                                val selectedFacilitiesObject = js("{}")

                                                selectedGroup?.facilities?.forEach {
                                                    val propertyName = "${it.facilityId}"
                                                    selectedFacilitiesObject[propertyName] = "true"
                                                }

                                                checkedFacilitiesStateSetter(selectedFacilitiesObject)

                                                selectedGroup?.facilityGroupName?.let { it1 ->
                                                    childGroupStateSetter(
                                                        childGroupState.copy(
                                                            childGroupName = it1,
                                                            childGroupFacilitiesList = selectedFacilitiesObject
                                                        )
                                                    )
                                                }
                                                openAddOrEditGroupForm = true
                                            }
                                            Edit()
                                        }
                                    }
                                    Tooltip {
                                        title = ReactNode("Delete Group")
                                        IconButton {
                                            onClick = {
                                                showDeleteConfirmationDialog = true
                                            }
                                            Delete()
                                        }
                                    }
                                }
                            }
                        }
                        state = jso {
                            density = "compact"
                        }
                        enableDensityToggle = false
                        enableHiding = false
                        globalFilterFn = "contains"
                    }
                }
            }
        }
    }
    DialogWrapper {
        dialogMaxWidth = 80
        dialogMaxWidthIsPercent = true
        open = openAddOrEditGroupForm
        dialogTitle = if (formMode == FormMode.ADD) "Add Group" else "Edit Group"
        DialogContent {
            TextField {
                sx {
                    marginTop = 20.px
                    marginBottom = 20.px
                }
                variant = FormControlVariant.outlined
                size = Size.medium
                fullWidth = true
                label = ReactNode("Group Name *")
                type = InputType.text
                value = childGroupState.childGroupName
                error = childGroupStateValidation.childGroupNameErrorState
                helperText = childGroupStateValidation.childGroupNameErrorMessage
                onChange = {
                    val target = it.target as HTMLInputElement
                    childGroupStateSetter(
                        childGroupState.copy(
                            childGroupName = target.value
                        )
                    )
                }
                onBlur = {
                    validateChildGroupName(childGroupState, childGroupStateValidation, childGroupValidationStateSetter)
                }
            }

            Typography {
                if (childGroupStateValidation.childGroupFacilitiesErrorState) {
                    sx {
                        color = Color("Red")
                    }
                }
                align = TypographyAlign.center
                component = ReactHTML.h6
                variant = TypographyVariant.h6
                if (childGroupStateValidation.childGroupFacilitiesErrorState) +childGroupStateValidation.childGroupFacilitiesErrorMessage else +"Select Facilities"
            }

            MaterialReactTable {
                columns = facilitiesColumns
                data = if (formMode == FormMode.ADD) selectedGroupFacilities else parentGroupFacilities
                enableRowSelection = true
                getRowId = { row ->
                    row.facilityId
                }
                state = jso {
                    rowSelection = checkedFacilities
                    density = "compact"
                }
                onRowSelectionChange = checkedFacilitiesStateSetter
                enableDensityToggle = false
                muiTopToolbarProps = GroupManagementConstants.muiTableToolbarColor
                muiBottomToolbarProps = GroupManagementConstants.muiTableToolbarColor
                muiTableBodyCellProps = GroupManagementConstants.muiTableCellColor
                muiTableHeadCellProps = GroupManagementConstants.headCellProps
                muiTablePaperProps = jso {
                    elevation = 0
                }
                enableHiding = false
                globalFilterFn = "contains"
            }
        }
        DialogActions {
            sx {
                justifyContent = JustifyContent.center
            }
            Button {
                onClick = {
                    childGroupStateSetter(ChildGroupStateDataClass())
                    childGroupValidationStateSetter(ChildGroupValidationStateDataClass())
                    facilitiesErrorStateTrigger = false
                    checkedFacilitiesStateSetter(jso())
                    openAddOrEditGroupForm = false
                }
                +"Cancel"
            }
            Button {
                variant = ButtonVariant.contained
                onClick = {
                    if (isChildGroupValid(childGroupState, childGroupStateValidation, childGroupValidationStateSetter)) {
                        mainScope.launch {

                            isSubmitting = true

                            if (formMode == FormMode.ADD) {
                                val newGroup = createChildGroup(childGroupState)

                                if (newGroup != null) {

                                    val result = selectedGroup?.facilityGroupId?.let { it1 -> addChildGroupToSelectedGroup(mainGroup, it1, newGroup) }

                                    if (result != null) {
                                        mainGroupStateSetter(
                                            mainGroup.copy(
                                                childGroups = mainGroup.childGroups
                                            )
                                        )

                                        notificationStateSetter(
                                            notificationState.copy(
                                                status = NotificationStatus.SUCCESS,
                                                message = "Group Created Successfully.",
                                                visible = true
                                            )
                                        )

                                        childGroupStateSetter(ChildGroupStateDataClass())
                                        childGroupValidationStateSetter(ChildGroupValidationStateDataClass())
                                        facilitiesErrorStateTrigger = false
                                        checkedFacilitiesStateSetter(jso())
                                        openAddOrEditGroupForm = false
                                    }

                                } else {
                                    notificationStateSetter(
                                        notificationState.copy(
                                            status = NotificationStatus.ERROR,
                                            message = Constants.NOTIFICATION_ERROR_MESSAGE,
                                            visible = true
                                        )
                                    )
                                }
                            } else {

                                val editedGroup =
                                    selectedGroup?.let { it1 ->
                                        editGroup(
                                            it1.facilityGroupId,
                                            childGroupState
                                        )
                                    }

                                if (editedGroup != null) {

                                    val result = editSelectedGroup(
                                        mainGroup,
                                        editedGroup.facilityGroupId,
                                        editedGroup.facilityGroupName,
                                        editedGroup.facilities
                                    )

                                    if (result != null) {

                                        mainGroupStateSetter(
                                            mainGroup.copy(
                                                childGroups = mainGroup.childGroups
                                            )
                                        )

                                        notificationStateSetter(
                                            notificationState.copy(
                                                status = NotificationStatus.SUCCESS,
                                                message = "Group Updated Successfully.",
                                                visible = true
                                            )
                                        )
                                        childGroupStateSetter(ChildGroupStateDataClass())
                                        childGroupValidationStateSetter(ChildGroupValidationStateDataClass())
                                        facilitiesErrorStateTrigger = false
                                        checkedFacilitiesStateSetter(jso())
                                        openAddOrEditGroupForm = false
                                    }


                                } else {
                                    notificationStateSetter(
                                        notificationState.copy(
                                            status = NotificationStatus.ERROR,
                                            message = Constants.NOTIFICATION_ERROR_MESSAGE,
                                            visible = true
                                        )
                                    )
                                }
                            }
                            isSubmitting = false
                        }
                    }
                }
                +if (isSubmitting) "Saving Group..." else "Save Group"
                disabled = isSubmitting
            }
        }

    }
    ConfirmationDialog {
        open = showDeleteConfirmationDialog
        title = "Delete Group"
        body = "Deleting this group will permanently delete all associated child groups as well, are you sure you want to delete?"
        actionResult = { value ->
            if (value) {
                mainScope.launch {
                    spinnerMessage = "Deleting Group..."
                    isDeleting = true
                    val apiCallStatus = selectedGroup?.facilityGroupId?.let { deleteGroup(it) }
                    if (apiCallStatus == ApiCallStatus.SUCCESS) {

                        val result = removeGroupFromMainGroup(mainGroup, selectedGroup.facilityGroupId)
                        if (result != null) {
                            mainGroupStateSetter(
                                mainGroup.copy(
                                    childGroups = result.childGroups
                                )
                            )
                            props.selectedGroupIdStateSetterProps(mainGroup.facilityGroupId)
                            notificationStateSetter(
                                notificationState.copy(
                                    status = NotificationStatus.SUCCESS,
                                    message = "Group Deleted Successfully.",
                                    visible = true
                                )
                            )
                        }

                    } else {
                        notificationStateSetter(
                            notificationState.copy(
                                status = NotificationStatus.ERROR,
                                message = Constants.NOTIFICATION_ERROR_MESSAGE,
                                visible = true
                            )
                        )
                    }
                    isDeleting = false
                    spinnerMessage = ""
                }

            }
            showDeleteConfirmationDialog = false
        }
    }
    DialogSpinner {
        open = isDeleting
        message = spinnerMessage
    }
    AlertNotifications {
        open = notificationState.visible
        status = notificationState.status
        message = notificationState.message
        closeNotification = {
            notificationStateSetter(
                notificationState.copy(
                    visible = false
                )
            )
        }
    }
}
