package com.ecosave.watch.portal.helpers.userprofile

import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.models.userprofile.UserProfileState
import com.ecosave.watch.portal.models.userprofile.UserProfileValidationState
import react.ReactNode
import react.StateSetter

fun validateFirstName(
    state: UserProfileState,
    validation: UserProfileValidationState,
    setValidation: StateSetter<UserProfileValidationState>
) {
    if (state.firstName.isEmpty()) {
        validation.firstNameErrorState = true
        validation.firstNameErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.firstNameErrorState = false
        validation.firstNameErrorMessage = null
    }

    updateUserProfileValidationState(validation, setValidation)
}

fun validateLastName(
    state: UserProfileState,
    validation: UserProfileValidationState,
    setValidation: StateSetter<UserProfileValidationState>
) {
    if (state.lastName.isEmpty()) {
        validation.lastNameErrorState = true
        validation.lastNameErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.lastNameErrorState = false
        validation.lastNameErrorMessage = null
    }

    updateUserProfileValidationState(validation, setValidation)
}

fun validateJobTitle(
    state: UserProfileState,
    validation: UserProfileValidationState,
    setValidation: StateSetter<UserProfileValidationState>
) {
    if (state.jobTitle.isEmpty()) {
        validation.jobTitleErrorState = true
        validation.jobTitleErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.jobTitleErrorState = false
        validation.jobTitleErrorMessage = null
    }

    updateUserProfileValidationState(validation, setValidation)
}

fun updateUserProfileValidationState(
    validation: UserProfileValidationState,
    setValidation: StateSetter<UserProfileValidationState>
) {
    setValidation(validation.copy())
}

fun isUserProfileFormValid(
    state: UserProfileState,
    validation: UserProfileValidationState,
    setValidation: StateSetter<UserProfileValidationState>
): Boolean {
    validateFirstName(state, validation, setValidation)
    validateLastName(state, validation, setValidation)
    validateJobTitle(state, validation, setValidation)

    return !(validation.firstNameErrorState || validation.lastNameErrorState || validation.jobTitleErrorState)
}