package com.ecosave.watch.portal.components.privacypolicy

import react.FC
import react.Props

val UKResidentsPrivacyPolicy = FC<Props> {
    HeadingWrapper {
        heading = "For those of you in the UK, please see our Notice to UK Residents:"
    }
    HeadingWrapper {
        heading = "Notice to UK Residents"
        subHeading = true
    }
    HeadingWrapper {
        heading = "Cross-border data transfers"
        subHeading = true
    }
    TypographyWrapper {
        +"Ecosave complies with the Data Privacy Framework as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of Personal Information transferred from the United Kingdom (“UK”) to the U.S. Ecosave has certified to the U.S. Department of Commerce that it adheres to the Data Privacy Framework."
    }
    TypographyWrapper {
        +"If there is any conflict between the terms of the Privacy Statement and the DPF, the DPF shall govern. To learn more about the Data Privacy Framework, and to view our certification, visit the Data Privacy Framework website."
    }
    HeadingWrapper {
        heading = "Dispute resolution process"
        subHeading = true
    }
    TypographyWrapper {
        +"For residents in which GDPR applies, in the unlikely event that a dispute arises between you and Ecosave regarding our handling of your Personal Information, we will do our best to resolve the matter with you."
    }
    TypographyWrapper {
        +"As Ecosave is an American company, we are subject to the jurisdiction of the U.S. Federal Trade Commission (FTC)."
    }
}
