package com.ecosave.watch.portal.components.utilityaccounts

import com.ecosave.watch.portal.components.common.MultipleAutoComplete
import com.ecosave.watch.portal.components.common.PopoverWrapper
import com.ecosave.watch.portal.helpers.common.AutoCompleteOption
import com.ecosave.watch.portal.models.billing.UtilityAccountFiltersDataClass
import com.ecosave.watch.portal.models.common.PopoverPos
import com.ecosave.watch.portal.styles.CommonStyles
import mui.icons.material.FilterList
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Typography
import mui.system.sx
import react.FC
import react.Props
import react.StateSetter
import react.dom.aria.ariaDescribedBy
import react.useEffect
import react.useState
import web.cssom.Display
import web.cssom.JustifyContent
import web.cssom.Position
import web.cssom.px
import web.html.HTMLButtonElement

external interface UtilityAccountSummaryFilterComponentProps : Props {
    var currentFilterOptions: UtilityAccountFiltersDataClass
    var currentFilterOptionsStateSetter: StateSetter<UtilityAccountFiltersDataClass>
    var facilityFilterOptions: Array<AutoCompleteOption>
    var streetFilterOptions: Array<AutoCompleteOption>
    var zipcodeFilterOptions: Array<AutoCompleteOption>
    var stateFilterOptions: Array<AutoCompleteOption>
    var cityFilterOptions: Array<AutoCompleteOption>
    var filtersCount: Int
}

val UtilityAccountSummaryFilterComponent = FC<UtilityAccountSummaryFilterComponentProps> { props ->

    val currentFilterOptions = props.currentFilterOptions
    val (filterState, filterStateSetter) = useState(currentFilterOptions)
    var bAnchorEl: HTMLButtonElement? by useState(null)
    val isOpen = bAnchorEl != null

    useEffect(currentFilterOptions) {
        filterStateSetter(currentFilterOptions)
    }

    Box {
        Box {
            sx {
                display = Display.flex
                justifyContent = JustifyContent.end
            }
            Button {
                sx {
                    position = Position.relative
                }
                variant = ButtonVariant.outlined
                endIcon = endIcon.also {
                    FilterList()
                }
                onClick = {
                    bAnchorEl = it.currentTarget
                }
                ariaDescribedBy = "filter-popover"
                +"Filter"
                if (props.filtersCount > 0) {
                    Box {
                        className = CommonStyles.BADGE.cssClass
                        +"${props.filtersCount}"
                    }
                }

            }
        }
        PopoverWrapper {
            id = "filter-popover"
            open = isOpen
            anchorEl = bAnchorEl
            onClose = { _, _ ->
                bAnchorEl = null
            }
            anchorOrigin = PopoverPos("bottom", "right")
            transformOrigin = PopoverPos("top", "right")
            Box {
                sx {
                    padding = 30.px
                    width = 450.px
                }
                Box {
                    sx {
                        marginBottom = 20.px
                    }
                    Typography {
                        +"Facility"
                    }
                    MultipleAutoComplete {
                        name = "facility"
                        options = props.facilityFilterOptions
                        value = filterState.facility
                        onSelect = {
                            filterStateSetter(
                                filterState.copy(
                                    facility = it
                                )
                            )
                        }
                    }
                }
                Box {
                    sx {
                        marginBottom = 20.px
                    }
                    Typography {
                        +"Street"
                    }
                    MultipleAutoComplete {
                        name = "street"
                        options = props.streetFilterOptions
                        value = filterState.street
                        onSelect = {
                            filterStateSetter(
                                filterState.copy(
                                    street = it
                                )
                            )
                        }
                    }
                }
                Box {
                    sx {
                        marginBottom = 20.px
                    }
                    Typography {
                        +"City"
                    }
                    MultipleAutoComplete {
                        name = "city"
                        options = props.cityFilterOptions
                        value = filterState.city
                        onSelect = {
                            filterStateSetter(
                                filterState.copy(
                                    city = it
                                )
                            )
                        }
                    }
                }
                Box {
                    sx {
                        marginBottom = 20.px
                    }
                    Typography {
                        +"State"
                    }
                    MultipleAutoComplete {
                        name = "state"
                        options = props.stateFilterOptions
                        value = filterState.state
                        onSelect = {
                            filterStateSetter(
                                filterState.copy(
                                    state = it
                                )
                            )
                        }
                    }
                }
                Box {
                    sx {
                        marginBottom = 20.px
                    }
                    Typography {
                        +"Zipcode"
                    }
                    MultipleAutoComplete {
                        name = "zipcode"
                        options = props.zipcodeFilterOptions
                        value = filterState.zipcode
                        onSelect = {
                            filterStateSetter(
                                filterState.copy(
                                    zipcode = it
                                )
                            )
                        }
                    }
                }
                Box {
                    sx {
                        display = Display.flex
                        justifyContent = JustifyContent.end
                        marginTop = 10.px
                    }
                    Button {
                        sx {
                            marginRight = 20.px
                        }
                        variant = ButtonVariant.text
                        onClick = {
                            props.currentFilterOptionsStateSetter(UtilityAccountFiltersDataClass())
                        }
                        +"Clear All"
                    }
                    Button {
                        variant = ButtonVariant.contained
                        onClick = {
                            bAnchorEl = null
                            props.currentFilterOptionsStateSetter(filterState)
                        }
                        +"Search"
                    }
                }
            }
        }
    }
}
