package com.ecosave.watch.portal.components.workorder

import com.ecosave.watch.portal.styles.CommonStyles
import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.components.common.Loading
import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.helpers.workorder.WorkOrderConstants
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.workorder.WorkForce
import com.ecosave.watch.portal.services.workorder.getWorkForce
import kotlinx.coroutines.launch
import mui.icons.material.InfoOutlined
import mui.material.Box
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableCellAlign
import mui.material.TableContainer
import mui.material.TableHead
import mui.material.TableRow
import mui.material.Tooltip
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.useEffectOnce
import react.useState
import web.cssom.AlignItems
import web.cssom.Color
import web.cssom.Display
import web.cssom.px


val WorkForceTableComponent = FC<Props> {

    var isLoading by useState(false)
    val (notificationState, setNotificationState) = useState(NotificationState())
    var workForceList by useState<MutableList<WorkForce>>(mutableListOf())

    useEffectOnce {
        mainScope.launch {
            isLoading = true
            workForceList = getWorkForce()
            isLoading = false
        }
    }

    TableContainer {
        sx {
            marginTop = 30.px
        }
        Table {
            TableHead {
                TableRow {
                    for (header in WorkOrderConstants.workForceTableHeaderList) {
                        TableCell {
                            className = CommonStyles.BOLD_TEXT.cssClass
                            +header
                        }
                    }
                }
            }
            TableBody {
                if (isLoading) {
                    TableRow {
                        TableCell {
                            colSpan = 3
                            className = CommonStyles.REMOVED_CELL_BOTTOM_BORDER.cssClass
                            align = TableCellAlign.center
                            Box {
                                sx {
                                    height = 200.px
                                }
                                Loading()
                            }
                        }
                    }
                } else {
                    if (workForceList.isEmpty()) {
                        TableRow {
                            hover = true
                            TableCell {
                                colSpan = 3
                                align = TableCellAlign.center
                                +"There has been an issue fetching the workforce."
                            }
                        }
                    } else {
                        for (user in workForceList) {
                            TableRow {
                                hover = true
                                role = react.dom.aria.AriaRole.checkbox
                                TableCell {
                                    Box {
                                        sx {
                                            display = Display.flex
                                            gap = 10.px
                                            alignItems = AlignItems.center
                                        }
                                        +user.username
                                        Tooltip {
                                            title = ReactNode(user.emailAddress)
                                            InfoOutlined {
                                                sx {
                                                    fontSize = 20.px
                                                }
                                            }
                                        }
                                    }
                                }

                                TableCell {
                                    +user.actionAssigned.toString()
                                }
                                TableCell {
                                    +user.actionInProgress.toString()
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    AlertNotifications {
        open = notificationState.visible
        status = notificationState.status
        message = notificationState.message
        closeNotification = {
            setNotificationState(
                notificationState.copy(
                    visible = false
                )
            )
        }
    }
}