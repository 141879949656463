package com.ecosave.watch.portal.components.utilitybills

import com.ecosave.watch.portal.components.common.MultipleAutoComplete
import com.ecosave.watch.portal.components.common.PopoverWrapper
import com.ecosave.watch.portal.helpers.billing.BillingConstants
import com.ecosave.watch.portal.helpers.common.AutoCompleteOption
import com.ecosave.watch.portal.models.billing.UtilityBillSummaryTableFilter
import com.ecosave.watch.portal.models.common.PopoverPos
import com.ecosave.watch.portal.styles.CommonStyles
import mui.icons.material.FilterList
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.FormControlVariant
import mui.material.Size
import mui.material.TextField
import mui.material.Typography
import mui.system.sx
import react.FC
import react.Props
import react.StateSetter
import react.dom.aria.ariaDescribedBy
import react.dom.onChange
import react.useEffect
import react.useState
import web.cssom.Display
import web.cssom.JustifyContent
import web.cssom.Position
import web.cssom.px
import web.html.HTMLButtonElement
import web.html.HTMLInputElement
import web.html.InputType

external interface TableFilterProps : Props {
    var currentFilterOptions: UtilityBillSummaryTableFilter
    var setCurrentFilterOptions: StateSetter<UtilityBillSummaryTableFilter>
    var facilityId: String
    var filterCount: Int
    var currentServiceNameOptions: Array<AutoCompleteOption>
    var currentAccountNumberOptions: Array<AutoCompleteOption>
}

val TableFilter = FC<TableFilterProps> { props ->
    val (formState, setFormState) = useState(props.currentFilterOptions)
    var bAnchorEl: HTMLButtonElement? by useState(null)
    val isOpen = bAnchorEl != null

    useEffect(props.currentFilterOptions) {
        setFormState(props.currentFilterOptions)
    }

    Box {
        Box {
            sx {
                display = Display.flex
                justifyContent = JustifyContent.end
            }
            Button {
                sx {
                    position = Position.relative
                    marginBottom = 20.px
                }
                variant = ButtonVariant.outlined
                endIcon = endIcon.also {
                    FilterList()
                }
                onClick = {
                    bAnchorEl = it.currentTarget
                }
                ariaDescribedBy = "filter-popover"
                +"Filter"
                if (props.filterCount > 0) {
                    Box {
                        className = CommonStyles.BADGE.cssClass
                        +"${props.filterCount}"
                    }
                }
            }
        }
        PopoverWrapper {
            id = "filter-popover"
            open = isOpen
            anchorEl = bAnchorEl
            onClose = { _, _ ->
                bAnchorEl = null
            }
            anchorOrigin = PopoverPos("bottom", "right")
            transformOrigin = PopoverPos("top", "right")
            Box {
                sx {
                    padding = 30.px
                    width = 450.px
                }
                Box {
                    sx {
                        marginBottom = 20.px
                    }
                    Typography {
                        +"Utility Type"
                    }
                    MultipleAutoComplete {
                        name = "utilityType"
                        options = BillingConstants.utilityTypeOptions
                        value = formState.utilityType
                        onSelect = {
                            setFormState(
                                formState.copy(
                                    utilityType = it
                                )
                            )
                        }
                    }
                }
                Box {
                    sx {
                        marginBottom = 20.px
                    }
                    Typography {
                        +"Service Name"
                    }
                    MultipleAutoComplete {
                        name = "serviceName"
                        options = props.currentServiceNameOptions
                        value = formState.serviceName
                        onSelect = {
                            setFormState(
                                formState.copy(
                                    serviceName = it
                                )
                            )
                        }
                    }
                }
                Box {
                    sx {
                        marginBottom = 20.px
                    }
                    Typography {
                        +"Account Number"
                    }
                    MultipleAutoComplete {
                        name = "accountNumber"
                        options = props.currentAccountNumberOptions
                        value = formState.accountNumber
                        onSelect = {
                            setFormState(
                                formState.copy(
                                    accountNumber = it
                                )
                            )
                        }
                    }
                }
                Box {
                    sx {
                        marginBottom = 20.px
                    }
                    Typography {
                        +"Bill Duration"
                    }
                    Box {
                        sx {
                            display = Display.flex
                        }
                        TextField {
                            sx {
                                marginRight = 4.px
                            }
                            id = "billStartDate"
                            variant = FormControlVariant.outlined
                            fullWidth = true
                            placeholder = "Start"
                            type = InputType.date
                            value = formState.billStartDate
                            onChange = {
                                val target = it.target as HTMLInputElement
                                setFormState(
                                    formState.copy(
                                        billStartDate = target.value
                                    )
                                )
                            }
                            size = Size.small
                        }
                        TextField {
                            sx {
                                marginLeft = 4.px
                            }
                            id = "billEndDate"
                            variant = FormControlVariant.outlined
                            placeholder = "End"
                            fullWidth = true
                            type = InputType.date
                            value = formState.billEndDate
                            onChange = {
                                val target = it.target as HTMLInputElement
                                setFormState(
                                    formState.copy(
                                        billEndDate = target.value
                                    )
                                )
                            }
                            size = Size.small
                        }
                    }
                }
                Box {
                    sx {
                        display = Display.flex
                        justifyContent = JustifyContent.end
                        marginTop = 10.px
                    }
                    Button {
                        sx {
                            marginRight = 20.px
                        }
                        variant = ButtonVariant.text
                        onClick = {
                            props.setCurrentFilterOptions(UtilityBillSummaryTableFilter())
                        }
                        +"Clear All"
                    }
                    Button {
                        variant = ButtonVariant.contained
                        onClick = {
                            bAnchorEl = null
                            props.setCurrentFilterOptions(formState)
                        }
                        +"Search"
                    }
                }
            }
        }
    }
}
