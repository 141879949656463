package com.ecosave.watch.portal.pages

import com.ecosave.watch.portal.styles.CommonStyles
import com.ecosave.watch.portal.components.navigation.NavigationDrawer
import com.ecosave.watch.portal.helpers.aboutus.AboutUsStatements
import com.ecosave.watch.portal.helpers.common.PageTitles
import com.ecosave.watch.portal.useGlobalState
import emotion.react.css
import mui.material.Box
import mui.material.Card
import mui.material.CardContent
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h4
import react.dom.html.ReactHTML.img
import react.useEffectOnce
import web.cssom.pct
import web.cssom.px
import com.ecosave.watch.portal.styles.AboutUsPageStyles as styles

val AboutUs = FC<Props> {
    val globalState = useGlobalState()

    useEffectOnce {
        globalState.updatePageTitle(PageTitles.ABOUT_US.title)
    }

    NavigationDrawer {}
    Box {
        className = CommonStyles.FLEX_FILLED.cssClass
        sx {
            padding = 40.px
        }
        Box {
            className = styles.MAIN_BOX.cssClass
            Box {
                className = CommonStyles.LOGO_BOX.cssClass
                img {
                    css {
                        width = 40.px
                        height = 40.px
                        marginRight = 10.px
                    }
                    src = "/images/logo/company-circle-logo.png"
                }
                Typography {
                    component = h4
                    variant = TypographyVariant.h4
                    +"About Us"
                }
            }
            Box {
                className = styles.MAIN_TITLE.cssClass
                Typography {
                    variant = TypographyVariant.h4
                    component = h4
                    +AboutUsStatements.MISSION_STATEMENT
                }
            }
            Box {
                className = styles.CARD_SECTOR.cssClass
                Card {
                    sx {
                        width = 45.pct
                        height = 45.pct
                    }
                    CardContent {
                        Typography {
                            variant = TypographyVariant.h5
                            component = div
                            +AboutUsStatements.WHAT_PROBLEM
                        }
                        Typography {
                            variant = TypographyVariant.body1
                            +AboutUsStatements.DATA_WITHOUT_ACTION
                        }
                    }
                }
                Card {
                    sx {
                        width = 45.pct
                        height = 45.pct
                    }
                    CardContent {
                        Typography {
                            variant = TypographyVariant.h5
                            component = div
                            +AboutUsStatements.OUR_JOURNEY
                        }
                        Typography {
                            variant = TypographyVariant.body1
                            +AboutUsStatements.BOOTSTRAPPED
                        }
                    }
                }
                Card {
                    sx {
                        width = 45.pct
                        height = 45.pct
                    }
                    CardContent {
                        Typography {
                            variant = TypographyVariant.h5
                            component = div
                            +AboutUsStatements.WHAT_MISSION
                        }
                        Typography {
                            variant = TypographyVariant.body1
                            +AboutUsStatements.ENERGY_DECISIONS
                        }
                    }
                }
                Card {
                    sx {
                        width = 45.pct
                        height = 45.pct
                    }
                    CardContent {
                        Typography {
                            variant = TypographyVariant.h5
                            component = div
                            +AboutUsStatements.MILESTONES
                        }
                        Typography {
                            variant = TypographyVariant.body1
                            +AboutUsStatements.MARKET_GROWTH
                        }
                    }
                }
            }
        }
    }
}
