package com.ecosave.watch.portal.components

import com.ecosave.watch.portal.helpers.common.PageRoutes
import com.ecosave.watch.portal.useGlobalState
import react.FC
import react.Props
import react.router.Navigate
import react.router.Outlet

val ProtectedPage = FC<Props> {

    val stateContext = useGlobalState()

    if (stateContext.isAuthenticated) {
        Outlet {}
    } else {
        Navigate {
            to = PageRoutes.SIGN_IN.route
            replace = true
        }
    }
}
