package com.ecosave.watch.portal.components.esg.materials

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val GRI_301_3 = FC<EsgSubFormComponentProps> { props ->
    val onChangeDecimalNumber = props.onChangeDecimalNumber
    val onBlurDecimalNumber = props.onBlurDecimalNumber
    val onChangeText = props.onChangeText
    val onBlurText = props.onBlurText
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.materials?.let { state ->
        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_301_3
        }

        Box {
            className = ESGCommonStyles.THREE_COLUMN_TABLE_8_2_2.cssClass
            TableHeader {
                header = ""
            }
            TableHeader {
                header = "Unit"
            }
            TableHeader {
                header = "Value"
            }

            label {
                +"Total products and their packaging materials reclaimed"
            }
            ESGTextFieldWrapper {
                name = state::section3013Unit1.name
                value = state.section3013Unit1
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section3013Current1.name
                value = state.section3013Current1
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Total products sold"
            }
            ESGTextFieldWrapper {
                name = state::section3013Unit2.name
                value = state.section3013Unit2
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section3013Current2.name
                value = state.section3013Current2
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }
        }

        Box {
            label {
                +"How the data for this disclosure have been collected?"
            }
            ESGTextAreaFieldWrapper {
                name = state::section3013Field1.name
                value = state.section3013Field1
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}