package com.ecosave.watch.portal.helpers.common

import web.cssom.px

object ScreenBreakPoints {
    val extraSmall = 0.px
    val small = 600.px
    val medium = 900.px
    val large = 1200.px
    val extraLarge = 1536.px
    val laptop = 1400.px
    val tablet = 930
    val mobile = 500
}

