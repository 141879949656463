package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class LaborOrManagementRelationsState(
    var section4021A1: Long? = null,
    var section4021A2: String? = null,

    var section3_3_402A1: String? = null,
    var section3_3_402A2: String? = null,
    var section3_3_402A3: String? = null,
    var section3_3_402A4: String? = null,
    var section3_3_402A5: String? = null,
    var section3_3_402A6: String? = null
)
