package com.ecosave.watch.portal.models.facilitymanagement

import kotlinx.serialization.Serializable

@Serializable
data class ZipCodeInfo(
    val zip: String? = null,
    val latitude: Float? = null,
    val longitude: Float? = null,
    val city: String? = null,
    val state: String? = null,
    val country: String? = null
)
