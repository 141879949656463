package com.ecosave.watch.portal.components.privacypolicy

import react.FC
import react.Props

val  EcosaveUseOfCustomerData = FC<Props> {
    HeadingWrapper {
        heading = "16. Ecosave’s Use of Customer Data; Authorization of Access to Customer Utility Data"
    }
    TypographyWrapper {
        +"You hereby grant Ecosave a worldwide non-exclusive paid-up license to use your Customer Data pursuant to this Agreement and Ecosave’s Privacy Policy to enhance the Service."
    }
    TypographyWrapper {
        +"To the extent that you select the Service feature of submitting your utility data for processing, you hereby grant Ecosave all applicable rights under applicable laws to access your utility data without reservation for the duration of the delivery of the Service or until such time that you opt of this Service feature. "
    }
}
