package com.ecosave.watch.portal.components.esg.generaldisclosures.governance

import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextFieldWrapper
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import mui.system.sx
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange
import web.cssom.px

val Disclosure_2_9 = FC<EsgSubFormComponentProps> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea
    val onChangeText = props.onChangeText
    val onBlurText = props.onBlurText
    val onChangeNumber = props.onChangeNumber
    val onBlurNumber = props.onBlurNumber

    props.esgReportState.report?.governance?.let { state ->

        val section29ListName = state::section2_9List.name

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_2_9
        }

        Box {
            label {
                +"Describe the governance structure, including committees of the highest governance body."
            }
            ESGTextAreaFieldWrapper {
                name = state::section29Field1.name
                value = state.section29Field1 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"List the committees of the highest governance body that are responsible for decision- making on and overseeing the management of the organization’s impacts on the economy, environment, and people."
            }
            ESGTextAreaFieldWrapper {
                name = state::section29Field2.name
                value = state.section29Field2 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            className = ESGCommonStyles.STICKY_HEADER.cssClass
            DynamicTableWrapper {
                addRowOrSection = {
                    props.addRowOrSection(section29ListName)
                }
                items = state.section2_9List
                action = DynamicTableAction.ADD
                variant = DynamicTableVariant.TABLE
                addRowOrSectionBtnText = "Add a new committee"
                Box {
                    sx {
                        padding = 20.px
                    }
                    DisclosureSubHeading {
                        subHeading = "Describe the composition of the highest governance body and its committees"
                    }
                }
            }
        }

        state.section2_9List.forEachIndexed { index, it ->
            DynamicTableWrapper {
                key = it.key
                deleteRowOrSection = {
                    props.deleteRowOrSection(section29ListName, index)
                }
                items = state.section2_9List
                action = DynamicTableAction.DELETE
                Box {
                    className = ESGCommonStyles.DYNAMIC_TABLE_SECTION_LAYOUT.cssClass
                    DisclosureSubHeading {
                        subHeading = "Committee ${index + 1}"
                    }

                    label {
                        +"Committee"
                    }
                    ESGTextFieldWrapper {
                        name = it::section29A1.name
                        value = it.section29A1 ?: ""
                        onChange = { event -> onChangeText(event, index, section29ListName) }
                        onBlur = { event -> onBlurText(event, index, section29ListName) }
                    }

                    label {
                        +"Number of executive members."
                    }
                    ESGNumberFieldWrapper {
                        name = it::section29A2.name
                        value = it.section29A2 ?: ""
                        onChange = { event -> onChangeNumber(event, index, section29ListName) }
                        onBlur = { event -> onBlurNumber(event, index, section29ListName) }
                    }

                    label {
                        +"Number of non executive members."
                    }
                    ESGNumberFieldWrapper {
                        name = it::section29A3.name
                        value = it.section29A3 ?: ""
                        onChange = { event -> onChangeNumber(event, index, section29ListName) }
                        onBlur = { event -> onBlurNumber(event, index, section29ListName) }
                    }

                    label {
                        +"Is independent?"
                    }
                    ESGTextFieldWrapper {
                        name = it::section29A4.name
                        value = it.section29A4 ?: ""
                        onChange = { event -> onChangeText(event, index, section29ListName) }
                        onBlur = { event -> onBlurText(event, index, section29ListName) }
                    }

                    label {
                        +"Tenure of members on the governance body."
                    }
                    ESGTextFieldWrapper {
                        name = it::section29A5.name
                        value = it.section29A5 ?: ""
                        onChange = { event -> onChangeText(event, index, section29ListName) }
                        onBlur = { event -> onBlurText(event, index, section29ListName) }
                    }

                    label {
                        +"Number of other significant positions and commitments held by each member, and"
                        +"the nature of the commitments."
                    }
                    ESGTextAreaFieldWrapper {
                        name = it::section29A6.name
                        value = it.section29A6 ?: ""
                        onChange = { event -> onChangeTextArea(event, index, section29ListName) }
                        onBlur = { event -> onBlurTextArea(event, index, section29ListName) }
                    }

                    label {
                        +"Gender"
                    }
                    ESGTextFieldWrapper {
                        name = it::section29A7.name
                        value = it.section29A7 ?: ""
                        onChange = { event -> onChangeText(event, index, section29ListName) }
                        onBlur = { event -> onBlurText(event, index, section29ListName) }
                    }

                    label {
                        +"Under-represented social groups."
                    }
                    ESGTextAreaFieldWrapper {
                        name = it::section29A8.name
                        value = it.section29A8 ?: ""
                        onChange = { event -> onChangeTextArea(event, index, section29ListName) }
                        onBlur = { event -> onBlurTextArea(event, index, section29ListName) }
                    }

                    label {
                        +"Competencies relevant to the impacts of the organization."
                    }
                    ESGTextAreaFieldWrapper {
                        name = it::section29A9.name
                        value = it.section29A9 ?: ""
                        onChange = { event -> onChangeTextArea(event, index, section29ListName) }
                        onBlur = { event -> onBlurTextArea(event, index, section29ListName) }
                    }

                    label {
                        +"Stakeholder representation"
                    }
                    ESGTextAreaFieldWrapper {
                        name = it::section29A10.name
                        value = it.section29A10 ?: ""
                        onChange = { event -> onChangeTextArea(event, index, section29ListName) }
                        onBlur = { event -> onBlurTextArea(event, index, section29ListName) }
                    }
                }
            }
        }
    }
}