package com.ecosave.watch.portal.components.privacypolicy

import react.FC
import react.Props
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.ul

val CustomerData = FC<Props> {
    HeadingWrapper {
        heading = "11. Customer Data"
    }
    HeadingWrapper {
        heading = "11.1. Ownership of Data"
        subHeading = true
    }
    TypographyWrapper {
        +"You retain ownership, right, and responsibility to all text, software, audio, video, images, or other content that you and/or any Authorized User run on or through the Service (collectively \"Customer Data\"), subject only to the limited rights expressly granted in this Agreement and intellectual property rights. You are solely responsible, and Ecosave assumes no liability for the Customer Data that Authorized Users or other third parties post, send, or otherwise make available over or through the Service."
    }
    HeadingWrapper {
        heading = "11.2. License Grant to Ecosave"
        subHeading = true
    }
    TypographyWrapper {
        +"In order to provide and support the Service for your benefit, you hereby grant Ecosave a worldwide, non-exclusive, royalty-free license during the Service Period to use, reproduce, electronically distribute, transmit, have transmitted, perform, display, store, and archive Customer Data. You agree that, so long as no Confidential Information is publicly disclosed, Ecosave may:"
    }
    TypographyWrapper {
        ul {
            li {
                +"Use Customer Data to refine, supplement, or test Ecosave's product and Service offerings."
            }
            li {
                +"Include aggregated and anonymized data in any publicly available reports, analyses, and promotional materials."
            }
            li {
                +"Retain anonymized, non-attributable data following any termination of this Agreement for use in connection with the above."
            }
            li {
                +"List you as a Customer and use your name and logo on the Website, on publicly available customer lists, and in media releases during the Service Period."
            }
        }
    }
}
