package com.ecosave.watch.portal.helpers.assetmanagement

enum class AssetSystemType(val description: String) {
    WATER("Water / Aquatic"),
    LIGHTING("Lighting"),
    HVAC("HVAC"),
    COOLING("Cooling"),
    HEATING("Heating"),
    VENTILATION("Ventilation"),
    ONSITE_GENERATION("Onsite Generation"),
    KITCHEN_EQUIPMENT("Kitchen Equipment"),
    POWER_EFFICIENCY("Power Efficiency / Quality"),
    AIR_QUALITY("Air Quality"),
    OTHER("Other")
}