package com.ecosave.watch.portal.components.energymanagement

import js.core.jso
import mui.material.*
import com.ecosave.watch.portal.components.npm.CalendarPicker
import com.ecosave.watch.portal.components.npm.CalendarPickerProps
import moment.Moment
import moment.moment
import mui.icons.material.CalendarMonthOutlined
import mui.material.styles.TypographyVariant
import muix.pickers.CalendarPickerView
import mui.system.sx
import react.*
import react.dom.aria.ariaDescribedBy
import react.dom.aria.ariaReadOnly
import react.dom.html.ReactHTML
import web.cssom.Display
import web.cssom.JustifyContent
import web.cssom.px
import web.html.HTMLButtonElement

external interface SecondDurationPickerProps: Props {
    var id: String
    var value: String?
    var duration: Int?
    var start: String?
    var submit: (start: String, end: String) -> Unit
}

val SecondDuration = FC<SecondDurationPickerProps> {props ->
    var startMonth by useState<Moment?>(null)
    var endMonth by useState<Moment?>(null)
    var startView by useState(CalendarPickerView.year)

    var bAnchorEl: HTMLButtonElement? by useState(null)
    val isOpen = bAnchorEl != null

    val startLabel = startMonth?.format("MM/YYYY") ?: "Start"
    val endLabel = endMonth?.format("MM/YYYY") ?: "End"

    useEffect(props.duration, startMonth) {
        if (props.duration != null && startMonth != null) {
            console.log(props.duration, startMonth)
            endMonth = moment(startMonth).subtract(props.duration, "months")
        }
    }

    useEffect(props.start) {
        if (props.start != null) {
            startMonth = moment(props.start)
        }
    }

    TextField {
        sx {
            width = 260.px
            marginRight = 30.px
        }
        id = props.id
        value = props.value
        placeholder = "MM/YYYY - MM/YYYY"
        ariaReadOnly = true
        disabled = props.duration == null

        asDynamic().InputProps = jso<InputBaseProps> {
            endAdornment = InputAdornment.create {
                position = InputAdornmentPosition.end
                IconButton {
                    ariaDescribedBy="${props.id}-popover"
                    disabled = props.duration == null
                    onClick = {
                        bAnchorEl = it.currentTarget
                    }
                    CalendarMonthOutlined()
                }
            }
        }
    }
    Popover {
        id = "${props.id}-popover"
        open = isOpen
        anchorEl = bAnchorEl
        onClose = { _, _ ->
            bAnchorEl = null
        }
        Box {
            sx {
                padding = 20.px
            }
            Typography {
                +"SELECT MONTH RANGE"
            }
            Typography {
                sx {
                    marginTop = 10.px
                }
                variant = TypographyVariant.h6
                component = ReactHTML.h6
                +"$startLabel - $endLabel"
            }
            Box {
                sx {
                    display = Display.flex
                }
                @Suppress("UPPER_BOUND_VIOLATED")
                (CalendarPicker<CalendarPickerProps<String>> {
                    view = startView
                    views = arrayOf(CalendarPickerView.year, CalendarPickerView.month)
                    date = startMonth
                    disableFuture = true
                    onViewChange = {
                        startView = it
                    }
                    onYearChange = {
                        startView = CalendarPickerView.month
                    }
                    onChange = {date, _ ->
                        startMonth = moment(date)
                    }
                })
            }
            Box {
                sx {
                    display = Display.flex
                    justifyContent = JustifyContent.flexEnd
                }
                Button {
                    sx {
                        marginTop = 10.px
                    }
                    variant = ButtonVariant.contained
                    disabled = startMonth == null || endMonth == null
                    onClick = {
                        startMonth?.let { it1 -> endMonth?.let { it2 -> props.submit(it1.format(), it2.format()) } }
                        bAnchorEl = null
                    }
                    +"Done"
                }
            }
        }
    }
}