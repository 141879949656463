package com.ecosave.watch.portal.components.esg.emissions

import com.ecosave.watch.portal.components.esg.EsgCoreComponentProps
import com.ecosave.watch.portal.components.esg.GenericSection_3_3
import com.ecosave.watch.portal.components.esg.formcontrols.GRIStandardName
import com.ecosave.watch.portal.helpers.common.isValidDecimalNumber
import com.ecosave.watch.portal.helpers.esg.EsgSection
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.omitDisclosureFromUI
import com.ecosave.watch.portal.models.esg.BiodiversityState
import com.ecosave.watch.portal.models.esg.CurrentInputState
import com.ecosave.watch.portal.models.esg.EmissionsState
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import kotlinx.serialization.json.JsonPrimitive
import mui.material.Box
import react.FC
import react.dom.events.FocusEvent
import react.dom.events.FormEvent
import web.html.HTMLDivElement
import web.html.HTMLElement
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement

val EmissionsComponent = FC<EsgCoreComponentProps> { props ->
    val reportState = props.reportState
    val setReportState = props.setReportState
    val griStandardName = EsgSection.EMISSIONS

    reportState.report?.emissions?.let { state ->

        fun stateSetter(state: EmissionsState) {
            setReportState(
                reportState.copy(
                    report = reportState.report.copy(
                        emissions = state
                    )
                )
            )
        }

        val onChangeTextArea: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value

            val newState = state.copy()

            newState.let {
                when (propertyName) {
                    it::section3_3_305A1.name -> it.section3_3_305A1 = propertyValue
                    it::section3_3_305A2.name -> it.section3_3_305A2 = propertyValue
                    it::section3_3_305A3.name -> it.section3_3_305A3 = propertyValue
                    it::section3_3_305A4.name -> it.section3_3_305A4 = propertyValue
                    it::section3_3_305A5.name -> it.section3_3_305A5 = propertyValue
                    it::section3_3_305A6.name -> it.section3_3_305A6 = propertyValue

                    it::section3051Current2.name -> it.section3051Current2 = propertyValue

                    it::section3051Field1.name -> it.section3051Field1 = propertyValue
                    it::section3051Field2.name -> it.section3051Field2 = propertyValue
                    it::section3051Field3.name -> it.section3051Field3 = propertyValue
                    it::section3051Field4.name -> it.section3051Field4 = propertyValue

                    it::section3052Field1.name -> it.section3052Field1 = propertyValue
                    it::section3052Field2.name -> it.section3052Field2 = propertyValue
                    it::section3052Field3.name -> it.section3052Field3 = propertyValue
                    it::section3053Field4.name -> it.section3053Field4 = propertyValue
                }

            }

            stateSetter(newState)

            props.setInputState(
                CurrentInputState(
                    parentPath = field,
                    childPath = propertyName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue),
                    objectName = griStandardName.name,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurTextArea: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value

            props.onBlurApiCall(
                propertyName,
                JsonPrimitive(propertyValue),
                changeIndex,
                field,
                griStandardName.name
            )
        }

        val onChangeDecimalNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement

            if (isValidDecimalNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toDoubleOrNull()

                val newState = state.copy()

                newState.let {
                    when (propertyName) {
                        it::section3051Current1.name -> it.section3051Current1 = propertyValue
                        it::section3051Current3.name -> it.section3051Current3 = propertyValue

                        it::section3052Current1.name -> it.section3052Current1 = propertyValue
                        it::section3052Current2.name -> it.section3052Current2 = propertyValue
                    }
                }

                stateSetter(newState)

                props.setInputState(
                    CurrentInputState(
                        parentPath = field,
                        childPath = propertyName,
                        index = changeIndex,
                        value = JsonPrimitive(propertyValue),
                        objectName = griStandardName.name,
                        reportName = reportState.reportFileName
                    )
                )
            }
        }

        val onBlurDecimalNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidDecimalNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toDoubleOrNull()
                props.onBlurApiCall(
                    propertyName,
                    JsonPrimitive(propertyValue),
                    changeIndex,
                    field,
                    griStandardName.name
                )
            }
        }


        Box {
            className = ESGCommonStyles.SECTION_MAIN_LAYOUT.cssClass
            GRIStandardName {
                griStandard = griStandardName
            }

            GenericSection_3_3 {
                this.onBlurTextArea = onBlurTextArea
                this.onChangeTextArea = onChangeTextArea
                this.materialTopicName = griStandardName.description

                this.section3_3_A1_name = state::section3_3_305A1.name
                this.section3_3_A1_value = state.section3_3_305A1
                this.section3_3_A2_name = state::section3_3_305A2.name
                this.section3_3_A2_value = state.section3_3_305A2
                this.section3_3_A3_name = state::section3_3_305A3.name
                this.section3_3_A3_value = state.section3_3_305A3
                this.section3_3_A4_name = state::section3_3_305A4.name
                this.section3_3_A4_value = state.section3_3_305A4
                this.section3_3_A5_name = state::section3_3_305A5.name
                this.section3_3_A5_value = state.section3_3_305A5
                this.section3_3_A6_name = state::section3_3_305A6.name
                this.section3_3_A6_value = state.section3_3_305A6
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_305_1)) {
                GRI_305_1 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeDecimalNumber = onChangeDecimalNumber
                    this.onBlurDecimalNumber = onBlurDecimalNumber
                }
            }
            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_305_2)) {
                GRI_305_2 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeDecimalNumber = onChangeDecimalNumber
                    this.onBlurDecimalNumber = onBlurDecimalNumber
                }
            }

        }
    }
}