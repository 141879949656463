package com.ecosave.watch.portal.components.esg.generaldisclosures.governance

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val Disclosure_2_20 = FC<EsgSubFormComponentProps> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.governance?.let { state ->

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_2_20
        }

        Box {
            label {
                +"Describe the process for designing the organization’s remuneration policies and for determining remuneration."
            }
            ESGTextAreaFieldWrapper {
                name = state::section220A1.name
                value = state.section220A1 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"Whether independent highest governance body members or an independent remuneration committee oversees the process for determining remuneration."
            }
            ESGTextAreaFieldWrapper {
                name = state::section220A2.name
                value = state.section220A2 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"How the views of stakeholders (including shareholders) regarding remuneration are sought and taken into consideration."
            }
            ESGTextAreaFieldWrapper {
                name = state::section220A3.name
                value = state.section220A3 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"Whether remuneration consultants are involved in determining remuneration and, if so, whether they are independent of the organization, its highest governance body and senior executives."
            }
            ESGTextAreaFieldWrapper {
                name = state::section220A4.name
                value = state.section220A4 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"Report the results of votes of stakeholders (including shareholders) on remuneration policies and proposals, if applicable."
            }
            ESGTextAreaFieldWrapper {
                name = state::section220A5.name
                value = state.section220A5 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}