package com.ecosave.watch.portal.components.esg.waterandeffluents

import com.ecosave.watch.portal.components.common.RomanListWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.*
import com.ecosave.watch.portal.components.esg.waterandeffluents.WaterConstants.CATEGORY_HEADER
import com.ecosave.watch.portal.components.esg.waterandeffluents.WaterConstants.GROUND_WATER
import com.ecosave.watch.portal.components.esg.waterandeffluents.WaterConstants.PRODUCED_WATER
import com.ecosave.watch.portal.components.esg.waterandeffluents.WaterConstants.SEA_WATER
import com.ecosave.watch.portal.components.esg.waterandeffluents.WaterConstants.SURFACE_WATER
import com.ecosave.watch.portal.components.esg.waterandeffluents.WaterConstants.THIRD_PARTY_WATER
import com.ecosave.watch.portal.components.esg.waterandeffluents.WaterConstants.TOTAL_WATER
import com.ecosave.watch.portal.components.esg.waterandeffluents.WaterConstants.headers
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val GRI_303_3 = FC<EsgSubFormComponentProps> { props ->
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea
    val onChangeDecimalNumber = props.onChangeDecimalNumber
    val onBlurDecimalNumber = props.onBlurDecimalNumber

    props.esgReportState.report?.waterAndEffluents?.let { state ->
        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_303_3
        }

        DisclosureSubHeading {
            subHeading = "Total water withdrawal from all areas in megaliters, and a breakdown of this total by the " +
                "following sources, if applicable:"
        }

        Box {
            className = ESGCommonStyles.TWO_COLUMN_TABLE.cssClass

            TableHeader {
                header = "Category"
            }
            TableHeader {
                header = "Total Water"
            }

            listOf(
                Triple("Surface Water", state::section3033Current13.name, state.section3033Current13),
                Triple("Ground Water", state::section3033Current14.name, state.section3033Current14),
                Triple("Sea Water", state::section3033Current15.name, state.section3033Current15),
                Triple("Produced Water", state::section3033Current16.name, state.section3033Current16),
                Triple("Third-Party Water", state::section3033Current17.name, state.section3033Current17),
                Triple("Total Water", state::section3033Current18.name, state.section3033Current18),

                ).forEach {
                label {
                    +it.first
                }
                ESGNumberFieldWrapper {
                    name = it.second
                    value = it.third
                    onChange = { event -> onChangeDecimalNumber(event, null, null) }
                    onBlur = { event -> onBlurDecimalNumber(event, null, null) }
                }
            }

        }

        Box {

        }
        DisclosureSubHeading {
            subHeading = "Total water withdrawal from all areas with water stress in megaliters, and a breakdown " +
                "of this total by the following sources, if applicable:"
        }
        Box {
            className = ESGCommonStyles.TWO_COLUMN_TABLE.cssClass

            TableHeader {
                header = CATEGORY_HEADER
            }
            TableHeader {
                header = TOTAL_WATER
            }

            listOf(
                Triple("Surface Water", state::section3033Current31.name, state.section3033Current31),
                Triple("Ground Water", state::section3033Current32.name, state.section3033Current32),
                Triple("Sea Water", state::section3033Current33.name, state.section3033Current33),
                Triple("Produced Water", state::section3033Current34.name, state.section3033Current34),
                Triple("Third-Party Water", state::section3033Current35.name, state.section3033Current35),
                Triple("Total Water", state::section3033Current36.name, state.section3033Current36),

                ).forEach {
                label {
                    +it.first
                }
                ESGNumberFieldWrapper {
                    name = it.second
                    value = it.third
                    onChange = { event -> onChangeDecimalNumber(event, null, null) }
                    onBlur = { event -> onBlurDecimalNumber(event, null, null) }
                }
            }

        }
        Box {

        }

        RomanListWrapper(
            title = "A breakdown of total water withdrawal from each of the sources listed in Disclosures " +
                "303-3-a in megaliters by the following categories:",
            list = listOf(
                "Freshwater (less than or equal to 1,000 mg/L Total Dissolved Solids);",
                "Other water (greater than 1,000 mg/L Total Dissolved Solids)."
            )
        )
        Box {
            className = ESGCommonStyles.FOUR_COLUMN_TABLE.cssClass

            headers.forEach {
                TableHeader {
                    header = it
                }
            }

            listOf(
                CrossTablePlacement(
                    SURFACE_WATER,
                    listOf(
                        Pair(state::section3033Current1.name, state.section3033Current1),
                        Pair(state::section3033Current2.name, state.section3033Current2),
                    ),
                    state.section3033Current13
                ),
                CrossTablePlacement(
                    GROUND_WATER,
                    listOf(
                        Pair(state::section3033Current3.name, state.section3033Current3),
                        Pair(state::section3033Current4.name, state.section3033Current4),
                    ),
                    state.section3033Current14
                ),
                CrossTablePlacement(
                    SEA_WATER,
                    listOf(
                        Pair(state::section3033Current5.name, state.section3033Current5),
                        Pair(state::section3033Current6.name, state.section3033Current6),
                    ),
                    state.section3033Current14
                ),
                CrossTablePlacement(
                    PRODUCED_WATER,
                    listOf(
                        Pair(state::section3033Current7.name, state.section3033Current7),
                        Pair(state::section3033Current8.name, state.section3033Current8),
                    ),
                    state.section3033Current16
                ),
                CrossTablePlacement(
                    THIRD_PARTY_WATER,
                    listOf(
                        Pair(state::section3033Current9.name, state.section3033Current9),
                        Pair(state::section3033Current10.name, state.section3033Current10),
                    ),
                    state.section3033Current17
                ),
                CrossTablePlacement(
                    TOTAL_WATER,
                    listOf(
                        Pair(state::section3033Current11.name, state.section3033Current11),
                        Pair(state::section3033Current12.name, state.section3033Current12),
                    ),
                    state.section3033Current18
                )
            ).forEach {
                label {
                    +it.title
                }
                it.values.forEach { valuePair1 ->
                    ESGNumberFieldWrapper {
                        name = valuePair1.first
                        value = valuePair1.second
                        onChange = { event -> onChangeDecimalNumber(event, null, null) }
                    }
                }


                label {
                    +it.total?.toString()
                }

            }
        }

        Box {

        }

        RomanListWrapper(
            title = "A breakdown of total water withdrawal from each of the sources listed in Disclosures " +
                "303-3-b in megaliters by the following categories:",
            list = listOf(
                "Freshwater (less than or equal to 1,000 mg/L Total Dissolved Solids);",
                "Other water (greater than 1,000 mg/L Total Dissolved Solids)."
            )
        )
        Box {
            className = ESGCommonStyles.FOUR_COLUMN_TABLE.cssClass

            headers.forEach {
                TableHeader {
                    header = it
                }
            }

            listOf(
                CrossTablePlacement(
                    SURFACE_WATER,
                    listOf(
                        Pair(state::section3033Current19.name, state.section3033Current19),
                        Pair(state::section3033Current20.name, state.section3033Current20)
                    ),
                    state.section3033Current31
                ),
                CrossTablePlacement(
                    GROUND_WATER,
                    listOf(
                        Pair(state::section3033Current21.name, state.section3033Current21),
                        Pair(state::section3033Current22.name, state.section3033Current22)
                    ),
                    state.section3033Current32
                ),
                CrossTablePlacement(
                    SEA_WATER,
                    listOf(
                        Pair(state::section3033Current23.name, state.section3033Current23),
                        Pair(state::section3033Current24.name, state.section3033Current24)
                    ),
                    state.section3033Current33
                ),
                CrossTablePlacement(
                    PRODUCED_WATER,
                    listOf(
                        Pair(state::section3033Current25.name, state.section3033Current25),
                        Pair(state::section3033Current26.name, state.section3033Current26)
                    ),
                    state.section3033Current34
                ),
                CrossTablePlacement(
                    THIRD_PARTY_WATER,
                    listOf(
                        Pair(state::section3033Current27.name, state.section3033Current27),
                        Pair(state::section3033Current28.name, state.section3033Current28)
                    ),
                    state.section3033Current35
                ),
                CrossTablePlacement(
                    TOTAL_WATER,
                    listOf(
                        Pair(state::section3033Current29.name, state.section3033Current29),
                        Pair(state::section3033Current30.name, state.section3033Current30)
                    ),
                    state.section3033Current36
                )
            ).forEach {
                label {
                    +it.title
                }
                it.values.forEach { valuePair1 ->
                    ESGNumberFieldWrapper {
                        name = valuePair1.first
                        value = valuePair1.second
                        onChange = { event -> onChangeDecimalNumber(event, null, null) }
                        onBlur = { event -> onBlurDecimalNumber(event, null, null) }
                    }
                }

                label {
                    +it.total?.toString()
                }

            }

        }

        Box {
            label {
                +("Provide any contextual information necessary to understand how the " +
                    "data have been compiled,such as any standards, methodologies, and " +
                    "assumptions used.")
            }

            ESGTextAreaFieldWrapper {
                name = state::section3033Field1.name
                value = state.section3033Field1
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
                minimumRows = 4
            }

        }
    }
}

data class CrossTablePlacement(
    val title: String,
    val values: List<Pair<String, Double?>>,
    val total: Double? = null
)