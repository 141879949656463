package com.ecosave.watch.portal.helpers.esg

import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.models.esg.EsgReportState
import com.ecosave.watch.portal.models.esg.EsgReportValidationState
import react.ReactNode
import react.StateSetter

fun validateReportName(
    state: EsgReportState,
    validation: EsgReportValidationState,
    setValidation: StateSetter<EsgReportValidationState>
) {
    if (state.reportFileName.isNullOrBlank()) {
        validation.reportFileNameErrorState = true
        validation.reportFileNameErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.reportFileNameErrorState = false
        validation.reportFileNameErrorMessage = null
    }
    updateEsgState(validation, setValidation)
}

fun validateReportTitle(
    state: EsgReportState,
    validation: EsgReportValidationState,
    setValidation: StateSetter<EsgReportValidationState>
) {
    if (state.reportTitle.isNullOrBlank()) {
        validation.reportTitleErrorState = true
        validation.reportTitleErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.reportTitleErrorState = false
        validation.reportTitleErrorMessage = null
    }
    updateEsgState(validation, setValidation)
}

fun validateCompanyName(
    state: EsgReportState,
    validation: EsgReportValidationState,
    setValidation: StateSetter<EsgReportValidationState>
) {
    if (state.companyName.isNullOrBlank()) {
        validation.companyNameErrorState = true
        validation.companyNameErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.companyNameErrorState = false
        validation.companyNameErrorMessage = null

    }
    updateEsgState(validation, setValidation)
}

fun validateFinancialYear(
    state: EsgReportState,
    validation: EsgReportValidationState,
    setValidation: StateSetter<EsgReportValidationState>
) {
    if (state.reportFinancialYear.financialYear == null) {
        validation.reportFinancialYear.financialYearErrorState = true
        validation.reportFinancialYear.financialYearErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else if (!(state.reportFinancialYear.financialYear.toString().length == ReportConstants.ESG_CALENDAR_YEAR_MAX_DIGITS)) {
        validation.reportFinancialYear.financialYearErrorState = true
        validation.reportFinancialYear.financialYearErrorMessage = ReactNode(ReportConstants.ESG_FINANCIAL_YEAR_FOUR_DIGITS)
    } else {
        validation.reportFinancialYear.financialYearErrorState = false
        validation.reportFinancialYear.financialYearErrorMessage = null
    }
    updateEsgState(validation, setValidation)
}

fun updateEsgState(
    validation: EsgReportValidationState,
    setValidation: StateSetter<EsgReportValidationState>
) {
    setValidation(validation.copy())
}

fun validateCalendarDateStartState(
    state: EsgReportState,
    validation: EsgReportValidationState,
    setValidation: StateSetter<EsgReportValidationState>
) {
    if (state.reportFinancialYear.calendarDateStart.isNullOrBlank()) {
        validation.reportFinancialYear.calendarDateStartErrorState = true
        validation.reportFinancialYear.calendarDateStartErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.reportFinancialYear.calendarDateStartErrorState = false
        validation.reportFinancialYear.calendarDateStartErrorMessage = null
    }
    updateEsgState(validation, setValidation)
}

fun validateCalendarDateEndState(
    state: EsgReportState,
    validation: EsgReportValidationState,
    setValidation: StateSetter<EsgReportValidationState>
) {
    if (state.reportFinancialYear.calendarDateEnd.isNullOrBlank()) {
        validation.reportFinancialYear.calendarDateEndErrorState = true
        validation.reportFinancialYear.calendarDateEndErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.reportFinancialYear.calendarDateEndErrorState = false
        validation.reportFinancialYear.calendarDateEndErrorMessage = null
    }
    updateEsgState(validation, setValidation)
}

fun isEsgFormValid(state: EsgReportState, validation: EsgReportValidationState, setValidation: StateSetter<EsgReportValidationState>): Boolean {
    validateEsgForm(state, validation, setValidation)
    return isEsgFormFieldsValid(validation)
}

fun validateEsgForm(
    state: EsgReportState,
    validation: EsgReportValidationState,
    setValidation: StateSetter<EsgReportValidationState>
) {
    validateReportName(state, validation, setValidation)
    validateReportTitle(state, validation, setValidation)
    validateCompanyName(state, validation, setValidation)
    validateFinancialYear(state, validation, setValidation)
    validateCalendarDateStartState(state, validation, setValidation)
    validateCalendarDateEndState(state, validation, setValidation)
}

fun isEsgFormFieldsValid(
    validation: EsgReportValidationState
): Boolean {
    return !(validation.reportFileNameErrorState || validation.reportTitleErrorState || validation.companyNameErrorState || validation.reportFinancialYear.financialYearErrorState || validation.reportFinancialYear.calendarDateStartErrorState || validation.reportFinancialYear.calendarDateEndErrorState)
}