package com.ecosave.watch.portal.components.utilitybills

import com.ecosave.watch.portal.helpers.billing.BillingHeaders
import com.ecosave.watch.portal.helpers.billing.DemandUnit
import com.ecosave.watch.portal.models.billing.utilitybillstate.UtilityBillState
import com.ecosave.watch.portal.styles.BillingTableStyles
import mui.material.FormControl
import mui.material.FormControlVariant
import mui.material.MenuItem
import mui.material.Select
import mui.material.Size
import mui.material.TableCell
import react.FC
import react.Props
import react.StateSetter

external interface DemandUnitProps : Props {
    var stateDemandUnit: UtilityBillState
    var setStateDemandUnit: StateSetter<UtilityBillState>
    var demandUnits: List<DemandUnit>
}

val DemandUnit = FC<DemandUnitProps> { props ->

    val state = props.stateDemandUnit
    val setState = props.setStateDemandUnit
    val demandUnits = props.demandUnits

    TableCell {
        className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
        +BillingHeaders.DEMAND_UNIT.header
    }
    TableCell {
        className = BillingTableStyles.TABLE_CELL.cssClass
        FormControl {
            fullWidth = true
            size = Size.small
            variant = FormControlVariant.filled
            hiddenLabel = true
            Select {
                value = state.demandUnit
                onChange = { event, _ ->
                    setState(
                        state.copy(
                            demandUnit = event.target.value
                        )
                    )
                }
                for (unit in demandUnits) {
                    MenuItem {
                        value = unit.name
                        +unit.description
                    }
                }
            }
        }
    }
}