package com.ecosave.watch.portal.components.energymanagement

import com.ecosave.watch.portal.helpers.Colors
import com.ecosave.watch.portal.helpers.billing.UtilityType
import com.ecosave.watch.portal.helpers.energymanagement.getTotalOfUtilities
import com.ecosave.watch.portal.models.energymanagement.TotalEnergyUsageNCO2
import mui.material.Box
import mui.material.Typography
import mui.system.sx
import react.FC
import react.Props
import web.cssom.Color
import web.cssom.px

external interface UtilityTypePieChartTooltipProps : Props {
    var active: Boolean
    var payload: Array<dynamic>
    var data: List<TotalEnergyUsageNCO2>
}

val UtilityTypePieChartTooltip = FC<UtilityTypePieChartTooltipProps> { props ->

    if (props.active && props.payload.isNotEmpty()) {

        val total = getTotalOfUtilities(props.payload.first().dataKey, props.data)
        val percentage: Double = (props.payload.first().value / total) * 100

        Box {
            sx {
                paddingTop = 10.px
                paddingBottom = 10.px
                paddingLeft = 8.px
                paddingRight = 8.px
                borderRadius = 3.px
                backgroundColor = Color(Colors.DARK_GRAY)
            }
            Typography {
                sx {
                    color = when (props.payload.first().name) {
                        UtilityType.ELECTRIC.type -> Color(Colors.LIGHT_CYAN_BLUE)
                        UtilityType.NATURAL_GAS.type -> Color(Colors.BRIGHT_GREEN)
                        UtilityType.STEAM.type -> Color(Colors.GOLDENROD)
                        else -> Color("")
                    }
                }
                if (total == 0.0) {
                    +"Error, something went wrong."
                } else {
                    +"${props.payload.first().name}: ${percentage.asDynamic().toFixed(1)}%"
                }
            }
        }
    }
}