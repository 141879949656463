package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class SecurityPracticesState(
    var section4101A1: Double? = null,
    var section4101A2: String? = null,

    var section3_3_410A1: String? = null,
    var section3_3_410A2: String? = null,
    var section3_3_410A3: String? = null,
    var section3_3_410A4: String? = null,
    var section3_3_410A5: String? = null,
    var section3_3_410A6: String? = null
)