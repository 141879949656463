package com.ecosave.watch.portal.components.esg.localcommunity

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureSubHeading
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.models.esg.LocalCommunityState
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val GRI_413_1 = FC<EsgSubFormComponentProps> { props ->
    val onChangeDecimalNumber = props.onChangeDecimalNumber
    val onBlurDecimalNumber = props.onBlurDecimalNumber

    props.esgReportState.report?.localCommunity?.let { state ->
        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_413_1
        }

        DisclosureSubHeading {
            subHeading =
                "Percentage of operations with implemented local community engagement, impact assessments, and/or development programs, including the use of:"
        }

        Box {
            className = ESGCommonStyles.TWO_COLUMN_TABLE_8_4.cssClass

            label {
                +"Social impact assessments, including gender impact assessments, based on participatory processes"
            }
            ESGNumberFieldWrapper {
                name = LocalCommunityState::section4131A1.name
                value = state.section4131A1
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Environmental impact assessments and ongoing monitoring"
            }
            ESGNumberFieldWrapper {
                name = LocalCommunityState::section4131A2.name
                value = state.section4131A2
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Public disclosure of results of environmental and social impact assessments"
            }
            ESGNumberFieldWrapper {
                name = LocalCommunityState::section4131A3.name
                value = state.section4131A3
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Local community development programs based on local communities’ needs"
            }
            ESGNumberFieldWrapper {
                name = LocalCommunityState::section4131A4.name
                value = state.section4131A4
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Stakeholder engagement plan based on stakeholder mapping"
            }
            ESGNumberFieldWrapper {
                name = LocalCommunityState::section4131A5.name
                value = state.section4131A5
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Broad based local community consultation committees and processes that include vulnerable groups"
            }
            ESGNumberFieldWrapper {
                name = LocalCommunityState::section4131A6.name
                value = state.section4131A6
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Works councils, occupational health and safety committees and other worker representation bodies to deal with impacts"
            }
            ESGNumberFieldWrapper {
                name = LocalCommunityState::section4131A7.name
                value = state.section4131A7
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Formal local community grievance processes"
            }
            ESGNumberFieldWrapper {
                name = LocalCommunityState::section4131A8.name
                value = state.section4131A8
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }
        }
    }
}