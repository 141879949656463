package com.ecosave.watch.portal.components.common

import com.ecosave.watch.portal.styles.CommonStyles
import com.ecosave.watch.portal.helpers.Colors
import emotion.react.css
import mui.material.Box
import mui.material.Typography
import mui.material.styles.TypographyVariant
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.router.dom.Link
import web.cssom.Color

external interface GlobalMessageProps : Props {
    var title: String
    var message: String
    var showNavigationButton: Boolean
    var navigationButtonText: String
    var navigationRoute: String
}

val GlobalMessage = FC<GlobalMessageProps> { props ->
    Box {
        className = CommonStyles.MESSAGE_WRAPPER.cssClass
        Typography {
            className = CommonStyles.HEADING_TITLE.cssClass
            variant = TypographyVariant.h3
            +props.title
        }
        Typography {
            className = CommonStyles.SUBTITLE.cssClass
            variant = TypographyVariant.h6
            component = ReactHTML.h4
            +props.message
        }
        if (props.showNavigationButton) {
            Link {
                to = props.navigationRoute
                css {
                    color = Color(Colors.BRIGHT_CYAN_BLUE)
                }
                +props.navigationButtonText
            }
        }
    }
}
