package com.ecosave.watch.portal.models.billing

import com.ecosave.watch.portal.helpers.billing.DemandUnit
import com.ecosave.watch.portal.helpers.billing.UsageUnit
import kotlinx.serialization.Serializable

@Serializable
data class UtilityBill(
    val utilityBillId: Int?,
    val utilityAccountId: Int,
    val utilityType: String,
    val billStartDate: String,
    val billEndDate: String,
    val totalUsage: Float? = null,
    val usageUnit: UsageUnit,
    val demandUnit: DemandUnit? = null,
    val totalBillCost: Float? = null,
    val billEstimated: Boolean? = null,
    val billAdjusted: Boolean? = null,
    val totalDeliveryDistribution: Float? = null,
    var electricAdditional: ElectricAdditional,
    var naturalGasAdditional: NaturalGasAdditional,
    var waterBillAdditional: WaterBillAdditional,
    var steamBillAdditional: SteamBillAdditional,
    var solarBillAdditional: SolarBillAdditional
)

@Serializable
data class ElectricAdditional(
    val totalPeakBilledDemand: Float? = null,
    val peakDemandCharges: Float? = null,
    val onPeakUsage: Float? = null,
    val onPeakUsageCharges: Float? = null,
    val offPeakUsage: Float? = null,
    val offPeakUsageCharges: Float? = null,
    val onPeakDemand: Float? = null,
    val offPeakDemand: Float? = null
)

@Serializable
data class NaturalGasAdditional(
    val totalCommoditySupply: Float? = null
)

@Serializable
data class WaterBillAdditional(
    val serviceCharges: Float? = null,
    val stormWaterCharges: Float? = null,
    val industrialSurcharge: Float? = null,
    val flow: Float? = null,
    val srf: Float? = null,
    val flowUsedForBilling: Float? = null,
    val biochemicalOxygenDemand: Float? = null,
    val bodSurchargeFactor: Float? = null,
    val bodCharges: Float? = null,
    val totalSuspendedSolids: Float? = null,
    val tssSurchargeFactor: Float? = null,
    val tssCharges: Float? = null,
    val totalSurchargeBill: Float? = null
)

@Serializable
data class SteamBillAdditional(
    val demandMeasured: Float? = null,
    val demandBilled: Float? = null,
    val totalHeatingSteamCharges: Float? = null,
    val heatingDemandCharges: Float? = null,
    val heatingSteamUsage: Float? = null,
    val heatingUsageCharges: Float? = null,
    val coolingSteamUsage: Float? = null,
    val totalCoolingSteamCharges: Float? = null
)

@Serializable
data class SolarBillAdditional(
    val solarExported: Float? = null,
    val solarExportedCredit: Float? = null,
    val totalSolarGenerated: Float? = null,
    val totalSolarCost: Float? = null
)

