package com.ecosave.watch.portal.components.privacypolicy

import react.FC
import react.Props

val CancellationAndTermination = FC<Props> {
    HeadingWrapper {
        heading = "7. Cancellation and Termination"
    }
    HeadingWrapper {
        heading = "7.1. Account Cancellation"
        subHeading = true
    }
    TypographyWrapper {
        +"It is your responsibility to properly cancel your account with Ecosave. You can cancel your account at any time by going into your account settings."
    }
    HeadingWrapper {
        heading = "7.2. Upon Cancellation"
        subHeading = true
    }
    TypographyWrapper {
        +"Any and all rights granted to you with respect to the Service and Technology, and any and all rights granted to Ecosave with respect to your data except as written in Section 11.2, will terminate on the effective date of termination. You agree return to Ecosave any and all Confidential Information of Ecosave in your possession or control. Ecosave will have no obligation to provide the Service to you or Authorized Users after the effective date of the termination. You will pay to Ecosave any amounts payable for your and Authorized User's use of the Service through the effective date of the termination, together with all other amounts in accordance with Section 6. This Section 7 and Sections 4, 6, and 10-15 will survive the expiration or earlier termination of this Agreement."
    }
    TypographyWrapper {
        +"Ecosave's only obligation with respect to any electronic information transmitted or received by you or Authorized Users in relation to use of the Service is to promptly delete or destroy any information that is stored in the Service database on the effective date of termination upon your request. You acknowledge that the duration of the retention of such information is determined by the terms of the applicable Free Plan or Paying Plan. In addition, you acknowledge that although information in the Service database will be deleted from its transaction servers, Ecosave may retain such information stored on automatic backup archiving systems during the period such backup or archived materials are retained under Ecosave's customary procedures and policies. In addition, Ecosave may retain certain information as provided in Section 11.2."
    }
    HeadingWrapper {
        heading = "7.3. Ecosave May Terminate"
        subHeading = true
    }
    TypographyWrapper {
        +"Ecosave may terminate this Agreement immediately upon notice to you if you breach any provisions in the Agreement, in order to comply with applicable laws or regulations, or if you default in the timely payment of any amounts due Ecosave under a Paying Plan."
    }
}
