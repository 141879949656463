package com.ecosave.watch.portal.components.esg.generaldisclosures.governance

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.ol
import react.dom.onChange

val Disclosure_2_19 = FC<EsgSubFormComponentProps> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.governance?.let { state ->

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_2_19
        }

        Box {
            label {
                +"Describe the remuneration policies for members of the highest governance body and senior executives, including:"
                ol {
                    li {
                        +"Fixed pay and variable pay."
                    }
                    li {
                       +"Sign-on bonuses or recruitment incentive payments."
                    }
                    li {
                        +"Termination payments."
                    }
                    li {
                        +"Clawbacks."
                    }
                    li {
                        +"Retirement benefits."
                    }
                }
            }
            ESGTextAreaFieldWrapper {
                name = state::section219A8.name
                value = state.section219A8 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"Describe how the remuneration policies for members of the highest governance body and senior executives relate to their objectives and performance in relation to the management of the organization’s impacts on the economy, environment, and people."
            }
            ESGTextAreaFieldWrapper {
                name = state::section219A7.name
                value = state.section219A7 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}