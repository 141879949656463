package com.ecosave.watch.portal.components.privacypolicy

import react.FC
import react.Props
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.ul

val LimitationsOfLiability = FC<Props> {
    HeadingWrapper {
        heading = "13. Limitations of Liability"
    }
    TypographyWrapper {
        +"You understand and agree that we will not be liable to you or any third party for any damage, injury, or loss of profits, use, goodwill, or data, or for any incidental, indirect, special, consequential, or exemplary damages, however arising, that result from:"
    }
    TypographyWrapper {
        ul {
            for (item in TermsOfServiceConstants.limitationsOfLiabilityList) {
                li {
                    +item
                }
            }
        }
    }
    TypographyWrapper {
        +"Our liability is limited whether or not we have been informed of the possibility of such damages, and even if a remedy described in this Agreement is found to have failed of its essential purpose. We will have no liability for any failure or delay due to matters beyond our reasonable control."
    }
    TypographyWrapper {
        +"These limitations will not apply to damages arising out of a party's failure to comply with its confidentiality obligations, indemnification obligations, or payment obligations to Ecosave. Except for their respective indemnity and confidentiality obligations, in no event will either party, their affiliates, directors, employees, or licensors be liable to the other party or any authorized user for any claims, proceedings, liabilities, obligations, damages, losses, or costs in an amount exceeding the fees you paid to Ecosave."
    }
    TypographyWrapper {
        +"This limitation of liability section applies whether the alleged liability is based on contract, tort, negligence, strict liability, or any other basis, even if the non-breaching party has been advised of the possibility of such damage. The above limitations of liability will apply to the fullest extent permitted by law in the applicable jurisdiction."
    }
}
