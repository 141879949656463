package com.ecosave.watch.portal.styles

import web.cssom.ClassName

enum class RecoverPasswordStyles(val cssClass: ClassName) {
    CENTER(ClassName("recover-password-center")),
    FORM_SECTION(ClassName("recover-password-form-section")),
    INPUT(ClassName("recover-password-input")),
    BUTTON(ClassName("recover-password-button")),
    CONTAINER(ClassName("recover-password-container"))
}