package com.ecosave.watch.portal.helpers.billing

import com.ecosave.watch.portal.models.billing.utilitybillstate.UtilityBillState
import com.ecosave.watch.portal.models.billing.UtilityBillValidationState
import react.ReactNode
import react.StateSetter

fun validateTotalCommoditySupply(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.naturalGasAdditional.totalCommoditySupply == null) {
        validation.naturalGasBillValidationState.totalCommoditySupplyErrorState = false
        validation.naturalGasBillValidationState.totalCommoditySupplyErrorMessage = null
    } else if (getDigitsCountBeforeDecimal(state.naturalGasAdditional.totalCommoditySupply.toString()) > BillingConstants.MAX_COST_DIGITS) {
        validation.naturalGasBillValidationState.totalCommoditySupplyErrorState = true
        validation.naturalGasBillValidationState.totalCommoditySupplyErrorMessage =
            ReactNode(BillingConstants.INVALID_COST_ERROR_MESSAGE)
    } else {
        validation.naturalGasBillValidationState.totalCommoditySupplyErrorState = false
        validation.naturalGasBillValidationState.totalCommoditySupplyErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun validateTotalDeliveryDistribution(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.totalDeliveryDistribution == null) {
        validation.naturalGasBillValidationState.totalDeliveryDistributionErrorState = false
        validation.naturalGasBillValidationState.totalDeliveryDistributionErrorMessage = null
    } else if (getDigitsCountBeforeDecimal(state.totalDeliveryDistribution.toString()) > BillingConstants.MAX_COST_DIGITS) {
        validation.naturalGasBillValidationState.totalDeliveryDistributionErrorState = true
        validation.naturalGasBillValidationState.totalDeliveryDistributionErrorMessage =
            ReactNode(BillingConstants.INVALID_COST_ERROR_MESSAGE)
    } else {
        validation.naturalGasBillValidationState.totalDeliveryDistributionErrorState = false
        validation.naturalGasBillValidationState.totalDeliveryDistributionErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun isBillingNaturalGasFormValid(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
): Boolean {
    validateBillingNaturalGasForm(state, validation, setValidation)
    validateBillingCommonFields(state, validation, setValidation, UtilityType.NATURAL_GAS)
    return naturalGasFormFieldsValid(validation) && isBillingCommonFieldsValid(
        validation,
        UtilityType.NATURAL_GAS
    )
}

fun validateBillingNaturalGasForm(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    validateTotalCommoditySupply(state, validation, setValidation)
    validateTotalDeliveryDistribution(state, validation, setValidation)
}

fun naturalGasFormFieldsValid(validation: UtilityBillValidationState): Boolean {
    return !(validation.naturalGasBillValidationState.totalCommoditySupplyErrorState ||
            validation.naturalGasBillValidationState.totalDeliveryDistributionErrorState)
}
