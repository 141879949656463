package com.ecosave.watch.portal.models.esg

import com.ecosave.watch.portal.helpers.esg.EsgSection
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonPrimitive

@Serializable
data class EsgCollectionDeleteRow(
    val objectPatchEnum: EsgSection,
    val reportFileName: String,
    val pathFirst: String,
    val indexAt: Int
)

@Serializable
data class EsgCollectionAddRow(
    val objectName: String,
    val reportFileName: String,
    val patchCollection: RowPatch
)

@Serializable
data class RowPatch (
    val pathFirst: String
)

@Serializable
data class EsgPatchUpdate(
    val objectName: String,
    val reportFileName: String,
    val patch: EsgPatch
)

@Serializable
data class EsgCollectionPatchUpdate(
    val objectName: String,
    val reportFileName: String,
    val patchCollection: EsgCollectionPatch
)

@Serializable
data class EsgPatch(
    val op: String,
    val path: String,
    val value: JsonPrimitive? = null
)

@Serializable
data class EsgCollectionPatch(
    val pathFirst: String,
    val pathSecond: String? = null,
    val index: Int? = null,
    val value: JsonPrimitive? = null
)