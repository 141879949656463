package com.ecosave.watch.portal.components.privacypolicy

object TermsOfServiceConstants {
    val definitonsList = mutableListOf(
        "The \"Agreement\" refers to these Terms of Service (the \"Terms\"), our Privacy Policy, and all other operating rules, policies, and procedures that we may publish periodically on the Website.",
        "\"Ecosave,\"\"we,\"and \"us\"refer Ecosave, LLC, a Delaware company doing business as Ecosave, as well as our affiliates, directors, subsidiaries, contractors, licensors, officers, agents, and employees.",
        "The \"Service\" refers to the Website and online products provided by Ecosave.",
        "The \"Website\" refers to Ecosave's website located at ecosaveinc.com, all subdomains of ecosaveinc.com, and all content, services, and products provided by Ecosave at or through ecosaveinc.com and its subdomains.",
        "\"User ,\"\"you ,\"and \"your\"refer to the individual person, company, or organization that has visited or is using the Website or Service; that accesses or uses any part of the account; or that directs the use of the account in the performance of its functions. A User must be at least 13 years of age.",
        "\"Authorized Users\" refer to Users and the Ecosave personnel that Users authorize to access our Service.",
        "A \"Subscription\" is (a) an online order for the Service completed and submitted by you through this Website and accepted by Ecosave or (b) another written agreement or purchase order for the Service accepted by both you and Ecosave.",
        "The \"Subscription Date\" refers to (a) the date you submit your online order through the Website or (b) the date you accept an agreement that is ultimately accepted by both you and Ecosave.",
    )
    val conductRestrictionsPoints = mutableListOf(
        "Attempt to interfere with, compromise the system integrity or security, or decipher any transmissions to or from the servers running the Service",
        "Take any action that imposes, or may impose at Ecosave's discretion, an unreasonable or disproportionately large load on Ecosave's infrastructure",
        "Knowingly upload invalid data, viruses, worms, or other software agents through the Service",
        "Enable any person or entity other than Authorized Users to access and use the Service or Technology (as defined in Section 12)",
        "Modify or create any derivative work based upon the Service or Technology",
        "Engage in, permit, or suffer to continue any copying or distribution of the Service or Technology",
        "Reverse engineer, disassemble, or decompile all or any portion of, or attempt to discover or recreate the source code for, any software that is part of the Service or Technology",
        "Access the Service in order to build a competitive solution or to assist any third party to build a competitive solution",
        "Remove, obscure, or alter any proprietary notice related to the Service or Technology",
        "Engage in, permit, or suffer to continue any use or other activity that is not expressly authorized under this Agreement by any person or entity within your control.",
        "In the event you violate any of the terms in this section, in addition to any other remedies available at law or in equity, Ecosave will have the right, in its discretion, to immediately suspend your and Authorized Users' use and access to the Service."
    )
    val limitationsOfLiabilityList = mutableListOf(
        "The use, disclosure, or display of your Customer Data.",
        "Your use or inability to use the Service.",
        "Any modification, price change, suspension, interruption, cessation of transmission, or discontinuance of the Service.",
        "The Service generally or the software or systems that make the Service available.",
        "Unauthorized access to or alterations of your transmissions or data.",
        "Statements or conduct of any third party on the Service.",
        "Any other user interactions that you input or receive through your use of the Service.",
        "Hacking, tampering, or other unauthorized access or use of the Service or your account(s) or the information contained therein.",
        "Errors, mistakes, or inaccuracies of data",
        "Personal injury or property damage, of any nature whatsoever, resulting from your and authorized users' access to and use of the Service or support.",
        "Any other matter relating to the Service."
    )
    val ecosaveWebsiteURL = "https://ecosaveinc.com/"
    val ecosaveSupportEmail = "support@ecosavewatch.com" //Main
    val ecosaveComplicanceEmail = "compliance@ecosavewatch.com"
    val ecosaveTosEmail = "tos@ecosavewatch.com"
    val ecosavePrivacyEmail = "privacy@ecosavewatch.com"
    val ecosaveSalesEmail = "sales@ecosavewatch.com"
    val ecosaveLegalEmail = "legal@ecosavewatch.com"
    val ecosavePhoneNumber = "(833) 510-1403"

}