package com.ecosave.watch.portal.components.utilityaccounts

import com.ecosave.watch.portal.helpers.billing.BillingCommonAnnotations
import com.ecosave.watch.portal.helpers.billing.BillingConstants
import com.ecosave.watch.portal.helpers.billing.BillingHeaders
import com.ecosave.watch.portal.helpers.billing.BillingSolarHeaders
import com.ecosave.watch.portal.helpers.billing.differenceBetweenBillStartAndEndDate
import com.ecosave.watch.portal.helpers.billing.validateBillStartDate
import com.ecosave.watch.portal.models.billing.UtilityBillValidationState
import com.ecosave.watch.portal.models.billing.utilitybillstate.UtilityBillState
import kotlin.math.abs
import react.FC
import react.Props
import react.ReactNode
import react.StateSetter
import react.dom.onChange
import web.html.HTMLInputElement
import web.html.InputType

external interface BillStartDateProps : Props {
    var state: UtilityBillState
    var setState: StateSetter<UtilityBillState>
    var validation: UtilityBillValidationState
    var setValidation: StateSetter<UtilityBillValidationState>
    var isSolarStartDate: Boolean
    var setDialogState: StateSetter<Boolean>
}

val BillStartDate = FC<BillStartDateProps> { props ->

    TextFieldWrapper {
        label =
            if (props.isSolarStartDate) ReactNode(BillingSolarHeaders.START_DATE.header) else
                ReactNode("${BillingHeaders.BILL_START_DATE.header} *")
        type = InputType.date
        if (!props.isSolarStartDate) {
            annotation = BillingCommonAnnotations.BILL_START_DATE
        }
        InputLabelProps = js("({shrink: true})")
        value = props.state.billStartDate
        onChange = {
            val target = it.target as HTMLInputElement
            val value = target.value
            props.setState(
                props.state.copy(
                    billStartDate = value
                )
            )
        }
        error = props.validation.billStartDateErrorState
        helperText = props.validation.billStartDateErrorMessage
        onBlur = {
            val days = differenceBetweenBillStartAndEndDate(props.state.billStartDate, props.state.billEndDate)
            props.setDialogState(abs(days) > BillingConstants.BILL_START_AND_END_DATE_DAYS_DIFF)
            validateBillStartDate(props.state, props.validation, props.setValidation)
        }
    }

}