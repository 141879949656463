package com.ecosave.watch.portal.components.esg.marketpresence

import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.Label
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.MarketPresenceField
import com.ecosave.watch.portal.models.esg.MarketPresenceState
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import com.ecosave.watch.portal.styles.esg.MarketPresence
import mui.material.Box
import react.FC
import react.Props
import react.dom.events.FormEvent
import react.dom.onChange
import web.html.HTMLDivElement

external interface Section2022Props : Props {
    var onChangeTextArea: (FormEvent<HTMLDivElement>) -> Unit
    var onChangeNumber: (FormEvent<HTMLDivElement>) -> Unit
    var state: MarketPresenceState
}

val Section202_2 = FC<Section2022Props> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onChangeNumber = props.onChangeNumber
    val state = props.state

    DisclosureName {
        disclosure = GriDisclosureTitles.GRI_202_2
    }

    Box {
        className = ESGCommonStyles.TABLE_WRAPPER.cssClass
        Box {
            className = MarketPresence.SECTION_202_2_TABLE.cssClass
            Label {
                label = "Percentage of senior management at significant locations of operation that are hired from the local community"
            }
            ESGNumberFieldWrapper {
                name = MarketPresenceField.SECTION_202_2_A1.name
                value = state.section2022A1
                onChange = { event -> onChangeNumber(event) }
            }
            Label {
                label = "Definition used for “senior management”:"
            }
            ESGTextAreaFieldWrapper {
                name = MarketPresenceField.SECTION_202_2_A2.name
                value = state.section2022A2
                onChange = { event -> onChangeTextArea(event) }
            }
            Label {
                label = "The organization’s geographical definition of ‘local’."
            }
            ESGTextAreaFieldWrapper {
                name = MarketPresenceField.SECTION_202_2_A3.name
                value = state.section2022A3
                onChange = { event -> onChangeTextArea(event) }
            }
            Label {
                label = "The definition used for ‘significant locations of operation’."
            }
            ESGTextAreaFieldWrapper {
                name = MarketPresenceField.SECTION_202_2_A4.name
                value = state.section2022A4
                onChange = { event -> onChangeTextArea(event) }
            }
        }
    }
}