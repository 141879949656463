package com.ecosave.watch.portal.helpers.workorder

enum class ActionStatus(val description: String) {
    ASSIGNED("Assigned"),
    IN_PROGRESS("In-Progress"),
    COMPLETED("Closed"),
    UNASSIGNED("Unassigned")
}

enum class ActionSeverity(val description: String) {
    MINOR("Minor"),
    MODERATE("Moderate"),
    MAJOR("Major")
}