package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class NonDiscriminationState(
    var section4061Field1: Long? = null,
    var nonDiscriminationItems: MutableList<NonDiscriminationItem> = mutableListOf(),

    var section3_3_406A1: String? = null,
    var section3_3_406A2: String? = null,
    var section3_3_406A3: String? = null,
    var section3_3_406A4: String? = null,
    var section3_3_406A5: String? = null,
    var section3_3_406A6: String? = null
)

@Serializable
data class NonDiscriminationItem(
    var section4061A1: String? = null,
    var section4061A2: String? = null,
    var section4061A3: String? = null,
    var section4061A4: String? = null,
    var section4061A5: String? = null,
) : DynamicTableItemKey()


