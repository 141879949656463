package com.ecosave.watch.portal.services

import com.ecosave.watch.portal.helpers.BackendServerURL
import com.ecosave.watch.portal.helpers.common.getRefreshTokenFromLocalStorage
import com.ecosave.watch.portal.helpers.usermanagement.OnBoardingStatus
import com.ecosave.watch.portal.models.auth.TokenData
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*

suspend fun getOnboardingStatus(): OnBoardingStatus? {
    try {
        val response: HttpResponse = httpClient.get("$BackendServerURL/userAccount/onboarding-status")
        if (response.status == HttpStatusCode.OK) {
            return response.body()
        }

    } catch (e: dynamic) {
        console.log(e)
    }
    return null
}

suspend fun getAccessToken(): TokenData? {
    try {
        val response: HttpResponse =
            nonAuthClient.post("$BackendServerURL/userAccount/login/refresh-access-token") {
                contentType(ContentType.Application.Json)
                setBody(getRefreshTokenFromLocalStorage())
            }

        if (response.status == HttpStatusCode.OK) {
            return response.body()
        }

    } catch (_: dynamic) {

    }
    return null
}
