package com.ecosave.watch.portal.models.workorder

import com.ecosave.watch.portal.helpers.common.AutoCompleteOption

data class ObservationsFilterDataClass(
    var generatedDate: String = "",
    var severity: MutableList<AutoCompleteOption> = mutableListOf(),
    var utility: MutableList<AutoCompleteOption> = mutableListOf(),
    var event: MutableList<AutoCompleteOption> = mutableListOf(),
    var facility: MutableList<AutoCompleteOption> = mutableListOf(),
    var accountNumber: MutableList<AutoCompleteOption> = mutableListOf()
)
