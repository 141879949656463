package com.ecosave.watch.portal.models.controlcenter

import com.ecosave.watch.portal.helpers.workorder.ActionSeverity

data class ActionsData(
    var facilityId: String = "",
    var facilityName: String = "",
    var severity: ActionSeverity = ActionSeverity.MAJOR,
    var navigatedFromControlCenter: Boolean = false
)

