package com.ecosave.watch.portal.models.workorder

import com.ecosave.watch.portal.helpers.workorder.ActionSeverity
import kotlinx.serialization.Serializable

@Serializable
data class Observation(
    val observationId: Int,
    val event: String,
    val eventDescription: String,
    val severity: ActionSeverity,
    val utility: String,
    val facilityName: String,
    val accountNumber: String,
    val observation: String,
    val dateTime: String,
    val actionGenerated: Int,
    var actionInProgress: Int,
    var actionCompleted: Int
)

