package com.ecosave.watch.portal.components.esg.formcontrols

import com.ecosave.watch.portal.helpers.Colors
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML
import web.cssom.Color
import web.cssom.px

external interface TableHeaderProps : Props {
    var header: String
}

val TableHeader = FC<TableHeaderProps> {
    ReactHTML.label {
        css {
            fontSize = 16.px
            color = Color(Colors.GRAY)
        }
        +it.header
    }
}