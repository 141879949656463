package com.ecosave.watch.portal.components.energymanagement

import com.ecosave.watch.portal.Themes
import com.ecosave.watch.portal.Themes.ecosaveGrayAccent
import com.ecosave.watch.portal.components.common.ApiLazyRender
import com.ecosave.watch.portal.components.npm.Bar
import com.ecosave.watch.portal.components.npm.BarChart
import com.ecosave.watch.portal.components.npm.Brush
import com.ecosave.watch.portal.components.npm.CartesianGrid
import com.ecosave.watch.portal.components.npm.XAxis
import com.ecosave.watch.portal.components.npm.YAxis
import com.ecosave.watch.portal.helpers.Colors
import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.formatTick
import com.ecosave.watch.portal.helpers.energymanagement.BaseSectionLabels
import com.ecosave.watch.portal.helpers.energymanagement.renderTraveller
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.models.energymanagement.BaselineSelectionStatus
import com.ecosave.watch.portal.models.energymanagement.BrushPoint
import com.ecosave.watch.portal.services.energymanagement.saveBaseline
import js.core.jso
import kotlinx.coroutines.launch
import moment.moment
import mui.icons.material.Close
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.FormControlLabel
import mui.material.IconButton
import mui.material.Modal
import mui.material.Radio
import mui.material.RadioGroup
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.StateSetter
import react.create
import react.useEffect
import react.useState
import web.cssom.Color
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.FontWeight
import web.cssom.JustifyContent
import web.cssom.TextAlign
import web.cssom.px
import web.window.window
import com.ecosave.watch.portal.components.npm.Tooltip as TTip
import com.ecosave.watch.portal.styles.EnergyManagementStyles as styles

external interface BaseLineComponentProps : Props {
    var data: String
    var baselineStatus: BaselineSelectionStatus
    var baselineStartMonth: String
    var refetchBaseline: () -> Unit
    var facilityId: Int
    var isSecondary: Boolean
    var isBaselineOpen: Boolean
    var closeBaseline: () -> Unit
    var notification: NotificationState
    var notify: StateSetter<NotificationState>
}

val BaseLineComponent = FC<BaseLineComponentProps> { props ->
    var saving by useState(false)
    var activeLabel by useState(BaseSectionLabels.USAGE)
    var baselineStart by useState(props.baselineStartMonth)

    useEffect(props.baselineStatus, props.baselineStartMonth) {
        baselineStart = props.baselineStartMonth
    }

    var innerWidth by useState(window.innerWidth - 80)

    useEffect {
        kotlinx.browser.window.addEventListener("resize", {
            innerWidth = kotlinx.browser.window.innerWidth - 80
        }, null)
    }

    var point = BrushPoint()

    val buttonLabel = if (!props.isSecondary) {
        "Update Baseline"
    } else {
        "Save Baseline"
    }
    val buttonLabelDuringApiCall = if (!props.isSecondary) {
        "Updating Baseline..."
    } else {
        "Saving Baseline..."
    }
    val savedStartMonth = moment(baselineStart, "MMM YYYY").format("MMMM YYYY")
    val savedEndMonth = moment(baselineStart, "MMM YYYY").add(11, "months").format("MMMM YYYY")

    Modal {
        open = props.isBaselineOpen
        onClose = { _, _ ->
            props.closeBaseline()
        }
        Box {
            className = styles.BASE_LINE_MODAL_CONTAINER.cssClass
            Box {
                className = styles.BASE_LINE_MODAL_CONTENT.cssClass
                sx {
                    backgroundColor = Color(Colors.MAIN_BLUE)
                }
                IconButton {
                    className = styles.BASE_LINE_CLOSE_ICON.cssClass
                    onClick = {
                        props.closeBaseline()
                    }
                    Close()
                }
                Typography {
                    sx {
                        textAlign = TextAlign.center
                        fontWeight = FontWeight.bold
                    }
                    variant = TypographyVariant.h5
                    +"Select Baseline"
                }

                Typography {
                    sx {
                        textAlign = TextAlign.center
                    }
                    if(props.baselineStatus == BaselineSelectionStatus.SELECTED) {
                        +"(Saved Baseline: $savedStartMonth to ${savedEndMonth})"
                    } else {
                        +"(Saved Baseline: Not selected/saved yet)"
                    }
                }


                Box {
                    sx {
                        display = Display.flex
                        justifyContent = JustifyContent.flexEnd
                    }
                    RadioGroup {
                        sx {
                            flexDirection = FlexDirection.row
                        }
                        value = activeLabel.value
                        onChange = { _, value ->
                            when (value) {
                                BaseSectionLabels.USAGE.value -> {
                                    activeLabel = BaseSectionLabels.USAGE
                                }

                                else -> {
                                    activeLabel = BaseSectionLabels.COST
                                }
                            }
                        }
                        for (radioLabel in BaseSectionLabels.values()) {
                            FormControlLabel {
                                value = radioLabel.value
                                control = Radio.create()
                                label = ReactNode(radioLabel.label)
                            }
                        }
                    }
                }
                Box {
                    ApiLazyRender {
                        dataFetched = true
                        BarChart {
                            width = innerWidth
                            height = 400
                            data = props.data
                            CartesianGrid {
                                stroke = ecosaveGrayAccent.toString()
                            }
                            XAxis {
                                dataKey = "billingPeriod"
                                height = 50
                                tick = jso {
                                    fill = Colors.WHITE
                                }
                            }
                            YAxis {
                                type = "number"
                                tickCount = 10
                                width = 90
                                tickFormatter = ::formatTick
                                tick = jso {
                                    fill = Colors.WHITE
                                }
                            }
                            TTip {
                                content = BaselineTooltipContent
                                labelStyle = jso {
                                    color = Colors.BLACK
                                }
                            }
                            Bar {
                                dataKey = activeLabel.value
                                fill = Colors.LIGHT_CYAN_BLUE
                            }
                            Brush {
                                data = props.data
                                width = innerWidth - 100
                                height = 80
                                startIndex = 0
                                endIndex = 11
                                alwaysShowText = true
                                dataKey = "billingPeriod"
                                stroke = Themes.ecosaveOrangeAccent.toString()
                                travellerWidth = 0
                                traveller = ::renderTraveller
                                tickFormatter = { value, index ->
                                    if (point.index + 11 != index) {
                                        point = BrushPoint(index, value)
                                    }
                                    value
                                }
                                BarChart {
                                    data = props.data
                                    Bar {
                                        background = Colors.GOLD_YELLOW
                                        dataKey = activeLabel.value
                                        fill = Colors.VIBRANT_GREEN

                                    }
                                }
                            }
                        }
                    }
                }
                Box {
                    sx {
                        marginTop = 30.px
                        display = Display.flex
                        justifyContent = JustifyContent.center
                    }
                    Button {
                        variant = ButtonVariant.contained
                        disabled = saving
                        onClick = {
                            mainScope.launch {
                                saving = true
                                val baselineStartMonth = moment(point.value, "MMM YYYY").format("MMMM")
                                val baselineStartYear = moment(point.value, "MMM YYYY").format("YYYY")
                                val response =
                                    saveBaseline("facilityId=${props.facilityId}&baselineStartMonth=${baselineStartMonth}&baselineStartYear=${baselineStartYear}")
                                if (response === ApiCallStatus.FAILURE) {
                                    props.notify(
                                        props.notification.copy(
                                            status = NotificationStatus.ERROR,
                                            message = Constants.NOTIFICATION_ERROR_MESSAGE,
                                            visible = true
                                        )
                                    )
                                } else {
                                    props.closeBaseline()
                                    baselineStart = "$baselineStartMonth $baselineStartYear"
                                    props.refetchBaseline()
                                    props.notify(
                                        props.notification.copy(
                                            status = NotificationStatus.SUCCESS,
                                            message = "Baseline Submitted Successfully",
                                            visible = true
                                        )
                                    )
                                }
                                saving = false
                            }
                        }
                        if (saving) +buttonLabelDuringApiCall else +buttonLabel
                    }
                }
            }
        }
    }
}