package com.ecosave.watch.portal.components.utilitybills

import com.ecosave.watch.portal.helpers.billing.BillingHeaders
import com.ecosave.watch.portal.helpers.billing.BillingWaterHeaders
import com.ecosave.watch.portal.helpers.billing.UtilityOtherUnits
import com.ecosave.watch.portal.helpers.billing.UtilityType
import com.ecosave.watch.portal.helpers.common.YesNoEnum
import com.ecosave.watch.portal.helpers.billing.formatBillingValues
import com.ecosave.watch.portal.helpers.billing.formatWaterBillingLabels
import com.ecosave.watch.portal.helpers.billing.validateBioChemicalOxygenDemand
import com.ecosave.watch.portal.helpers.billing.validateBodCharges
import com.ecosave.watch.portal.helpers.billing.validateBodSurchargeFactor
import com.ecosave.watch.portal.helpers.billing.validateFlow
import com.ecosave.watch.portal.helpers.billing.validateFlowUsedForBilling
import com.ecosave.watch.portal.helpers.billing.validateIndustrialSurcharge
import com.ecosave.watch.portal.helpers.billing.validateServiceCharges
import com.ecosave.watch.portal.helpers.billing.validateSrf
import com.ecosave.watch.portal.helpers.billing.validateTotalSurchargeBill
import com.ecosave.watch.portal.helpers.billing.validateTotalSuspendedSolids
import com.ecosave.watch.portal.helpers.billing.validateTssCharges
import com.ecosave.watch.portal.helpers.billing.validateTssSurchargeFactor
import com.ecosave.watch.portal.models.billing.UtilityBillSummary
import com.ecosave.watch.portal.styles.BillingTableStyles
import com.ecosave.watch.portal.models.billing.utilitybillstate.UtilityBillState
import com.ecosave.watch.portal.models.billing.UtilityBillValidationState
import mui.material.TableCell
import mui.material.TableRow
import react.FC
import react.Props
import react.StateSetter
import react.dom.onChange
import web.html.HTMLInputElement
import web.html.InputType

external interface WaterDataRowProps : Props {
    var editModeWater: (Boolean)
    var editRowWater: Boolean
    var stateWater: UtilityBillState
    var validationWater: UtilityBillValidationState
    var billWater: UtilityBillSummary
    var setStateWater: StateSetter<UtilityBillState>
    var setValidationWater: StateSetter<UtilityBillValidationState>
}

val WaterDataRow = FC<WaterDataRowProps> { props ->

    val editMode = props.editModeWater
    val editRow = props.editRowWater
    val state = props.stateWater
    val setState = props.setStateWater
    val validation = props.validationWater
    val setValidation = props.setValidationWater
    val bill = props.billWater

    TableRow {
        TableCell {
            className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
            +formatWaterBillingLabels(BillingWaterHeaders.SERVICE_CHARGES, editMode)
        }

        TableCell {
            className = BillingTableStyles.TABLE_CELL.cssClass
            if (editRow) {
                EditableTextFieldWrapper {
                    type = InputType.number
                    value = state.waterBillAdditional.serviceCharges
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setState(
                            state.copy(
                                waterBillAdditional = state.waterBillAdditional.copy(
                                    serviceCharges = target.value.toFloatOrNull()
                                )
                            )
                        )
                    }
                    error =
                        validation.waterBillValidationState.serviceChargesErrorState
                    helperText =
                        validation.waterBillValidationState.serviceChargesErrorMessage
                    onBlur = {
                        validateServiceCharges(state, validation, setValidation)
                    }
                }
            } else {
                +formatBillingValues(
                    bill.utilityBill.waterBillAdditional.serviceCharges,
                    null
                )
            }
        }

        TableCell {
            className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
            +formatWaterBillingLabels(BillingWaterHeaders.STORMWATER_CHARGES, editMode)
        }

        TableCell {
            className = BillingTableStyles.TABLE_CELL.cssClass
            if (editRow) {
                EditableTextFieldWrapper {
                    type = InputType.number
                    value = state.waterBillAdditional.stormWaterCharges
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setState(
                            state.copy(
                                waterBillAdditional = state.waterBillAdditional.copy(
                                    stormWaterCharges = target.value.toFloatOrNull()
                                )
                            )
                        )
                    }
                    error =
                        validation.waterBillValidationState.stormWaterChargesErrorState
                    helperText =
                        validation.waterBillValidationState.stormWaterChargesErrorMessage
                    onBlur = {
                        validateServiceCharges(state, validation, setValidation)
                    }
                }
            } else {
                +formatBillingValues(
                    bill.utilityBill.waterBillAdditional.serviceCharges,
                    null
                )
            }
        }

        TableCell {
            className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
            +BillingHeaders.BILL_ESTIMATED.header
        }

        TableCell {
            className = BillingTableStyles.TABLE_CELL.cssClass
            if (editRow) {
                BillEstimated {
                    stateBillEstimated = state
                    setStateBillEstimated = setState
                }
            } else {
                if (bill.utilityBill.billEstimated == true) +YesNoEnum.YES.description else +YesNoEnum.NO.description
            }
        }

    }

    TableRow {
        TableCell {
            className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
            +BillingHeaders.BILL_ADJUSTED.header
        }

        TableCell {
            className = BillingTableStyles.TABLE_CELL.cssClass
            if (editRow) {
                BillAdjusted {
                    stateBillAdjusted = state
                    setStateBillAdjusted = setState
                }
            } else {
                if (bill.utilityBill.billAdjusted == true) +YesNoEnum.YES.description else +YesNoEnum.NO.description
            }
        }

        if (bill.surchargeBillExists == true) {
            TableCell {
                className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
                +formatWaterBillingLabels(BillingWaterHeaders.INDUSTRIAL_SURCHARGE, editMode)
            }

            TableCell {
                className = BillingTableStyles.TABLE_CELL.cssClass
                if (editRow) {
                    EditableTextFieldWrapper {
                        type = InputType.number
                        value = state.waterBillAdditional.industrialSurcharge
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setState(
                                state.copy(
                                    waterBillAdditional = state.waterBillAdditional.copy(
                                        industrialSurcharge = target.value.toFloatOrNull()
                                    )
                                )
                            )
                        }
                        error =
                            validation.waterBillValidationState.industrialSurchargeErrorState
                        helperText =
                            validation.waterBillValidationState.industrialSurchargeErrorMessage
                        onBlur = {
                            validateIndustrialSurcharge(state, validation, setValidation)
                        }
                    }
                } else {
                    +formatBillingValues(
                        bill.utilityBill.waterBillAdditional.serviceCharges,
                        null
                    )
                }
            }

            TableCell {
                className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
                +BillingWaterHeaders.FLOW.header
            }

            TableCell {
                className = BillingTableStyles.TABLE_CELL.cssClass
                if (editRow) {
                    EditableTextFieldWrapper {
                        type = InputType.number
                        value = state.waterBillAdditional.flow
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setState(
                                state.copy(
                                    waterBillAdditional = state.waterBillAdditional.copy(
                                        flow = target.value.toFloatOrNull()
                                    )
                                )
                            )
                        }
                        error =
                            validation.waterBillValidationState.flowErrorState
                        helperText =
                            validation.waterBillValidationState.flowErrorMessage
                        onBlur = {
                            validateFlow(state, validation, setValidation)
                        }
                    }
                } else {
                    +formatBillingValues(
                        bill.utilityBill.waterBillAdditional.serviceCharges,
                        bill.utilityBill.usageUnit.description
                    )
                }
            }
        }
    }

    if (bill.surchargeBillExists == true) {
        TableRow {
            TableCell {
                className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
                +BillingWaterHeaders.SRF.header
            }

            TableCell {
                className = BillingTableStyles.TABLE_CELL.cssClass
                if (editRow) {
                    EditableTextFieldWrapper {
                        type = InputType.number
                        value = state.waterBillAdditional.srf
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setState(
                                state.copy(
                                    waterBillAdditional = state.waterBillAdditional.copy(
                                        srf = target.value.toFloatOrNull()
                                    )
                                )
                            )
                        }
                        error =
                            validation.waterBillValidationState.srfErrorState
                        helperText =
                            validation.waterBillValidationState.srfErrorMessage
                        onBlur = {
                            validateSrf(state, validation, setValidation)
                        }
                    }
                } else {
                    if (bill.utilityBill.waterBillAdditional.srf == null) +"" else +bill.utilityBill.waterBillAdditional.srf.toString()
                }
            }

            TableCell {
                className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
                +BillingWaterHeaders.FLOW_USED_FOR_BILLING.header
            }

            TableCell {
                className = BillingTableStyles.TABLE_CELL.cssClass
                if (editRow) {
                    EditableTextFieldWrapper {
                        type = InputType.number
                        value = state.waterBillAdditional.flowUsedForBilling
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setState(
                                state.copy(
                                    waterBillAdditional = state.waterBillAdditional.copy(
                                        flowUsedForBilling = target.value.toFloatOrNull()
                                    )
                                )
                            )
                        }
                        error =
                            validation.waterBillValidationState.flowUsedForBillingErrorState
                        helperText =
                            validation.waterBillValidationState.flowUsedForBillingErrorMessage
                        onBlur = {
                            validateFlowUsedForBilling(state, validation, setValidation)
                        }
                    }
                } else {
                    +formatBillingValues(
                        bill.utilityBill.waterBillAdditional.serviceCharges,
                        bill.utilityBill.usageUnit.description
                    )
                }
            }

            TableCell {
                className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
                +BillingWaterHeaders.BIO_CHEMICAL_OXYGEN_DEMAND.header
            }

            TableCell {
                className = BillingTableStyles.TABLE_CELL.cssClass
                if (editRow) {
                    EditableTextFieldWrapper {
                        type = InputType.number
                        value = state.waterBillAdditional.biochemicalOxygenDemand
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setState(
                                state.copy(
                                    waterBillAdditional = state.waterBillAdditional.copy(
                                        biochemicalOxygenDemand = target.value.toFloatOrNull()
                                    )
                                )
                            )
                        }
                        error =
                            validation.waterBillValidationState.bioChemicalOxygenDemandErrorState
                        helperText =
                            validation.waterBillValidationState.bioChemicalOxygenDemandErrorMessage
                        onBlur = {
                            validateBioChemicalOxygenDemand(state, validation, setValidation)
                        }
                    }
                } else {
                    +formatBillingValues(
                        bill.utilityBill.waterBillAdditional.biochemicalOxygenDemand,
                        UtilityOtherUnits.MG_L.description
                    )
                }
            }
        }

        TableRow {
            TableCell {
                className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
                +BillingWaterHeaders.BOD_SURCHARGE_FACTOR.header
            }

            TableCell {
                className = BillingTableStyles.TABLE_CELL.cssClass
                if (editRow) {
                    EditableTextFieldWrapper {
                        type = InputType.number
                        value = state.waterBillAdditional.bodSurchargeFactor
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setState(
                                state.copy(
                                    waterBillAdditional = state.waterBillAdditional.copy(
                                        bodSurchargeFactor = target.value.toFloatOrNull()
                                    )
                                )
                            )
                        }
                        error =
                            validation.waterBillValidationState.bodSurchargeFactorErrorState
                        helperText =
                            validation.waterBillValidationState.bodSurchargeFactorErrorMessage
                        onBlur = {
                            validateBodSurchargeFactor(state, validation, setValidation)
                        }
                    }
                } else {
                    if (bill.utilityBill.waterBillAdditional.bodSurchargeFactor == null) +"" else
                        +bill.utilityBill.waterBillAdditional.bodSurchargeFactor.toString()
                }
            }

            TableCell {
                className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
                +formatWaterBillingLabels(BillingWaterHeaders.BOD_CHARGES, editMode)
            }

            TableCell {
                className = BillingTableStyles.TABLE_CELL.cssClass
                if (editRow) {
                    EditableTextFieldWrapper {
                        type = InputType.number
                        value = state.waterBillAdditional.bodCharges
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setState(
                                state.copy(
                                    waterBillAdditional = state.waterBillAdditional.copy(
                                        bodCharges = target.value.toFloatOrNull()
                                    )
                                )
                            )
                        }
                        error =
                            validation.waterBillValidationState.bodChargesErrorState
                        helperText =
                            validation.waterBillValidationState.bodChargesErrorMessage
                        onBlur = {
                            validateBodCharges(state, validation, setValidation)
                        }
                    }
                } else {
                    +formatBillingValues(
                        bill.utilityBill.waterBillAdditional.bodCharges,
                        null
                    )
                }
            }

            TableCell {
                className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
                +BillingWaterHeaders.TOTAL_SUSPENDED_SOLIDS.header
            }

            TableCell {
                className = BillingTableStyles.TABLE_CELL.cssClass
                if (editRow) {
                    EditableTextFieldWrapper {
                        type = InputType.number
                        value = state.waterBillAdditional.totalSuspendedSolids
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setState(
                                state.copy(
                                    waterBillAdditional = state.waterBillAdditional.copy(
                                        totalSuspendedSolids = target.value.toFloatOrNull()
                                    )
                                )
                            )
                        }
                        error =
                            validation.waterBillValidationState.totalSuspendedSolidsErrorState
                        helperText =
                            validation.waterBillValidationState.totalSuspendedSolidsErrorMessage
                        onBlur = {
                            validateTotalSuspendedSolids(state, validation, setValidation)
                        }
                    }
                } else {
                    +formatBillingValues(
                        bill.utilityBill.waterBillAdditional.totalSuspendedSolids,
                        UtilityOtherUnits.MG_L.description
                    )
                }
            }
        }

        TableRow {
            TableCell {
                className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
                +BillingWaterHeaders.TSS_SURCHARGE_FACTOR.header
            }

            TableCell {
                className = BillingTableStyles.TABLE_CELL.cssClass
                if (editRow) {
                    EditableTextFieldWrapper {
                        type = InputType.number
                        value = state.waterBillAdditional.tssSurchargeFactor
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setState(
                                state.copy(
                                    waterBillAdditional = state.waterBillAdditional.copy(
                                        tssSurchargeFactor = target.value.toFloatOrNull()
                                    )
                                )
                            )
                        }
                        error =
                            validation.waterBillValidationState.tssSurchargeFactorErrorState
                        helperText =
                            validation.waterBillValidationState.tssSurchargeFactorErrorMessage
                        onBlur = {
                            validateTssSurchargeFactor(state, validation, setValidation)
                        }
                    }
                } else {
                    if (bill.utilityBill.waterBillAdditional.tssSurchargeFactor == null) +"" else
                        +bill.utilityBill.waterBillAdditional.tssSurchargeFactor.toString()
                }
            }

            TableCell {
                className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
                +formatWaterBillingLabels(BillingWaterHeaders.TSS_CHARGES, editMode)
            }

            TableCell {
                className = BillingTableStyles.TABLE_CELL.cssClass
                if (editRow) {
                    EditableTextFieldWrapper {
                        type = InputType.number
                        value = state.waterBillAdditional.tssCharges
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setState(
                                state.copy(
                                    waterBillAdditional = state.waterBillAdditional.copy(
                                        tssCharges = target.value.toFloatOrNull()
                                    )
                                )
                            )
                        }
                        error =
                            validation.waterBillValidationState.tssChargesErrorState
                        helperText =
                            validation.waterBillValidationState.tssChargesErrorMessage
                        onBlur = {
                            validateTssCharges(state, validation, setValidation)
                        }
                    }
                } else {
                    +formatBillingValues(
                        bill.utilityBill.waterBillAdditional.tssCharges,
                        null
                    )
                }
            }

            TableCell {
                className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
                +formatWaterBillingLabels(BillingWaterHeaders.TOTAL_SURCHARGE_BILL, editMode)
            }

            TableCell {
                className = BillingTableStyles.TABLE_CELL.cssClass
                if (editRow) {
                    EditableTextFieldWrapper {
                        type = InputType.number
                        value = state.waterBillAdditional.totalSurchargeBill
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setState(
                                state.copy(
                                    waterBillAdditional = state.waterBillAdditional.copy(
                                        totalSurchargeBill = target.value.toFloatOrNull()
                                    )
                                )
                            )
                        }
                        error =
                            validation.waterBillValidationState.totalSurchargeBillErrorState
                        helperText =
                            validation.waterBillValidationState.totalSurchargeBillErrorMessage
                        onBlur = {
                            validateTotalSurchargeBill(state, validation, setValidation)
                        }
                    }
                } else {
                    +formatBillingValues(
                        bill.utilityBill.waterBillAdditional.totalSurchargeBill,
                        null
                    )
                }
            }
        }
    }

    if (editRow) {
        TableRow {
            UsageUnit {
                stateUsageUnit = state
                setStateUsageUnit = setState
                usageUnits = UtilityType.WATER.usageUnits
            }
        }
    }
}