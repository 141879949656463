package com.ecosave.watch.portal.components.recoverpassword

import com.ecosave.watch.portal.Themes
import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.components.common.GlobalMessage
import com.ecosave.watch.portal.components.common.Loading
import com.ecosave.watch.portal.helpers.Colors
import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.PageRoutes
import com.ecosave.watch.portal.helpers.usermanagement.TokenVerificationStatus
import com.ecosave.watch.portal.helpers.usermanagement.isPasswordValid
import com.ecosave.watch.portal.helpers.usermanagement.validateConfirmPassword
import com.ecosave.watch.portal.helpers.usermanagement.validatePassword
import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.models.recoverpassword.ChangePasswordState
import com.ecosave.watch.portal.models.recoverpassword.ChangePasswordValidationState
import com.ecosave.watch.portal.pages.mainScope
import com.ecosave.watch.portal.services.changePassword
import com.ecosave.watch.portal.services.verifyChangedPasswordToken
import com.ecosave.watch.portal.styles.RecoverPasswordStyles
import emotion.react.css
import io.ktor.http.*
import js.core.jso
import kotlinx.coroutines.launch
import mui.icons.material.Visibility
import mui.icons.material.VisibilityOff
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.FormControlVariant
import mui.material.IconButton
import mui.material.InputAdornment
import mui.material.InputAdornmentPosition
import mui.material.InputBaseProps
import mui.material.Paper
import mui.material.Size
import mui.material.TextField
import mui.material.Typography
import mui.material.TypographyAlign
import mui.material.styles.ThemeProvider
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.create
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.h6
import react.dom.html.ReactHTML.img
import react.dom.onChange
import react.router.useNavigate
import react.router.useParams
import react.useEffectOnce
import react.useState
import web.cssom.Color
import web.cssom.FontWeight
import web.cssom.Position
import web.cssom.em
import web.cssom.pct
import web.cssom.px
import web.html.HTMLInputElement
import web.html.InputType
import com.ecosave.watch.portal.styles.CommonStyles as commonStyles

val ChangePassword = FC<Props> {

    val (state, setState) = useState(ChangePasswordState())
    val (validation, setValidation) = useState(ChangePasswordValidationState())
    var disableConfirmPassword by useState(true)
    var showPassword by useState(false)
    var showConfirmPassword by useState(false)
    var isPasswordChanged by useState(false)
    val navigate = useNavigate()
    var isSubmitting by useState(false)
    var username by useState("")
    var tokenVerificationStatus by useState(TokenVerificationStatus.VERIFYING)
    val (notificationState, notificationStateSetter) = useState(NotificationState())
    var tokenExpiredAfterVerification by useState(false)
    val params = useParams()
    val token = params["token"]

    useEffectOnce {
        mainScope.launch {
            val response = verifyChangedPasswordToken(token)
            if (response.tokenVerificationStatus == TokenVerificationStatus.VERIFIED) {
                username = response.userName
            }
            tokenVerificationStatus = response.tokenVerificationStatus
        }
    }

    when (tokenVerificationStatus) {
        TokenVerificationStatus.VERIFYING -> Loading()
        TokenVerificationStatus.VERIFIED -> {
            Box {
                className = RecoverPasswordStyles.CONTAINER.cssClass
                ThemeProvider {
                    theme = Themes.lightTheme
                    Paper {
                        className = RecoverPasswordStyles.CENTER.cssClass
                        sx {
                            position = Position.relative
                            minHeight = if (isPasswordChanged) {
                                50.pct
                            } else {
                                70.pct
                            }
                        }
                        img {
                            css {
                                width = 100.pct
                            }
                            src = "/images/logo/company-logo-dark.jpg"
                        }
                        Box {
                            className = RecoverPasswordStyles.FORM_SECTION.cssClass
                            Box {
                                sx {
                                    marginTop = 15.px
                                }
                                Typography {
                                    align = TypographyAlign.center
                                    className = commonStyles.HEADING_TITLE.cssClass
                                    variant = TypographyVariant.h5
                                    +"Change password for"
                                }
                                Typography {
                                    className = commonStyles.HEADING_TITLE.cssClass
                                    variant = TypographyVariant.h5
                                    align = TypographyAlign.center
                                    +username
                                }
                            }
                            if (!isPasswordChanged) {
                                TextField {
                                    className = RecoverPasswordStyles.INPUT.cssClass
                                    type = if (showPassword) InputType.text else InputType.password
                                    variant = FormControlVariant.outlined
                                    fullWidth = true
                                    label = ReactNode("Password *")
                                    asDynamic().InputProps = jso<InputBaseProps> {
                                        endAdornment = InputAdornment.create {
                                            position = InputAdornmentPosition.end
                                            IconButton {
                                                onClick = {
                                                    showPassword = !showPassword
                                                }
                                                if (showPassword) Visibility() else VisibilityOff()
                                            }
                                        }
                                    }
                                    value = state.password
                                    onChange = {
                                        val target = it.target as HTMLInputElement
                                        val value = target.value
                                        if (value.isBlank()) {
                                            disableConfirmPassword = true
                                            showPassword = false
                                            showConfirmPassword = false
                                            setState(
                                                state.copy(
                                                    password = value,
                                                    confirmPassword = ""
                                                )
                                            )
                                            setValidation(
                                                validation.copy(
                                                    confirmPasswordErrorState = false,
                                                    confirmPasswordErrorMessage = null
                                                )
                                            )
                                        } else {
                                            disableConfirmPassword = false
                                            setState(
                                                state.copy(
                                                    password = value,
                                                )
                                            )
                                        }
                                    }
                                    error = validation.passwordErrorState
                                    helperText = validation.passwordErrorMessage
                                    onBlur = {
                                        validatePassword(state, validation, setValidation)
                                    }
                                }
                                TextField {
                                    className = RecoverPasswordStyles.INPUT.cssClass
                                    disabled = disableConfirmPassword || validation.passwordErrorState
                                    type = if (showConfirmPassword) InputType.text else InputType.password
                                    variant = FormControlVariant.outlined
                                    fullWidth = true
                                    label = ReactNode("Confirm Password *")
                                    value = state.confirmPassword
                                    asDynamic().InputProps = jso<InputBaseProps> {
                                        endAdornment = InputAdornment.create {
                                            position = InputAdornmentPosition.end
                                            IconButton {
                                                disabled = disableConfirmPassword || validation.passwordErrorState
                                                onClick = {
                                                    showConfirmPassword = !showConfirmPassword
                                                }
                                                if (showConfirmPassword) Visibility() else VisibilityOff()
                                            }
                                        }
                                    }
                                    onChange = {
                                        val target = it.target as HTMLInputElement
                                        setState(
                                            state.copy(
                                                confirmPassword = target.value
                                            )
                                        )
                                    }
                                    error = validation.confirmPasswordErrorState
                                    helperText = validation.confirmPasswordErrorMessage
                                    onBlur = {
                                        validateConfirmPassword(state, validation, setValidation)
                                    }
                                }
                                Typography {
                                    sx {
                                        fontSize = 1.em
                                        fontWeight = FontWeight.bold
                                        color = Color(Colors.PINKISH_RED)
                                    }
                                    variant = TypographyVariant.h6
                                    component = h6
                                    +"Make sure password is at least 8 characters including a number, a capital letter and a special character."
                                }
                                Button {
                                    className = RecoverPasswordStyles.BUTTON.cssClass
                                    variant = ButtonVariant.contained
                                    fullWidth = true
                                    size = Size.large
                                    disabled =
                                        validation.passwordErrorState || validation.confirmPasswordErrorState ||
                                                state.password.isBlank() || state.confirmPassword.isBlank() || isSubmitting
                                    onClick = {
                                        if (isPasswordValid(state, validation, setValidation)) {
                                            mainScope.launch {
                                                isSubmitting = true
                                                when (changePassword(token ?: "", state.password)) {
                                                    null -> {
                                                        notificationStateSetter(
                                                            notificationState.copy(
                                                                status = NotificationStatus.ERROR,
                                                                visible = true,
                                                                message = Constants.NOTIFICATION_ERROR_MESSAGE
                                                            )
                                                        )
                                                    }

                                                    HttpStatusCode.Created -> {
                                                        isPasswordChanged = true
                                                    }

                                                    else -> {
                                                        tokenExpiredAfterVerification = true
                                                    tokenVerificationStatus = TokenVerificationStatus.INVALID_OR_EXPIRED
                                                    }
                                                }
                                                isSubmitting = false
                                            }
                                        }
                                    }
                                    if (isSubmitting) +"Changing Password..." else +"Change Password"
                                }
                            } else {
                                Typography {
                                    className = commonStyles.SUBTITLE.cssClass
                                    variant = TypographyVariant.h6
                                    component = ReactHTML.h4
                                    sx {
                                        marginLeft = 20.px
                                    }
                                    +"Congratulations! Your password has been changed successfully."
                                }
                                Button {
                                    className = RecoverPasswordStyles.BUTTON.cssClass
                                    variant = ButtonVariant.contained
                                    fullWidth = true
                                    size = Size.large
                                    onClick = {
                                        navigate(PageRoutes.SIGN_IN.route)
                                    }
                                    +"Return to sign in"
                                }
                            }
                        }
                    }
                }
            }
        }

        TokenVerificationStatus.INVALID_OR_EXPIRED -> {
            GlobalMessage {
                title = "Oops!"
                message =
                    if (tokenExpiredAfterVerification) "It seems the session has expired." else "It seems the link you followed is either invalid or has expired."
                showNavigationButton = true
                navigationRoute = "/recover-password"
                navigationButtonText = "Click here to generate new password reset link"
            }
        }

        TokenVerificationStatus.EXCEPTION -> {
            GlobalMessage {
                title = "Oops!"
                message = "An unexpected exception has occurred, please retry."
            }
        }

        else -> {
            GlobalMessage {
                title = "Oops!"
                message = "An unexpected exception has occurred, please retry."
            }
        }
    }
    AlertNotifications {
        open = notificationState.visible
        status = notificationState.status
        message = notificationState.message
        closeNotification = {
            notificationStateSetter(
                notificationState.copy(
                    visible = false
                )
            )
        }
    }
}