package com.ecosave.watch.portal.services.facilitymanagement

import com.ecosave.watch.portal.helpers.BackendServerURL
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.models.facilitymanagement.EnergyStarCandidateFacility
import com.ecosave.watch.portal.models.facilitymanagement.EnergyStarSelectedCandidateFacility
import com.ecosave.watch.portal.services.httpClient
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*

suspend fun getFacilitiesToImport(): MutableList<EnergyStarCandidateFacility>? {
    try {
        val response: HttpResponse = httpClient.get("$BackendServerURL/facility/energy-star-candidates")
        if (response.status == HttpStatusCode.OK) {
            return response.body()
        }
    } catch (e: dynamic) {
        console.log(e)
    }
    return null
}

suspend fun importFacilities(requestBody: MutableList<EnergyStarSelectedCandidateFacility>): ApiCallStatus {
    try {
        val response: HttpResponse = httpClient.post("$BackendServerURL/facility/energy-star-candidates") {
            setBody(requestBody)
        }
        if (response.status == HttpStatusCode.Accepted) {
           return ApiCallStatus.SUCCESS
        }
    } catch (e: dynamic) {
        console.log("Error: ", e)
    }
    return ApiCallStatus.FAILURE
}