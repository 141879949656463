package com.ecosave.watch.portal.components.esg.generaldisclosures.stakeholderengagement

import com.ecosave.watch.portal.components.common.TableHeader
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.system.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val Disclosure_2_30 = FC<EsgSubFormComponentProps> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea
    val onChangeNumber = props.onChangeNumber
    val onBlurNumber = props.onBlurNumber
    val onChangeDecimalNumber = props.onChangeDecimalNumber
    val onBlurDecimalNumber = props.onBlurDecimalNumber

    props.esgReportState.report?.stakeholderEngagement?.let { state ->

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_2_30
        }

        Box {
            className = ESGCommonStyles.TWO_COLUMN_TABLE_8_4.cssClass
            label {
                +""
            }
            TableHeader {
                tableHeaderName = "Current Year"
            }
            label {
                +"Total Employees"
            }
            ESGNumberFieldWrapper {
                name = state::section230A1.name
                value = state.section230A1 ?: ""
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            label {
                +"Employees covered by collective bargaining agreements"
            }
            ESGNumberFieldWrapper {
                name = state::section230A2.name
                value = state.section230A2 ?: ""
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            label {
                +"Percent of total employees covered by CBA’s"
            }
            ESGNumberFieldWrapper {
                name = state::section230A3.name
                value = state.section230A3 ?: ""
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }
        }

        Box {
            label {
                +"For employees not covered by collective bargaining agreements, report whether the organization determines their working conditions and terms of employment based on collective bargaining agreements that cover its other employees or based on collective bargaining agreements from other organizations."
            }
            ESGTextAreaFieldWrapper {
                name = state::section230Field1.name
                value = state.section230Field1 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}