package com.ecosave.watch.portal.components.privacypolicy

import react.FC
import react.Props

val DisclaimerOfWarranty = FC<Props> {
    HeadingWrapper {
        heading = "14. Disclaimer of Warranty"
    }
    TypographyWrapper {
        +"You acknowledge and agree that, except as expressly provided herein, the Service provided by Ecosave is provided \"as is\" and without any warranty of merchantability. To the fullest extent permitted by law, Ecosave expressly disclaims any and all warranties, whether express or implied, including but not limited to the implied warranties of merchantability, with respect to the services provided under this Agreement."
    }
    TypographyWrapper {
        +"Further you acknowledge and agree that the Service provided by the Ecosave may not meet the specific requirements or purposes for which you intend to use them."
    }
    TypographyWrapper {
        +"Ecosave expressly disclaims all warranties, whether express or implied, including but not limited to the implied warranties of fitness for a particular purpose, with respect to the Service provided under this Agreement, except as expressly stated herein."
    }
    TypographyWrapper {
        +"You acknowledge and agree that the Service provided by Ecosave is not warranted to be commercially viable or to integrate seamlessly into your business operations without any disruption."
    }
    TypographyWrapper {
        +"Ecosave expressly disclaims all warranties, whether express or implied, including but not limited to any warranties of commercial viability or success, with respect to the services provided under this Agreement."
    }
    TypographyWrapper {
        +"You acknowledge and agree that any reliance on the features and functionalities of the services provided by Ecosave is at your own risk."
    }
    TypographyWrapper {
        +"Ecosave expressly disclaims all warranties, whether express or implied, regarding the performance, reliability, or availability of any features, illustrative calculations or functionalities of the Service provided under this Agreement."
    }
    TypographyWrapper {
        +"Except as expressly provided in this Agreement, Ecosave makes no warranties, express or implied, regarding any matter, including but not limited to the condition, quality, durability, performance, or suitability of the services for your purposes."
    }
}
