package com.ecosave.watch.portal.components.utilityaccounts

import com.ecosave.watch.portal.helpers.billing.UtilityAccountFields
import com.ecosave.watch.portal.helpers.billing.validatePassword
import com.ecosave.watch.portal.helpers.billing.validateUserId
import com.ecosave.watch.portal.helpers.common.sm
import com.ecosave.watch.portal.helpers.common.xs
import com.ecosave.watch.portal.models.billing.UtilityAccountState
import com.ecosave.watch.portal.models.billing.UtilityAccountValidationState
import mui.material.Grid
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.StateSetter
import react.dom.html.ReactHTML
import react.dom.onChange
import web.cssom.Margin
import web.cssom.px
import web.html.HTMLInputElement
import web.html.InputType

external interface AutomaticDataCollectionRequiredFieldsProps : Props {
    var formState: UtilityAccountState
    var setFormState: StateSetter<UtilityAccountState>
    var formValidationState: UtilityAccountValidationState
    var setFormValidationState: StateSetter<UtilityAccountValidationState>
}

val AutomaticDataCollectionRequiredFields = FC<AutomaticDataCollectionRequiredFieldsProps> { props ->

    val formState = props.formState
    val setFormState = props.setFormState
    val formValidationState = props.formValidationState
    val setFormValidationState = props.setFormValidationState


    Grid {
        container = true
        Grid {
            item = true
            xs = 12
            Typography {
                sx {
                    margin = Margin(0.px, 20.px)
                }
                component = ReactHTML.h6
                variant = TypographyVariant.h6
                +"Utility Service Provider Credentials"
            }
        }
        Grid {
            item = true
            xs = 12
            sm = 6
            UtilityAccountTextFieldWrapper {
                label = ReactNode(UtilityAccountFields.USER_ID.field)
                type = InputType.text
                value = formState.userId
                error = formValidationState.userIdErrorState
                helperText = formValidationState.userIdErrorMessage
                onChange = {
                    val target = it.target as HTMLInputElement
                    setFormState(
                        formState.copy(
                            userId = target.value
                        )
                    )
                }
                onBlur = {
                    validateUserId(
                        formState,
                        formValidationState,
                        setFormValidationState
                    )
                }
            }
        }

        Grid {
            item = true
            xs = 12
            sm = 6
            UtilityAccountTextFieldWrapper {
                label = ReactNode(UtilityAccountFields.PASSWORD.field)
                type = InputType.text
                value = formState.password
                error = formValidationState.passwordErrorState
                helperText = formValidationState.passwordErrorMessage
                onChange = {
                    val target = it.target as HTMLInputElement
                    setFormState(
                        formState.copy(
                            password = target.value
                        )
                    )
                }
                onBlur = {
                    validatePassword(
                        formState,
                        formValidationState,
                        setFormValidationState
                    )
                }
            }
        }
    }
}