package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class AntiCorruptionState(
    var section2051A1: Long? = null,
    var section2051A2: Double? = null,
    var section2051A3: String? = null,

    var communicationAndTrainingList: MutableList<CommunicationAndTraining> = mutableListOf(),
    var employeeCategoryList: MutableList<EmployeeCategory> = mutableListOf(),
    var businessPartnerTypeList: MutableList<BusinessPartnerType> = mutableListOf(),
    var section2052Part4Field1: String? = null,
    var governanceBodyTrainingList: MutableList<GovernanceBodyTraining> = mutableListOf(),
    var employeeCategoryTrainingList: MutableList<EmployeeCategoryTraining> = mutableListOf(),

    var section2053A1: String? = null,
    var section2053A2: Long? = null,
    var section2053A3: Long? = null,
    var legalCasesOutcomesList: MutableList<LegalCasesOutcomes> = mutableListOf(),

    var section3_3_205A1: String? = null,
    var section3_3_205A2: String? = null,
    var section3_3_205A3: String? = null,
    var section3_3_205A4: String? = null,
    var section3_3_205A5: String? = null,
    var section3_3_205A6: String? = null
)

@Serializable
data class CommunicationAndTraining(
    var section2052Part2A1: String? = null,
    var section2052Part2B1: Long? = null,
    var section2052Part2C1: Double? = null,
) : DynamicTableItemKey()

@Serializable
data class EmployeeCategory(
    var section2052Part3A1: String? = null,
    var section2052Part3B1: String? = null,
    var section2052Part3C1: Long? = null,
    var section2052Part3D1: Double? = null,
) : DynamicTableItemKey()

@Serializable
data class BusinessPartnerType(
    var section2052Part4A1: String? = null,
    var section2052Part4B1: String? = null,
    var section2052Part4C1: Long? = null,
    var section2052Part4D1: Double? = null,
) : DynamicTableItemKey()

@Serializable
data class GovernanceBodyTraining(
    var section2052Part5A1: String? = null,
    var section2052Part5B1: Long? = null,
    var section2052Part5C1: Double? = null,
) : DynamicTableItemKey()

@Serializable
data class EmployeeCategoryTraining(
    var section2052Part6A1: String? = null,
    var section2052Part6B1: String? = null,
    var section2052Part6C1: Long? = null,
    var section2052Part6D1: Double? = null,
) : DynamicTableItemKey()

@Serializable
data class LegalCasesOutcomes(
    var section2053Case1: String? = null,
    var section2053Outcome1: String? = null,
) : DynamicTableItemKey()