package com.ecosave.watch.portal.services

import com.ecosave.watch.portal.helpers.BackendServerURL
import com.ecosave.watch.portal.helpers.usermanagement.TokenVerificationStatus
import com.ecosave.watch.portal.models.recoverpassword.TokenVerificationApiResponse
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import js.uri.encodeURIComponent

suspend fun sendPasswordResetEmail(
    email: String
): HttpStatusCode? {
    try {
        val response: HttpResponse = nonAuthClient.post("$BackendServerURL/userAccount/recover-password?emailAddress=$email")
        return when (response.status) {
            HttpStatusCode.OK -> HttpStatusCode.OK
            HttpStatusCode.NotFound -> HttpStatusCode.NotFound
            HttpStatusCode.Forbidden -> HttpStatusCode.Forbidden
            else -> null
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return null
}

suspend fun verifyChangedPasswordToken(token: String?): TokenVerificationApiResponse {
    try {
        val response: HttpResponse =
            nonAuthClient.post("$BackendServerURL/userAccount/verify-recover-token?recoverPasswordToken=${encodeURIComponent(token ?: "")}") {
                contentType(ContentType.Application.Json)
            }
        return when (response.status) {
            HttpStatusCode.OK -> {
                TokenVerificationApiResponse(
                    TokenVerificationStatus.VERIFIED,
                    response.body()
                )
            }

            HttpStatusCode.Forbidden, HttpStatusCode.NotFound -> {
                TokenVerificationApiResponse(
                    TokenVerificationStatus.INVALID_OR_EXPIRED,
                    ""
                )
            }

            else -> {
                TokenVerificationApiResponse(
                    TokenVerificationStatus.EXCEPTION,
                    ""
                )
            }
        }

    } catch (_: dynamic) {

    }
    return TokenVerificationApiResponse(
        TokenVerificationStatus.EXCEPTION,
        ""
    )
}

suspend fun changePassword(token: String, password: String): HttpStatusCode? {
    try {
        val encodedPassword = encodeURIComponent(password)
        val encodedToken = encodeURIComponent(token)

        val response: HttpResponse =
            nonAuthClient.post("$BackendServerURL/userAccount/reset-password?recoverPasswordToken=$encodedToken&password=$encodedPassword")
        return when (response.status) {
            HttpStatusCode.Created -> HttpStatusCode.Created
            HttpStatusCode.NotFound, HttpStatusCode.Forbidden -> HttpStatusCode.Forbidden
            else -> null
        }
    } catch (e: dynamic) {

    }
    return null
}