package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class InfrastructureInvestments(
    var section2031Part1A1: String? = null,
    var section2031Part1A2: String? = null,
    var section2031Part1A3: String? = null,
) : DynamicTableItemKey()

@Serializable
data class ServicesSupported(
    var section2031Part2A1: String? = null,
    var section2031Part2A2: String? = null,
    var section2031Part2A3: String? = null,
)

@Serializable
data class IndirectEconomicImpactTable(
    var section2032A1: String? = null,
    var section2032B1: String? = null
) : DynamicTableItemKey()

@Serializable
data class IndirectEconomicImpactsState(
    var infrastructureInvestmentList: MutableList<InfrastructureInvestments> = mutableListOf(),
    var servicesSupportedList: MutableList<ServicesSupported> = mutableListOf(),
    var indirectEconomicImpactList: MutableList<IndirectEconomicImpactTable> = mutableListOf(),
)