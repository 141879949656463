package com.ecosave.watch.portal.components.esg.generaldisclosures.activitiesandworkers

import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import com.ecosave.watch.portal.styles.esg.ESGFormStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.ol
import react.dom.onChange

val Disclosure_2_7 = FC<EsgSubFormComponentProps> { props ->

    val onChangeText = props.onChangeText
    val onBlurText = props.onBlurText
    val onChangeNumber = props.onChangeNumber
    val onBlurNumber = props.onBlurNumber
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.activitiesAndWorkers?.let { state ->

        val employeesByRegionList1Name = state::employeesByRegionList1.name
        val employeesByRegionList2Name = state::employeesByRegionList2.name

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_2_7
        }

        DisclosureSubHeading {
            subHeading =
                "Report the total number of employees, and a breakdown of this total by gender and by region."
        }

        Box {
            className = ESGCommonStyles.TABLE_WRAPPER.cssClass
            DynamicTableWrapper {
                addRowOrSection = {
                    props.addRowOrSection(employeesByRegionList1Name)
                }
                items = state.employeesByRegionList1
                action = DynamicTableAction.ADD
                addRowOrSectionBtnText = ReportConstants.ADD_ROW_MESSAGE
                Box {
                    className = ESGCommonStyles.THREE_COLUMN_TABLE.cssClass
                    TableHeader {
                        header = "Region"
                    }
                    TableHeader {
                        header = "Gender"
                    }
                    TableHeader {
                        header = "Number of Employee"
                    }
                }
            }
            state.employeesByRegionList1.forEachIndexed { index, it ->
                DynamicTableWrapper {
                    key = it.key
                    deleteRowOrSection = {
                        props.deleteRowOrSection(employeesByRegionList1Name, index)
                    }
                    items = state.employeesByRegionList1
                    action = DynamicTableAction.DELETE
                    variant = DynamicTableVariant.TABLE
                    Box {
                        className = ESGCommonStyles.THREE_COLUMN_TABLE.cssClass
                        ESGTextFieldWrapper {
                            name = it::section27Part1A1.name
                            value = it.section27Part1A1 ?: ""
                            onChange = { event -> onChangeText(event, index, employeesByRegionList1Name) }
                            onBlur = { event -> onBlurText(event, index, employeesByRegionList1Name) }
                        }
                        ESGTextFieldWrapper {
                            name = it::section27Part1A2.name
                            value = it.section27Part1A2 ?: ""
                            onChange = { event -> onChangeText(event, index, employeesByRegionList1Name) }
                            onBlur = { event -> onBlurText(event, index, employeesByRegionList1Name) }
                        }
                        ESGNumberFieldWrapper {
                            name = it::section27Part1C1.name
                            value = it.section27Part1C1
                            onChange = { event -> onChangeNumber(event, index, employeesByRegionList1Name) }
                            onBlur = { event -> onBlurNumber(event, index, employeesByRegionList1Name) }
                        }
                    }
                }
            }
        }

        Box {
            className = ESGCommonStyles.TABLE_WRAPPER.cssClass
            DynamicTableWrapper {
                addRowOrSection = {
                    props.addRowOrSection(employeesByRegionList2Name)
                }
                items = state.employeesByRegionList2
                action = DynamicTableAction.ADD
                addRowOrSectionBtnText = ReportConstants.ADD_ROW_MESSAGE
                Box {
                    className = ESGFormStyles.DISCLOSURE_2_7_SECOND_TABLE.cssClass
                    TableHeader {
                        header = "Region"
                    }
                    TableHeader {
                        header = "Gender"
                    }
                    TableHeader {
                        header = "Permanent Employees"
                    }
                    TableHeader {
                        header = "Temporary Employees"
                    }
                    TableHeader {
                        header = "Non-guaranteed hours employees"
                    }
                    TableHeader {
                        header = "Full-time employees"
                    }
                    TableHeader {
                        header = "Part-time employees"
                    }
                }
            }
            state.employeesByRegionList2.forEachIndexed { index, it ->
                DynamicTableWrapper {
                    key = it.key
                    deleteRowOrSection = {
                        props.deleteRowOrSection(employeesByRegionList2Name, index)
                    }
                    items = state.employeesByRegionList2
                    action = DynamicTableAction.DELETE
                    variant = DynamicTableVariant.TABLE
                    Box {
                        className = ESGFormStyles.DISCLOSURE_2_7_SECOND_TABLE.cssClass
                        ESGTextFieldWrapper {
                            name = it::section27Part2A1.name
                            value = it.section27Part2A1 ?: ""
                            onChange = { event -> onChangeText(event, index, employeesByRegionList2Name) }
                            onBlur = { event -> onBlurText(event, index, employeesByRegionList2Name) }
                        }
                        ESGTextFieldWrapper {
                            name = it::section27Part2A2.name
                            value = it.section27Part2A2 ?: ""
                            onChange = { event -> onChangeText(event, index, employeesByRegionList2Name) }
                            onBlur = { event -> onBlurText(event, index, employeesByRegionList2Name) }
                        }
                        ESGNumberFieldWrapper {
                            name = it::section27Part2B1.name
                            value = it.section27Part2B1
                            onChange = { event -> onChangeNumber(event, index, employeesByRegionList2Name) }
                            onBlur = { event -> onBlurNumber(event, index, employeesByRegionList2Name) }
                        }
                        ESGNumberFieldWrapper {
                            name = it::section27Part2C1.name
                            value = it.section27Part2C1
                            onChange = { event -> onChangeNumber(event, index, employeesByRegionList2Name) }
                            onBlur = { event -> onBlurNumber(event, index, employeesByRegionList2Name) }
                        }
                        ESGNumberFieldWrapper {
                            name = it::section27Part2D1.name
                            value = it.section27Part2D1
                            onChange = { event -> onChangeNumber(event, index, employeesByRegionList2Name) }
                            onBlur = { event -> onBlurNumber(event, index, employeesByRegionList2Name) }
                        }
                        ESGNumberFieldWrapper {
                            name = it::section27Part2E1.name
                            value = it.section27Part2E1
                            onChange = { event -> onChangeNumber(event, index, employeesByRegionList2Name) }
                            onBlur = { event -> onBlurNumber(event, index, employeesByRegionList2Name) }
                        }
                        ESGNumberFieldWrapper {
                            name = it::section27Part2F1.name
                            value = it.section27Part2F1
                            onChange = { event -> onChangeNumber(event, index, employeesByRegionList2Name) }
                            onBlur = { event -> onBlurNumber(event, index, employeesByRegionList2Name) }
                        }
                    }
                }
            }
        }

        Box {
            label {
                +"Describe the methodologies and assumptions used to compile the data, including whether the numbers are reported."
                ol {
                    li {
                        +"In head count, full-time equivalent (FTE) or using another methodology."
                    }
                    li {
                        +"At the end of the reporting period, as an average across the reporting period, or using another methodology."
                    }
                }
            }
            ESGTextAreaFieldWrapper {
                name = state::section27Field1.name
                value = state.section27Field1 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"Report contextual information necessary to understand the data reported for the total number of employees and the breakdown by gender and region."
            }
            ESGTextAreaFieldWrapper {
                name = state::section27Field2.name
                value = state.section27Field2 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"Describe significant fluctuations in the number of employees during the reporting period and between reporting periods."
            }
            ESGTextAreaFieldWrapper {
                name = state::section27Field3.name
                value = state.section27Field3 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}