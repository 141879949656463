package com.ecosave.watch.portal.components.esg.emissions

import com.ecosave.watch.portal.components.esg.formcontrols.DecimalFieldMapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGBaseFormBuilder
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.TextAreaFieldMapper
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import react.FC

val GRI_305_2 = FC<EsgSubFormComponentProps> { props ->
    props.esgReportState.report?.emissions?.let { state ->
        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_305_2
        }

        ESGBaseFormBuilder(
            listOf(
                DecimalFieldMapper(
                    "Gross location-based energy indirect (Scope 2) GHG emissions in metric tons of CO2 equivalent.",
                    state::section3052Current1.name,
                    state.section3052Current1
                ),
                DecimalFieldMapper(
                    "If applicable, gross market-based energy indirect (Scope 2) GHG emissions in metric " +
                        "tons of CO equivalent.",
                    state::section3052Current2.name,
                    state.section3052Current2
                ),
                TextAreaFieldMapper(
                    "If available, the gases included in the calculation; whether CO , CH , N O, HFCs, PFCs,\n" +
                        "2 4 2 SF , NF , or all.",
                    state::section3052Current3.name,
                    state.section3052Current3
                ),
                TextAreaFieldMapper(
                    "Base year for the calculation, if applicable, including:",
                    state::section3052Field1.name,
                    state.section3052Field1,
                    listHeaders = listOf(
                        "the rationale for choosing it;",
                        "emissions in the base year;",
                        "the context for any significant changes in emissions that triggered recalculations of base " +
                            "year emissions."
                    )
                ),
                TextAreaFieldMapper(
                    "Source of the emission factors and the global warming potential (GWP) rates used, or a " +
                        "reference to the GWP source.",
                    state::section3052Field2.name,
                    state.section3052Field2
                ),
                TextAreaFieldMapper(
                    "Consolidation approach for emissions; whether equity share, financial control, or " +
                        "operational control.",
                    state::section3052Field3.name,
                    state.section3052Field3
                ),
                TextAreaFieldMapper(
                    "Standards, methodologies, assumptions, and/or calculation tools used.",
                    state::section3052Field4.name,
                    state.section3052Field4
                ),
            ),
            props
        )

    }
}