package com.ecosave.watch.portal.components.esg.generaldisclosures.organizationanditsreportingpractices

import com.ecosave.watch.portal.components.esg.EsgCoreComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.GRIDisclosureGroupName
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.EsgSubSection
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import com.ecosave.watch.portal.helpers.esg.failureErrorMessage
import com.ecosave.watch.portal.helpers.esg.omitDisclosureFromUI
import com.ecosave.watch.portal.helpers.esg.resetPreviousPatchState
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.esg.CurrentInputState
import com.ecosave.watch.portal.models.esg.EsgCollectionAddRow
import com.ecosave.watch.portal.models.esg.RestatementsOfInformation
import com.ecosave.watch.portal.models.esg.RowPatch
import com.ecosave.watch.portal.models.esg.TheOrgAndItsRepPracticesState
import com.ecosave.watch.portal.services.esg.addDynamicRowOrSection
import com.ecosave.watch.portal.services.esg.deleteDynamicRowOrSection
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import kotlinx.coroutines.launch
import kotlinx.serialization.json.JsonPrimitive
import mui.material.Box
import react.FC
import react.dom.events.FocusEvent
import react.dom.events.FormEvent
import web.html.HTMLDivElement
import web.html.HTMLElement
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement

val OrganizationAndItsReportingPracticesComponent = FC<EsgCoreComponentProps> { props ->

    val reportState = props.reportState
    val setReportState = props.setReportState

    reportState.report?.theOrgAndItsRepPractices?.let { state ->

        fun stateSetter(state: TheOrgAndItsRepPracticesState) {
            setReportState(
                reportState.copy(
                    report = reportState.report.copy(
                        theOrgAndItsRepPractices = state
                    )
                )
            )
        }

        val addRowOrSection: (String) -> Unit = { fieldName ->
            resetPreviousPatchState(props.setInputState)
            mainScope.launch {
                props.setAddDeleteRowOrSection(DynamicTableAction.ADD)
                props.setAddDeleteRowOrSectionApiCallInProgress(true)
                val apiCallStatus = addDynamicRowOrSection(
                    EsgCollectionAddRow(
                        objectName = EsgSubSection.THE_ORG_AND_ITS_REP_PRACTICES.name,
                        reportFileName = reportState.reportFileName,
                        patchCollection = RowPatch(
                            pathFirst = fieldName
                        )
                    )
                )

                val newState = state.copy()

                if (apiCallStatus == ApiCallStatus.SUCCESS) {
                    when (fieldName) {
                        state::restatementOfInformationItems.name -> {
                            newState.restatementOfInformationItems.add(RestatementsOfInformation())
                            props.setNotificationMessage(ReportConstants.ADD_SECTION_NOTIFICATION_MESSAGE)
                        }
                    }

                    stateSetter(newState)

                    props.setNotificationStatus(NotificationStatus.SUCCESS)
                    props.setOpenAlertNotifications(true)
                } else {
                    failureErrorMessage(
                        props.setNotificationMessage,
                        props.setNotificationStatus,
                        props.setOpenAlertNotifications
                    )
                }
                props.setAddDeleteRowOrSectionApiCallInProgress(false)
            }
        }

        val deleteRowOrSection: (String, Int) -> Unit = { fieldName, index ->
            resetPreviousPatchState(props.setInputState)
            mainScope.launch {
                props.setAddDeleteRowOrSection(DynamicTableAction.DELETE)
                props.setAddDeleteRowOrSectionApiCallInProgress(true)
                val apiCallStatus = deleteDynamicRowOrSection(
                    objectPatchEnum = EsgSubSection.THE_ORG_AND_ITS_REP_PRACTICES.name,
                    reportFileName = reportState.reportFileName,
                    pathFirst = fieldName,
                    indexAt = index
                )

                val newState = state.copy()

                if (apiCallStatus == ApiCallStatus.SUCCESS) {
                    when (fieldName) {
                        state::restatementOfInformationItems.name -> {
                            newState.restatementOfInformationItems.removeAt(index)
                            props.setNotificationMessage(ReportConstants.DELETE_SECTION_NOTIFICATION_MESSAGE)
                        }
                    }

                    stateSetter(newState)

                    props.setNotificationStatus(NotificationStatus.SUCCESS)
                    props.setOpenAlertNotifications(true)
                } else {
                    failureErrorMessage(
                        props.setNotificationMessage,
                        props.setNotificationStatus,
                        props.setOpenAlertNotifications
                    )
                }
                props.setAddDeleteRowOrSectionApiCallInProgress(false)
            }
        }

        val onChangeText: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            val propertyName = target.name
            val propertyValue = target.value

            val newState = state.copy()

            when (propertyName) {
                state::natureOfOwnershipLegalForm.name -> state.natureOfOwnershipLegalForm = propertyValue
                state::section21Field2.name -> newState.section21Field2 = propertyValue
                state::section21Field3.name -> newState.section21Field3 = propertyValue
                state::section21Field4.name -> newState.section21Field4 = propertyValue
                state::section21Field5.name -> newState.section21Field5 = propertyValue
                state::section21Field6.name -> newState.section21Field6 = propertyValue
                state::section21Field7.name -> newState.section21Field7 = propertyValue
                state::section21Field8.name -> newState.section21Field8 = propertyValue
            }

            stateSetter(newState)

            props.setInputState(
                CurrentInputState(
                    parentPath = field,
                    childPath = propertyName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue),
                    objectName = EsgSubSection.THE_ORG_AND_ITS_REP_PRACTICES.name,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurText: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            val propertyName = target.name
            val propertyValue = target.value
            props.onBlurApiCall(
                propertyName,
                JsonPrimitive(propertyValue),
                changeIndex,
                field,
                EsgSubSection.THE_ORG_AND_ITS_REP_PRACTICES.name
            )
        }

        val onChangeTextArea: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, listName ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value

            val newState = state.copy()

            if (listName == state::restatementOfInformationItems.name && changeIndex != null) {
                newState.restatementOfInformationItems[changeIndex].let {
                    when (propertyName) {
                        it::section24FieldA1.name -> it.section24FieldA1 = propertyValue
                        it::section24FieldA2.name -> it.section24FieldA2 = propertyValue
                        it::section24FieldA3.name -> it.section24FieldA3 = propertyValue
                    }
                }
            } else {
                when (propertyName) {
                    state::section22Field1.name -> newState.section22Field1 = propertyValue
                    state::section22Field2.name -> newState.section22Field2 = propertyValue
                    state::section22Field3.name -> newState.section22Field3 = propertyValue
                    state::section22Field4.name -> newState.section22Field4 = propertyValue
                    state::section22Field5.name -> newState.section22Field5 = propertyValue
                    state::section22Field6.name -> newState.section22Field6 = propertyValue

                    state::section23Field1.name -> newState.section23Field1 = propertyValue
                    state::section23Field2.name -> newState.section23Field2 = propertyValue
                    state::section23Field3.name -> newState.section23Field3 = propertyValue
                    state::section23Field4.name -> newState.section23Field4 = propertyValue
                    state::section23Field5.name -> newState.section23Field5 = propertyValue

                    state::section25Field1.name -> newState.section25Field1 = propertyValue
                    state::section25Field4.name -> newState.section25Field4 = propertyValue
                    state::section25Field5.name -> newState.section25Field5 = propertyValue
                    state::section25Field6.name -> newState.section25Field6 = propertyValue
                }
            }

            stateSetter(newState)

            props.setInputState(
                CurrentInputState(
                    parentPath = listName,
                    childPath = propertyName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue),
                    objectName = EsgSubSection.THE_ORG_AND_ITS_REP_PRACTICES.name,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurTextArea: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value
            props.onBlurApiCall(
                propertyName,
                JsonPrimitive(propertyValue),
                changeIndex,
                field,
                EsgSubSection.THE_ORG_AND_ITS_REP_PRACTICES.name
            )
        }


        Box {
            className = ESGCommonStyles.SECTION_MAIN_LAYOUT.cssClass

            GRIDisclosureGroupName {
                griDisclosureGroup = EsgSubSection.THE_ORG_AND_ITS_REP_PRACTICES
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_2_1)) {
                Disclosure_2_1 {
                    this.esgReportState = reportState
                    this.onChangeText = onChangeText
                    this.onBlurText = onBlurText
                }
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_2_2)) {
                Disclosure_2_2 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                }
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_2_3)) {
                Disclosure_2_3 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                }
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_2_4)) {
                Disclosure_2_4 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.addRowOrSection = addRowOrSection
                    this.deleteRowOrSection = deleteRowOrSection
                }
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_2_5)) {
                Disclosure_2_5 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                }
            }

        }
    }
}
