package com.ecosave.watch.portal.helpers.facilitymanagement

import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.models.facilitymanagement.FacilityState
import com.ecosave.watch.portal.models.facilitymanagement.FacilityValidationState
import react.ReactNode
import react.StateSetter

fun validateFacilityName(
    state: FacilityState,
    validation: FacilityValidationState,
    setValidation: StateSetter<FacilityValidationState>
) {
    if (state.facilityName.isBlank()) {
        validation.facilityNameErrorState = true
        validation.facilityNameErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.facilityNameErrorState = false
        validation.facilityNameErrorMessage = null
    }

    updateFacilityValidationState(validation, setValidation)
}

fun validateBuildingCategory(
    state: FacilityState,
    validation: FacilityValidationState,
    setValidation: StateSetter<FacilityValidationState>
) {
    if (state.buildingCategory.isBlank()) {
        validation.buildingCategoryErrorState = true
        validation.buildingCategoryErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.buildingCategoryErrorState = false
        validation.buildingCategoryErrorMessage = null
    }

    updateFacilityValidationState(validation, setValidation)
}

fun validateBuildingType(
    state: FacilityState,
    validation: FacilityValidationState,
    setValidation: StateSetter<FacilityValidationState>
) {
    if (state.buildingType.isBlank()) {
        validation.buildingTypeErrorState = true
        validation.buildingTypeErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.buildingTypeErrorState = false
        validation.buildingTypeErrorMessage = null
    }

    updateFacilityValidationState(validation, setValidation)
}

fun validateOtherBuildingType(
    state: FacilityState,
    validation: FacilityValidationState,
    setValidation: StateSetter<FacilityValidationState>
) {
    if (state.otherBuildingType.isBlank()) {
        validation.otherBuildingTypeErrorState = true
        validation.otherBuildingTypeErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.otherBuildingTypeErrorState = false
        validation.otherBuildingTypeErrorMessage = null
    }

    updateFacilityValidationState(validation, setValidation)
}

fun validateBuildingTotalArea(
    state: FacilityState,
    validation: FacilityValidationState,
    setValidation: StateSetter<FacilityValidationState>
) {
    if (state.buildingTotalArea == null) {
        validation.buildingTotalAreaErrorState = true
        validation.buildingTotalAreaErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.buildingTotalAreaErrorState = false
        validation.buildingTotalAreaErrorMessage = null
    }

    updateFacilityValidationState(validation, setValidation)
}

fun validateStreet(
    state: FacilityState,
    validation: FacilityValidationState,
    setValidation: StateSetter<FacilityValidationState>
) {
    if (state.street.isBlank()) {
        validation.streetErrorState = true
        validation.streetErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.streetErrorState = false
        validation.streetErrorMessage = null
    }

    updateFacilityValidationState(validation, setValidation)
}

fun validateState(
    state: FacilityState,
    validation: FacilityValidationState,
    setValidation: StateSetter<FacilityValidationState>
) {
    if (state.state.isBlank()) {
        validation.stateErrorState = true
        validation.stateErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.stateErrorState = false
        validation.stateErrorMessage = null
    }

    updateFacilityValidationState(validation, setValidation)
}

fun validateCity(
    state: FacilityState,
    validation: FacilityValidationState,
    setValidation: StateSetter<FacilityValidationState>
) {
    if (state.city.isBlank()) {
        validation.cityErrorState = true
        validation.cityErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.cityErrorState = false
        validation.cityErrorMessage = null
    }

    updateFacilityValidationState(validation, setValidation)
}

fun validateZipCode(
    state: FacilityState,
    validation: FacilityValidationState,
    setValidation: StateSetter<FacilityValidationState>
) {
    if (state.zipcode.isBlank()) {
        validation.zipcodeErrorState = true
        validation.zipcodeErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else if (!isValidZipCode(state.zipcode)) {
        validation.zipcodeErrorState = true
        validation.zipcodeErrorMessage = ReactNode("Zipcode needs to be 5 digits.")
    } else {
        validation.zipcodeErrorState = false
        validation.zipcodeErrorMessage = null
    }

    updateFacilityValidationState(validation, setValidation)
}

fun isValidZipCode(zipcode: String): Boolean {
    return (zipcode.length == 5)
}

fun validateBuildingYearBuilt(
    state: FacilityState,
    validation: FacilityValidationState,
    setValidation: StateSetter<FacilityValidationState>
) {
    if (state.buildingYearBuilt == null) {
        validation.buildingYearBuiltErrorState = true
        validation.buildingYearBuiltErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.buildingYearBuiltErrorState = false
        validation.buildingYearBuiltErrorMessage = null
    }

    updateFacilityValidationState(validation, setValidation)
}

fun validateBuildingOccupancy(
    state: FacilityState,
    validation: FacilityValidationState,
    setValidation: StateSetter<FacilityValidationState>
) {
    if (state.buildingOccupancy == null) {
        validation.buildingOccupancyErrorState = true
        validation.buildingOccupancyErrorMessage = ReactNode("Building Occupancy is required.")
    } else {
        validation.buildingOccupancyErrorState = false
        validation.buildingOccupancyErrorMessage = null
    }

    updateFacilityValidationState(validation, setValidation)
}

fun validatePropertyId(
    state: FacilityState,
    validation: FacilityValidationState,
    setValidation: StateSetter<FacilityValidationState>
) {
    if (state.propertyId == null) {
        validation.propertyIdErrorState = true
        validation.propertyIdErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.propertyIdErrorState = false
        validation.propertyIdErrorMessage = null
    }

    updateFacilityValidationState(validation, setValidation)
}

fun updateFacilityValidationState(
    validation: FacilityValidationState,
    setValidation: StateSetter<FacilityValidationState>
) {
    setValidation(
        validation.copy()
    )
}

fun isFacilityFormValid(
    state: FacilityState,
    validation: FacilityValidationState,
    setValidation: StateSetter<FacilityValidationState>
): Boolean {
    validateFacilityForm(state, validation, setValidation)
    return isFacilityFormFieldsValid(state, validation)
}

fun validateFacilityForm(
    state: FacilityState,
    validation: FacilityValidationState,
    setValidation: StateSetter<FacilityValidationState>
) {
    validateFacilityName(state, validation, setValidation)
    validateBuildingCategory(state, validation, setValidation)
    validateBuildingType(state, validation, setValidation)

    if (isBuildingTypeOther(state.buildingType)) {
        validateOtherBuildingType(state, validation, setValidation)
    }

    validateBuildingTotalArea(state, validation, setValidation)
    validateStreet(state, validation, setValidation)
    validateState(state, validation, setValidation)
    validateCity(state, validation, setValidation)
    validateZipCode(state, validation, setValidation)
    validateBuildingYearBuilt(state, validation, setValidation)
    validateBuildingOccupancy(state, validation, setValidation)

    if (state.energyStarSubscription) {
        validatePropertyId(state, validation, setValidation)
    }
}

fun isFacilityFormFieldsValid(state: FacilityState, validation: FacilityValidationState): Boolean {

    val propertyIdValidation = !(validation.propertyIdErrorState)
    val fieldsValidation = !(validation.facilityNameErrorState ||
            validation.buildingCategoryErrorState ||
            validation.buildingTypeErrorState ||
            validation.otherBuildingTypeErrorState ||
            validation.buildingTotalAreaErrorState ||
            validation.streetErrorState ||
            validation.stateErrorState ||
            validation.cityErrorState ||
            validation.zipcodeErrorState ||
            validation.buildingYearBuiltErrorState ||
            validation.buildingOccupancyErrorState
            )

    return if (state.energyStarSubscription) {
        propertyIdValidation && fieldsValidation
    } else {
        fieldsValidation
    }
}