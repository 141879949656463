package com.ecosave.watch.portal.components.resourcecenter.facilitygroupmanagement

import com.ecosave.watch.portal.components.resourcecenter.ResourcesSection
import com.ecosave.watch.portal.helpers.resourcecenter.FacilityGroupManagementImages
import com.ecosave.watch.portal.styles.ResourceCenterStyles
import mui.material.Box
import mui.material.List
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML
import web.cssom.px

external interface FacilityGroupManagementComponentProps : Props {
    var listPadding: Number
}

val FacilityGroupManagementComponent = FC<FacilityGroupManagementComponentProps> {

    List {
        sx {
            paddingLeft = it.listPadding.px
        }
        Box {
            id = "facility-group-management-overview"
            className = ResourceCenterStyles.RESOURCES_SUBSECTION.cssClass
            ResourcesSection {
                title = "Facility Group Management Overview"
                sentence = "This section enables you to manage your facility's groups by adding new groups, sorting and filtering data."
            }
            ReactHTML.img {
                src = FacilityGroupManagementImages.FacilityGroupManagementOverviewImage
                className = ResourceCenterStyles.RESOURCES_DISPLAY_OVERVIEW.cssClass
            }
        }
        Box {
            id = "add-facility-group"
            className = ResourceCenterStyles.RESOURCES_SUBSECTION.cssClass
            ResourcesSection {
                title = "Add Facility Group"
                category = "To Add a New Facility Group"
            }
            for (sentence in addFacilityGroupSentences) {
                ResourcesSection {
                    subCategory = sentence
                }
            }
            ReactHTML.img {
                src = FacilityGroupManagementImages.AddGroupImage
                className = ResourceCenterStyles.RESOURCES_DISPLAY_OVERVIEW.cssClass
            }
        }
    }
}