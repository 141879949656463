package com.ecosave.watch.portal.components.facilitymanagement

import com.ecosave.watch.portal.styles.FacilityManagementStyles
import com.ecosave.watch.portal.helpers.facilitymanagement.FacilityOperatingHoursDefaultValues
import com.ecosave.watch.portal.helpers.facilitymanagement.convertToDouble
import com.ecosave.watch.portal.helpers.facilitymanagement.convertToHoursMinutes
import com.ecosave.watch.portal.helpers.facilitymanagement.initializeOperatingSchedule
import com.ecosave.watch.portal.helpers.facilitymanagement.padSingleDigit
import com.ecosave.watch.portal.helpers.facilitymanagement.FacilityDays
import com.ecosave.watch.portal.models.facilitymanagement.DailyOperatingSchedule
import com.ecosave.watch.portal.models.facilitymanagement.FacilityOperatingScheduleState
import kotlin.math.abs
import mui.material.Box
import mui.material.MenuItem
import mui.material.Orientation
import mui.material.Select
import mui.material.Slider
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.StateSetter
import react.useEffect
import react.useState
import web.cssom.Display
import web.cssom.pct
import web.cssom.px

external interface FacilityDailyOperatingScheduleProps : Props {
    var state: FacilityOperatingScheduleState
    var setState: StateSetter<FacilityOperatingScheduleState>
    var day: FacilityDays
}

val FacilityDailyOperatingSchedule = FC<FacilityDailyOperatingScheduleProps> { props ->

    val state = props.state
    val setState = props.setState
    val day = props.day

    var startingHour by useState(state.facilityOperatingSchedule.find { it.dayOfTheWeek == day.dayEnum }?.startingHour?.toInt())
    var startingMinute by useState(state.facilityOperatingSchedule.find { it.dayOfTheWeek == day.dayEnum }?.startingMinute?.toInt())
    var endingHour by useState(state.facilityOperatingSchedule.find { it.dayOfTheWeek == day.dayEnum }?.endingHour?.toInt())
    var endingMinute by useState(state.facilityOperatingSchedule.find { it.dayOfTheWeek == day.dayEnum }?.endingMinute?.toInt())
    var sliderValue by useState(arrayOf(convertToDouble(startingHour, startingMinute), convertToDouble(endingHour, endingMinute)))

    useEffect(startingHour, startingMinute, endingHour, endingMinute) {
        sliderValue = arrayOf(convertToDouble(startingHour, startingMinute), convertToDouble(endingHour, endingMinute))
    }

    useEffect(state.isOperating24x7) {
        if (state.isOperating24x7) {
            setState(
                state.copy(
                    facilityOperatingSchedule = initializeOperatingSchedule()
                )
            )
            startingHour = 0
            startingMinute = 0
            endingHour = 0
            endingMinute = 0
        }
    }

    fun setUpdatedOperatingSchedule(updatedOperatingSchedule: MutableList<DailyOperatingSchedule>) {
        setState(
            state.copy(
                facilityOperatingSchedule = updatedOperatingSchedule
            )
        )
    }

    fun setStartingHour(hour: String): MutableList<DailyOperatingSchedule> {
        val updatedOperatingScheduleList = state.facilityOperatingSchedule
        updatedOperatingScheduleList.find { it.dayOfTheWeek == day.dayEnum }?.startingHour = hour
        return updatedOperatingScheduleList
    }

    fun setEndingHour(hour: String): MutableList<DailyOperatingSchedule> {
        val updatedOperatingScheduleList = state.facilityOperatingSchedule
        updatedOperatingScheduleList.find { it.dayOfTheWeek == day.dayEnum }?.endingHour = hour
        return updatedOperatingScheduleList
    }

    fun setStartingMinute(minute: String): MutableList<DailyOperatingSchedule> {
        val updatedOperatingScheduleList = state.facilityOperatingSchedule
        updatedOperatingScheduleList.find { it.dayOfTheWeek == day.dayEnum }?.startingMinute = minute
        return updatedOperatingScheduleList
    }

    fun setEndingMinute(minute: String): MutableList<DailyOperatingSchedule> {
        val updatedOperatingScheduleList = state.facilityOperatingSchedule
        updatedOperatingScheduleList.find { it.dayOfTheWeek == day.dayEnum }?.endingMinute = minute
        return updatedOperatingScheduleList
    }

    Box {
        sx {
            width = 12.pct
            marginLeft = 2.px
            marginRight = 2.px
        }
        Box {
            sx {
                height = 5.pct
                width = 100.pct
                display = Display.block
            }
            Typography {
                variant = TypographyVariant.h5
                +day.dayAbbreviation
            }
        }
        Box {
            className = FacilityManagementStyles.FACILITY_TIME_SELECT_WRAPPER.cssClass
            Select {
                disabled = state.isOperating24x7
                value = when (state.isOperating24x7) {
                    true -> FacilityOperatingHoursDefaultValues.startingHour
                    else -> state.facilityOperatingSchedule.find { it.dayOfTheWeek == day.dayEnum }?.startingHour
                }
                label = ReactNode("Starting Hour")
                onChange = { event, _ ->
                    val value = event.target.value
                    startingHour = value.toInt()
                    val updatedOperatingSchedule = setStartingHour(value)
                    setUpdatedOperatingSchedule(updatedOperatingSchedule)
                }
                for (hours in 0..23) {
                    MenuItem {
                        value = padSingleDigit(hours)
                        +padSingleDigit(hours)
                    }
                }
            }
            Select {
                disabled = state.isOperating24x7
                value = when (state.isOperating24x7) {
                    true -> FacilityOperatingHoursDefaultValues.startingMinute
                    else -> state.facilityOperatingSchedule.find { it.dayOfTheWeek == day.dayEnum }?.startingMinute
                }
                label = ReactNode("Minutes")
                for (minutes in 0..59) {
                    MenuItem {
                        value = padSingleDigit(minutes)
                        +padSingleDigit(minutes)
                    }
                }
                onChange = { event, _ ->
                    val value = event.target.value
                    startingMinute = value.toInt()
                    val updatedOperatingSchedule = setStartingMinute(value)
                    setUpdatedOperatingSchedule(updatedOperatingSchedule)
                }
            }
        }
        Box {
            className = FacilityManagementStyles.FACILITY_TIME_SELECT_WRAPPER.cssClass
            Select {
                disabled = state.isOperating24x7
                value = when (state.isOperating24x7) {
                    true -> FacilityOperatingHoursDefaultValues.endingHour
                    else -> state.facilityOperatingSchedule.find { it.dayOfTheWeek == day.dayEnum }?.endingHour
                }
                onChange = { event, _ ->
                    val value = event.target.value
                    endingHour = value.toInt()
                    val updatedOperatingSchedule = setEndingHour(value)
                    setUpdatedOperatingSchedule(updatedOperatingSchedule)
                }
                label = ReactNode("Ending Hour")
                for (hours in 0..23) {
                    MenuItem {
                        value = padSingleDigit(hours)
                        +padSingleDigit(hours)
                    }
                }
            }
            Select {
                disabled = state.isOperating24x7
                value = when (state.isOperating24x7) {
                    true -> FacilityOperatingHoursDefaultValues.endingMinute
                    else -> state.facilityOperatingSchedule.find { it.dayOfTheWeek == day.dayEnum }?.endingMinute
                }
                label = ReactNode("End Minutes")
                for (minutes in 0..59) {
                    MenuItem {
                        value = padSingleDigit(minutes)
                        +padSingleDigit(minutes)
                    }
                }
                onChange = { event, _ ->
                    val value = event.target.value
                    endingMinute = value.toInt()
                    val updatedOperatingSchedule = setEndingMinute(value)
                    setUpdatedOperatingSchedule(updatedOperatingSchedule)
                }
            }
        }
        Box {
            className = FacilityManagementStyles.FACILITY_TIME_CENTER.cssClass
            Box {
                className = FacilityManagementStyles.TIME_BOX.cssClass
                Slider {
                    disabled = state.isOperating24x7
                    value = when (state.isOperating24x7) {
                        true -> {
                            arrayOf(0.00, -23.59)
                        }

                        else -> {
                            sliderValue
                        }
                    }
                    disableSwap = true
                    min = -23.59
                    max = 00.00
                    step = 0.01
                    orientation = Orientation.vertical
                    scale = { x -> -x.toDouble() }
                    onChange = { _, newValue, _ ->

                        val value = newValue as Array<Double>

                        val endingHourMinutes = convertToHoursMinutes(abs(value.first()))
                        val startingHourMinutes = convertToHoursMinutes(abs((value[1])))

                        startingHour = startingHourMinutes.first
                        startingMinute = startingHourMinutes.second
                        endingHour = endingHourMinutes.first
                        endingMinute = endingHourMinutes.second

                        val updatedOperatingSchedule: MutableList<DailyOperatingSchedule> = state.facilityOperatingSchedule

                        updatedOperatingSchedule.find { it.dayOfTheWeek == day.dayEnum }?.startingHour = padSingleDigit(startingHourMinutes.first)
                        updatedOperatingSchedule.find { it.dayOfTheWeek == day.dayEnum }?.startingMinute = padSingleDigit(startingHourMinutes.second)
                        updatedOperatingSchedule.find { it.dayOfTheWeek == day.dayEnum }?.endingHour = padSingleDigit(endingHourMinutes.first)
                        updatedOperatingSchedule.find { it.dayOfTheWeek == day.dayEnum }?.endingMinute = padSingleDigit(endingHourMinutes.second)

                        setUpdatedOperatingSchedule(updatedOperatingSchedule)
                    }
                }
            }
        }
    }

}