package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class GovernanceState(
    var section29Field1: String? = null,
    var section29Field2: String? = null,
    var section2_9List: MutableList<Section2_9> = mutableListOf(),

    var section210A1: String? = null,
    var section210A2: String? = null,
    var section210A3: String? = null,
    var section210A4: String? = null,
    var section210A5: String? = null,

    var section211A1: String? = null,
    var section211A2: String? = null,

    var section212A1: String? = null,
    var section212A2: String? = null,
    var section212A3: String? = null,
    var section212A4: String? = null,
    var section212A5: String? = null,

    var section213A1: String? = null,
    var section213A2: String? = null,
    var section213A3: String? = null,
    var section213A4: String? = null,

    var section214A1: String? = null,
    var section214A2: String? = null,

    var section215A6: String? = null,
    var section215A7: String? = null,
    var section215A1: String? = null,
    var section215A2: String? = null,
    var section215A3: String? = null,
    var section215A4: String? = null,


    var section216A1: String? = null,
    var section216A2: Long? = null,
    var section216A3: String? = null,

    var section217A1: String? = null,

    var section218A1: String? = null,
    var section218A2: String? = null,
    var section218A3: String? = null,

    var section219A8: String? = null,
    var section219A7: String? = null,

    var section220A1: String? = null,
    var section220A2: String? = null,
    var section220A3: String? = null,
    var section220A4: String? = null,
    var section220A5: String? = null,

    var section221B1: Double? = null,
    var section221B2: Double? = null,
    var section221B4: Double? = null,
    var section221B5: Double? = null,
    var section221Field1: String? = null,
)

@Serializable
data class Section2_9(
    var section29A1: String? = null,
    var section29A2: Long? = null,
    var section29A3: Long? = null,
    var section29A4: String? = null,
    var section29A5: String? = null,
    var section29A6: String? = null,
    var section29A7: String? = null,
    var section29A8: String? = null,
    var section29A9: String? = null,
    var section29A10: String? = null,
) : DynamicTableItemKey()
