package com.ecosave.watch.portal.components.common

import com.ecosave.watch.portal.helpers.Colors
import mui.icons.material.ArrowDownward
import mui.icons.material.ArrowUpward
import mui.material.Box
import mui.material.TableCell
import mui.material.TableCellAlign
import mui.material.TableCellPadding
import mui.system.sx
import react.FC
import react.Props
import web.cssom.AlignItems
import web.cssom.Color
import web.cssom.Cursor
import web.cssom.Display
import web.cssom.pct
import web.cssom.px
import web.cssom.rem

external interface SortableTableCellProps : Props {
    var active: Boolean
    var isAsc: Boolean
    var label: String
    var createSortHandler: () -> Unit
}

val SortableTableCell = FC<SortableTableCellProps> { props ->
    TableCell {
        align = TableCellAlign.left
        padding = TableCellPadding.normal
        sx {
            width = 13.pct
            fontSize = 0.9.rem
            color = Color(Colors.GRAY)
        }
        onClick = {
            props.createSortHandler()
        }
        Box {
            sx {
                display = Display.flex
                alignItems = AlignItems.center
                cursor = Cursor.pointer
            }
            +props.label
            if (props.active) {
                if (props.isAsc) {
                    ArrowUpward {
                        sx {
                            width = 20.px
                            height = 20.px
                        }
                    }
                } else {
                    ArrowDownward {
                        sx {
                            width = 20.px
                            height = 20.px
                        }
                    }
                }
            } else {
                Box {
                    sx {
                        width = 20.px
                        height = 20.px
                    }
                }
            }
        }
    }
}
