package com.ecosave.watch.portal.models.workorder

import kotlinx.serialization.Serializable

@Serializable
data class ActionStateDataClass(
    var actionId: Int? = null,
    var status: String = "",
    var notes: String = ""
)
