package com.ecosave.watch.portal.services

import com.ecosave.watch.portal.helpers.BackendServerURL
import com.ecosave.watch.portal.models.billing.Facility
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*

suspend fun getAllFacilities(): MutableList<Facility> {
    try {
        val response: HttpResponse = httpClient.get("$BackendServerURL/facility/get-facilities")
        if (response.status == HttpStatusCode.OK) {
            return response.body()
        }
    } catch (e: dynamic) {
        return mutableListOf()
    }
    return mutableListOf()
}