package com.ecosave.watch.portal.components.privacypolicy

import com.ecosave.watch.portal.helpers.privacypolicy.getMailToWithSubject
import react.FC
import react.Props
import react.dom.html.OlType
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.ol
import react.dom.html.ReactHTML.ul

val VirginiaResidentsPrivacyPolicy = FC<Props> {
    HeadingWrapper {
        heading = "For those of you in Virginia, please see our Notice to Virginia Residents:"
    }
    HeadingWrapper {
        heading = "Notice to Virginia Residents"
        subHeading = true
    }
    TypographyWrapper {
        +"The Virginia Consumer Data Protection Act (“VCDPA”) became effective on January 1, 2023, and gives Virginia residents rights and control over their Personal Information. Ecosave provides this statement to Virginia residents in accordance with requirements under the VCDPA to make certain disclosures about the collection and processing of their Personal Information. This is Ecosave’s Virginia-specific description of consumers’ privacy rights under the VCDPA."
    }
    HeadingWrapper {
        heading = "We Do Not Sell Your Personal Information"
        subHeading = true
    }
    TypographyWrapper {
        +"Under the VCDPA, a business that sells Virginia residents’ Personal Information to others:"
    }
    TypographyWrapper {
        ul {
            li {
                +"must give notice to Virginia residents before selling their Personal Information to others; and"
            }
            li {
                +"must provide the right to opt out of the sale of their Personal Information."
            }
        }
    }
    TypographyWrapper {
        +"Ecosave does not sell Personal Information, including Personal Information of anyone under 16 years old, without consent."
    }
    HeadingWrapper {
        heading = "Your Rights Under the VCDPA"
        subHeading = true
    }
    TypographyWrapper {
        +"Virginia residents have the right to:"
    }
    TypographyWrapper {
        ul {
            li {
                +"Confirm whether or not a business is processing their Personal Information;"
            }
            li {
                +"Access their Personal Information;"
            }
            li {
                +"Correct inaccuracies in their Personal Information;"
            }
            li {
                +"Delete their Personal Information;"
            }
            li {
                +"Obtain a copy of their Personal Information in a portable and, to the extent technically feasible, readily usable format that allows the consumer to transmit the information to another entity; and"
            }
            li {
                +"Opt out of the processing of their Personal Information for purposes of"
                ol {
                    type = OlType.i
                    li {
                        +"targeted advertising, "
                    }
                    li {
                        +"the sale of Personal Information, or "
                    }
                    li {
                        +"profiling that may produce a legal or other significant impact on the Virginia resident."
                    }
                }
            }
        }
    }
    TypographyWrapper {
        +"If you request that a business disclose categories and specific pieces of Personal Information collected about you, you have the right to receive that information, free of charge, twice a year."
    }
    TypographyWrapper {
        +"Ecosave may use de-identified data (data that cannot reasonably be linked to an identified or identifiable person or a device linked to such person) in some instances, but Ecosave either maintains such data without attempting to re-identify it or treats such data as Personal Information."
    }
    TypographyWrapper {
        +"The VCDPA prohibits businesses from discriminating against a Virginia resident for exercising any of their rights under the VCDPA, including by:"
    }
    TypographyWrapper {
        ul {
            li {
                +"denying goods or services to the resident;"
            }
            li {
                +"charging different prices or rates for goods or services; or"
            }
            li {
                +"providing a different level or quality of goods or services."
            }
        }
    }
    HeadingWrapper {
        heading = "To Exercise Your VCDPA Rights"
        subHeading = true
    }
    TypographyWrapper {
        +"Contact us at "
        CustomLink {
            to = "tel: ${TermsOfServiceConstants.ecosavePhoneNumber}"
            text = TermsOfServiceConstants.ecosavePhoneNumber
        }
    }
    TypographyWrapper {
        +"Ecosave will verify your request within seven (7) days and complete the request within 45 days from receipt of the request, as required by law. Additionally, you may have the right to appeal the denial of any of these rights by submitting a form that will be provided to you if we deny a data request."
    }
    TypographyWrapper {
        +"If you have any questions specifically about VCDPA, you may reach out to us at "
        CustomLink {
            to = TermsOfServiceConstants.ecosavePrivacyEmail.getMailToWithSubject("VCDPA")
            text = TermsOfServiceConstants.ecosavePrivacyEmail
        }
    }
}
