package com.ecosave.watch.portal.models.workorder

import kotlinx.serialization.Serializable

@Serializable
data class WorkForce(
    val emailAddress: String,
    val username: String,
    var actionAssigned: Int,
    val actionInProgress: Int
)