package com.ecosave.watch.portal.models.controlcenter

import kotlinx.serialization.Serializable

@Serializable
data class BarChartVisibility(
    val electric: Boolean = false,
    val naturalGas: Boolean = false,
    val steam: Boolean = false
)
