package com.ecosave.watch.portal.helpers.resourcecenter

import com.ecosave.watch.portal.models.resourcecenter.ModuleNavigationItem
import kotlinx.browser.document
import mui.material.ListItemButton
import mui.material.ListItemText
import react.FC
import react.Props
import react.ReactNode
import react.State
import react.StateSetter

external interface ModuleNavigationHelperProps : Props {
    var moduleNavigationItemList: List<ModuleNavigationItem>
    var openModuleNavigationStateSetter: StateSetter<Boolean>
}

val ModuleNavigationHelper = FC<ModuleNavigationHelperProps> { props ->
    props.moduleNavigationItemList.forEach { moduleItem ->
        ListItemButton {
            ListItemText {
                primary = ReactNode(moduleItem.name)
                onClick = {
                    val sectionId = moduleItemToRouteMap[moduleItem.name]
                    if (sectionId != null) {
                        val sectionElement = document.getElementById(sectionId)
                        sectionElement?.scrollIntoView(js("({behavior: 'smooth'})"))
                        props.openModuleNavigationStateSetter(false)
                    }
                }
            }
        }
    }
}