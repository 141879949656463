package com.ecosave.watch.portal.helpers.billing

object BillingCommonAnnotations {
    const val UTILITY_SERVICE_PROVIDER = "This is the name of the utility provider."
    const val ACCOUNT_NAME = "This is the name of the utility account/service location name."
    const val ACCOUNT_NUMBER = "This is the main utility account number."
    const val METER_NUMBER =
        "This is the meter number associated. If there are multiple meters, enter each meter separated by a comma."
    const val BILL_START_DATE = "Start of the bill period."
    const val BILL_END_DATE = "End of the bill period."
    const val BILL_ESTIMATED = "Is it indicated somewhere in the bill that values are estimated?"
    const val BILL_ADJUSTED = "Do you see an adjustment/credit for a past month usage?"
    const val TOTAL_USAGE = "This is the total monthly usage in the bill."
    const val TOTAL_BILL_COST =
        "This is the total charges for the current month in the bill. This includes both commodity and distribution charges. This should not include any late fees or past month payment details."
    const val ENERGY_STAR_METER_ID = "This is ${BillingConstants.ENERGY_STAR} meter id."
}

object BillingElectricAnnotations {
    const val TOTAL_PEAK_BILLED_DEMAND = "This is the Peak billed demand / Distribution demand."
    const val PEAK_DEMAND_CHARGES =
        "Please enter only the demand charge. This can also be called Distribution Demand Charges."
    const val ON_PEAK_USAGE = "Enter on-peak usage if provided in bill."
    const val ON_PEAK_USAGE_CHARGES = "Enter on-peak usage charges if provided in bill."
    const val OFF_PEAK_USAGE = "Enter off-peak usage if provided in bill."
    const val OFF_PEAK_USAGE_CHARGES = "Enter off-peak usage charges if provided in bill."
    const val ON_PEAK_DEMAND = "Enter the on-peak demand value if provided in bill."
    const val OFF_PEAK_DEMAND = "Enter the off-peak demand value if provided in bill"
    const val TOTAL_DELIVERY_DISTRIBUTION = "This is the total delivery/distribution charge shown in the bill."
}

object BillingNaturalGasAnnotations {
    const val TOTAL_SUPPLY_CHARGES =
        "This is the total supply charges. If there is more than one supply charge, enter the sum of all supply charges."
    const val TOTAL_DELIVERY_DISTRIBUTION = "This is the total delivery charges."
}

object BillingWaterAndSewerAnnotations {
    const val SERVICE_CHARGES = "This is the service charge listed."
    const val STORMWATER_CHARGES = "This is the storm water charges."
    const val SEPARATE_SEWER_SURCHARGE_BILL = "If unsure, select No."
    const val INDUSTRIAL_SURCHARGE = "This is the total industrial surcharge on the bill."
    const val FLOW = "This is the flow printed on the bill before the SRF multiplier is used."
    const val SRF = "This is the SRF multiplier."
    const val FLOW_USED_FOR_BILLING = "This is the flow used for billing."
    const val BIO_CHEMICAL_OXYGEN_DEMAND = "This is the billed Biochemical Oxygen Demand Value (BOD) value in mg/L."
    const val TOTAL_SUSPENDED_SOLIDS = "This is the billed Total Suspended Solids (TSS) value in mg/L."
    const val TOTAL_SURCHARGE_BILL = "This is the Total Surcharge value shown in the surcharge bill."
}

object BillingSteamAnnotations {
    const val DEMAND_MEASURED = "This is the total demand measured."
    const val DEMAND_BILLED = "This is the total demand billed."
    const val HEATING_USAGE_CHARGES =
        "This is the total usage charge. Include any charge that is charged based on <Mlbs> of steam used."
    const val HEATING_DEMAND_CHARGES =
        "This is the total demand charge. Include any charge that is charged based on <Lbs/hr> of steam demand."
    const val COOLING_STEAM_USAGE = "This is the total monthly usage in the bill."
    const val TOTAL_COOLING_STEAM_CHARGES =
        "This is the total charges for the current month in the bill. This includes both commodity and distribution charges. This should not include any late fees or past month payment details."
    const val HEATING_STEAM_USAGE = "This is the total usage on the heating steam bill."
}

object BillingSolarAnnotations {
    const val SOLAR_NET_METERED = "Do you export back to the grid and have solar component in your utility bill?"
    const val PPA_SERVICE_PROVIDER = "This is the name of the vendor you have the Power Purchase Agreement (PPA) with."
    const val TOTAL_ANNUAL_GENERATION = "This is the total annual kWh that can be generated by your solar system."
    const val TOTAL_INSTALLED_SOLAR_CAPACITY = "This is the total capacity of the installed Solar System in kW."
}
