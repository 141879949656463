package com.ecosave.watch.portal.helpers.energymanagement

import com.ecosave.watch.portal.helpers.Colors
import com.ecosave.watch.portal.helpers.billing.*
import com.ecosave.watch.portal.helpers.common.removeDecimalAndAddComma
import com.ecosave.watch.portal.models.energymanagement.*
import com.ecosave.watch.portal.models.common.CustomTooltipLabel
import js.core.push
import moment.moment

fun getValues(payload: Array<dynamic>, unit: UtilityUnit): Array<CustomTooltipLabel> {
    var values = emptyArray<CustomTooltipLabel>()
    payload.forEachIndexed() {index, item ->
        var doubleVal = item.value.toString().toDouble()
        val label = if (index == 0) {
            item.payload.billingMonth
        } else if (index == 1) {
            item.payload.billingMonthTwo
        } else {
            "Target"
        }
        values.push(CustomTooltipLabel("${label}: ${unit.prefix}${removeDecimalAndAddComma(doubleVal)} ${unit.surfix}", item.color))
    }
    return values
}

fun getYLabel (label: String, utilityName: String): String {
    return when(label) {
        RadioLabels.USAGE.label -> when(utilityName) {
            UtilityType.ELECTRIC.name -> "Usage (${UsageUnit.KWH.description})"
            UtilityType.NATURAL_GAS.name -> "Usage (${UsageUnit.CCF.description})"
            UtilityType.STEAM.name -> "Usage (${UsageUnit.MLBS.description})"
            else -> ""
        }
        RadioLabels.COST.label -> "Cost ($)"
        RadioLabels.CO2.label -> "CO₂ (Metric Tonnes)"
        else -> ""
    }
}

fun getColorForUtility (value: String?): String {
    return when(value) {
        UtilityType.ELECTRIC.name -> Colors.LIGHT_CYAN_BLUE
        UtilityType.NATURAL_GAS.name -> Colors.BRIGHT_GREEN
        UtilityType.STEAM.name -> Colors.GOLDENROD
        else -> ""
    }
}

fun getSecondColorForUtility (value: String?): String {
    return when(value) {
        UtilityType.ELECTRIC.name -> Colors.ANAKIWA
        UtilityType.NATURAL_GAS.name -> Colors.AERO_BLUE
        UtilityType.STEAM.name -> Colors.BUTTERMILK
        else -> ""
    }
}

fun getUnitForUtilityType(utilityType: String): String {
    return when(utilityType) {
        UtilityType.ELECTRIC.type -> UsageUnit.KWH.description
        UtilityType.NATURAL_GAS.type -> UsageUnit.CCF.description
        UtilityType.STEAM.type -> UsageUnit.MLBS.description
        else -> ""
    }
}

fun getUtiltyUnit(dataKey: String, utilityType: String): UtilityUnit {
    return when(dataKey) {
        "utilityCost" -> UtilityUnit("$utilityType Cost", "$", "")
        "utilityCo2Emission" -> UtilityUnit("$utilityType CO₂", "", "Metric Tonnes")
        "utilityUsage" -> UtilityUnit("$utilityType Usage", "", getUnitForUtilityType(utilityType))
        else -> UtilityUnit("", "", "")
    }
}

fun getMonthAndValue(data: EnergyUsageNCO2, key: String): MonthAndValue {
    if (key == RadioLabels.USAGE.value) {
        return MonthAndValue(data.billingMonth, data.utilityUsage)
    } else if (key == RadioLabels.CO2.value) {
        return MonthAndValue(data.billingMonth, data.utilityCo2Emission)
    } else {
        return MonthAndValue(data.billingMonth, data.utilityCost)
    }
}

fun renderTraveller(): Unit? {
    return null
}
fun getUtilityBaseline(data: List<UtilityBaseline>, utilityType: UtilityType): List<EnergyUsageNCO2> {
    for (baseline in data) {
        if (baseline.utilityType == utilityType) {
            return baseline.selectedEnergyBaseline
        }
    }
    return emptyList()
}

fun getUtilityTarget(data: List<UtilityTarget>?, utilityType: UtilityType): List<EnergyUsageNCO2> {
    if (data != null) {
        for (target in data) {
            if (target.utilityType == utilityType) {
                if (target.selectedEnergyTarget != null) {
                    return target.selectedEnergyTarget
                }
            }
        }
    }
    return emptyList()
}
fun getBaselineOrTarget(data: List<EnergyUsageNCO2>, billingMonth: String): EnergyUsageNCO2? {
    val month = moment(billingMonth, "MMM YYYY").format("MMM")
    for (baseline in data) {
        val baselineMonth = moment(baseline.billingMonth, "MMM YYYY").format("MMM")
        if (month == baselineMonth) {
            return baseline
        }
    }
    return null
}

fun getName(key: String): RankingUnit {
    return when(key) {
        "siteEnergyUseIntensity" -> RankingUnit("Site Energy Use Intensity", "kBTU/sqft")
        "costIntensity" -> RankingUnit("Cost Intensity", "$/sqft")
        else -> RankingUnit("CO₂ Intensity", "kg/sqft")
    }
}

fun getTotalOfUtilities(datakey: String, data: List<TotalEnergyUsageNCO2>): Double {
    val total = when (datakey) {
        RadioLabels.USAGE.totalTableKey -> {
            data.sumOf { it.utilityUsageInMMBtu }
        }

        RadioLabels.CO2.totalTableKey -> {
            data.sumOf { it.utilityCo2Emission }
        }

        RadioLabels.COST.totalTableKey -> {
            data.sumOf { it.utilityCost }
        }

        else -> {
            0.0
        }
    }
    return total
}
