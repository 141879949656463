package com.ecosave.watch.portal.helpers.resourcecenter

object AssetManagementImages {
    const val OverviewImage =
        "../../../../../../../images/resourcecenter/sections/assetManagementImages/AssetManagementOverview.png"
    const val SelectionImage =
        "../../../../../../../images/resourcecenter/sections/assetManagementImages/SelectAssetManagement.png"
    const val AddAssetImage = "../../../../../../../images/resourcecenter/sections/assetManagementImages/AddAsset.png"
    const val ColumnActionsImage =
        "../../../../../../../images/resourcecenter/sections/assetManagementImages/ColumnActions.png"
    const val ShowHideSearchImage =
        "../../../../../../../images/resourcecenter/sections/assetManagementImages/ShowHideSearch.png"
    const val ShowHideFiltersImage =
        "../../../../../../../images/resourcecenter/sections/assetManagementImages/ShowHideFilters.png"
    const val ShowHideColumnsImage =
        "../../../../../../../images/resourcecenter/sections/assetManagementImages/ShowHideColumns.png"
}

object InitialOnboardingImages {
    const val CreateAccountImage =
        "../../../../../../../images/resourcecenter/sections/initialOnboardingImages/CreateAccount.png"
    const val CreateAccount2Image =
        "../../../../../../../images/resourcecenter/sections/initialOnboardingImages/CreateAccount2.png"
    const val AccountCreatedImage =
        "../../../../../../../images/resourcecenter/sections/initialOnboardingImages/AccountCreated.png"
    const val EmailVerificationImage =
        "../../../../../../../images/resourcecenter/sections/initialOnboardingImages/EmailVerification.png"
    const val ClickVerifyImage =
        "../../../../../../../images/resourcecenter/sections/initialOnboardingImages/ClickVerify.png"
    const val SignInImage = "../../../../../../../images/resourcecenter/sections/initialOnboardingImages/SignIn.png"
}

object UtilityInformationImages {
    const val SelectBillSummaryImage =
        "../../../../../../../images/resourcecenter/sections/utilityInformationImages/SelectBillSummary.png"
    const val ClickDeleteIconImage =
        "../../../../../../../images/resourcecenter/sections/utilityInformationImages/ClickDeleteIcon.png"
    const val ConfirmDeleteImage =
        "../../../../../../../images/resourcecenter/sections/utilityInformationImages/ConfirmDelete.png"
}

object EnergyManagementImages {
    const val EditFacilityImage =
        "../../../../../../../images/resourcecenter/sections/energyManagementImages/EditFacility.png"
    const val EnergyManagementBaselineComparisonImage =
        "../../../../../../../images/resourcecenter/sections/energyManagementImages/EnergyManagementBaselineComparison.png"
    const val EnergyManagementCustomComparisonImage =
        "../../../../../../../images/resourcecenter/sections/energyManagementImages/EnergyManagementCustomComparison.png"
    const val PortfolioOverviewImage =
        "../../../../../../../images/resourcecenter/sections/energyManagementImages/PortfolioOverview.png"
    const val SelectBaselineImage =
        "../../../../../../../images/resourcecenter/sections/energyManagementImages/SelectBaseline.png"
    const val SelectFacilityGroupImage =
        "../../../../../../../images/resourcecenter/sections/energyManagementImages/SelectFacilityGroup.png"
    const val SelectFacilityOverviewImage =
        "../../../../../../../images/resourcecenter/sections/energyManagementImages/SelectFacilityOverview.png"
    const val SelectPortfolioOverviewImage =
        "../../../../../../../images/resourcecenter/sections/energyManagementImages/SelectPortfolioOverview.png"
    const val SetSavingsTargetImage =
        "../../../../../../../images/resourcecenter/sections/energyManagementImages/SetSavingsTarget.png"
}

object FacilityGroupManagementImages {
    const val AddGroupImage =
        "../../../../../../../images/resourcecenter/sections/facilityGroupManagementImages/AddGroup.png"
    const val FacilityGroupManagementOverviewImage =
        "../../../../../../../images/resourcecenter/sections/facilityGroupManagementImages/FacilityGroupManagementOverview.png"
    const val SelectFacilityGroupManagementImage =
        "../../../../../../../images/resourcecenter/sections/facilityGroupManagementImages/SelectFacilityGroupManagement.png"
}

object FacilityManagementImages {
    const val EditOperatingHoursImage =
        "../../../../../../../images/resourcecenter/sections/facilityManagementImages/EditOperatingHours.png"
    const val FacilityManagementOverviewImage =
        "../../../../../../../images/resourcecenter/sections/facilityManagementImages/FacilityManagementOverview.png"
    const val FacilityManagementOverviewExpandedImage =
        "../../../../../../../images/resourcecenter/sections/facilityManagementImages/FacilityManagementOverviewExpanded.png"
    const val SelectFacilityManagementImage =
        "../../../../../../../images/resourcecenter/sections/facilityManagementImages/SelectFacilityManagement.png"
}

object UserManagementImages {
    const val EditUserRoleImage =
        "../../../../../../../images/resourcecenter/sections/userManagementImages/EditUserRole.png"
    const val InviteUsersImage =
        "../../../../../../../images/resourcecenter/sections/userManagementImages/InviteUsers.png"
    const val SelectUserManagementImage =
        "../../../../../../../images/resourcecenter/sections/userManagementImages/SelectUserManagement.png"
    const val UserManagementOverview =
        "../../../../../../../images/resourcecenter/sections/userManagementImages/UserManagementOverview.png"
}

object ControlCenterImages {
    const val ControlCenterOverviewImage =
        "../../../../../../../images/resourcecenter/sections/controlCenterImages/ControlCenterOverview.png"
}
object WorkOrdersImages {
    const val ActionManagementAssignmentImage  =        "../../../../../../../images/resourcecenter/sections/workOrdersImages/ActionManagementAssignment.png"


    const val ActionManagementFilterImage =
        "../../../../../../../images/resourcecenter/sections/workOrdersImages/ActionManagementFilter.png"
    const val ActionManagementOverviewImage =
        "../../../../../../../images/resourcecenter/sections/workOrdersImages/ActionManagementOverview.png"
    const val ObservationSummaryOverviewImage =
        "../../../../../../../images/resourcecenter/sections/workOrdersImages/ObservationSummaryOverview.png"
    const val ObservationSummaryFilterImage =
        "../../../../../../../images/resourcecenter/sections/workOrdersImages/ObservationSummaryFilter.png"
}