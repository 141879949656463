package com.ecosave.watch.portal.components.esg.anticorruption

import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val Disclosure_205_3 = FC<EsgSubFormComponentProps> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea
    val onChangeNumber = props.onChangeNumber
    val onBlurNumber = props.onBlurNumber

    props.esgReportState.report?.antiCorruption?.let { state ->

        val legalCasesOutcomesListName = state::legalCasesOutcomesList.name

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_205_3
        }

        Box {
            label {
                +"Number and nature of confirmed incidents of corruption"
            }
            ESGTextAreaFieldWrapper {
                name = state::section2053A1.name
                value = state.section2053A1 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            className = ESGCommonStyles.TWO_COLUMN_TABLE_8_4.cssClass
            label {
                +"Number of confirmed incidents in which employees were dismissed or disciplined for corruption"
            }
            ESGNumberFieldWrapper {
                name = state::section2053A2.name
                value = state.section2053A2 ?: ""
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            label {
                +"Number of confirmed incidents when contracts with business partners were terminated or not renewed due to violations related to corruption"
            }
            ESGNumberFieldWrapper {
                name = state::section2053A3.name
                value = state.section2053A3 ?: ""
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
        }

        DisclosureSubHeading {
            subHeading =
                "Public legal cases regarding corruption brought against the organization or its employees during the reporting period and the outcomes of such cases."
        }

        Box {
            className = ESGCommonStyles.TABLE_WRAPPER.cssClass
            DynamicTableWrapper {
                addRowOrSection = {
                    props.addRowOrSection(legalCasesOutcomesListName)
                }
                items = state.legalCasesOutcomesList
                action = DynamicTableAction.ADD
                addRowOrSectionBtnText = ReportConstants.ADD_ROW_MESSAGE
                Box {
                    className = ESGCommonStyles.TWO_COLUMN_TABLE.cssClass
                    TableHeader {
                        header = "Legal Case"
                    }
                    TableHeader {
                        header = "Outcome of the case"
                    }
                }
            }
            state.legalCasesOutcomesList.forEachIndexed { index, it ->
                DynamicTableWrapper {
                    key = it.key
                    deleteRowOrSection = {
                        props.deleteRowOrSection(legalCasesOutcomesListName, index)
                    }
                    items = state.legalCasesOutcomesList
                    action = DynamicTableAction.DELETE
                    variant = DynamicTableVariant.TABLE
                    Box {
                        className = ESGCommonStyles.TWO_COLUMN_TABLE.cssClass
                        ESGTextAreaFieldWrapper {
                            name = it::section2053Case1.name
                            value = it.section2053Case1 ?: ""
                            onChange = { event -> onChangeTextArea(event, index, legalCasesOutcomesListName) }
                            onBlur = { event -> onBlurTextArea(event, index, legalCasesOutcomesListName) }
                        }
                        ESGTextAreaFieldWrapper {
                            name = it::section2053Outcome1.name
                            value = it.section2053Outcome1 ?: ""
                            onChange = { event -> onChangeTextArea(event, index, legalCasesOutcomesListName) }
                            onBlur = { event -> onBlurTextArea(event, index, legalCasesOutcomesListName) }
                        }
                    }
                }
            }
        }

    }
}