package com.ecosave.watch.portal.components.utilitybills

import com.ecosave.watch.portal.helpers.common.PageRoutes
import com.ecosave.watch.portal.helpers.common.PageTitles
import com.ecosave.watch.portal.helpers.common.md
import com.ecosave.watch.portal.helpers.common.sm
import com.ecosave.watch.portal.helpers.common.xs
import com.ecosave.watch.portal.models.billing.Facility
import com.ecosave.watch.portal.pages.mainScope
import com.ecosave.watch.portal.services.billing.getFacilities
import com.ecosave.watch.portal.styles.BillingTableStyles
import kotlinx.coroutines.launch
import mui.icons.material.KeyboardArrowLeft
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.FormControl
import mui.material.FormControlVariant
import mui.material.Grid
import mui.material.InputLabel
import mui.material.MenuItem
import mui.material.Select
import mui.material.Size
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.router.useNavigate
import react.useEffectOnce
import react.useState
import web.cssom.Display
import web.cssom.JustifyContent
import web.cssom.pct
import web.cssom.px

external interface UtilityBillSummaryHeaderProps : Props {
    var facilityId: String
    var onSelectFacilityId: (String) -> Unit
}

val Header = FC<UtilityBillSummaryHeaderProps> {
    val navigate = useNavigate()
    var facilityList: List<Facility> by useState(listOf())

    useEffectOnce {
        mainScope.launch {
            facilityList = getFacilities()
        }
    }

    Grid {
        container = true
        Grid {
            spacing = responsive(2)
            sx {
                marginTop = 10.px
                marginBottom = 10.px
                justifyContent = JustifyContent.flexStart
            }
            item = true
            xs = 12
            sm = 12
            md = 4
            container = true
            Box {
                className = BillingTableStyles.NAVIGATION_SECTOR.cssClass
                sx {
                    display = Display.flex
                }
                Button {
                    variant = ButtonVariant.contained
                    className = BillingTableStyles.NAVIGATION_BUTTON.cssClass
                    startIcon = startIcon.also {
                        KeyboardArrowLeft()
                    }
                    onClick = {
                        navigate.invoke(PageRoutes.UTILITY_ACCOUNTS.route)
                    }
                    +PageTitles.UTILITY_ACCOUNTS.title
                }
                FormControl {
                    sx {
                        minWidth = 100.pct
                    }
                    size = Size.small
                    fullWidth = true
                    variant = FormControlVariant.outlined
                    InputLabel {
                        +"Bills For Facility"
                    }
                    Select {
                        fullWidth = true
                        label = ReactNode("Bills For Facility")
                        value = it.facilityId
                        onChange = { event, _ ->
                            val value = event.target.value
                            it.onSelectFacilityId(value)
                        }
                        MenuItem {
                            value = "All"
                            +"All"
                        }
                        for (facility in facilityList) {
                            MenuItem {
                                value = facility.facilityId.toString()
                                +facility.facilityName
                            }
                        }
                    }
                }
            }
        }
    }
}
