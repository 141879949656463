package com.ecosave.watch.portal.components.energymanagement

import com.ecosave.watch.portal.helpers.energymanagement.getSecondColorForUtility
import com.ecosave.watch.portal.helpers.energymanagement.getColorForUtility
import com.ecosave.watch.portal.models.energymanagement.XAxisPayload
import moment.moment
import react.Props
import react.FC
import react.dom.svg.ReactSVG.g
import react.dom.svg.ReactSVG.text

external interface CustomXAxisContentComponentProps: Props {
    var x: Double
    var y: Double
    var name: String
    var payload: XAxisPayload
}

val CustomXAxisContentComponent = FC<CustomXAxisContentComponentProps> {props ->
    val dates = props.payload.value.split("|")
    val startMonth = moment(dates[0], "MMM YYYY").format("MMM")
    val startYear = moment(dates[0], "MMM YYYY").format("YYYY")
    val endMonth = moment(dates[1], "MMM YYYY").format("MMM")
    val endYear = moment(dates[1], "MMM YYYY").format("YYYY")

    val endFill = getSecondColorForUtility(props.name)
    val startFill = getColorForUtility(props.name)

    g {
        transform = "translate(${props.x},${props.y})"
        text {
            x = 0.0
            y = 12.0
            fill = endFill
            textAnchor = "start"
            +endMonth
        }
        text {
            x = 0.0
            y = 26.0
            fill = endFill
            textAnchor = "start"
            +endYear
        }
        text {
            x = 0.0
            y = 12.0
            fill = startFill
            textAnchor = "end"
            +startMonth
        }
        text {
            x = 0.0
            y = 26.0
            fill = startFill
            textAnchor = "end"
            +startYear
        }
    }
}