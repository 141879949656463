package com.ecosave.watch.portal.helpers.energymanagement

import com.ecosave.watch.portal.helpers.Colors

val EVENT_COLORS = arrayOf(Colors.VIVID_ORANGE, Colors.BRIGHT_CYAN, Colors.ROYAL_BLUE, Colors.VIBRANT_ORANGE)
var MONTHS = arrayOf("JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE", "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER")
var INITIAL_MONTH_PERCENT = mapOf(
    "JANUARY" to 0,
    "FEBRUARY" to 0,
    "MARCH" to 0,
    "APRIL" to 0,
    "MAY" to 0,
    "JUNE" to 0,
    "JULY" to 0,
    "AUGUST" to 0,
    "SEPTEMBER" to 0,
    "OCTOBER" to 0,
    "NOVEMBER" to 0,
    "DECEMBER" to 0,
)

enum class RadioLabels(val label: String, val value: String, val percent: String, val key: String, var totalTableKey: String) {
    USAGE("Energy", "utilityUsage", "Energy (%)", "Usage", "utilityUsageInMMBtu"),
    CO2("CO₂", "utilityCo2Emission", "CO₂ (%)", "CO₂", "utilityCo2Emission"),
    COST("$", "utilityCost", "$ (%)", "Cost", "utilityCost")
}

enum class BaseSectionLabels(val label: String, val value: String, val second: String) {
    USAGE("Energy", "baselineUsage", "targetUsage"),
    COST("Cost", "baselineCost", "targetCost")
}

data class UtilityUnit(var label: String, var prefix: String, var surfix: String)
