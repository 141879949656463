package com.ecosave.watch.portal.components.esg.diversityandequalopportunity

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val GRI_405_1 = FC<EsgSubFormComponentProps> { props ->
    val onChangeDecimalNumber = props.onChangeDecimalNumber
    val onBlurDecimalNumber = props.onBlurDecimalNumber

    props.esgReportState.report?.diversityAndEqualOpportunity?.let { state ->
        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_405_1
        }

        DisclosureSubHeading{
            subHeading = "Percentage of individuals within governance bodies in diversity category"
        }

        Box {
            className = ESGCommonStyles.TWO_COLUMN_TABLE_8_4.cssClass


            label {
                +"Male number of Board members"
            }
            ESGNumberFieldWrapper {
                name = state::section4051A1.name
                value = state.section4051A1
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Male percentage of Board members"
            }
            ESGNumberFieldWrapper {
                name = state::section4051A2.name
                value = state.section4051A2
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Female number of Board members"
            }
            ESGNumberFieldWrapper {
                name = state::section4051A3.name
                value = state.section4051A3
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Female percentage of Board members"
            }
            ESGNumberFieldWrapper {
                name = state::section4051A4.name
                value = state.section4051A4
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Number of Board members from a Minority or Vulnerable Group"
            }
            ESGNumberFieldWrapper {
                name = state::section4051A5.name
                value = state.section4051A5
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Percentage of Board members from a Minority or Vulnerable Group"
            }
            ESGNumberFieldWrapper {
                name = state::section4051A6.name
                value = state.section4051A6
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Percentage of Board members - Age <30 yrs old"
            }
            ESGNumberFieldWrapper {
                name = state::section4051A7.name
                value = state.section4051A7
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Percentage of Board members - Age 30-50 yrs old"
            }
            ESGNumberFieldWrapper {
                name = state::section4051A8.name
                value = state.section4051A8
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Percentage of Board members - Age >50 yrs old"
            }
            ESGNumberFieldWrapper {
                name = state::section4051A9.name
                value = state.section4051A9
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }
        }

        DisclosureSubHeading{
            subHeading = "Percentage of employees per employee category in each of the following diversity categories."
        }

        Box {
            className = ESGCommonStyles.TWO_COLUMN_TABLE_8_4.cssClass

            label {
                +"Management: Male number of employees"
            }
            ESGNumberFieldWrapper {
                name = state::section4051A10.name
                value = state.section4051A10
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Management: Male %"
            }
            ESGNumberFieldWrapper {
                name = state::section4051A11.name
                value = state.section4051A11
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Management: Female number of employees"
            }
            ESGNumberFieldWrapper {
                name = state::section4051A12.name
                value = state.section4051A12
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Management: Female %"
            }
            ESGNumberFieldWrapper {
                name = state::section4051A13.name
                value = state.section4051A13
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Management: Number of Minority or Vulnerable Group"
            }
            ESGNumberFieldWrapper {
                name = state::section4051A14.name
                value = state.section4051A14
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Management: Percentage of Minority or Vulnerable Group"
            }
            ESGNumberFieldWrapper {
                name = state::section4051A15.name
                value = state.section4051A15
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"% <30 yrs old - Total employees"
            }
            ESGNumberFieldWrapper {
                name = state::section4051A16.name
                value = state.section4051A16
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"% 30-50 yrs old - Total employees"
            }
            ESGNumberFieldWrapper {
                name = state::section4051A17.name
                value = state.section4051A17
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"% >50 yrs old - Total employees"
            }
            ESGNumberFieldWrapper {
                name = state::section4051A18.name
                value = state.section4051A18
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"% <30 yrs old - Managers"
            }
            ESGNumberFieldWrapper {
                name = state::section4051A19.name
                value = state.section4051A19
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"% 30-50 yrs old - Managers"
            }
            ESGNumberFieldWrapper {
                name = state::section4051A20.name
                value = state.section4051A20
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"% >50 yrs old - Managers"
            }
            ESGNumberFieldWrapper {
                name = state::section4051A21.name
                value = state.section4051A21
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }
        }
    }
}