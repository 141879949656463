package com.ecosave.watch.portal.models.billing

import kotlinx.serialization.Serializable

@Serializable
data class UtilityBillSummary(
    val accountNumber: String?,
    val serviceName: String,
    val surchargeBillExists: Boolean?,
    val solarNetMetered: Boolean?,
    val solarPPAInPlace: Boolean?,
    val coolingSteamUsed: Boolean?,
    val heatingSteamUsed: Boolean?,
    var utilityBill: UtilityBill
)