package com.ecosave.watch.portal.components.userprofile

import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.components.energymanagement.FacilitySelectionComponent
import com.ecosave.watch.portal.helpers.common.AutoCompleteOption
import com.ecosave.watch.portal.helpers.common.PageTitles
import com.ecosave.watch.portal.helpers.common.sm
import com.ecosave.watch.portal.helpers.common.xs
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.services.billing.getFacilities
import com.ecosave.watch.portal.services.saveFacilityPreference
import com.ecosave.watch.portal.useGlobalState
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Divider
import mui.material.Grid
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.useEffectOnce
import react.useState
import web.cssom.AlignItems
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.Float
import web.cssom.JustifyContent
import web.cssom.px
import web.cssom.unaryMinus

val UserPreferencesComponent = FC<Props> {

    val globalState = useGlobalState()
    var activeOption by useState<AutoCompleteOption>()
    var facilityOptions by useState<Array<AutoCompleteOption>>(emptyArray())
    val (notificationState, notificationStateSetter) = useState(NotificationState())
    var isSaving by useState(false)

    useEffectOnce {
        globalState.updatePageTitle(PageTitles.USER_PREFERENCES.title)
        mainScope.launch {
            val facilities = getFacilities()
            if (facilities.isNotEmpty()) {
                facilityOptions = facilities.map { item -> AutoCompleteOption(item.facilityName, "${item.facilityId}") }.toTypedArray()
                activeOption = AutoCompleteOption(facilities[0].facilityName, "${facilities[0].facilityId}")
            }
        }

    }

    Box {
        sx {
            display = Display.flex
            justifyContent = JustifyContent.center
            alignItems = AlignItems.center
            flexDirection = FlexDirection.column
        }
        Grid {
            container = true
            spacing = responsive(3)
            sx {
                maxWidth = 1200.px
            }
            Grid {
                item = true
                xs = 12
                Button {
                    sx {
                        float = Float.right
                    }
                    disabled = isSaving
                    variant = ButtonVariant.contained
                    onClick = {
                        mainScope.launch {
                            isSaving = true
                            val status = activeOption?.value?.let { it1 ->
                                activeOption?.label?.let { it2 ->
                                    saveFacilityPreference(
                                        it1.toInt(),
                                        it2
                                    )
                                }
                            }
                            if (status == null) {
                                notificationStateSetter(
                                    notificationState.copy(
                                        status = NotificationStatus.ERROR,
                                        message = "An error has occurred while saving facility, please retry.",
                                        visible = true
                                    )
                                )
                            } else {
                                notificationStateSetter(
                                    notificationState.copy(
                                        status = NotificationStatus.SUCCESS,
                                        message = "Preferences Saved Successfully",
                                        visible = true
                                    )
                                )
                            }
                            isSaving = false
                        }
                    }
                    if (isSaving) +"Saving Preferences..." else +"Save Preferences"
                }
            }
            Grid {
                item = true
                xs = 12
                Typography {
                    variant = TypographyVariant.h6
                    +"Facility Preferences"
                }
            }
            Grid {
                item = true
                xs = 12
                Divider {
                    sx {
                        marginTop = -20.px
                        marginBottom = 20.px
                    }
                }
            }
            Grid {
                item = true
                xs = 12
                container = true
                spacing = responsive(2)
                sx {
                    display = Display.flex
                    alignItems = AlignItems.center
                    justifyContent = JustifyContent.spaceBetween
                }
                Grid {
                    item = true
                    xs = 12
                    sm = 8
                    Typography {
                        variant = TypographyVariant.body1
                        +"Select the default facility you want to see when you land on Control Center and Facility Overview Page."
                    }
                }
                Grid {
                    item = true
                    xs = 12
                    sm = 4
                    FacilitySelectionComponent {
                        name = "facility"
                        options = facilityOptions
                        fullWidth = true
                        value = activeOption ?: AutoCompleteOption("", "")
                        onSelect = {
                            activeOption = it
                        }
                    }
                }
            }
        }
    }
    AlertNotifications {
        open = notificationState.visible
        status = notificationState.status
        message = notificationState.message
        closeNotification = {
            notificationStateSetter(
                notificationState.copy(
                    visible = false
                )
            )
        }
    }
}