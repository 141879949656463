package com.ecosave.watch.portal.components.resourcecenter.initialonboarding

import com.ecosave.watch.portal.components.resourcecenter.ResourcesSection
import com.ecosave.watch.portal.helpers.resourcecenter.InitialOnboardingImages.AccountCreatedImage
import com.ecosave.watch.portal.helpers.resourcecenter.InitialOnboardingImages.ClickVerifyImage
import com.ecosave.watch.portal.helpers.resourcecenter.InitialOnboardingImages.CreateAccount2Image
import com.ecosave.watch.portal.helpers.resourcecenter.InitialOnboardingImages.CreateAccountImage
import com.ecosave.watch.portal.helpers.resourcecenter.InitialOnboardingImages.SignInImage
import com.ecosave.watch.portal.styles.ResourceCenterStyles
import mui.material.List
import mui.system.Box
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.router.useNavigate
import web.cssom.px

external interface InitialOnboardingComponentProps : Props {
    var listPadding: Number
}

val InitialOnboardingComponent = FC<InitialOnboardingComponentProps> {
    List {
        sx {
            paddingLeft = it.listPadding.px
        }
        Box {
            id = "initial-onboarding-overview"
            className = ResourceCenterStyles.RESOURCES_SUBSECTION.cssClass
            ResourcesSection {
                title = "Initial Onboarding Overview"
                sentence = "This section offers a comprehensive overview of how to create and verify your account"
            }
        }

        Box {
            className = ResourceCenterStyles.RESOURCES_SUBSECTION.cssClass
            Box {
                id = "create-company-admin-account-1"
                ResourcesSection {
                    underlined = "Create Company/Admin Account"
                }
                ReactHTML.img {
                    src = CreateAccountImage
                    className = ResourceCenterStyles.RESOURCES_DISPLAY_OVERVIEW_BOX.cssClass
                }
                ReactHTML.img {
                    src = CreateAccount2Image
                    className = ResourceCenterStyles.RESOURCES_DISPLAY_OVERVIEW_BOX.cssClass
                }
            }
            Box {
                id = "account-created"
                className = ResourceCenterStyles.RESOURCES_SUBSECTION.cssClass
                ResourcesSection {
                    underlined = "Account Created"
                }
                ReactHTML.img {
                    src = AccountCreatedImage
                    className = ResourceCenterStyles.RESOURCES_DISPLAY_OVERVIEW_BOX.cssClass
                }
            }
            Box {
                id = "verify-account"
                className = ResourceCenterStyles.RESOURCES_SUBSECTION.cssClass
                ResourcesSection {
                    underlined = "Verify Account"
                }
                ReactHTML.img {
                    src = ClickVerifyImage
                    className = ResourceCenterStyles.RESOURCES_DISPLAY_OVERVIEW_BOX.cssClass
                }
            }
            Box {
                id = "sign-in"
                className = ResourceCenterStyles.RESOURCES_SUBSECTION.cssClass
                ResourcesSection {
                    underlined = "Sign In"
                }
                ReactHTML.img {
                    src = SignInImage
                    className = ResourceCenterStyles.RESOURCES_DISPLAY_OVERVIEW_BOX.cssClass
                }
            }
        }

    }
}