package com.ecosave.watch.portal.components.utilitybills

import com.ecosave.watch.portal.styles.CommonStyles
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.components.common.SortableTableCell
import com.ecosave.watch.portal.components.common.TableHeader
import com.ecosave.watch.portal.helpers.billing.BillingConstants
import com.ecosave.watch.portal.helpers.billing.getUtilityBillIndex
import com.ecosave.watch.portal.helpers.billing.getUtilityBillSummaryFilterParams
import com.ecosave.watch.portal.helpers.common.AutoCompleteOption
import com.ecosave.watch.portal.models.billing.UtilityBillSummary
import com.ecosave.watch.portal.models.billing.UtilityBillSummaryTableFilter
import com.ecosave.watch.portal.pages.mainScope
import com.ecosave.watch.portal.services.billing.getAccountNumbers
import com.ecosave.watch.portal.services.billing.getServiceNames
import com.ecosave.watch.portal.services.billing.getUtilityBills
import com.ecosave.watch.portal.styles.BillingTableStyles
import com.ecosave.watch.portal.useGlobalState
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableCellAlign
import mui.material.TableContainer
import mui.material.TableHead
import mui.material.TableRow
import mui.system.sx
import react.FC
import react.Props
import react.useEffect
import react.useState
import web.cssom.pct

external interface UtilityBillSummaryTableProps : Props {
    var facilityId: String
}

val UtilityBillSummaryTable = FC<UtilityBillSummaryTableProps> { props ->
    var isLoading by useState(true)
    var utilityBills: List<UtilityBillSummary> by useState(emptyList())
    var openSnackbar by useState(false)
    var notificationMessage by useState("")
    var notificationStatus by useState(NotificationStatus.SUCCESS)
    var editMode by useState(false)
    var sort by useState("")
    var sortBy by useState("")
    var counter by useState(0)
    var serviceNameLoaded by useState(false)
    var serviceNameOptions by useState<Array<AutoCompleteOption>>(emptyArray())
    var accountNumberOptions by useState<Array<AutoCompleteOption>>(emptyArray())
    val (filters, setFilters) = useState(UtilityBillSummaryTableFilter())
    val stateContextValue = useGlobalState()

    useEffect(props.facilityId, sort, sortBy, filters, serviceNameLoaded) {
        editMode = false
        mainScope.launch {
            if (serviceNameLoaded) {
                isLoading = true
                val id = if (props.facilityId == "All") {
                    null
                } else {
                    props.facilityId.toInt()
                }
                var params = "?sortOrder=${sort.uppercase()}&sortBy=$sortBy"
                if (id != null) {
                    params += "&facilityIds=$id"
                }
                val temp = getUtilityBillSummaryFilterParams(filters)
                params += temp.params
                counter = temp.counter
                utilityBills = getUtilityBills(params)
                isLoading = false
            }
        }
    }

    useEffect(props.facilityId) {
        editMode = false
        mainScope.launch {
            isLoading = true
            serviceNameLoaded = false
            val serviceNames: List<String>
            val accountNumbers: List<String>
            if (props.facilityId == "All") {
                serviceNames = getServiceNames()
                accountNumbers = getAccountNumbers()
            } else {
                val id = props.facilityId.toInt()
                serviceNames = getServiceNames("?facilityIds=$id")
                accountNumbers = getAccountNumbers("?facilityIds=$id")
            }
            serviceNameOptions = serviceNames.map {
                AutoCompleteOption(it, it)
            }.toTypedArray()
            accountNumberOptions = accountNumbers.map {
                AutoCompleteOption(it, it)
            }.toTypedArray()
            if (stateContextValue.accountNumber != null && stateContextValue.utilityType != null) {
                setFilters(
                    filters.copy(
                        accountNumber = mutableListOf(
                            AutoCompleteOption(
                                stateContextValue.accountNumber!!,
                                stateContextValue.accountNumber!!
                            )
                        ),
                        utilityType = mutableListOf(AutoCompleteOption(stateContextValue.utilityType!!.type, stateContextValue.utilityType!!.name))
                    )
                )
                stateContextValue.updateAccountNumber(null)
                stateContextValue.updateUtilityType(null)
            }
            isLoading = false
            serviceNameLoaded = true
        }
    }

    Box {
        TableFilter {
            currentServiceNameOptions = serviceNameOptions
            currentAccountNumberOptions = accountNumberOptions
            currentFilterOptions = filters
            setCurrentFilterOptions = setFilters
            facilityId = props.facilityId
            filterCount = counter
        }
        TableContainer {
            className = BillingTableStyles.TABLE_HEIGHT.cssClass
            Table {
                stickyHeader = true
                TableHead {
                    TableRow {
                        TableCell {
                            sx {
                                width = 5.pct
                            }
                        }
                        for (header in BillingConstants.utilityBillSummaryTableHeaders) {
                            SortableTableCell {
                                key = header.id
                                label = header.label
                                active = header.id == sortBy
                                isAsc = sort == "asc"
                                createSortHandler = {
                                    val isAsc = sortBy == header.id && sort == "asc"
                                    val toggleSort = if (isAsc) {
                                        "desc"
                                    } else {
                                        "asc"
                                    }
                                    sortBy = header.id
                                    sort = toggleSort
                                }
                            }
                        }
                        TableHeader {
                            tableHeaderName = "Actions"
                        }
                    }
                }
                TableBody {
                    if (isLoading) {
                        TableRow {
                            TableCell {
                                colSpan = 12
                                align = TableCellAlign.center
                                +"Fetching bills..."
                            }
                        }
                    } else {
                        if (utilityBills.isEmpty()) {
                            TableRow {
                                TableCell {
                                    colSpan = 12
                                    align = TableCellAlign.center
                                    +"No bills found."
                                }
                            }
                        } else {
                            for (utilityBill in utilityBills) {
                                UtilityBillSummaryDataRow {
                                    bill = utilityBill
                                    billData = utilityBills
                                    this.editMode = editMode
                                    setEditMode = { editModeValue ->
                                        editMode = editModeValue
                                    }
                                    editBill = { utilityBill ->
                                        val utilityBillList = utilityBills.toMutableList()
                                        val index = getUtilityBillIndex(utilityBillList, utilityBill.utilityBillId!!)
                                        utilityBillList[index].utilityBill = utilityBill
                                        utilityBills = utilityBillList
                                    }
                                    deleteBill = { utilityBillId ->
                                        val utilityBillList = utilityBills.toMutableList()
                                        val index = getUtilityBillIndex(utilityBillList, utilityBillId)
                                        utilityBillList.removeAt(index)
                                        utilityBills = utilityBillList
                                    }
                                    setNotificationMessage = { message ->
                                        notificationMessage = message
                                    }
                                    setNotificationStatus = { status ->
                                        notificationStatus = status
                                    }
                                    showNotification = {
                                        openSnackbar = true
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        AlertNotifications {
            open = openSnackbar
            status = notificationStatus
            message = notificationMessage
            closeNotification = {
                openSnackbar = false
            }
        }
    }
}
