package com.ecosave.watch.portal.models.billing.utilitybillstate

import kotlinx.serialization.Serializable

@Serializable
data class UtilityBillState(
    var utilityAccountId: Int? = null,
    var utilityBillId: Int? = null,
    var utilityType: String = "",
    var billStartDate: String = "",
    var billEndDate: String = "",
    var totalUsage: Float? = null,
    var totalBillCost: Float? = null,
    var billEstimated: Boolean? = null,
    var billAdjusted: Boolean? = null,
    var totalDeliveryDistribution: Float? = null,
    var usageUnit: String = "",
    var demandUnit: String = "",
    var electricAdditional: ElectricAdditional,
    var naturalGasAdditional: NaturalGasAdditional,
    var waterBillAdditional: WaterBillAdditional,
    var steamBillAdditional: SteamBillAdditional,
    var solarBillAdditional: SolarBillAdditional
)

@Serializable
data class ElectricAdditional(
    var totalPeakBilledDemand: Float? = null,
    var peakDemandCharges: Float? = null,
    var onPeakUsage: Float? = null,
    var onPeakUsageCharges: Float? = null,
    var offPeakUsage: Float? = null,
    var offPeakUsageCharges: Float? = null,
    var offPeakDemand: Float? = null,
    var onPeakDemand: Float? = null
)

@Serializable
data class NaturalGasAdditional(
    var totalCommoditySupply: Float? = null
)

@Serializable
data class WaterBillAdditional(
    var serviceCharges: Float? = null,
    var stormWaterCharges: Float? = null,
    var industrialSurcharge: Float? = null,
    var flow: Float? = null,
    var srf: Float? = null,
    var flowUsedForBilling: Float? = null,
    var biochemicalOxygenDemand: Float? = null,
    var bodSurchargeFactor: Float? = null,
    var bodCharges: Float? = null,
    var totalSuspendedSolids: Float? = null,
    var tssSurchargeFactor: Float? = null,
    var tssCharges: Float? = null,
    var totalSurchargeBill: Float? = null
)

@Serializable
data class SteamBillAdditional(
    var demandMeasured: Float? = null,
    var demandBilled: Float? = null,
    var totalHeatingSteamCharges: Float? = null,
    var heatingDemandCharges: Float? = null,
    var heatingSteamUsage: Float? = null,
    var heatingUsageCharges: Float? = null,
    var coolingSteamUsage: Float? = null,
    var totalCoolingSteamCharges: Float? = null
)

@Serializable
data class SolarBillAdditional(
    var totalSolarGenerated: Float? = null,
    var totalSolarCost: Float? = null,
    var solarExported: Float? = null,
    var solarExportedCredit: Float? = null
)