package com.ecosave.watch.portal.helpers.usermanagement

import com.ecosave.watch.portal.models.usermanagement.InvitedUserDetail
import com.ecosave.watch.portal.models.usermanagement.UserDeletionState

enum class FieldLabel(val description: String) {
    NAME("Name"),
    EMAIL("Email"),
    ROLE("Role"),
    STATUS("Status"),
    JOB_TITLE("Job Title")
}

enum class UserRole(val description: String) {
    ADMIN("Admin"),
    USER("User")
}

enum class Subscription(val description: String, val value: String) {
    BASE_PORTAL("base-portal", "BASE_PORTAL"),
    ADD_ESG("add-esg", "ADD_ESG")
}

val tableHeadersList = listOf(
    FieldLabel.NAME,
    FieldLabel.EMAIL,
    FieldLabel.ROLE,
    FieldLabel.JOB_TITLE,
    FieldLabel.STATUS
)

val userRoleLists = listOf(
    UserRole.ADMIN,
    UserRole.USER
)

enum class CompanyOrUserFields(val description: String) {
    FIRST_NAME("First Name *"),
    LAST_NAME("Last Name *"),
    EMAIL_ADDRESS("Email Address *"),
    CONFIRM_EMAIL_ADDRESS("Confirm Email Address *"),
    COMPANY_NAME("Company Name *"),
    JOB_TITLE("Job Title *"),
    PASSWORD("Password *"),
    CONFIRM_PASSWORD("Confirm Password *")
}

fun getUserIndex(userList: MutableList<InvitedUserDetail>, email: String): Int {
    val index = userList.indexOf(userList.find {
        it.emailAddress == email
    })
    return index
}

fun setStateForToBeDeletedUser(): UserDeletionState {
    return UserDeletionState(
        username = "",
        emailAddress = "",
        actionAssigned = 0,
        actionInProgress = 0
    )
}