package com.ecosave.watch.portal.helpers.billing

import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.models.billing.UtilityBillValidationState
import com.ecosave.watch.portal.models.billing.utilitybillstate.UtilityBillState
import kotlinx.datetime.LocalDate
import react.ReactNode
import react.StateSetter

fun validateBillStartDate(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.billStartDate.isEmpty()) {
        validation.billStartDateErrorState = true
        validation.billStartDateErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else if (state.billEndDate.isNotEmpty() && LocalDate.parse(state.billStartDate) >= LocalDate.parse(state.billEndDate)) {
        validation.billStartDateErrorState = true
        validation.billStartDateErrorMessage = ReactNode("Bill start date should be less than bill end date.")
    } else {
        validation.billStartDateErrorState = false
        validation.billStartDateErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun validateBillEndDate(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.billEndDate.isEmpty()) {
        validation.billEndDateErrorState = true
        validation.billEndDateErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else if (state.billStartDate.isNotEmpty() && LocalDate.parse(state.billEndDate) <= LocalDate.parse(state.billStartDate)) {
        validation.billEndDateErrorState = true
        validation.billEndDateErrorMessage = ReactNode("Bill end date should be greater than bill start date.")
    } else {
        validation.billEndDateErrorState = false
        validation.billEndDateErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun validateTotalUsage(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.totalUsage == null) {
        validation.totalUsageErrorState = true
        validation.totalUsageErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else if (state.totalUsage!!.compareTo(BillingConstants.MAX_VALUE) > 0) {
        validation.totalUsageErrorState = true
        validation.totalUsageErrorMessage = ReactNode(BillingConstants.INVALID_VALUE_ERROR_MESSAGE)
    } else {
        validation.totalUsageErrorState = false
        validation.totalUsageErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun validateTotalBillCost(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.totalBillCost == null) {
        validation.totalBillCostErrorState = true
        validation.totalBillCostErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else if (state.totalBillCost!!.compareTo(BillingConstants.MAX_COST) > 0) {
        validation.totalBillCostErrorState = true
        validation.totalBillCostErrorMessage = ReactNode(BillingConstants.INVALID_COST_ERROR_MESSAGE)
    } else {
        validation.totalBillCostErrorState = false
        validation.totalBillCostErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun validateBillAdjusted(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.billAdjusted == null) {
        validation.billAdjustedErrorState = true
        validation.billAdjustedErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.billAdjustedErrorState = false
        validation.billAdjustedErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun validateBillEstimated(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.billEstimated == null) {
        validation.billEstimatedErrorState = true
        validation.billEstimatedErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.billEstimatedErrorState = false
        validation.billEstimatedErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun isBillingCommonFieldsValid(
    validation: UtilityBillValidationState,
    utilityType: UtilityType
): Boolean {
    var fieldsNotNeededInSolar = true
    var fieldsNotNeededInHotAndChilledWater = true

    if (utilityType != UtilityType.SOLAR) {
        fieldsNotNeededInSolar =
            !(validation.totalBillCostErrorState || validation.totalUsageErrorState)
    }

    if (utilityType != UtilityType.CHILLED_WATER && utilityType != UtilityType.HOT_WATER) {
        fieldsNotNeededInHotAndChilledWater = !(validation.billAdjustedErrorState || validation.billEstimatedErrorState)
    }

    val commonFields =
        !(validation.billStartDateErrorState || validation.billEndDateErrorState)

    return fieldsNotNeededInSolar && fieldsNotNeededInHotAndChilledWater && commonFields
}

fun validateBillingCommonFields(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>,
    utilityType: UtilityType
) {
    if (utilityType != UtilityType.SOLAR) {
        validateTotalBillCost(state, validation, setValidation)
        validateTotalUsage(state, validation, setValidation)
    }

    if (utilityType != UtilityType.CHILLED_WATER && utilityType != UtilityType.HOT_WATER) {
        validateBillAdjusted(state, validation, setValidation)
        validateBillEstimated(state, validation, setValidation)
    }

    validateBillStartDate(state, validation, setValidation)
    validateBillEndDate(state, validation, setValidation)
}

fun isBillingHotOrChilledWaterFormValid(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>,
): Boolean {
    val utilityType = UtilityType.valueOf(state.utilityType)
    validateBillingCommonFields(
        state,
        validation,
        setValidation,
        utilityType
    )
    return isBillingCommonFieldsValid(
        validation,
        utilityType
    )
}