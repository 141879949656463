package com.ecosave.watch.portal.models.facilitygroupmanagement

import com.ecosave.watch.portal.helpers.common.AutoCompleteOption
import kotlinx.serialization.Serializable

@Serializable
data class ChildGroupStateDataClass(
    var parentGroupId: Int = 0,
    var childGroupName: String = "",
    var childGroupFacilitiesList: MutableList<Int> = mutableListOf()
)