package com.ecosave.watch.portal.components.esg.energy

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val GRI_302_3 = FC<EsgSubFormComponentProps> { props ->
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea
    val onChangeText = props.onChangeText
    val onBlurText = props.onBlurText
    val onChangeDecimalNumber = props.onChangeDecimalNumber
    val onBlurDecimalNumber = props.onBlurDecimalNumber

    props.esgReportState.report?.energy?.let { state ->
        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_302_3
        }

        DisclosureSubHeading {
            subHeading = "Absolute Energy Intensity"
        }

        Box {
            className = ESGCommonStyles.THREE_COLUMN_TABLE_8_2_2.cssClass

            TableHeader {
                header = ""
            }
            TableHeader {
                header = "Unit"
            }
            TableHeader {
                header = "Value"
            }

            label {
                +"Numerator"
            }
            ESGTextFieldWrapper {
                name = state::section3023IntensityUnit1.name
                value = state.section3023IntensityUnit1
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section3023IntensityCurrent1.name
                value = state.section3023IntensityCurrent1
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Denominator"
            }
            ESGTextFieldWrapper {
                name = state::section3023IntensityUnit2.name
                value = state.section3023IntensityUnit2
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section3023IntensityCurrent2.name
                value = state.section3023IntensityCurrent2
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }
        }

        Box {
            label {
                +"What are the types of energy included in the intensity ratio; whether fuel, electricity, heating, cooling, steam, or all?"
            }
            ESGTextAreaFieldWrapper {
                name = state::section3023Field1.name
                value = state.section3023Field1
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
        Box {
            label {
                +"Does this ratio use energy consumption within the organization, outside of it, or both?"
            }
            ESGTextAreaFieldWrapper {
                name = state::section3023Field2.name
                value = state.section3023Field2
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
        Box {
            label {
                +"Organization-specific metric (the denominator) chosen to calculate the ratio"
            }
            ESGTextAreaFieldWrapper {
                name = state::section3023Field3.name
                value = state.section3023Field3
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}