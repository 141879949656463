package com.ecosave.watch.portal.components.common

import com.ecosave.watch.portal.helpers.common.AutoCompleteOption
import mui.base.AutocompleteChangeReason
import mui.material.Autocomplete
import mui.material.AutocompleteProps
import mui.material.TextField
import react.FC
import react.Props
import react.create

external interface MultipleAutoCompleteProps : Props {
    var name: String
    var options: Array<AutoCompleteOption>
    var value: MutableList<AutoCompleteOption>
    var onSelect: (MutableList<AutoCompleteOption>) -> Unit
}

val MultipleAutoComplete = FC<MultipleAutoCompleteProps> { props ->
    @Suppress("UPPER_BOUND_VIOLATED")
    (Autocomplete<AutocompleteProps<AutoCompleteOption>> {
        multiple = true
        id = props.name
        options = props.options
        value = props.value.toTypedArray()
        size = "small"
        onChange = { _, _, reason, details ->
            val data = props.value
            if (reason == AutocompleteChangeReason.selectOption && details != null) {
                data.add(details.option)
            }
            if (reason == AutocompleteChangeReason.removeOption && details != null) {
                data.remove(details.option)
            }
            if (reason == AutocompleteChangeReason.clear) {
                data.clear()
            }
            props.onSelect(data)
        }
        renderInput = { params ->
            TextField.create {
                +params
            }
        }
    })
}