package com.ecosave.watch.portal.models.facilitymanagement

import com.ecosave.watch.portal.helpers.facilitymanagement.initializeOperatingSchedule
import kotlinx.datetime.DayOfWeek

data class FacilityState(
    var facilityName: String = "",
    var buildingCategory: String = "",
    var buildingType: String = "",
    var otherBuildingType: String = "",
    var buildingTotalArea: Int? = null,
    var street: String = "",
    var state: String = "",
    var city: String = "",
    var zipcode: String = "",
    var buildingYearBuilt: Int? = null,
    var facilityDescription: String = "",
    var propertyId: Int? = null,
    var buildingOccupancy: Int? = null,
    var energyStarSubscription: Boolean = false
)

data class FacilityOperatingScheduleState(
    var isOperating24x7: Boolean = false,
    var facilityOperatingSchedule: MutableList<DailyOperatingSchedule> = initializeOperatingSchedule()
)

data class DailyOperatingSchedule(
    var dayOfTheWeek: DayOfWeek,
    var startingHour: String = "00",
    var startingMinute: String = "00",
    var endingHour: String = "00",
    var endingMinute: String = "00"
)

