package com.ecosave.watch.portal.models.usermanagement

import kotlinx.serialization.Serializable

@Serializable
data class InvitedUserRegistration(
    val inviteToken: String,
    val firstName: String,
    val lastName: String,
    val jobTitle: String,
    val password: String
)