package com.ecosave.watch.portal.components.utilitybills

import com.ecosave.watch.portal.helpers.billing.BillingHeaders
import com.ecosave.watch.portal.helpers.billing.UsageUnit
import com.ecosave.watch.portal.models.billing.utilitybillstate.UtilityBillState
import com.ecosave.watch.portal.styles.BillingTableStyles
import mui.material.FormControl
import mui.material.FormControlVariant
import mui.material.MenuItem
import mui.material.Select
import mui.material.Size
import mui.material.TableCell
import react.FC
import react.Props
import react.StateSetter

external interface UsageUnitProps : Props {
    var stateUsageUnit: UtilityBillState
    var setStateUsageUnit: StateSetter<UtilityBillState>
    var usageUnits: List<UsageUnit>
}

val UsageUnit = FC<UsageUnitProps> { props ->

    val state = props.stateUsageUnit
    val setState = props.setStateUsageUnit
    val usageUnits = props.usageUnits

    TableCell {
        className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
        +BillingHeaders.USAGE_UNIT.header
    }
    TableCell {
        className = BillingTableStyles.TABLE_CELL.cssClass
        FormControl {
            fullWidth = true
            size = Size.small
            variant = FormControlVariant.filled
            hiddenLabel = true
            Select {
                value = state.usageUnit
                onChange = { event, _ ->
                    setState(
                        state.copy(
                            usageUnit = event.target.value
                        )
                    )
                }
                for (unit in usageUnits) {
                    MenuItem {
                        value = unit.name
                        +unit.description
                    }
                }
            }
        }
    }
}