package com.ecosave.watch.portal.helpers.resourcecenter

import com.ecosave.watch.portal.models.resourcecenter.Module
import com.ecosave.watch.portal.models.resourcecenter.ModuleContent
import com.ecosave.watch.portal.models.resourcecenter.ModuleNavigationItem

object ResourceCenterConstants {

    var modulesList = listOf<Module>(
        Module(
            ModuleName.INITIAL_ONBOARDING.title,
            "Creating & Registering Accounts",
            ModulePath.INITIAL_ONBOARDING.route
        ),
        Module(
            ModuleName.UTILITY_INFORMATION.title,
            "Managing Utility Data",
            ModulePath.UTILITY_INFORMATION.route
        ),
        Module(
            ModuleName.ENERGY_MANAGEMENT.title,
            "Managing Energy Data",
            ModulePath.ENERGY_MANAGEMENT.route
        ),
        Module(
            ModuleName.ASSET_MANAGEMENT.title,
            "Managing Assets",
            ModulePath.ASSET_MANAGEMENT.route
        ),
        Module(
            ModuleName.CONTROL_CENTER.title,
            "Managing Control Operations",
            ModulePath.CONTROL_CENTER.route
        ),
        Module(
            ModuleName.FACILITY_MANAGEMENT.title,
            "Managing Facility Management",
            ModulePath.FACILITY_MANAGEMENT.route
        ),
        Module(
            ModuleName.USER_MANAGEMENT.title,
            "Managing Users",
            ModulePath.USER_MANAGEMENT.route
        ),
        Module(
            ModuleName.FACILITY_GROUP_MANAGEMENT.title,
            "Managing Groups",
            ModulePath.FACILITY_GROUP_MANAGEMENT.route
        ),
        Module(
            ModuleName.WORK_ORDERS.title,
            "Managing Work Orders",
            ModulePath.WORK_ORDERS.route
        ),
    )


    val moduleContentUtilityInformation = ModuleContent(
        ModuleName.UTILITY_INFORMATION.title,
        listOf(
            ModuleNavigationItem(
                "Choose Bill",
            ),
            ModuleNavigationItem(
                "Confirm Selection",
            ),
        )
    )

    val moduleContentAssetManagement = ModuleContent(
        ModuleName.ASSET_MANAGEMENT.title,
        listOf(
            ModuleNavigationItem(
                "Asset Management Overview",
            ),
            ModuleNavigationItem(
                "Select Asset Management",
            ),
            ModuleNavigationItem(
                "Add Asset",
            ),
            ModuleNavigationItem(
                "Column Actions",
            ),
            ModuleNavigationItem(
                "Show/Hide Search",
            ),
            ModuleNavigationItem(
                "Show/Hide Filters",
            ),
            ModuleNavigationItem(
                "Show/Hide Columns",
            )
        )
    )
    val moduleContentInitialOnboarding = ModuleContent(
        ModuleName.INITIAL_ONBOARDING.title,
        listOf(
            ModuleNavigationItem(
                "Create Company/Admin Account",
            ),
            ModuleNavigationItem(
                "Verify Account",
            ),
            ModuleNavigationItem(
                "Sign In",
            ),
        )
    )
    val moduleContentEnergyManagement = ModuleContent(
        ModuleName.ENERGY_MANAGEMENT.title,
        listOf(
            ModuleNavigationItem(
                "Energy Management Overview - Default View",
            ),
            ModuleNavigationItem(
                "Selection of Baseline",
            ),
            ModuleNavigationItem(
                "Set Savings Target",
            ),
            ModuleNavigationItem(
                "Energy Management Overview - Baseline Comparison",
            ),
            ModuleNavigationItem(
                "Energy Management - Portfolio Overview",
            ),
            ModuleNavigationItem(
                "Facility-Group",
            ),
        )
    )

    val moduleContentControlCenter = ModuleContent(
        ModuleName.CONTROL_CENTER.title,
        listOf(
            ModuleNavigationItem(
                "Control Center Overview",
            ),
        )
    )
    val moduleContentFacilityManagement = ModuleContent(
        ModuleName.FACILITY_MANAGEMENT.title,
        listOf(
            ModuleNavigationItem(
                "Facility Management Overview",
            ),
            ModuleNavigationItem(
                "Facility Management Overview - Expanded",
            ),
            ModuleNavigationItem(
                "Edit Operating Hours",
            ),
            ModuleNavigationItem(
                "Edit Facility",
            ),
        )
    )
    val moduleContentFacilityGroupManagement = ModuleContent(
        ModuleName.FACILITY_GROUP_MANAGEMENT.title,
        listOf(
            ModuleNavigationItem(
                "Facility Group Management Overview",
            ),
            ModuleNavigationItem(
                "Add Facility Group",
            ),
        )
    )
    val moduleContentUserManagement = ModuleContent(
        ModuleName.USER_MANAGEMENT.title,
        listOf(
            ModuleNavigationItem(
                "User Management Overview",
            ),
            ModuleNavigationItem(
                "Edit User Role",
            ),
            ModuleNavigationItem(
                "Invite Users",
            )
        )
    )
    val moduleContentWorkOrders = ModuleContent(
        ModuleName.WORK_ORDERS.title,
        listOf(
            ModuleNavigationItem(
                "Observation Summary Overview",
            ),
            ModuleNavigationItem(
                "Observation Summary Filter",
            ),
            ModuleNavigationItem(
                "Action Management Overview",
            ),
            ModuleNavigationItem(
                "Action Management Assignment",
            ),
            ModuleNavigationItem(
                "Action Management Filter",
            ),
        )
    )
}