package com.ecosave.watch.portal.components.esg.securitypractices

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.models.esg.SecurityPracticesState
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val GRI_410_1 = FC<EsgSubFormComponentProps> { props ->
    val onChangeText = props.onChangeText
    val onBlurText = props.onBlurText
    val onChangeDecimalNumber = props.onChangeDecimalNumber
    val onBlurDecimalNumber = props.onBlurDecimalNumber

    props.esgReportState.report?.securityPractices?.let { state ->
        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_410_1
        }

        Box {
            className = ESGCommonStyles.TWO_COLUMN_TABLE.cssClass

            label {
                +"What is the percentage of security personnel who have received formal training in the organization’s human rights policies or specific procedures and their application to security?"
            }
            ESGNumberFieldWrapper {
                name = SecurityPracticesState::section4101A1.name
                value = state.section4101A1
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Whether training requirements also apply to third-party organizations providing security personnel?"
            }
            ESGTextFieldWrapper {
                name = SecurityPracticesState::section4101A2.name
                value = state.section4101A2
                onChange = { event ->
                    onChangeText(event, null, null)
                }
                onBlur = { event ->
                    onBlurText(event, null, null)
                }
            }
        }
    }
}