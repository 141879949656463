package com.ecosave.watch.portal.components.esg.customerhealthnsafety

import com.ecosave.watch.portal.components.esg.EsgCoreComponentProps
import com.ecosave.watch.portal.components.esg.GenericSection_3_3
import com.ecosave.watch.portal.components.esg.formcontrols.GRIStandardName
import com.ecosave.watch.portal.helpers.common.isValidLongNumber
import com.ecosave.watch.portal.helpers.esg.EsgSection
import com.ecosave.watch.portal.helpers.esg.omitDisclosureFromUI
import com.ecosave.watch.portal.models.esg.CurrentInputState
import com.ecosave.watch.portal.models.esg.CustomerHealthAndSafetyState
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import kotlinx.serialization.json.JsonPrimitive
import mui.material.Box
import react.FC
import react.dom.events.FocusEvent
import react.dom.events.FormEvent
import web.html.HTMLDivElement
import web.html.HTMLElement
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles as Titles

val CustomerHealthAndSafetyComponent = FC<EsgCoreComponentProps> { props ->
    val reportState = props.reportState
    val setReportState = props.setReportState

    reportState.report?.customerHealthAndSafety?.let { state ->
        fun stateSetter(state: CustomerHealthAndSafetyState) {
            setReportState(
                reportState.copy(
                    report = reportState.report.copy(
                        customerHealthAndSafety = state
                    )
                )
            )
        }

        val onChangeTextArea: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value.ifBlank { null }
            val newState = state.copy()

            when (propertyName) {

                state::section3_3_416A1.name -> newState.section3_3_416A1 = propertyValue
                state::section3_3_416A2.name -> newState.section3_3_416A2 = propertyValue
                state::section3_3_416A3.name -> newState.section3_3_416A3 = propertyValue
                state::section3_3_416A4.name -> newState.section3_3_416A4 = propertyValue
                state::section3_3_416A5.name -> newState.section3_3_416A5 = propertyValue
                state::section3_3_416A6.name -> newState.section3_3_416A6 = propertyValue

                state::section4161A1.name -> newState.section4161A1 = propertyValue
                state::section4162Field1.name -> newState.section4162Field1 = propertyValue
            }

            stateSetter(newState)

            props.setInputState(
                CurrentInputState(
                    childPath = propertyName,
                    value = JsonPrimitive(propertyValue),
                    objectName = EsgSection.CUSTOMER_HEALTH_AND_SAFETY.name,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurTextArea: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value
            props.onBlurApiCall(
                propertyName,
                JsonPrimitive(propertyValue),
                changeIndex,
                field,
                EsgSection.CUSTOMER_HEALTH_AND_SAFETY.name
            )
        }


        val onChangeNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            val propertyName = target.name

            if (isValidLongNumber(target)) {
                val propertyValue = target.value.toLongOrNull()
                val newState = state.copy()

                when (propertyName) {
                    state::section4162A1.name -> newState.section4162A1 = propertyValue
                    state::section4162A2.name -> newState.section4162A2 = propertyValue
                    state::section4162A3.name -> newState.section4162A3 = propertyValue
                    state::section4162A4.name -> newState.section4162A4 = propertyValue

                    state::section4162B1.name -> newState.section4162B1 = propertyValue
                    state::section4162B2.name -> newState.section4162B2 = propertyValue
                    state::section4162B3.name -> newState.section4162B3 = propertyValue
                    state::section4162B4.name -> newState.section4162B4 = propertyValue
                }


                stateSetter(newState)
                CurrentInputState(
                    childPath = propertyName,
                    value = JsonPrimitive(propertyValue),
                    objectName = EsgSection.CUSTOMER_HEALTH_AND_SAFETY.name,
                    reportName = reportState.reportFileName
                )
            }
        }

        val onBlurNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidLongNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toLongOrNull()
                props.onBlurApiCall(
                    propertyName,
                    JsonPrimitive(propertyValue),
                    changeIndex,
                    field,
                    EsgSection.CUSTOMER_HEALTH_AND_SAFETY.name
                )
            }
        }



        Box {
            className = ESGCommonStyles.SECTION_MAIN_LAYOUT.cssClass

            GRIStandardName {
                griStandard = EsgSection.CUSTOMER_HEALTH_AND_SAFETY
            }

            GenericSection_3_3 {
                this.onBlurTextArea = onBlurTextArea
                this.onChangeTextArea = onChangeTextArea
                materialTopicName = "Customer Health and Safety"
                section3_3_A1_name = state::section3_3_416A1.name
                section3_3_A1_value = state.section3_3_416A1

                section3_3_A2_name = state::section3_3_416A2.name
                section3_3_A2_value = state.section3_3_416A2

                section3_3_A3_name = state::section3_3_416A3.name
                section3_3_A3_value = state.section3_3_416A3

                section3_3_A4_name = state::section3_3_416A4.name
                section3_3_A4_value = state.section3_3_416A4

                section3_3_A5_name = state::section3_3_416A5.name
                section3_3_A5_value = state.section3_3_416A5

                section3_3_A6_name = state::section3_3_416A6.name
                section3_3_A6_value = state.section3_3_416A6
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, Titles.GRI_416_1)) {
                GRI_416_1 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                }
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, Titles.GRI_416_2)) {
                GRI_416_2 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeNumber = onChangeNumber
                    this.onBlurNumber = onBlurNumber
                }
            }
        }

    }

}