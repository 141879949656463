package com.ecosave.watch.portal.components.userprofile

import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.PageTitles
import com.ecosave.watch.portal.helpers.common.sm
import com.ecosave.watch.portal.helpers.common.xs
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.helpers.userprofile.isUserProfileFormValid
import com.ecosave.watch.portal.helpers.userprofile.validateFirstName
import com.ecosave.watch.portal.helpers.userprofile.validateJobTitle
import com.ecosave.watch.portal.helpers.userprofile.validateLastName
import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.models.userprofile.UserProfileState
import com.ecosave.watch.portal.models.userprofile.UserProfileValidationState
import com.ecosave.watch.portal.services.getUserProfile
import com.ecosave.watch.portal.services.saveUserProfile
import com.ecosave.watch.portal.useGlobalState
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Grid
import mui.material.Size
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.dom.onChange
import react.useEffectOnce
import react.useState
import web.cssom.AlignItems
import web.cssom.Display
import web.cssom.JustifyContent
import web.cssom.px
import web.html.HTMLInputElement
import web.html.InputType

val UserProfileComponent = FC<Props> {

    val (userProfileState, userProfileStateSetter) = useState(UserProfileState())
    val (userProfileValidationState, userProfileValidationStateSetter) = useState(UserProfileValidationState())
    val globalState = useGlobalState()
    val (notificationState, notificationStateSetter) = useState(NotificationState())
    var isSubmitting by useState(false)

    useEffectOnce {
        globalState.updatePageTitle(PageTitles.USER_PROFILE.title)
        mainScope.launch {
            val userProfile = getUserProfile()
            if (userProfile == null) {
                notificationStateSetter(
                    notificationState.copy(
                        status = NotificationStatus.ERROR,
                        message = Constants.NOTIFICATION_ERROR_MESSAGE,
                        visible = true
                    )
                )
            } else {
                userProfileStateSetter(
                    userProfileState.copy(
                        firstName = userProfile.firstName,
                        lastName = userProfile.lastName,
                        jobTitle = userProfile.jobTitle,
                        companyName = userProfile.companyName,
                        emailAddress = userProfile.emailAddress
                    )
                )
            }
        }
    }

    Box {
        sx {
            display = Display.flex
            justifyContent = JustifyContent.center
            alignItems = AlignItems.center
        }
        Grid {
            sx {
                marginTop = 50.px
                maxWidth = 1200.px
                width = 850.px
            }
            container = true
            spacing = responsive(3)
            Grid {
                item = true
                xs = 12
                sm = 6
                UserProfileTextFieldWrapper {
                    label = ReactNode("First Name *")
                    type = InputType.text
                    value = userProfileState.firstName
                    error = userProfileValidationState.firstNameErrorState
                    helperText = userProfileValidationState.firstNameErrorMessage
                    onChange = {
                        val target = it.target as HTMLInputElement
                        userProfileStateSetter(
                            userProfileState.copy(
                                firstName = target.value
                            )
                        )
                    }
                    onBlur = {
                        validateFirstName(userProfileState, userProfileValidationState, userProfileValidationStateSetter)
                    }
                }
            }
            Grid {
                item = true
                xs = 12
                sm = 6
                UserProfileTextFieldWrapper {
                    label = ReactNode("Last Name *")
                    type = InputType.text
                    value = userProfileState.lastName
                    error = userProfileValidationState.lastNameErrorState
                    helperText = userProfileValidationState.lastNameErrorMessage
                    onChange = {
                        val target = it.target as HTMLInputElement
                        userProfileStateSetter(
                            userProfileState.copy(
                                lastName = target.value
                            )
                        )
                    }
                    onBlur = {
                        validateLastName(userProfileState, userProfileValidationState, userProfileValidationStateSetter)
                    }
                }
            }
            Grid {
                item = true
                xs = 12
                sm = 6
                UserProfileTextFieldWrapper {
                    label = ReactNode("Job Title *")
                    type = InputType.text
                    value = userProfileState.jobTitle
                    error = userProfileValidationState.jobTitleErrorState
                    helperText = userProfileValidationState.jobTitleErrorMessage
                    onChange = {
                        val target = it.target as HTMLInputElement
                        userProfileStateSetter(
                            userProfileState.copy(
                                jobTitle = target.value
                            )
                        )
                    }
                    onBlur = {
                        validateJobTitle(userProfileState, userProfileValidationState, userProfileValidationStateSetter)
                    }
                }
            }
            Grid {
                item = true
                xs = 12
                sm = 6
                UserProfileTextFieldWrapper {
                    disabled = true
                    label = ReactNode("Email Address *")
                    type = InputType.text
                    value = userProfileState.emailAddress
                }
            }
            Grid {
                item = true
                xs = 12
                sm = 6
                UserProfileTextFieldWrapper {
                    disabled = true
                    label = ReactNode("Company Name *")
                    type = InputType.text
                    value = userProfileState.companyName
                    size = Size.small
                }
            }

            Grid {
                item = true
                xs = 12
                Button {
                    fullWidth = true
                    variant = ButtonVariant.contained
                    size = Size.large
                    disabled = isSubmitting
                    onClick = {
                        mainScope.launch {
                            if (isUserProfileFormValid(userProfileState, userProfileValidationState, userProfileValidationStateSetter)) {
                                isSubmitting = true
                                val status = saveUserProfile(userProfileState)
                                if (status == null) {
                                    notificationStateSetter(
                                        notificationState.copy(
                                            status = NotificationStatus.ERROR,
                                            message = Constants.NOTIFICATION_ERROR_MESSAGE,
                                            visible = true
                                        )
                                    )
                                } else {
                                    notificationStateSetter(
                                        notificationState.copy(
                                            status = NotificationStatus.SUCCESS,
                                            message = "Profile Saved Successfully.",
                                            visible = true
                                        )
                                    )
                                }
                                isSubmitting = false
                            }
                        }
                    }
                    if (isSubmitting) +"Saving Profile..." else +"Save Profile"
                }
            }
        }
    }
    AlertNotifications {
        open = notificationState.visible
        status = notificationState.status
        message = notificationState.message
        closeNotification = {
            notificationStateSetter(
                notificationState.copy(
                    visible = false
                )
            )
        }
    }
}
