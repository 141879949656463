package com.ecosave.watch.portal.components.esg.procurementpractices

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.Label
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.onChange

val GRI_204_1 = FC<EsgSubFormComponentProps> { props ->
    val onChangeDecimalNumber = props.onChangeDecimalNumber
    val onBlurDecimalNumber = props.onBlurDecimalNumber
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.procurementPractices?.let { state ->
        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_204_1
        }
        Box {
            className = ESGCommonStyles.GRID_WRAPPER.cssClass
            Box {
                Label {
                    label =
                        "Percentage of the procurement budget used for significant locations of operation that is spent on suppliers local to that operation (i.e. percentage of products and services purchased locally)"
                }
                ESGNumberFieldWrapper {
                    name = state::section2041A1.name
                    value = state.section2041A1
                    onChange = { event -> onChangeDecimalNumber(event, null, null) }
                    onBlur = { event -> onBlurDecimalNumber(event, null, null) }
                }
            }
            Box {
                Label {
                    label = "The organization’s geographical definition of ‘local’."
                }
                ESGTextAreaFieldWrapper {
                    name = state::section2041A2.name
                    value = state.section2041A2
                    onChange = { event -> onChangeTextArea(event, null, null) }
                    onBlur = { event -> onBlurTextArea(event, null, null) }
                }
            }
            Box {
                Label {
                    label = "The definition used for ‘significant locations of operation’."
                }
                ESGTextAreaFieldWrapper {
                    name = state::section2041A3.name
                    value = state.section2041A3
                    onChange = { event -> onChangeTextArea(event, null, null) }
                    onBlur = { event -> onBlurTextArea(event, null, null) }
                }
            }
        }
    }
}