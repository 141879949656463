package com.ecosave.watch.portal.models.userprofile

import kotlinx.serialization.Serializable

@Serializable
data class UserProfileUpdateApiRequest(
    var firstName: String,
    var lastName: String,
    var jobTitle: String
)
