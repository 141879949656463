package com.ecosave.watch.portal.models.billing

import react.ReactNode

data class UtilityAccountValidationState(
    var accountTypeErrorState: Boolean = false,
    var accountTypeErrorMessage: ReactNode? = null,
    var utilityServiceProviderErrorState: Boolean = false,
    var utilityServiceProviderErrorMessage: ReactNode? = null,
    var serviceNameErrorState: Boolean = false,
    var serviceNameErrorMessage: ReactNode? = null,
    var serviceAddressErrorState: Boolean = false,
    var serviceAddressErrorMessage: ReactNode? = null,
    var accountNumberErrorState: Boolean = false,
    var accountNumberErrorMessage: ReactNode? = null,
    var meterNumberErrorState: Boolean = false,
    var meterNumberErrorMessage: ReactNode? = null,
    var surchargeBillExistsErrorState: Boolean = false,
    var surchargeBillExistsErrorMessage: ReactNode? = null,
    var totalInstalledSolarCapacityErrorState: Boolean = false,
    var totalInstalledSolarCapacityErrorMessage: ReactNode? = null,
    var totalAnnualGenerationSolarErrorState: Boolean = false,
    var totalAnnualGenerationSolarErrorMessage: ReactNode? = null,
    var ppaInPlaceSolarErrorState: Boolean = false,
    var ppaInPlaceSolarErrorMessage: ReactNode? = null,
    var ppaServiceProviderSolarErrorState: Boolean = false,
    var ppaServiceProviderSolarErrorMessage: ReactNode? = null,
    var currentPPARateSolarErrorState: Boolean = false,
    var currentPPARateSolarErrorMessage: ReactNode? = null,
    var totalLengthOfPPASolarErrorState: Boolean = false,
    var totalLengthOfPPASolarErrorMessage: ReactNode? = null,
    var isSolarNetMeteredErrorState: Boolean = false,
    var isSolarNetMeteredErrorMessage: ReactNode? = null,
    var solarUsageUnitErrorState: Boolean = false,
    var solarUsageUnitErrorMessage: ReactNode? = null,
    var otherUtilityServiceProviderErrorState: Boolean = false,
    var otherUtilityServiceProviderErrorMessage: ReactNode? = null,
    var utilityServiceProviderWebsiteLinkErrorState: Boolean = false,
    var utilityServiceProviderWebsiteLinkErrorMessage: ReactNode? = null,
    var collectionMethodErrorState: Boolean = false,
    var collectionMethodErrorMessage: ReactNode? = null,
    var userIdErrorState: Boolean = false,
    var userIdErrorMessage: ReactNode? = null,
    var passwordErrorState: Boolean = false,
    var passwordErrorMessage: ReactNode? = null
)