package com.ecosave.watch.portal.services.esg

import com.ecosave.watch.portal.helpers.BackendReportingServiceURL
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.esg.EsgSection
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.transformByteArrayAndDownloadReportPdf
import com.ecosave.watch.portal.models.esg.EsgCollectionAddRow
import com.ecosave.watch.portal.models.esg.EsgCollectionDeleteRow
import com.ecosave.watch.portal.models.esg.EsgCollectionPatchUpdate
import com.ecosave.watch.portal.models.esg.EsgPatchUpdate
import com.ecosave.watch.portal.models.esg.EsgReportState
import com.ecosave.watch.portal.models.esg.IncludeOrExcludeSectionsRequest
import com.ecosave.watch.portal.models.esg.OmitOrIncludeDisclosureRequest
import com.ecosave.watch.portal.models.esg.OmitOrIncludeDisclosureResponse
import com.ecosave.watch.portal.models.esg.OmittedDetail
import com.ecosave.watch.portal.services.httpClient
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*


suspend fun createEsgReport(
    esgReportState: EsgReportState,
): HttpStatusCode? {
    try {
        val response: HttpResponse = httpClient.post("$BackendReportingServiceURL/esg-report") {
            setBody(esgReportState)
        }
        return when (response.status) {
            HttpStatusCode.Created -> HttpStatusCode.Created
            HttpStatusCode.Conflict -> HttpStatusCode.Conflict
            else -> null
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return null
}

suspend fun downloadReport(reportFileName: String): ApiCallStatus {
    return try {
        val reportByteArray = getReportByteArray(reportFileName)
        if (reportByteArray != null) {
            transformByteArrayAndDownloadReportPdf(reportByteArray, reportFileName)
            ApiCallStatus.SUCCESS
        } else {
            ApiCallStatus.FAILURE
        }
    } catch (e: Exception) {
        console.error(e.message)
        ApiCallStatus.FAILURE
    }
}

suspend fun getReportByteArray(reportFileName: String): ByteArray? {
    try {
        val response: HttpResponse =
            httpClient.get("$BackendReportingServiceURL/esg-report/download-report?reportFileName=$reportFileName")
        if (response.status == HttpStatusCode.OK) {
            return response.readBytes()
        }
    } catch (e: Exception) {
        console.log("Error: ${e.message}")
    }
    return null
}

suspend fun esgDownloadReportSection(reportFileName: String, section: EsgSection? = null): ApiCallStatus {
    return try {
        val pdfReport = downloadReportSection(reportFileName, section)

        if (pdfReport != null) {
            transformByteArrayAndDownloadReportPdf(pdfReport, reportFileName)
            ApiCallStatus.SUCCESS
        } else {
            ApiCallStatus.FAILURE
        }
    } catch (e: Exception) {
        console.error(e.message)
        ApiCallStatus.FAILURE
    }
}

suspend fun downloadReportSection(reportFileName: String, section: EsgSection? = null): ByteArray? {
    try {
        val response: HttpResponse =
            httpClient.get("$BackendReportingServiceURL/esg-report/preview-report?reportFileName=$reportFileName&section=$section")
        if (response.status == HttpStatusCode.OK) {
            return response.readBytes()
        }
    } catch (e: Exception) {
        console.log("Error: ${e.message}")
    }
    return null
}

suspend fun getAllReports(): List<EsgReportState>? {
    try {
        val response: HttpResponse = httpClient.get("$BackendReportingServiceURL/esg-report/all")

        if (response.status == HttpStatusCode.OK) {
            return response.body()
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return null
}

suspend fun deleteReport(reportFileName: String): ApiCallStatus {
    try {
        val response: HttpResponse =
            httpClient.delete("$BackendReportingServiceURL/esg-report/?reportFileName=$reportFileName")
        if (response.status == HttpStatusCode.OK) {
            return ApiCallStatus.SUCCESS
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return ApiCallStatus.FAILURE
}

suspend fun includeOrExcludeSections(reportFileName: String, selectedSectionsList: MutableList<EsgSection>): EsgReportState? {

    try {
        val response: HttpResponse = httpClient.put("$BackendReportingServiceURL/esg-report/update-esg-sections") {
            setBody(
                IncludeOrExcludeSectionsRequest(
                    reportFileName,
                    selectedSectionsList
                )
            )
        }
        if (response.status == HttpStatusCode.OK) {
            return response.body()
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return null
}

suspend fun patchEsgReport(patch: EsgPatchUpdate): EsgReportState? {
    try {
        val response: HttpResponse = httpClient.patch("$BackendReportingServiceURL/esg-report") {
            setBody(patch)
        }
        if (response.status == HttpStatusCode.OK) {
            val report: EsgReportState = response.body()
            return report
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return null
}

suspend fun addDynamicRowOrSection(patch: EsgCollectionAddRow): ApiCallStatus {
    try {
        val response: HttpResponse = httpClient.post("$BackendReportingServiceURL/esg-report/collection-item") {
            setBody(patch)
        }
        if (response.status == HttpStatusCode.Created) {
            return ApiCallStatus.SUCCESS
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return ApiCallStatus.FAILURE
}

suspend fun deleteDynamicRowOrSection(patch: EsgCollectionDeleteRow): ApiCallStatus {
    try {
        val response: HttpResponse =
            httpClient.delete("$BackendReportingServiceURL/esg-report/collection-item?objectPatchEnum=${patch.objectPatchEnum}&reportFileName=${patch.reportFileName}&pathFirst=${patch.pathFirst}&indexAt=${patch.indexAt}")
        if (response.status == HttpStatusCode.OK) {
            return ApiCallStatus.SUCCESS
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return ApiCallStatus.FAILURE
}

/* objectPatchEnum is of type EsgSection in EsgCollectionDeleteRow and EsgSection do not contain General Disclosures subsections,
 below call is overload of above call to avoid refactor */

suspend fun deleteDynamicRowOrSection(objectPatchEnum: String, reportFileName: String, pathFirst: String, indexAt: Int): ApiCallStatus {
    try {
        val response: HttpResponse =
            httpClient.delete("$BackendReportingServiceURL/esg-report/collection-item?objectPatchEnum=${objectPatchEnum}&reportFileName=${reportFileName}&pathFirst=${pathFirst}&indexAt=${indexAt}")
        if (response.status == HttpStatusCode.OK) {
            return ApiCallStatus.SUCCESS
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return ApiCallStatus.FAILURE
}

suspend fun autoSaveEsg(patch: EsgPatchUpdate): ApiCallStatus {
    try {
        val response: HttpResponse = httpClient.patch("$BackendReportingServiceURL/esg-report") {
            setBody(patch)
        }
        if (response.status == HttpStatusCode.OK) {
            return ApiCallStatus.SUCCESS
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return ApiCallStatus.FAILURE
}

suspend fun autoSaveEsgCollection(patch: EsgCollectionPatchUpdate): ApiCallStatus {
    try {
        val response: HttpResponse = httpClient.patch("$BackendReportingServiceURL/esg-report/collection-item") {
            setBody(patch)
        }
        if (response.status == HttpStatusCode.OK) {
            return ApiCallStatus.SUCCESS
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return ApiCallStatus.FAILURE
}

suspend fun omitOrIncludeDisclosure(
    omittedDetail: OmittedDetail?,
    reportFileName: String,
    disclosure: GriDisclosureTitles,
): OmitOrIncludeDisclosureResponse? {
    try {
        val response: HttpResponse = httpClient.post("$BackendReportingServiceURL/esg-report/omission-reason") {
            setBody(
                OmitOrIncludeDisclosureRequest(
                    reportFileName = reportFileName,
                    disclosure = disclosure,
                    omittedDetail = omittedDetail
                )
            )
        }
        if (response.status == HttpStatusCode.Created) {
            return response.body()
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return null
}
