package com.ecosave.watch.portal.pages

import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.components.common.Loading
import com.ecosave.watch.portal.components.common.TableHeader
import com.ecosave.watch.portal.components.workorder.ObservationsFilterComponent
import com.ecosave.watch.portal.components.workorder.ObservationsTableRowComponent
import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.helpers.common.AutoCompleteOption
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.PageTitles
import com.ecosave.watch.portal.helpers.workorder.WorkOrderConstants
import com.ecosave.watch.portal.helpers.workorder.getObservationsFilterParams
import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.models.workorder.Observation
import com.ecosave.watch.portal.models.workorder.ObservationsFilterDataClass
import com.ecosave.watch.portal.services.billing.getAccountNumbers
import com.ecosave.watch.portal.services.getAllFacilities
import com.ecosave.watch.portal.services.workorder.getEventFilterOptions
import com.ecosave.watch.portal.services.workorder.getObservations
import com.ecosave.watch.portal.styles.CommonStyles
import com.ecosave.watch.portal.useGlobalState
import kotlinx.coroutines.launch
import mui.icons.material.ChevronRight
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableCellAlign
import mui.material.TableContainer
import mui.material.TableHead
import mui.material.TableRow
import mui.system.sx
import react.FC
import react.Props
import react.router.useNavigate
import react.useEffect
import react.useEffectOnce
import react.useState
import web.cssom.Display
import web.cssom.JustifyContent
import web.cssom.px

val WorkOrderObservationSummaryPage = FC<Props> {

    val navigate = useNavigate()
    var observationsList by useState<MutableList<Observation>>(mutableListOf())
    val (notificationState, setNotificationState) = useState(NotificationState())
    var isLoading by useState(false)
    val (filters, filtersStateSetter) = useState(
        ObservationsFilterDataClass()
    )
    var activeFiltersCount by useState(0)
    var eventFilterOptions by useState<Array<AutoCompleteOption>>(emptyArray())
    var facilityFilterOptions by useState<Array<AutoCompleteOption>>(emptyArray())
    var accountNumberFilterOptions by useState<Array<AutoCompleteOption>>(emptyArray())
    val globalState = useGlobalState()

    useEffectOnce {
        globalState.updatePageTitle(PageTitles.OBSERVATION_SUMMARY.title)
        com.ecosave.watch.portal.helpers.mainScope.launch {
            isLoading = true
            val observations = getObservations()
            val events = getEventFilterOptions()
            val facilities = getAllFacilities()
            val accountNumbers = getAccountNumbers()
            if (events.isNotEmpty()) {
                eventFilterOptions = events.map {
                    AutoCompleteOption(it.event, it.eventId.toString())
                }.toTypedArray()
            }
            if (facilities.isNotEmpty()) {
                facilityFilterOptions = facilities.map {
                    AutoCompleteOption(it.facilityName.toString(), it.facilityId.toString())
                }.toTypedArray()
            }
            if (accountNumbers.isNotEmpty()) {
                accountNumberFilterOptions = accountNumbers.map {
                    AutoCompleteOption(it, it)
                }.toTypedArray()
            }
            if (observations == null) {
                setNotificationState(
                    notificationState.copy(
                        status = NotificationStatus.ERROR,
                        message = Constants.NOTIFICATION_ERROR_MESSAGE,
                        visible = true
                    )
                )
            } else {
                observationsList = observations
            }
            isLoading = false
        }
    }

    useEffect(filters) {
        mainScope.launch {
            isLoading = true
            var params = "?"
            val filterParams = getObservationsFilterParams(filters)
            params += filterParams.params
            activeFiltersCount = filterParams.counter
            observationsList = getObservations(params) ?: mutableListOf()
            isLoading = false
        }
    }

    Box {
        Box {
            sx {
                marginBottom = 10.px
                display = Display.flex
                justifyContent = JustifyContent.spaceBetween
            }
            Button {
                variant = ButtonVariant.contained
                onClick = {
                    navigate.invoke("/action-management")
                }
                +"Action Management"
                endIcon = endIcon.also {
                    ChevronRight()
                }
            }
            ObservationsFilterComponent {
                currentFilterOptions = filters
                currentFilterOptionsStateSetter = filtersStateSetter
                this.eventFilterOptions = eventFilterOptions
                this.facilityFilterOptions = facilityFilterOptions
                this.accountNumberFilterOptions = accountNumberFilterOptions
                filtersCount = activeFiltersCount
            }
        }
        Box {
            TableContainer {
                className = CommonStyles.TABLE_HEIGHT.cssClass
                Table {
                    stickyHeader = true
                    TableHead {
                        for (header in WorkOrderConstants.observationsTableHeaderList) {
                            TableHeader {
                                tableHeaderName = header
                            }
                        }
                    }

                    TableBody {
                        if (isLoading) {
                            TableRow {
                                TableCell {
                                    colSpan = 11
                                    className = CommonStyles.REMOVED_CELL_BOTTOM_BORDER.cssClass
                                    align = TableCellAlign.center
                                    Box {
                                        sx {
                                            height = 400.px
                                        }
                                        Loading()
                                    }
                                }
                            }
                        } else {
                            if (observationsList.isEmpty()) {
                                TableRow {
                                    TableCell {
                                        colSpan = 11
                                        align = TableCellAlign.center
                                        +"No Observations Found"
                                    }
                                }
                            } else {
                                for (observation in observationsList) {
                                    ObservationsTableRowComponent {
                                        this.observation = observation
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    AlertNotifications {
        open = notificationState.visible
        status = notificationState.status
        message = notificationState.message
        closeNotification = {
            setNotificationState(
                notificationState.copy(
                    visible = false
                )
            )
        }
    }
}