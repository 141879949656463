package com.ecosave.watch.portal.helpers.aboutus

class AboutUsStatements {
    companion object {
        //NOTE all are upper snake case when constants
        val MISSION_STATEMENT =
            "EcosaveWatch is a proprietarily developed, boot-strapped energy analytics tool that pairs real-time utility monitoring tools with on-call problem-solving support to help businesses manage their resources, cut operational expenses and reduce energy consumption.By connecting to your building’s energy systems, EcosaveWatch provides real-time, actionable energy consumption data, allowing property management teams to power critical infrastructure, and significantly reduce utility costs and carbon emissions for maximal ROI. EcosaveWatch also provides emissions reporting, enabling you to benchmark your emissions output and helping to keep your business in-line with local, state, and federal emissions regulations."
        val ABOUT_US =
            "EcosaveWatch is a division of Ecosave, a privately owned energy-as-a-service provider powered by deep, leading-edge technical and engineering competency in energy and water conservation, renewable geothermal and solar solutions.Founded in 2002, Ecosave is headquartered in Philadelphia, Pennsylvania, with offices in Washington, D.C, New York City and Boston. EcosaveWatch launched in xxx and today serves xxx in xxxx"
        val WHAT_PROBLEM = "What Problem In Our Industry Are we Solving?"
        val DATA_WITHOUT_ACTION = "Data without action has no value / does not help combat climate change"
        val OUR_JOURNEY = "What's Our Journey To A Solution?"
        val BOOTSTRAPPED = "Bootstrapped in-house analytical tools -> market success -> dedicated investment -> full stack dev -> full product suite"
        val MARKET_GROWTH = "Market growth and high-profile customers."
        val MILESTONES = "What Are Our Milestones and Wins?"
        val ENERGY_DECISIONS =
            "We want to optimize every energy decision and transaction to promote the best possible return on investment for the built environment and our planet. "
        val WHAT_MISSION = "What's Our Mission? "
    }
}

