package com.ecosave.watch.portal.components.esg.generaldisclosures.organizationanditsreportingpractices

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.Label
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val Disclosure_2_1 = FC<EsgSubFormComponentProps> { props ->

    val onChangeText = props.onChangeText
    val onBlurText = props.onBlurText

    props.esgReportState.report?.theOrgAndItsRepPractices?.let { state ->

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_2_1
        }

        Box {
            label {
                +"Nature of Ownership & Legal Form"
            }
            ESGTextFieldWrapper {
                name = state::natureOfOwnershipLegalForm.name
                value = state.natureOfOwnershipLegalForm
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
        }
        Box {
            label {
                +"Legal Name Of Organization"
            }
            ESGTextFieldWrapper {
                name = state::section21Field2.name
                value = state.section21Field2
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
        }

        DisclosureSubHeading {
            subHeading = "Location of Headquarters"
        }

        Box {
            label {
                +"Street Address"
            }
            ESGTextFieldWrapper {
                name = state::section21Field3.name
                value = state.section21Field3
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
        }
        Box {
            label {
                +"Street Address Line 2"
            }
            ESGTextFieldWrapper {
                name = state::section21Field4.name
                value = state.section21Field4
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
        }
        Box {
            label {
                +"City"
            }
            ESGTextFieldWrapper {
                name = state::section21Field5.name
                value = state.section21Field5
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
        }
        Box {
            label {
                +"State"
            }
            ESGTextFieldWrapper {
                name = state::section21Field6.name
                value = state.section21Field6
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
        }
        Box {
            label {
                +"Zip Code"
            }
            ESGTextFieldWrapper {
                name = state::section21Field7.name
                value = state.section21Field7
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
        }

        DisclosureSubHeading {
            subHeading = "Countries of Operation"
        }

        Box {
            Label {
                label = "Countries of Operation"
            }
            ESGTextFieldWrapper {
                name = state::section21Field8.name
                value = state.section21Field8
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
        }
    }
}