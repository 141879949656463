package com.ecosave.watch.portal.components

import com.ecosave.watch.portal.styles.CommonStyles
import com.ecosave.watch.portal.helpers.common.ScreenBreakPoints
import mui.icons.material.PhoneIphoneOutlined
import mui.icons.material.TabletMacOutlined
import mui.material.Box
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML
import web.cssom.em

external interface BrowserResizeErrorComponentProps : Props {
    var browserWidth: Int
    var visible: Boolean
}

val BrowserResizeErrorComponent = FC<BrowserResizeErrorComponentProps> { props ->

    if (props.visible) {
        Box {
            className = CommonStyles.BROWSER_RESIZE.cssClass
            Box {
                if (props.browserWidth <= ScreenBreakPoints.mobile) {
                    PhoneIphoneOutlined {
                        sx {
                            width = 3.em
                            height = 3.em
                        }
                    }
                } else {
                    TabletMacOutlined {
                        sx {
                            width = 3.em
                            height = 3.em
                        }
                    }
                }
            }
            Typography {
                className = CommonStyles.HEADING_TITLE.cssClass
                variant = TypographyVariant.h3
                +"Uh oh!"
            }
            Typography {
                className = CommonStyles.SUBTITLE.cssClass
                variant = TypographyVariant.h6
                component = ReactHTML.h4
                if (props.browserWidth <= ScreenBreakPoints.mobile) +"EcosaveWatch web is not supported on this screen yet." else +"This page is not supported on this screen yet."
            }
        }
    }
}