package com.ecosave.watch.portal.components.esg.generaldisclosures.activitiesandworkers

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val Disclosure_2_6 = FC<EsgSubFormComponentProps> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.activitiesAndWorkers?.let { state ->

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_2_6
        }

        Box {
            label {
                +"Sectors in which organization is active."
            }
            ESGTextAreaFieldWrapper {
                name = state::section26Field1.name
                value = state.section26Field1
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"Describe the organization’s activities, products, services, and markets served."
            }
            ESGTextAreaFieldWrapper {
                name = state::section26Field2.name
                value = state.section26Field2
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"Describe the organization’s supply chain."
            }
            ESGTextAreaFieldWrapper {
                name = state::section26Field3.name
                value = state.section26Field3
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"Describe the entities downstream from the organization and their activities."
            }
            ESGTextAreaFieldWrapper {
                name = state::section26Field4.name
                value = state.section26Field4
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"Report other relevant business relationships."
            }
            ESGTextAreaFieldWrapper {
                name = state::section26Field5.name
                value = state.section26Field5
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"Describe significant changes compared to the previous reporting period."
            }
            ESGTextAreaFieldWrapper {
                name = state::section26Field6.name
                value = state.section26Field6
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}