package com.ecosave.watch.portal.components.esg.generaldisclosures.organizationanditsreportingpractices

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange


val Disclosure_2_5 = FC<EsgSubFormComponentProps> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.theOrgAndItsRepPractices?.let { state ->

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_2_5
        }

        Box {
            label {
                +"Describe its policy and practice for seeking external assurance, including whether and how the highest governance body and senior executives are involved."
            }
            ESGTextAreaFieldWrapper {
                name = state::section25Field1.name
                value = state.section25Field1
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"Provide a link or reference to the external assurance report(s) or assurance statement(s)."
            }
            ESGTextAreaFieldWrapper {
                name = state::section25Field4.name
                value = state.section25Field4
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"Describe what has been assured and on what basis, including the assurance standards used, the level of assurance obtained, and any limitations of the assurance process."
            }
            ESGTextAreaFieldWrapper {
                name = state::section25Field5.name
                value = state.section25Field5
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"Describe the relationship between the organization and the assurance provider."
            }
            ESGTextAreaFieldWrapper {
                name = state::section25Field6.name
                value = state.section25Field6
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

    }

}