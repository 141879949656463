package com.ecosave.watch.portal.components.esg.employment

import com.ecosave.watch.portal.components.common.AlphaListWrapper
import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.*
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.onChange

val GRI_401_1 = FC<EsgSubFormComponentProps> { props ->
    val onChangeText = props.onChangeText
    val onBlurText = props.onBlurText
    val onChangeNumber = props.onChangeNumber
    val onBlurNumber = props.onBlurNumber
    val onChangeDecimalNumber = props.onChangeDecimalNumber
    val onBlurDecimalNumber = props.onBlurDecimalNumber

    props.esgReportState.report?.employment?.let { state ->
        val newHiresTurnoverListName = state::newHiresTurnoverList.name
        val sevenColumnsGridCSS = ESGCommonStyles.SEVEN_COLUMN_TABLE.cssClass

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_401_1
        }

        AlphaListWrapper(
            title = "The reporting organization shall report the following information:",
            list = listOf(
                "Total number and rate of new employee hires during the reporting period, by age group,\n" +
                    "gender and region.",
                "Total number and rate of employee turnover during the reporting period, by age group,\n" +
                    "gender and region."
            ),
        )

        AlphaListWrapper(
            title = "An organization can use the following age groups:",
            list = listOf(
                "Under 30 years old",
                "30-50 years old",
                "Over 50 years old"
            )
        )

        Box {
            className = ESGCommonStyles.TABLE_WRAPPER.cssClass
            DynamicTableWrapper {
                addRowOrSection = { props.addRowOrSection(newHiresTurnoverListName) }
                items = state.newHiresTurnoverList
                action = DynamicTableAction.ADD
                addRowOrSectionBtnText = ReportConstants.ADD_ROW_MESSAGE
                Box {
                    className = sevenColumnsGridCSS
                    val headers = listOf(
                        "Region",
                        "Age Group",
                        "Gender Group",
                        "New Hires",
                        "New Hires Rate",
                        "Turnover Count",
                        "Turnover Rage"
                    )
                    for (inHeader in headers) {
                        TableHeader {
                            header = inHeader
                        }
                    }
                }
            }
            state.newHiresTurnoverList.forEachIndexed{ index, newHiresTurnover ->
                DynamicTableWrapper {
                    key = newHiresTurnover.key
                    deleteRowOrSection = {props.deleteRowOrSection(newHiresTurnoverListName, index) }
                    items = state.newHiresTurnoverList
                    action = DynamicTableAction.DELETE
                    variant = DynamicTableVariant.TABLE
                    Box {
                        className = sevenColumnsGridCSS
                        ESGTextFieldWrapper {
                            name = newHiresTurnover::emp401region.name
                            value = newHiresTurnover.emp401region
                            onChange = { event -> onChangeText(event, index, newHiresTurnoverListName) }
                            onBlur = { event -> onBlurText(event, index, newHiresTurnoverListName) }
                        }
                        ESGTextFieldWrapper {
                            name = newHiresTurnover::emp401ageGroup.name
                            value = newHiresTurnover.emp401ageGroup
                            onChange = { event -> onChangeText(event, index, newHiresTurnoverListName) }
                            onBlur = { event -> onBlurText(event, index, newHiresTurnoverListName) }
                        }
                        ESGTextFieldWrapper {
                            name = newHiresTurnover::emp401sexGroup.name
                            value = newHiresTurnover.emp401sexGroup
                            onChange = { event -> onChangeText(event, index, newHiresTurnoverListName) }
                            onBlur = { event -> onBlurText(event, index, newHiresTurnoverListName) }
                        }
                        ESGNumberFieldWrapper {
                            name = newHiresTurnover::newHires401count.name
                            value = newHiresTurnover.newHires401count
                            onChange = { event -> onChangeNumber(event, index, newHiresTurnoverListName) }
                            onBlur = { event -> onBlurNumber(event, index, newHiresTurnoverListName) }
                        }
                        ESGNumberFieldWrapper {
                            name = newHiresTurnover::newHires401rate.name
                            value = newHiresTurnover.newHires401rate
                            onChange = { event -> onChangeDecimalNumber(event, index, newHiresTurnoverListName) }
                            onBlur = { event -> onBlurDecimalNumber(event, index, newHiresTurnoverListName) }
                        }
                        ESGNumberFieldWrapper {
                            name = newHiresTurnover::turnover401count.name
                            value = newHiresTurnover.turnover401count
                            onChange = { event -> onChangeNumber(event, index, newHiresTurnoverListName) }
                            onBlur = { event -> onBlurNumber(event, index, newHiresTurnoverListName) }
                        }
                        ESGNumberFieldWrapper {
                            name = newHiresTurnover::turnover401rate.name
                            value = newHiresTurnover.turnover401rate
                            onChange = { event -> onChangeDecimalNumber(event, index, newHiresTurnoverListName) }
                            onBlur = { event -> onBlurDecimalNumber(event, index, newHiresTurnoverListName) }
                        }
                    }
                }
            }
        }
    }
}