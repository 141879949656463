package com.ecosave.watch.portal.components

import com.ecosave.watch.portal.helpers.usermanagement.Subscription
import com.ecosave.watch.portal.helpers.usermanagement.UserRole
import com.ecosave.watch.portal.useGlobalState
import react.FC
import react.Props
import react.router.Navigate
import react.router.Outlet

val EsgOnlyPages = FC<Props> {

    val globalState = useGlobalState()

    if (globalState.userData.subscriptions.contains(Subscription.ADD_ESG)) {
        Outlet {}
    } else {
        Navigate {
            to = "/page-not-found"
            replace = true
        }
    }
}