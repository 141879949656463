package com.ecosave.watch.portal

import com.ecosave.watch.portal.components.common.Loading
import com.ecosave.watch.portal.helpers.billing.UtilityType
import com.ecosave.watch.portal.helpers.common.decodeAccessToken
import com.ecosave.watch.portal.helpers.common.getAccessTokenFromLocalStorage
import com.ecosave.watch.portal.helpers.common.getAuthenticateState
import com.ecosave.watch.portal.helpers.common.isRefreshTokenValid
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.helpers.usermanagement.OnBoardingStatus
import com.ecosave.watch.portal.helpers.usermanagement.Subscription
import com.ecosave.watch.portal.models.auth.UserData
import com.ecosave.watch.portal.models.billing.UtilityAccountData
import com.ecosave.watch.portal.models.controlcenter.ActionsData
import com.ecosave.watch.portal.models.usermanagement.UserActionsData
import com.ecosave.watch.portal.services.getOnboardingStatus
import js.core.jso
import kotlinx.browser.localStorage
import kotlinx.coroutines.launch
import react.FC
import react.PropsWithChildren
import react.StateSetter
import react.createContext
import react.useContext
import react.useEffectOnce
import react.useState

sealed external interface StateContextProps {
    var userData: UserData
    var utilityAccountData: UtilityAccountData
    var utilityAccountId: Int?
    var utilityType: UtilityType?
    var accountNumber: String?
    var facilityIdForUtilityBills: Int?
    var isAuthenticated: Boolean
    var pageTitle: String
    var handleUpdateUserData: StateSetter<UserData>
    var updateUtilityAccountData: StateSetter<UtilityAccountData>
    var updateUtilityAccountId: StateSetter<Int?>
    var updateFacilityForUtilityBills: StateSetter<Int?>
    var updateIsAuthenticated: StateSetter<Boolean>
    var updateUtilityType: StateSetter<UtilityType?>
    var updateAccountNumber: StateSetter<String?>
    var updatePageTitle: StateSetter<String>
    var observationId: Int?
    var actionsData: ActionsData
    var userActionsData: UserActionsData
    var updateActionsData: StateSetter<ActionsData>
    var updateUserActionsData: StateSetter<UserActionsData>
}

val StateContext = createContext<StateContextProps>()

val GlobalContextProvider = FC<PropsWithChildren> {

    val (currentUserData, setUserData) = useState(UserData("", "", "", "", OnBoardingStatus.DONE, listOf(Subscription.BASE_PORTAL)))
    val (currentUtilityAccountData, setUtilityAccountData) = useState(UtilityAccountData())
    val (currentUtilityAccountId, setUtilityAccountId) = useState<Int?>(null)
    val (currentFacilityIdForUtilityBills, setFacilityIdForUtilityBills) = useState<Int?>(null)
    val (currentUtilityType, setCurrentUtilityType) = useState<UtilityType?>(null)
    val (currentAccountNumber, setCurrentAccountNumber) = useState<String?>(null)
    val (currentPageTitle, currentPageTitleStateSetter) = useState("Control Center")
    val (isAuth, setIsAuthenticated) = useState(false)
    var loading by useState(true)
    val currentObservationId by useState(null)
    val (currentActionsData, currentActionsDataStateSetter) = useState(ActionsData())
    val (currentUserActionsData, currentUserActionsDataStateSetter) = useState(UserActionsData())

    useEffectOnce {
        mainScope.launch {
            val isAuthenticated = getAuthenticateState() && isRefreshTokenValid()
            if (isAuthenticated && currentUserData.firstName == "") {
                val onBoardingStatus = getOnboardingStatus()
                //    TODO: Handle the scenario when api will fail to give us onboarding status for any reason. (make a global error page or something)
                val userInfo = decodeAccessToken(getAccessTokenFromLocalStorage()!!)
                val userData = UserData(
                    firstName = userInfo.firstName,
                    lastName = userInfo.lastName,
                    emailAddress = userInfo.emailAddress,
                    userRole = userInfo.userRole,
                    subscriptions = userInfo.subscriptions,
                    onBoardingStatus = onBoardingStatus!!
                )
                setUserData(userData)
            } else {
                localStorage.clear()
            }
            setIsAuthenticated(isAuthenticated)
            loading = false
        }
    }

    if (loading) {
        Loading {}
    } else {
        StateContext.Provider {
            value = jso {
                userData = currentUserData
                utilityAccountData = currentUtilityAccountData
                utilityAccountId = currentUtilityAccountId
                utilityType = currentUtilityType
                accountNumber = currentAccountNumber
                facilityIdForUtilityBills = currentFacilityIdForUtilityBills
                isAuthenticated = isAuth
                handleUpdateUserData = setUserData
                updateUtilityAccountData = setUtilityAccountData
                updateUtilityAccountId = setUtilityAccountId
                updateFacilityForUtilityBills = setFacilityIdForUtilityBills
                updateIsAuthenticated = setIsAuthenticated
                updateUtilityType = setCurrentUtilityType
                updateAccountNumber = setCurrentAccountNumber
                observationId = currentObservationId
                actionsData = currentActionsData
                updateActionsData = currentActionsDataStateSetter
                userActionsData = currentUserActionsData
                updateUserActionsData = currentUserActionsDataStateSetter
                pageTitle = currentPageTitle
                updatePageTitle = currentPageTitleStateSetter
            }
            +it.children
        }
    }
}

fun useGlobalState(): StateContextProps {
    val globalState = useContext(StateContext)

    if (globalState == null) {
        // TODO:  understand the scenarios when this will be null and build an error page which will be used for global/unhandled exceptions , navigate.invoke("error-page")
    }
    return globalState!!
}