package com.ecosave.watch.portal.components.common

import com.ecosave.watch.portal.helpers.Colors
import com.ecosave.watch.portal.styles.CommonStyles
import mui.material.TableCell
import mui.material.TableCellProps
import mui.system.sx
import react.FC
import web.cssom.Color
import web.cssom.rem

external interface TableHeaderProps : TableCellProps {
    var tableHeaderName: String
    var removeBottomBorder: Boolean
}

val TableHeader = FC<TableHeaderProps> { props ->
    TableCell {
        +props
        if (props.removeBottomBorder) {
            className = CommonStyles.REMOVED_CELL_BOTTOM_BORDER.cssClass
        }
        sx {
            fontSize = 0.9.rem
            color = Color(Colors.GRAY)
        }
        +props.tableHeaderName
    }
}