package com.ecosave.watch.portal.components.resourcecenter

import com.ecosave.watch.portal.Themes
import com.ecosave.watch.portal.styles.ResourceCenterStyles
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML
import web.cssom.px

external interface ResourcesSentencesProps : Props {
    var title: String?
    var underlined:String?
    var sentence:String?
    var category: String?
    var subCategory: String?
    var note: String?
}

val ResourcesSection = FC<ResourcesSentencesProps> { props ->
    Typography {
        className = ResourceCenterStyles.RESOURCES_MAIN_TITLE.cssClass
        variant = TypographyVariant.h4
        component = ReactHTML.h4
        +props.title
    }

    Typography {
        className = ResourceCenterStyles.RESOURCES_TITLE_UNDERLINED.cssClass
        variant = TypographyVariant.h4
        component = ReactHTML.h4
        +props.underlined
    }
    Typography {
        className = ResourceCenterStyles.RESOURCES_SENTENCE.cssClass
        variant = TypographyVariant.h6
        component = ReactHTML.h6
        +props.sentence
    }
    Typography {
        className = ResourceCenterStyles.RESOURCES_CATEGORY.cssClass
        variant = TypographyVariant.h6
        component = ReactHTML.h6
        +props.category
    }
    Typography {
        className = ResourceCenterStyles.RESOURCES_SUBCATEGORY.cssClass
        variant = TypographyVariant.h6
        component = ReactHTML.h6
        +props.subCategory
    }
    Typography {
        sx {
            color = Themes.ecosaveOrangeAccent
            width = 1200.px
            paddingLeft = 20.px
        }
        className = ResourceCenterStyles.RESOURCES_SUBCATEGORY.cssClass
        variant = TypographyVariant.h6
        component = ReactHTML.h6
        +props.note
    }
}