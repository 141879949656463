package com.ecosave.watch.portal.components.usermanagement

import com.ecosave.watch.portal.Themes
import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.components.common.GlobalMessage
import com.ecosave.watch.portal.components.common.Loading
import com.ecosave.watch.portal.components.common.PaperWrapper
import com.ecosave.watch.portal.components.formcontrols.PasswordTextField
import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.PageRoutes
import com.ecosave.watch.portal.helpers.common.sm
import com.ecosave.watch.portal.helpers.common.xs
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.helpers.usermanagement.TokenVerificationStatus
import com.ecosave.watch.portal.helpers.usermanagement.isUserInvitationFormValid
import com.ecosave.watch.portal.helpers.usermanagement.validateFirstName
import com.ecosave.watch.portal.helpers.usermanagement.validateJobTitle
import com.ecosave.watch.portal.helpers.usermanagement.validateLastName
import com.ecosave.watch.portal.helpers.usermanagement.validateRegisterUserConfirmPassword
import com.ecosave.watch.portal.helpers.usermanagement.validateRegisterUserPassword
import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.models.usermanagement.RegisterCompanyOrUserState
import com.ecosave.watch.portal.models.usermanagement.RegisterCompanyOrUserValidationState
import com.ecosave.watch.portal.pages.ecosaveLogo
import com.ecosave.watch.portal.services.usermanagement.registerInvitedUser
import com.ecosave.watch.portal.services.usermanagement.verifyInvitedUser
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Grid
import mui.material.Size
import mui.material.Typography
import mui.material.TypographyAlign
import mui.material.styles.ThemeProvider
import mui.material.styles.TypographyVariant
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.dom.onChange
import react.router.useParams
import react.useEffectOnce
import react.useState
import web.cssom.AlignItems
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.FontWeight
import web.cssom.JustifyContent
import web.cssom.Padding
import web.cssom.px
import web.html.HTMLInputElement
import web.html.InputType
import com.ecosave.watch.portal.styles.CommonStyles as commonstyles


val RegisterUser = FC<Props> {

    val (registerUserState, setRegisterUserState) = useState(RegisterCompanyOrUserState())
    val (registerUserValidationState, setRegisterUserValidationState) = useState(RegisterCompanyOrUserValidationState())
    var registerUserFormSubmitted by useState(false)
    var tokenVerificationStatus by useState(TokenVerificationStatus.VERIFYING)
    val (notificationState, setNotificationState) = useState(NotificationState())
    var isSubmitting by useState(false)
    val params = useParams()
    val token = params["token"]

    useEffectOnce {
        mainScope.launch {
            val response = verifyInvitedUser(token)
            if (response.tokenVerificationStatus == TokenVerificationStatus.VERIFIED) {
                setRegisterUserState(
                    registerUserState.copy(
                        emailAddress = response.invitedUserCompany?.emailAddress ?: "",
                        companyName = response.invitedUserCompany?.companyName ?: ""
                    )
                )
            }
            tokenVerificationStatus = response.tokenVerificationStatus
        }
    }


    when (tokenVerificationStatus) {
        TokenVerificationStatus.VERIFYING -> Loading()
        TokenVerificationStatus.VERIFIED -> {
            Box {
                className = commonstyles.BASIC_BACK.cssClass
                ThemeProvider {
                    theme = Themes.lightTheme
                    Box {
                        sx {
                            display = Display.flex
                            flexDirection = FlexDirection.column
                            gap = 20.px
                            justifyContent = JustifyContent.center
                            alignItems = AlignItems.center
                        }
                        Box {
                            ecosaveLogo()
                        }
                        PaperWrapper {
                            sx {
                                maxWidth = 900.px
                            }
                            Box {
                                sx {
                                    padding = Padding(30.px, 50.px)
                                }
                                if (!registerUserFormSubmitted) {
                                    Grid {
                                        sx {
                                            justifyContent = JustifyContent.center
                                            alignItems = AlignItems.baseline
                                        }
                                        container = true
                                        spacing = responsive(3)
                                        Grid {
                                            item = true
                                            xs = 12
                                            Typography {
                                                sx {
                                                    fontWeight = FontWeight.bold
                                                }
                                                align = TypographyAlign.center
                                                variant = TypographyVariant.h5
                                                +"Create Your Ecosave Account"
                                            }
                                        }
                                        Grid {
                                            item = true
                                            sm = 6
                                            xs = 12
                                            UserTextFieldWrapper {
                                                label = ReactNode("First Name *")
                                                type = InputType.text
                                                value = registerUserState.firstName
                                                error = registerUserValidationState.firstNameErrorState
                                                helperText = registerUserValidationState.firstNameErrorMessage
                                                onChange = {
                                                    val target = it.target as HTMLInputElement
                                                    setRegisterUserState(
                                                        registerUserState.copy(
                                                            firstName = target.value
                                                        )
                                                    )
                                                }
                                                onBlur = {
                                                    validateFirstName(
                                                        registerUserState,
                                                        registerUserValidationState,
                                                        setRegisterUserValidationState
                                                    )
                                                }
                                            }
                                        }
                                        Grid {
                                            item = true
                                            sm = 6
                                            xs = 12
                                            UserTextFieldWrapper {
                                                label = ReactNode("Last Name *")
                                                type = InputType.text
                                                value = registerUserState.lastName
                                                error = registerUserValidationState.lastNameErrorState
                                                helperText = registerUserValidationState.lastNameErrorMessage
                                                onChange = {
                                                    val target = it.target as HTMLInputElement
                                                    setRegisterUserState(
                                                        registerUserState.copy(
                                                            lastName = target.value
                                                        )
                                                    )
                                                }
                                                onBlur = {
                                                    validateLastName(
                                                        registerUserState,
                                                        registerUserValidationState,
                                                        setRegisterUserValidationState
                                                    )
                                                }
                                            }
                                        }
                                        Grid {
                                            item = true
                                            sm = 6
                                            xs = 12
                                            UserTextFieldWrapper {
                                                label = ReactNode("Company Name *")
                                                type = InputType.text
                                                value = registerUserState.companyName
                                                disabled = true
                                            }
                                        }
                                        Grid {
                                            item = true
                                            sm = 6
                                            xs = 12
                                            UserTextFieldWrapper {
                                                label = ReactNode("Job Title *")
                                                type = InputType.text
                                                value = registerUserState.jobTitle
                                                error = registerUserValidationState.jobTitleErrorState
                                                helperText = registerUserValidationState.jobTitleErrorMessage
                                                onChange = {
                                                    val target = it.target as HTMLInputElement
                                                    setRegisterUserState(
                                                        registerUserState.copy(
                                                            jobTitle = target.value
                                                        )
                                                    )
                                                }
                                                onBlur = {
                                                    validateJobTitle(
                                                        registerUserState,
                                                        registerUserValidationState,
                                                        setRegisterUserValidationState
                                                    )
                                                }
                                            }
                                        }
                                        Grid {
                                            item = true
                                            xs = 12
                                            UserTextFieldWrapper {
                                                label = ReactNode("Email Address *")
                                                type = InputType.email
                                                value = registerUserState.emailAddress
                                                disabled = true
                                            }
                                        }
                                        Grid {
                                            item = true
                                            sm = 6
                                            xs = 12
                                            PasswordTextField {
                                                label = ReactNode("Password *")
                                                value = registerUserState.password
                                                error = registerUserValidationState.passwordErrorState
                                                helperText = registerUserValidationState.passwordErrorMessage
                                                onChange = {
                                                    val target = it.target as HTMLInputElement
                                                    setRegisterUserState(
                                                        registerUserState.copy(
                                                            password = target.value
                                                        )
                                                    )
                                                }
                                                onBlur = {
                                                    validateRegisterUserPassword(
                                                        registerUserState,
                                                        registerUserValidationState,
                                                        setRegisterUserValidationState
                                                    )
                                                }
                                            }
                                        }
                                        Grid {
                                            item = true
                                            sm = 6
                                            xs = 12
                                            PasswordTextField {
                                                label = ReactNode("Confirm Password *")
                                                value = registerUserState.confirmPassword
                                                error = registerUserValidationState.confirmPasswordErrorState
                                                helperText = registerUserValidationState.confirmPasswordErrorMessage
                                                onChange = {
                                                    val target = it.target as HTMLInputElement
                                                    setRegisterUserState(
                                                        registerUserState.copy(
                                                            confirmPassword = target.value
                                                        )
                                                    )
                                                }
                                                onBlur = {
                                                    validateRegisterUserConfirmPassword(
                                                        registerUserState,
                                                        registerUserValidationState,
                                                        setRegisterUserValidationState
                                                    )
                                                }
                                            }
                                        }
                                        Grid {
                                            item = true
                                            xs = 12
                                            Button {
                                                disabled = isSubmitting
                                                fullWidth = true
                                                variant = ButtonVariant.contained
                                                size = Size.large
                                                onClick = {
                                                    if (isUserInvitationFormValid(
                                                            registerUserState,
                                                            registerUserValidationState,
                                                            setRegisterUserValidationState,
                                                        )
                                                    ) {
                                                        mainScope.launch {
                                                            isSubmitting = true
                                                            val apiCallStatus = registerInvitedUser(registerUserState, token ?: "")
                                                            if (apiCallStatus == ApiCallStatus.SUCCESS) {
                                                                registerUserFormSubmitted = true
                                                            } else {
                                                                setNotificationState(
                                                                    notificationState.copy(
                                                                        status = NotificationStatus.ERROR,
                                                                        message = Constants.NOTIFICATION_ERROR_MESSAGE,
                                                                        visible = true
                                                                    )
                                                                )
                                                            }
                                                            isSubmitting = false
                                                        }
                                                    }

                                                }
                                                if (isSubmitting) +"Submitting.." else +"Submit"
                                            }
                                        }
                                    }
                                } else {
                                    GlobalMessage {
                                        title = "Congratulations!"
                                        message = "Your account has been created successfully."
                                        showNavigationButton = true
                                        navigationRoute = PageRoutes.SIGN_IN.route
                                        navigationButtonText = "Click here to Sign In"
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        TokenVerificationStatus.INVALID_OR_EXPIRED -> {
            GlobalMessage {
                title = "Oops!"
                message =
                    "It seems the link you followed is either invalid or has expired, please contact your Admin for new invitation/registration email."
            }
        }

        TokenVerificationStatus.EXCEPTION -> {
            GlobalMessage {
                title = "Oops!"
                message = "An unexpected exception has occurred, please retry."
            }
        }

        TokenVerificationStatus.ALREADY_VERIFIED -> {
            GlobalMessage {
                title = "Already Registered!"
                message = "You have already completed your registration."
                showNavigationButton = true
                navigationRoute = PageRoutes.SIGN_IN.route
                navigationButtonText = "Click here to Sign In"
            }
        }

    }
    AlertNotifications {
        open = notificationState.visible
        status = notificationState.status
        message = notificationState.message
        closeNotification = {
            setNotificationState(
                notificationState.copy(
                    visible = false
                )
            )
        }
    }
}



