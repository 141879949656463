package com.ecosave.watch.portal.models.billing

import com.ecosave.watch.portal.helpers.billing.UtilityType

data class UtilityAccountData(
    var accountId: Int? = null,
    var accountNumber: String? = "",
    var utilityType: UtilityType? = null,
    var surchargeBillExists: Boolean = false,
    var isSolarNetMetered: Boolean = false,
    var isTherePPAInPlaceSolar: Boolean = false,
    var coolingSteamUsed: Boolean = false,
    var heatingSteamUsed: Boolean = false
)