package com.ecosave.watch.portal.components.esg.energy

import com.ecosave.watch.portal.components.esg.EsgCoreComponentProps
import com.ecosave.watch.portal.components.esg.GenericSection_3_3
import com.ecosave.watch.portal.components.esg.formcontrols.GRIStandardName
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.isValidDecimalNumber
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.EsgSection
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import com.ecosave.watch.portal.helpers.esg.failureErrorMessage
import com.ecosave.watch.portal.helpers.esg.omitDisclosureFromUI
import com.ecosave.watch.portal.helpers.esg.resetPreviousPatchState
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.esg.*
import com.ecosave.watch.portal.services.esg.addDynamicRowOrSection
import com.ecosave.watch.portal.services.esg.deleteDynamicRowOrSection
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import kotlinx.coroutines.launch
import kotlinx.serialization.json.JsonPrimitive
import mui.material.Box
import react.FC
import react.dom.events.FocusEvent
import react.dom.events.FormEvent
import web.html.HTMLDivElement
import web.html.HTMLElement
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement

val EnergyComponent = FC<EsgCoreComponentProps> { props ->
    val reportState = props.reportState
    val setReportState = props.setReportState
    val griStandard = EsgSection.ENERGY

    reportState.report?.energy?.let { state ->

        fun stateSetter(state: EnergyState) {
            setReportState(
                reportState.copy(
                    report = reportState.report.copy(
                        energy = state
                    )
                )
            )
        }

        val addRowOrSection: (String) -> Unit = { fieldName ->
            resetPreviousPatchState(props.setInputState)
            mainScope.launch {
                props.setAddDeleteRowOrSection(DynamicTableAction.ADD)
                props.setAddDeleteRowOrSectionApiCallInProgress(true)
                val apiCallStatus = addDynamicRowOrSection(
                    EsgCollectionAddRow(
                        objectName = griStandard.name,
                        reportFileName = reportState.reportFileName,
                        patchCollection = RowPatch(
                            pathFirst = fieldName
                        )
                    )
                )

                val newState = state.copy()

                if (apiCallStatus == ApiCallStatus.SUCCESS) {
                    when (fieldName) {
                        state::renewableFuelTypeList.name -> {
                            newState.renewableFuelTypeList.add(RenewableFuelType())
                            props.setNotificationMessage(ReportConstants.ADD_ROW_NOTIFICATION_MESSAGE)
                        }

                        state::nonRenewableFuelTypeList.name -> {
                            newState.nonRenewableFuelTypeList.add(NonRenewableFuelType())
                            props.setNotificationMessage(ReportConstants.ADD_ROW_NOTIFICATION_MESSAGE)
                        }

                        state::breakdownOfEnergyConsumptionList.name -> {
                            newState.breakdownOfEnergyConsumptionList.add(EnergyConsumptionBreakdown())
                            props.setNotificationMessage(ReportConstants.ADD_ROW_NOTIFICATION_MESSAGE)
                        }

                        state::breakdownOfEnergyIntensityList.name -> {
                            newState.breakdownOfEnergyIntensityList.add(EnergyIntensityBreakdown())
                            props.setNotificationMessage(ReportConstants.ADD_ROW_NOTIFICATION_MESSAGE)
                        }

                        state::energyReductionERList.name -> {
                            newState.energyReductionERList.add(EnergyReductionER())
                            props.setNotificationMessage(ReportConstants.ADD_ROW_NOTIFICATION_MESSAGE)
                        }
                    }

                    stateSetter(newState)

                    props.setNotificationStatus(NotificationStatus.SUCCESS)
                    props.setOpenAlertNotifications(true)
                } else {
                    failureErrorMessage(
                        props.setNotificationMessage,
                        props.setNotificationStatus,
                        props.setOpenAlertNotifications
                    )
                }

                props.setAddDeleteRowOrSectionApiCallInProgress(false)
            }
        }

        val deleteRowOrSection: (String, Int) -> Unit = { fieldName, index ->
            resetPreviousPatchState(props.setInputState)
            mainScope.launch {
                props.setAddDeleteRowOrSection(DynamicTableAction.DELETE)
                props.setAddDeleteRowOrSectionApiCallInProgress(true)
                val apiCallStatus = deleteDynamicRowOrSection(
                    EsgCollectionDeleteRow(
                        objectPatchEnum = griStandard,
                        reportFileName = reportState.reportFileName,
                        pathFirst = fieldName,
                        indexAt = index
                    )
                )

                val newState = state.copy()

                if (apiCallStatus == ApiCallStatus.SUCCESS) {
                    when (fieldName) {
                        state::renewableFuelTypeList.name -> {
                            newState.renewableFuelTypeList.removeAt(index)
                            props.setNotificationMessage(ReportConstants.DELETE_ROW_NOTIFICATION_MESSAGE)
                        }

                        state::nonRenewableFuelTypeList.name -> {
                            newState.nonRenewableFuelTypeList.removeAt(index)
                            props.setNotificationMessage(ReportConstants.DELETE_ROW_NOTIFICATION_MESSAGE)
                        }

                        state::breakdownOfEnergyConsumptionList.name -> {
                            newState.breakdownOfEnergyConsumptionList.removeAt(index)
                            props.setNotificationMessage(ReportConstants.DELETE_ROW_NOTIFICATION_MESSAGE)
                        }

                        state::breakdownOfEnergyIntensityList.name -> {
                            newState.breakdownOfEnergyIntensityList.removeAt(index)
                            props.setNotificationMessage(ReportConstants.DELETE_ROW_NOTIFICATION_MESSAGE)
                        }

                        state::energyReductionERList.name -> {
                            newState.energyReductionERList.removeAt(index)
                            props.setNotificationMessage(ReportConstants.DELETE_ROW_NOTIFICATION_MESSAGE)
                        }
                    }

                    stateSetter(newState)

                    props.setNotificationStatus(NotificationStatus.SUCCESS)
                    props.setOpenAlertNotifications(true)
                } else {
                    failureErrorMessage(
                        props.setNotificationMessage,
                        props.setNotificationStatus,
                        props.setOpenAlertNotifications
                    )
                }
                props.setAddDeleteRowOrSectionApiCallInProgress(false)
            }
        }

        val onChangeDecimalNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement

            if (isValidDecimalNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toDoubleOrNull()

                val newState = state.copy()

                if (field == newState::renewableFuelTypeList.name && changeIndex != null) {
                    newState.renewableFuelTypeList[changeIndex].let {
                        when (propertyName) {
                            it::section3021RenewableCurrent.name -> it.section3021RenewableCurrent = propertyValue
                        }
                    }
                } else if (field == newState::nonRenewableFuelTypeList.name && changeIndex != null) {
                    newState.nonRenewableFuelTypeList[changeIndex].let {
                        when (propertyName) {
                            it::section3021NonRenewableCurrent.name -> it.section3021NonRenewableCurrent = propertyValue
                        }
                    }
                } else if (field == newState::breakdownOfEnergyConsumptionList.name && changeIndex != null) {
                    newState.breakdownOfEnergyConsumptionList[changeIndex].let {
                        when (propertyName) {
                            it::section3021ElectricityTotal.name -> it.section3021ElectricityTotal = propertyValue
                            it::section3021SteamTotal.name -> it.section3021SteamTotal = propertyValue
                            it::section3021CoolingTotal.name -> it.section3021CoolingTotal = propertyValue
                            it::section3021HeatingTotal.name -> it.section3021HeatingTotal = propertyValue
                        }
                    }
                } else if (field == newState::breakdownOfEnergyIntensityList.name && changeIndex != null) {
                    newState.breakdownOfEnergyIntensityList[changeIndex].let {
                        when (propertyName) {
                            it::section3023EnergyConsumption.name -> it.section3023EnergyConsumption = propertyValue
                            it::section3023OrgSpecificMetric.name -> it.section3023OrgSpecificMetric = propertyValue
                            it::section3023EnergyIntensity.name -> it.section3023EnergyIntensity = propertyValue
                        }
                    }
                } else if (field == newState::energyReductionERList.name && changeIndex != null) {
                    newState.energyReductionERList[changeIndex].let {
                        when (propertyName) {
                            it::section3025ReductionERCurrent.name -> it.section3025ReductionERCurrent = propertyValue
                        }
                    }
                } else {
                    newState.let {
                        when (propertyName) {
                            it::section3021ConsumedCY1.name -> it.section3021ConsumedCY1 = propertyValue
                            it::section3021ConsumedCY2.name -> it.section3021ConsumedCY2 = propertyValue
                            it::section3021ConsumedCY3.name -> it.section3021ConsumedCY3 = propertyValue
                            it::section3021ConsumedCY4.name -> it.section3021ConsumedCY4 = propertyValue
                            it::section3021SoldCY1.name -> it.section3021SoldCY1 = propertyValue
                            it::section3021SoldCY2.name -> it.section3021SoldCY2 = propertyValue
                            it::section3021SoldCY3.name -> it.section3021SoldCY3 = propertyValue
                            it::section3021SoldCY4.name -> it.section3021SoldCY4 = propertyValue
                            it::section3021Field3.name -> it.section3021Field3 = propertyValue
//                            it::section3022UpstreamCurrent1.name -> it.section3022UpstreamCurrent1 = propertyValue
                            it::section3022UpstreamCurrent2.name -> it.section3022UpstreamCurrent2 = propertyValue
                            it::section3022UpstreamCurrent3.name -> it.section3022UpstreamCurrent3 = propertyValue
                            it::section3022UpstreamCurrent4.name -> it.section3022UpstreamCurrent4 = propertyValue
                            it::section3022UpstreamCurrent5.name -> it.section3022UpstreamCurrent5 = propertyValue
                            it::section3022UpstreamCurrent6.name -> it.section3022UpstreamCurrent6 = propertyValue
                            it::section3022UpstreamCurrent7.name -> it.section3022UpstreamCurrent7 = propertyValue
                            it::section3022UpstreamCurrent8.name -> it.section3022UpstreamCurrent8 = propertyValue
                            it::section3022UpstreamCurrent9.name -> it.section3022UpstreamCurrent9 = propertyValue
                            it::section3022UpstreamCurrent10.name -> it.section3022UpstreamCurrent10 = propertyValue
                            it::section3022DownstreamCurrent11.name -> it.section3022DownstreamCurrent11 = propertyValue
                            it::section3022DownstreamCurrent12.name -> it.section3022DownstreamCurrent12 = propertyValue
                            it::section3022DownstreamCurrent13.name -> it.section3022DownstreamCurrent13 = propertyValue
                            it::section3022DownstreamCurrent14.name -> it.section3022DownstreamCurrent14 = propertyValue
                            it::section3022DownstreamCurrent15.name -> it.section3022DownstreamCurrent15 = propertyValue
                            it::section3022DownstreamCurrent16.name -> it.section3022DownstreamCurrent16 = propertyValue
                            it::section3022DownstreamCurrent17.name -> it.section3022DownstreamCurrent17 = propertyValue
                            it::section3022DownstreamCurrent18.name -> it.section3022DownstreamCurrent18 = propertyValue
                            it::section3022DownstreamCurrent19.name -> it.section3022DownstreamCurrent19 = propertyValue
                            it::section3023IntensityCurrent1.name -> it.section3023IntensityCurrent1 = propertyValue
                            it::section3023IntensityCurrent2.name -> it.section3023IntensityCurrent2 = propertyValue
                            it::section3023IntensityCurrent3.name -> it.section3023IntensityCurrent3 = propertyValue
                            it::section3024Field1.name -> it.section3024Field1 = propertyValue
                        }
                    }
                }

                stateSetter(newState)

                props.setInputState(
                    CurrentInputState(
                        parentPath = field,
                        childPath = propertyName,
                        index = changeIndex,
                        value = JsonPrimitive(propertyValue),
                        objectName = EsgSection.ENERGY.name,
                        reportName = reportState.reportFileName
                    )
                )
            }
        }

        val onBlurDecimalNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidDecimalNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toDoubleOrNull()
                props.onBlurApiCall(
                    propertyName,
                    JsonPrimitive(propertyValue),
                    changeIndex,
                    field,
                    griStandard.name
                )
            }
        }

        val onChangeTextArea: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value

            val newState = state.copy()

            newState.let {
                when (propertyName) {
                    it::section3021Field1.name -> it.section3021Field1 = propertyValue
                    it::section3021Field2.name -> it.section3021Field2 = propertyValue

                    it::section3022Field1.name -> it.section3022Field1 = propertyValue
                    it::section3022Field2.name -> it.section3022Field2 = propertyValue

                    it::section3023Field1.name -> it.section3023Field1 = propertyValue
                    it::section3023Field2.name -> it.section3023Field2 = propertyValue
                    it::section3023Field3.name -> it.section3023Field3 = propertyValue

                    it::section3024Field2.name -> it.section3024Field2 = propertyValue
                    it::section3024Field3.name -> it.section3024Field3 = propertyValue
                    it::section3024Field4.name -> it.section3024Field4 = propertyValue
                    it::section3024Field5.name -> it.section3024Field5 = propertyValue

                    it::section3025Field1.name -> it.section3025Field1 = propertyValue
                    it::section3025Field2.name -> it.section3025Field2 = propertyValue

                    it::section3_3_302A1.name -> it.section3_3_302A1 = propertyValue
                    it::section3_3_302A2.name -> it.section3_3_302A2 = propertyValue
                    it::section3_3_302A3.name -> it.section3_3_302A3 = propertyValue
                    it::section3_3_302A4.name -> it.section3_3_302A4 = propertyValue
                    it::section3_3_302A5.name -> it.section3_3_302A5 = propertyValue
                    it::section3_3_302A6.name -> it.section3_3_302A6 = propertyValue
                }
            }

            stateSetter(newState)

            props.setInputState(
                CurrentInputState(
                    parentPath = field,
                    childPath = propertyName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue),
                    objectName = griStandard.name,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurTextArea: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value

            props.onBlurApiCall(
                propertyName,
                JsonPrimitive(propertyValue),
                changeIndex,
                field,
                griStandard.name
            )
        }

        val onChangeText: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            val propertyName = target.name
            val propertyValue = target.value

            val newState = state.copy()

            if (field == newState::renewableFuelTypeList.name && changeIndex != null) {
                newState.renewableFuelTypeList[changeIndex].let {
                    when (propertyName) {
                        it::section3021RenewableFuelType.name -> it.section3021RenewableFuelType = propertyValue
                        it::section3021RenewableUnit.name -> it.section3021RenewableUnit = propertyValue
                    }
                }
            } else if (field == newState::nonRenewableFuelTypeList.name && changeIndex != null) {
                newState.nonRenewableFuelTypeList[changeIndex].let {
                    when (propertyName) {
                        it::section3021NonRenewableFuelType.name -> it.section3021NonRenewableFuelType = propertyValue
                        it::section3021NonRenewableUnit.name -> it.section3021NonRenewableUnit = propertyValue
                    }
                }
            } else if (field == newState::breakdownOfEnergyConsumptionList.name && changeIndex != null) {
                newState.breakdownOfEnergyConsumptionList[changeIndex].let {
                    when (propertyName) {
                        it::section3021GroupOrBusinessUnit.name -> it.section3021GroupOrBusinessUnit = propertyValue
                        it::section3021FuelType.name -> it.section3021FuelType = propertyValue
                        it::section3021FuelSource.name -> it.section3021FuelSource = propertyValue
                        it::section3021Country.name -> it.section3021Country = propertyValue
                        it::section3021Activity.name -> it.section3021Activity = propertyValue
                    }
                }
            } else if (field == newState::breakdownOfEnergyIntensityList.name && changeIndex != null) {
                newState.breakdownOfEnergyIntensityList[changeIndex].let {
                    when (propertyName) {
                        it::section3023GroupOrBusinessUnit.name -> it.section3023GroupOrBusinessUnit = propertyValue
                        it::section3023FuelType.name -> it.section3023FuelType = propertyValue
                        it::section3023FuelSource.name -> it.section3023FuelSource = propertyValue
                        it::section3023Country.name -> it.section3023Country = propertyValue
                        it::section3023Activity.name -> it.section3023Activity = propertyValue
                        it::section3023EnergyIntensityUnit.name -> it.section3023EnergyIntensityUnit = propertyValue
                    }
                }
            } else if (field == newState::energyReductionERList.name && changeIndex != null) {
                newState.energyReductionERList[changeIndex].let {
                    when (propertyName) {
                        it::section3025productNService.name -> it.section3025productNService = propertyValue
                        it::section3025ReductionERUnit.name -> it.section3025ReductionERUnit = propertyValue
                    }
                }
            } else {
                newState.let {
                    when (propertyName) {
                        it::section3021ConsumedUnit1.name -> it.section3021ConsumedUnit1 = propertyValue
                        it::section3021ConsumedUnit2.name -> it.section3021ConsumedUnit2 = propertyValue
                        it::section3021ConsumedUnit3.name -> it.section3021ConsumedUnit3 = propertyValue
                        it::section3021ConsumedUnit4.name -> it.section3021ConsumedUnit4 = propertyValue
                        it::section3021SoldUnit1.name -> it.section3021SoldUnit1 = propertyValue
                        it::section3021SoldUnit2.name -> it.section3021SoldUnit2 = propertyValue
                        it::section3021SoldUnit3.name -> it.section3021SoldUnit3 = propertyValue
                        it::section3021SoldUnit4.name -> it.section3021SoldUnit4 = propertyValue
                        it::section3022UpstreamUnit2.name -> it.section3022UpstreamUnit2 = propertyValue
                        it::section3022UpstreamUnit3.name -> it.section3022UpstreamUnit3 = propertyValue
                        it::section3022UpstreamUnit4.name -> it.section3022UpstreamUnit4 = propertyValue
                        it::section3022UpstreamUnit5.name -> it.section3022UpstreamUnit5 = propertyValue
                        it::section3022UpstreamUnit6.name -> it.section3022UpstreamUnit6 = propertyValue
                        it::section3022UpstreamUnit7.name -> it.section3022UpstreamUnit7 = propertyValue
                        it::section3022UpstreamUnit8.name -> it.section3022UpstreamUnit8 = propertyValue
                        it::section3022UpstreamUnit9.name -> it.section3022UpstreamUnit9 = propertyValue
                        it::section3022UpstreamUnit10.name -> it.section3022UpstreamUnit10 = propertyValue
                        it::section3022DownstreamUnit11.name -> it.section3022DownstreamUnit11 = propertyValue
                        it::section3022DownstreamUnit12.name -> it.section3022DownstreamUnit12 = propertyValue
                        it::section3022DownstreamUnit13.name -> it.section3022DownstreamUnit13 = propertyValue
                        it::section3022DownstreamUnit14.name -> it.section3022DownstreamUnit14 = propertyValue
                        it::section3022DownstreamUnit15.name -> it.section3022DownstreamUnit15 = propertyValue
                        it::section3022DownstreamUnit16.name -> it.section3022DownstreamUnit16 = propertyValue
                        it::section3022DownstreamUnit17.name -> it.section3022DownstreamUnit17 = propertyValue
                        it::section3022DownstreamUnit18.name -> it.section3022DownstreamUnit18 = propertyValue
                        it::section3022DownstreamUnit19.name -> it.section3022DownstreamUnit19 = propertyValue
                    }
                }
            }

            stateSetter(newState)

            props.setInputState(
                CurrentInputState(
                    parentPath = field,
                    childPath = propertyName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue.trim()),
                    objectName = griStandard.name,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurText: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            val propertyName = target.name
            val propertyValue = target.value

            props.onBlurApiCall(
                propertyName,
                JsonPrimitive(propertyValue),
                changeIndex,
                field,
                griStandard.name
            )
        }

        Box {
            className = ESGCommonStyles.SECTION_MAIN_LAYOUT.cssClass

            GRIStandardName {
                this.griStandard = griStandard
            }

            GenericSection_3_3 {
                this.onBlurTextArea = onBlurTextArea
                this.onChangeTextArea = onChangeTextArea
                this.materialTopicName = EsgSection.ENERGY.description

                this.section3_3_A1_name = state::section3_3_302A1.name
                this.section3_3_A1_value = state.section3_3_302A1

                this.section3_3_A2_name = state::section3_3_302A2.name
                this.section3_3_A2_value = state.section3_3_302A2

                this.section3_3_A3_name = state::section3_3_302A3.name
                this.section3_3_A3_value = state.section3_3_302A3

                this.section3_3_A4_name = state::section3_3_302A4.name
                this.section3_3_A4_value = state.section3_3_302A4

                this.section3_3_A5_name = state::section3_3_302A5.name
                this.section3_3_A5_value = state.section3_3_302A5

                this.section3_3_A6_name = state::section3_3_302A6.name
                this.section3_3_A6_value = state.section3_3_302A6
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_302_1)) {
                GRI_302_1 {
                    this.esgReportState = reportState
                    this.onChangeText = onChangeText
                    this.onBlurText = onBlurText
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeDecimalNumber = onChangeDecimalNumber
                    this.onBlurDecimalNumber = onBlurDecimalNumber
                    this.addRowOrSection = addRowOrSection
                    this.deleteRowOrSection = deleteRowOrSection
                }
            }
            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_302_2)) {
                GRI_302_2 {
                    this.esgReportState = reportState
                    this.onChangeText = onChangeText
                    this.onBlurText = onBlurText
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeDecimalNumber = onChangeDecimalNumber
                    this.onBlurDecimalNumber = onBlurDecimalNumber
                }
            }
            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_302_3)) {
                GRI_302_3 {
                    this.esgReportState = reportState
                    this.onChangeText = onChangeText
                    this.onBlurText = onBlurText
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeDecimalNumber = onChangeDecimalNumber
                    this.onBlurDecimalNumber = onBlurDecimalNumber
                }
            }
            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_302_4)) {
                GRI_302_4 {
                    this.esgReportState = reportState
                    this.onChangeText = onChangeText
                    this.onBlurText = onBlurText
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeDecimalNumber = onChangeDecimalNumber
                    this.onBlurDecimalNumber = onBlurDecimalNumber
                }
            }
            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_302_5)) {
                GRI_302_5 {
                    this.esgReportState = reportState
                    this.onChangeText = onChangeText
                    this.onBlurText = onBlurText
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeDecimalNumber = onChangeDecimalNumber
                    this.onBlurDecimalNumber = onBlurDecimalNumber
                    this.addRowOrSection = addRowOrSection
                    this.deleteRowOrSection = deleteRowOrSection
                }
            }
        }
    }
}