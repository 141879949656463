package com.ecosave.watch.portal.services.energymanagement

import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.models.energymanagement.*
import com.ecosave.watch.portal.helpers.BackendServerURL
import com.ecosave.watch.portal.services.httpClient
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*

suspend fun saveBaseline(params: String): ApiCallStatus {
    try {
        val response: HttpResponse = httpClient.post("$BackendServerURL/energy-management/baseline?${params}")
        if (response.status == HttpStatusCode.Accepted) {
            return ApiCallStatus.SUCCESS
        }
    } catch (e: dynamic) {
        console.error(e)
    }
    return ApiCallStatus.FAILURE
}

suspend fun fetchBaselineData(params: String): String {
    try {
        val response: HttpResponse = httpClient.get("$BackendServerURL/energy-management/get-all?${params}")
        if (response.status.equals(HttpStatusCode.OK)) {
            return JSON.parse(response.bodyAsText())
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return ""
}

suspend fun fetchSelectedBaselineData(params: String): BaselineSummary {
    try {
        val response: HttpResponse = httpClient.get("$BackendServerURL/energy-management/baseline-data?${params}")
        if (response.status == HttpStatusCode.OK) {
            return response.body()
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return BaselineSummary("", emptyList())
}