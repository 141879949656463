package com.ecosave.watch.portal.helpers.common

import com.ecosave.watch.portal.helpers.Colors

object ServicesCost {
    const val ECOSAVE_WATCH_AUTOMATIC_DATA_COLLECTION = "$15"
    const val UTILITY_HISTORY_DATA = "$35"
    const val ENERGY_STAR = "$20"
}

object ColorConstants {
    const val HIGHLIGHTED_TEXT = Colors.GREEN
}


