package com.ecosave.watch.portal.components.utilitybills

import com.ecosave.watch.portal.helpers.billing.BillingHeaders
import com.ecosave.watch.portal.models.billing.utilitybillstate.UtilityBillState
import com.ecosave.watch.portal.models.billing.UtilityBillValidationState
import com.ecosave.watch.portal.helpers.billing.UtilityType
import com.ecosave.watch.portal.helpers.common.YesNoEnum
import com.ecosave.watch.portal.helpers.billing.formatBillingLabels
import com.ecosave.watch.portal.helpers.billing.formatBillingValues
import com.ecosave.watch.portal.helpers.billing.validateTotalCommoditySupply
import com.ecosave.watch.portal.helpers.billing.validateTotalDeliveryDistribution
import com.ecosave.watch.portal.models.billing.UtilityBillSummary
import com.ecosave.watch.portal.styles.BillingTableStyles
import mui.material.TableCell
import mui.material.TableRow
import react.FC
import react.Props
import react.StateSetter
import react.dom.onChange
import web.html.HTMLInputElement
import web.html.InputType

external interface NaturalGasDataRowProps : Props {
    var editModeNaturalGas: (Boolean)
    var editRowNaturalGas: Boolean
    var stateNaturalGas: UtilityBillState
    var validationNaturalGas: UtilityBillValidationState
    var billNaturalGas: UtilityBillSummary
    var setStateNaturalGas: StateSetter<UtilityBillState>
    var setValidationNaturalGas: StateSetter<UtilityBillValidationState>
}

val NaturalGasDataRow = FC<NaturalGasDataRowProps> { props ->

    val editMode = props.editModeNaturalGas
    val editRow = props.editRowNaturalGas
    val state = props.stateNaturalGas
    val setState = props.setStateNaturalGas
    val validation = props.validationNaturalGas
    val setValidation = props.setValidationNaturalGas
    val bill = props.billNaturalGas

    TableRow {
        TableCell {
            className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
            +BillingHeaders.BILL_ESTIMATED.header
        }

        TableCell {
            className = BillingTableStyles.TABLE_CELL.cssClass
            if (editRow) {
                BillEstimated {
                    stateBillEstimated = state
                    setStateBillEstimated = setState
                }
            } else {
                if (bill.utilityBill.billEstimated == true) +YesNoEnum.YES.description else +YesNoEnum.NO.description
            }
        }

        TableCell {
            className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
            +BillingHeaders.BILL_ADJUSTED.header
        }

        TableCell {
            className = BillingTableStyles.TABLE_CELL.cssClass
            if (editRow) {
                BillAdjusted {
                    stateBillAdjusted = state
                    setStateBillAdjusted = setState
                }
            } else {
                if (bill.utilityBill.billAdjusted == true) +YesNoEnum.YES.description else +YesNoEnum.NO.description
            }
        }

        TableCell {
            className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
            +formatBillingLabels(BillingHeaders.TOTAL_DELIVERY_DISTRIBUTION, editMode)
        }

        TableCell {
            className = BillingTableStyles.TABLE_CELL.cssClass
            if (editRow) {
                EditableTextFieldWrapper {
                    type = InputType.number
                    value = state.totalDeliveryDistribution
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setState(
                            state.copy(
                                totalDeliveryDistribution = target.value.toFloatOrNull()
                            )
                        )
                    }
                    error =
                        validation.naturalGasBillValidationState.totalDeliveryDistributionErrorState
                    helperText =
                        validation.naturalGasBillValidationState.totalDeliveryDistributionErrorMessage
                    onBlur = {
                        validateTotalDeliveryDistribution(state, validation, setValidation)
                    }
                }
            } else {
                +formatBillingValues(
                    bill.utilityBill.totalDeliveryDistribution,
                    null
                )
            }
        }
    }

    TableRow {
        TableCell {
            className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
            +formatBillingLabels(BillingHeaders.TOTAL_COMMODITY_SUPPLY, editMode)
        }

        TableCell {
            className = BillingTableStyles.TABLE_CELL.cssClass
            if (editRow) {
                EditableTextFieldWrapper {
                    type = InputType.number
                    value = state.naturalGasAdditional.totalCommoditySupply
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setState(
                            state.copy(
                                naturalGasAdditional = state.naturalGasAdditional.copy(
                                    totalCommoditySupply = target.value.toFloatOrNull()
                                )
                            )
                        )
                    }
                    error =
                        validation.naturalGasBillValidationState.totalCommoditySupplyErrorState
                    helperText =
                        validation.naturalGasBillValidationState.totalCommoditySupplyErrorMessage
                    onBlur = {
                        validateTotalCommoditySupply(state, validation, setValidation)
                    }
                }
            } else {
                +formatBillingValues(
                    bill.utilityBill.naturalGasAdditional.totalCommoditySupply,
                    null
                )
            }
        }

        if (editRow) {
            UsageUnit {
                stateUsageUnit = state
                setStateUsageUnit = setState
                usageUnits = UtilityType.NATURAL_GAS.usageUnits
            }
        }
    }
}