package com.ecosave.watch.portal.components.privacypolicy

import react.FC
import react.Props

val ServiceCommitmentAndSupport = FC<Props> {
    HeadingWrapper {
        heading = "8. Service Commitment and Support"
    }
    TypographyWrapper {
        +"Excluding scheduled maintenance windows, Ecosave will use commercially reasonable efforts to maintain 99.8% availability of the hosted portion of the Service for each month during the term of this Agreement. The Service will be deemed \"available\"so long as Authorized Users are able to login to the Service interface and access data."
    }
    TypographyWrapper {
        +"If you are under a Paying Plan, Ecosave will provide in-product and email support (\"Support\"). Although no response times are guaranteed, Ecosave will use commercially reasonable efforts to respond to such support requests within 48 hours. Ecosave may delegate the performance of certain portions of the Support to third parties, but will remain responsible to you for delivery. In the event any Support is not performed with reasonable skill, care, and diligence, Ecosave will re-perform the Support to the extent necessary to correct the defective performance, and you acknowledge that re-performance will be your sole and exclusive remedy for any defective performance."
    }
    TypographyWrapper {
        +"Subject to this Agreement, Ecosave will make the Service available to you and Authorized Users during the Service Period, and Ecosave hereby grants to you, during the Service Period, a nonexclusive, nontransferable, limited right to enable Authorized Users to access and use the Service through the interface and the Website, and to access and use Ecosave's technical and operations documentation and Agents (as defined in Section 9) in support solely for your internal, business use."
    }
    TypographyWrapper {
        +"You acknowledge that your and each Authorized User's access and use of the Service are subject to Ecosave's Privacy Policy which is incorporated into this Agreement by reference. Ecosave may delegate the performance of certain portions of the Service to third parties but will remain responsible to you for delivery. Ecosave may in its discretion modify, enhance, or otherwise change the Service."
    }
}
