package com.ecosave.watch.portal.helpers.facilitymanagement

import com.ecosave.watch.portal.helpers.billing.BillingConstants
import js.core.ReadonlyArray
import js.core.jso

object FacilityConstants {

    val weekDaysList = listOf(
        FacilityDays.SUNDAY,
        FacilityDays.MONDAY,
        FacilityDays.TUESDAY,
        FacilityDays.WEDNESDAY,
        FacilityDays.THURSDAY,
        FacilityDays.FRIDAY,
        FacilityDays.SATURDAY
    )

    const val ENERGY_STAR_PORTFOLIO_MANAGER = "Energy Star® PortfolioManager®"

    const val PROPERTY_ID_ACCESS_ISSUE_ERROR_MESSAGE =
        "We currently do not have access to this ${BillingConstants.ENERGY_STAR} property and we also haven't received a share request for it."
    const val ENERGY_STAR_API_ERROR_MESSAGE = "${BillingConstants.ENERGY_STAR} API is having issues right now. Please subscribe later."
    const val INVALID_PROPERTY_ID_ERROR_MESSAGE = "Invalid ${BillingConstants.ENERGY_STAR} Property Id."
}

object FacilityOperatingHoursDefaultValues {
    const val startingHour = "00"
    const val startingMinute = "00"
    const val endingHour = "23"
    const val endingMinute = "59"
}

sealed external interface Mark {
    var value: Number?
    var label: String?
}

val facilityTimeMarks: ReadonlyArray<Mark> = arrayOf(
    jso { value = 00.00; label = "00:00" },
    jso { value = -12.00; label = "12:00" },
    jso { value = -23.00; label = "23:59" }
)