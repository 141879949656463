package com.ecosave.watch.portal.components.esg.generaldisclosures.strategypoliciesandpractices

import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.OlType
import react.dom.html.ReactHTML.label
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.ol
import react.dom.onChange

val Disclosure_2_24 = FC<EsgSubFormComponentProps> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.strategyPoliciesPractices?.let { state ->

        val embeddingPolicyCommitmentsListName = state::embeddingPolicyCommitmentsList.name

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_2_24
        }

        label {
            +"Describe how it embeds each of its policy commitments for responsible business conduct throughout its activities and business relationships, including:"
            ol {
                type = OlType.i
                li {
                    +"How responsibility is allocated to implement the commitments across different levels within the organization."
                }
                li {
                    +"How the organization integrates the commitments into organizational strategies, operational policies, and operational procedures."
                }
                li {
                    +"How the organization implements its comments with and through its business relationships."
                }
                li {
                    +"Training that the organization provides on implementing the commitments."
                }
            }
        }

        Box {
            className = ESGCommonStyles.TABLE_WRAPPER.cssClass
            DynamicTableWrapper {
                addRowOrSection = {
                    props.addRowOrSection(embeddingPolicyCommitmentsListName)
                }
                items = state.embeddingPolicyCommitmentsList
                action = DynamicTableAction.ADD
                addRowOrSectionBtnText = ReportConstants.ADD_ROW_MESSAGE
                Box {
                    className = ESGCommonStyles.TWO_COLUMN_TABLE.cssClass
                    TableHeader {
                        header = "Policy Commitment"
                    }
                    TableHeader {
                        header =
                            "How does the organization embed policy through activities and business relationships (consider above points as well)."
                    }
                }
            }
            state.embeddingPolicyCommitmentsList.forEachIndexed { index, it ->
                DynamicTableWrapper {
                    key = it.key
                    deleteRowOrSection = {
                        props.deleteRowOrSection(embeddingPolicyCommitmentsListName, index)
                    }
                    items = state.embeddingPolicyCommitmentsList
                    action = DynamicTableAction.DELETE
                    variant = DynamicTableVariant.TABLE
                    Box {
                        className = ESGCommonStyles.TWO_COLUMN_TABLE.cssClass
                        ESGTextAreaFieldWrapper {
                            name = it::section224A1.name
                            value = it.section224A1
                            onChange = { event -> onChangeTextArea(event, index, embeddingPolicyCommitmentsListName) }
                            onBlur = { event -> onBlurTextArea(event, index, embeddingPolicyCommitmentsListName) }
                        }
                        ESGTextAreaFieldWrapper {
                            name = it::section224A2.name
                            value = it.section224A2
                            onChange = { event -> onChangeTextArea(event, index, embeddingPolicyCommitmentsListName) }
                            onBlur = { event -> onBlurTextArea(event, index, embeddingPolicyCommitmentsListName) }
                        }
                    }
                }
            }
        }
    }
}