package com.ecosave.watch.portal.helpers.billing

import com.ecosave.watch.portal.models.billing.utilitybillstate.UtilityBillState
import com.ecosave.watch.portal.models.billing.UtilityBillValidationState
import react.StateSetter
import react.ReactNode

fun validateServiceCharges(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.waterBillAdditional.serviceCharges == null) {
        validation.waterBillValidationState.serviceChargesErrorState = false
        validation.waterBillValidationState.serviceChargesErrorMessage = null
    } else if (getDigitsCountBeforeDecimal(state.waterBillAdditional.serviceCharges.toString()) > BillingConstants.MAX_COST_DIGITS) {
        validation.waterBillValidationState.serviceChargesErrorState = true
        validation.waterBillValidationState.serviceChargesErrorMessage =
            ReactNode(BillingConstants.INVALID_COST_ERROR_MESSAGE)
    } else {
        validation.waterBillValidationState.serviceChargesErrorState = false
        validation.waterBillValidationState.serviceChargesErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun validateStormWaterCharges(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.waterBillAdditional.stormWaterCharges == null) {
        validation.waterBillValidationState.stormWaterChargesErrorState = false
        validation.waterBillValidationState.stormWaterChargesErrorMessage = null
    } else if (getDigitsCountBeforeDecimal(state.waterBillAdditional.stormWaterCharges.toString()) > BillingConstants.MAX_COST_DIGITS) {
        validation.waterBillValidationState.stormWaterChargesErrorState = true
        validation.waterBillValidationState.stormWaterChargesErrorMessage =
            ReactNode(BillingConstants.INVALID_COST_ERROR_MESSAGE)
    } else {
        validation.waterBillValidationState.stormWaterChargesErrorState = false
        validation.waterBillValidationState.stormWaterChargesErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun validateIndustrialSurcharge(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.waterBillAdditional.industrialSurcharge == null) {
        validation.waterBillValidationState.industrialSurchargeErrorState = false
        validation.waterBillValidationState.industrialSurchargeErrorMessage = null
    } else if (getDigitsCountBeforeDecimal(state.waterBillAdditional.industrialSurcharge.toString()) > BillingConstants.MAX_COST_DIGITS) {
        validation.waterBillValidationState.industrialSurchargeErrorState = true
        validation.waterBillValidationState.industrialSurchargeErrorMessage =
            ReactNode(BillingConstants.INVALID_COST_ERROR_MESSAGE)
    } else {
        validation.waterBillValidationState.industrialSurchargeErrorState = false
        validation.waterBillValidationState.industrialSurchargeErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun validateFlow(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.waterBillAdditional.flow == null) {
        validation.waterBillValidationState.flowErrorState = false
        validation.waterBillValidationState.flowErrorMessage = null
    } else if (getDigitsCountBeforeDecimal(state.waterBillAdditional.flow.toString()) > BillingConstants.MAX_VALUE_DIGITS) {
        validation.waterBillValidationState.flowErrorState = true
        validation.waterBillValidationState.flowErrorMessage = ReactNode(BillingConstants.INVALID_VALUE_ERROR_MESSAGE)
    } else {
        validation.waterBillValidationState.flowErrorState = false
        validation.waterBillValidationState.flowErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun validateSrf(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.waterBillAdditional.srf == null) {
        validation.waterBillValidationState.srfErrorState = false
        validation.waterBillValidationState.srfErrorMessage = null
    } else if (getDigitsCountBeforeDecimal(state.waterBillAdditional.srf.toString()) > BillingConstants.MAX_VALUE_DIGITS) {
        validation.waterBillValidationState.srfErrorState = true
        validation.waterBillValidationState.srfErrorMessage = ReactNode(BillingConstants.INVALID_VALUE_ERROR_MESSAGE)
    } else {
        validation.waterBillValidationState.srfErrorState = false
        validation.waterBillValidationState.srfErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun validateFlowUsedForBilling(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.waterBillAdditional.flowUsedForBilling == null) {
        validation.waterBillValidationState.flowUsedForBillingErrorState = false
        validation.waterBillValidationState.flowUsedForBillingErrorMessage = null
    } else if (getDigitsCountBeforeDecimal(state.waterBillAdditional.flowUsedForBilling.toString()) > BillingConstants.MAX_VALUE_DIGITS) {
        validation.waterBillValidationState.flowUsedForBillingErrorState = true
        validation.waterBillValidationState.flowUsedForBillingErrorMessage =
            ReactNode(BillingConstants.INVALID_VALUE_ERROR_MESSAGE)
    } else {
        validation.waterBillValidationState.flowUsedForBillingErrorState = false
        validation.waterBillValidationState.flowUsedForBillingErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun validateBioChemicalOxygenDemand(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.waterBillAdditional.biochemicalOxygenDemand == null) {
        validation.waterBillValidationState.bioChemicalOxygenDemandErrorState = false
        validation.waterBillValidationState.bioChemicalOxygenDemandErrorMessage = null
    } else if (getDigitsCountBeforeDecimal(state.waterBillAdditional.biochemicalOxygenDemand.toString()) > BillingConstants.MAX_VALUE_DIGITS) {
        validation.waterBillValidationState.bioChemicalOxygenDemandErrorState = true
        validation.waterBillValidationState.bioChemicalOxygenDemandErrorMessage =
            ReactNode(BillingConstants.INVALID_VALUE_ERROR_MESSAGE)
    } else {
        validation.waterBillValidationState.bioChemicalOxygenDemandErrorState = false
        validation.waterBillValidationState.bioChemicalOxygenDemandErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun validateBodSurchargeFactor(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.waterBillAdditional.bodSurchargeFactor == null) {
        validation.waterBillValidationState.bodSurchargeFactorErrorState = false
        validation.waterBillValidationState.bodSurchargeFactorErrorMessage = null
    } else if (getDigitsCountBeforeDecimal(state.waterBillAdditional.bodSurchargeFactor.toString()) > BillingConstants.MAX_VALUE_DIGITS) {
        validation.waterBillValidationState.bodSurchargeFactorErrorState = true
        validation.waterBillValidationState.bodSurchargeFactorErrorMessage =
            ReactNode(BillingConstants.INVALID_VALUE_ERROR_MESSAGE)
    } else {
        validation.waterBillValidationState.bodSurchargeFactorErrorState = false
        validation.waterBillValidationState.bodSurchargeFactorErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun validateBodCharges(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.waterBillAdditional.bodCharges == null) {
        validation.waterBillValidationState.bodChargesErrorState = false
        validation.waterBillValidationState.bodChargesErrorMessage = null
    } else if (getDigitsCountBeforeDecimal(state.waterBillAdditional.bodCharges.toString()) > BillingConstants.MAX_COST_DIGITS) {
        validation.waterBillValidationState.bodChargesErrorState = true
        validation.waterBillValidationState.bodChargesErrorMessage =
            ReactNode(BillingConstants.INVALID_COST_ERROR_MESSAGE)
    } else {
        validation.waterBillValidationState.bodChargesErrorState = false
        validation.waterBillValidationState.bodChargesErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun validateTotalSuspendedSolids(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.waterBillAdditional.totalSuspendedSolids == null) {
        validation.waterBillValidationState.totalSuspendedSolidsErrorState = false
        validation.waterBillValidationState.totalSuspendedSolidsErrorMessage = null
    } else if (getDigitsCountBeforeDecimal(state.waterBillAdditional.totalSuspendedSolids.toString()) > BillingConstants.MAX_VALUE_DIGITS) {
        validation.waterBillValidationState.totalSuspendedSolidsErrorState = true
        validation.waterBillValidationState.totalSuspendedSolidsErrorMessage =
            ReactNode(BillingConstants.INVALID_VALUE_ERROR_MESSAGE)
    } else {
        validation.waterBillValidationState.totalSuspendedSolidsErrorState = false
        validation.waterBillValidationState.totalSuspendedSolidsErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun validateTssSurchargeFactor(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.waterBillAdditional.tssSurchargeFactor == null) {
        validation.waterBillValidationState.tssSurchargeFactorErrorState = false
        validation.waterBillValidationState.tssSurchargeFactorErrorMessage = null
    } else if (getDigitsCountBeforeDecimal(state.waterBillAdditional.tssSurchargeFactor.toString()) > BillingConstants.MAX_VALUE_DIGITS) {
        validation.waterBillValidationState.tssSurchargeFactorErrorState = true
        validation.waterBillValidationState.tssSurchargeFactorErrorMessage =
            ReactNode(BillingConstants.INVALID_VALUE_ERROR_MESSAGE)
    } else {
        validation.waterBillValidationState.tssSurchargeFactorErrorState = false
        validation.waterBillValidationState.tssSurchargeFactorErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun validateTssCharges(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.waterBillAdditional.tssCharges == null) {
        validation.waterBillValidationState.tssChargesErrorState = false
        validation.waterBillValidationState.tssChargesErrorMessage = null
    } else if (getDigitsCountBeforeDecimal(state.waterBillAdditional.tssCharges.toString()) > BillingConstants.MAX_COST_DIGITS) {
        validation.waterBillValidationState.tssChargesErrorState = true
        validation.waterBillValidationState.tssChargesErrorMessage =
            ReactNode(BillingConstants.INVALID_COST_ERROR_MESSAGE)
    } else {
        validation.waterBillValidationState.tssChargesErrorState = false
        validation.waterBillValidationState.tssChargesErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun validateTotalSurchargeBill(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.waterBillAdditional.totalSurchargeBill == null) {
        validation.waterBillValidationState.totalSurchargeBillErrorState = false
        validation.waterBillValidationState.totalSurchargeBillErrorMessage = null
    } else if (getDigitsCountBeforeDecimal(state.waterBillAdditional.totalSurchargeBill.toString()) > BillingConstants.MAX_COST_DIGITS) {
        validation.waterBillValidationState.totalSurchargeBillErrorState = true
        validation.waterBillValidationState.totalSurchargeBillErrorMessage =
            ReactNode(BillingConstants.INVALID_COST_ERROR_MESSAGE)
    } else {
        validation.waterBillValidationState.totalSurchargeBillErrorState = false
        validation.waterBillValidationState.totalSurchargeBillErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun isBillingWaterFormValid(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>,
    sewerIncluded: Boolean
): Boolean {
    validateBillingWaterForm(state, validation, setValidation, sewerIncluded)
    validateBillingCommonFields(state, validation, setValidation, UtilityType.WATER)
    return waterFormFieldsValid(validation, sewerIncluded) && isBillingCommonFieldsValid(
        validation,
        UtilityType.ELECTRIC
    )
}

fun validateBillingWaterForm(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>,
    sewerIncluded: Boolean
) {
    validateServiceCharges(state, validation, setValidation)
    validateStormWaterCharges(state, validation, setValidation)

    if (sewerIncluded) {
        validateIndustrialSurcharge(state, validation, setValidation)
        validateFlow(state, validation, setValidation)
        validateSrf(state, validation, setValidation)
        validateFlowUsedForBilling(state, validation, setValidation)
        validateBioChemicalOxygenDemand(state, validation, setValidation)
        validateBodSurchargeFactor(state, validation, setValidation)
        validateBodCharges(state, validation, setValidation)
        validateTotalSuspendedSolids(state, validation, setValidation)
        validateTssSurchargeFactor(state, validation, setValidation)
        validateTssCharges(state, validation, setValidation)
        validateTotalSurchargeBill(state, validation, setValidation)
    }
}

fun waterFormFieldsValid(validation: UtilityBillValidationState, sewerIncluded: Boolean): Boolean {

    val sewerFieldsValidation = !(validation.waterBillValidationState.industrialSurchargeErrorState ||
            validation.waterBillValidationState.flowErrorState ||
            validation.waterBillValidationState.srfErrorState ||
            validation.waterBillValidationState.flowUsedForBillingErrorState ||
            validation.waterBillValidationState.bioChemicalOxygenDemandErrorState ||
            validation.waterBillValidationState.bodSurchargeFactorErrorState ||
            validation.waterBillValidationState.bodChargesErrorState ||
            validation.waterBillValidationState.totalSuspendedSolidsErrorState ||
            validation.waterBillValidationState.tssSurchargeFactorErrorState ||
            validation.waterBillValidationState.tssChargesErrorState ||
            validation.waterBillValidationState.totalSurchargeBillErrorState
            )

    val waterFieldsValidation = !(validation.waterBillValidationState.serviceChargesErrorState ||
            validation.waterBillValidationState.stormWaterChargesErrorState)

    return if (sewerIncluded) {
        sewerFieldsValidation && waterFieldsValidation
    } else {
        waterFieldsValidation
    }
}
