package com.ecosave.watch.portal.helpers.facilitygroupmanagement

import com.ecosave.watch.portal.models.facilitygroupmanagement.FacilityGroup
import com.ecosave.watch.portal.models.facilitygroupmanagement.FacilitySummary
import js.core.jso
import js.typedarrays.TypedArray

fun findGroupById(groups: List<FacilityGroup>, targetGroupId: Int): FacilityGroup? {
    for (group in groups) {
        if (group.facilityGroupId == targetGroupId) {
            return group
        }
        val foundInChildGroups = findGroupById(group.childGroups ?: emptyList(), targetGroupId)
        if (foundInChildGroups != null) {
            return foundInChildGroups
        }
    }
    return null
}

fun findParentFacilities(rootGroup: FacilityGroup, childGroupId: Int): List<FacilitySummary> {
    rootGroup.childGroups?.forEach { childGroup ->
        if (childGroup.facilityGroupId == childGroupId) {
            return rootGroup.facilities
        }

        val parentFacilities = findParentFacilities(childGroup, childGroupId)
        if (parentFacilities.isNotEmpty()) {
            return parentFacilities
        }
    }
    return emptyList()
}

fun addChildGroupToSelectedGroup(
    mainGroup: FacilityGroup,
    selectedGroupId: Int,
    newChildGroup: FacilityGroup
): FacilityGroup? {

    if (mainGroup.facilityGroupId == selectedGroupId) {
        if (mainGroup.childGroups == null) {
            mainGroup.childGroups = mutableListOf(newChildGroup)
        } else {
            mainGroup.childGroups?.add(newChildGroup)
        }
        return mainGroup
    } else {
        mainGroup.childGroups?.forEach { child ->
            val updatedChildGroup = addChildGroupToSelectedGroup(child, selectedGroupId, newChildGroup)
            if (updatedChildGroup != null) {
                return@addChildGroupToSelectedGroup mainGroup
            }
        }
    }

    return null
}

fun editSelectedGroup(
    mainGroup: FacilityGroup,
    selectedGroupId: Int,
    selectedGroupName: String,
    selectedGroupFacilities: MutableList<FacilitySummary>
): FacilityGroup? {
    if (mainGroup.facilityGroupId == selectedGroupId) {
        mainGroup.facilityGroupName = selectedGroupName
        mainGroup.facilities = selectedGroupFacilities
        return mainGroup
    } else {
        mainGroup.childGroups?.forEach { child ->
            val updatedChildGroup = editSelectedGroup(child, selectedGroupId, selectedGroupName, selectedGroupFacilities)
            if (updatedChildGroup != null) {
                return@editSelectedGroup mainGroup
            }
        }
    }
    return null
}

fun removeGroupFromMainGroup(mainGroup: FacilityGroup?, selectedGroupId: Int): FacilityGroup? {
    if (mainGroup == null) {
        return null
    }

    if (mainGroup.facilityGroupId == selectedGroupId) {
        return null
    }

    val updatedChildGroups = mainGroup.childGroups?.mapNotNull { childGroup ->
        removeGroupFromMainGroup(childGroup, selectedGroupId)
    }?.toMutableList()

    mainGroup.childGroups = updatedChildGroups
    return mainGroup
}

fun createFacilitiesObjectDataArray(list: MutableList<FacilitySummary>): Array<dynamic> {

    val dataArray = mutableListOf<dynamic>()

    list.forEach {
        dataArray.add(
            jso {
                facilityId = it.facilityId
                facilityName = it.facilityName
                street = it.address.street
                city = it.address.city
                state = it.address.state
                zipcode = it.address.zipcode
                buildingCategory = it.buildingCategory.description
            }
        )
    }
    return dataArray.toTypedArray()
}
