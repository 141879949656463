package com.ecosave.watch.portal.helpers.onboarding

enum class OnboardingComponents(val description: String, var stepCompleted: Boolean) {
    FACILITY_REGISTRATION("Facility Registration", false),
    UTILITY_ACCOUNT_REGISTRATION("Utility Account and Bill Registration", false)
}

enum class MainSubscriptionPayment {
    PAYMENT_ALREADY_DONE,
    MAKE_PAYMENT,
    EXCEPTION
}
