package com.ecosave.watch.portal.models.facilitymanagement

import com.ecosave.watch.portal.helpers.facilitymanagement.BuildingCategory
import com.ecosave.watch.portal.helpers.facilitymanagement.BuildingType
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.LocalDateTime
import kotlinx.serialization.Serializable

@Serializable
data class Facility(
    val facilityId: Int?,
    val facilityName: String,
    val buildingCategory: BuildingCategory,
    val buildingType: BuildingType,
    val otherBuildingType: String?,
    val buildingTotalArea: Int,
    val buildingAddress: Address,
    val buildingYearBuilt: Int,
    val facilityDescription: String?,
    val propertyId: Int?,
    val buildingOccupancy: Int,
    val is24x7: Boolean,
    val energyStarSubscription: Boolean,
    val facilityDailyOperations: MutableList<DailyOperation>?,
    val cancellationEffectiveOn: LocalDateTime?
)

@Serializable
data class Address(
    var street: String,
    var city: String,
    var state: String,
    var zipcode: String
)


@Serializable
data class DailyOperation(
    var dayOfTheWeek: DayOfWeek,
    var startTime: String,
    var endTime: String
)
