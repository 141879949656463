package com.ecosave.watch.portal.components.utilitybills

import com.ecosave.watch.portal.models.billing.utilitybillstate.UtilityBillState
import com.ecosave.watch.portal.helpers.billing.UtilityType
import mui.material.TableRow
import react.FC
import react.Props
import react.StateSetter

external interface HotOrChilledWaterDataRowProps : Props {
    var editRowHotOrChilledWater: Boolean
    var stateHotOrChilledWater: UtilityBillState
    var setStateHotOrChilledWater: StateSetter<UtilityBillState>
    var utilityTypeHotOrChilledWater: UtilityType
}

val HotOrChilledWaterDataRow = FC<HotOrChilledWaterDataRowProps> { props ->

    TableRow {
        if (props.editRowHotOrChilledWater) {
            UsageUnit {
                stateUsageUnit = props.stateHotOrChilledWater
                setStateUsageUnit = props.setStateHotOrChilledWater
                usageUnits =
                    if (props.utilityTypeHotOrChilledWater == UtilityType.HOT_WATER) UtilityType.HOT_WATER.usageUnits else UtilityType.CHILLED_WATER.usageUnits
            }
        }
    }
}