package com.ecosave.watch.portal.components.esg.trainingandeducation

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val GRI_404_2 = FC<EsgSubFormComponentProps> { props ->
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.trainingAndEducation?.let { state ->
        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_404_2
        }

        Box {
            label {
                +"Type and scope of programs implemented and assistance provided to upgrade employee skills."
            }
            ESGTextAreaFieldWrapper {
                name = state::section4042A1.name
                value = state.section4042A1
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
        Box {
            label {
                +"Transition assistance programs provided to facilitate continued employability and the management of career endings resulting from "
                +"retirement or termination of employment."
            }
            ESGTextAreaFieldWrapper {
                name = state::section4042A2.name
                value = state.section4042A2
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}