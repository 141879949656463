package com.ecosave.watch.portal.components.esg.waterandeffluents

import com.ecosave.watch.portal.components.common.RomanListWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.helpers.esg.GenericSection3n3TitlesEnum
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val GRI_303_2 = FC<EsgSubFormComponentProps> { props ->
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.waterAndEffluents?.let { state ->
        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_303_2
        }

        Box {

            RomanListWrapper(
                title = "A description of any minimum standards set for the quality of effluent discharge, and " +
                    "how these minimum standards were determined, including:",
                list = listOf(
                    "how standards for facilities operating in locations with no local discharge requirements were " +
                        "determined;",
                    "any internally developed water quality standards or guidelines;",
                    "any sector-specific standards considered;",
                    "whether the profile of the receiving waterbody was considered"
                )
            )

            ESGTextAreaFieldWrapper {
                name = state::section3032B1.name
                value = state.section3032B1
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}