package com.ecosave.watch.portal.pages

import com.ecosave.watch.portal.Themes
import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.components.common.LinkWrapper
import com.ecosave.watch.portal.components.common.PaperWrapper
import com.ecosave.watch.portal.components.formcontrols.PasswordTextField
import com.ecosave.watch.portal.components.usermanagement.UserTextFieldWrapper
import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.PageRoutes
import com.ecosave.watch.portal.helpers.common.setUserContext
import com.ecosave.watch.portal.helpers.common.sm
import com.ecosave.watch.portal.helpers.common.xs
import com.ecosave.watch.portal.helpers.usermanagement.CompanyOrUserFields
import com.ecosave.watch.portal.helpers.usermanagement.isAdminRegistrationFormValid
import com.ecosave.watch.portal.helpers.usermanagement.validateCompanyName
import com.ecosave.watch.portal.helpers.usermanagement.validateFirstName
import com.ecosave.watch.portal.helpers.usermanagement.validateJobTitle
import com.ecosave.watch.portal.helpers.usermanagement.validateLastName
import com.ecosave.watch.portal.helpers.usermanagement.validateRegisterCompanyConfirmEmailAddress
import com.ecosave.watch.portal.helpers.usermanagement.validateRegisterCompanyEmailAddress
import com.ecosave.watch.portal.helpers.usermanagement.validateRegisterUserConfirmPassword
import com.ecosave.watch.portal.helpers.usermanagement.validateRegisterUserPassword
import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.models.usermanagement.RegisterCompanyOrUserState
import com.ecosave.watch.portal.models.usermanagement.RegisterCompanyOrUserValidationState
import com.ecosave.watch.portal.services.usermanagement.registerCompany
import com.ecosave.watch.portal.styles.CommonStyles
import com.ecosave.watch.portal.useGlobalState
import emotion.react.css
import io.ktor.http.*
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Checkbox
import mui.material.FormControl
import mui.material.FormHelperText
import mui.material.Grid
import mui.material.Size
import mui.material.Typography
import mui.material.TypographyAlign
import mui.material.styles.ThemeProvider
import mui.material.styles.TypographyVariant
import mui.system.responsive
import mui.system.sx
import react.ChildrenBuilder
import react.FC
import react.Props
import react.ReactNode
import react.dom.html.ReactHTML
import react.dom.onChange
import react.router.useNavigate
import react.useState
import web.cssom.AlignItems
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.FontWeight
import web.cssom.JustifyContent
import web.cssom.Padding
import web.cssom.TextAlign
import web.cssom.px
import web.cssom.unaryMinus
import web.html.HTMLInputElement
import web.html.InputType
import web.window.WindowTarget
import com.ecosave.watch.portal.styles.CommonStyles as commonstyles

val mainScope = MainScope()

val RegisterCompany = FC<Props> {

    val navigate = useNavigate()
    val (registerCompanyState, setRegisterCompanyState) = useState(RegisterCompanyOrUserState())
    val (registerCompanyValidationState, setRegisterCompanyValidationState) = useState(RegisterCompanyOrUserValidationState())
    val (notificationState, setNotificationState) = useState(NotificationState())
    var isSubmitting by useState(false)
    val globalState = useGlobalState()

    Box {
        className = commonstyles.BASIC_BACK.cssClass
        ThemeProvider {
            theme = Themes.lightTheme
            Box {
                sx {
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    gap = 20.px
                    justifyContent = JustifyContent.center
                    alignItems = AlignItems.center
                }
                Box {
                    ecosaveLogo()
                }
                PaperWrapper {
                    sx {
                        maxWidth = 900.px
                    }
                    Box {
                        sx {
                            padding = Padding(30.px, 50.px)
                        }
                        Grid {
                            sx {
                                justifyContent = JustifyContent.flexStart
                                alignItems = AlignItems.baseline
                            }
                            container = true
                            spacing = responsive(3)
                            Grid {
                                item = true
                                xs = 12
                                Box {
                                    sx {
                                        display = Display.flex
                                        flexDirection = FlexDirection.column
                                        justifyContent = JustifyContent.center
                                        gap = 15.px
                                    }
                                    Typography {
                                        sx {
                                            fontWeight = FontWeight.bold
                                        }
                                        align = TypographyAlign.center
                                        variant = TypographyVariant.h5
                                        +"Energy Management Portal"
                                    }
                                    Typography {
                                        align = TypographyAlign.center
                                        variant = TypographyVariant.h6
                                        +"Create Account"
                                    }
                                    Box {
                                        sx {
                                            textAlign = TextAlign.right
                                        }
                                        LinkWrapper {
                                            url = "https://ecosaveinc.com/turnkey-solutions/ecosave-watch/pricing"
                                            text = "See all offerings"
                                            openInNewTab = true
                                        }
                                    }
                                }
                            }
                            Grid {
                                item = true
                                xs = 12
                                sm = 6
                                UserTextFieldWrapper {
                                    label = ReactNode(CompanyOrUserFields.COMPANY_NAME.description)
                                    type = InputType.text
                                    value = registerCompanyState.companyName
                                    error = registerCompanyValidationState.companyNameErrorState
                                    helperText = registerCompanyValidationState.companyNameErrorMessage
                                    onChange = {
                                        val target = it.target as HTMLInputElement
                                        setRegisterCompanyState(
                                            registerCompanyState.copy(
                                                companyName = target.value
                                            )
                                        )
                                    }
                                    onBlur = {
                                        validateCompanyName(
                                            registerCompanyState,
                                            registerCompanyValidationState,
                                            setRegisterCompanyValidationState
                                        )
                                    }
                                }
                            }
                            Grid {
                                item = true
                                sm = 6
                                xs = 12
                                UserTextFieldWrapper {
                                    label = ReactNode(CompanyOrUserFields.JOB_TITLE.description)
                                    type = InputType.text
                                    value = registerCompanyState.jobTitle
                                    error = registerCompanyValidationState.jobTitleErrorState
                                    helperText = registerCompanyValidationState.jobTitleErrorMessage
                                    onChange = {
                                        val target = it.target as HTMLInputElement
                                        setRegisterCompanyState(
                                            registerCompanyState.copy(
                                                jobTitle = target.value
                                            )
                                        )
                                    }
                                    onBlur = {
                                        validateJobTitle(
                                            registerCompanyState,
                                            registerCompanyValidationState,
                                            setRegisterCompanyValidationState
                                        )
                                    }
                                }
                            }
                            Grid {
                                item = true
                                sm = 6
                                xs = 12
                                UserTextFieldWrapper {
                                    label = ReactNode(CompanyOrUserFields.FIRST_NAME.description)
                                    type = InputType.text
                                    value = registerCompanyState.firstName
                                    error = registerCompanyValidationState.firstNameErrorState
                                    helperText = registerCompanyValidationState.firstNameErrorMessage
                                    onChange = {
                                        val target = it.target as HTMLInputElement
                                        setRegisterCompanyState(
                                            registerCompanyState.copy(
                                                firstName = target.value
                                            )
                                        )
                                    }
                                    onBlur = {
                                        validateFirstName(
                                            registerCompanyState,
                                            registerCompanyValidationState,
                                            setRegisterCompanyValidationState
                                        )
                                    }
                                }
                            }
                            Grid {
                                item = true
                                sm = 6
                                xs = 12
                                UserTextFieldWrapper {
                                    label = ReactNode(CompanyOrUserFields.LAST_NAME.description)
                                    type = InputType.text
                                    value = registerCompanyState.lastName
                                    error = registerCompanyValidationState.lastNameErrorState
                                    helperText = registerCompanyValidationState.lastNameErrorMessage
                                    onChange = {
                                        val target = it.target as HTMLInputElement
                                        setRegisterCompanyState(
                                            registerCompanyState.copy(
                                                lastName = target.value
                                            )
                                        )
                                    }
                                    onBlur = {
                                        validateLastName(
                                            registerCompanyState,
                                            registerCompanyValidationState,
                                            setRegisterCompanyValidationState
                                        )
                                    }
                                }
                            }
                            Grid {
                                item = true
                                xs = 12
                                sm = 6
                                UserTextFieldWrapper {
                                    label = ReactNode(CompanyOrUserFields.EMAIL_ADDRESS.description)
                                    type = InputType.password
                                    value = registerCompanyState.emailAddress
                                    error = registerCompanyValidationState.emailAddressErrorState
                                    helperText = registerCompanyValidationState.emailAddressErrorMessage
                                    onChange = {
                                        val target = it.target as HTMLInputElement
                                        setRegisterCompanyState(
                                            registerCompanyState.copy(
                                                emailAddress = target.value
                                            )
                                        )
                                    }
                                    onBlur = {
                                        validateRegisterCompanyEmailAddress(
                                            registerCompanyState,
                                            registerCompanyValidationState,
                                            setRegisterCompanyValidationState
                                        )
                                    }
                                    onPaste = { event ->
                                        event.preventDefault()
                                    }
                                }
                            }
                            Grid {
                                item = true
                                xs = 12
                                sm = 6
                                UserTextFieldWrapper {
                                    label = ReactNode(CompanyOrUserFields.CONFIRM_EMAIL_ADDRESS.description)
                                    type = InputType.email
                                    value = registerCompanyState.confirmEmailAddress
                                    error = registerCompanyValidationState.confirmEmailAddressErrorState
                                    helperText = registerCompanyValidationState.confirmEmailAddressErrorMessage
                                    onChange = {
                                        val target = it.target as HTMLInputElement
                                        setRegisterCompanyState(
                                            registerCompanyState.copy(
                                                confirmEmailAddress = target.value
                                            )
                                        )
                                    }
                                    onPaste = { event ->
                                        event.preventDefault()
                                    }
                                    onBlur = {
                                        validateRegisterCompanyConfirmEmailAddress(
                                            registerCompanyState,
                                            registerCompanyValidationState,
                                            setRegisterCompanyValidationState
                                        )
                                    }
                                }
                            }
                            Grid {
                                item = true
                                sm = 6
                                xs = 12
                                PasswordTextField {
                                    label = ReactNode(CompanyOrUserFields.PASSWORD.description)
                                    value = registerCompanyState.password
                                    error = registerCompanyValidationState.passwordErrorState
                                    helperText = registerCompanyValidationState.passwordErrorMessage
                                    onChange = {
                                        val target = it.target as HTMLInputElement
                                        setRegisterCompanyState(
                                            registerCompanyState.copy(
                                                password = target.value
                                            )
                                        )
                                    }
                                    onBlur = {
                                        validateRegisterUserPassword(
                                            registerCompanyState,
                                            registerCompanyValidationState,
                                            setRegisterCompanyValidationState
                                        )
                                    }
                                }
                            }
                            Grid {
                                item = true
                                sm = 6
                                xs = 12
                                PasswordTextField {
                                    label = ReactNode(CompanyOrUserFields.CONFIRM_PASSWORD.description)
                                    value = registerCompanyState.confirmPassword
                                    error = registerCompanyValidationState.confirmPasswordErrorState
                                    helperText = registerCompanyValidationState.confirmPasswordErrorMessage
                                    onChange = {
                                        val target = it.target as HTMLInputElement
                                        setRegisterCompanyState(
                                            registerCompanyState.copy(
                                                confirmPassword = target.value
                                            )
                                        )
                                    }
                                    onBlur = {
                                        validateRegisterUserConfirmPassword(
                                            registerCompanyState,
                                            registerCompanyValidationState,
                                            setRegisterCompanyValidationState
                                        )
                                    }
                                }
                            }
                            Grid {
                                item = true
                                xs = 12
                                FormControl {
                                    sx {
                                        marginLeft = -10.px
                                    }
                                    error = registerCompanyValidationState.agreeTermsErrorState
                                    Box {
                                        Checkbox {
                                            onChange = { _, checked ->
                                                setRegisterCompanyState(
                                                    registerCompanyState.copy(
                                                        agreeTerms = checked
                                                    )
                                                )
                                            }
                                        }
                                        +"I agree to the"
                                        mui.material.Link {
                                            href = "/legal/privacy-policy"
                                            target = WindowTarget._blank
                                            className = CommonStyles.CURSOR.cssClass
                                            sx {
                                                marginLeft = 7.px
                                                marginRight = 7.px
                                            }
                                            +"Privacy Policy"
                                        }
                                        +"and"
                                        mui.material.Link {
                                            href = "/legal/terms-of-service"
                                            className = CommonStyles.CURSOR.cssClass
                                            target = WindowTarget._blank
                                            sx {
                                                marginLeft = 7.px
                                            }
                                            +"Terms of Service"
                                        }
                                    }
                                    FormHelperText {
                                        +registerCompanyValidationState.agreeTermsErrorMessage
                                    }
                                }
                            }
                            Grid {
                                item = true
                                xs = 12
                                Box {
                                    className = CommonStyles.CENTER_ITEM.cssClass
                                    Button {
                                        sx {
                                            width = 175.px
                                        }
                                        disabled = isSubmitting
                                        fullWidth = true
                                        variant = ButtonVariant.contained
                                        size = Size.large
                                        onClick = {
                                            if (isAdminRegistrationFormValid(
                                                    registerCompanyState,
                                                    registerCompanyValidationState,
                                                    setRegisterCompanyValidationState
                                                )
                                            ) {
                                                mainScope.launch {
                                                    isSubmitting = true
                                                    val response = registerCompany(registerCompanyState)
                                                    if (response == null) {
                                                        setNotificationState(
                                                            notificationState.copy(
                                                                status = NotificationStatus.ERROR,
                                                                message = Constants.NOTIFICATION_ERROR_MESSAGE,
                                                                visible = true
                                                            )
                                                        )
                                                    } else if (response.httpStatusCode == HttpStatusCode.Conflict) {
                                                        setNotificationState(
                                                            notificationState.copy(
                                                                status = NotificationStatus.ERROR,
                                                                message = "This email address already exists in the system.",
                                                                visible = true
                                                            )
                                                        )
                                                    } else if (response.httpStatusCode == HttpStatusCode.Created) {
                                                        setUserContext(
                                                            response.loginResponse!!.token,
                                                            response.loginResponse.onBoardingStatus,
                                                            globalState,
                                                            navigate
                                                        )
                                                    }

                                                    isSubmitting = false
                                                }
                                            }

                                        }
                                        +if (isSubmitting) "Submitting.." else "Submit"
                                    }
                                }
                            }

                            Grid {
                                item = true
                                xs = 12
                                Box {
                                    sx {
                                        textAlign = TextAlign.center
                                    }
                                    LinkWrapper {
                                        url = PageRoutes.SIGN_IN.route
                                        text = "Sign In With Existing Account"
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }

    }
    AlertNotifications {
        open = notificationState.visible
        status = notificationState.status
        message = notificationState.message
        closeNotification = {
            setNotificationState(
                notificationState.copy(
                    visible = false
                )
            )
        }
    }
}

fun ChildrenBuilder.ecosaveLogo(): Unit = ReactHTML.img {
    css {
        width = 170.px
    }
    src = "/images/logo/company-logo.png"
}