package com.ecosave.watch.portal.components.utilityaccounts

import com.ecosave.watch.portal.helpers.billing.BillingCommonAnnotations
import com.ecosave.watch.portal.helpers.billing.BillingHeaders
import com.ecosave.watch.portal.helpers.billing.validateTotalBillCost
import com.ecosave.watch.portal.models.billing.utilitybillstate.UtilityBillState
import com.ecosave.watch.portal.models.billing.UtilityBillValidationState
import react.FC
import react.Props
import react.ReactNode
import react.StateSetter
import react.dom.onChange
import web.html.HTMLInputElement
import web.html.InputType

external interface AddTotalBillCostProps : Props {
    var state: UtilityBillState
    var setState: StateSetter<UtilityBillState>
    var validation: UtilityBillValidationState
    var setValidation: StateSetter<UtilityBillValidationState>
}

val AddTotalBillCost = FC<AddTotalBillCostProps> { props ->
    TextFieldWrapper {
        label = ReactNode(
            "${BillingHeaders.TOTAL_BILL_COST.header} ($) *"
        )
        type = InputType.number
        annotation = BillingCommonAnnotations.TOTAL_BILL_COST
        value = props.state.totalBillCost
        onChange = {
            val target = it.target as HTMLInputElement
            props.setState(
                props.state.copy(
                    totalBillCost = target.value.toFloatOrNull()
                )
            )
        }
        error = props.validation.totalBillCostErrorState
        helperText = props.validation.totalBillCostErrorMessage
        onBlur = {
            validateTotalBillCost(props.state, props.validation, props.setValidation)
        }
    }
}