package com.ecosave.watch.portal.services.billing

import com.ecosave.watch.portal.helpers.BackendServerURL
import com.ecosave.watch.portal.models.billing.UtilityBillSummary
import com.ecosave.watch.portal.services.httpClient
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*

suspend fun getServiceNames(params: String = ""): List<String> {
    try {
        val response: HttpResponse =
            httpClient.get("$BackendServerURL/utility-account/service-names$params") {
                contentType(ContentType.Application.Json)
            }
        if (response.status == HttpStatusCode.OK) {
            console.log(response.body())
            return response.body()
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return emptyList()
}

suspend fun getAccountNumbers(params: String = ""): List<String> {
    try {
        val response: HttpResponse =
            httpClient.get("$BackendServerURL/utility-account/account-numbers$params")
        if (response.status == HttpStatusCode.OK) {
            return response.body()
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return emptyList()
}

suspend fun getUtilityBills(params: String = ""): List<UtilityBillSummary> {
    try {
        val response: HttpResponse =
            httpClient.get("$BackendServerURL/utilityBill/summaries$params")
        if (response.status == HttpStatusCode.OK) {
            return response.body()
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return emptyList()
}