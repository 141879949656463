package com.ecosave.watch.portal.models.billing

import kotlinx.serialization.Serializable

@Serializable
data class UtilityAccountFiltersResponse(
    val facilities: List<Facility> = mutableListOf(),
    val streets: List<String> = mutableListOf(),
    val zipcodes: List<String> = mutableListOf(),
    val cities: List<String> = mutableListOf(),
    val states: List<String> = mutableListOf()
)
