package com.ecosave.watch.portal.pages

import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.components.common.DialogWrapper
import com.ecosave.watch.portal.components.common.Loading
import com.ecosave.watch.portal.components.common.MessageDialogComponent
import com.ecosave.watch.portal.components.common.SortableTableCell
import com.ecosave.watch.portal.components.common.TableHeader
import com.ecosave.watch.portal.components.workorder.ActionManagementTableFilterComponent
import com.ecosave.watch.portal.components.workorder.ActionSeverityChip
import com.ecosave.watch.portal.components.workorder.WorkForceTableComponent
import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.helpers.common.AutoCompleteOption
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.PageTitles
import com.ecosave.watch.portal.helpers.workorder.ActionStatus
import com.ecosave.watch.portal.helpers.workorder.WorkOrderConstants
import com.ecosave.watch.portal.helpers.workorder.getActionManagementFilterParams
import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.models.controlcenter.ActionsData
import com.ecosave.watch.portal.models.usermanagement.UserActionsData
import com.ecosave.watch.portal.models.workorder.ActionDataClass
import com.ecosave.watch.portal.models.workorder.ActionManagementTableFilterDataClass
import com.ecosave.watch.portal.models.workorder.ActionStateDataClass
import com.ecosave.watch.portal.models.workorder.ActionsAssignmentState
import com.ecosave.watch.portal.services.billing.getAccountNumbers
import com.ecosave.watch.portal.services.getAllFacilities
import com.ecosave.watch.portal.services.workorder.assignActions
import com.ecosave.watch.portal.services.workorder.getActions
import com.ecosave.watch.portal.services.workorder.getEventFilterOptions
import com.ecosave.watch.portal.services.workorder.getWorkForce
import com.ecosave.watch.portal.services.workorder.updateAction
import com.ecosave.watch.portal.styles.CommonStyles
import com.ecosave.watch.portal.useGlobalState
import kotlinx.coroutines.launch
import mui.icons.material.ChevronLeft
import mui.icons.material.Edit
import mui.icons.material.InfoOutlined
import mui.icons.material.PersonAdd
import mui.icons.material.Visibility
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Checkbox
import mui.material.DialogActions
import mui.material.DialogContent
import mui.material.FormControl
import mui.material.FormHelperText
import mui.material.IconButton
import mui.material.InputLabel
import mui.material.MenuItem
import mui.material.Select
import mui.material.Size
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableCellAlign
import mui.material.TableContainer
import mui.material.TableHead
import mui.material.TableRow
import mui.material.TextField
import mui.material.Tooltip
import mui.material.Typography
import mui.material.TypographyAlign
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.dom.onChange
import react.router.useNavigate
import react.useEffect
import react.useEffectOnce
import react.useState
import web.cssom.AlignItems
import web.cssom.Display
import web.cssom.JustifyContent
import web.cssom.Margin
import web.cssom.px
import web.html.HTMLTextAreaElement
import web.html.InputType

val WorkOrderActionManagementPage = FC<Props> {

    var isLoading by useState(false)
    var isSubmitting by useState(false)
    var openActionManagementDialog by useState(false)
    var actionsList by useState(mutableListOf<ActionDataClass>())
    val (notificationState, notificationStateSetter) = useState(NotificationState())
    val (actionState, actionStateSetter) = useState(ActionStateDataClass())
    var statusErrorState by useState(false)
    val navigate = useNavigate()
    var openMessageDialog by useState(false)
    var notes by useState("")
    val globalState = useGlobalState()
    var sort by useState("")
    var sortBy by useState("")
    val (filters, filtersStateSetter) = useState(ActionManagementTableFilterDataClass())
    var activeFiltersCount by useState(0)
    var userOptions by useState<Array<AutoCompleteOption>>(emptyArray())
    var eventFilterOptions by useState<Array<AutoCompleteOption>>(emptyArray())
    val (actionsAssignmentState, setActionsAssignmentState) = useState(ActionsAssignmentState())
    var openAssignmentDialog by useState(false)
    var assignToErrorState by useState(false)
    var facilityFilterOptions by useState<Array<AutoCompleteOption>>(emptyArray())
    var accountNumberFilterOptions by useState<Array<AutoCompleteOption>>(emptyArray())
    var actionsMessage by useState("")

    fun updateActionsList(list: MutableList<ActionDataClass>?) {
        if (list == null) {
            actionsMessage = "An error has occurred while fetching actions, please retry."
            actionsList = mutableListOf()
        } else if (list.isEmpty()) {
            actionsMessage = "No Actions Found (you will not see closed actions unless you apply filter)"
            actionsList = mutableListOf()
        } else {
            actionsList = list
        }
    }

    useEffectOnce {
        globalState.updatePageTitle(PageTitles.ACTION_MANAGEMENT.title)
        mainScope.launch {
            isLoading = true
            val workForce = getWorkForce()
            val events = getEventFilterOptions()
            val facilities = getAllFacilities()
            val accountNumbers = getAccountNumbers()
            if (workForce.isNotEmpty()) {
                userOptions = workForce.map {
                    AutoCompleteOption(it.username, it.emailAddress)
                }.toTypedArray()
            }
            if (events.isNotEmpty()) {
                eventFilterOptions = events.map {
                    AutoCompleteOption(it.event, it.eventId.toString())
                }.toTypedArray()
            }
            if (facilities.isNotEmpty()) {
                facilityFilterOptions = facilities.map {
                    AutoCompleteOption(it.facilityName.toString(), it.facilityId.toString())
                }.toTypedArray()
            }
            if (accountNumbers.isNotEmpty()) {
                accountNumberFilterOptions = accountNumbers.map {
                    AutoCompleteOption(it, it)
                }.toTypedArray()
            }
            isLoading = false
        }
    }

    useEffect(filters, sort, sortBy) {
        mainScope.launch {
            isLoading = true
            var params = "?sortOrder=$sort&sortBy=$sortBy"
            val filterParams = getActionManagementFilterParams(filters)
            params += filterParams.params
            activeFiltersCount = filterParams.counter
            if (globalState.observationId != null) {
                val list = getActions("?observationId=${globalState.observationId}")
                updateActionsList(list)
                globalState.observationId = null
            } else if (globalState.actionsData.navigatedFromControlCenter) {
                filtersStateSetter(
                    filters.copy(
                        facility = mutableListOf(AutoCompleteOption(globalState.actionsData.facilityName, globalState.actionsData.facilityId)),
                        severity = mutableListOf(
                            AutoCompleteOption(
                                globalState.actionsData.severity.description,
                                globalState.actionsData.severity.name
                            )
                        ),
                        status = mutableListOf(AutoCompleteOption(ActionStatus.UNASSIGNED.description, ActionStatus.UNASSIGNED.name))
                    )
                )
                globalState.updateActionsData(
                    ActionsData()
                )
            } else if (globalState.userActionsData.navigatedFromUserManagement) {
                filtersStateSetter(
                    filters.copy(
                        userEmail = mutableListOf(AutoCompleteOption(globalState.userActionsData.username, globalState.userActionsData.email)),
                        status = mutableListOf(
                            AutoCompleteOption(ActionStatus.UNASSIGNED.description, ActionStatus.UNASSIGNED.name),
                            AutoCompleteOption(ActionStatus.ASSIGNED.description, ActionStatus.ASSIGNED.name),
                            AutoCompleteOption(ActionStatus.IN_PROGRESS.description, ActionStatus.IN_PROGRESS.name),
                            AutoCompleteOption(ActionStatus.COMPLETED.description, ActionStatus.COMPLETED.name)
                        )
                    )
                )
                globalState.updateUserActionsData(
                    UserActionsData()
                )
            } else {
                val list = getActions(params)
                updateActionsList(list)
            }
            isLoading = false
        }
    }

    Box {
        Box {
            sx {
                marginBottom = 10.px
                display = Display.flex
                justifyContent = JustifyContent.spaceBetween
            }
            Box {
                sx {
                    display = Display.flex
                    gap = 15.px
                    alignItems = AlignItems.center
                }
                Button {
                    variant = ButtonVariant.contained
                    onClick = {
                        navigate.invoke("/observations")
                    }
                    startIcon = startIcon.also {
                        ChevronLeft()
                    }
                    +"Observation Summary"
                }
                if (actionsAssignmentState.actionIds.size != 0) {
                    Typography {
                        variant = TypographyVariant.body1
                        align = TypographyAlign.center
                        +"${actionsAssignmentState.actionIds.size} of ${actionsList.size} actions selected"
                    }
                }
            }

            Box {
                sx {
                    display = Display.flex
                    gap = 15.px
                }
                Button {
                    variant = ButtonVariant.outlined
                    disabled = actionsAssignmentState.actionIds.isEmpty()
                    onClick = {
                        openAssignmentDialog = true
                    }
                    startIcon = startIcon.also {
                        PersonAdd()
                    }
                    +" Assign Actions"
                }
                ActionManagementTableFilterComponent {
                    currentFilterOptions = filters
                    currentFilterOptionsStateSetter = filtersStateSetter
                    currentUserOptions = userOptions
                    this.eventFilterOptions = eventFilterOptions
                    this.facilityFilterOptions = facilityFilterOptions
                    this.accountNumberFilterOptions = accountNumberFilterOptions
                    filtersCount = activeFiltersCount
                }
            }
        }

        Box {
            TableContainer {
                className = CommonStyles.TABLE_HEIGHT.cssClass
                Table {
                    stickyHeader = true
                    TableHead {
                        TableRow {
                            hover = true
                            if (actionsList.isNotEmpty()) {
                                TableCell {
                                    Checkbox {
                                        checked = (actionsList.count { it.status != ActionStatus.COMPLETED } == actionsAssignmentState.actionIds.size)
                                        onChange = { _, checked ->
                                            val actionsId: MutableList<Int> = mutableListOf()
                                            if (checked) {
                                                actionsList.forEach {
                                                    if (it.status != ActionStatus.COMPLETED) {
                                                        actionsId.add(it.actionId)
                                                    }
                                                }
                                            }
                                            setActionsAssignmentState(
                                                actionsAssignmentState.copy(
                                                    actionIds = actionsId
                                                )
                                            )
                                        }
                                    }
                                }
                            }
                            TableHeader {
                                tableHeaderName = "Severity"
                            }
                            TableHeader {
                                tableHeaderName = "Facility"
                            }
                            TableHeader {
                                tableHeaderName = "Utility"
                            }
                            TableHeader {
                                tableHeaderName = "Account Number"
                            }
                            TableHeader {
                                tableHeaderName = "Event"
                            }
                            TableHeader {
                                tableHeaderName = "Observation"
                            }
                            TableHeader {
                                tableHeaderName = "Action"
                            }
                            for (header in WorkOrderConstants.actionsTableHeaderList) {
                                SortableTableCell {
                                    key = header.id
                                    label = header.label
                                    active = header.id == sortBy
                                    isAsc = sort == "ASC"
                                    createSortHandler = {
                                        val isAsc = sortBy == header.id && sort == "ASC"
                                        val toggleSort = if (isAsc) {
                                            "DESC"
                                        } else {
                                            "ASC"
                                        }
                                        sortBy = header.id
                                        sort = toggleSort
                                    }
                                }
                            }
                            TableHeader {
                                tableHeaderName = "Notes"
                            }
                            TableHeader {
                                tableHeaderName = "Manage Action"
                            }
                        }
                    }
                    TableBody {
                        if (isLoading) {
                            TableRow {
                                TableCell {
                                    colSpan = 14
                                    className = CommonStyles.REMOVED_CELL_BOTTOM_BORDER.cssClass
                                    align = TableCellAlign.center
                                    Box {
                                        sx {
                                            height = 400.px
                                        }
                                        Loading()
                                    }
                                }
                            }
                        } else if (actionsList.isEmpty()) {
                            TableRow {
                                TableCell {
                                    colSpan = 14
                                    align = TableCellAlign.center
                                    +actionsMessage
                                }
                            }
                        } else {
                            for (action in actionsList) {
                                TableRow {
                                    hover = true
                                    TableCell {
                                        Tooltip {
                                            title =
                                                if (action.status == ActionStatus.COMPLETED) ReactNode("This action is closed.") else ReactNode("")
                                            Box {
                                                Checkbox {
                                                    checked = actionsAssignmentState.actionIds.contains(action.actionId)
                                                    disabled = action.status == ActionStatus.COMPLETED
                                                    onChange = { _, checked ->
                                                        val actionIdsList = actionsAssignmentState.actionIds

                                                        if (checked && action.status != ActionStatus.COMPLETED) {
                                                            actionIdsList.add(action.actionId)
                                                        } else {
                                                            actionIdsList.remove(action.actionId)
                                                        }
                                                        setActionsAssignmentState(
                                                            actionsAssignmentState.copy(
                                                                actionIds = actionIdsList
                                                            )
                                                        )
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    TableCell {
                                        ActionSeverityChip {
                                            label = action.severity.description
                                            severity = action.severity
                                        }
                                    }
                                    TableCell {
                                        +action.facilityName
                                    }
                                    TableCell {
                                        +action.utility
                                    }
                                    TableCell {
                                        +action.accountNumber
                                    }
                                    TableCell {
                                        sx {
                                            minWidth = 150.px
                                        }
                                        +action.event
                                        Box {
                                            sx {
                                                display = Display.flex
                                                gap = 10.px
                                                alignItems = AlignItems.center
                                            }
                                            Tooltip {
                                                title = ReactNode(action.eventDescription)
                                                InfoOutlined {
                                                    sx {
                                                        fontSize = 20.px
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    TableCell {
                                        sx {
                                            minWidth = 250.px
                                        }
                                        +action.observation
                                    }
                                    TableCell {
                                        sx {
                                            minWidth = 250.px
                                        }
                                        +action.action
                                    }
                                    TableCell {
                                        +action.status.description
                                    }
                                    TableCell {
                                        +action.generatedDate
                                    }
                                    TableCell {
                                        Box {
                                            sx {
                                                display = Display.flex
                                                gap = 10.px
                                                alignItems = AlignItems.center
                                            }
                                            if (action.username == null) +"" else +action.username
                                            if (action.emailAddress != null) {
                                                Tooltip {
                                                    title = ReactNode(
                                                        action.emailAddress ?: ""
                                                    )
                                                    InfoOutlined {
                                                        sx {
                                                            fontSize = 20.px
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    TableCell {
                                        Box {
                                            sx {
                                                display = Display.flex
                                                gap = 10.px
                                                alignItems = AlignItems.center
                                            }
                                            if (action.closedByUsername == null) +"" else +action.closedByUsername
                                            if (action.closedByEmailAddress != null) {
                                                Tooltip {
                                                    title = ReactNode(
                                                        action.closedByEmailAddress ?: ""
                                                    )
                                                    InfoOutlined {
                                                        sx {
                                                            fontSize = 20.px
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    TableCell {
                                        if (action.closedDate == null) +"" else +action.closedDate
                                    }
                                    TableCell {
                                        Tooltip {
                                            title = ReactNode("View Notes")
                                            IconButton {
                                                onClick = {
                                                    action.notes?.let {
                                                        notes = it
                                                    }
                                                    openMessageDialog = true
                                                }
                                                Visibility()
                                            }
                                        }
                                    }
                                    TableCell {
                                        align = TableCellAlign.center
                                        Tooltip {
                                            title =
                                                if (action.emailAddress == null) ReactNode("Assign action to a user to edit it.") else ReactNode("")
                                            Box {
                                                IconButton {
                                                    disabled = action.emailAddress == null
                                                    onClick = {
                                                        actionStateSetter(
                                                            actionState.copy(
                                                                actionId = action.actionId,
                                                                status = action.status.name,
                                                                notes = action.notes ?: ""
                                                            )
                                                        )
                                                        openActionManagementDialog = true
                                                    }
                                                    Edit()
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    MessageDialogComponent {
        title = "Notes"
        open = openMessageDialog
        message = notes
        formattedMessage = true
        actionResult = { value ->
            openMessageDialog = false
            notes = ""
        }
    }
    DialogWrapper {
        open = openActionManagementDialog
        dialogTitle = "Manage Action"
        DialogContent {
            FormControl {
                sx {
                    margin = Margin(20.px, 0.px, 0.px, 20.px)
                }
                InputLabel {
                    +"Status *"
                }
                fullWidth = true
                size = Size.medium
                error = statusErrorState
                Select {
                    sx {
                        marginRight = 40.px
                    }
                    label = ReactNode("Status *")
                    value = actionState.status
                    for (status in WorkOrderConstants.actionStatusList) {
                        MenuItem {
                            value = status.name
                            +status.description
                        }
                    }
                    onChange = { event, _ ->
                        actionStateSetter(
                            actionState.copy(
                                status = event.target.value
                            )
                        )
                    }
                }
                FormHelperText {
                    if (statusErrorState) +Constants.REQUIRED_FIELD_ERROR_MESSAGE
                }
            }
            Box {
                sx {
                    margin = 20.px
                }
                TextField {
                    fullWidth = true
                    size = Size.medium
                    type = InputType.text
                    label = ReactNode("Notes")
                    multiline = true
                    minRows = 5
                    maxRows = 20
                    value = actionState.notes
                    onChange = {
                        val target = it.target as HTMLTextAreaElement
                        actionStateSetter(
                            actionState.copy(
                                notes = target.value
                            )
                        )
                    }
                }
            }
            DialogActions {
                sx {
                    justifyContent = JustifyContent.center
                }
                Button {
                    +"Cancel"
                    onClick = {
                        actionStateSetter(ActionStateDataClass())
                        openActionManagementDialog = false
                        statusErrorState = false
                    }
                }
                Button {
                    if (isSubmitting) +"Submitting..." else +"Submit"
                    disabled = isSubmitting
                    variant = ButtonVariant.contained
                    onClick = {
                        if (actionState.status == ActionStatus.ASSIGNED.name) {
                            statusErrorState = true
                        } else {
                            mainScope.launch {
                                isSubmitting = true
                                val apiResponse = updateAction(actionState)
                                if (apiResponse == null) {

                                    notificationStateSetter(
                                        notificationState.copy(
                                            status = NotificationStatus.ERROR,
                                            message = Constants.NOTIFICATION_ERROR_MESSAGE,
                                            visible = true
                                        )
                                    )

                                } else {
                                    notificationStateSetter(
                                        notificationState.copy(
                                            status = NotificationStatus.SUCCESS,
                                            message = "Action Updated Successfully.",
                                            visible = true
                                        )
                                    )

                                    val index = actionsList.indexOf(actionsList.find {
                                        it.actionId == actionState.actionId
                                    })

                                    actionsList[index].status = ActionStatus.valueOf(actionState.status)
                                    actionsList[index].notes = actionState.notes
                                    actionsList[index].closedDate = apiResponse.closedDate
                                    actionsList[index].username = apiResponse.assignedUsername
                                    actionsList[index].emailAddress = apiResponse.assignedEmailAddress
                                    actionsList[index].closedByUsername = apiResponse.closedByUsername
                                    actionsList[index].closedByEmailAddress = apiResponse.closedByEmailAddress

                                    actionStateSetter(ActionStateDataClass())

                                    openActionManagementDialog = false
                                    statusErrorState = false
                                }
                                isSubmitting = false
                            }
                        }
                    }
                }
            }
        }
    }
    AlertNotifications {
        open = notificationState.visible
        status = notificationState.status
        message = notificationState.message
        closeNotification = {
            notificationStateSetter(
                notificationState.copy(
                    visible = false
                )
            )
        }
    }
    DialogWrapper {
        open = openAssignmentDialog
        dialogTitle = "Assign Action(s)"
        DialogContent {
            FormControl {
                sx {
                    marginTop = 20.px
                }
                InputLabel {
                    +"Assign To *"
                }
                fullWidth = true
                size = Size.medium
                error = assignToErrorState
                Select {
                    label = ReactNode("Assign To *")
                    value = actionsAssignmentState.assignTo
                    userOptions.toMutableList().forEach {
                        MenuItem {
                            value = it.value
                            +"${it.label} (${it.value})"
                        }
                    }
                    onChange = { event, _ ->
                        setActionsAssignmentState(
                            actionsAssignmentState.copy(
                                assignTo = event.target.value
                            )
                        )
                    }
                }
                FormHelperText {
                    if (assignToErrorState) +Constants.REQUIRED_FIELD_ERROR_MESSAGE
                }
            }
            WorkForceTableComponent {}
        }
        DialogActions {
            sx {
                justifyContent = JustifyContent.center
            }
            Button {
                +"Cancel"
                onClick = {
                    openAssignmentDialog = false
                    assignToErrorState = false
                    setActionsAssignmentState(
                        actionsAssignmentState.copy(
                            assignTo = ""
                        )
                    )
                }
            }
            Button {
                disabled = isSubmitting
                if (isSubmitting) +"Submitting..." else +"Submit"
                variant = ButtonVariant.contained
                onClick = {
                    if (actionsAssignmentState.assignTo.isBlank()) {
                        assignToErrorState = true
                    } else {
                        com.ecosave.watch.portal.helpers.mainScope.launch {
                            isSubmitting = true
                            val response = assignActions(actionsAssignmentState)
                            if (response == null) {
                                notificationStateSetter(
                                    notificationState.copy(
                                        status = NotificationStatus.ERROR,
                                        message = Constants.NOTIFICATION_ERROR_MESSAGE,
                                        visible = true
                                    )
                                )
                            } else {

                                notificationStateSetter(
                                    notificationState.copy(
                                        status = NotificationStatus.SUCCESS,
                                        message = "Action(s) Assigned Successfully",
                                        visible = true
                                    )
                                )

                                openAssignmentDialog = false
                                assignToErrorState = false

                                // update actions
                                response.actionAndStatus.forEach { it ->
                                    actionsList.forEach { action ->
                                        if (action.actionId == it.actionId) {
                                            action.emailAddress = response.assignedUserEmailAddress
                                            action.username = response.assignedUsername
                                            action.status = it.status
                                        }
                                    }
                                }

                                setActionsAssignmentState(
                                    actionsAssignmentState.copy(
                                        assignTo = "",
                                        actionIds = mutableListOf()
                                    )
                                )

                            }
                            isSubmitting = false
                        }
                    }
                }
            }
        }
    }
}
