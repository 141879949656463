package com.ecosave.watch.portal.components.utilityaccounts

import com.ecosave.watch.portal.helpers.billing.BillingCommonAnnotations
import com.ecosave.watch.portal.helpers.billing.BillingHeaders
import com.ecosave.watch.portal.helpers.billing.validateTotalUsage
import com.ecosave.watch.portal.models.billing.utilitybillstate.UtilityBillState
import com.ecosave.watch.portal.models.billing.UtilityBillValidationState
import react.FC
import react.Props
import react.ReactNode
import react.StateSetter
import react.dom.onChange
import web.html.HTMLInputElement
import web.html.InputType

external interface AddTotalUsageProps : Props {
    var state: UtilityBillState
    var setState: StateSetter<UtilityBillState>
    var validation: UtilityBillValidationState
    var setValidation: StateSetter<UtilityBillValidationState>
}

val AddTotalUsage = FC<AddTotalUsageProps> { props ->
    TextFieldWrapper {
        label = ReactNode(
            "${BillingHeaders.TOTAL_USAGE.header} *"
        )
        type = InputType.number
        annotation = BillingCommonAnnotations.TOTAL_USAGE
        value = props.state.totalUsage
        onChange = {
            val target = it.target as HTMLInputElement
            props.setState(
                props.state.copy(
                    totalUsage = target.value.toFloatOrNull()
                )
            )
        }
        error = props.validation.totalUsageErrorState
        helperText = props.validation.totalUsageErrorMessage
        onBlur = {
            validateTotalUsage(props.state, props.validation, props.setValidation)
        }
    }
}