package com.ecosave.watch.portal.models.usermanagement

import kotlinx.serialization.Serializable

@Serializable
data class UserActionsData(
    var username: String = "",
    var email: String = "",
    var navigatedFromUserManagement: Boolean = false
)
