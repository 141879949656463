package com.ecosave.watch.portal.helpers.workorder

import com.ecosave.watch.portal.helpers.common.generateParamsFromOptions
import com.ecosave.watch.portal.models.workorder.ActionManagementFilterParamsDataClass
import com.ecosave.watch.portal.models.workorder.ActionManagementTableFilterDataClass
import com.ecosave.watch.portal.models.workorder.ObservationsFilterDataClass
import com.ecosave.watch.portal.models.workorder.ObservationsFilterParamsDataClass

fun getActionManagementFilterParams(filters: ActionManagementTableFilterDataClass): ActionManagementFilterParamsDataClass {
    var params = ""
    var counter = 0
    if (filters.status.isNotEmpty()) {
        params += "&status=${generateParamsFromOptions(filters.status)}"
        counter += 1
    }
    if (filters.userEmail.isNotEmpty()) {
        params += "&emails=${generateParamsFromOptions(filters.userEmail)}"
        counter += 1
    }
    if (filters.generatedDate.isNotEmpty()) {
        params += "&generatedDate=${filters.generatedDate}"
        counter += 1
    }
    if (filters.closedDate.isNotEmpty()) {
        params += "&closedDate=${filters.closedDate}"
        counter += 1
    }
    if (filters.severity.isNotEmpty()) {
        params += "&severities=${generateParamsFromOptions(filters.severity)}"
        counter += 1
    }
    if (filters.utility.isNotEmpty()) {
        params += "&utilities=${generateParamsFromOptions(filters.utility)}"
        counter += 1
    }
    if (filters.event.isNotEmpty()) {
        params += "&eventIds=${generateParamsFromOptions(filters.event)}"
        counter += 1
    }
    if (filters.facility.isNotEmpty()) {
        params += "&facilityIds=${generateParamsFromOptions(filters.facility)}"
        counter += 1
    }
    if (filters.accountNumber.isNotEmpty()) {
        params += "&accountNumbers=${generateParamsFromOptions(filters.accountNumber)}"
        counter += 1
    }
    return ActionManagementFilterParamsDataClass(params, counter)
}

fun getObservationsFilterParams(filters: ObservationsFilterDataClass): ObservationsFilterParamsDataClass {
    var params = ""
    var counter = 0

    if (filters.generatedDate.isNotEmpty()) {
        params += "&generatedDate=${filters.generatedDate}"
        counter += 1
    }
    if (filters.severity.isNotEmpty()) {
        params += "&severities=${generateParamsFromOptions(filters.severity)}"
        counter += 1
    }
    if (filters.utility.isNotEmpty()) {
        params += "&utilities=${generateParamsFromOptions(filters.utility)}"
        counter += 1
    }
    if (filters.event.isNotEmpty()) {
        params += "&eventIds=${generateParamsFromOptions(filters.event)}"
        counter += 1
    }
    if (filters.facility.isNotEmpty()) {
        params += "&facilityIds=${generateParamsFromOptions(filters.facility)}"
        counter += 1
    }
    if (filters.accountNumber.isNotEmpty()) {
        params += "&accountNumbers=${generateParamsFromOptions(filters.accountNumber)}"
        counter += 1
    }
    return ObservationsFilterParamsDataClass(params, counter)
}

