package com.ecosave.watch.portal.helpers.billing

import com.ecosave.watch.portal.helpers.facilitymanagement.FacilityConstants

enum class BillingWaterHeaders(val header: String) {
    SERVICE_CHARGES("Service Charges"),
    STORMWATER_CHARGES("Stormwater Charges"),
    INDUSTRIAL_SURCHARGE("Industrial Surcharge"),
    FLOW("Flow"),
    SRF("SRF"),
    FLOW_USED_FOR_BILLING("Flow used for billing"),
    BIO_CHEMICAL_OXYGEN_DEMAND("Biochemical Oxygen Demand (BOD)"),
    BOD_SURCHARGE_FACTOR("BOD Surcharge Factor"),
    BOD_CHARGES("BOD Charges"),
    TOTAL_SUSPENDED_SOLIDS("Total Suspended Solids (TSS)"),
    TSS_SURCHARGE_FACTOR("TSS Surcharge Factor"),
    TSS_CHARGES("TSS Charges"),
    TOTAL_SURCHARGE_BILL("Total Surcharge Bill")
}

enum class BillingSteamHeaders(val header: String) {
    DEMAND_MEASURED("Demand Measured *"),
    DEMAND_BILLED("Demand Billed *"),
    TOTAL_HEATING_STEAM_CHARGES("Total Heating Steam Charges"),
    HEATING_DEMAND_CHARGES("Heating Demand Charges"),
    COOLING_STEAM_USAGE("Cooling Steam Usage *"),
    HEATING_STEAM_USAGE("Heating Steam Usage *"),
    HEATING_USAGE_CHARGES("Heating Usage Charges"),
    TOTAL_COOLING_STEAM_CHARGES("Total Cooling Steam Charges")
}

enum class BillingSolarHeaders(val header: String) {
    PPA_IN_PLACE("Is there a PPA in place? *"),
    PPA_SERVICE_PROVIDER("PPA Service Provider *"),
    TOTAL_INSTALLED_SOLAR_CAPACITY("Total Installed Solar Capacity"),
    TOTAL_ANNUAL_GENERATION("Total Annual Generation *"),
    SERVICE_START_DATE("Service Start Date"),
    TOTAL_LENGTH_OF_PPA("Total Length of PPA *"),
    SOLAR_NET_METERED("Is Solar Net Metered? *"),
    TOTAL_SOLAR_GENERATED("Total Solar Generated *"),
    TOTAL_SOLAR_COST("Total Solar Cost"),
    SOLAR_EXPORTED("Solar Exported *"),
    SOLAR_EXPORTED_CREDIT("Solar Exported Credit"),
    START_DATE("Start Date *"),
    END_DATE("End Date *")
}

class UtilityUnits {
    companion object {

        val electricDemandUnits = listOf(
            DemandUnit.KW,
            DemandUnit.MW,
            DemandUnit.KVA
        )

        val electricUsageUnits = listOf(
            UsageUnit.KWH,
            UsageUnit.KBTU,
            UsageUnit.MMBTU,
            UsageUnit.MWH,
            UsageUnit.GJ,
        )

        val naturalGasUsageUnits = listOf(
            UsageUnit.THERMS,
            UsageUnit.KBTU,
            UsageUnit.MMBTU,
            UsageUnit.CCF,
            UsageUnit.MCF,
            UsageUnit.DTH,
            UsageUnit.GJ,
            UsageUnit.CUBIC_METERS
        )

        val waterUsageUnits = listOf(
            UsageUnit.CCF,
            UsageUnit.GALLONS,
            UsageUnit.KL
        )

        val hotWaterUsageUnits = listOf(
            UsageUnit.KBTU,
            UsageUnit.MMBTU,
            UsageUnit.THERMS,
            UsageUnit.GJ,
        )

        val chilledWaterUsageUnits = listOf(
            UsageUnit.KBTU,
            UsageUnit.MMBTU,
            UsageUnit.TH,
            UsageUnit.GJ,
        )

        val solarUsageUnits = listOf(
            UsageUnit.KWH,
            UsageUnit.KBTU,
            UsageUnit.MMBTU,
            UsageUnit.MWH,
            UsageUnit.GJ
        )

        val steamUsageUnits = listOf(
            UsageUnit.MLBS,
            UsageUnit.KLBS,
            UsageUnit.KBTU,
            UsageUnit.MMBTU,
            UsageUnit.LBS,
            UsageUnit.THERMS,
            UsageUnit.GJ,
            UsageUnit.KG
        )

        val steamDemandUnits = listOf(DemandUnit.LBSH)
    }
}


enum class UtilityType(val type: String, val demandUnits: List<DemandUnit>?, val usageUnits: List<UsageUnit>) {

    ELECTRIC("Electric", UtilityUnits.electricDemandUnits, UtilityUnits.electricUsageUnits),
    NATURAL_GAS("Natural Gas", null, UtilityUnits.naturalGasUsageUnits),
    WATER("Water And Sewer", null, UtilityUnits.waterUsageUnits),
    STEAM("Steam", UtilityUnits.steamDemandUnits, UtilityUnits.steamUsageUnits),
    HOT_WATER("Hot Water", null, UtilityUnits.hotWaterUsageUnits),
    CHILLED_WATER("Chilled Water", null, UtilityUnits.chilledWaterUsageUnits),
    SOLAR("Solar", null, UtilityUnits.solarUsageUnits)

}

enum class DemandUnit(val description: String) {
    KW("kW"),
    MW("MW"),
    KVA("kVA"),
    LBSH("lbs/h")
}

enum class UtilityOtherUnits(val description: String) {
    MG_L("mg/L")
}

enum class UsageUnit(val description: String) {
    KWH("kWh"),
    MWH("MWh"),
    MLBS("Mlbs"),
    KLBS("kLbs"),
    LBS("lbs"),
    THERMS("therms"),
    MCF("Mcf"),
    CCF("Ccf"),
    DTH("Dth"),
    GALLONS("Gallons"),
    KBTU("kBtu"),
    MMBTU("MBtu/MMBtu"),
    GJ("GJ"),
    CUBIC_METERS("cubic meters"),
    KG("kg"),
    KL("kL"),
    TH("Ton Hours")
}

enum class BillingHeaders(val header: String) {
    UTILITY_TYPE("Utility Type"),
    SERVICE_NAME("Service Name"),
    ACCOUNT_NUMBER("Account Number"),
    BILL_START_DATE("Bill Start Date"),
    BILL_END_DATE("Bill End Date"),
    BILL_ESTIMATED("Is This Bill Estimated? *"),
    BILL_ADJUSTED("Bill Adjusted? *"),
    TOTAL_USAGE("Total Usage"),
    TOTAL_BILL_COST("Total Bill Cost"),
    TOTAL_PEAK_BILLED_DEMAND("Total Peak Billed Demand *"),
    PEAK_DEMAND_CHARGES("Peak Demand Charges"),
    ON_PEAK_USAGE("On-Peak Usage"),
    ON_PEAK_USAGE_CHARGES("On-Peak Usage Charges"),
    OFF_PEAK_USAGE("Off-Peak Usage"),
    OFF_PEAK_USAGE_CHARGES("Off-Peak Usage Charges"),
    ON_PEAK_DEMAND("On-Peak Demand"),
    OFF_PEAK_DEMAND("Off-Peak Demand"),
    USAGE_UNIT("Usage Unit *"),
    DEMAND_UNIT("Demand Unit *"),
    TOTAL_DELIVERY_DISTRIBUTION("Total Delivery/Distribution"),
    TOTAL_COMMODITY_SUPPLY("Total Commodity/Supply"),
}

enum class UtilityAccountSummaryHeaders(val header: String) {
    FACILITY_NAME("Facility Name"),
    FACILITY_ADDRESS("Facility Address"),
    FACILITY_SIZE("Facility Size"),
    ENERGY_STAR_PORTFOLIO_MANAGER(FacilityConstants.ENERGY_STAR_PORTFOLIO_MANAGER)
}

enum class UtilityAccountTableHeaders(val header: String) {
    UTILITY_TYPE("Utility Type"),
    ACCOUNT_NUMBER("Account Number"),
    SERVICE_ADDRESS("Service Address"),
    ACCOUNT_STATUS("Account Status"),
    UTILITY_PROVIDER("Utility Service Provider"),
    COLLECTION_METHOD("Collection Method"),
    COLLECTION_STATUS("Collection Status"),
    LATEST_BILL_MONTH("Latest Bill Month")
}

enum class SteamType(val description: String) {
    COOLING("Cooling"),
    HEATING("Heating"),
    BOTH("Both")
}

enum class UtilityAccountFields(val field: String) {
    ACCOUNT_TYPE("Utility Type *"),
    SERVICE_NAME("Service Name/Alias Name *"),
    SERVICE_ADDRESS("Service Address *"),
    ACCOUNT_NUMBER("Account Number *"),
    METER_NUMBER("Meter Number(s)"),
    UTILITY_SERVICE_PROVIDER("Utility Service Provider *"),
    SEWER_SURCHARGE_BILL("Does your facility receive a separate industrial sewer surcharge bill? *"),
    SOLAR_CAPACITY_UNIT("Capacity Unit"),
    SOLAR_PPA_RATE("Current PPA Rate *"),
    COLLECTION_METHOD("Select your collection method *"),
    OTHER_UTILITY_SERVICE_PROVIDER("Specify Utility Service Provider *"),
    UTILITY_SERVICE_PROVIDER_WEBSITE_LINK("Website Link Of Utility Service Provider *"),
    ENERGYSTAR_METER_ID("${BillingConstants.ENERGY_STAR} Meter Id"),
    USER_ID("User Id *"),
    PASSWORD("Password *")
}

enum class UtilityDataCollectionMethod(val description: String) {
    MANUAL_UTILITY_DATA_ENTRY("Manual Utility Data Entry"),
    ECOSAVE_WATCH("Ecosave Watch Automatic Data Collection")
}