package com.ecosave.watch.portal.components.privacypolicy

import react.FC
import react.Props

val IllustrativeCalculations = FC<Props> {
    HeadingWrapper {
        heading = "15. Illustrative Calculations"
    }
    TypographyWrapper {
        +"All calculations presented by the Service are merely illustrative and you take the risk when using such calculations in your business operations."
    }
}
