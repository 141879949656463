package com.ecosave.watch.portal.components.onboarding

import com.ecosave.watch.portal.helpers.common.xs
import com.ecosave.watch.portal.models.onboarding.ProductsPaymentSummary
import kotlinx.browser.window
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Grid
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableContainer
import mui.material.TableHead
import mui.material.TableRow
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import web.cssom.JustifyContent

external interface ProductsTableProps : Props {
    var productsPaymentSummary: ProductsPaymentSummary?
}

val ProductsTable = FC<ProductsTableProps> { props ->

    val productsPaymentSummary = props.productsPaymentSummary

    Grid {
        container = true
        spacing = responsive(4)
        sx {
            justifyContent = JustifyContent.center
        }
        Grid {
            item = true
            xs = 12
            TableContainer {
                Table {
                    stickyHeader = true
                    TableHead {
                        TableRow {
                            hover = true
                            TableCell {
                                +"Product"
                            }
                            TableCell {
                                +"Price"
                            }
                        }
                    }
                    TableBody {
                        productsPaymentSummary?.let { productsPaymentSummary ->
                            for (products in productsPaymentSummary.ecosaveWatchProducts) {
                                TableRow {
                                    hover = true
                                    TableCell {
                                        +products.stripeProductName
                                    }
                                    TableCell {
                                        +"$${products.stripeProductCost}/month"
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        Grid {
            item = true
            Button {
                variant = ButtonVariant.contained
                onClick = {
                    productsPaymentSummary?.let { productsPaymentSummary ->
                        window.open(productsPaymentSummary.paymentLinkInfo.paymentLinkUrl, "_self")
                    }
                }
                +"Start 365 days trial"
            }
        }
    }

}