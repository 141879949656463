package com.ecosave.watch.portal.models.workorder

import com.ecosave.watch.portal.helpers.workorder.ActionStatus
import kotlinx.serialization.Serializable

@Serializable
data class AssignActionsResponseDataClass(
    val assignedUsername: String,
    val assignedUserEmailAddress: String,
    val actionAndStatus: List<AssignActionsStatusResponse>
)

@Serializable
data class AssignActionsStatusResponse(
    val actionId: Int,
    val status: ActionStatus
)


