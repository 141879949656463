package com.ecosave.watch.portal.components.workorder

import com.ecosave.watch.portal.models.workorder.Observation
import com.ecosave.watch.portal.useGlobalState
import mui.icons.material.InfoOutlined
import mui.icons.material.Visibility
import mui.material.Box
import mui.material.IconButton
import mui.material.TableCell
import mui.material.TableRow
import mui.material.Tooltip
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.dom.html.TdAlign
import react.router.useNavigate
import web.cssom.AlignItems
import web.cssom.Color
import web.cssom.Display
import web.cssom.px

external interface ObservationsTableRowComponentProps : Props {
    var observation: Observation
}

val ObservationsTableRowComponent = FC<ObservationsTableRowComponentProps> { props ->

    val observation = props.observation
    val globalState = useGlobalState()
    val navigate = useNavigate()

    TableRow {
        hover = true
        sx {
            height = 30.px
            maxHeight = 30.px
            borderBottom = 0.px
            color = Color("rgba(255,255,255,1.0)")
        }
        hover = true
        tabIndex = -1
        TableCell {
            ActionSeverityChip {
                label = observation.severity.description
                severity = observation.severity
            }
        }
        TableCell {
            +observation.facilityName
        }
        TableCell {
            +observation.utility
        }
        TableCell {
            +observation.accountNumber
        }
        TableCell {
            +observation.event
            Box {
                sx {
                    display = Display.flex
                    gap = 10.px
                    alignItems = AlignItems.center
                }
                Tooltip {
                    title = ReactNode(observation.eventDescription)
                    InfoOutlined {
                        sx {
                            fontSize = 20.px
                        }
                    }
                }
            }
        }
        TableCell {
            +observation.observation
        }
        TableCell {
            sx {
                fontSize = 12.px
            }
            align = TdAlign.center
            +observation.dateTime
        }

        TableCell {
            align = TdAlign.center
            +observation.actionGenerated.toString()
        }
        TableCell {
            align = TdAlign.center
            +observation.actionInProgress.toString()
        }
        TableCell {
            align = TdAlign.center
            +observation.actionCompleted.toString()
        }
        TableCell {
            IconButton {
                onClick = {
                    globalState.observationId = observation.observationId
                    navigate.invoke("/action-management")
                }
                Visibility()
            }
        }
    }
}