package com.ecosave.watch.portal.services.usermanagement

import com.ecosave.watch.portal.helpers.BackendServerURL
import com.ecosave.watch.portal.helpers.usermanagement.UserRole
import com.ecosave.watch.portal.models.auth.LoginApiResponse
import com.ecosave.watch.portal.models.usermanagement.RegisterCompanyOrUserState
import com.ecosave.watch.portal.models.usermanagement.UserRegistration
import com.ecosave.watch.portal.services.nonAuthClient
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*

suspend fun registerCompany(state: RegisterCompanyOrUserState): LoginApiResponse? {
    try {
        val response: HttpResponse = nonAuthClient.post("$BackendServerURL/userAccount/register-admin-user") {
            contentType(ContentType.Application.Json)
            setBody(
                UserRegistration(
                    firstName = state.firstName,
                    lastName = state.lastName,
                    jobTitle = state.jobTitle,
                    companyName = state.companyName,
                    emailAddress = state.emailAddress,
                    password = state.password,
                    userRole = UserRole.ADMIN,
                    agreeToTerms = state.agreeTerms
                )
            )
        }
        return when (response.status) {
            HttpStatusCode.Created -> {
                LoginApiResponse(
                    HttpStatusCode.Created,
                    response.body()
                )
            }

            HttpStatusCode.Conflict -> {
                LoginApiResponse(
                    HttpStatusCode.Conflict,
                    null
                )
            }

            else -> {
                null
            }
        }
    } catch (e: dynamic) {
        return null
    }
}
