package com.ecosave.watch.portal.models.onboarding

import com.ecosave.watch.portal.helpers.onboarding.MainSubscriptionPayment
import kotlinx.serialization.Serializable

@Serializable
data class EcosaveWatchProduct(
    val stripeProductName: String,
    val stripeProductDescription: String?,
    val stripeProductCost: Double
)

@Serializable
data class ProductsPaymentSummary(
    val ecosaveWatchProducts: List<EcosaveWatchProduct>,
    val totalAmount: Double,
    val paymentLinkInfo: PaymentLinkInfo
)

@Serializable
data class PaymentLinkInfo(
    val paymentLinkUrl: String
)

@Serializable
data class MainProductsApiResponse(
    var productsPaymentSummary: ProductsPaymentSummary?,
    var paymentStatus: MainSubscriptionPayment
)

