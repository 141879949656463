package com.ecosave.watch.portal.components.privacypolicy

import react.FC
import react.Props
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.ul

val MainContentScopeAndGeography = FC<Props> {
    TypographyWrapper {
        +"At Ecosave LLC (“Ecosave”), our core mission is to transform the way businesses owners and operators monitor, manage, and optimize their energy consumption to achieve immediate cost savings. We want to be clear about how we collect, use, protect, and share information, including Personal Information (provide by you or on behalf of your organization, collectively, “you, or your”) about you, and the rights and choices you have about the ways in which you can help us protect your privacy."
    }
    HeadingWrapper {
        heading = "This Privacy Statement explains:"
        subHeading = true
    }
    TypographyWrapper {
        ul {
            li {
                +"What information we collect and why we collect it."
            }
            li {
                +"How we use that information and when we disclose it."
            }
            li {
                +"Your rights regarding your information, including how to access and update your information."
            }
            li {
                +"How we maintain information and the steps we take to protect your information."
            }
        }
    }
    HeadingWrapper {
        heading = "Privacy Policy Scope"
        subHeading = true
    }
    TypographyWrapper {
        +"This Privacy Statement applies to the information that we obtain through your use of Ecosave products and services, including our website ("
        CustomLink {
            text = TermsOfServiceConstants.ecosaveWebsiteURL
            to = TermsOfServiceConstants.ecosaveWebsiteURL
        }
        +") and your interactions and communications through our website, our portal, virtual terminal, hosted sites, social media, mobile and web-based tools (collectively, our “Services”)."
    }
    TypographyWrapper {
        +"This Privacy Statement does not apply to Personal Information arising from Ecosave employment-related activities. Except to the extent that a third party provides services on our behalf (such as a SaaS vendor), this Privacy Statement also does not apply to the practices of third parties to which we may link or otherwise refer you, such as consultants, pen testing firms, audit firms, and other vendors."
    }
    HeadingWrapper {
        heading = "Geography"
        subHeading = true
    }
    TypographyWrapper {
        +"Ecosave is a United States (“U.S.”) based company that offers its Services to domestic and international business customers. As a result, Personal Information may be transferred to our U.S. offices to permit us to comply with our legal and contractual obligations, to provide information and services to prospective and current clients, and to perform related business activities. In addition, we may provide information to third-party service providers in the U.S. and in other countries to the extent necessary to support Ecosave’s business activities, and we may access Personal Information collected by our customers to support the Services that we provide to our customers. Thus, Personal Information may be transferred to and stored on servers located in the U.S. and in countries different from the country in which that information was initially collected. Similarly, the information we collect may be accessed by Ecosave and our third-party service providers and business partners from countries other than the ones in which the information is stored."
    }
    TypographyWrapper {
        +"We understand that we have users from different countries and regions with different privacy expectations, and we endeavor to meet those expectations even when the U.S. imposes lesser obligations. Stated differently, we work hard to adhere to applicable data privacy laws wherever we do business, working with our Data Protection Officer as part of a cross-functional team that oversees our privacy compliance efforts. Additionally, if our vendors or affiliates have access to Personal Information, they must sign agreements that require them to comply with our privacy policies and with applicable data privacy laws."
    }
}
