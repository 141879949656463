package com.ecosave.watch.portal.components.esg

import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.md
import com.ecosave.watch.portal.helpers.common.sm
import com.ecosave.watch.portal.helpers.common.xs
import com.ecosave.watch.portal.helpers.esg.EsgPages
import com.ecosave.watch.portal.helpers.esg.EsgSection
import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.models.esg.EsgReportState
import com.ecosave.watch.portal.pages.mainScope
import com.ecosave.watch.portal.services.esg.includeOrExcludeSections
import com.ecosave.watch.portal.useGlobalState
import kotlinx.coroutines.launch
import mui.icons.material.InfoOutlined
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Checkbox
import mui.material.FormControlLabel
import mui.material.Grid
import mui.material.IconButton
import mui.material.Size
import mui.material.Tooltip
import mui.system.Box
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.StateSetter
import react.create
import react.dom.html.ReactHTML.div
import react.useEffectOnce
import react.useState
import web.cssom.Display
import web.cssom.JustifyContent
import web.cssom.px

external interface SelectEsgSectionsComponentProps : Props {
    var reportState: EsgReportState
    var setReportState: StateSetter<EsgReportState>
    var setVisiblePage: StateSetter<EsgPages>
}

val SelectEsgSectionsComponent = FC<SelectEsgSectionsComponentProps> { props ->

    val reportState = props.reportState
    val setReportState = props.setReportState
    val globalState = useGlobalState()
    var sectionsSelectionState by useState(
        mutableMapOf<EsgSection, Boolean>().also {
            EsgSection.entries.forEach { enumValue ->
                it[enumValue] = reportState.esgSections.contains(enumValue)
            }
            it[EsgSection.GENERAL_DISCLOSURES] = true
            it[EsgSection.MATERIAL_TOPICS] = true
        }
    )
    var isSaving by useState(false)
    val (notificationState, setNotificationState) = useState(NotificationState())

    useEffectOnce {
        globalState.updatePageTitle("Include/Exclude Sections from - ${reportState.reportFileName}")
    }

    Box {
        FormControlLabel {
            label = ReactNode("Select All")
            control = Checkbox.create {
                checked = !sectionsSelectionState.values.contains(false)
                onChange = { _, checked ->

                    val updatedSectionsSelectionState =
                        sectionsSelectionState.mapValues {
                            checked || (it.key == EsgSection.GENERAL_DISCLOSURES) ||
                                    (it.key == EsgSection.MATERIAL_TOPICS)
                        }

                    sectionsSelectionState = updatedSectionsSelectionState.toMutableMap()
                }
            }
        }

        Grid {
            container = true
            columnSpacing = responsive(2)
            sectionsSelectionState.forEach { (section, check) ->
                Grid {
                    item = true
                    xs = 12
                    sm = 12
                    md = 4
                    FormControlLabel {
                        label = div.create {
                            +section.description
                            Tooltip {
                                IconButton {
                                    size = Size.small
                                    InfoOutlined()
                                }
                                title = ReactNode(section.info)
                            }
                        }
                        control = Checkbox.create {
                            checked = check
                            disabled = (section == EsgSection.GENERAL_DISCLOSURES) || (section == EsgSection.MATERIAL_TOPICS)
                            onChange = { _, checked ->

                                val updatedSectionsSelectionState = sectionsSelectionState.toMutableMap()

                                updatedSectionsSelectionState.apply {
                                    put(section, checked)
                                }

                                sectionsSelectionState = updatedSectionsSelectionState
                            }
                        }
                    }
                }
            }
        }
        Box {
            sx {
                display = Display.flex
                marginTop = 20.px
                justifyContent = JustifyContent.center
            }
            Button {
                variant = ButtonVariant.contained
                disabled = isSaving
                onClick = {
                    mainScope.launch {
                        isSaving = true

                        val selectedSectionsList = sectionsSelectionState.filter { it.value }.keys.toMutableList()

                        val updatedReport = includeOrExcludeSections(reportState.reportFileName, selectedSectionsList)

                        if (updatedReport == null) {
                            setNotificationState(
                                notificationState.copy(
                                    status = NotificationStatus.ERROR,
                                    message = Constants.NOTIFICATION_ERROR_MESSAGE,
                                    visible = true
                                )
                            )
                        } else {
                            updatedReport.esgSections = selectedSectionsList
                            setReportState(updatedReport)
                            props.setVisiblePage(EsgPages.TABLE_OF_CONTENTS)
                        }
                        isSaving = false
                    }
                }
                if (isSaving) +"Saving..." else +"Save and Next"
            }
        }
    }
    AlertNotifications {
        open = notificationState.visible
        status = notificationState.status
        message = notificationState.message
        closeNotification = {
            setNotificationState(
                notificationState.copy(
                    visible = false
                )
            )
        }
    }
}