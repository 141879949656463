package com.ecosave.watch.portal.pages

import com.ecosave.watch.portal.components.energymanagement.EnergyManagementHeader
import com.ecosave.watch.portal.components.energymanagement.RangeController
import com.ecosave.watch.portal.components.energymanagement.UtilityCompareSection
import com.ecosave.watch.portal.components.energymanagement.UtilityTotalSection
import com.ecosave.watch.portal.helpers.billing.UtilityType
import com.ecosave.watch.portal.helpers.common.AutoCompleteOption
import com.ecosave.watch.portal.helpers.common.PageTitles
import com.ecosave.watch.portal.helpers.energymanagement.RadioLabels
import com.ecosave.watch.portal.helpers.energymanagement.getUtilityBaseline
import com.ecosave.watch.portal.helpers.energymanagement.getUtilityTarget
import com.ecosave.watch.portal.helpers.energymanagement.getYLabel
import com.ecosave.watch.portal.models.energymanagement.ActionCount
import com.ecosave.watch.portal.models.energymanagement.BaselineSelectionStatus
import com.ecosave.watch.portal.models.energymanagement.BaselineSummary
import com.ecosave.watch.portal.models.energymanagement.EnergyUsageCostNCO2PerFacility
import com.ecosave.watch.portal.models.energymanagement.EnergyUsageNCO2Details
import com.ecosave.watch.portal.models.energymanagement.FacilityDetails
import com.ecosave.watch.portal.models.energymanagement.FacilityTargetSummary
import com.ecosave.watch.portal.models.energymanagement.TargetSummary
import com.ecosave.watch.portal.services.billing.getFacilities
import com.ecosave.watch.portal.services.energymanagement.fetchBaselineData
import com.ecosave.watch.portal.services.energymanagement.fetchSelectedBaselineData
import com.ecosave.watch.portal.services.energymanagement.fetchSelectedTargetData
import com.ecosave.watch.portal.services.energymanagement.getEnergyUsageCostNCO2PerFacility
import com.ecosave.watch.portal.services.energymanagement.getFacilityTargetSummary
import com.ecosave.watch.portal.useGlobalState
import kotlinx.coroutines.launch
import mui.material.Box
import react.FC
import react.Props
import react.useEffectOnce
import react.useState
import com.ecosave.watch.portal.styles.EnergyManagementStyles as styles

val FacilityOverviewPage = FC<Props> {
    var activeOption by useState<AutoCompleteOption>()
    var facilityOptions by useState<Array<AutoCompleteOption>>(emptyArray())
    var isFetched by useState(false)
    var isFetchedError by useState(false)
    var (active, setActive) = useState(RadioLabels.USAGE)
    var fetchedData by useState(EnergyUsageCostNCO2PerFacility())
    var baselineData by useState("")
    var selectedBaselineData by useState(BaselineSummary("", emptyList()))
    var selectedTarget by useState(TargetSummary("", emptyList()))
    var facilityTargetSummary by useState<FacilityTargetSummary>()
    var compareWithBaseline by useState(false)
    var timeRange by useState("")
    val globalState = useGlobalState()

    useEffectOnce {
        globalState.updatePageTitle(PageTitles.FACILITY_OVERVIEW.title)
        mainScope.launch {
            isFetched = false
            isFetchedError = false
            var facilities = getFacilities()
            if (facilities.isNotEmpty()) {
                var params = "facilityId=${facilities[0].facilityId}"
                val response = getEnergyUsageCostNCO2PerFacility(params)
                fetchedData = response.data
                isFetchedError = response.isError
                baselineData = fetchBaselineData(params)
                selectedBaselineData = fetchSelectedBaselineData(params)
                selectedTarget = fetchSelectedTargetData(params)
                facilityTargetSummary = getFacilityTargetSummary(params)
                facilityOptions = facilities.map { item -> AutoCompleteOption(item.facilityName, "${item.facilityId}") }.toTypedArray()
                activeOption = AutoCompleteOption(facilities[0].facilityName, "${facilities[0].facilityId}")
            }
            isFetched = true
        }
    }

    val facilityDetail = fetchedData.facilities.firstOrNull() ?: FacilityDetails()

    val startDate1 = fetchedData.searchCriteriaOne?.startDate ?: ""
    val endDate1 = fetchedData.searchCriteriaOne?.endDate ?: ""
    val startDate2 = fetchedData.searchCriteriaTwo?.startDate ?: ""
    val endDate2 = fetchedData.searchCriteriaTwo?.endDate ?: ""
    val actionStatusCountOne = fetchedData.searchCriteriaOne?.actionsStatusCount ?: ActionCount()
    val actionStatusCountTwo = fetchedData.searchCriteriaTwo?.actionsStatusCount ?: ActionCount()
    val energyTotalOne = fetchedData.searchCriteriaOne?.energyTotal ?: emptyList()
    val energyTotalTwo = fetchedData.searchCriteriaTwo?.energyTotal ?: emptyList()
    val utilityBrakeDownsOne = fetchedData.searchCriteriaOne?.utilityBrakeDowns ?: emptyList()
    val utilityBrakeDownsTwo = fetchedData.searchCriteriaTwo?.utilityBrakeDowns ?: emptyList()
    val selectionBaselineStatus = fetchedData.baselineStatus

    val transform: (EnergyUsageNCO2Details) -> UtilityType = { it.utilityType }

    var availableUtilities = utilityBrakeDownsOne.map(transform)

    Box {
        className = styles.CONTAINER.cssClass
        EnergyManagementHeader {
            facility = facilityDetail
            option = activeOption
            options = facilityOptions
            setActiveOption = {
                mainScope.launch {
                    activeOption = it
                    val params = "facilityId=${it?.value}" + timeRange

                    isFetched = false
                    isFetchedError = false
                    val response = getEnergyUsageCostNCO2PerFacility(params)
                    fetchedData = response.data
                    isFetched = true
                    isFetchedError = response.isError

                    baselineData = fetchBaselineData("facilityId=${it?.value}")
                    selectedTarget = fetchSelectedTargetData("facilityId=${it?.value}")
                    selectedBaselineData = fetchSelectedBaselineData("facilityId=${it?.value}")
                    facilityTargetSummary = getFacilityTargetSummary("facilityId=${it?.value}")
                }
            }
            activeRadio = active
            setActiveRadio = setActive
            data = baselineData
            baselineStatus = selectionBaselineStatus
            baselineProp = selectedBaselineData
            targetProp = selectedTarget
            targetSummary = facilityTargetSummary
            utilities = availableUtilities
            refetchBaseline = {
                mainScope.launch {
                    selectedBaselineData = fetchSelectedBaselineData("facilityId=${activeOption?.value}")
                    fetchedData = fetchedData.copy(
                        baselineStatus = BaselineSelectionStatus.SELECTED
                    )
                }
            }
            refetchTarget = {
                mainScope.launch {
                    selectedTarget = fetchSelectedTargetData("facilityId=${activeOption?.value}")
                    facilityTargetSummary = getFacilityTargetSummary("facilityId=${activeOption?.value}")
                }
            }
        }
        RangeController {
            baselineStatus = selectionBaselineStatus
            checked = compareWithBaseline
            onChecked = { value ->
                compareWithBaseline = value
            }
            reset = {
                mainScope.launch {
                    isFetched = false
                    isFetchedError = false
                    val response = getEnergyUsageCostNCO2PerFacility("facilityId=${activeOption?.value}")
                    fetchedData = response.data
                    isFetchedError = response.isError
                    isFetched = true
                }
            }
            compare = {
                mainScope.launch {
                    isFetched = false
                    isFetchedError = false
                    var params = "facilityId=${activeOption?.value}" + it
                    timeRange = it
                    val response = getEnergyUsageCostNCO2PerFacility(params)
                    fetchedData = response.data
                    isFetchedError = response.isError
                    isFetched = true
                }
            }
        }
        UtilityTotalSection {
            title = if (startDate1.isBlank()) {
                ""
            } else {
                "$startDate1 - $endDate1"
            }
            energyData = energyTotalOne
            dataFetched = isFetched
            dataError = isFetchedError
            radioLabel = active
            actionStatus = actionStatusCountOne
        }
        if (!compareWithBaseline && !energyTotalTwo.isNullOrEmpty()) {
            UtilityTotalSection {
                title = "$startDate2 - $endDate2"
                energyData = energyTotalTwo
                dataFetched = isFetched
                radioLabel = active
                actionStatus = actionStatusCountTwo
            }
        }
        for ((index, utilityBrakeDown) in utilityBrakeDownsOne.withIndex()) {
            UtilityCompareSection {
                dataFetched = isFetched
                dataError = isFetchedError
                utilityType = utilityBrakeDown.utilityType
                firstUtilityData = utilityBrakeDown.energyUsageNCO2
                secondUtilityData = utilityBrakeDownsTwo.getOrNull(index)?.energyUsageNCO2 ?: emptyList()
                activeRadio = active
                yLabel = getYLabel(active.label, utilityBrakeDown.utilityType.name)
                firstDuration = "$startDate1 - $endDate1"
                secondDuration = "$startDate2 - $endDate2"
                withBaseline = compareWithBaseline
                baselineSummaryList = getUtilityBaseline(selectedBaselineData.utilityBaselineList, utilityBrakeDown.utilityType)
                targetSummaryList = getUtilityTarget(selectedTarget.utilityTargetList, utilityBrakeDown.utilityType)
                firstPeriod = "$startDate1 - $endDate1"
                secondPeriod = "$startDate2 - $endDate2"
            }
        }
    }
}
