package com.ecosave.watch.portal.components.privacypolicy

import react.FC
import react.Props

val AgentsThirdPartyCommitment = FC<Props> {
    HeadingWrapper {
        heading = "9. Agents; Third-Party Content"
    }
    TypographyWrapper {
        +"Ecosave may make various application program interfaces (APIs), agents, libraries, and other materials available at the Website or through the Service at its discretion to support your access and use of the Service (collectively, \"Agents\"). You acknowledge and agree that:"
    }
    TypographyWrapper {
        +"The Agents may only be used on systems owned, leased, or primarily operated by you."
    }
    TypographyWrapper {
        +"The Agents are made available solely to support access and use of the Service, and Ecosave has no liability with respect to any other uses of the Agents."
    }
    TypographyWrapper {
        +"Certain of the Agents may include third-party content that is subject to open-source license terms that may expand or limit your rights to use such content."
    }
    TypographyWrapper {
        +"You agree to review any electronic documentation that accompanies the Agents or is identified in a link provided to you to determine which portions of the Agents are open source and are licensed under open-source license terms. To the extent any such license terms require that Ecosave provide you the rights to copy, modify, distribute, or otherwise use any open source software in the Agents that are inconsistent with the limited rights granted to you in this Agreement, then such rights in the applicable open source license terms will take precedence over the rights and restrictions granted in this Agreement, but solely with respect to such open source software. Further, you acknowledge and agree that all third-party content is governed by its respective terms and such terms are solely between you and the applicable licensor. You agree to comply with such third-party terms (including open-source license terms), as applicable, and Ecosave has no liability with respect to third-party content under this Agreement."
    }
}
