package com.ecosave.watch.portal.components.onboarding

import com.ecosave.watch.portal.helpers.onboarding.OnboardingComponents
import mui.material.Box
import mui.material.Step
import mui.material.StepLabel
import mui.material.Stepper
import mui.system.sx
import react.FC
import react.Props
import web.cssom.Display
import web.cssom.Transition

external interface OnboardingStepperProps : Props {
    var activeStep: Number
    var stepper: Array<OnboardingComponents>
}

val OnboardingStepper = FC<OnboardingStepperProps> { props ->

    Box {
        sx {
            display = Display.flex
            transition = Transition.ease
        }
        Stepper {
            activeStep = props.activeStep

            for (step in props.stepper) {
                Step {
                    key = step.name
                    completed = step.stepCompleted
                    StepLabel {
                        +step.description
                    }
                }
            }
        }
    }
}