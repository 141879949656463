package com.ecosave.watch.portal.components.esg.generaldisclosures.governance

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val Disclosure_2_15 = FC<EsgSubFormComponentProps> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.governance?.let { state ->

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_2_15
        }

        Box {
            label {
                +"Describe the processes for the highest governance body to ensure that conflicts of interest are prevented and mitigated."
            }
            ESGTextAreaFieldWrapper {
                name = state::section215A6.name
                value = state.section215A6 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"Report whether conflicts of interest are disclosed to stakeholders."
            }
            ESGTextAreaFieldWrapper {
                name = state::section215A7.name
                value = state.section215A7 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        DisclosureSubHeading {
            subHeading = "Report whether conflicts of interest are disclosed to stakeholders, including, at a minimum, conflicts of interest relating to:"
        }

        Box {
            label {
                +"Cross-board membership."
            }
            ESGTextAreaFieldWrapper {
                name = state::section215A1.name
                value = state.section215A1 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"Cross-shareholding with suppliers and other stakeholders."
            }
            ESGTextAreaFieldWrapper {
                name = state::section215A2.name
                value = state.section215A2 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"Existence of controlling shareholders."
            }
            ESGTextAreaFieldWrapper {
                name = state::section215A3.name
                value = state.section215A3 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"Related parties, their relationships, transactions, and outstanding balances."
            }
            ESGTextAreaFieldWrapper {
                name = state::section215A4.name
                value = state.section215A4 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}