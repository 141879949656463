package com.ecosave.watch.portal.components.energymanagement

import com.ecosave.watch.portal.components.common.ApiFailedMessage
import com.ecosave.watch.portal.components.common.ApiLazyRender
import com.ecosave.watch.portal.components.npm.Bar
import com.ecosave.watch.portal.components.npm.CartesianGrid
import com.ecosave.watch.portal.components.npm.ComposedChart
import com.ecosave.watch.portal.components.npm.Legend
import com.ecosave.watch.portal.components.npm.Line
import com.ecosave.watch.portal.components.npm.ResponsiveContainer
import com.ecosave.watch.portal.components.npm.XAxis
import com.ecosave.watch.portal.components.npm.YAxis
import com.ecosave.watch.portal.helpers.Colors
import com.ecosave.watch.portal.helpers.billing.UtilityType
import com.ecosave.watch.portal.helpers.common.formatTick
import com.ecosave.watch.portal.helpers.energymanagement.RadioLabels
import com.ecosave.watch.portal.helpers.energymanagement.getBaselineOrTarget
import com.ecosave.watch.portal.helpers.energymanagement.getColorForUtility
import com.ecosave.watch.portal.helpers.energymanagement.getMonthAndValue
import com.ecosave.watch.portal.helpers.energymanagement.getSecondColorForUtility
import com.ecosave.watch.portal.helpers.energymanagement.getUtiltyUnit
import com.ecosave.watch.portal.models.energymanagement.EnergyUsageNCO2
import com.ecosave.watch.portal.models.energymanagement.SummaryData
import com.ecosave.watch.portal.models.energymanagement.UtilityData
import js.core.jso
import js.core.push
import mui.material.Box
import mui.material.Paper
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML
import web.cssom.Display
import web.cssom.JustifyContent
import web.cssom.pct
import web.cssom.px
import com.ecosave.watch.portal.components.npm.Tooltip as TTip
import com.ecosave.watch.portal.styles.EnergyManagementStyles as styles

external interface UtilityCompareSectionProps : Props {
    var dataFetched: Boolean
    var utilityType: UtilityType
    var activeRadio: RadioLabels
    var yLabel: String
    var firstUtilityData: List<EnergyUsageNCO2>
    var secondUtilityData: List<EnergyUsageNCO2>
    var firstDuration: String
    var secondDuration: String
    var withBaseline: Boolean
    var baselineSummaryList: List<EnergyUsageNCO2>
    var targetSummaryList: List<EnergyUsageNCO2>
    var firstPeriod: String
    var secondPeriod: String?
    var dataError: Boolean
}

val UtilityCompareSection = FC<UtilityCompareSectionProps> { props ->
    val convertedData = arrayOf<UtilityData>()

    val firstUtilitySummary = SummaryData()
    val secondUtilitySummary = SummaryData()

    for ((index, firstUtility) in props.firstUtilityData.withIndex()) {
        val data = getMonthAndValue(firstUtility, props.activeRadio.value)
        firstUtilitySummary.sum += data.value
        if (data.value > firstUtilitySummary.highestAmount) {
            firstUtilitySummary.highestMonth = data.month
            firstUtilitySummary.highestAmount = data.value
        }
        if (index == props.firstUtilityData.lastIndex) {
            firstUtilitySummary.lastMonth = data.month
            firstUtilitySummary.lastAmount = data.value
        }

        if (props.withBaseline && props.baselineSummaryList.isNotEmpty()) {
            val baseline = getBaselineOrTarget(props.baselineSummaryList, firstUtility.billingMonth)
            if (props.targetSummaryList.isNotEmpty()) {
                val target = getBaselineOrTarget(props.targetSummaryList, firstUtility.billingMonth)
                convertedData.push(
                    jso {
                        utilityType = firstUtility.utilityType.type
                        billingMonthTwo = firstUtility.billingMonth
                        utilityUsageTwo = firstUtility.utilityUsage
                        utilityCostTwo = firstUtility.utilityCost
                        utilityCo2EmissionTwo = firstUtility.utilityCo2Emission
                        billingMonth = baseline?.billingMonth.toString()
                        utilityUsage = baseline?.utilityUsage!!
                        utilityCost = baseline?.utilityCost!!
                        utilityCo2Emission = baseline?.utilityCo2Emission!!
                        billingMonthThree = target?.billingMonth.toString()
                        utilityUsageThree = target?.utilityUsage!!
                        utilityCostThree = target?.utilityCost!!
                        utilityCo2EmissionThree = target?.utilityCo2Emission!!
                        dataKey = "${baseline.billingMonth}|${firstUtility?.billingMonth}"
                    }
                )
            } else {
                convertedData.push(
                    jso {
                        utilityType = firstUtility.utilityType.type
                        billingMonthTwo = firstUtility.billingMonth
                        utilityUsageTwo = firstUtility.utilityUsage
                        utilityCostTwo = firstUtility.utilityCost
                        utilityCo2EmissionTwo = firstUtility.utilityCo2Emission
                        billingMonth = baseline?.billingMonth.toString()
                        utilityUsage = baseline?.utilityUsage!!
                        utilityCost = baseline?.utilityCost!!
                        utilityCo2Emission = baseline?.utilityCo2Emission!!
                        dataKey = "${baseline.billingMonth}|${firstUtility?.billingMonth}"
                    }
                )
            }
        } else {
            if (props.secondUtilityData.isNullOrEmpty()) {
                convertedData.push(
                    jso {
                        utilityType = firstUtility.utilityType.type
                        billingMonth = firstUtility.billingMonth
                        utilityUsage = firstUtility.utilityUsage
                        utilityCost = firstUtility.utilityCost
                        utilityCo2Emission = firstUtility.utilityCo2Emission
                        dataKey = firstUtility.billingMonth
                    }
                )
            } else {
                val secondData = getMonthAndValue(props.secondUtilityData[index], props.activeRadio.value)
                secondUtilitySummary.sum += secondData.value
                if (secondData.value > secondUtilitySummary.highestAmount) {
                    secondUtilitySummary.highestMonth = secondData.month
                    secondUtilitySummary.highestAmount = secondData.value
                }
                if (index == props.secondUtilityData.lastIndex) {
                    secondUtilitySummary.lastMonth = secondData.month
                    secondUtilitySummary.lastAmount = secondData.value
                }
                convertedData.push(
                    jso {
                        utilityType = firstUtility.utilityType.type
                        billingMonth = firstUtility.billingMonth
                        utilityUsage = firstUtility.utilityUsage
                        utilityCost = firstUtility.utilityCost
                        utilityCo2Emission = firstUtility.utilityCo2Emission
                        billingMonthTwo = props.secondUtilityData[index]?.billingMonth
                        utilityUsageTwo = props.secondUtilityData[index]?.utilityUsage
                        utilityCostTwo = props.secondUtilityData[index]?.utilityCost
                        utilityCo2EmissionTwo = props.secondUtilityData[index]?.utilityCo2Emission
                        dataKey = "${firstUtility.billingMonth}|${props.secondUtilityData[index]?.billingMonth}"
                    }
                )
            }
        }
    }

    val secondPeriod = if (props.withBaseline) {
        "Baseline"
    } else {
        props.secondPeriod
    }

    Box {
        sx {
            marginTop = 30.px
        }
        Typography {
            variant = TypographyVariant.h5
            component = ReactHTML.h5
            +props.utilityType.type
        }
        Box {
            className = styles.SUMMARY_COMPARE_CONTAINER.cssClass
            Paper {
                className = if (props.secondUtilityData.isEmpty() || props.withBaseline) {
                    styles.CHART_SECTION_ONE.cssClass
                } else {
                    styles.CHART_SECTION_TWO.cssClass
                }
                Box {
                    className = styles.CHART_SUMMARY_SECTION_CONTENT.cssClass
                    if (props.dataError) {
                        ApiFailedMessage {}
                    } else {
                        ApiLazyRender {
                            dataFetched = props.dataFetched
                            ResponsiveContainer {
                                width = "100%"
                                height = "100%"
                                ComposedChart {
                                    width = 500
                                    height = 300
                                    data = JSON.parse(JSON.stringify(convertedData))
                                    CartesianGrid {
                                        stroke = Colors.WHITE_SMOKE
                                    }
                                    XAxis {
                                        dataKey = "dataKey"
                                        height = 50
                                        name = props.utilityType.name
                                        tick =
                                            if (props.secondUtilityData.isNotEmpty() || (props.withBaseline && props.baselineSummaryList.isNotEmpty())) {
                                                CustomXAxisContentComponent
                                            } else {
                                                jso {
                                                    fill = Colors.WHITE
                                                }
                                            }
                                    }
                                    YAxis {
                                        type = "number"
                                        tickCount = 10
                                        width = 90
                                        tickFormatter = ::formatTick
                                        tick = jso {
                                            fill = Colors.WHITE
                                        }
                                    }
                                    TTip {
                                        content = TooltipContent
                                        labelStyle = jso {
                                            color = Colors.BLACK
                                        }
                                    }
                                    Bar {
                                        dataKey = props.activeRadio.value
                                        fill = getColorForUtility(props.utilityType.name)
                                    }
                                    if (props.secondUtilityData.isNotEmpty() || (props.withBaseline && props.baselineSummaryList.isNotEmpty())) {
                                        Bar {
                                            dataKey = "${props.activeRadio.value}Two"
                                            fill = getSecondColorForUtility(props.utilityType.name)
                                        }
                                        if (props.withBaseline && props.targetSummaryList.isNotEmpty()) {
                                            Line {
                                                dataKey = "${props.activeRadio.value}Three"
                                                stroke = Colors.SCARLET_RED
                                                strokeWidth = 3
                                            }
                                        }
                                        Legend {
                                            iconSize = 20
                                            iconType = "wye"
                                            formatter = { name: String ->
                                                if (props.withBaseline) {
                                                    if (name.contains("Two")) {
                                                        props.firstPeriod
                                                    } else if (name.contains("Three")) {
                                                        "Target"
                                                    } else {
                                                        secondPeriod
                                                    }
                                                } else {
                                                    if (name.contains("Two")) {
                                                        secondPeriod
                                                    } else {
                                                        props.firstPeriod
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                Box {
                    sx {
                        width = 100.pct
                        display = Display.flex
                        justifyContent = JustifyContent.center
                    }
                    Typography {
                        variant = TypographyVariant.h6
                        component = ReactHTML.h6
                        +props.yLabel
                    }
                }
            }
            UtilitySummarySection {
                utilityType = props.utilityType
                first = firstUtilitySummary
                second = secondUtilitySummary
                hidden = props.secondUtilityData.isEmpty() || props.withBaseline
                firstDuration = props.firstDuration
                seconDuration = props.secondDuration
                firstColor = getColorForUtility(props.utilityType.name)
                secondColor = getSecondColorForUtility(props.utilityType.name)
                unit = getUtiltyUnit(props.activeRadio.value, props.utilityType.type)
                keyPoint = props.activeRadio.key
                dataError = props.dataError
            }
        }
    }
}