package com.ecosave.watch.portal.helpers.facilitymanagement

import com.ecosave.watch.portal.components.npm.lookup
import com.ecosave.watch.portal.models.facilitymanagement.Address
import com.ecosave.watch.portal.models.facilitymanagement.DailyOperatingSchedule
import com.ecosave.watch.portal.models.facilitymanagement.DailyOperation
import com.ecosave.watch.portal.models.facilitymanagement.Facility
import com.ecosave.watch.portal.models.facilitymanagement.FacilityOperatingScheduleState
import com.ecosave.watch.portal.models.facilitymanagement.FacilityState
import com.ecosave.watch.portal.models.facilitymanagement.ZipCodeInfo
import kotlinx.datetime.Clock
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.TimeZone
import kotlinx.datetime.internal.JSJoda.LocalTime
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.decodeFromDynamic


fun padSingleDigit(number: Int): String {
    return number.toString().padStart(2, '0')
}

fun initializeOperatingSchedule(): MutableList<DailyOperatingSchedule> {
    return mutableListOf(
        DailyOperatingSchedule(dayOfTheWeek = DayOfWeek.SUNDAY),
        DailyOperatingSchedule(dayOfTheWeek = DayOfWeek.MONDAY),
        DailyOperatingSchedule(dayOfTheWeek = DayOfWeek.TUESDAY),
        DailyOperatingSchedule(dayOfTheWeek = DayOfWeek.WEDNESDAY),
        DailyOperatingSchedule(dayOfTheWeek = DayOfWeek.THURSDAY),
        DailyOperatingSchedule(dayOfTheWeek = DayOfWeek.FRIDAY),
        DailyOperatingSchedule(dayOfTheWeek = DayOfWeek.SATURDAY)
    )
}

fun prepareFacilityRequestBody(
    facilityState: FacilityState,
    facilityOperatingScheduleState: FacilityOperatingScheduleState,
    facilityId: Int? = null
): Facility {
    return Facility(
        facilityId = facilityId,
        facilityName = facilityState.facilityName,
        buildingCategory = BuildingCategory.valueOf(facilityState.buildingCategory),
        buildingType = BuildingType.valueOf(facilityState.buildingType),
        otherBuildingType = facilityState.otherBuildingType.ifBlank { null },
        buildingTotalArea = facilityState.buildingTotalArea!!,
        buildingAddress = Address(
            street = facilityState.street,
            state = facilityState.state,
            city = facilityState.city,
            zipcode = facilityState.zipcode
        ),
        buildingYearBuilt = facilityState.buildingYearBuilt!!,
        facilityDescription = facilityState.facilityDescription,
        propertyId = facilityState.propertyId,
        buildingOccupancy = facilityState.buildingOccupancy!!,
        is24x7 = facilityOperatingScheduleState.isOperating24x7,
        energyStarSubscription = facilityState.energyStarSubscription,
        facilityDailyOperations = if (facilityOperatingScheduleState.isOperating24x7) null else facilityOperatingScheduleRequiredFormatForApi(
            facilityOperatingScheduleState.facilityOperatingSchedule
        ),
        cancellationEffectiveOn = null
    )
}

fun facilityOperatingScheduleRequiredFormatForApi(operatingSchedule: MutableList<DailyOperatingSchedule>): MutableList<DailyOperation> {
    val operatingScheduleRequiredFormat = mutableListOf<DailyOperation>()

    operatingSchedule.forEach { schedule ->
        operatingScheduleRequiredFormat.add(
            DailyOperation(
                dayOfTheWeek = schedule.dayOfTheWeek,
                startTime = LocalTime.of(
                    hour = schedule.startingHour.toInt(),
                    minute = schedule.startingMinute.toInt(),
                    second = 0,
                    nanoOfSecond = 0
                ).toString(),
                endTime = LocalTime.of(
                    hour = schedule.endingHour.toInt(),
                    minute = schedule.endingMinute.toInt(),
                    second = 0,
                    nanoOfSecond = 0
                ).toString()
            )
        )
    }

    return operatingScheduleRequiredFormat
}

fun facilityOperatingScheduleRequiredFormatForEditForm(operatingSchedule: MutableList<DailyOperation>?): MutableList<DailyOperatingSchedule> {

    val operatingScheduleRequiredFormat = mutableListOf<DailyOperatingSchedule>()

    operatingSchedule?.let {

        operatingSchedule.forEach { schedule ->
            operatingScheduleRequiredFormat.add(
                DailyOperatingSchedule(
                    dayOfTheWeek = schedule.dayOfTheWeek,
                    startingHour = padSingleDigit(LocalTime.parse(schedule.startTime).hour()),
                    startingMinute = padSingleDigit(LocalTime.parse(schedule.startTime).minute()),
                    endingHour = padSingleDigit(LocalTime.parse(schedule.endTime).hour()),
                    endingMinute = padSingleDigit(LocalTime.parse(schedule.endTime).minute())
                )
            )
        }
    }
    return operatingScheduleRequiredFormat
}

fun isBuildingTypeOther(buildingType: String): Boolean {
    return buildingType.startsWith("OTHER")
}

fun convertToDouble(hours: Int?, minutes: Int?): Double? {
    return if (hours != null && minutes != null) {
        -("$hours.$minutes".toDouble())
    } else {
        null
    }
}

fun convertToHoursMinutes(doubleValue: Double): Pair<Int, Int> {

    val list = doubleValue.toString().split('.')
    val hours = list.first().toInt()
    var minutes = 0

    if (list.size > 1) {
        minutes = list[1].toInt()
        if (minutes > 59) {
            minutes = 59
        }
    }
    return Pair(hours, minutes)
}

@OptIn(ExperimentalSerializationApi::class)
fun fetchZipCodeInfo(inputZip: String): ZipCodeInfo {
    val zipCodeEntered = lookup(inputZip)
    return Json.decodeFromDynamic(zipCodeEntered)
}

fun isEnergyStarSubscriptionCancelled(cancellationEffectiveOn: LocalDateTime, energyStarSubscriptionFromDB: Boolean?): Boolean {
    return energyStarSubscriptionFromDB == false && Clock.System.now().toLocalDateTime(TimeZone.UTC) > cancellationEffectiveOn
}

fun isEnergyManagementPortalTrialActive(energyManagementPortalTrialEndDate: LocalDateTime?): Boolean {
    return energyManagementPortalTrialEndDate != null && energyManagementPortalTrialEndDate > Clock.System.now().toLocalDateTime(TimeZone.UTC)
}