package com.ecosave.watch.portal.components.common

import mui.material.Box
import mui.material.Button
import mui.material.DialogActions
import mui.material.DialogContent
import mui.material.Size
import mui.material.TextField
import mui.system.sx
import react.FC
import react.Props
import web.cssom.JustifyContent
import web.cssom.px
import web.html.InputType

external interface NotificationDialogProps : Props {
    var open: Boolean
    var title: String
    var message: String?
    var actionResult: (Boolean) -> Unit
    var formattedMessage: Boolean
}

val MessageDialogComponent = FC<NotificationDialogProps> { props ->
    DialogWrapper {
        dialogMaxWidth = 600
        dialogTitle = props.title
        open = props.open
        DialogContent {
            if (props.formattedMessage) {
                Box {
                    sx {
                        margin = 20.px
                    }
                    TextField {
                        sx {
                            marginRight = 40.px
                        }
                        disabled = true
                        fullWidth = true
                        size = Size.medium
                        type = InputType.text
                        multiline = true
                        minRows = 5
                        maxRows = 20
                        value = props.message
                    }
                }
            } else {
                +props.message
            }
        }
        DialogActions {
            sx {
                justifyContent = JustifyContent.center
            }
            Button {
                +"Close"
                onClick = {
                    props.actionResult(false)
                }
            }
        }
    }
}

