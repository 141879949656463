package com.ecosave.watch.portal.components.privacypolicy

import react.FC
import react.Props
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.ul

val NonUseAndNonDisclosureOfConfidentialInfo = FC<Props> {
    HeadingWrapper {
        heading = "10. Nonuse and Nondisclosure of Confidential Information"
    }
    HeadingWrapper {
        heading = "10.1. Confidentiality"
        subHeading = true
    }
    TypographyWrapper {
        +"As used in this Agreement, \"Confidential Information\" means any information that is proprietary or confidential to the Discloser (as defined below) or that the Discloser is obligated to keep confidential (e.g., pursuant to a contractual or other obligation owing to a third party). Confidential Information may be of a technical, business, or other nature. However, Confidential Information does not include any information that:"
    }
    TypographyWrapper {
        ul {
            li {
                +"Was known to the Recipient (as defined below) prior to receiving the same from the Discloser in connection with this Agreement."
            }
            li {
                +"Is independently developed by the Recipient."
            }
            li {
                +"Is acquired by the Recipient from another source without restriction as to use or disclosure."
            }
            li {
                +"Is or becomes part of the public domain through no fault or action of the Recipient."
            }
        }
    }
    TypographyWrapper {
        +"Each party reserves any and all right, title, and interest (including any intellectual property rights) that it may have in or to any Confidential Information that it may disclose to the other party under this Agreement. The party that receives any Confidential Information (the \"Recipient\") of the other party (the \"Discloser\") will protect Confidential Information of the Discloser against any Unauthorized Use or disclosure to the same extent that the Recipient protects its own Confidential Information of a similar nature against Unauthorized Use or disclosure, but in no event will use less than a reasonable standard of care to protect such Confidential Information; provided that the Confidential Information of the Discloser is conspicuously marked or otherwise identified as confidential or proprietary upon receipt by the Recipient or the Recipient otherwise knows or has reason to know that the same is Confidential Information of the Discloser. The Recipient will use any Confidential Information of the Discloser solely for the purposes for which the Discloser provides it."
    }
    TypographyWrapper {
        +"This section will not be interpreted or construed to prohibit:"
    }
    TypographyWrapper {
        ul {
            li {
                +"Any use or disclosure which is necessary or appropriate in connection with the Recipient's performance of its obligations or exercise of its rights under this Agreement or any other agreement between the parties."
            }
            li {
                +"Any use or disclosure required by applicable law, provided that the Recipient uses reasonable efforts to give the Discloser reasonable advance notice to afford the Discloser an opportunity to intervene and seek an order or other appropriate relief for the protection of its Confidential Information"
            }
            li {
                +"Any use or disclosure made with the consent of the Discloser."
            }
        }
    }
    TypographyWrapper {
        +"In the event of any breach or threatened breach by the Recipient of its obligations under this section, the Discloser will be entitled to injunctive and other equitable relief to enforce such obligations. The obligations of confidentiality will survive expiration or termination of this Agreement."
    }
    HeadingWrapper {
        heading = "10.2. Pre-Release Program"
        subHeading = true
    }
    TypographyWrapper {
        +"As set forth in the Non-Disclosure Agreement, you agree that you will not disclose, publish, or otherwise disseminate any Confidential Information to anyone other than individuals who are employees of Ecosave, members of the pre-release program, or as otherwise expressly permitted or agreed to in writing by Ecosave. You further agree to take reasonable precautions to prevent any unauthorized use, disclosure, publication, or dissemination of Confidential Information, including preventing access to or display of the Service to third parties. You agree to use the Confidential Information solely for the permitted uses as set forth in this Agreement. You agree not to use Confidential Information otherwise for your own or any third party's benefit without the prior written approval of an authorized representative of Ecosave in each instance."
    }
}
