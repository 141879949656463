package com.ecosave.watch.portal.components.resourcecenter.workorders

import com.ecosave.watch.portal.components.resourcecenter.ResourcesSection
import com.ecosave.watch.portal.helpers.resourcecenter.WorkOrdersImages
import com.ecosave.watch.portal.styles.ResourceCenterStyles
import mui.system.Box
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML
import web.cssom.px

external interface WorkOrdersComponentProps : Props {
    var listPadding: Number
}

val WorkOrdersComponent = FC<WorkOrdersComponentProps> {
    mui.material.List {
        sx {
            paddingLeft = it.listPadding.px
        }

        Box {
            id = "observation-summary-overview"
            className = ResourceCenterStyles.RESOURCES_SUBSECTION.cssClass
            ResourcesSection {
                title = "Observation Summary Overview"
            }
            for (sent in observationSummarySentences) {
                ResourcesSection {
                    sentence = sent
                }
            }
        }
        ReactHTML.img {
            src = WorkOrdersImages.ObservationSummaryOverviewImage
            className = ResourceCenterStyles.RESOURCES_DISPLAY_OVERVIEW.cssClass
        }
        Box {
            id = "observation-summary-filter"
            className = ResourceCenterStyles.RESOURCES_SUBSECTION.cssClass
            ResourcesSection {
                title = "Observation Summary Filter"
            }
        }
        ReactHTML.img {
            src = WorkOrdersImages.ObservationSummaryFilterImage
            className = ResourceCenterStyles.RESOURCES_DISPLAY_OVERVIEW_SMALLER.cssClass
        }
        Box {
            id = "action-management-overview"
            className = ResourceCenterStyles.RESOURCES_SUBSECTION.cssClass
            ResourcesSection {
                title = "Action Management Overview"
            }
            for (sent in actionManagementOverviewSentences) {
                ResourcesSection {
                    sentence = sent
                }
            }
        }
        ReactHTML.img {
            src = WorkOrdersImages.ActionManagementOverviewImage
            className = ResourceCenterStyles.RESOURCES_DISPLAY_OVERVIEW.cssClass
        }
        Box {
            id = "action-management-assignment"
            className = ResourceCenterStyles.RESOURCES_SUBSECTION.cssClass
            ResourcesSection {
                title = "Action Management Assignment"
            }
        }
        ReactHTML.img {
            src = WorkOrdersImages.ActionManagementAssignmentImage
            className = ResourceCenterStyles.RESOURCES_DISPLAY_OVERVIEW.cssClass
        }
        Box {
            id = "action-management-filter"
            className = ResourceCenterStyles.RESOURCES_SUBSECTION.cssClass
            ResourcesSection {
                title = "Action Management Filter"
            }
        }
        ReactHTML.img {
            src = WorkOrdersImages.ActionManagementFilterImage
            className = ResourceCenterStyles.RESOURCES_DISPLAY_OVERVIEW_SMALLER.cssClass
        }
    }
}