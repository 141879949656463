package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class EnergyState(
    var renewableFuelTypeList: MutableList<RenewableFuelType> = mutableListOf(),
    var nonRenewableFuelTypeList: MutableList<NonRenewableFuelType> = mutableListOf(),
    var breakdownOfEnergyConsumptionList: MutableList<EnergyConsumptionBreakdown> = mutableListOf(),
    var breakdownOfEnergyIntensityList: MutableList<EnergyIntensityBreakdown> = mutableListOf(),

    var section3021ConsumedUnit1: String? = null,
    var section3021ConsumedUnit2: String? = null,
    var section3021ConsumedUnit3: String? = null,
    var section3021ConsumedUnit4: String? = null,

    var section3021ConsumedCY1: Double? = null,
    var section3021ConsumedCY2: Double? = null,
    var section3021ConsumedCY3: Double? = null,
    var section3021ConsumedCY4: Double? = null,

    var section3021SoldUnit1: String? = null,
    var section3021SoldUnit2: String? = null,
    var section3021SoldUnit3: String? = null,
    var section3021SoldUnit4: String? = null,

    var section3021SoldCY1: Double? = null,
    var section3021SoldCY2: Double? = null,
    var section3021SoldCY3: Double? = null,
    var section3021SoldCY4: Double? = null,

    var section3021Field1: String? = null,
    var section3021Field2: String? = null,
    var section3021Field3: Double? = null,

    var section3022UpstreamUnit2: String? = null,
    var section3022UpstreamUnit3: String? = null,
    var section3022UpstreamUnit4: String? = null,
    var section3022UpstreamUnit5: String? = null,
    var section3022UpstreamUnit6: String? = null,
    var section3022UpstreamUnit7: String? = null,
    var section3022UpstreamUnit8: String? = null,
    var section3022UpstreamUnit9: String? = null,
    var section3022UpstreamUnit10: String? = null,
    var section3022DownstreamUnit11: String? = null,
    var section3022DownstreamUnit12: String? = null,
    var section3022DownstreamUnit13: String? = null,
    var section3022DownstreamUnit14: String? = null,
    var section3022DownstreamUnit15: String? = null,
    var section3022DownstreamUnit16: String? = null,
    var section3022DownstreamUnit17: String? = null,
    var section3022DownstreamUnit18: String? = null,
    var section3022DownstreamUnit19: String? = null,

    var section3022UpstreamCurrent2: Double? = null,
    var section3022UpstreamCurrent3: Double? = null,
    var section3022UpstreamCurrent4: Double? = null,
    var section3022UpstreamCurrent5: Double? = null,
    var section3022UpstreamCurrent6: Double? = null,
    var section3022UpstreamCurrent7: Double? = null,
    var section3022UpstreamCurrent8: Double? = null,
    var section3022UpstreamCurrent9: Double? = null,
    var section3022UpstreamCurrent10: Double? = null,
    var section3022DownstreamCurrent11: Double? = null,
    var section3022DownstreamCurrent12: Double? = null,
    var section3022DownstreamCurrent13: Double? = null,
    var section3022DownstreamCurrent14: Double? = null,
    var section3022DownstreamCurrent15: Double? = null,
    var section3022DownstreamCurrent16: Double? = null,
    var section3022DownstreamCurrent17: Double? = null,
    var section3022DownstreamCurrent18: Double? = null,
    var section3022DownstreamCurrent19: Double? = null,

    var section3022Field1: String? = null,
    var section3022Field2: String? = null,
    var section3022Field3: Double? = null,

    var section3023IntensityUnit1: String? = null,
    var section3023IntensityUnit2: String? = null,
    var section3023IntensityUnit3: String? = null,

    var section3023IntensityCurrent1: Double? = null,
    var section3023IntensityCurrent2: Double? = null,
    var section3023IntensityCurrent3: Double? = null,

    var section3023Field1: String? = null,
    var section3023Field2: String? = null,
    var section3023Field3: String? = null,

    var section3024Field1: Double? = null,
    var section3024Field2: String? = null,
    var section3024Field3: String? = null,
    var section3024Field4: String? = null,
    var section3024Field5: String? = null,

    var section3025Field1: String? = null,
    var section3025Field2: String? = null,

    var energyReductionERList: MutableList<EnergyReductionER> = mutableListOf(),

    var section3_3_302A1: String? = null,
    var section3_3_302A2: String? = null,
    var section3_3_302A3: String? = null,
    var section3_3_302A4: String? = null,
    var section3_3_302A5: String? = null,
    var section3_3_302A6: String? = null
)

@Serializable
data class RenewableFuelType(
    var section3021RenewableFuelType: String? = null,
    var section3021RenewableUnit: String? = null,
    var section3021RenewableCurrent: Double? = null
) : DynamicTableItemKey()

@Serializable
data class NonRenewableFuelType(
    var section3021NonRenewableFuelType: String? = null,
    var section3021NonRenewableUnit: String? = null,
    var section3021NonRenewableCurrent: Double? = null
) : DynamicTableItemKey()

@Serializable
data class EnergyConsumptionBreakdown(
    var section3021GroupOrBusinessUnit: String? = null,
    var section3021FuelType: String? = null,
    var section3021FuelSource: String? = null,
    var section3021Country: String? = null,
    var section3021Activity: String? = null,
    var section3021ElectricityTotal: Double? = null,
    var section3021SteamTotal: Double? = null,
    var section3021CoolingTotal: Double? = null,
    var section3021HeatingTotal: Double? = null
) : DynamicTableItemKey()

@Serializable
data class EnergyIntensityBreakdown(
    var section3023GroupOrBusinessUnit: String? = null,
    var section3023FuelType: String? = null,
    var section3023FuelSource: String? = null,
    var section3023Country: String? = null,
    var section3023Activity: String? = null,
    var section3023EnergyConsumption: Double? = null,
    var section3023OrgSpecificMetric: Double? = null,
    var section3023EnergyIntensity: Double? = null,
    var section3023EnergyIntensityUnit: String?= null
) : DynamicTableItemKey()

@Serializable
data class EnergyReductionER(
    var section3025productNService: String? = null,
    var section3025ReductionERUnit: String? = null,
    var section3025ReductionERCurrent: Double? = null
) : DynamicTableItemKey()
