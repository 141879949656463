package com.ecosave.watch.portal.components.utilityaccounts

import com.ecosave.watch.portal.helpers.billing.BillingConstants
import com.ecosave.watch.portal.models.billing.utilitybillstate.UtilityBillState
import com.ecosave.watch.portal.models.billing.UtilityBillValidationState
import com.ecosave.watch.portal.helpers.billing.clearBillStartAndEndDate
import com.ecosave.watch.portal.components.common.ConfirmationDialog
import react.FC
import react.Props
import react.StateSetter
import react.useState

external interface BillingCommonFormFieldsProps : Props {
    var commonFormState: UtilityBillState
    var setCommonFormState: StateSetter<UtilityBillState>
    var commonFormValidationState: UtilityBillValidationState
    var setCommonFormValidationState: StateSetter<UtilityBillValidationState>
    var hideFieldsNotNeededInHotOrChilledWaterForm: Boolean
    var hideFieldsNotNeededInSolarForm: Boolean
}

val BillingCommonFormFields = FC<BillingCommonFormFieldsProps> { props ->

    val formState = props.commonFormState
    val setFormState = props.setCommonFormState
    val formValidationState = props.commonFormValidationState
    val setFormValidationState = props.setCommonFormValidationState
    val (openDialog, setOpenDialog) = useState(false)

    BillStartDate {
        state = formState
        setState = setFormState
        validation = formValidationState
        setValidation = setFormValidationState
        isSolarStartDate = false
        setDialogState = setOpenDialog
    }

    BillEndDate {
        state = formState
        setState = setFormState
        validation = formValidationState
        setValidation = setFormValidationState
        isSolarEndDate = false
        setDialogState = setOpenDialog
    }

    if (!props.hideFieldsNotNeededInSolarForm) {

        AddTotalUsage {
            state = formState
            setState = setFormState
            validation = formValidationState
            setValidation = setFormValidationState
        }

        AddTotalBillCost {
            state = formState
            setState = setFormState
            validation = formValidationState
            setValidation = setFormValidationState
        }

    }

    if (!props.hideFieldsNotNeededInHotOrChilledWaterForm) {

        AddBillEstimated {
            state = formState
            setState = setFormState
            validation = formValidationState
            setValidation = setFormValidationState
        }

        AddBillAdjusted {
            state = formState
            setState = setFormState
            validation = formValidationState
            setValidation = setFormValidationState
        }

    }
    ConfirmationDialog {
        open = openDialog
        title = "Confirmation"
        body = BillingConstants.BILL_START_END_DATE_DIFF_DIALOG_MESSAGE
        actionResult = { value ->
            setOpenDialog(false)
            if (!value) {
                clearBillStartAndEndDate(formState, setFormState)
            }
        }
    }
}