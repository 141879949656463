package com.ecosave.watch.portal.components.controlcenter

import com.ecosave.watch.portal.components.common.ApiFailedMessage
import com.ecosave.watch.portal.components.common.ApiLazyRender
import com.ecosave.watch.portal.components.npm.Cell
import com.ecosave.watch.portal.components.npm.Legend
import com.ecosave.watch.portal.components.npm.Pie
import com.ecosave.watch.portal.components.npm.PieChart
import com.ecosave.watch.portal.components.npm.ResponsiveContainer
import com.ecosave.watch.portal.components.npm.Tooltip
import com.ecosave.watch.portal.helpers.Colors
import com.ecosave.watch.portal.helpers.billing.UtilityType
import com.ecosave.watch.portal.helpers.controlcenter.PieChartDataKey
import com.ecosave.watch.portal.helpers.controlcenter.labelFormatter
import com.ecosave.watch.portal.models.controlcenter.JSONPieData
import com.ecosave.watch.portal.models.controlcenter.PieData
import js.core.jso
import js.core.push
import mui.material.Box
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import web.cssom.Color
import com.ecosave.watch.portal.styles.ControlCenterPageStyles as styles

external interface PieChartComponentProps : Props {
    var dataFetched: Boolean
    var isError: Boolean
    var pieTitle: String
    var data: List<PieData>
    var dataKey: String
}

val PieChartComponent = FC<PieChartComponentProps> { props ->

    val convertedData = arrayOf<JSONPieData>()
    var isUsageEmpty = true
    var isBillCostEmpty = true

    for (item in props.data) {
        convertedData.push(
            jso {
                name = item.name
                usage = item.usage
                billCost = item.billCost
            }
        )
        if (item.billCost > 0) {
            isBillCostEmpty = false
        }
        if (item.usage > 0) {
            isUsageEmpty = false
        }
    }

    val isEmpty = if (isUsageEmpty && props.dataKey === PieChartDataKey.USAGE.description) {
        true
    } else isBillCostEmpty && props.dataKey === PieChartDataKey.BILL_COST.description

    Typography {
        className = styles.TEXT_CENTER.cssClass
        variant = TypographyVariant.h6
        +props.pieTitle
    }
    Box {
        className = styles.TILE_CONTENT.cssClass
        ApiLazyRender {
            dataFetched = props.dataFetched
            if (props.isError) {
                ApiFailedMessage {

                }
            } else {
                if (isEmpty) {
                    Typography {
                        className = styles.TEXT_CENTER.cssClass
                        variant = TypographyVariant.subtitle1
                        sx {
                            color = Color(Colors.ORANGE_RED)
                        }
                        +"No data found"
                    }
                } else {
                    ResponsiveContainer {
                        width = "100%"
                        height = "100%"
                        PieChart {
                            width = "300"
                            height = "300"
                            Pie {
                                data = JSON.parse(JSON.stringify(convertedData))
                                cx = "50%"
                                cy = "50%"
                                label
                                outerRadius = "60%"
                                innerRadius = "40%"
                                paddingAngle = 5
                                dataKey = props.dataKey
                                props.data.forEachIndexed { index, pieData ->
                                    Cell {
                                        key = "cell-$index"
                                        fill = when (pieData.name) {
                                            UtilityType.ELECTRIC.name -> Colors.LIGHT_CYAN_BLUE
                                            UtilityType.NATURAL_GAS.name -> Colors.BRIGHT_GREEN
                                            UtilityType.STEAM.name -> Colors.GOLDENROD
                                            else -> ""
                                        }
                                        stroke = "0"
                                    }
                                }
                            }
                            Tooltip {
                                content = PieChartTooltip
                                labelStyle = jso {
                                    color = Colors.BLACK
                                }
                            }
                            Legend {
                                iconSize = 20
                                iconType = "wye"
                                formatter = ::labelFormatter
                            }
                        }
                    }
                }
            }
        }
    }
}
