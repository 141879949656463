package com.ecosave.watch.portal.components.privacypolicy

import com.ecosave.watch.portal.helpers.privacypolicy.getMailToWithSubject
import react.FC
import react.Props
import react.dom.html.OlType
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.ol

val CanadianResidentsPrivacyPolicy = FC<Props> {
    HeadingWrapper {
        heading = "For those of you in Canada, please see our Notice to Canadian Residents:"
    }
    HeadingWrapper {
        heading = "Notice to Canadian Residents"
        subHeading = true
    }
    TypographyWrapper {
        paragraph = true
        +"Ecosave complies with the Canadian Anti-Spam Law (“CASL”), which went into effect on July 1, 2014. Ecosave has certified that it adheres to the CASL. The purpose of the CASL is to promote the efficiency of the Canadian economy by regulating commercial conduct that discourages the use of email to carry out commercial means and to promote the efficiency and adaptability of the Canadian economy by regulating commercial conduct that discourages the use of electronic means to carry out commercial activities, because that conduct:"
    }
    TypographyWrapper {
        ol {
            type = OlType.a
            li {
                +"impairs the availability, reliability, efficiency and optimal use of electronic means to carry out commercial activities;"
            }
            li {
                +"imposes additional costs on businesses and consumers;"
            }
            li {
                +"compromises privacy and the security of confidential information; and"
            }
            li {
                +"undermines the confidence of Canadians in the use of electronic means of communication to carry out their commercial activities in Canada and abroad."
            }
        }
    }
    TypographyWrapper {
        +"In cases of a violation pursuant to CASL, Ecosave is potentially liable. If there is any conflict between the policies in the Privacy Policy and the CASL, the CASL shall govern. To learn more about the CASL and Ecosave’s compliance program, please visit fightspam.gc.ca. For any complaints in connection with any potential violation of the CASL, please first contact Ecosave at "
        CustomLink {
            to = TermsOfServiceConstants.ecosavePrivacyEmail.getMailToWithSubject("Privacy")
            text = TermsOfServiceConstants.ecosavePrivacyEmail
        }
    }
}