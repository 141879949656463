package com.ecosave.watch.portal

import react.StrictMode
import react.create
import react.dom.client.createRoot
import web.dom.document

fun main() {
    val container = document.getElementById("root") ?: error("Couldn't find root container!")
    createRoot(container).render(
        StrictMode.create {
            App {}
        }
    )
}