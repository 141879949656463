package com.ecosave.watch.portal.styles
import web.cssom.ClassName

enum class BillingInputFormStyles(val cssClass: ClassName) {
    INPUT_WRAPPER(ClassName("billing-input-wrapper")),
    INPUT_ITEM(ClassName("billing-input-item")),
    MAIN_FORM(ClassName("billing-main-form")),
}

enum class BillingTableStyles(val cssClass: ClassName) {
    TABLE_CELL(ClassName("billing-summary-cell")),
    TABLE_HEADERS(ClassName("billing-table-headers")),
    COLLAPSED_TABLE_HEADERS(ClassName("billing-table-collapsed-headers billing-summary-cell")),
    NAVIGATION_SECTOR(ClassName("navigation-sector")),
    NAVIGATION_BUTTON(ClassName("bill-summary-navigation-button")),
    TABLE_HEIGHT(ClassName("bill-summary-table-height")),
}

enum class UtilityAccountStyles(val cssClass: ClassName) {
    UTILITY_ACCOUNT_LABEL(ClassName("utility-account-label")),
    OPTION_SELECTION_TOOLTIP(ClassName("option-selection-tooltip"))
}