package com.ecosave.watch.portal.services

import com.ecosave.watch.portal.helpers.BackendServerURL
import com.ecosave.watch.portal.helpers.common.PageRoutes
import com.ecosave.watch.portal.helpers.common.getAccessTokenFromLocalStorage
import com.ecosave.watch.portal.helpers.common.getRefreshTokenFromLocalStorage
import com.ecosave.watch.portal.helpers.common.setTokensInLocalStorage
import com.ecosave.watch.portal.models.auth.TokenData
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.engine.js.*
import io.ktor.client.plugins.*
import io.ktor.client.plugins.api.*
import io.ktor.client.plugins.auth.*
import io.ktor.client.plugins.auth.providers.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.request.*
import io.ktor.http.*
import io.ktor.serialization.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.browser.localStorage
import kotlinx.browser.window
import kotlinx.serialization.json.Json

val CustomHeaderPlugin = createClientPlugin("CustomHeaderPlugin") {
    onRequest { request, _ ->
        request.headers.append("Content-Type", "application/json")
        request.headers.append("Cache-Control", "no-cache")
    }
}

fun getHttpClient(): HttpClient {
    return HttpClient(Js) {
        install(ContentNegotiation) {
            json(Json {
                ignoreUnknownKeys = true
            })
        }
        install(CustomHeaderPlugin)
        install(Auth) {
            bearer {
                loadTokens {
                    val accessToken = getAccessTokenFromLocalStorage()
                    val refreshToken = getRefreshTokenFromLocalStorage()
                    if (accessToken != null && refreshToken != null) {
                        BearerTokens(accessToken, refreshToken)
                    } else {
                        null
                    }
                }
                refreshTokens {
                    val response = nonAuthClient.post(
                        "$BackendServerURL/userAccount/login/refresh-access-token"
                    ) {
                        markAsRefreshTokenRequest()
                        contentType(ContentType.Application.Json)
                        setBody(getRefreshTokenFromLocalStorage())
                    }

                    if (response.status == HttpStatusCode.Unauthorized) {
                        localStorage.clear()
                        window.location.href = PageRoutes.SIGN_IN.route
                        null
                    } else {
                        val refreshTokenInfo: TokenData = response.body()
                        setTokensInLocalStorage(refreshTokenInfo.accessToken, refreshTokenInfo.refreshToken)
                        BearerTokens(refreshTokenInfo.accessToken, refreshTokenInfo.refreshToken)
                    }
                }
            }
        }
        HttpResponseValidator {
            handleResponseExceptionWithRequest { exception, _ ->
                when (exception) {
                    is ClientRequestException -> {
                        console.error("Bad Request Exception")
                        console.error("client request exception: ", exception)
                    }

                    is RedirectResponseException -> {
                        console.error("Redirect Response Exception")
                        console.error("redirect request exception: ", exception)
                    }

                    is ServerResponseException -> {
                        console.error("Server Response Exception")
                        console.error("Server request exception: ", exception)
                    }

                    is JsonConvertException -> {
                        console.error("Json Conversion Exception")
                        console.error("Json Conversion Exception: ", exception)
                    }
                }
            }
            validateResponse { response ->
                // handle 2xx responses
            }
        }

    }
}

var httpClient = getHttpClient()

val nonAuthClient: HttpClient = HttpClient(Js) {
    install(ContentNegotiation) {
        json(Json {
            ignoreUnknownKeys = true
        })
    }
}

data class ApiResponse<T>(
    var fetched: Boolean,
    var isError: Boolean,
    var data: T
)