package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class AntiCompetitiveBehaviorState(
    var section2061Field1: Long? = null,
    var legalActionsOutcomesList: MutableList<LegalActionsOutcomes> = mutableListOf(),

    var section3_3_206A1: String? = null,
    var section3_3_206A2: String? = null,
    var section3_3_206A3: String? = null,
    var section3_3_206A4: String? = null,
    var section3_3_206A5: String? = null,
    var section3_3_206A6: String? = null
)

@Serializable
data class LegalActionsOutcomes(
    var section2061A1: String? = null,
    var section2061B1: String? = null
) : DynamicTableItemKey()
