package com.ecosave.watch.portal.components.esg.employment

import com.ecosave.watch.portal.components.esg.EsgCoreComponentProps
import com.ecosave.watch.portal.components.esg.GenericSection_3_3
import com.ecosave.watch.portal.components.esg.formcontrols.GRIStandardName
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.isValidDecimalNumber
import com.ecosave.watch.portal.helpers.common.isValidLongNumber
import com.ecosave.watch.portal.helpers.esg.*
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.esg.*
import com.ecosave.watch.portal.services.esg.addDynamicRowOrSection
import com.ecosave.watch.portal.services.esg.deleteDynamicRowOrSection
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import kotlinx.coroutines.launch
import kotlinx.serialization.json.JsonPrimitive
import mui.material.Box
import react.FC
import react.dom.events.FocusEvent
import react.dom.events.FormEvent
import web.html.HTMLDivElement
import web.html.HTMLElement
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement


val EmploymentComponent = FC<EsgCoreComponentProps> { props ->

    val reportState = props.reportState
    val setReportState = props.setReportState
    val griStandardName = EsgSection.EMPLOYMENT

    reportState.report?.employment?.let { state ->
        fun stateSetter(state: EmploymentState) {
            setReportState(
                reportState.copy(
                    report = reportState.report.copy(
                        employment = state
                    )
                )
            )
        }

        val addRowOrSection: (String) -> Unit = { fieldName ->
            resetPreviousPatchState(props.setInputState)
            mainScope.launch {
                props.setAddDeleteRowOrSection(DynamicTableAction.ADD)
                props.setAddDeleteRowOrSectionApiCallInProgress(true)
                val apiCallStatus = addDynamicRowOrSection(
                    EsgCollectionAddRow(
                        objectName = griStandardName.name,
                        reportFileName = reportState.reportFileName,
                        patchCollection = RowPatch(
                            pathFirst = fieldName
                        )
                    )
                )

                val newState = state.copy()

                if (apiCallStatus == ApiCallStatus.SUCCESS) {
                    when (fieldName) {
                        state::newHiresTurnoverList.name -> {
                            newState.newHiresTurnoverList.add(NewHiresTurnover())
                            props.setNotificationMessage(ReportConstants.ADD_ROW_NOTIFICATION_MESSAGE)
                        }

                        state::benefitsList.name -> {
                            newState.benefitsList.add(EmployeeBenefits())
                            props.setNotificationMessage(ReportConstants.ADD_SECTION_NOTIFICATION_MESSAGE )
                        }
                    }

                    stateSetter(newState)

                    props.setNotificationStatus(NotificationStatus.SUCCESS)
                    props.setOpenAlertNotifications(true)
                } else {
                    failureErrorMessage(
                        props.setNotificationMessage,
                        props.setNotificationStatus,
                        props.setOpenAlertNotifications
                    )
                }
                props.setAddDeleteRowOrSectionApiCallInProgress(false)
            }
        }

        val deleteRowOrSection: (String, Int) -> Unit = { fieldName, index ->
            resetPreviousPatchState(props.setInputState)
            mainScope.launch {
                props.setAddDeleteRowOrSection(DynamicTableAction.DELETE)
                props.setAddDeleteRowOrSectionApiCallInProgress(true)
                val apiCallStatus = deleteDynamicRowOrSection(
                    EsgCollectionDeleteRow(
                        objectPatchEnum = griStandardName,
                        reportFileName = reportState.reportFileName,
                        pathFirst = fieldName,
                        indexAt = index
                    )
                )

                val newState = state.copy()

                if (apiCallStatus == ApiCallStatus.SUCCESS) {
                    when (fieldName) {
                        state::newHiresTurnoverList.name -> {
                            newState.newHiresTurnoverList.removeAt(index)
                            props.setNotificationMessage(ReportConstants.DELETE_ROW_NOTIFICATION_MESSAGE)
                        }

                        state::benefitsList.name -> {
                            newState.benefitsList.removeAt(index)
                            props.setNotificationMessage(ReportConstants.DELETE_SECTION_NOTIFICATION_MESSAGE)
                        }
                    }

                    stateSetter(newState)

                    props.setNotificationStatus(NotificationStatus.SUCCESS)
                    props.setOpenAlertNotifications(true)
                } else {
                    failureErrorMessage(
                        props.setNotificationMessage,
                        props.setNotificationStatus,
                        props.setOpenAlertNotifications
                    )
                }
                props.setAddDeleteRowOrSectionApiCallInProgress(false)
            }
        }

        val onChangeTextArea: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value

            val newState = state.copy()

            newState.let {
                when (propertyName) {
                    it::section3_3_401A1.name -> it.section3_3_401A1 = propertyValue
                    it::section3_3_401A2.name -> it.section3_3_401A2 = propertyValue
                    it::section3_3_401A3.name -> it.section3_3_401A3 = propertyValue
                    it::section3_3_401A4.name -> it.section3_3_401A4 = propertyValue
                    it::section3_3_401A5.name -> it.section3_3_401A5 = propertyValue
                    it::section3_3_401A6.name -> it.section3_3_401A6 = propertyValue
                }

                if (field == newState::benefitsList.name && changeIndex != null) {
                    newState.benefitsList[changeIndex].let {
                        when (propertyName) {
                            it::location.name -> it.location = propertyValue
                            it::lifeInsurance.name -> it.lifeInsurance = propertyValue
                            it::healthCare.name -> it.healthCare = propertyValue
                            it::disability.name -> it.disability = propertyValue
                            it::parentalLeave.name -> it.parentalLeave = propertyValue
                            it::retirementProvision.name -> it.retirementProvision = propertyValue
                            it::stockOwnership.name -> it.stockOwnership = propertyValue
                            it::otherBefenits.name -> it.otherBefenits = propertyValue
                        }
                    }
                }
            }

            stateSetter(newState)

            props.setInputState(
                CurrentInputState(
                    parentPath = field,
                    childPath = propertyName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue),
                    objectName = griStandardName.name,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurTextArea: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value

            props.onBlurApiCall(
                propertyName,
                JsonPrimitive(propertyValue),
                changeIndex,
                field,
                griStandardName.name
            )
        }

        val onChangeText: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            val propertyName = target.name
            val propertyValue = target.value

            val newState = state.copy()

            if (field == newState::newHiresTurnoverList.name && changeIndex != null) {
                newState.newHiresTurnoverList[changeIndex].let {
                    when (propertyName) {
                        it::emp401region.name -> it.emp401region = propertyValue
                        it::emp401ageGroup.name -> it.emp401ageGroup = propertyValue
                        it::emp401sexGroup.name -> it.emp401sexGroup = propertyValue
                    }
                }
            }

            stateSetter(newState)

            props.setInputState(
                CurrentInputState(
                    parentPath = field,
                    childPath = propertyName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue.trim()),
                    objectName = griStandardName.name,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurText: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            val propertyName = target.name
            val propertyValue = target.value

            props.onBlurApiCall(
                propertyName,
                JsonPrimitive(propertyValue),
                changeIndex,
                field,
                griStandardName.name
            )
        }

        val onChangeNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement

            if (isValidLongNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toLongOrNull()

                val newState = state.copy()

                if (field == newState::newHiresTurnoverList.name && changeIndex != null) {
                    newState.newHiresTurnoverList[changeIndex].let {
                        when (propertyName) {
                            it::newHires401count.name -> it.newHires401count = propertyValue
                            it::turnover401count.name -> it.turnover401count = propertyValue
                        }
                    }
                }

                when (propertyName) {
                    newState::section4013Current1.name -> newState.section4013Current1 = propertyValue
                    newState::section4013Current2.name -> newState.section4013Current2 = propertyValue
                    newState::section4013Current3.name -> newState.section4013Current3 = propertyValue
                    newState::section4013Current4.name -> newState.section4013Current4 = propertyValue
                    newState::section4013Current5.name -> newState.section4013Current5 = propertyValue
                    newState::section4013Current6.name -> newState.section4013Current6 = propertyValue
                    newState::section4013Current7.name -> newState.section4013Current7 = propertyValue
                    newState::section4013Current8.name -> newState.section4013Current8 = propertyValue
                    newState::section4013Current9.name -> newState.section4013Current9 = propertyValue
                    newState::section4013Current10.name -> newState.section4013Current10 = propertyValue
                    newState::section4013Current11.name -> newState.section4013Current11 = propertyValue
                    newState::section4013Current12.name -> newState.section4013Current12 = propertyValue
                    newState::section4013Current13.name -> newState.section4013Current13 = propertyValue
                    newState::section4013Current14.name -> newState.section4013Current14 = propertyValue
                    newState::section4013Current15.name -> newState.section4013Current15 = propertyValue
                    newState::section4013Current16.name -> newState.section4013Current16 = propertyValue
                    newState::section4013Current17.name -> newState.section4013Current17 = propertyValue
                    newState::section4013Current18.name -> newState.section4013Current18 = propertyValue

                    newState::section4013Previous1.name -> newState.section4013Previous1 = propertyValue
                    newState::section4013Previous2.name -> newState.section4013Previous2 = propertyValue
                    newState::section4013Previous3.name -> newState.section4013Previous3 = propertyValue
                    newState::section4013Previous4.name -> newState.section4013Previous4 = propertyValue
                    newState::section4013Previous5.name -> newState.section4013Previous5 = propertyValue
                    newState::section4013Previous6.name -> newState.section4013Previous6 = propertyValue
                    newState::section4013Previous7.name -> newState.section4013Previous7 = propertyValue
                    newState::section4013Previous8.name -> newState.section4013Previous8 = propertyValue
                    newState::section4013Previous9.name -> newState.section4013Previous9 = propertyValue
                    newState::section4013Previous10.name -> newState.section4013Previous10 = propertyValue
                    newState::section4013Previous11.name -> newState.section4013Previous11 = propertyValue
                    newState::section4013Previous12.name -> newState.section4013Previous12 = propertyValue
                    newState::section4013Previous13.name -> newState.section4013Previous13 = propertyValue
                    newState::section4013Previous14.name -> newState.section4013Previous14 = propertyValue
                    newState::section4013Previous15.name -> newState.section4013Previous15 = propertyValue
                    newState::section4013Previous16.name -> newState.section4013Previous16 = propertyValue
                    newState::section4013Previous17.name -> newState.section4013Previous17 = propertyValue
                    newState::section4013Previous18.name -> newState.section4013Previous18 = propertyValue
                }



                stateSetter(newState)

                props.setInputState(
                    CurrentInputState(
                        parentPath = field,
                        childPath = propertyName,
                        index = changeIndex,
                        value = JsonPrimitive(propertyValue),
                        objectName = griStandardName.name,
                        reportName = reportState.reportFileName
                    )
                )
            }
        }


        val onBlurNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidLongNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toLongOrNull()
                props.onBlurApiCall(
                    propertyName,
                    JsonPrimitive(propertyValue),
                    changeIndex,
                    field,
                    griStandardName.name
                )
            }
        }

        val onChangeDecimalNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement

            if (isValidDecimalNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toDoubleOrNull()

                val newState = state.copy()

                when (field) {
                    newState::newHiresTurnoverList.name -> {
                        if (changeIndex != null) {
                            newState.newHiresTurnoverList[changeIndex].let {
                                when (propertyName) {
                                    it::newHires401rate.name -> it.newHires401rate = propertyValue
                                    it::turnover401rate.name -> it.turnover401rate = propertyValue
                                }
                            }
                        }
                    }
                }
                stateSetter(newState)

                props.setInputState(
                    CurrentInputState(
                        parentPath = field,
                        childPath = propertyName,
                        index = changeIndex,
                        value = JsonPrimitive(propertyValue),
                        objectName = griStandardName.name,
                        reportName = reportState.reportFileName
                    )
                )
            }
        }

        val onBlurDecimalNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidDecimalNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toDoubleOrNull()
                props.onBlurApiCall(
                    propertyName,
                    JsonPrimitive(propertyValue),
                    changeIndex,
                    field,
                    griStandardName.name
                )
            }
        }

        Box {
            className = ESGCommonStyles.SECTION_MAIN_LAYOUT.cssClass
            GRIStandardName {
                griStandard = griStandardName
            }

            GenericSection_3_3 {
                this.onBlurTextArea = onBlurTextArea
                this.onChangeTextArea = onChangeTextArea
                this.materialTopicName = griStandardName.description

                this.section3_3_A1_name = state::section3_3_401A1.name
                this.section3_3_A1_value = state.section3_3_401A1

                this.section3_3_A2_name = state::section3_3_401A2.name
                this.section3_3_A2_value = state.section3_3_401A2

                this.section3_3_A3_name = state::section3_3_401A3.name
                this.section3_3_A3_value = state.section3_3_401A3

                this.section3_3_A4_name = state::section3_3_401A4.name
                this.section3_3_A4_value = state.section3_3_401A4

                this.section3_3_A5_name = state::section3_3_401A5.name
                this.section3_3_A5_value = state.section3_3_401A5

                this.section3_3_A6_name = state::section3_3_401A6.name
                this.section3_3_A6_value = state.section3_3_401A6
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_401_1)) {
                GRI_401_1 {
                    this.esgReportState = reportState
                    this.onChangeText = onChangeText
                    this.onBlurText = onBlurText
                    this.onChangeNumber = onChangeNumber
                    this.onBlurNumber = onBlurNumber
                    this.onChangeDecimalNumber = onChangeDecimalNumber
                    this.onBlurDecimalNumber = onBlurDecimalNumber
                    this.addRowOrSection = addRowOrSection
                    this.deleteRowOrSection = deleteRowOrSection
                }
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_401_2)) {
                GRI_401_2 {
                    this.esgReportState = reportState
                    this.onChangeText = onChangeText
                    this.onBlurText = onBlurText
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.addRowOrSection = addRowOrSection
                    this.deleteRowOrSection = deleteRowOrSection
                }
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_401_3)) {
                GRI_401_3 {
                    this.esgReportState = reportState
                    this.onChangeNumber = onChangeNumber
                    this.onBlurNumber = onBlurNumber
                }
            }
        }
    }
}