package com.ecosave.watch.portal.components.energymanagement

import com.ecosave.watch.portal.models.energymanagement.ConfirmationDialogState
import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.components.common.ConfirmationDialog
import com.ecosave.watch.portal.components.common.DialogSpinner
import com.ecosave.watch.portal.services.energymanagement.deleteFacilityImage
import com.ecosave.watch.portal.services.energymanagement.downloadFacilityImage
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.assetmanagement.FacilityClass
import emotion.react.css
import js.core.jso
import kotlinx.coroutines.launch
import react.FC
import react.Props
import react.ReactNode
import react.StateSetter
import react.dom.html.ReactHTML.img
import mui.material.Dialog
import mui.material.DialogContent
import mui.material.DialogTitle
import mui.system.sx
import mui.icons.material.Close
import mui.icons.material.Download
import mui.icons.material.Delete
import mui.icons.material.ZoomIn
import mui.icons.material.ZoomOut
import mui.material.Tooltip
import web.cssom.*
import react.useState
import kotlin.js.Date


external interface PhotoDialogProps:Props {
    var open: Boolean
    var setOpen: StateSetter<Boolean>
    var facilityChild: FacilityClass
    var setFacilityChild: StateSetter<FacilityClass>
}
val PhotoDialog = FC<PhotoDialogProps> { props ->

    var scaleNumber by useState(1.0)
    val (confirmationState, setConfirmationState) = useState(ConfirmationDialogState())
    val (notificationState,setNotificationState) = useState(NotificationState())
    var isLoading by useState(false)
    var spinnerMessage by useState("")

    Dialog {
        open = props.open
        onBackdropClick = {
            props.setOpen(false)
            scaleNumber = 1.0
        }
        PaperProps = jso {
            sx {
                maxWidth = 100.pct
                backgroundColor = rgb(0,0,0,0.0)
            }
        }
        DialogTitle {
            sx {
                float = Float.right
            }
            Tooltip {
                title = ReactNode("Close")
                Close()
                onClick = {
                    props.setOpen(false)
                    scaleNumber = 1.0
                }
            }
            Tooltip {
                title = ReactNode("Download")
                onClick = {
                        downloadFacilityImage(props.facilityChild)
                }
                Download()
            }
            Tooltip {
                title = ReactNode("Delete")
                onClick = {
                        setConfirmationState (
                            confirmationState.copy (
                                open = true,
                                title = "Facility Image Deletion",
                                body = "Are you sure you want to delete the image for the ${props.facilityChild.facilityName} facility ?"
                            )
                        )
                }
                Delete()
            }
            Tooltip {
                title = ReactNode("Zoom In")
                onClick = {
                    scaleNumber += 0.1
                }
                ZoomIn()
            }
            Tooltip {
                title = ReactNode("Zoom Out")
                onClick = {
                    if (scaleNumber > 0.2) {
                        scaleNumber-=0.1
                    }
                }
                ZoomOut()
            }
        }
        DialogContent {
            img {
                css {
                    width = 100.pct
                    transform = scale(scaleNumber)
                    objectFit = ObjectFit.scaleDown
                }
                key = "${props.facilityChild.imageUrl}?${Date.now()}"
                src = "${props.facilityChild.imageUrl}?${Date.now()}"
            }
        }
    }
    DialogSpinner {
        open = isLoading
        message = spinnerMessage
    }
    AlertNotifications {
        open = notificationState.visible
        message = notificationState.message
        status = notificationState.status
        closeNotification = {
            setNotificationState(
                notificationState.copy(
                    visible = false
                )
            )
        }
    }
    ConfirmationDialog {
        open = confirmationState.open
        title = confirmationState.title
        body = confirmationState.body
        actionResult = {value ->
            if (value) {
                mainScope.launch {
                    spinnerMessage = "Deleting..."
                    isLoading = true
                    props.facilityChild.facilityId?.let{
                        val deletedImage = deleteFacilityImage(it)
                        if (deletedImage.response) {
                            setNotificationState(
                                notificationState.copy(
                                    status = NotificationStatus.SUCCESS,
                                    message = "Image successfully deleted.",
                                    visible = true
                                )
                            )
                            props.setFacilityChild (
                                props.facilityChild.copy(
                                imageUrl = null
                                )
                            )
                            props.setOpen(false)
                        } else {
                            setNotificationState(
                                notificationState.copy(
                                    status = NotificationStatus.ERROR,
                                    message = "Error with deleting image.",
                                    visible = true
                                )
                            )
                        }
                    }
                    isLoading = false
                }
            }
            setConfirmationState (
                confirmationState.copy (
                    open = false
                )
            )
        }
    }
}