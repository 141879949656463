package com.ecosave.watch.portal.helpers.billing

import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.models.billing.utilitybillstate.UtilityBillState
import com.ecosave.watch.portal.models.billing.UtilityBillValidationState
import react.ReactNode
import react.StateSetter

fun validateTotalSolarCost(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.solarBillAdditional.totalSolarCost == null) {
        validation.solarBillValidationState.totalSolarCostErrorState = true
        validation.solarBillValidationState.totalSolarCostErrorMessage =
            ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else if (getDigitsCountBeforeDecimal(state.solarBillAdditional.totalSolarCost.toString()) > BillingConstants.MAX_COST_DIGITS) {
        validation.solarBillValidationState.totalSolarCostErrorState = true
        validation.solarBillValidationState.totalSolarCostErrorMessage =
            ReactNode(BillingConstants.INVALID_COST_ERROR_MESSAGE)
    } else {
        validation.solarBillValidationState.totalSolarCostErrorState = false
        validation.solarBillValidationState.totalSolarCostErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun validateSolarExportedCredit(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.solarBillAdditional.solarExportedCredit == null) {
        validation.solarBillValidationState.solarExportedCreditErrorState = true
        validation.solarBillValidationState.solarExportedCreditErrorMessage =
            ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else if (getDigitsCountBeforeDecimal(state.solarBillAdditional.solarExportedCredit.toString()) > BillingConstants.MAX_COST_DIGITS) {
        validation.solarBillValidationState.solarExportedCreditErrorState = true
        validation.solarBillValidationState.solarExportedCreditErrorMessage =
            ReactNode(BillingConstants.INVALID_COST_ERROR_MESSAGE)
    } else {
        validation.solarBillValidationState.solarExportedCreditErrorState = false
        validation.solarBillValidationState.solarExportedCreditErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun validateTotalSolarGenerated(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.solarBillAdditional.totalSolarGenerated == null) {
        validation.solarBillValidationState.totalSolarGeneratedErrorState = true
        validation.solarBillValidationState.totalSolarGeneratedErrorMessage =
            ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else if (getDigitsCountBeforeDecimal(state.solarBillAdditional.totalSolarGenerated.toString()) > BillingConstants.MAX_VALUE_DIGITS) {
        validation.solarBillValidationState.totalSolarGeneratedErrorState = true
        validation.solarBillValidationState.totalSolarGeneratedErrorMessage =
            ReactNode(BillingConstants.INVALID_VALUE_ERROR_MESSAGE)
    } else {
        validation.solarBillValidationState.totalSolarGeneratedErrorState = false
        validation.solarBillValidationState.totalSolarGeneratedErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun validateSolarExported(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.solarBillAdditional.solarExported == null) {
        validation.solarBillValidationState.solarExportedErrorState = true
        validation.solarBillValidationState.solarExportedErrorMessage =
            ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else if (getDigitsCountBeforeDecimal(state.solarBillAdditional.solarExported.toString()) > BillingConstants.MAX_VALUE_DIGITS) {
        validation.solarBillValidationState.solarExportedErrorState = true
        validation.solarBillValidationState.solarExportedErrorMessage =
            ReactNode(BillingConstants.INVALID_VALUE_ERROR_MESSAGE)
    } else {
        validation.solarBillValidationState.solarExportedErrorState = false
        validation.solarBillValidationState.solarExportedErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun isBillingSolarFormValid(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>,
    isSolarNetMetered: Boolean,
    isPPAInPlace: Boolean
): Boolean {
    return if (isSolarNetMetered) {

        validateSolarNetMeteredFields(state, validation, setValidation, isPPAInPlace)
        validateBillingCommonFields(state, validation, setValidation, UtilityType.SOLAR)

        isSolarNetMeteredFieldsValid(validation, isPPAInPlace) && isBillingCommonFieldsValid(
            validation,
            UtilityType.SOLAR
        )
    } else {
        validateSolarNotNetMeteredFields(state, validation, setValidation, isPPAInPlace)
        isSolarNotNetMeteredFieldsValid(validation, isPPAInPlace)
    }
}

fun validateSolarNetMeteredFields(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>,
    isPPAInPlace: Boolean
) {
    validateTotalSolarGenerated(state, validation, setValidation)
    validateSolarExported(state, validation, setValidation)
    validateSolarExportedCredit(state, validation, setValidation)

    if (isPPAInPlace) {
        validateTotalSolarCost(state, validation, setValidation)
    }
}

fun validateSolarNotNetMeteredFields(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>,
    isPPAInPlace: Boolean
) {
    validateBillStartDate(state, validation, setValidation)
    validateBillEndDate(state, validation, setValidation)
    validateTotalSolarGenerated(state, validation, setValidation)

    if (isPPAInPlace) {
        validateTotalSolarCost(state, validation, setValidation)
    }
}

fun isSolarNetMeteredFieldsValid(validation: UtilityBillValidationState, isPPAInPlace: Boolean): Boolean {

    val netMeteredFields = !(validation.solarBillValidationState.totalSolarGeneratedErrorState ||
            validation.solarBillValidationState.solarExportedErrorState ||
            validation.solarBillValidationState.solarExportedCreditErrorState)

    return if (isPPAInPlace) {
        netMeteredFields && !validation.solarBillValidationState.totalSolarCostErrorState
    } else {
        netMeteredFields
    }
}

fun isSolarNotNetMeteredFieldsValid(validation: UtilityBillValidationState, isPPAInPlace: Boolean): Boolean {

    val notNetMeteredFields = !(validation.billStartDateErrorState || validation.billEndDateErrorState ||
            validation.solarBillValidationState.totalSolarGeneratedErrorState)

    return if (isPPAInPlace) {
        notNetMeteredFields && !validation.solarBillValidationState.totalSolarCostErrorState
    } else {
        notNetMeteredFields
    }
}
