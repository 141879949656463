package com.ecosave.watch.portal.components.esg.generaldisclosures.stakeholderengagement

import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val Disclosure_2_29 = FC<EsgSubFormComponentProps> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.stakeholderEngagement?.let { state ->

        val categoriesOfStakeholdersListName = state::categoriesOfStakeholdersList.name

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_2_29
        }

        Box {
            className = ESGCommonStyles.TABLE_WRAPPER.cssClass
            DynamicTableWrapper {
                addRowOrSection = {
                    props.addRowOrSection(categoriesOfStakeholdersListName)
                }
                items = state.categoriesOfStakeholdersList
                action = DynamicTableAction.ADD
                addRowOrSectionBtnText = ReportConstants.ADD_ROW_MESSAGE
                Box {
                    className = ESGCommonStyles.THREE_COLUMN_TABLE.cssClass
                    TableHeader {
                        header = "Category of stakeholder"
                    }
                    TableHeader {
                        header = "How they are identified"
                    }
                    TableHeader {
                        header = "Purpose of the engagement"
                    }
                }
            }
            state.categoriesOfStakeholdersList.forEachIndexed { index, it ->
                DynamicTableWrapper {
                    key = it.key
                    deleteRowOrSection = {
                        props.deleteRowOrSection(categoriesOfStakeholdersListName, index)
                    }
                    items = state.categoriesOfStakeholdersList
                    action = DynamicTableAction.DELETE
                    variant = DynamicTableVariant.TABLE
                    Box {
                        className = ESGCommonStyles.THREE_COLUMN_TABLE.cssClass

                        ESGTextAreaFieldWrapper {
                            name = it::section229A1.name
                            value = it.section229A1
                            onChange = { event -> onChangeTextArea(event, index, categoriesOfStakeholdersListName) }
                            onBlur = { event -> onBlurTextArea(event, index, categoriesOfStakeholdersListName) }
                        }
                        ESGTextAreaFieldWrapper {
                            name = it::section229A2.name
                            value = it.section229A2
                            onChange = { event -> onChangeTextArea(event, index, categoriesOfStakeholdersListName) }
                            onBlur = { event -> onBlurTextArea(event, index, categoriesOfStakeholdersListName) }
                        }
                        ESGTextAreaFieldWrapper {
                            name = it::section229A3.name
                            value = it.section229A3
                            onChange = { event -> onChangeTextArea(event, index, categoriesOfStakeholdersListName) }
                            onBlur = { event -> onBlurTextArea(event, index, categoriesOfStakeholdersListName) }
                        }

                    }
                }
            }
        }

        Box {
            label {
                +"How does the organization seeks to ensure meaningful engagement with stakeholders?"
            }
            ESGTextAreaFieldWrapper {
                name = state::section229Field1.name
                value = state.section229Field1
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}