package com.ecosave.watch.portal.components.resourcecenter.utilityinformation

import com.ecosave.watch.portal.components.resourcecenter.ResourcesSection
import com.ecosave.watch.portal.helpers.resourcecenter.UtilityInformationImages.ClickDeleteIconImage
import com.ecosave.watch.portal.helpers.resourcecenter.UtilityInformationImages.ConfirmDeleteImage
import com.ecosave.watch.portal.styles.ResourceCenterStyles
import mui.material.List
import mui.system.Box
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML
import web.cssom.px

external interface UtilityInformationContentComponentProps : Props {
    var listPadding: Number
}

val UtilityInformationContentComponent = FC<UtilityInformationContentComponentProps> {
    List {
        sx {
            paddingLeft = it.listPadding.px
        }
        Box {
            id = "utility-information-overview"
            className = ResourceCenterStyles.RESOURCES_SUBSECTION.cssClass
            ResourcesSection {
                title = "Utility Information Overview"
                sentence = "This section offers a comprehensive overview of how to manage your utility information"
            }
        }
        Box {
            id = "choose-bill"
            className = ResourceCenterStyles.RESOURCES_SUBSECTION.cssClass
            ResourcesSection {
                underlined = "Choose Bill"
            }
            ReactHTML.img {
                src = ClickDeleteIconImage
                className = ResourceCenterStyles.RESOURCES_DISPLAY_OVERVIEW.cssClass
            }
        }
        Box {
            id = "confirm-selection"
            className = ResourceCenterStyles.RESOURCES_SUBSECTION.cssClass
            ResourcesSection {
                underlined = "Confirm Selection"
            }
            ReactHTML.img {
                src = ConfirmDeleteImage
                className = ResourceCenterStyles.RESOURCES_DISPLAY_OVERVIEW_BOX.cssClass
            }
        }
    }
}
