package com.ecosave.watch.portal.components.esg.childlabor

import com.ecosave.watch.portal.components.esg.EsgCoreComponentProps
import com.ecosave.watch.portal.components.esg.GenericSection_3_3
import com.ecosave.watch.portal.components.esg.formcontrols.GRIStandardName
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.EsgSection
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import com.ecosave.watch.portal.helpers.esg.failureErrorMessage
import com.ecosave.watch.portal.helpers.esg.omitDisclosureFromUI
import com.ecosave.watch.portal.helpers.esg.resetPreviousPatchState
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.esg.ChildLaborIncidentRisk
import com.ecosave.watch.portal.models.esg.ChildLaborState
import com.ecosave.watch.portal.models.esg.CurrentInputState
import com.ecosave.watch.portal.models.esg.EsgCollectionAddRow
import com.ecosave.watch.portal.models.esg.EsgCollectionDeleteRow
import com.ecosave.watch.portal.models.esg.RowPatch
import com.ecosave.watch.portal.models.esg.YoungWorkerIncidentRisk
import com.ecosave.watch.portal.services.esg.addDynamicRowOrSection
import com.ecosave.watch.portal.services.esg.deleteDynamicRowOrSection
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import kotlinx.coroutines.launch
import kotlinx.serialization.json.JsonPrimitive
import mui.material.Box
import react.FC
import react.dom.events.FocusEvent
import react.dom.events.FormEvent
import web.html.HTMLDivElement
import web.html.HTMLElement
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement

val ChildLaborComponent = FC<EsgCoreComponentProps> { props ->

    val reportState = props.reportState
    val setReportState = props.setReportState

    reportState.report?.childLabor?.let { state ->

        fun stateSetter(state: ChildLaborState) {
            setReportState(
                reportState.copy(
                    report = reportState.report.copy(
                        childLabor = state
                    )
                )
            )
        }

        val addRowOrSection: (String) -> Unit = { fieldName ->
            resetPreviousPatchState(props.setInputState)
            mainScope.launch {
                props.setAddDeleteRowOrSection(DynamicTableAction.ADD)
                props.setAddDeleteRowOrSectionApiCallInProgress(true)
                val apiCallStatus = addDynamicRowOrSection(
                    EsgCollectionAddRow(
                        objectName = EsgSection.CHILD_LABOR.name,
                        reportFileName = reportState.reportFileName,
                        patchCollection = RowPatch(
                            pathFirst = fieldName
                        )
                    )
                )
                if (apiCallStatus == ApiCallStatus.SUCCESS) {
                    val newState = state.copy()

                    when (fieldName) {
                        ChildLaborState::childLaborIncidentRiskList.name -> {
                            newState.childLaborIncidentRiskList.add(ChildLaborIncidentRisk())
                            props.setNotificationMessage(ReportConstants.ADD_ROW_NOTIFICATION_MESSAGE)
                        }

                        ChildLaborState::youngWorkerIncidentRiskList.name -> {
                            newState.youngWorkerIncidentRiskList.add(YoungWorkerIncidentRisk())
                            props.setNotificationMessage(ReportConstants.ADD_ROW_NOTIFICATION_MESSAGE)
                        }
                    }

                    stateSetter(newState)

                    props.setNotificationStatus(NotificationStatus.SUCCESS)
                    props.setOpenAlertNotifications(true)
                } else {
                    failureErrorMessage(
                        props.setNotificationMessage,
                        props.setNotificationStatus,
                        props.setOpenAlertNotifications
                    )
                }
                props.setAddDeleteRowOrSectionApiCallInProgress(false)
            }
        }

        val deleteRowOrSection: (String, Int) -> Unit = { fieldName, index ->
            resetPreviousPatchState(props.setInputState)
            mainScope.launch {
                props.setAddDeleteRowOrSection(DynamicTableAction.DELETE)
                props.setAddDeleteRowOrSectionApiCallInProgress(true)
                val apiCallStatus = deleteDynamicRowOrSection(
                    EsgCollectionDeleteRow(
                        objectPatchEnum = EsgSection.CHILD_LABOR,
                        reportFileName = reportState.reportFileName,
                        pathFirst = fieldName,
                        indexAt = index
                    )
                )
                if (apiCallStatus == ApiCallStatus.SUCCESS) {
                    val newState = state.copy()

                    when (fieldName) {
                        ChildLaborState::childLaborIncidentRiskList.name -> {
                            newState.childLaborIncidentRiskList.removeAt(index)
                            props.setNotificationMessage(ReportConstants.DELETE_ROW_NOTIFICATION_MESSAGE)
                        }

                        ChildLaborState::youngWorkerIncidentRiskList.name -> {
                            newState.youngWorkerIncidentRiskList.removeAt(index)
                            props.setNotificationMessage(ReportConstants.DELETE_ROW_NOTIFICATION_MESSAGE)
                        }
                    }

                    stateSetter(newState)

                    props.setNotificationStatus(NotificationStatus.SUCCESS)
                    props.setOpenAlertNotifications(true)
                } else {
                    failureErrorMessage(
                        props.setNotificationMessage,
                        props.setNotificationStatus,
                        props.setOpenAlertNotifications
                    )
                }
                props.setAddDeleteRowOrSectionApiCallInProgress(false)
            }
        }

        val onChangeTextArea: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value

            val newState = state.copy()

            when (propertyName) {
                state::section4081Field1.name -> newState.section4081Field1 = propertyValue

                state::section3_3_408A1.name -> newState.section3_3_408A1 = propertyValue
                state::section3_3_408A2.name -> newState.section3_3_408A2 = propertyValue
                state::section3_3_408A3.name -> newState.section3_3_408A3 = propertyValue
                state::section3_3_408A4.name -> newState.section3_3_408A4 = propertyValue
                state::section3_3_408A5.name -> newState.section3_3_408A5 = propertyValue
                state::section3_3_408A6.name -> newState.section3_3_408A6 = propertyValue
            }

            stateSetter(newState)

            props.setInputState(
                CurrentInputState(
                    parentPath = field,
                    childPath = propertyName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue),
                    objectName = EsgSection.CHILD_LABOR.name,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurTextArea: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value

            props.onBlurApiCall(
                propertyName,
                JsonPrimitive(propertyValue),
                changeIndex,
                field,
                EsgSection.CHILD_LABOR.name
            )
        }

        val onChangeText: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            val propertyName = target.name
            val propertyValue = target.value

            val newState = state.copy()

            if (field == ChildLaborState::childLaborIncidentRiskList.name && changeIndex != null) {
                newState.childLaborIncidentRiskList[changeIndex].let {
                    when (propertyName) {
                        ChildLaborIncidentRisk::section4081A1.name -> it.section4081A1 = propertyValue
                        ChildLaborIncidentRisk::section4081A2.name -> it.section4081A2 = propertyValue
                    }
                }
            } else if (field == ChildLaborState::youngWorkerIncidentRiskList.name && changeIndex != null) {
                newState.youngWorkerIncidentRiskList[changeIndex].let {
                    when (propertyName) {
                        YoungWorkerIncidentRisk::section4081B1.name -> it.section4081B1 = propertyValue
                        YoungWorkerIncidentRisk::section4081B2.name -> it.section4081B2 = propertyValue
                    }
                }
            }

            stateSetter(newState)

            props.setInputState(
                CurrentInputState(
                    parentPath = field,
                    childPath = propertyName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue.trim()),
                    objectName = EsgSection.CHILD_LABOR.name,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurText: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            val propertyName = target.name
            val propertyValue = target.value

            props.onBlurApiCall(
                propertyName,
                JsonPrimitive(propertyValue),
                changeIndex,
                field,
                EsgSection.CHILD_LABOR.name
            )
        }

        Box {
            className = ESGCommonStyles.SECTION_MAIN_LAYOUT.cssClass

            GRIStandardName {
                griStandard = EsgSection.CHILD_LABOR
            }

            GenericSection_3_3 {
                this.onBlurTextArea = onBlurTextArea
                this.onChangeTextArea = onChangeTextArea
                this.materialTopicName = EsgSection.CHILD_LABOR.description

                this.section3_3_A1_name = state::section3_3_408A1.name
                this.section3_3_A1_value = state.section3_3_408A1

                this.section3_3_A2_name = state::section3_3_408A2.name
                this.section3_3_A2_value = state.section3_3_408A2

                this.section3_3_A3_name = state::section3_3_408A3.name
                this.section3_3_A3_value = state.section3_3_408A3

                this.section3_3_A4_name = state::section3_3_408A4.name
                this.section3_3_A4_value = state.section3_3_408A4

                this.section3_3_A5_name = state::section3_3_408A5.name
                this.section3_3_A5_value = state.section3_3_408A5

                this.section3_3_A6_name = state::section3_3_408A6.name
                this.section3_3_A6_value = state.section3_3_408A6
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_408_1)) {
                GRI_408_1 {
                    this.esgReportState = reportState
                    this.onChangeText = onChangeText
                    this.onBlurText = onBlurText
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.addRowOrSection = addRowOrSection
                    this.deleteRowOrSection = deleteRowOrSection
                }
            }
        }
    }
}