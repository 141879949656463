package com.ecosave.watch.portal.components.resourcecenter.usermanagement


import com.ecosave.watch.portal.components.resourcecenter.ResourcesSection
import com.ecosave.watch.portal.helpers.resourcecenter.UserManagementImages
import com.ecosave.watch.portal.styles.ResourceCenterStyles
import mui.material.Box
import mui.material.List
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML
import web.cssom.px

external interface UserManagementComponentProps : Props {
    var listPadding: Number
}

val UserManagementComponent = FC<UserManagementComponentProps> {
    List {
        sx {
            paddingLeft = it.listPadding.px
        }
        Box {
            id = "user-management-overview"
            className = ResourceCenterStyles.RESOURCES_SUBSECTION.cssClass

            ResourcesSection {
                title = "User Management Overview"
                category = "To Invite Users"
            }

            for (sentence in userManagementInviteUsersSentences) {
                ResourcesSection {
                    subCategory = sentence
                }
            }
            ResourcesSection {
                category = "To Edit a User's Role"
            }
            for (sentence in userManagementEditRoleSentences) {
                ResourcesSection {
                    subCategory = sentence
                }
            }
            ResourcesSection {
                category = "To Delete a User"
                subCategory = "On the User Management Screen click the Delete Icon"
            }

            ResourcesSection {
                subCategory = "Follow the prompts"
            }
            ReactHTML.img {
                src = UserManagementImages.UserManagementOverview
                className = ResourceCenterStyles.RESOURCES_DISPLAY_OVERVIEW.cssClass
            }
        }
        Box {
            id = "edit-user-role"
            className = ResourceCenterStyles.RESOURCES_SUBSECTION.cssClass

            ResourcesSection {
                title = "Edit User Role"
            }
            ReactHTML.img {
                src = UserManagementImages.EditUserRoleImage
                className = ResourceCenterStyles.RESOURCES_DISPLAY_OVERVIEW_BOX.cssClass
            }
        }
        Box {
            id = "invite-users"
            className = ResourceCenterStyles.RESOURCES_SUBSECTION.cssClass
            ResourcesSection {
                title = "Invite Users"
            }
            ReactHTML.img {
                src = UserManagementImages.InviteUsersImage
                className = ResourceCenterStyles.RESOURCES_DISPLAY_OVERVIEW_BOX.cssClass
            }
        }
    }
}