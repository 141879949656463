package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class LocalCommunityState(
    var section4131A1: Double? = null,
    var section4131A2: Double? = null,
    var section4131A3: Double? = null,
    var section4131A4: Double? = null,
    var section4131A5: Double? = null,
    var section4131A6: Double? = null,
    var section4131A7: Double? = null,
    var section4131A8: Double? = null,
    var section4131A9: Double? = null,

    val impactOnLocalCommunityList: MutableList<ImpactOnLocalCommunity> = mutableListOf(ImpactOnLocalCommunity()),

    var section3_3_413A1: String? = null,
    var section3_3_413A2: String? = null,
    var section3_3_413A3: String? = null,
    var section3_3_413A4: String? = null,
    var section3_3_413A5: String? = null,
    var section3_3_413A6: String? = null
)

@Serializable
data class ImpactOnLocalCommunity(
    var section4132A1: String? = null,
    var section4132A2: String? = null,
) : DynamicTableItemKey()