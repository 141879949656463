package com.ecosave.watch.portal.helpers.facilitygroupmanagement

import js.core.jso

val facilitiesColumns: Array<dynamic> = arrayOf(
    jso {
        id = 1
        accessorKey = "facilityName"
        size = 170
        header = "Facility Name"
    },
    jso {
        id = 2
        accessorKey = "street"
        size = 170
        header = "Street"
    },
    jso {
        id = 3
        accessorKey = "city"
        size = 170
        header = "City"
    },
    jso {
        id = 4
        accessorKey = "state"
        size = 170
        header = "State"
    },
    jso {
        id = 5
        accessorKey = "zipcode"
        size = 170
        header = "Zipcode"
    },
    jso {
        id = 6
        accessorKey = "buildingCategory"
        size = 170
        header = "Building Category"
    }
)