package com.ecosave.watch.portal.components.esg.generaldisclosures.strategypoliciesandpractices

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val Disclosure_2_27 = FC<EsgSubFormComponentProps> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea
    val onChangeNumber = props.onChangeNumber
    val onBlurNumber = props.onBlurNumber
    val onChangeDecimalNumber = props.onChangeDecimalNumber
    val onBlurDecimalNumber = props.onBlurDecimalNumber

    props.esgReportState.report?.strategyPoliciesPractices?.let { state ->

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_2_27
        }

        Box {
            className = ESGCommonStyles.TWO_COLUMN_TABLE_8_4.cssClass
            label {
                +"Report the total number of significant instances of non-compliance with laws and regulations during the reporting period."
            }
            ESGNumberFieldWrapper {
                name = state::section227I1.name
                value = state.section227I1
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            label {
                +"Breakdown of above total by:"
            }
            Box {}
            label {
                +"Instances for which fines were occurred."
            }
            ESGNumberFieldWrapper {
                name = state::section227I2.name
                value = state.section227I2
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            label {
                +"Instances for which non-monetary sanctions were occurred."
            }
            ESGNumberFieldWrapper {
                name = state::section227I3.name
                value = state.section227I3
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
        }

        Box {
            className = ESGCommonStyles.THREE_COLUMN_TABLE_8_2_2.cssClass
            TableHeader {
                header = ""
            }
            TableHeader {
                header = "Number Of Fines"
            }
            TableHeader {
                header = "Monetary Value"
            }
            label {
                +"Report the total number and the monetary value of fines for instances of non- compliance with laws and regulations that were paid during the reporting period."
            }
            ESGNumberFieldWrapper {
                name = state::section227F1.name
                value = state.section227F1
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section227M1.name
                value = state.section227M1
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }
            label {
                +"Breakdown of above total by:"
            }
            Box {}
            Box {}
            label {
                +"Fines for instances of non-compliance with laws and regulations that occurred in the current reporting period."
            }
            ESGNumberFieldWrapper {
                name = state::section227F2.name
                value = state.section227F2
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section227M2.name
                value = state.section227M2
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }
            label {
                +"Fines for instances of non-compliance with laws and regulations that occurred in previous reporting periods."
            }
            ESGNumberFieldWrapper {
                name = state::section227F3.name
                value = state.section227F3
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section227M3.name
                value = state.section227M3
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }
        }

        Box {
            label {
                +"Describe the significant instances of non-compliance."
            }
            ESGTextAreaFieldWrapper {
                name = state::section227Field1.name
                value = state.section227Field1 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"Describe how it has determined significant instances of non-compliance."
            }
            ESGTextAreaFieldWrapper {
                name = state::section227Field2.name
                value = state.section227Field2 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}
