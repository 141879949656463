package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class FreedomOfAssociationAndCollectiveBargainingState(
    var section4071Field1: String? = null,
    val workerRightsOfAssociationAndCollectiveBargainingList: MutableList<WorkerRightsOfAssociationAndCollectiveBargaining> = mutableListOf(),
    var section3_3_407A1: String? = null,
    var section3_3_407A2: String? = null,
    var section3_3_407A3: String? = null,
    var section3_3_407A4: String? = null,
    var section3_3_407A5: String? = null,
    var section3_3_407A6: String? = null
)

@Serializable
data class WorkerRightsOfAssociationAndCollectiveBargaining(
    var section4071A1: String? = null,
    var section4071A2: String? = null,
) : DynamicTableItemKey()