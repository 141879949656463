package com.ecosave.watch.portal.helpers.esg

import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.models.esg.CurrentInputState
import com.ecosave.watch.portal.models.esg.EsgCollectionPatch
import com.ecosave.watch.portal.models.esg.EsgCollectionPatchUpdate
import com.ecosave.watch.portal.models.esg.EsgPatch
import com.ecosave.watch.portal.models.esg.EsgPatchUpdate
import com.ecosave.watch.portal.services.esg.autoSaveEsg
import com.ecosave.watch.portal.services.esg.autoSaveEsgCollection
import kotlinx.browser.document
import kotlinx.serialization.json.JsonPrimitive
import org.w3c.dom.HTMLAnchorElement
import org.w3c.dom.url.URL
import org.w3c.files.Blob
import org.w3c.files.BlobPropertyBag
import react.StateSetter

suspend fun autoSaveEsgField(path: String, value: JsonPrimitive?, objectName: String, reportName: String): ApiCallStatus {

    val updatedField = EsgPatch(
        op = "UPSERT",
        path = path,
        value = value
    )
    val patch = EsgPatchUpdate(objectName, reportName, updatedField)
    return autoSaveEsg(patch)
}

suspend fun autoSaveEsgCollectionField(
    parentPath: String,
    childPath: String,
    index: Int,
    value: JsonPrimitive?,
    objectName: String,
    reportName: String,
): ApiCallStatus {

    val updatedField = EsgCollectionPatch(
        pathFirst = parentPath,
        pathSecond = childPath,
        index = index,
        value = value
    )
    val patch = EsgCollectionPatchUpdate(objectName, reportName, updatedField)
    return autoSaveEsgCollection(patch)
}

fun savingErrorMessage(
    setNotificationMessage: StateSetter<String>,
    setNotificationStatus: StateSetter<NotificationStatus>,
    setOpenAlertNotifications: StateSetter<Boolean>,
    status: ApiCallStatus?,
) {
    if (status == ApiCallStatus.FAILURE || status == null) {
        setNotificationMessage("error while saving...")
        setNotificationStatus(NotificationStatus.ERROR)
        setOpenAlertNotifications(true)
    }
}

fun failureErrorMessage(
    setNotificationMessage: StateSetter<String>,
    setNotificationStatus: StateSetter<NotificationStatus>,
    setOpenAlertNotifications: StateSetter<Boolean>,
) {
    setNotificationMessage("An error has occurred, please retry.")
    setNotificationStatus(NotificationStatus.ERROR)
    setOpenAlertNotifications(true)
}


fun resetPreviousPatchState(stateSetter: StateSetter<CurrentInputState>) {
    stateSetter(
        CurrentInputState(
            childPath = "",
            value = JsonPrimitive(""),
            objectName = "",
            reportName = ""
        )
    )
}

fun omitDisclosureFromUI(omittedDisclosuresList: List<GriDisclosureTitles>, disclosure: GriDisclosureTitles): Boolean {
    return !(omittedDisclosuresList.contains(disclosure))
}

fun transformByteArrayAndDownloadReportPdf(reportByteArray: dynamic, reportFileName: String) {
    val blob = Blob(arrayOf(reportByteArray), BlobPropertyBag("application/pdf"))
    val url = URL.createObjectURL(blob)
    val a = document.createElement("a") as HTMLAnchorElement
    a.href = url
    a.download = reportFileName
    a.click()
}