package com.ecosave.watch.portal.components.assetmanagement

import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.components.common.DialogWrapper
import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.helpers.assetmanagement.AssetSystemType
import com.ecosave.watch.portal.helpers.assetmanagement.getDynamicAssetObject
import com.ecosave.watch.portal.helpers.assetmanagement.isAssetFormValid
import com.ecosave.watch.portal.helpers.assetmanagement.prepareAssetRequestBody
import com.ecosave.watch.portal.helpers.assetmanagement.validateEquipmentId
import com.ecosave.watch.portal.helpers.assetmanagement.validateEquipmentLocation
import com.ecosave.watch.portal.helpers.assetmanagement.validateEquipmentMaintenanceProvider
import com.ecosave.watch.portal.helpers.assetmanagement.validateEquipmentType
import com.ecosave.watch.portal.helpers.assetmanagement.validateFacility
import com.ecosave.watch.portal.helpers.assetmanagement.validateManufacturer
import com.ecosave.watch.portal.helpers.assetmanagement.validateModel
import com.ecosave.watch.portal.helpers.assetmanagement.validateOmDocument
import com.ecosave.watch.portal.helpers.assetmanagement.validateQuantity
import com.ecosave.watch.portal.helpers.assetmanagement.validateSerialNumber
import com.ecosave.watch.portal.helpers.assetmanagement.validateSystemType
import com.ecosave.watch.portal.helpers.assetmanagement.validateWarrantyEndDate
import com.ecosave.watch.portal.helpers.assetmanagement.validateYearInstalled
import com.ecosave.watch.portal.helpers.common.FormMode
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.isValidInteger
import com.ecosave.watch.portal.helpers.common.md
import com.ecosave.watch.portal.helpers.common.showNotification
import com.ecosave.watch.portal.helpers.common.sm
import com.ecosave.watch.portal.helpers.common.xs
import com.ecosave.watch.portal.models.assetmanagement.AssetApiResponse
import com.ecosave.watch.portal.models.assetmanagement.AssetState
import com.ecosave.watch.portal.models.assetmanagement.AssetValidationState
import com.ecosave.watch.portal.models.billing.Facility
import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.pages.mainScope
import com.ecosave.watch.portal.services.assetmanagement.addAsset
import com.ecosave.watch.portal.services.assetmanagement.addAssetWithDocument
import com.ecosave.watch.portal.services.assetmanagement.updateAsset
import com.ecosave.watch.portal.services.assetmanagement.updateAssetWithDocument
import com.ecosave.watch.portal.services.getAllFacilities
import io.ktor.http.*
import js.core.jso
import kotlinx.coroutines.launch
import mui.lab.LoadingButton
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.DialogActions
import mui.material.DialogContent
import mui.material.FormControl
import mui.material.FormHelperText
import mui.material.Grid
import mui.material.InputLabel
import mui.material.MenuItem
import mui.material.Select
import mui.material.TextField
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.StateSetter
import react.dom.onChange
import react.useEffect
import react.useEffectOnce
import react.useState
import web.cssom.JustifyContent
import web.cssom.px
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement
import web.html.InputType

external interface AddOrEditAssetProps : Props {
    var openAddOrEditAssetForm: Boolean
    var setOpenAddOrEditAssetForm: StateSetter<Boolean>
    var formMode: FormMode
    var assets: MutableList<dynamic>
    var setAssets: StateSetter<MutableList<dynamic>>
    var idOfAssetToBeUpdated: Int?
    var setIdOfAssetToBeUpdated: StateSetter<Int?>
}

val AddOrEditAsset = FC<AddOrEditAssetProps> { props ->

    val (assetState, setAssetState) = useState(AssetState())
    val (assetValidationState, setAssetValidationState) = useState(AssetValidationState())
    var isLoading by useState(false)
    var facilities by useState<List<Facility>>(emptyList())
    var isSaving by useState(false)
    val (notificationState, setNotificationState) = useState(NotificationState())

    useEffectOnce {
        mainScope.launch {
            isLoading = true
            facilities = getAllFacilities()
            isLoading = false
        }
    }

    // Populating Asset state for updating the asset
    useEffect(props.idOfAssetToBeUpdated) {
        props.idOfAssetToBeUpdated?.let {
            if (props.formMode == FormMode.EDIT) {
                val assetToBeUpdated = props.assets.first { it.assetId == props.idOfAssetToBeUpdated }
                setAssetState(
                    assetState.copy(
                        facilityId = assetToBeUpdated.facilityId.toString(),
                        equipmentId = assetToBeUpdated.equipmentId,
                        equipmentType = assetToBeUpdated.equipmentType,
                        equipmentLocation = assetToBeUpdated.equipmentLocation,
                        quantity = assetToBeUpdated.quantity,
                        serves = assetToBeUpdated.serves ?: "",
                        ownership = assetToBeUpdated.ownership ?: "",
                        manufacturer = assetToBeUpdated.manufacturer,
                        equipmentMaintenanceProvider = assetToBeUpdated.equipmentMaintenanceProvider,
                        model = assetToBeUpdated.modelNumber,
                        serialNumber = assetToBeUpdated.serialNumber,
                        systemType = AssetSystemType.entries.first { it.description == assetToBeUpdated.systemType }.name,
                        yearInstalled = assetToBeUpdated.yearInstalled,
                        warrantyEndDate = assetToBeUpdated.warrantyEndDate,
                        comments = assetToBeUpdated.comments ?: "",
                        fileType = assetToBeUpdated.fileType ?: "",
                        uploadDocumentName = assetToBeUpdated.uploadDocumentName ?: "",
                        documentUrl = assetToBeUpdated.documentUrl ?: ""
                    )
                )
            }
        }
    }

    fun closeAddOrEditAssetForm() {
        setAssetState(AssetState())
        setAssetValidationState(AssetValidationState())
        props.setOpenAddOrEditAssetForm(false)
        if (props.formMode == FormMode.EDIT) {
            props.setIdOfAssetToBeUpdated(null)
        }
    }

    DialogWrapper {
        open = props.openAddOrEditAssetForm
        dialogMaxWidth = 900
        dialogTitle = if (props.formMode == FormMode.ADD) "Add Asset" else "Edit Asset"
        DialogContent {
            Grid {
                sx {
                    paddingTop = 20.px
                }
                container = true
                spacing = responsive(3)
                if (props.formMode == FormMode.ADD) {
                    Grid {
                        item = true
                        xs = 12
                        sm = 6
                        md = 4
                        FormControl {
                            fullWidth = true
                            InputLabel {
                                +"Select Facility *"
                            }
                            error = assetValidationState.facilityErrorState
                            Select {
                                label = ReactNode("Select Facility *")
                                value = assetState.facilityId
                                onChange = { event, _ ->
                                    val value = event.target.value
                                    setAssetState(
                                        assetState.copy(
                                            facilityId = value
                                        )
                                    )
                                }
                                onBlur = {
                                    validateFacility(assetState, assetValidationState, setAssetValidationState)
                                }
                                if (facilities.isEmpty()) {
                                    MenuItem {
                                        disabled = true
                                        +"Loading..."
                                    }
                                } else {
                                    for (facility in facilities) {
                                        MenuItem {
                                            value = facility.facilityId.toString()
                                            +facility.facilityName
                                        }
                                    }
                                }
                            }
                            FormHelperText {
                                +assetValidationState.facilityErrorMessage
                            }
                        }
                    }
                }
                Grid {
                    item = true
                    xs = 12
                    sm = 6
                    md = 4
                    AssetTextFieldWrapper {
                        type = InputType.text
                        label = ReactNode("Equipment ID *")
                        value = assetState.equipmentId
                        error = assetValidationState.equipmentIdErrorState
                        helperText = assetValidationState.equipmentIdErrorMessage
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setAssetState(
                                assetState.copy(
                                    equipmentId = target.value
                                )
                            )
                        }
                        onBlur = {
                            validateEquipmentId(assetState, assetValidationState, setAssetValidationState)
                        }
                    }
                }
                Grid {
                    item = true
                    xs = 12
                    sm = 6
                    md = 4
                    AssetTextFieldWrapper {
                        type = InputType.text
                        label = ReactNode("Equipment Type *")
                        value = assetState.equipmentType
                        error = assetValidationState.equipmentTypeErrorState
                        helperText = assetValidationState.equipmentTypeErrorMessage
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setAssetState(
                                assetState.copy(
                                    equipmentType = target.value
                                )
                            )
                        }
                        onBlur = {
                            validateEquipmentType(assetState, assetValidationState, setAssetValidationState)
                        }
                    }
                }
                Grid {
                    item = true
                    xs = 12
                    sm = 6
                    md = 4
                    AssetTextFieldWrapper {
                        type = InputType.text
                        label = ReactNode("Equipment Location *")
                        value = assetState.equipmentLocation
                        error = assetValidationState.equipmentLocationErrorState
                        helperText = assetValidationState.equipmentLocationErrorMessage
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setAssetState(
                                assetState.copy(
                                    equipmentLocation = target.value
                                )
                            )
                        }
                        onBlur = {
                            validateEquipmentLocation(assetState, assetValidationState, setAssetValidationState)
                        }
                    }
                }
                Grid {
                    item = true
                    xs = 12
                    sm = 6
                    md = 4
                    AssetTextFieldWrapper {
                        type = InputType.number
                        label = ReactNode("Quantity *")
                        value = assetState.quantity
                        error = assetValidationState.quantityErrorState
                        helperText = assetValidationState.quantityErrorMessage
                        onChange = {
                            val target = it.target as HTMLInputElement
                            if (isValidInteger(target)) {
                                setAssetState(
                                    assetState.copy(
                                        quantity = target.value.toIntOrNull()
                                    )
                                )
                            }
                        }
                        onBlur = {
                            validateQuantity(assetState, assetValidationState, setAssetValidationState)
                        }
                    }
                }
                Grid {
                    item = true
                    xs = 12
                    sm = 6
                    md = 4
                    AssetTextFieldWrapper {
                        type = InputType.text
                        label = ReactNode("Serves")
                        value = assetState.serves
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setAssetState(
                                assetState.copy(
                                    serves = target.value
                                )
                            )
                        }
                    }
                }
                Grid {
                    item = true
                    xs = 12
                    sm = 6
                    md = 4
                    AssetTextFieldWrapper {
                        type = InputType.text
                        label = ReactNode("Ownership")
                        value = assetState.ownership
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setAssetState(
                                assetState.copy(
                                    ownership = target.value
                                )
                            )
                        }
                    }
                }
                Grid {
                    item = true
                    xs = 12
                    sm = 6
                    md = 4
                    AssetTextFieldWrapper {
                        type = InputType.text
                        label = ReactNode("Manufacturer *")
                        value = assetState.manufacturer
                        error = assetValidationState.manufacturerErrorState
                        helperText = assetValidationState.manufacturerErrorMessage
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setAssetState(
                                assetState.copy(
                                    manufacturer = target.value
                                )
                            )
                        }
                        onBlur = {
                            validateManufacturer(assetState, assetValidationState, setAssetValidationState)
                        }
                    }
                }
                Grid {
                    item = true
                    xs = 12
                    sm = 6
                    md = 4
                    AssetTextFieldWrapper {
                        type = InputType.text
                        label = ReactNode("Equipment Maintenance Provider *")
                        value = assetState.equipmentMaintenanceProvider
                        error = assetValidationState.equipmentMaintenanceProviderErrorState
                        helperText = assetValidationState.equipmentMaintenanceProviderErrorMessage
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setAssetState(
                                assetState.copy(
                                    equipmentMaintenanceProvider = target.value
                                )
                            )
                        }
                        onBlur = {
                            validateEquipmentMaintenanceProvider(assetState, assetValidationState, setAssetValidationState)
                        }
                    }
                }
                Grid {
                    item = true
                    xs = 12
                    sm = 6
                    md = 4
                    AssetTextFieldWrapper {
                        type = InputType.text
                        label = ReactNode("Make/Model # *")
                        value = assetState.model
                        error = assetValidationState.modelErrorState
                        helperText = assetValidationState.modelErrorMessage
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setAssetState(
                                assetState.copy(
                                    model = target.value
                                )
                            )
                        }
                        onBlur = {
                            validateModel(assetState, assetValidationState, setAssetValidationState)
                        }
                    }
                }
                Grid {
                    item = true
                    xs = 12
                    sm = 6
                    md = 4
                    AssetTextFieldWrapper {
                        type = InputType.text
                        label = ReactNode("Serial Number *")
                        value = assetState.serialNumber
                        error = assetValidationState.serialNumberErrorState
                        helperText = assetValidationState.serialNumberErrorMessage
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setAssetState(
                                assetState.copy(
                                    serialNumber = target.value
                                )
                            )
                        }
                        onBlur = {
                            validateSerialNumber(assetState, assetValidationState, setAssetValidationState)
                        }
                    }
                }
                Grid {
                    item = true
                    xs = 12
                    sm = 6
                    md = 4
                    FormControl {
                        fullWidth = true
                        InputLabel {
                            +"System Type *"
                        }
                        error = assetValidationState.systemTypeErrorState
                        Select {
                            label = ReactNode("System Type *")
                            value = assetState.systemType
                            onChange = { event, _ ->
                                val value = event.target.value
                                setAssetState(
                                    assetState.copy(
                                        systemType = value
                                    )
                                )
                            }
                            onBlur = {
                                validateSystemType(assetState, assetValidationState, setAssetValidationState)
                            }
                            for (systemType in AssetSystemType.entries) {
                                MenuItem {
                                    value = systemType.name
                                    +systemType.description
                                }
                            }
                        }
                        FormHelperText {
                            +assetValidationState.systemTypeErrorMessage
                        }
                    }
                }
                Grid {
                    item = true
                    xs = 12
                    sm = 6
                    md = 4
                    AssetTextFieldWrapper {
                        type = InputType.month
                        label = ReactNode("Year Installed *")
                        InputLabelProps = jso {
                            shrink = true
                        }
                        value = assetState.yearInstalled
                        error = assetValidationState.yearInstalledErrorState
                        helperText = assetValidationState.yearInstalledErrorMessage
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setAssetState(
                                assetState.copy(
                                    yearInstalled = target.value
                                )
                            )
                        }
                        onBlur = {
                            validateYearInstalled(assetState, assetValidationState, setAssetValidationState)
                        }
                    }
                }
                Grid {
                    item = true
                    xs = 12
                    sm = 6
                    md = 4
                    AssetTextFieldWrapper {
                        type = InputType.month
                        label = ReactNode("Warranty End Date *")
                        InputLabelProps = jso {
                            shrink = true
                        }
                        value = assetState.warrantyEndDate
                        error = assetValidationState.warrantyEndDateErrorState
                        helperText = assetValidationState.warrantyEndDateErrorMessage
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setAssetState(
                                assetState.copy(
                                    warrantyEndDate = target.value
                                )
                            )
                        }
                        onBlur = {
                            validateWarrantyEndDate(assetState, assetValidationState, setAssetValidationState)
                        }
                    }
                }
                Grid {
                    item = true
                    xs = 12
                    sm = 6
                    md = 4
                    AssetTextFieldWrapper {
                        type = InputType.file
                        label = ReactNode("O&M Manual")
                        InputLabelProps = jso {
                            shrink = true
                        }
                        error = assetValidationState.omDocumentErrorState
                        helperText = assetValidationState.omDocumentErrorMessage
                        onChange = {
                            val target = it.target as HTMLInputElement
                            val selectedFile = target.files?.get(0)
                            setAssetState(
                                assetState.copy(
                                    selectedFile = selectedFile,
                                    uploadDocumentName = selectedFile?.name ?: "",
                                    fileType = selectedFile?.type ?: ""
                                )
                            )
                        }
                        onBlur = {
                            validateOmDocument(assetState, assetValidationState, setAssetValidationState)
                        }
                    }
                }
                Grid {
                    item = true
                    xs = 12
                    TextField {
                        type = InputType.text
                        label = ReactNode("Comments")
                        multiline = true
                        fullWidth = true
                        minRows = Constants.TEXT_AREA_MIN_ROWS
                        maxRows = Constants.TEXT_AREA_MAX_ROWS
                        value = assetState.comments
                        onChange = {
                            val target = it.target as HTMLTextAreaElement
                            setAssetState(
                                assetState.copy(
                                    comments = target.value
                                )
                            )
                        }
                    }
                }
            }
        }
        DialogActions {
            sx {
                justifyContent = JustifyContent.center
            }
            Button {
                +"Cancel"
                onClick = {
                    closeAddOrEditAssetForm()
                }
            }
            LoadingButton {
                variant = ButtonVariant.contained
                loading = isSaving
                loadingIndicator = ReactNode("Saving...")
                +"Save"
                onClick = {

                    if (isAssetFormValid(assetState, assetValidationState, setAssetValidationState)) {
                        if (props.formMode == FormMode.ADD) {
                            // Add Asset
                            val requestBody = prepareAssetRequestBody(assetState)

                            mainScope.launch {
                                isSaving = true

                                val response: AssetApiResponse = if (assetState.selectedFile == null) {
                                    addAsset(requestBody)
                                } else {
                                    addAssetWithDocument(requestBody, assetState.selectedFile)
                                }

                                if (response.httpStatusCode == null) {
                                    showNotification(
                                        Constants.NOTIFICATION_ERROR_MESSAGE,
                                        NotificationStatus.ERROR,
                                        notificationState,
                                        setNotificationState
                                    )
                                } else if (response.httpStatusCode == HttpStatusCode.Created) {

                                    val assets = props.assets
                                    val facilityName = facilities.find { it.facilityId == response.asset?.facilityId }?.facilityName ?: ""
                                    assets.add(0, getDynamicAssetObject(response.asset!!, facilityName))
                                    props.setAssets(assets)

                                    showNotification(
                                        "Asset added successfully.",
                                        NotificationStatus.SUCCESS,
                                        notificationState,
                                        setNotificationState
                                    )
                                    closeAddOrEditAssetForm()
                                }
                                isSaving = false
                            }
                        } else {
                            // Update Asset
                            val requestBody = prepareAssetRequestBody(assetState, props.idOfAssetToBeUpdated)
                            mainScope.launch {
                                isSaving = true

                                val response: AssetApiResponse = if (assetState.selectedFile == null) {
                                    updateAsset(requestBody)
                                } else {
                                    updateAssetWithDocument(requestBody, assetState.selectedFile)
                                }

                                if (response.httpStatusCode == null) {
                                    showNotification(
                                        Constants.NOTIFICATION_ERROR_MESSAGE,
                                        NotificationStatus.ERROR,
                                        notificationState,
                                        setNotificationState
                                    )
                                } else if (response.httpStatusCode == HttpStatusCode.OK) {

                                    val assets = props.assets
                                    val index = assets.indexOf(
                                        assets.first { it.assetId == response.asset?.assetId }
                                    )
                                    assets[index] = getDynamicAssetObject(response.asset!!, assets[index].facilityName)
                                    props.setAssets(assets)

                                    showNotification(
                                        "Asset updated successfully.",
                                        NotificationStatus.SUCCESS,
                                        notificationState,
                                        setNotificationState
                                    )
                                    closeAddOrEditAssetForm()
                                }
                                isSaving = false
                            }
                        }
                    } else {
                        console.error("form not valid.")
                    }
                }
            }
        }
    }
    AlertNotifications {
        open = notificationState.visible
        status = notificationState.status
        message = notificationState.message
        closeNotification = {
            setNotificationState(
                notificationState.copy(
                    visible = false
                )
            )
        }
    }
}