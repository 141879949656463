package com.ecosave.watch.portal.helpers.facilitymanagement

import com.ecosave.watch.portal.helpers.billing.BillingConstants
import kotlinx.datetime.DayOfWeek

enum class FacilityManagementTableHeaders(val header: String) {
    EDIT("Edit"),
    FACILITY_NAME("Name"),
    CATEGORY("Category"),
    TYPE("Type"),
    TOTAL_AREA("Total Area (sq.ft)"),
    OCCUPANCY("Occupancy"),
    YEAR_BUILT("Year Built"),
    ENERGY_STAR_PORTFOLIO_MANAGER(FacilityConstants.ENERGY_STAR_PORTFOLIO_MANAGER),
    FACILITY_DESCRIPTION("Description"),
}

enum class FacilityManagementAddressHeaders(val header: String) {
    STREET("Street"),
    CITY("City"),
    STATE("State"),
    ZIPCODE("Zipcode")
}

enum class FacilityFields(val description: String) {
    NAME("Facility Name *"),
    BUILDING_CATEGORY("Building Category *"),
    BUILDING_TYPE("Building Type *"),
    OTHER_BUILDING_TYPE("Other Building Type *"),
    GROSS_FLOOR_AREA("Gross Floor Area (sq. ft.) *"),
    STREET("Street *"),
    CITY("City *"),
    ZIPCODE("Zipcode *"),
    STATE("State *"),
    YEAR_BUILT("Year Built *"),
    DESCRIPTION("Facility Description"),
    PROPERTY_ID("${BillingConstants.ENERGY_STAR} property id *"),
    OCCUPANCY("What % of your facility is occupied? *")
}

enum class FacilityDays(val dayName: String, val dayAbbreviation: String, val dayEnum: DayOfWeek) {
    SUNDAY("Sunday", "Sun", DayOfWeek.SUNDAY),
    MONDAY("Monday", "Mon", DayOfWeek.MONDAY),
    TUESDAY("Tuesday", "Tue", DayOfWeek.TUESDAY),
    WEDNESDAY("Wednesday", "Wed", DayOfWeek.WEDNESDAY),
    THURSDAY("Thursday", "Thu", DayOfWeek.THURSDAY),
    FRIDAY("Friday", "Fri", DayOfWeek.FRIDAY),
    SATURDAY("Saturday", "Sat", DayOfWeek.SATURDAY)
}

enum class EnergyStarConnectedAccountsFetchingStatus {
    LOADING,
    ERROR,
    SUCCESS
}

enum class ImportFacilitiesComponents(val description: String) {
    CONNECT_TO_ENERGY_STAR("Connect to ${BillingConstants.ENERGY_STAR}"),
    IMPORT_FACILITIES("Import Facilities")
}
