package com.ecosave.watch.portal.components.controlcenter

import com.ecosave.watch.portal.components.common.ApiFailedMessage
import com.ecosave.watch.portal.components.common.ApiLazyRender
import com.ecosave.watch.portal.helpers.Colors
import com.ecosave.watch.portal.helpers.billing.BillingConstants
import com.ecosave.watch.portal.models.controlcenter.EnergyKPIMetric
import mui.material.Box
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import web.cssom.Color
import web.cssom.FlexDirection
import web.cssom.JustifyContent
import web.cssom.pct
import com.ecosave.watch.portal.styles.ControlCenterPageStyles as styles

external interface EnergyKPIComponentProps : Props {
    var dataFetched: Boolean
    var energyKPIData: EnergyKPIMetric
    var isError: Boolean
}

val EnergyKPIComponent = FC<EnergyKPIComponentProps> { props ->
    Typography {
        className = styles.TEXT_CENTER.cssClass
        variant = TypographyVariant.h6
        +"Energy KPI"
    }
    ApiLazyRender {
        dataFetched = props.dataFetched
        Box {
            className = styles.TILE_CONTENT.cssClass
            sx {
                flexDirection = FlexDirection.column
                justifyContent = JustifyContent.spaceAround
                width = 85.pct
            }
            if (props.isError) {
                ApiFailedMessage {

                }
            } else {
                Box {
                    className = styles.LINE_WRAPPER.cssClass
                    Typography {
                        +"${BillingConstants.ENERGY_STAR} Score"
                    }
                    Typography {
                        sx {
                            color = Color(Colors.GREEN)
                        }
                        +"${props.energyKPIData.score}"
                    }
                }
                Box {
                    className = styles.LINE_WRAPPER.cssClass
                    Typography {
                        +"Site Energy Use Intensity (kBTU/sqft)"
                    }
                    Typography {
                        sx {
                            color = Color(Colors.GREEN)
                        }
                        +"${props.energyKPIData.siteEnergyUseIntensity}"
                    }
                }
                Box {
                    className = styles.LINE_WRAPPER.cssClass
                    Typography {
                        +"Cost Intensity ($/sqft)"
                    }
                    Typography {
                        sx {
                            color = Color(Colors.GREEN)
                        }
                        +"$${props.energyKPIData.costIntensity}"
                    }
                }
            }
        }
    }
}