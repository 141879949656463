package com.ecosave.watch.portal.helpers.assetmanagement

import com.ecosave.watch.portal.currentTheme
import com.ecosave.watch.portal.helpers.Colors
import com.ecosave.watch.portal.models.assetmanagement.Asset
import com.ecosave.watch.portal.models.assetmanagement.AssetState
import com.ecosave.watch.portal.models.assetmanagement.FacilityAndAssets
import com.ecosave.watch.portal.models.assetmanagement.RegistrationDailyOperation
import js.core.jso
import kotlinx.datetime.DayOfWeek
import mui.icons.material.Download
import mui.material.Link
import mui.material.SkeletonAnimation
import mui.material.SkeletonProps
import mui.material.TableCellAlign
import mui.material.TableCellProps
import mui.material.TableHeadProps
import mui.material.TableRowProps
import mui.material.ToolbarProps
import mui.system.sx
import react.create
import web.cssom.Color
import web.cssom.TextAlign
import web.cssom.px

val muiTableTheme: TableHeadProps = jso {
    sx {
        textAlign = TextAlign.center
        backgroundColor = Color(currentTheme.palette.background.default)
    }
}

val muiTableSkeleton: SkeletonProps = jso {
    animation = SkeletonAnimation.wave
}

val muiTableRowProps: TableRowProps = jso {
    sx {
        textAlign = TextAlign.center
        height = 100.px
        maxHeight = 100.px
    }
}

val tableCellProps: TableCellProps = jso {
    align = TableCellAlign.center
    sx {
        backgroundColor = Color(currentTheme.palette.background.default)
    }
}

val muiTableTopToolBarColor: ToolbarProps = jso {
    sx {
        backgroundColor = Color(currentTheme.palette.background.default)
        textAlign = TextAlign.center
    }
}

val headCellProps: TableCellProps = jso {
    align = TableCellAlign.center
    sx {
        color = Color(Colors.GRAY)
        width = 120.px
    }
}

fun getAssetEnumDescription(enumInput: String): String {
    return when (enumInput) {
        AssetSystemType.WATER.name -> AssetSystemType.WATER.description
        AssetSystemType.LIGHTING.name -> AssetSystemType.LIGHTING.description
        AssetSystemType.HVAC.name -> AssetSystemType.HVAC.description
        AssetSystemType.COOLING.name -> AssetSystemType.COOLING.description
        AssetSystemType.HEATING.name -> AssetSystemType.HEATING.description
        AssetSystemType.VENTILATION.name -> AssetSystemType.VENTILATION.description
        AssetSystemType.ONSITE_GENERATION.name -> AssetSystemType.ONSITE_GENERATION.description
        AssetSystemType.KITCHEN_EQUIPMENT.name -> AssetSystemType.KITCHEN_EQUIPMENT.description
        AssetSystemType.POWER_EFFICIENCY.name -> AssetSystemType.POWER_EFFICIENCY.description
        AssetSystemType.AIR_QUALITY.name -> AssetSystemType.AIR_QUALITY.description
        AssetSystemType.OTHER.name -> AssetSystemType.OTHER.description
        else -> ""
    }
}

fun getDataTableData(inputFacilityAndAssets: List<FacilityAndAssets>): MutableList<dynamic> {
    val dataArray = mutableListOf<dynamic>()
    for (facility in inputFacilityAndAssets) {
        facility.assets.forEach { asset ->
            dataArray.add(jso {
                facilityId = facility.facilityId
                facilityName = facility.facilityName
                assetId = asset.assetId
                equipmentId = asset.equipmentId
                equipmentType = asset.equipmentType
                systemType = getAssetEnumDescription(asset.systemType)
                equipmentLocation = asset.equipmentLocation
                quantity = asset.quantity
                serves = asset.serves
                ownership = asset.ownership
                manufacturer = asset.manufacturer
                equipmentMaintenanceProvider = asset.equipmentMaintenanceProvider
                modelNumber = asset.model
                serialNumber = asset.serialNumber
                yearInstalled = asset.yearInstalled
                warrantyEndDate = asset.warrantyEndDate
                comments = asset.comment
                oMManual = Link.create {
                    href = asset.documentUrl
                    asset.documentUrl?.let {
                        Download()
                    }
                }
                fileType = asset.fileType
                uploadDocumentName = asset.uploadDocumentName
                documentUrl = asset.documentUrl
            })
        }
    }
    return dataArray.toMutableList()
}

fun createFacilityTimeInitialData(): MutableList<RegistrationDailyOperation> {
    return mutableListOf(
        RegistrationDailyOperation(dayOfTheWeek = DayOfWeek.SUNDAY, startTime = "12:00:00", endTime = "12:00:00"),
        RegistrationDailyOperation(dayOfTheWeek = DayOfWeek.MONDAY, startTime = "12:00:00", endTime = "12:00:00"),
        RegistrationDailyOperation(dayOfTheWeek = DayOfWeek.TUESDAY, startTime = "12:00:00", endTime = "12:00:00"),
        RegistrationDailyOperation(dayOfTheWeek = DayOfWeek.WEDNESDAY, startTime = "12:00:00", endTime = "12:00:00"),
        RegistrationDailyOperation(dayOfTheWeek = DayOfWeek.THURSDAY, startTime = "12:00:00", endTime = "12:00:00"),
        RegistrationDailyOperation(dayOfTheWeek = DayOfWeek.FRIDAY, startTime = "12:00:00", endTime = "12:00:00"),
        RegistrationDailyOperation(dayOfTheWeek = DayOfWeek.SATURDAY, startTime = "12:00:00", endTime = "12:00:00")
    )
}

fun prepareAssetRequestBody(
    state: AssetState,
    assetId: Int? = null
): Asset {
    return Asset(
        assetId = assetId,
        facilityId = state.facilityId.toInt(),
        equipmentId = state.equipmentId,
        equipmentType = state.equipmentType,
        systemType = state.systemType,
        equipmentLocation = state.equipmentLocation,
        quantity = state.quantity!!,
        serves = state.serves.ifBlank { null },
        ownership = state.ownership.ifBlank { null },
        manufacturer = state.manufacturer,
        equipmentMaintenanceProvider = state.equipmentMaintenanceProvider,
        model = state.model,
        serialNumber = state.serialNumber,
        yearInstalled = state.yearInstalled,
        warrantyEndDate = state.warrantyEndDate,
        comment = state.comments.ifBlank { null },
        fileType = state.fileType.ifBlank { null },
        uploadDocumentName = state.uploadDocumentName.ifBlank { null },
        documentUrl = state.documentUrl.ifBlank { null },
    )
}

fun getDynamicAssetObject(asset: Asset, facilityName: String): dynamic {

    val assetSystemType = AssetSystemType.valueOf(asset.systemType).description

    return jso {
        facilityId = asset.facilityId
        this.facilityName = facilityName
        assetId = asset.assetId
        equipmentId = asset.equipmentId
        equipmentType = asset.equipmentType
        systemType = assetSystemType
        equipmentLocation = asset.equipmentLocation
        quantity = asset.quantity
        serves = asset.serves
        ownership = asset.ownership
        manufacturer = asset.manufacturer
        equipmentMaintenanceProvider = asset.equipmentMaintenanceProvider
        modelNumber = asset.model
        serialNumber = asset.serialNumber
        yearInstalled = asset.yearInstalled
        warrantyEndDate = asset.warrantyEndDate
        comments = asset.comment
        oMManual = Link.create {
            href = asset.documentUrl
            asset.documentUrl?.let {
                Download()
            }
        }
        fileType = asset.fileType
        uploadDocumentName = asset.uploadDocumentName
        documentUrl = asset.documentUrl
    }
}