package com.ecosave.watch.portal.components.esg.anticompetitivebehavior

import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val GRI_206_1 = FC<EsgSubFormComponentProps> { props ->
    val onChangeNumber = props.onChangeNumber
    val onChangeTextArea = props.onChangeTextArea
    val onBlurNumber = props.onBlurNumber
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.antiCompetitiveBehavior?.let { state ->
        val legalActionsOutcomesListName = state::legalActionsOutcomesList.name

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_206_1
        }

        label {
            +"Number of legal actions pending or completed during the reporting period regarding anti-competitive behavior and violations of anti-trust and monopoly legislation in which the organization has been identified as a participant."
        }
        ESGNumberFieldWrapper {
            name = state::section2061Field1.name
            value = state.section2061Field1
            onChange = { event -> onChangeNumber(event, null, null) }
            onBlur = { event -> onBlurNumber(event, null, null) }
        }

        Box {
            className = ESGCommonStyles.TABLE_WRAPPER.cssClass
            DynamicTableWrapper {
                addRowOrSection = {
                    props.addRowOrSection(legalActionsOutcomesListName)
                }
                items = state.legalActionsOutcomesList
                action = DynamicTableAction.ADD
                addRowOrSectionBtnText = ReportConstants.ADD_ROW_MESSAGE
                Box {
                    className = ESGCommonStyles.TWO_COLUMN_TABLE.cssClass
                    TableHeader {
                        header = "Legal actions"
                    }
                    TableHeader {
                        header = "Main outcomes of completed legal actions, including any decisions or judgements."
                    }
                }
            }
            state.legalActionsOutcomesList.forEachIndexed { index, it ->
                DynamicTableWrapper {
                    key = it.key
                    deleteRowOrSection = { props.deleteRowOrSection(legalActionsOutcomesListName, index) }
                    items = state.legalActionsOutcomesList
                    action = DynamicTableAction.DELETE
                    variant = DynamicTableVariant.TABLE
                    Box {
                        className = ESGCommonStyles.TWO_COLUMN_TABLE.cssClass
                        ESGTextAreaFieldWrapper {
                            name = it::section2061A1.name
                            value = it.section2061A1
                            onChange = { event -> onChangeTextArea(event, index, legalActionsOutcomesListName) }
                            onBlur = { event -> onBlurTextArea(event, index, legalActionsOutcomesListName) }
                        }
                        ESGTextAreaFieldWrapper {
                            name = it::section2061B1.name
                            value = it.section2061B1
                            onChange = { event -> onChangeTextArea(event, index, legalActionsOutcomesListName) }
                            onBlur = { event -> onBlurTextArea(event, index, legalActionsOutcomesListName) }
                        }
                    }
                }
            }
        }
    }
}