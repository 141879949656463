package com.ecosave.watch.portal.components.esg.trainingandeducation

import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.onChange

val GRI_404_3 = FC<EsgSubFormComponentProps> { props ->
    val onChangeText = props.onChangeText
    val onBlurText = props.onBlurText
    val onChangeDecimalNumber = props.onChangeDecimalNumber
    val onBlurDecimalNumber = props.onBlurDecimalNumber

    props.esgReportState.report?.trainingAndEducation?.let { state ->
        val breakdownOf4043ListName = state::breakdownOf4043List.name

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_404_3
        }
        DisclosureSubHeading {
            subHeading = "Percentage of total employees who received a regular performance and career development review during the reporting period."
        }
        Box {
            className = ESGCommonStyles.TABLE_WRAPPER.cssClass
            DynamicTableWrapper {
                addRowOrSection = { props.addRowOrSection(breakdownOf4043ListName) }
                items = state.breakdownOf4043List
                action = DynamicTableAction.ADD
                addRowOrSectionBtnText = ReportConstants.ADD_ROW_MESSAGE

                Box {
                    className = ESGCommonStyles.THREE_COLUMN_TABLE.cssClass
                    TableHeader {
                        header = "Gender"
                    }
                    TableHeader {
                        header = "Employee Category"
                    }
                    TableHeader {
                        header = "Percentage of employees"
                    }
                }
            }
            state.breakdownOf4043List.forEachIndexed { index, it ->
                DynamicTableWrapper {
                    key = it.key
                    deleteRowOrSection = { props.deleteRowOrSection(breakdownOf4043ListName, index) }
                    items = state.breakdownOf4043List
                    action = DynamicTableAction.DELETE
                    variant = DynamicTableVariant.TABLE
                    Box {
                        className = ESGCommonStyles.THREE_COLUMN_TABLE.cssClass
                        ESGTextFieldWrapper {
                            name = it::section4043Current1.name
                            value = it.section4043Current1
                            onChange = { event -> onChangeText(event, index, breakdownOf4043ListName) }
                            onBlur = { event -> onBlurText(event, index, breakdownOf4043ListName) }
                        }
                        ESGTextFieldWrapper {
                            name = it::section4043Current2.name
                            value = it.section4043Current2
                            onChange = { event -> onChangeText(event, index, breakdownOf4043ListName) }
                            onBlur = { event -> onBlurText(event, index, breakdownOf4043ListName) }
                        }
                        ESGNumberFieldWrapper {
                            name = it::section4043Current3.name
                            value = it.section4043Current3
                            onChange = { event -> onChangeDecimalNumber(event, index, breakdownOf4043ListName) }
                            onBlur = { event -> onBlurDecimalNumber(event, index, breakdownOf4043ListName) }
                        }
                    }
                }
            }
        }
    }
}