package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class CustomerPrivacyState(
    var section4181A1: Long? = null,
    var section4181A2: Long? = null,
    var section4181A3: Long? = null,
    var section4181A4: String? = null,

    var section4181B1: Long? = null,
    var section4181B2: Long? = null,
    var section4181B3: Long? = null,
    var section4181B4: String? = null,

    var section3_3_418A1: String? = null,
    var section3_3_418A2: String? = null,
    var section3_3_418A3: String? = null,
    var section3_3_418A4: String? = null,
    var section3_3_418A5: String? = null,
    var section3_3_418A6: String? = null
)
