package com.ecosave.watch.portal.components.controlcenter

import com.ecosave.watch.portal.helpers.Colors
import com.ecosave.watch.portal.helpers.billing.UtilityType
import com.ecosave.watch.portal.helpers.common.removeDecimalAndAddComma
import com.ecosave.watch.portal.helpers.controlcenter.PieChartDataKey
import mui.material.Box
import mui.material.Typography
import mui.system.sx
import react.FC
import react.Props
import web.cssom.Color
import web.cssom.px

external interface PieChartTooltipProps : Props {
    var active: Boolean
    var payload: Array<dynamic>
}

val PieChartTooltip = FC<PieChartTooltipProps> { props ->

    if (props.active && props.payload.isNotEmpty()) {
        val payload = props.payload.first()
        val prefix = if (payload.dataKey == PieChartDataKey.USAGE.description) {
            ""
        } else {
            "$"
        }
        val suffix = if (payload.dataKey == PieChartDataKey.USAGE.description) {
            "MMBtu"
        } else {
            ""
        }

        Box {
            sx {
                paddingTop = 10.px
                paddingBottom = 10.px
                paddingLeft = 8.px
                paddingRight = 8.px
                borderRadius = 3.px
                backgroundColor = Color(Colors.DARK_GRAY)
            }
            Typography {
                sx {
                    color = when (payload.name) {
                        UtilityType.ELECTRIC.name -> Color(Colors.LIGHT_CYAN_BLUE)
                        UtilityType.NATURAL_GAS.name -> Color(Colors.BRIGHT_GREEN)
                        UtilityType.STEAM.name -> Color(Colors.GOLDENROD)
                        else -> Color("")
                    }
                }
                +"${UtilityType.valueOf(payload.name).type}: ${prefix}${removeDecimalAndAddComma(payload.value)} ${suffix}"
            }
        }
    }
}