package com.ecosave.watch.portal.services.workorder

import com.ecosave.watch.portal.helpers.BackendServerURL
import com.ecosave.watch.portal.models.workorder.ActionDataClass
import com.ecosave.watch.portal.models.workorder.ActionStateDataClass
import com.ecosave.watch.portal.models.workorder.ActionUpdateResponseDataClass
import com.ecosave.watch.portal.models.workorder.EventDataClass
import com.ecosave.watch.portal.services.httpClient
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*

suspend fun getActions(params: String = ""): MutableList<ActionDataClass>? {
    try {
        val response: HttpResponse = httpClient.get("$BackendServerURL/work-force-management$params") {
            contentType(ContentType.Application.Json)
        }
        if (response.status == HttpStatusCode.OK) {
            return response.body()
        }
    } catch (_: dynamic) {

    }
    return null
}

suspend fun updateAction(body: ActionStateDataClass): ActionUpdateResponseDataClass? {
    try {
        val response: HttpResponse = httpClient.post("$BackendServerURL/work-force-management/update-action") {
            contentType(ContentType.Application.Json)
            setBody(body)
        }
        if (response.status == HttpStatusCode.OK) {
            return response.body()
        }
    } catch (_: dynamic) {

    }
    return null
}

suspend fun getEventFilterOptions(): MutableList<EventDataClass> {
    try {
        val response: HttpResponse = httpClient.get("$BackendServerURL/action-management/events") {
            contentType(ContentType.Application.Json)
        }
        if (response.status == HttpStatusCode.OK) {
            return response.body()
        }
    } catch (_: dynamic) {

    }
    return mutableListOf()
}