package com.ecosave.watch.portal.components.utilitybills

import com.ecosave.watch.portal.models.billing.utilitybillstate.UtilityBillState
import com.ecosave.watch.portal.helpers.common.yesNoDropdown
import mui.material.FormControl
import mui.material.FormControlVariant
import mui.material.MenuItem
import mui.material.Select
import mui.material.Size
import react.FC
import react.Props
import react.StateSetter

external interface BillAdjustedProps : Props {
    var stateBillAdjusted: UtilityBillState
    var setStateBillAdjusted: StateSetter<UtilityBillState>
}

val BillAdjusted = FC<BillAdjustedProps> { props ->

    val state = props.stateBillAdjusted
    val setState = props.setStateBillAdjusted

    FormControl {
        fullWidth = true
        size = Size.small
        variant = FormControlVariant.filled
        hiddenLabel = true
        Select {
            value = state.billAdjusted
            onChange = { event, _ ->
                setState(
                    state.copy(
                        billAdjusted = event.target.value.toBoolean()
                    )
                )
            }
            for (item in yesNoDropdown) {
                MenuItem {
                    value = item.value
                    +item.key
                }
            }
        }
    }
}