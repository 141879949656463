package com.ecosave.watch.portal.components.energymanagement

import com.ecosave.watch.portal.helpers.Colors
import com.ecosave.watch.portal.helpers.common.removeDecimalAndAddComma
import react.FC
import react.Props
import mui.material.Box
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import web.cssom.Color
import web.cssom.px
import com.ecosave.watch.portal.styles.EnergyManagementStyles as styles

external interface BaselineTooltipContentProps: Props {
    var active: Boolean
    var payload: Array<dynamic>
    var label: String?
}

val BaselineTooltipContent = FC<BaselineTooltipContentProps> {props ->
    if (props.active && !props.payload.isNullOrEmpty() && props.label is String) {
        var prefix = ""
        var surfix = ""
        var title = ""

        if (props.payload[0].dataKey == "baselineUsage") {
            prefix = ""
            surfix = "MMBtu"
            title = "Usage: "
        } else {
            prefix = "$"
            surfix = ""
            title = "Cost: "
        }

        Box {
            className = styles.TOOLTIP_CONTENT.cssClass
            Typography {
                sx {
                    color = Color(Colors.WHITE)
                }
                variant = TypographyVariant.h6
                +props.label
            }
            Typography {
                sx {
                    marginTop = 2.px
                    color = Color(Colors.LIGHT_CYAN_BLUE)
                }
                +"${title} ${prefix}${removeDecimalAndAddComma(props.payload[0].value)} ${surfix}"
            }
        }
    }
}