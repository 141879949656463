package com.ecosave.watch.portal.components.privacypolicy

import react.FC
import react.Props
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.ul

val AcceptableUse = FC<Props> {
    HeadingWrapper {
        heading = "4. Acceptable Use"
    }
    HeadingWrapper {
        heading = "4.1 Compliance with Laws and Regulations"
        subHeading = true
    }
    TypographyWrapper {
        +"You will use commercially reasonable efforts to ensure, through proper instructions and enforcement actions, that all access to and use of the Service by you or Authorized Users, or otherwise through your facilities, equipment, identifiers, or passwords, will conform to this Agreement and will be made and used solely for proper and legal purposes, and will be conducted in a manner that does not violate any law or regulation, the rights of any third party, or this Agreement."
    }
    HeadingWrapper {
        heading = "4.2 Conduct Restrictions"
        subHeading = true
    }
    TypographyWrapper {
        +"No provision of this Agreement includes the right to, and you will not, directly or indirectly:"
    }
    TypographyWrapper {
        ul {
            for (item in TermsOfServiceConstants.conductRestrictionsPoints) {
                li {
                    +item
                }
            }
        }
    }
}
