package com.ecosave.watch.portal.components.esg.tax


import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.Label
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.TaxField
import com.ecosave.watch.portal.models.esg.TaxState
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import com.ecosave.watch.portal.styles.esg.TaxStyles
import mui.material.Box
import react.FC
import react.Props
import react.dom.events.FormEvent
import react.dom.onChange
import web.html.HTMLDivElement

external interface Section2072Props : Props {
    var onChange: (FormEvent<HTMLDivElement>) -> Unit
    var state: TaxState
}

val Section207_2 = FC<Section2072Props> { props ->

    val onChangeFunction = props.onChange
    val state = props.state

    DisclosureName {
        disclosure = GriDisclosureTitles.GRI_207_2
    }


    Box {
        className = ESGCommonStyles.TABLE_WRAPPER.cssClass
        Box {
            className = TaxStyles.SECTION_207_TABLE.cssClass
            Label {
                label = "Governance body/executive-level position within the organization accountable for compliance with the tax strategy"
            }
            ESGTextAreaFieldWrapper {
                name = TaxField.SECTION_207_2_A1.name
                value = state.section2072A1
                onChange = { event -> onChangeFunction(event) }
            }
            Label {
                label = "How is the approach embedded within the organization?"
            }
            ESGTextAreaFieldWrapper {
                name = TaxField.SECTION_207_2_A2.name
                value = state.section2072A2
                onChange = { event -> onChangeFunction(event) }
            }
            Label {
                label =
                    "The approach to tax risks (including how risks are identified, managed, and monitored)"
            }
            ESGTextAreaFieldWrapper {
                name = TaxField.SECTION_207_2_A3.name
                value = state.section2072A3
                onChange = { event -> onChangeFunction(event) }
            }
            Label {
                label = "How is compliance with the tax governance and control framework evaluated?"
            }
            ESGTextAreaFieldWrapper {
                name = TaxField.SECTION_207_2_A4.name
                value = state.section2072A4
                onChange = { event -> onChangeFunction(event) }
            }
            Label {
                label =
                    "Provide a description of the mechanisms to raise concerns about the organization’s business conduct and the organization’s integrity in relation to tax."
            }
            ESGTextAreaFieldWrapper {
                name = TaxField.SECTION_207_2_A5.name
                value = state.section2072A5
                onChange = { event -> onChangeFunction(event) }
            }
            Label {
                label =
                    "Provide a description of the assurance process for disclosures on tax including, if applicable, a link or reference to the external assurance report(s) or assurance statement(s)."
            }
            ESGTextAreaFieldWrapper {
                name = TaxField.SECTION_207_2_A6.name
                value = state.section2072A6
                onChange = { event -> onChangeFunction(event) }
            }

        }
    }
}




