package com.ecosave.watch.portal.components.privacypolicy

import react.FC
import react.Props

val DataProvidedByCustomers = FC<Props> {
    HeadingWrapper {
        heading = "Data Provided by Customers"
    }
    TypographyWrapper {
        +"Our customers and their designated users use our Services to develop, establish, implement, and maintain secure application and database deployment environments for processing sensitive data, including Personal Information. While using our Services, our customers may create, input, submit, post, upload, transmit, or store Personal Information that they have collected from individuals. During the course of our business relationship, we may need to access a customer’s account and the information it contains to provide support for our Services."
    }
    TypographyWrapper {
        +"Our customers and prospective customers are responsible for complying with all applicable federal, state, local, and international laws and regulations regarding notice, disclosure, consent, and transfer of Personal Information, prior to providing that Personal Information to Ecosave."
    }
    TypographyWrapper {
        +"In addition, our customers and prospective customers are also responsible for identifying, in their services agreements with Ecosave or in a related document (such as a HIPAA Business Associate Agreement or General Data Protection Regulation (GDPR) agreement), any additional requirements for protecting, accessing, and handling Personal Information in a manner that exceeds the reasonable, risk-based administrative, technical, and physical safeguards that Ecosave would otherwise routinely implement, or that are inconsistent with the collection and use practices identified in this Privacy Statement."
    }
    TypographyWrapper {
        +"Unlike the other collections of information described in this section, our agreements with customers include protections and limitations regarding our access to and use of Personal Information collected by customers, and we do not access, use, copy, retain, or aggregate customer data except as stated in those agreements."
    }
}
