package com.ecosave.watch.portal.components.resourcecenter

import com.ecosave.watch.portal.Themes
import com.ecosave.watch.portal.helpers.common.PageRoutes
import com.ecosave.watch.portal.styles.ResourceCenterStyles
import com.ecosave.watch.portal.useGlobalState
import mui.icons.material.Lock
import mui.material.Box
import mui.material.Button
import mui.material.ButtonColor
import mui.material.ButtonVariant
import mui.material.Card
import mui.material.CardActions
import mui.material.CardContent
import mui.material.PaperVariant
import mui.material.Size
import mui.material.SvgIconColor
import mui.material.SvgIconSize
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.router.useNavigate
import web.cssom.FontWeight
import web.cssom.Position
import web.cssom.blur
import web.cssom.px

external interface ModuleCardProps : Props {
    var title: String
    var body: String
    var route: String
}

val ModuleCard = FC<ModuleCardProps> { props ->

    val globalState = useGlobalState()
    val navigate = useNavigate()

    fun isModuleLocked(): Boolean {
        return ((!globalState.isAuthenticated) && (props.title != "Initial Onboarding"))
    }
    Box {
        className = ResourceCenterStyles.RESOURCES_MAIN_DISPLAY.cssClass
        Card {
            variant = PaperVariant.elevation

//            sx {
//                borderRadius = 10.px
//                backgroundColor = Themes.ecosaveBlueAccent
//                position = Position.relative
//            }

            Box {

                sx {
                    if (isModuleLocked()) {
                        filter = blur(1.px)
                    }
                }
                CardContent {
                    Typography {
                        sx {
                            fontWeight = FontWeight.bolder
                            marginBottom = 20.px
                        }
                        gutterBottom = true
                        variant = TypographyVariant.h5
                        +props.title
                    }

                    Typography {
                        component = ReactHTML.div
                        variant = TypographyVariant.body2
                        +props.body
                    }
                }
                CardActions {
                    Button {
                        disabled = isModuleLocked()
                        size = Size.small
                        onClick = {
                            navigate.invoke(props.route)
                        }
                        +"Learn More"
                    }
                }
            }
            if (isModuleLocked()) {
                Box {
                    className = ResourceCenterStyles.LOCK_POSITIONING.cssClass
                    Lock {
                        sx {
                            marginLeft = 20.px
                        }
                        fontSize = SvgIconSize.medium
                        color = SvgIconColor.success
                    }
                    Button {
                        variant = ButtonVariant.contained
                        size = Size.small
                        color = ButtonColor.success
                        onClick = {
                            navigate.invoke(PageRoutes.SIGN_IN.route)
                        }
                        +"Sign In"
                    }
                }
            }
        }
    }

}