package com.ecosave.watch.portal.helpers

object Colors {
    const val MAIN_BLUE = "#054166"
    const val MID_BLUE = "#04314E"
    const val DARK_BLUE = "#03253B"
    const val WHITE = "#FFFFFF"
    const val LIGHT_GRAY = "#F5F6F7"
    const val GRAY = "#A9A9A9"
    const val GREEN = "#88c86d"
    const val HALF_GREEN = "#C2E0b1"
    const val TEXT_DISABLED = "#FFFFFF80"
    const val AQUA = "#00AEB5"

    const val LIGHT_CYAN_BLUE = "#03BCFC"
    const val ANAKIWA = "#90E3FF"
    const val BRIGHT_GREEN = "#04FB39"
    const val AERO_BLUE = "#B3FEC3"
    const val GOLDENROD = "#FDC019"
    const val BUTTERMILK = "#FEECB4"
    const val WHITE_SMOKE = "#f5f5f5"
    const val BLACK = "#000"
    const val ORANGE_RED = "#ff7961"
    const val CRIMSON_RED = "#f44336"
    const val TANGERINE_ORANGE = "#ffa726"
    const val SUBTLE_LIME_GREEN = "#66bb6a"
    const val SCARLET_RED = "#FF0000"
    const val PERIWINKLE_BLUE = "#8884d8"
    const val PINKISH_RED = "#F1666A"
    const val BRIGHT_CYAN_BLUE = "#66d3f1"
    const val SILVER_GRAY = "#CCCCCC"
    const val DARK_GRAY = "#323232"
    const val GOLD_YELLOW = "#FFD700"
    const val VIBRANT_GREEN = "#4CAF50"
    const val AQUAMARINE = "#00CED1"
    const val VIVID_ORANGE = "#FFBB28"
    const val BRIGHT_CYAN = "#00C49F"
    const val ROYAL_BLUE = "#0088FE"
    const val VIBRANT_ORANGE = "#FF8042"
    const val PURPLE = "#635bff"
}
