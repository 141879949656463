package com.ecosave.watch.portal.components.utilitybills

import com.ecosave.watch.portal.helpers.billing.BillingHeaders
import com.ecosave.watch.portal.helpers.billing.BillingSteamHeaders
import com.ecosave.watch.portal.models.billing.utilitybillstate.UtilityBillState
import com.ecosave.watch.portal.models.billing.UtilityBillValidationState
import com.ecosave.watch.portal.helpers.billing.UtilityType
import com.ecosave.watch.portal.helpers.common.YesNoEnum
import com.ecosave.watch.portal.helpers.billing.formatBillingValues
import com.ecosave.watch.portal.helpers.billing.formatSteamBillingLabels
import com.ecosave.watch.portal.helpers.billing.validateCoolingSteamUsage
import com.ecosave.watch.portal.helpers.billing.validateDemandBilled
import com.ecosave.watch.portal.helpers.billing.validateDemandMeasured
import com.ecosave.watch.portal.helpers.billing.validateHeatingDemandCharges
import com.ecosave.watch.portal.helpers.billing.validateHeatingSteamUsage
import com.ecosave.watch.portal.helpers.billing.validateHeatingUsageCharges
import com.ecosave.watch.portal.helpers.billing.validateTotalCoolingSteamCharges
import com.ecosave.watch.portal.helpers.billing.validateTotalHeatingSteamCharges
import com.ecosave.watch.portal.models.billing.UtilityBillSummary
import com.ecosave.watch.portal.styles.BillingTableStyles
import mui.material.TableCell
import mui.material.TableRow
import react.FC
import react.Props
import react.StateSetter
import react.dom.onChange
import web.html.HTMLInputElement
import web.html.InputType

external interface SteamDataRowProps : Props {
    var editModeSteam: (Boolean)
    var editRowSteam: Boolean
    var stateSteam: UtilityBillState
    var validationSteam: UtilityBillValidationState
    var billSteam: UtilityBillSummary
    var setStateSteam: StateSetter<UtilityBillState>
    var setValidationSteam: StateSetter<UtilityBillValidationState>
}

val SteamDataRow = FC<SteamDataRowProps> { props ->

    val editMode = props.editModeSteam
    val editRow = props.editRowSteam
    val state = props.stateSteam
    val setState = props.setStateSteam
    val validation = props.validationSteam
    val setValidation = props.setValidationSteam
    val bill = props.billSteam

    fun demandMeasured() {
        TableCell {
            className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
            +BillingSteamHeaders.DEMAND_MEASURED.header
        }
        TableCell {
            className = BillingTableStyles.TABLE_CELL.cssClass
            if (editRow) {
                EditableTextFieldWrapper {
                    type = InputType.number
                    value = state.steamBillAdditional.demandMeasured
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setState(
                            state.copy(
                                steamBillAdditional = state.steamBillAdditional.copy(
                                    demandMeasured = target.value.toFloatOrNull()
                                )
                            )
                        )
                    }
                    error =
                        validation.steamBillValidationState.demandMeasuredErrorState
                    helperText =
                        validation.steamBillValidationState.demandMeasuredErrorMessage
                    onBlur = {
                        validateDemandMeasured(state, validation, setValidation)
                    }
                }
            } else {
                +formatBillingValues(
                    bill.utilityBill.steamBillAdditional.demandMeasured,
                    bill.utilityBill.demandUnit?.description ?: ""
                )
            }
        }
    }

    fun demandBilled() {
        TableCell {
            className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
            +BillingSteamHeaders.DEMAND_BILLED.header
        }
        TableCell {
            className = BillingTableStyles.TABLE_CELL.cssClass
            if (editRow) {
                EditableTextFieldWrapper {
                    type = InputType.number
                    value = state.steamBillAdditional.demandBilled
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setState(
                            state.copy(
                                steamBillAdditional = state.steamBillAdditional.copy(
                                    demandBilled = target.value.toFloatOrNull()
                                )
                            )
                        )
                    }
                    error =
                        validation.steamBillValidationState.demandBilledErrorState
                    helperText =
                        validation.steamBillValidationState.demandBilledErrorMessage
                    onBlur = {
                        validateDemandBilled(state, validation, setValidation)
                    }
                }
            } else {
                +formatBillingValues(
                    bill.utilityBill.steamBillAdditional.demandBilled,
                    bill.utilityBill.demandUnit?.description ?: ""
                )
            }
        }
    }

    fun totalHeatingSteamCharges() {
        TableCell {
            className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
            +"${formatSteamBillingLabels(BillingSteamHeaders.TOTAL_HEATING_STEAM_CHARGES, editMode)} *"
        }
        TableCell {
            className = BillingTableStyles.TABLE_CELL.cssClass
            if (editRow) {
                EditableTextFieldWrapper {
                    type = InputType.number
                    value = state.steamBillAdditional.totalHeatingSteamCharges
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setState(
                            state.copy(
                                steamBillAdditional = state.steamBillAdditional.copy(
                                    totalHeatingSteamCharges = target.value.toFloatOrNull()
                                )
                            )
                        )
                    }
                    error =
                        validation.steamBillValidationState.totalHeatingSteamChargesErrorState
                    helperText =
                        validation.steamBillValidationState.totalHeatingSteamChargesErrorMessage
                    onBlur = {
                        validateTotalHeatingSteamCharges(state, validation, setValidation)
                    }
                }
            } else {
                +formatBillingValues(
                    bill.utilityBill.steamBillAdditional.totalHeatingSteamCharges,
                    null
                )
            }
        }
    }

    fun heatingDemandCharges() {
        TableCell {
            className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
            +"${formatSteamBillingLabels(BillingSteamHeaders.HEATING_DEMAND_CHARGES, editMode)} *"
        }
        TableCell {
            className = BillingTableStyles.TABLE_CELL.cssClass
            if (editRow) {
                EditableTextFieldWrapper {
                    type = InputType.number
                    value = state.steamBillAdditional.heatingDemandCharges
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setState(
                            state.copy(
                                steamBillAdditional = state.steamBillAdditional.copy(
                                    heatingDemandCharges = target.value.toFloatOrNull()
                                )
                            )
                        )
                    }
                    error =
                        validation.steamBillValidationState.heatingDemandChargesErrorState
                    helperText =
                        validation.steamBillValidationState.heatingDemandChargesErrorMessage
                    onBlur = {
                        validateHeatingDemandCharges(state, validation, setValidation)
                    }
                }
            } else {
                +formatBillingValues(
                    bill.utilityBill.steamBillAdditional.heatingDemandCharges,
                    null
                )
            }
        }
    }

    fun billEstimated() {
        TableCell {
            className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
            +BillingHeaders.BILL_ESTIMATED.header
        }
        TableCell {
            className = BillingTableStyles.TABLE_CELL.cssClass
            if (editRow) {
                BillEstimated {
                    stateBillEstimated = state
                    setStateBillEstimated = setState
                }
            } else {
                if (bill.utilityBill.billEstimated == true) +YesNoEnum.YES.description else +YesNoEnum.NO.description
            }
        }
    }

    fun billAdjusted() {
        TableCell {
            className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
            +BillingHeaders.BILL_ADJUSTED.header
        }
        TableCell {
            className = BillingTableStyles.TABLE_CELL.cssClass
            if (editRow) {
                BillAdjusted {
                    stateBillAdjusted = state
                    setStateBillAdjusted = setState
                }
            } else {
                if (bill.utilityBill.billAdjusted == true) +YesNoEnum.YES.description else +YesNoEnum.NO.description
            }
        }
    }

    fun heatingSteamUsage() {
        TableCell {
            className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
            +BillingSteamHeaders.HEATING_STEAM_USAGE.header
        }
        TableCell {
            className = BillingTableStyles.TABLE_CELL.cssClass
            if (editRow) {
                EditableTextFieldWrapper {
                    type = InputType.number
                    value = state.steamBillAdditional.heatingSteamUsage
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setState(
                            state.copy(
                                steamBillAdditional = state.steamBillAdditional.copy(
                                    heatingSteamUsage = target.value.toFloatOrNull()
                                )
                            )
                        )
                    }
                    error =
                        validation.steamBillValidationState.heatingSteamUsageErrorState
                    helperText =
                        validation.steamBillValidationState.heatingSteamUsageErrorMessage
                    onBlur = {
                        validateHeatingSteamUsage(state, validation, setValidation)
                    }
                }
            } else {
                +formatBillingValues(
                    bill.utilityBill.steamBillAdditional.heatingSteamUsage,
                    bill.utilityBill.usageUnit.description
                )
            }
        }
    }

    fun heatingUsageCharges() {
        TableCell {
            className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
            +"${formatSteamBillingLabels(BillingSteamHeaders.HEATING_USAGE_CHARGES, editMode)} *"
        }
        TableCell {
            className = BillingTableStyles.TABLE_CELL.cssClass
            if (editRow) {
                EditableTextFieldWrapper {
                    type = InputType.number
                    value = state.steamBillAdditional.heatingUsageCharges
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setState(
                            state.copy(
                                steamBillAdditional = state.steamBillAdditional.copy(
                                    heatingUsageCharges = target.value.toFloatOrNull()
                                )
                            )
                        )
                    }
                    error =
                        validation.steamBillValidationState.heatingUsageChargesErrorState
                    helperText =
                        validation.steamBillValidationState.heatingUsageChargesErrorMessage
                    onBlur = {
                        validateHeatingUsageCharges(state, validation, setValidation)
                    }
                }
            } else {
                +formatBillingValues(
                    bill.utilityBill.steamBillAdditional.heatingUsageCharges,
                    null
                )
            }
        }
    }

    fun coolingSteamUsage() {
        TableCell {
            className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
            +BillingSteamHeaders.COOLING_STEAM_USAGE.header
        }
        TableCell {
            className = BillingTableStyles.TABLE_CELL.cssClass
            if (editRow) {
                EditableTextFieldWrapper {
                    type = InputType.number
                    value = state.steamBillAdditional.coolingSteamUsage
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setState(
                            state.copy(
                                steamBillAdditional = state.steamBillAdditional.copy(
                                    coolingSteamUsage = target.value.toFloatOrNull()
                                )
                            )
                        )
                    }
                    error =
                        validation.steamBillValidationState.coolingSteamUsageErrorState
                    helperText =
                        validation.steamBillValidationState.coolingSteamUsageErrorMessage
                    onBlur = {
                        validateCoolingSteamUsage(state, validation, setValidation)
                    }
                }
            } else {
                +formatBillingValues(
                    bill.utilityBill.steamBillAdditional.coolingSteamUsage,
                    bill.utilityBill.usageUnit.description
                )
            }
        }
    }

    fun totalCoolingSteamCharges() {
        TableCell {
            className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
            +"${formatSteamBillingLabels(BillingSteamHeaders.TOTAL_COOLING_STEAM_CHARGES, editMode)} *"
        }
        TableCell {
            className = BillingTableStyles.TABLE_CELL.cssClass
            if (editRow) {
                EditableTextFieldWrapper {
                    type = InputType.number
                    value = state.steamBillAdditional.totalCoolingSteamCharges
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setState(
                            state.copy(
                                steamBillAdditional = state.steamBillAdditional.copy(
                                    totalCoolingSteamCharges = target.value.toFloatOrNull()
                                )
                            )
                        )
                    }
                    error =
                        validation.steamBillValidationState.totalCoolingSteamChargesErrorState
                    helperText =
                        validation.steamBillValidationState.totalCoolingSteamChargesErrorMessage
                    onBlur = {
                        validateTotalCoolingSteamCharges(state, validation, setValidation)
                    }
                }
            } else {
                +formatBillingValues(
                    bill.utilityBill.steamBillAdditional.totalCoolingSteamCharges,
                    null
                )
            }
        }
    }

    fun usageUnit() {
        if (editRow) {
            UsageUnit {
                stateUsageUnit = state
                setStateUsageUnit = setState
                usageUnits = UtilityType.STEAM.usageUnits
            }
        }
    }

    fun demandUnit() {
        if (editRow) {
            DemandUnit {
                stateDemandUnit = state
                setStateDemandUnit = setState
                demandUnits = UtilityType.STEAM.demandUnits ?: listOf()
            }
        }
    }

    if (bill.coolingSteamUsed == true && bill.heatingSteamUsed == true) {
        TableRow {
            billEstimated()
            billAdjusted()
            heatingSteamUsage()
        }
        TableRow {
            totalHeatingSteamCharges()
            demandBilled()
            demandMeasured()
        }
        TableRow {
            heatingUsageCharges()
            heatingDemandCharges()
            coolingSteamUsage()
        }
        TableRow {
            totalCoolingSteamCharges()
            usageUnit()
            demandUnit()
        }
    } else if (bill.heatingSteamUsed == true) {
        TableRow {
            billEstimated()
            billAdjusted()
            heatingSteamUsage()
        }
        TableRow {
            totalHeatingSteamCharges()
            demandBilled()
            demandMeasured()
        }
        TableRow {
            heatingUsageCharges()
            heatingDemandCharges()
            usageUnit()
        }
        TableRow {
            demandUnit()
        }
    } else {
        TableRow {
            billEstimated()
            billAdjusted()
            coolingSteamUsage()
        }
        TableRow {
            totalCoolingSteamCharges()
            usageUnit()
        }
    }
}