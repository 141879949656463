package com.ecosave.watch.portal.components.esg

import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.components.common.DialogSpinner
import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.esg.EsgPages
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.models.esg.EsgReportState
import com.ecosave.watch.portal.services.esg.downloadReport
import kotlinx.coroutines.launch
import mui.icons.material.Delete
import mui.icons.material.Edit
import mui.icons.material.InfoOutlined
import mui.icons.material.PictureAsPdf
import mui.material.Box
import mui.material.IconButton
import mui.material.TableCell
import mui.material.TableCellAlign
import mui.material.TableRow
import mui.material.Tooltip
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.StateSetter
import react.useState
import web.cssom.AlignItems
import web.cssom.Display
import web.cssom.FlexWrap
import web.cssom.px

external interface EsgReportsTableRowComponentProps : Props {
    var report: EsgReportState
    var setReportState: StateSetter<EsgReportState>
    var visiblePage: EsgPages
    var setVisiblePage: StateSetter<EsgPages>
    var setToBeDeletedReportFileName: StateSetter<String>
    var setOpenDeleteReportConfirmationDialog: StateSetter<Boolean>
}

val EsgReportsTableRowComponent = FC<EsgReportsTableRowComponentProps> { props ->

    val report = props.report
    var isDownloading by useState(false)
    val (notificationState, setNotificationState) = useState(NotificationState())

    TableRow {
        TableCell {
            align = TableCellAlign.left
            +report.reportFileName
        }
        TableCell {
            align = TableCellAlign.left
            +report.reportTitle
        }
        TableCell {
            align = TableCellAlign.left
            +report.reportFinancialYear.financialYear.toString()
        }
        TableCell {
            align = TableCellAlign.left
            Box {
                sx {
                    display = Display.flex
                    alignItems = AlignItems.center
                    flexWrap = FlexWrap.wrap
                }
                report.modifiedBy?.let {
                    +"${it.firstName} ${it.lastName}"
                    Tooltip {
                        sx {
                            marginLeft = 5.px
                        }
                        InfoOutlined()
                        title = ReactNode(it.emailAddress)
                    }
                }
            }
        }
        TableCell {
            align = TableCellAlign.left
            +report.lastModifiedTime
        }
        TableCell {
            align = TableCellAlign.left
            Tooltip {
                title = ReactNode("Download Report")
                IconButton {
                    PictureAsPdf()
                    disabled = isDownloading
                    onClick = {
                        mainScope.launch {
                            isDownloading = true
                            try {
                                val status = downloadReport(report.reportFileName)
                                if (status == ApiCallStatus.SUCCESS) {
                                    setNotificationState(
                                        notificationState.copy(
                                            status = NotificationStatus.SUCCESS,
                                            message = "Report downloaded successfully.",
                                            visible = true
                                        )
                                    )
                                } else {
                                    setNotificationState(
                                        notificationState.copy(
                                            status = NotificationStatus.ERROR,
                                            message = Constants.NOTIFICATION_ERROR_MESSAGE,
                                            visible = true
                                        )
                                    )
                                }
                            } catch (e: Exception) {
                                console.error(e.message)
                            }
                            isDownloading = false
                        }
                    }
                }
            }
            Tooltip {
                title = ReactNode("Edit Report")
                IconButton {
                    Edit()
                    onClick = {
                        props.setReportState(report)
                        if (report.esgSections.isNotEmpty()) {
                            props.setVisiblePage(EsgPages.TABLE_OF_CONTENTS)
                        } else {
                            props.setVisiblePage(EsgPages.SELECT_SECTIONS)
                        }
                    }
                }
            }
            Tooltip {
                title = ReactNode("Delete Report")
                IconButton {
                    Delete()
                    onClick = {
                        props.setToBeDeletedReportFileName(report.reportFileName)
                        props.setOpenDeleteReportConfirmationDialog(true)
                    }
                }
            }
        }
    }
    DialogSpinner {
        open = isDownloading
        message = "Downloading..."
    }
    AlertNotifications {
        open = notificationState.visible
        status = notificationState.status
        message = notificationState.message
        closeNotification = {
            setNotificationState(
                notificationState.copy(
                    visible = false
                )
            )
        }
    }
}