package com.ecosave.watch.portal.components.utilitybills

import com.ecosave.watch.portal.components.common.ConfirmationDialog
import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.helpers.billing.BillingConstants
import com.ecosave.watch.portal.helpers.billing.UtilityType
import com.ecosave.watch.portal.helpers.billing.clearBillStartAndEndDate
import com.ecosave.watch.portal.helpers.billing.differenceBetweenBillStartAndEndDate
import com.ecosave.watch.portal.helpers.billing.formatBillingValues
import com.ecosave.watch.portal.helpers.billing.getUtilityTypeDescription
import com.ecosave.watch.portal.helpers.billing.isEditBillFormValid
import com.ecosave.watch.portal.helpers.billing.mainScope
import com.ecosave.watch.portal.helpers.billing.populateBillState
import com.ecosave.watch.portal.helpers.billing.utilityBillStateInitialization
import com.ecosave.watch.portal.helpers.billing.utilityBillValidationStateInitialization
import com.ecosave.watch.portal.helpers.billing.validateBillEndDate
import com.ecosave.watch.portal.helpers.billing.validateBillStartDate
import com.ecosave.watch.portal.helpers.billing.validateTotalBillCost
import com.ecosave.watch.portal.helpers.billing.validateTotalUsage
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.models.billing.UtilityBill
import com.ecosave.watch.portal.models.billing.UtilityBillSummary
import com.ecosave.watch.portal.services.billing.deleteUtilityBill
import com.ecosave.watch.portal.services.billing.editUtilityBill
import kotlin.math.abs
import kotlinx.coroutines.launch
import mui.icons.material.Cancel
import mui.icons.material.Check
import mui.icons.material.Delete
import mui.icons.material.Edit
import mui.icons.material.KeyboardArrowDown
import mui.icons.material.KeyboardArrowUp
import mui.material.Box
import mui.material.Collapse
import mui.material.IconButton
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableRow
import mui.system.sx
import react.FC
import react.Props
import react.dom.onChange
import react.useState
import web.cssom.pct
import web.html.HTMLInputElement
import web.html.InputType

external interface UtilityBillSummaryDataRowProps : Props {
    var billData: List<UtilityBillSummary>
    var bill: UtilityBillSummary
    var editMode: Boolean
    var setEditMode: (Boolean) -> Unit
    var editBill: (UtilityBill) -> Unit
    var deleteBill: (Int) -> Unit
    var setNotificationMessage: (String) -> Unit
    var setNotificationStatus: (NotificationStatus) -> Unit
    var showNotification: () -> Unit
}

val UtilityBillSummaryDataRow = FC<UtilityBillSummaryDataRowProps> { props ->

    val billData = props.billData
    val bill = props.bill
    val editMode = props.editMode
    val setEditMode = props.setEditMode
    val editBill = props.editBill
    val (rowCollapsed, setRowCollapsed) = useState(false)
    val (editRow, setEditRow) = useState(false)
    var openDialog by useState(false)
    val dialogTitle by useState("Confirmation")
    var dialogBody by useState("")
    var confirmBillDeletion by useState(false)
    /* below two states are created to keep track that dialog is used for bill
       deletion or for bill start and end date validation confirmation */
    var billDeletionDialogState by useState(false)
    var billStartEndDateValidationDialogState by useState(false)
    val (state, setState) = useState(utilityBillStateInitialization())
    val (validation, setValidation) = useState(utilityBillValidationStateInitialization())
    val utilityType = UtilityType.valueOf(bill.utilityBill.utilityType)

    if (confirmBillDeletion) {
        mainScope.launch {
            val status = deleteUtilityBill(bill.utilityBill.utilityBillId!!)
            if (status == ApiCallStatus.SUCCESS) {
                props.setNotificationStatus(NotificationStatus.SUCCESS)
                props.setNotificationMessage(BillingConstants.DELETE_BILL_SUCCESS_NOTIFICATION_MESSAGE)
                props.deleteBill(bill.utilityBill.utilityBillId!!)
            } else {
                props.setNotificationStatus(NotificationStatus.ERROR)
                props.setNotificationMessage(Constants.NOTIFICATION_ERROR_MESSAGE)
            }
            confirmBillDeletion = false
            props.showNotification()
        }
    }

    fun billStartEndDateConfirmationDialog(days: Int) {
        dialogBody = BillingConstants.BILL_START_END_DATE_DIFF_DIALOG_MESSAGE
        val result = abs(days) > BillingConstants.BILL_START_AND_END_DATE_DAYS_DIFF
        if (result) {
            openDialog = true
            billStartEndDateValidationDialogState = true
        }
    }

    TableRow {
        hover = true
        TableCell {
            if (utilityType != UtilityType.HOT_WATER && utilityType != UtilityType.CHILLED_WATER) {
                IconButton {
                    onClick = { setRowCollapsed(!rowCollapsed) }
                    if (rowCollapsed) {
                        KeyboardArrowUp {}
                    } else {
                        KeyboardArrowDown {}
                    }
                    disabled = editRow
                }
            }
        }
        TableCell {
            +getUtilityTypeDescription(bill.utilityBill.utilityType)
        }
        TableCell {
            +bill.serviceName
        }
        TableCell {
            // NA is for Solar
            if (bill.accountNumber == null) +"NA" else +bill.accountNumber
        }
        TableCell {
            if (editRow) {
                EditableTextFieldWrapper {
                    type = InputType.date
                    value = state.billStartDate
                    onChange = {
                        val target = it.target as HTMLInputElement
                        val value = target.value
                        setState(state.copy(billStartDate = value))
                    }
                    error = validation.billStartDateErrorState
                    helperText = validation.billStartDateErrorMessage
                    onBlur = {
                        val days = differenceBetweenBillStartAndEndDate(state.billStartDate, state.billEndDate)
                        billStartEndDateConfirmationDialog(days)
                        validateBillStartDate(state, validation, setValidation)
                    }
                }
            } else {
                +bill.utilityBill.billStartDate
            }
        }
        TableCell {
            if (editRow) {
                EditableTextFieldWrapper {
                    type = InputType.date
                    value = state.billEndDate
                    onChange = {
                        val target = it.target as HTMLInputElement
                        val value = target.value
                        setState(state.copy(billEndDate = value))
                    }
                    error = validation.billEndDateErrorState
                    helperText = validation.billEndDateErrorMessage
                    onBlur = {
                        val days = differenceBetweenBillStartAndEndDate(state.billStartDate, state.billEndDate)
                        billStartEndDateConfirmationDialog(days)
                        validateBillEndDate(state, validation, setValidation)
                    }
                }
            } else {
                +bill.utilityBill.billEndDate
            }
        }
        TableCell {
            if (editRow
                && utilityType != UtilityType.SOLAR
                && utilityType != UtilityType.STEAM
            ) {
                EditableTextFieldWrapper {
                    type = InputType.number
                    value = state.totalUsage
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setState(state.copy(totalUsage = target.value.toFloatOrNull()))
                    }
                    error = validation.totalUsageErrorState
                    helperText = validation.totalUsageErrorMessage
                    onBlur = {
                        validateTotalUsage(state, validation, setValidation)
                    }
                }
            } else {
                // NA is for Solar
                if (bill.utilityBill.totalUsage == null) +"NA" else +formatBillingValues(
                    bill.utilityBill.totalUsage,
                    bill.utilityBill.usageUnit.description
                )
            }
        }
        TableCell {
            if (editRow
                && utilityType != UtilityType.SOLAR
                && utilityType != UtilityType.STEAM
            ) {
                EditableTextFieldWrapper {
                    type = InputType.number
                    value = state.totalBillCost
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setState(state.copy(totalBillCost = target.value.toFloatOrNull()))
                    }
                    error = validation.totalBillCostErrorState
                    helperText = validation.totalBillCostErrorMessage
                    onBlur = {
                        validateTotalBillCost(state, validation, setValidation)
                    }
                }
            } else {
                // NA is for Solar
                if (bill.utilityBill.totalBillCost == null) +"NA" else +formatBillingValues(
                    bill.utilityBill.totalBillCost,
                    null
                )
            }
        }
        TableCell {
            if (editRow) {
                IconButton {
                    Cancel()
                    onClick = {
                        setEditRow(false)
                        setRowCollapsed(false)
                        setEditMode(false)
                        setState(
                            utilityBillStateInitialization()
                        )
                        setValidation(
                            utilityBillValidationStateInitialization()
                        )
                    }
                }
                IconButton {
                    onClick = {
                        if (isEditBillFormValid(
                                state,
                                validation,
                                setValidation,
                                bill.surchargeBillExists,
                                bill.solarNetMetered,
                                bill.solarPPAInPlace,
                                bill.coolingSteamUsed ?: false,
                                bill.heatingSteamUsed ?: false
                            )
                        ) {
                            mainScope.launch {
                                val (utilityBill, status) = editUtilityBill(state)
                                if (status == ApiCallStatus.SUCCESS) {
                                    setEditRow(false)
                                    setRowCollapsed(false)
                                    setEditMode(false)
                                    editBill(utilityBill!!)
                                    props.setNotificationStatus(NotificationStatus.SUCCESS)
                                    props.setNotificationMessage(BillingConstants.EDIT_BILL_SUCCESS_NOTIFICATION_MESSAGE)
                                } else {
                                    props.setNotificationStatus(NotificationStatus.ERROR)
                                    props.setNotificationMessage(Constants.NOTIFICATION_ERROR_MESSAGE)
                                }
                                props.showNotification()
                            }
                        }
                    }
                    Check()
                }
            } else {
                IconButton {
                    disabled = editMode
                    onClick = {
                        setState(populateBillState(bill.utilityBill))
                        setEditRow(true)
                        setRowCollapsed(true)
                        setEditMode(true)
                    }
                    Edit()
                }
                IconButton {
                    disabled = editMode
                    Delete()
                    onClick = {
                        dialogBody = "Are you sure you want to delete this bill?"
                        openDialog = true
                        billDeletionDialogState = true
                    }
                }
            }

        }
    }
    TableRow {
        TableCell {
            sx {
                width = 5.pct
                paddingBottom = 0.pct
                paddingTop = 0.pct
            }
        }
        TableCell {
            sx {
                paddingBottom = 0.pct
                paddingTop = 0.pct
            }
            colSpan =
                if ((utilityType == UtilityType.HOT_WATER || utilityType == UtilityType.CHILLED_WATER)
                ) {
                    2
                } else if (utilityType == UtilityType.SOLAR && bill.solarNetMetered == false && bill.solarPPAInPlace == false && !editRow) {
                    2
                } else if (utilityType == UtilityType.SOLAR && bill.solarNetMetered == false && bill.solarPPAInPlace == false && editRow) {
                    4
                } else if (utilityType == UtilityType.SOLAR && bill.solarNetMetered == false && bill.solarPPAInPlace == true && !editRow) {
                    5
                } else if (utilityType == UtilityType.SOLAR && bill.solarNetMetered == false && bill.solarPPAInPlace == true && editRow) {
                    7
                } else {
                    7
                }

            Collapse {
                `in` = rowCollapsed
                timeout
                Box {
                    Table {
                        TableBody {
                            when (utilityType) {
                                UtilityType.ELECTRIC -> {
                                    ElectricDataRow {
                                        editModeElectric = editMode
                                        editRowElectric = editRow
                                        stateElectric = state
                                        validationElectric = validation
                                        billElectric = bill
                                        setStateElectric = setState
                                        setValidationElectric = setValidation
                                    }
                                }

                                UtilityType.WATER -> {
                                    WaterDataRow {
                                        editModeWater = editMode
                                        editRowWater = editRow
                                        stateWater = state
                                        validationWater = validation
                                        billWater = bill
                                        setStateWater = setState
                                        setValidationWater = setValidation
                                    }
                                }

                                UtilityType.HOT_WATER, UtilityType.CHILLED_WATER -> {
                                    HotOrChilledWaterDataRow {
                                        editRowHotOrChilledWater = editRow
                                        stateHotOrChilledWater = state
                                        setStateHotOrChilledWater = setState
                                        utilityTypeHotOrChilledWater = utilityType
                                    }
                                }

                                UtilityType.NATURAL_GAS -> {
                                    NaturalGasDataRow {
                                        editModeNaturalGas = editMode
                                        editRowNaturalGas = editRow
                                        stateNaturalGas = state
                                        validationNaturalGas = validation
                                        billNaturalGas = bill
                                        setStateNaturalGas = setState
                                        setValidationNaturalGas = setValidation
                                    }
                                }

                                UtilityType.STEAM -> {
                                    SteamDataRow {
                                        editModeSteam = editMode
                                        editRowSteam = editRow
                                        stateSteam = state
                                        validationSteam = validation
                                        billSteam = bill
                                        setStateSteam = setState
                                        setValidationSteam = setValidation
                                    }
                                }

                                UtilityType.SOLAR -> {
                                    SolarDataRow {
                                        editModeSolar = editMode
                                        editRowSolar = editRow
                                        stateSolar = state
                                        validationSolar = validation
                                        billSolar = bill
                                        setStateSolar = setState
                                        setValidationSolar = setValidation
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        TableCell {
            colSpan =
                if ((utilityType == UtilityType.HOT_WATER || utilityType == UtilityType.CHILLED_WATER)
                ) {
                    6
                } else if (utilityType == UtilityType.SOLAR && bill.solarNetMetered == false && bill.solarPPAInPlace == false && !editRow) {
                    6
                } else if (utilityType == UtilityType.SOLAR && bill.solarNetMetered == false && bill.solarPPAInPlace == false && editRow) {
                    4
                } else if (utilityType == UtilityType.SOLAR && bill.solarNetMetered == false && bill.solarPPAInPlace == true && !editRow) {
                    3
                } else if (utilityType == UtilityType.SOLAR && bill.solarNetMetered == false && bill.solarPPAInPlace == true && editRow) {
                    1
                } else {
                    1
                }
            sx {
                paddingBottom = 0.pct
                paddingTop = 0.pct
            }
        }
    }
    ConfirmationDialog {
        open = openDialog
        title = dialogTitle
        body = dialogBody
        actionResult = { value ->
            openDialog = false
            if (billDeletionDialogState) {
                confirmBillDeletion = value
                billDeletionDialogState = false
            }
            if (billStartEndDateValidationDialogState) {
                if (!value) {
                    clearBillStartAndEndDate(state, setState)
                }
                billStartEndDateValidationDialogState = false
            }
        }
    }
}