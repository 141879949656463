package com.ecosave.watch.portal.services.energymanagement

import com.ecosave.watch.portal.models.energymanagement.TargetUsageAndCost
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.BackendServerURL
import com.ecosave.watch.portal.models.energymanagement.FacilityTargetSummary
import com.ecosave.watch.portal.models.energymanagement.TargetSummary
import com.ecosave.watch.portal.services.httpClient
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*

suspend fun fetchTargetData(params: String): List<TargetUsageAndCost> {
    try {
        val response: HttpResponse = httpClient.get("$BackendServerURL/energy-management/target-data?${params}")
        if (response.status.equals(HttpStatusCode.OK)) {
            return response.body()
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return emptyList()
}

suspend fun fetchSelectedTargetData(params: String): TargetSummary {
    try {
        val response: HttpResponse = httpClient.get("$BackendServerURL/energy-management/target-utility?${params}")
        if (response.status == HttpStatusCode.OK) {
            return response.body()
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.messsage)
    }
    return TargetSummary("", emptyList())
}

suspend fun getFacilityTargetSummary(params: String): FacilityTargetSummary? {
    try {
        val response: HttpResponse = httpClient.get("$BackendServerURL/energy-management/target?${params}")
        if (response.status == HttpStatusCode.OK) {
            return response.body()
        }
    } catch (e: dynamic) {
        console.error(e)
    }
    return null
}

suspend fun saveTarget(data: FacilityTargetSummary): ApiCallStatus {
    try {
        val response: HttpResponse = httpClient.post("$BackendServerURL/energy-management/target") {
            contentType(ContentType.Application.Json)
            setBody(data)
        }
        if (response.status == HttpStatusCode.OK) {
            return ApiCallStatus.SUCCESS
        }
    } catch (e: dynamic) {
        console.error(e)
    }
    return ApiCallStatus.FAILURE
}