package com.ecosave.watch.portal.components.utilityaccounts

import com.ecosave.watch.portal.helpers.billing.UtilityDataCollectionMethod
import com.ecosave.watch.portal.helpers.common.ServicesCost
import com.ecosave.watch.portal.styles.UtilityAccountStyles
import js.core.jso
import mui.icons.material.InfoOutlined
import mui.material.Box
import mui.material.Tooltip
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.create
import react.dom.html.ReactHTML
import web.cssom.AlignItems
import web.cssom.Color
import web.cssom.Display
import web.cssom.FontWeight
import web.cssom.px

val BillDataCollectionLabelComponent = FC<Props> {
    Box {
        sx {
            display = Display.flex
            gap = 10.px
            alignItems = AlignItems.center
            margin = 20.px
        }
        Typography {
            sx {
                color = Color("white")
            }
            component = ReactHTML.h6
            variant = TypographyVariant.h6
            +"Utility Bill Data Collection Options"
        }
        Tooltip {
            classes = jso {
                tooltip = UtilityAccountStyles.OPTION_SELECTION_TOOLTIP.cssClass
            }
            title = ReactHTML.div.create {
                Typography {
                    sx {
                        marginTop = 20.px
                    }
                    Box {
                        sx {
                            fontWeight = FontWeight.bolder
                            display = Display.inline
                        }
                        +"1. ${UtilityDataCollectionMethod.MANUAL_UTILITY_DATA_ENTRY.description}: "
                    }
                    +"Enter your utility bills manually."
                }
                Typography {
                    sx {
                        marginTop = 20.px
                        marginBottom = 20.px
                    }
                    Box {
                        sx {
                            fontWeight = FontWeight.bolder
                            display = Display.inline
                        }
                        +"2. ${UtilityDataCollectionMethod.ECOSAVE_WATCH.description}: "
                    }
                    +"Allow Ecosave to automatically connect to your utility provider and gather utility bills for you automatically, if allowed by your utility.  Additional subscription of ${ServicesCost.ECOSAVE_WATCH_AUTOMATIC_DATA_COLLECTION}/month is required."
                }
            }
            InfoOutlined {
                sx {
                    fontSize = 20.px
                }
            }
        }
    }
}