package com.ecosave.watch.portal.components.energymanagement

import com.ecosave.watch.portal.helpers.facilitygroupmanagement.createFacilitiesObjectDataArray
import com.ecosave.watch.portal.helpers.facilitygroupmanagement.facilitiesColumns
import com.ecosave.watch.portal.models.facilitygroupmanagement.FacilityGroup
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableContainer
import mui.material.TableHead
import mui.material.TableRow
import mui.system.sx
import react.FC
import react.Props
import react.useEffect
import react.useState
import web.cssom.px

external interface StickyTableProps : Props {
    var selectedGroupProp: FacilityGroup?
}

val StickyTable = FC<StickyTableProps> { props ->

    var selectedGroupFacilities by useState(emptyArray<dynamic>())
    val selectedGroup = props.selectedGroupProp

    useEffect(selectedGroup?.facilities) {
        selectedGroup?.facilities?.let {
            selectedGroupFacilities = createFacilitiesObjectDataArray(it)
        }
    }

    TableContainer {
        sx {
            height = 520.px
        }
        Table {
            stickyHeader = true
            TableHead {
                TableRow {
                    hover = true
                    facilitiesColumns.forEach { col ->
                        TableCell {
                            key = col.id
                            +"${col.header}"
                        }
                    }
                }
            }
            TableBody {
                selectedGroupFacilities.forEach { item ->
                    TableRow {
                        hover = true
                        facilitiesColumns.forEach { col ->
                            TableCell {
                                key = col.id
                                +"${item["${col.accessorKey}"]}"
                            }
                        }
                    }
                }
            }
        }
    }
}