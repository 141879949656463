package com.ecosave.watch.portal.components.esg.forcedorcompulsorylabor

import com.ecosave.watch.portal.components.esg.EsgCoreComponentProps
import com.ecosave.watch.portal.components.esg.GenericSection_3_3
import com.ecosave.watch.portal.components.esg.formcontrols.GRIStandardName
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.EsgSection
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import com.ecosave.watch.portal.helpers.esg.failureErrorMessage
import com.ecosave.watch.portal.helpers.esg.omitDisclosureFromUI
import com.ecosave.watch.portal.helpers.esg.resetPreviousPatchState
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.esg.CurrentInputState
import com.ecosave.watch.portal.models.esg.EsgCollectionAddRow
import com.ecosave.watch.portal.models.esg.EsgCollectionDeleteRow
import com.ecosave.watch.portal.models.esg.ForcedOrCompulsoryLaborIncident
import com.ecosave.watch.portal.models.esg.ForcedOrCompulsoryLaborState
import com.ecosave.watch.portal.models.esg.RowPatch
import com.ecosave.watch.portal.services.esg.addDynamicRowOrSection
import com.ecosave.watch.portal.services.esg.deleteDynamicRowOrSection
import kotlinx.coroutines.launch
import kotlinx.serialization.json.JsonPrimitive
import mui.material.Box
import react.FC
import react.dom.events.FocusEvent
import react.dom.events.FormEvent
import web.html.HTMLDivElement
import web.html.HTMLElement
import web.html.HTMLTextAreaElement
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles as styles


val ForcedOrCompulsoryLaborComponent = FC<EsgCoreComponentProps> { props ->

    val reportState = props.reportState
    val setReportState = props.setReportState

    reportState.report?.forcedOrCompulsoryLabor?.let { state ->

        fun stateSetter(state: ForcedOrCompulsoryLaborState) {
            setReportState(
                reportState.copy(
                    report = reportState.report.copy(
                        forcedOrCompulsoryLabor = state
                    )
                )
            )
        }

        val addRowOrSection: (String) -> Unit = { fieldName ->
            resetPreviousPatchState(props.setInputState)
            mainScope.launch {
                props.setAddDeleteRowOrSection(DynamicTableAction.ADD)
                props.setAddDeleteRowOrSectionApiCallInProgress(true)
                val apiCallStatus = addDynamicRowOrSection(
                    EsgCollectionAddRow(
                        objectName = EsgSection.FORCED_OR_COMPULSORY_LABOR.name,
                        reportFileName = reportState.reportFileName,
                        patchCollection = RowPatch(
                            pathFirst = fieldName
                        )
                    )
                )
                if (apiCallStatus == ApiCallStatus.SUCCESS) {
                    val newState = state.copy()

                    when (fieldName) {
                        ForcedOrCompulsoryLaborState::forcedOrCompulsoryLaborList.name -> {
                            newState.forcedOrCompulsoryLaborList.add(ForcedOrCompulsoryLaborIncident())
                            props.setNotificationMessage(ReportConstants.ADD_ROW_NOTIFICATION_MESSAGE)
                        }
                    }
                    stateSetter(newState)
                    props.setNotificationStatus(NotificationStatus.SUCCESS)
                    props.setOpenAlertNotifications(true)
                } else {
                    failureErrorMessage(
                        props.setNotificationMessage,
                        props.setNotificationStatus,
                        props.setOpenAlertNotifications
                    )
                }
                props.setAddDeleteRowOrSectionApiCallInProgress(false)
            }
        }

        val deleteRowOrSection: (String, Int) -> Unit = { fieldName, index ->
            resetPreviousPatchState(props.setInputState)
            mainScope.launch {
                props.setAddDeleteRowOrSection(DynamicTableAction.DELETE)
                props.setAddDeleteRowOrSectionApiCallInProgress(true)
                val apiCallStatus = deleteDynamicRowOrSection(
                    EsgCollectionDeleteRow(
                        objectPatchEnum = EsgSection.FORCED_OR_COMPULSORY_LABOR,
                        reportFileName = reportState.reportFileName,
                        pathFirst = fieldName,
                        indexAt = index
                    )
                )
                if (apiCallStatus == ApiCallStatus.SUCCESS) {
                    val newState = state.copy()

                    when (fieldName) {
                        newState::forcedOrCompulsoryLaborList.name -> {
                            newState.forcedOrCompulsoryLaborList.removeAt(index)
                            props.setNotificationMessage(ReportConstants.DELETE_ROW_NOTIFICATION_MESSAGE)
                        }
                    }
                    stateSetter(newState)
                    props.setNotificationStatus(NotificationStatus.SUCCESS)
                    props.setOpenAlertNotifications(true)
                } else {
                    failureErrorMessage(
                        props.setNotificationMessage,
                        props.setNotificationStatus,
                        props.setOpenAlertNotifications
                    )
                }
                props.setAddDeleteRowOrSectionApiCallInProgress(false)
            }
        }


        val onChangeTextArea: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value
            val newState = state.copy()

            when (propertyName) {
                state::section3_3_409A1.name -> newState.section3_3_409A1 = propertyValue
                state::section3_3_409A2.name -> newState.section3_3_409A2 = propertyValue
                state::section3_3_409A3.name -> newState.section3_3_409A3 = propertyValue
                state::section3_3_409A4.name -> newState.section3_3_409A4 = propertyValue
                state::section3_3_409A5.name -> newState.section3_3_409A5 = propertyValue
                state::section3_3_409A6.name -> newState.section3_3_409A6 = propertyValue

                state::section4091Field1.name -> newState.section4091Field1 = propertyValue
            }

            if (field == state::forcedOrCompulsoryLaborList.name && changeIndex != null) {

                newState.forcedOrCompulsoryLaborList[changeIndex].let {
                    when (propertyName) {
                        ForcedOrCompulsoryLaborIncident::section4091A1.name -> it.section4091A1 = propertyValue
                        ForcedOrCompulsoryLaborIncident::section4091A2.name -> it.section4091A2 = propertyValue
                    }
                }

            }

            stateSetter(newState)
            props.setInputState(
                CurrentInputState(
                    parentPath = field,
                    childPath = propertyName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue),
                    objectName = EsgSection.FORCED_OR_COMPULSORY_LABOR.name,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurTextArea: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value
            props.onBlurApiCall(
                propertyName,
                JsonPrimitive(propertyValue),
                changeIndex,
                field,
                EsgSection.FORCED_OR_COMPULSORY_LABOR.name
            )
        }

        Box {
            className = styles.SECTION_MAIN_LAYOUT.cssClass

            GRIStandardName {
                griStandard = EsgSection.FORCED_OR_COMPULSORY_LABOR
            }

            GenericSection_3_3 {
                this.onBlurTextArea = onBlurTextArea
                this.onChangeTextArea = onChangeTextArea
                materialTopicName = "Forced or Compulsory Labor"
                section3_3_A1_name = state::section3_3_409A1.name
                section3_3_A1_value = state.section3_3_409A1

                section3_3_A2_name = state::section3_3_409A2.name
                section3_3_A2_value = state.section3_3_409A2

                section3_3_A3_name = state::section3_3_409A3.name
                section3_3_A3_value = state.section3_3_409A3

                section3_3_A4_name = state::section3_3_409A4.name
                section3_3_A4_value = state.section3_3_409A4

                section3_3_A5_name = state::section3_3_409A5.name
                section3_3_A5_value = state.section3_3_409A5

                section3_3_A6_name = state::section3_3_409A6.name
                section3_3_A6_value = state.section3_3_409A6
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_409_1)) {
                GRI_409_1 {
                    this.esgReportState = reportState
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeTextArea = onChangeTextArea
                    this.addRowOrSection = addRowOrSection
                    this.deleteRowOrSection = deleteRowOrSection
                }
            }
        }
    }
}