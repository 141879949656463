package com.ecosave.watch.portal.components.esg.indirecteconomicimpacts

import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.IndirectEconomicImpactsField
import com.ecosave.watch.portal.models.esg.IndirectEconomicImpactsState
import com.ecosave.watch.portal.models.esg.InfrastructureInvestments
import com.ecosave.watch.portal.models.esg.ServicesSupported
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import com.ecosave.watch.portal.styles.esg.IndirectEconomicImpacts
import mui.material.Box
import react.FC
import react.Props
import react.dom.events.FormEvent
import react.dom.onChange
import web.html.HTMLDivElement

external interface Section203_1Props : Props {
    var state: IndirectEconomicImpactsState
    var stateSetter: (IndirectEconomicImpactsState) -> Unit
    var onChange: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit
    var addRow: (String) -> Unit
    var deleteRow: (String, Int) -> Unit
}

val Section203_1 = FC<Section203_1Props> { props ->

    val onChangeFunction = props.onChange
    val state = props.state
    val stateSetter = props.stateSetter

    DisclosureName {
        disclosure = GriDisclosureTitles.GRI_203_1
    }

    Box {
        className = ESGCommonStyles.TABLE_WRAPPER.cssClass
        Box {
            className = ESGCommonStyles.SECTION_MAIN_LAYOUT.cssClass
            DynamicTableWrapper {
                addRowOrSection = {
                    props.addRow(IndirectEconomicImpactsField.INFRASTRUCTURE_INVESTMENT_LIST.fieldName)
                    state.infrastructureInvestmentList.add(InfrastructureInvestments())
                    stateSetter(state)
                }
                items = state.infrastructureInvestmentList
                action = DynamicTableAction.ADD
                Box {
                    className = IndirectEconomicImpacts.SECTION_203_1_TABLE.cssClass
                    TableHeader {
                        header = "Developments of significant infrastructure investments"
                    }
                    TableHeader {
                        header = "Current or expected impacts on communities and local economies"
                    }
                    TableHeader {
                        header = "Commercial, in-kind, or pro bono engagements?"
                    }
                }
            }
            state.infrastructureInvestmentList.forEachIndexed{index, it ->
                DynamicTableWrapper {
                    deleteRowOrSection = {
                        props.deleteRow(IndirectEconomicImpactsField.INFRASTRUCTURE_INVESTMENT_LIST.fieldName,index)
                        state.infrastructureInvestmentList.removeAt(index)
                        stateSetter(state)
                    }
                    items = state.infrastructureInvestmentList
                    action = DynamicTableAction.DELETE
                    Box {
                        key =  "index$index"
                        className = IndirectEconomicImpacts.SECTION_203_1_TABLE_INNER.cssClass
                        ESGTextAreaFieldWrapper {
                            name = IndirectEconomicImpactsField.SECTION_2031PART1A1.name
                            value = it.section2031Part1A1
                            onChange = { event -> onChangeFunction(event, index, IndirectEconomicImpactsField.INFRASTRUCTURE_INVESTMENT_LIST.fieldName) }
                        }
                        ESGTextAreaFieldWrapper {
                            name = IndirectEconomicImpactsField.SECTION_2031PART1A2.name
                            value = it.section2031Part1A2
                            onChange = { event -> onChangeFunction(event, index,IndirectEconomicImpactsField.INFRASTRUCTURE_INVESTMENT_LIST.fieldName) }
                        }
                        ESGTextAreaFieldWrapper {
                            name = IndirectEconomicImpactsField.SECTION_2031PART1A3.name
                            value = it.section2031Part1A3
                            onChange = { event -> onChangeFunction(event, index, IndirectEconomicImpactsField.INFRASTRUCTURE_INVESTMENT_LIST.fieldName) }
                        }
                    }
                }
            }
        }
    }

    Box {
        className = ESGCommonStyles.TABLE_WRAPPER.cssClass
        Box {
            className = ESGCommonStyles.SECTION_MAIN_LAYOUT.cssClass
            DynamicTableWrapper {
                addRowOrSection = {
                    props.addRow(IndirectEconomicImpactsField.SERVICE_SUPPORTED_LIST.fieldName)
                    state.servicesSupportedList.add(ServicesSupported())
                    stateSetter(state)
                }
                items = state.servicesSupportedList
                action = DynamicTableAction.ADD
                Box {
                    className = IndirectEconomicImpacts.SECTION_203_1_TABLE.cssClass
                    TableHeader {
                        header = "Developments of significant services supported"
                    }
                    TableHeader {
                        header = "Current or expected impacts on communities and local economies"
                    }
                    TableHeader {
                        header = "Commercial, in-kind, or pro bono engagements?"
                    }
                }
            }
            state.servicesSupportedList.forEachIndexed{index, it ->
                DynamicTableWrapper {
                    deleteRowOrSection = {
                        props.deleteRow(IndirectEconomicImpactsField.SERVICE_SUPPORTED_LIST.fieldName,index)
                        state.servicesSupportedList.removeAt(index)
                        stateSetter(state)
                    }
                    items = state.servicesSupportedList
                    action = DynamicTableAction.DELETE
                    Box {
                        key = "index$index"
                        className = IndirectEconomicImpacts.SECTION_203_1_TABLE.cssClass
                        ESGTextAreaFieldWrapper {
                            name = IndirectEconomicImpactsField.SECTION_2031PART2A1.name
                            value = it.section2031Part2A1
                            onChange = { event ->
                                onChangeFunction(
                                    event,
                                    index,
                                    IndirectEconomicImpactsField.SERVICE_SUPPORTED_LIST.fieldName
                                )
                            }
                        }
                        ESGTextAreaFieldWrapper {
                            name = IndirectEconomicImpactsField.SECTION_2031PART2A2.name
                            value = it.section2031Part2A2
                            onChange = { event ->
                                onChangeFunction(
                                    event,
                                    index,
                                    IndirectEconomicImpactsField.SERVICE_SUPPORTED_LIST.fieldName
                                )
                            }
                        }
                        ESGTextAreaFieldWrapper {
                            name = IndirectEconomicImpactsField.SECTION_2031PART2A3.name
                            value = it.section2031Part2A3
                            onChange = { event ->
                                onChangeFunction(
                                    event,
                                    index,
                                    IndirectEconomicImpactsField.SERVICE_SUPPORTED_LIST.fieldName
                                )
                            }
                        }
                    }
                }
            }
        }
    }
}