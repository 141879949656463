package com.ecosave.watch.portal.components.energymanagement

import com.ecosave.watch.portal.helpers.Colors
import com.ecosave.watch.portal.helpers.energymanagement.getName
import react.FC
import react.Props
import mui.material.Box
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import web.cssom.Color
import web.cssom.px
import com.ecosave.watch.portal.styles.EnergyManagementStyles as styles

external interface RankingTooltipContentProps: Props {
    var active: Boolean
    var payload: Array<dynamic>
    var label: String?
}

val RankingTooltipContent = FC<RankingTooltipContentProps> {props ->
    if (props.active && !props.payload.isNullOrEmpty() && props.label is String) {
        val item = props.payload[0]
        val data = getName(props.payload[0].dataKey)
        Box {
            className = styles.TOOLTIP_CONTENT.cssClass
            Typography {
                sx {
                    color = Color(Colors.WHITE)
                }
                variant = TypographyVariant.h6
                +props.label
            }
            Typography {
                sx {
                    marginTop = 2.px
                    color = Color(item.fill)
                }
                +"${data.name}: ${item.value} ${data.unit}"
            }
        }
    }
}