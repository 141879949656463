package com.ecosave.watch.portal

import com.ecosave.watch.portal.helpers.Colors
import js.core.jso
import mui.material.PaletteMode
import mui.material.styles.createTheme
import web.cssom.Color
import web.cssom.rgb

object Themes {
    val premiumGreen = rgb(150, 198, 119, 1.0)
    val ecosaveDarkLogoBG = rgb(23, 58, 84, 1.0)
    val ecosaveGrayAccent = rgb(122, 141, 151)
    val ecosaveOrangeAccent = rgb(196, 87, 44)

    val lightTheme = createTheme(
        jso {
            typography = jso {
                fontFamily = "\"DM Sans\", \"Proxima Soft Medium\", \"Proxima Soft Bold\", \"Gibson Light\", serif"
            }
            palette = jso {
                mode = PaletteMode.light
                text = jso {
                    primary = Color(Colors.MAIN_BLUE)
                    secondary = Color(Colors.MID_BLUE)
                }
                background = jso {
                    default = Colors.WHITE_SMOKE
                    paper = Colors.WHITE
                }
            }
            components = jso {
                MuiTableCell = jso {
                    styleOverrides = jso {
                        root = jso {
                            color = Colors.MAIN_BLUE
                        }
                    }
                }

                MuiButton = jso {
                    styleOverrides = jso {
                        contained = jso<dynamic> {
                            background = "linear-gradient(90deg, #00AEB5 0%, #00AEB5 33%, #88C86D 66%, #88C86D 100%)"
                        }
                        text = jso<dynamic> {
                            color = Colors.DARK_BLUE
                        }
                        outlined = jso {
                            color = Colors.DARK_BLUE
                        }
                    }
                }

                MuiAppBar = jso {
                    styleOverrides = jso {
                        root = jso {
                            backgroundColor = Colors.MAIN_BLUE
                        }
                    }
                }

            }
        }
    )

    val darkTheme = createTheme(
        jso {
            typography = jso {
                fontFamily = "\"DM Sans\", \"Proxima Soft Medium\", \"Proxima Soft Bold\", \"Gibson Light\", serif"
            }
            palette = jso {
                mode = PaletteMode.dark
                text = jso {
                    primary = Color(Colors.WHITE)
                    secondary = Color(Colors.LIGHT_GRAY)
                }
                background = jso {
                    default = Colors.DARK_BLUE
                    paper = Colors.MAIN_BLUE
                }
                divider = Colors.SILVER_GRAY
            }
            components = jso {
                MuiTableCell = jso {
                    styleOverrides = jso {
                        root = jso {
                            color = Colors.WHITE
                        }
                    }
                }

                MuiButton = jso {
                    styleOverrides = jso {
                        contained = jso<dynamic> {
                            background = "linear-gradient(90deg, #00AEB5 0%, #00AEB5 33%, #88C86D 66%, #88C86D 100%)"
                        }
                        text = jso<dynamic> {
                            color = Colors.WHITE
                        }
                        outlined = jso {
                            color = Colors.WHITE
                        }
                    }
                }

                MuiAppBar = jso {
                    styleOverrides = jso {
                        root = jso {
                            backgroundColor = Colors.MAIN_BLUE
                        }
                    }
                }

                MuiPaper = jso {
                    styleOverrides = jso {
                        root = jso {
                            backgroundImage = "initial"
                        }
                    }
                }
            }
        }
    )
}