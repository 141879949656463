package com.ecosave.watch.portal.components.esg.generaldisclosures.organizationanditsreportingpractices

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange


val Disclosure_2_3 = FC<EsgSubFormComponentProps> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.theOrgAndItsRepPractices?.let { state ->

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_2_3
        }

        Box {
            label {
                +"What is the reporting period?"
            }
            ESGTextAreaFieldWrapper {
                name = state::section23Field1.name
                value = state.section23Field1
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"How frequent is the reporting period?"
            }
            ESGTextAreaFieldWrapper {
                name = state::section23Field2.name
                value = state.section23Field2
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"Is the reporting period for financial reporting different? If so, why?"
            }
            ESGTextAreaFieldWrapper {
                name = state::section23Field3.name
                value = state.section23Field3
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"Publication date of the report."
            }
            ESGTextAreaFieldWrapper {
                name = state::section23Field4.name
                value = state.section23Field4
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"Contact point for questions about the report."
            }
            ESGTextAreaFieldWrapper {
                name = state::section23Field5.name
                value = state.section23Field5
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

    }
}