package com.ecosave.watch.portal.models.usermanagement

import kotlinx.serialization.Serializable

@Serializable
data class RegisterCompanyOrUserState(
    var firstName: String = "",
    var lastName: String = "",
    var companyName: String = "",
    var emailAddress: String = "",
    var confirmEmailAddress: String = "",
    var jobTitle: String = "",
    var password: String = "",
    var confirmPassword: String = "",
    var agreeTerms: Boolean = false
)