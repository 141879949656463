package com.ecosave.watch.portal.components.workorder

import com.ecosave.watch.portal.components.common.MultipleAutoComplete
import com.ecosave.watch.portal.components.common.PopoverWrapper
import com.ecosave.watch.portal.helpers.common.AutoCompleteOption
import com.ecosave.watch.portal.helpers.workorder.WorkOrderConstants.ActionsUtilityTypeOptions
import com.ecosave.watch.portal.helpers.workorder.WorkOrderConstants.SeverityOptions
import com.ecosave.watch.portal.models.common.PopoverPos
import com.ecosave.watch.portal.models.workorder.ObservationsFilterDataClass
import com.ecosave.watch.portal.styles.CommonStyles
import mui.icons.material.FilterList
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.FormControlVariant
import mui.material.Size
import mui.material.TextField
import mui.material.Typography
import mui.system.sx
import react.FC
import react.Props
import react.StateSetter
import react.dom.aria.ariaDescribedBy
import react.dom.onChange
import react.useEffect
import react.useState
import web.cssom.Display
import web.cssom.JustifyContent
import web.cssom.Position
import web.cssom.px
import web.html.HTMLButtonElement
import web.html.HTMLInputElement
import web.html.InputType

external interface ObservationsFilterComponentProps : Props {
    var currentFilterOptions: ObservationsFilterDataClass
    var currentFilterOptionsStateSetter: StateSetter<ObservationsFilterDataClass>
    var eventFilterOptions: Array<AutoCompleteOption>
    var facilityFilterOptions: Array<AutoCompleteOption>
    var accountNumberFilterOptions: Array<AutoCompleteOption>
    var filtersCount: Int
}

val ObservationsFilterComponent = FC<ObservationsFilterComponentProps> { props ->

    val currentFilterOptions = props.currentFilterOptions
    val (filterState, filterStateSetter) = useState(currentFilterOptions)
    var bAnchorEl: HTMLButtonElement? by useState(null)
    val isOpen = bAnchorEl != null

    useEffect(currentFilterOptions) {
        filterStateSetter(currentFilterOptions)
    }

    Box {
        Box {
            sx {
                display = Display.flex
                justifyContent = JustifyContent.end
            }
            Button {
                sx {
                    position = Position.relative
                }
                variant = ButtonVariant.outlined
                endIcon = endIcon.also {
                    FilterList()
                }
                onClick = {
                    bAnchorEl = it.currentTarget
                }
                ariaDescribedBy = "filter-popover"
                +"Filter"
                if (props.filtersCount > 0) {
                    Box {
                        className = CommonStyles.BADGE.cssClass
                        +"${props.filtersCount}"
                    }
                }

            }
        }
        PopoverWrapper {
            id = "filter-popover"
            open = isOpen
            anchorEl = bAnchorEl
            onClose = { _, _ ->
                bAnchorEl = null
            }
            anchorOrigin = PopoverPos("bottom", "right")
            transformOrigin = PopoverPos("top", "right")
            Box {
                sx {
                    padding = 30.px
                    width = 450.px
                }
                Box {
                    sx {
                        marginBottom = 20.px
                    }
                    Typography {
                        +"Facility"
                    }
                    MultipleAutoComplete {
                        name = "facility"
                        options = props.facilityFilterOptions
                        value = filterState.facility
                        onSelect = {
                            filterStateSetter(
                                filterState.copy(
                                    facility = it
                                )
                            )
                        }
                    }
                }
                Box {
                    sx {
                        marginBottom = 20.px
                    }
                    Typography {
                        +"Account Number"
                    }
                    MultipleAutoComplete {
                        name = "accountNumber"
                        options = props.accountNumberFilterOptions
                        value = filterState.accountNumber
                        onSelect = {
                            filterStateSetter(
                                filterState.copy(
                                    accountNumber = it
                                )
                            )
                        }
                    }
                }
                Box {
                    sx {
                        marginBottom = 20.px
                    }
                    Typography {
                        +"Utility"
                    }
                    MultipleAutoComplete {
                        name = "utility"
                        options = ActionsUtilityTypeOptions
                        value = filterState.utility
                        onSelect = {
                            filterStateSetter(
                                filterState.copy(
                                    utility = it
                                )
                            )
                        }
                    }
                }
                Box {
                    sx {
                        marginBottom = 20.px
                    }
                    Typography {
                        +"Severity"
                    }
                    MultipleAutoComplete {
                        name = "severity"
                        options = SeverityOptions
                        value = filterState.severity
                        onSelect = {
                            filterStateSetter(
                                filterState.copy(
                                    severity = it
                                )
                            )
                        }
                    }
                }
                Box {
                    sx {
                        marginBottom = 20.px
                    }
                    Typography {
                        +"Event"
                    }
                    MultipleAutoComplete {
                        name = "event"
                        options = props.eventFilterOptions
                        value = filterState.event
                        onSelect = {
                            filterStateSetter(
                                filterState.copy(
                                    event = it
                                )
                            )
                        }
                    }
                }
                Box {
                    sx {
                        marginBottom = 20.px
                    }
                    Typography {
                        +"Generated Date"
                    }
                    TextField {
                        variant = FormControlVariant.outlined
                        fullWidth = true
                        type = InputType.date
                        size = Size.small
                        value = filterState.generatedDate
                        onChange = {
                            val target = it.target as HTMLInputElement
                            filterStateSetter(
                                filterState.copy(
                                    generatedDate = target.value
                                )
                            )
                        }
                    }
                }
                Box {
                    sx {
                        display = Display.flex
                        justifyContent = JustifyContent.end
                        marginTop = 10.px
                    }
                    Button {
                        sx {
                            marginRight = 20.px
                        }
                        variant = ButtonVariant.text
                        onClick = {
                            props.currentFilterOptionsStateSetter(ObservationsFilterDataClass())
                        }
                        +"Clear All"
                    }
                    Button {
                        variant = ButtonVariant.contained
                        onClick = {
                            bAnchorEl = null
                            props.currentFilterOptionsStateSetter(filterState)
                        }
                        +"Search"
                    }
                }
            }
        }
    }
}
