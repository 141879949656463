package com.ecosave.watch.portal.components.esg.marketingandlabeling

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.Label
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.onChange

val GRI_417_3 = FC<EsgSubFormComponentProps> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea
    val onChangeNumber = props.onChangeNumber
    val onBlurNumber = props.onBlurNumber

    props.esgReportState.report?.marketingAndLabeling?.let { state ->
        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_417_3
        }
        DisclosureSubHeading {
            subHeading =
                "Exclude incidents of non-compliance in which the organization was determined not to be at fault."
        }

        Box {
            className = ESGCommonStyles.THREE_COLUMN_TABLE.cssClass
            TableHeader {
                header = ""
            }
            TableHeader {
                header = "Current Year"
            }
            TableHeader {
                header = "Previous Year(s)"
            }
            Label {
                label = "Total number of incidents of non-compliance with regulations resulting in a fine or penalty."
            }
            ESGNumberFieldWrapper {
                name = state::section4173A1.name
                value = state.section4173A1
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section4173B1.name
                value = state.section4173B1
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            Label {
                label = "Total number of incidents of non-compliance with regulations resulting in a warning."
            }
            ESGNumberFieldWrapper {
                name = state::section4173A2.name
                value = state.section4173A2
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section4173B2.name
                value = state.section4173B2
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            Label {
                label = "Total number of incidents of non-compliance with voluntary codes."
            }
            ESGNumberFieldWrapper {
                name = state::section4173A3.name
                value = state.section4173A3
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section4173B3.name
                value = state.section4173B3
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            Label {
                label =
                    "Total number of incidents of non-compliance with regulations and/or voluntary codes concerning marketing communications, including advertising, promotion, and sponsorship."
            }
            ESGNumberFieldWrapper {
                name = state::section4173A4.name
                value = state.section4173A4
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section4173B4.name
                value = state.section4173B4
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            Label {
                label =
                    "If the organization has not identified any non-compliance with regulations and/or voluntary codes, a brief statement of this fact is sufficient."
            }
            ESGTextAreaFieldWrapper {
                name = state::section4173Field1.name
                value = state.section4173Field1
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
            ESGTextAreaFieldWrapper {
                name = state::section4173Field2.name
                value = state.section4173Field2
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}