package com.ecosave.watch.portal.models.esg
import kotlinx.serialization.Serializable
@Serializable
data class CustomerHealthAndSafetyState(
    var section4161A1: String? = null,
    var section4162A1: Long? = null,
    var section4162A2: Long? = null,
    var section4162A3: Long? = null,
    var section4162A4: Long? = null,

    var section4162B1: Long? = null,
    var section4162B2: Long? = null,
    var section4162B3: Long? = null,
    var section4162B4: Long? = null,
    var section4162Field1: String? = null,

    var section3_3_416A1: String? = null,
    var section3_3_416A2: String? = null,
    var section3_3_416A3: String? = null,
    var section3_3_416A4: String? = null,
    var section3_3_416A5: String? = null,
    var section3_3_416A6: String? = null
)
