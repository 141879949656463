package com.ecosave.watch.portal.components.privacypolicy

import react.FC
import react.Props
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.ul

val PersonalInformationDefinitions = FC<Props> {
    HeadingWrapper {
        heading = "Personal Information Definitions"
    }
    TypographyWrapper {
        +"For purposes of this Privacy Statement, “Personal Information” means information from or about you that identifies you directly and information that is associated with you and thus could potentially identify you, including when combined with other information from or about you."
    }
    HeadingWrapper {
        heading = "Types of Information Collected"
        subHeading = true
    }
    TypographyWrapper {
        ul {
            li {
                +"Names"
            }
            li {
                +"Social Media Handles"
            }
            li {
                +"Messenger App Handles"
            }
            li {
                +"Physical address"
            }
            li {
                +"Email addresses"
            }
            li {
                +"Telephone numbers"
            }
            li {
                +"Business contact information, including names, email addresses, business addresses, telephone numbers, company name or business affiliation, and title"
            }
            li {
                +"Account Information"
            }
        }
    }
    HeadingWrapper {
        heading = "User IDs and passwords"
        subHeading = true
    }
    TypographyWrapper {
        ul {
            li {
                +"Personal information that you choose to share within our user communities"
            }
            li {
                +"Payment card and financial account information"
            }
            li {
                +"Identifiers of devices used to access our Services"
            }
            li {
                +"Data Collection"
            }
            li {
                +"Voluntary Collection"
            }
        }
    }
    HeadingWrapper {
        heading = "Account and Profile Information:"
        subHeading = true
    }
    TypographyWrapper {
        +"We collect information about you and your company when you register for an account, create or modify your profile, and make purchases through our Services. Information we collect includes your name, username, address, email address, phone number, and payment card details. You may provide this information directly through our Services or in some cases another user (such as an account administrator) creating an account on your behalf may provide it. If you provide information (including Personal Information) about someone else, you confirm that you have the authority to act for them and to consent to the collection and use of their Personal Information as described in this Privacy Policy."
    }
    HeadingWrapper {
        heading = "Content"
        subHeading = true
    }
    TypographyWrapper {
        +"We collect and store content that you create, input, submit, post, upload, transmit, or store while using our Services. Such content may include any personal or other sensitive information submitted using our Services, such as Health Insurance Portability and Accountability Act (HIPAA) protected health information, European Union (EU) personal data, and other information such as source code or regulatory compliance materials."
    }
    HeadingWrapper {
        heading = "Other submissions"
        subHeading = true
    }
    TypographyWrapper {
        +"We collect other data that you may submit to our Services or to us directly, such as when you request customer support or communicate with us via email or social media Sites."
    }
}
