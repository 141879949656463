package com.ecosave.watch.portal.models.esg

import react.ReactNode


data class EsgReportValidationState(
    var reportFileNameErrorState: Boolean = false,
    var reportFileNameErrorMessage: ReactNode? = null,
    var reportTitleErrorState: Boolean = false,
    var reportTitleErrorMessage: ReactNode? = null,
    var companyNameErrorState: Boolean = false,
    var companyNameErrorMessage: ReactNode? = null,
    var reportFinancialYear: ReportFinancialYearValidationState
)

data class ReportFinancialYearValidationState(
    var financialYearErrorState: Boolean = false,
    var financialYearErrorMessage: ReactNode? = null,
    var calendarDateStartErrorState: Boolean = false,
    var calendarDateStartErrorMessage: ReactNode? = null,
    var calendarDateEndErrorState: Boolean = false,
    var calendarDateEndErrorMessage: ReactNode? = null
)

