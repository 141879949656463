package com.ecosave.watch.portal.components.esg.generaldisclosures.governance

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val Disclosure_2_11 = FC<EsgSubFormComponentProps> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.governance?.let { state ->

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_2_11
        }

        Box {
            label {
                +"Whether the chair of the highest governance body is also a senior executive in the organization."
            }
            ESGTextAreaFieldWrapper {
                name = state::section211A1.name
                value = state.section211A1 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"if the chair is also a senior executive, explain their function within the organization’s management, the reasons for this arrangement, and how conflicts of interest are prevented and mitigated."
            }
            ESGTextAreaFieldWrapper {
                name = state::section211A2.name
                value = state.section211A2 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}