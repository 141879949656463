package com.ecosave.watch.portal.components.privacypolicy

import com.ecosave.watch.portal.helpers.Colors
import emotion.react.css
import react.FC
import react.Props
import react.router.dom.Link
import web.cssom.Color
import web.cssom.Cursor
import web.cssom.FontWeight
import web.cssom.TextDecoration
import web.window.WindowTarget

external interface CustomLinkProps : Props {
    var text: String
    var to: String
}

val CustomLink = FC<CustomLinkProps> { props ->
    Link {
        to = props.to
        target = WindowTarget._blank
        css {
            color = Color(Colors.PURPLE)
            cursor = Cursor.pointer
            fontWeight = FontWeight.bold
            textDecoration = TextDecoration.solid
            hover {
                color = Color(Colors.PERIWINKLE_BLUE)
            }
        }
        +props.text
    }
}