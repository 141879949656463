package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class MaterialsState(
    var section3011A1: String? = null,
    var section3011A2: String? = null,
    var section3011A3: String? = null,

    var section3011B1: Double? = null,
    var section3011B2: Double? = null,
    var section3011B3: Double? = null,

    var section3011C1: Double? = null,
    var section3011C2: Double? = null,
    var section3011C3: Double? = null,

    var section3012Unit1: String? = null,
    var section3012Unit2: String? = null,

    var section3012Current1: Double? = null,
    var section3012Current2: Double? = null,
    var section3012Current3: Double? = null,

    var section3013Unit1: String? = null,
    var section3013Unit2: String? = null,

    var section3013Current1: Double? = null,
    var section3013Current2: Double? = null,
    var section3013Current3: Double? = null,

    var section3013Field1: String? = null,

    var section3_3_301A1: String? = null,
    var section3_3_301A2: String? = null,
    var section3_3_301A3: String? = null,
    var section3_3_301A4: String? = null,
    var section3_3_301A5: String? = null,
    var section3_3_301A6: String? = null
)