package com.ecosave.watch.portal.components.energymanagement

import com.ecosave.watch.portal.components.common.ApiFailedMessage
import com.ecosave.watch.portal.components.common.ApiLazyRender
import com.ecosave.watch.portal.components.npm.Cell
import com.ecosave.watch.portal.components.npm.Legend
import com.ecosave.watch.portal.components.npm.Pie
import com.ecosave.watch.portal.components.npm.PieChart
import com.ecosave.watch.portal.components.npm.ResponsiveContainer
import com.ecosave.watch.portal.helpers.Colors
import com.ecosave.watch.portal.helpers.energymanagement.EVENT_COLORS
import com.ecosave.watch.portal.models.energymanagement.ActionCount
import com.ecosave.watch.portal.models.energymanagement.Event
import com.ecosave.watch.portal.models.energymanagement.EventType
import com.ecosave.watch.portal.styles.ControlCenterPageStyles
import com.ecosave.watch.portal.styles.EnergyManagementStyles
import js.core.jso
import mui.material.Box
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML
import web.cssom.Color
import web.cssom.TextAlign

external interface EventPieChartProps : Props {
    var data: ActionCount
    var dataFetched: Boolean
    var dataError: Boolean
}

val EventPieChart = FC<EventPieChartProps> { props ->
    val isNotEmpty =
        props.data.actionUnassigned > 0 || props.data.actionAssigned > 0 || props.data.actionInProgress > 0 || props.data.actionCompleted > 0
    val events = arrayOf<Event>(
        jso {
            name = EventType.UN_ASSIGNED.label
            value = props.data.actionUnassigned
        },
        jso {
            name = EventType.ASSIGNED.label
            value = props.data.actionAssigned
        },
        jso {
            name = EventType.IN_PROGRESS.label
            value = props.data.actionInProgress
        },
        jso {
            name = EventType.COMPLETED.label
            value = props.data.actionCompleted
        }
    )
    Typography {
        variant = TypographyVariant.h6
        component = ReactHTML.h6
        sx {
            textAlign = TextAlign.center
        }
        +"Actions"
    }
    Box {
        className = EnergyManagementStyles.SUMMARY_PIE_CHART.cssClass

        ApiLazyRender {
            dataFetched = props.dataFetched
            ResponsiveContainer {
                width = "100%"
                height = "100%"
                PieChart {
                    width = "100%"
                    height = "100%"
                    Pie {
                        data = JSON.parse(JSON.stringify(events))
                        cx = "50%"
                        cy = "50%"
                        labelLine = false
                        label = true
                        fill = Colors.PERIWINKLE_BLUE
                        nameKey = "name"
                        outerRadius = "80%"
                        innerRadius = "60%"
                        paddingAngle = 5
                        dataKey = "value"
                        EVENT_COLORS.forEachIndexed { index, color ->
                            Cell {
                                key = "cell-$index"
                                fill = color
                                stroke = "0"
                            }
                        }
                    }
                    Legend {
                        iconSize = 20
                        iconType = "wye"
                    }
                }
            }
        }
        if (props.dataFetched) {
            Box {
                className = EnergyManagementStyles.SUMMARY_TITLE_CENTER.cssClass
                if (props.dataError) {
                    ApiFailedMessage()
                } else if (!isNotEmpty) {
                    Typography {
                        className = ControlCenterPageStyles.TEXT_CENTER.cssClass
                        variant = TypographyVariant.subtitle1
                        sx {
                            color = Color(Colors.GREEN)
                        }
                        +"You don't have any actions."
                    }
                }
            }
        }
    }
}