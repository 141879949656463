package com.ecosave.watch.portal.models.auth

import com.ecosave.watch.portal.helpers.usermanagement.OnBoardingStatus
import com.ecosave.watch.portal.helpers.usermanagement.Subscription
import io.ktor.http.*
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class UserInfo(
    val firstName: String,
    val lastName: String,
    val emailAddress: String,
    val userRole: String,
    val subscriptions: List<Subscription>
)

@Serializable
data class LoginResponse(
    val onBoardingStatus: OnBoardingStatus,
    val token: TokenData
)

@Serializable
data class Credential(
    val emailAddress: String,
    val password: String
)


@Serializable
data class TokenData(
    @SerialName("access_token")
    val accessToken: String,
    @SerialName("expires_in")
    val expiresIn: Int,
    @SerialName("refresh_expires_in")
    val refreshExpiresIn: Int,
    @SerialName("refresh_token")
    val refreshToken: String,
    @SerialName("token_type")
    val tokenType: String,
    @SerialName("not-before-policy")
    val notBeforePolicy: Int,
    @SerialName("session_state")
    val sessionState: String,
    val scope: String
)

data class LoginApiResponse(
    val httpStatusCode: HttpStatusCode,
    val loginResponse: LoginResponse?
)