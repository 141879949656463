package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class ChildLaborState(
    var section4081Field1: String? = null,
    var childLaborIncidentRiskList: MutableList<ChildLaborIncidentRisk> = mutableListOf(),
    var youngWorkerIncidentRiskList: MutableList<YoungWorkerIncidentRisk> = mutableListOf(),

    var section3_3_408A1: String? = null,
    var section3_3_408A2: String? = null,
    var section3_3_408A3: String? = null,
    var section3_3_408A4: String? = null,
    var section3_3_408A5: String? = null,
    var section3_3_408A6: String? = null
)

@Serializable
data class ChildLaborIncidentRisk(
    var section4081A1: String? = null,
    var section4081A2: String? = null,
) : DynamicTableItemKey()

@Serializable
data class YoungWorkerIncidentRisk(
    var section4081B1: String? = null,
    var section4081B2: String? = null,
) : DynamicTableItemKey()