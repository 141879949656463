package com.ecosave.watch.portal.components.common

import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.Colors
import mui.material.Alert
import mui.material.AlertColor
import mui.material.AlertVariant
import mui.material.Snackbar
import mui.material.SnackbarCloseReason
import mui.system.sx
import react.FC
import react.Props
import web.cssom.Color
import web.cssom.Position
import web.cssom.em
import web.cssom.px

external interface AlertNotificationProps : Props {
    var open: Boolean
    var status: NotificationStatus
    var message: String
    var closeNotification: () -> Unit
}

val AlertNotifications = FC<AlertNotificationProps> { props ->
    Snackbar {
        open = props.open
        onClose = { _, reason ->
            if (reason !== SnackbarCloseReason.clickaway)
                props.closeNotification()
        }
        autoHideDuration = 3000
        Alert {
            sx {
                position = Position.fixed
                right = 10.px
                top = 10.px
                fontSize = 1.em
                maxWidth = 400.px
                color = Color(Colors.WHITE)
            }
            color = when (props.status) {
                NotificationStatus.SUCCESS -> AlertColor.success
                NotificationStatus.ERROR -> AlertColor.error
                NotificationStatus.INFO -> AlertColor.info
            }
            severity = when (props.status) {
                NotificationStatus.SUCCESS -> AlertColor.success
                NotificationStatus.ERROR -> AlertColor.error
                NotificationStatus.INFO -> AlertColor.info
            }
            variant = AlertVariant.filled
            +props.message
            onClose = {
                props.closeNotification()
            }
        }
    }
}
