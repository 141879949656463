package com.ecosave.watch.portal.models.assetmanagement

import io.ktor.http.*
import kotlinx.serialization.Serializable

@Serializable
data class Asset(
    val assetId: Int?,
    val facilityId: Int,
    val equipmentId: String,
    val equipmentType: String,
    val systemType: String,
    val equipmentLocation: String,
    val quantity: Int,
    val serves: String?,
    val ownership: String?,
    val manufacturer: String,
    val equipmentMaintenanceProvider: String,
    val model: String,
    val serialNumber: String,
    val yearInstalled: String,
    val warrantyEndDate: String,
    val comment: String?,
    val fileType: String?,
    val uploadDocumentName: String?,
    val documentUrl: String?
)

data class AssetApiResponse(
    val httpStatusCode: HttpStatusCode?,
    val asset: Asset?
)
