package com.ecosave.watch.portal.services.onboarding

import com.ecosave.watch.portal.helpers.BackendServerURL
import com.ecosave.watch.portal.helpers.onboarding.MainSubscriptionPayment
import com.ecosave.watch.portal.models.onboarding.MainProductsApiResponse
import com.ecosave.watch.portal.services.httpClient
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*

suspend fun getMainProducts(): MainProductsApiResponse {
    try {
        val response: HttpResponse = httpClient.get("$BackendServerURL/payment/productsPaymentSummary")
        return when (response.status) {
            HttpStatusCode.OK -> {
                return MainProductsApiResponse(
                    productsPaymentSummary = response.body(),
                    paymentStatus = MainSubscriptionPayment.MAKE_PAYMENT
                )
            }

            HttpStatusCode.Created -> {
                return MainProductsApiResponse(
                    null,
                    paymentStatus = MainSubscriptionPayment.PAYMENT_ALREADY_DONE
                )
            }

            else -> {
                return MainProductsApiResponse(
                    null,
                    paymentStatus = MainSubscriptionPayment.EXCEPTION
                )
            }
        }

    } catch (e: dynamic) {
        console.log(e)
    }
    return MainProductsApiResponse(
        null,
        paymentStatus = MainSubscriptionPayment.EXCEPTION
    )
}

