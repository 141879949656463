package com.ecosave.watch.portal.models.facilitygroupmanagement

import kotlinx.serialization.Serializable

@Serializable
data class EditGroupRequestDataClass(
    val facilityGroupId: Int,
    val facilityGroupName: String,
    val facilitiesList: MutableList<Int>
)
