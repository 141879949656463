package com.ecosave.watch.portal.models.resourcecenter


data class Module(
    var moduleTitle: String,
    var moduleBody: String,
    var moduleRoute: String
)

data class ModuleContent(
    var name: String,
    var moduleNavigationItem: List<ModuleNavigationItem>
)

data class ModuleNavigationItem(
    var name: String,
    var childItems: List<ModuleNavigationItem>? = null
)

