package com.ecosave.watch.portal.components.formcontrols

import js.core.jso
import mui.icons.material.Visibility
import mui.icons.material.VisibilityOff
import mui.material.BaseTextFieldProps
import mui.material.FormControlVariant
import mui.material.IconButton
import mui.material.InputAdornment
import mui.material.InputAdornmentPosition
import mui.material.InputBaseProps
import mui.material.Size
import mui.material.TextField
import react.FC
import react.create
import react.useState
import web.html.InputType

val PasswordTextField = FC<BaseTextFieldProps> { props ->

    var showPassword by useState(false)

    TextField {
        +props
        variant = FormControlVariant.outlined
        size = Size.medium
        type = if (showPassword) InputType.text else InputType.password
        fullWidth = true
        asDynamic().InputProps = jso<InputBaseProps> {
            endAdornment = InputAdornment.create {
                position = InputAdornmentPosition.end
                IconButton {
                    onClick = {
                        showPassword = !showPassword
                    }
                    if (showPassword) Visibility() else VisibilityOff()
                }
            }
        }
    }
}