package com.ecosave.watch.portal.components.esg.waterandeffluents

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val GRI_303_1 = FC<EsgSubFormComponentProps> { props ->
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.waterAndEffluents?.let { state ->
        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_303_1
        }

        Box {
            label {
                +("Provide a description how the organization interacts with water, including how and where water is " +
                    "withdrawn, consumed, and discharged, and the water-related impacts the organization has caused " +
                    "or contributed to, or that are directly linked to its operations, products, or services by its " +
                    "business relationships (e.g., impacts caused by runoff).")
            }
            ESGTextAreaFieldWrapper {
                name = state::section3031A1.name
                value = state.section3031A1
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +("Provide a description of approach used to identify water-related impacts, including the scope of " +
                    "assessments, their timeframe, and are any tools or methodologies used.")
            }
            ESGTextAreaFieldWrapper {
                name = state::section3031A2.name
                value = state.section3031A2
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +("Provide a description of how water-related impacts are addressed, including how the organization " +
                    "works with stakeholders to steward water as a shared resource, and how it engages with suppliers " +
                    "or customers with significant water-related impacts.")
            }
            ESGTextAreaFieldWrapper {
                name = state::section3031A3.name
                value = state.section3031A3
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +("Explain the process for setting any water-related goals and targets that are part of the " +
                    "organization’s approach to managing water and effluents, and how they relate to public policy " +
                    "and the local context of each area with water stress.")
            }
            ESGTextAreaFieldWrapper {
                name = state::section3031A4.name
                value = state.section3031A4
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}