package com.ecosave.watch.portal.components.utilitybills

import com.ecosave.watch.portal.helpers.billing.BillingHeaders
import com.ecosave.watch.portal.models.billing.utilitybillstate.UtilityBillState
import com.ecosave.watch.portal.models.billing.UtilityBillValidationState
import com.ecosave.watch.portal.helpers.billing.UtilityType
import com.ecosave.watch.portal.helpers.common.YesNoEnum
import com.ecosave.watch.portal.helpers.billing.formatBillingLabels
import com.ecosave.watch.portal.helpers.billing.formatBillingValues
import com.ecosave.watch.portal.helpers.billing.validateOffPeakDemand
import com.ecosave.watch.portal.helpers.billing.validateOffPeakUsage
import com.ecosave.watch.portal.helpers.billing.validateOffPeakUsageCharges
import com.ecosave.watch.portal.helpers.billing.validateOnPeakDemand
import com.ecosave.watch.portal.helpers.billing.validateOnPeakUsage
import com.ecosave.watch.portal.helpers.billing.validateOnPeakUsageCharges
import com.ecosave.watch.portal.helpers.billing.validatePeakDemandCharges
import com.ecosave.watch.portal.helpers.billing.validateTotalDeliveryDistributionElectric
import com.ecosave.watch.portal.helpers.billing.validateTotalPeakBilledDemand
import com.ecosave.watch.portal.models.billing.UtilityBillSummary
import com.ecosave.watch.portal.styles.BillingTableStyles
import mui.material.TableCell
import mui.material.TableRow
import react.FC
import react.Props
import react.StateSetter
import react.dom.onChange
import web.html.HTMLInputElement
import web.html.InputType

external interface ElectricDataRowProps : Props {
    var editModeElectric: (Boolean)
    var editRowElectric: Boolean
    var stateElectric: UtilityBillState
    var validationElectric: UtilityBillValidationState
    var billElectric: UtilityBillSummary
    var setStateElectric: StateSetter<UtilityBillState>
    var setValidationElectric: StateSetter<UtilityBillValidationState>
}

val ElectricDataRow = FC<ElectricDataRowProps> { props ->

    val editMode = props.editModeElectric
    val editRow = props.editRowElectric
    val state = props.stateElectric
    val setState = props.setStateElectric
    val validation = props.validationElectric
    val setValidation = props.setValidationElectric
    val bill = props.billElectric

    TableRow {
        TableCell {
            className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
            +BillingHeaders.TOTAL_PEAK_BILLED_DEMAND.header
        }

        TableCell {
            className = BillingTableStyles.TABLE_CELL.cssClass
            if (editRow) {
                EditableTextFieldWrapper {
                    type = InputType.number
                    value = state.electricAdditional.totalPeakBilledDemand
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setState(
                            state.copy(
                                electricAdditional = state.electricAdditional.copy(
                                    totalPeakBilledDemand = target.value.toFloatOrNull()
                                )
                            )
                        )
                    }
                    error =
                        validation.electricBillValidationState.totalPeakBilledDemandErrorState
                    helperText =
                        validation.electricBillValidationState.totalPeakBilledDemandErrorMessage
                    onBlur = {
                        validateTotalPeakBilledDemand(state, validation, setValidation)
                    }
                }
            } else {
                +formatBillingValues(
                    bill.utilityBill.electricAdditional.totalPeakBilledDemand,
                    bill.utilityBill.demandUnit?.description ?: ""
                )
            }
        }

        TableCell {
            className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
            +"${formatBillingLabels(BillingHeaders.PEAK_DEMAND_CHARGES, editMode)}"
        }

        TableCell {
            className = BillingTableStyles.TABLE_CELL.cssClass
            if (editRow) {
                EditableTextFieldWrapper {
                    type = InputType.number
                    value = state.electricAdditional.peakDemandCharges
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setState(
                            state.copy(
                                electricAdditional = state.electricAdditional.copy(
                                    peakDemandCharges = target.value.toFloatOrNull()
                                )
                            )
                        )
                    }
                    error =
                        validation.electricBillValidationState.peakDemandChargesErrorState
                    helperText =
                        validation.electricBillValidationState.peakDemandChargesErrorMessage
                    onBlur = {
                        validatePeakDemandCharges(state, validation, setValidation)
                    }
                }
            } else {
                +formatBillingValues(
                    bill.utilityBill.electricAdditional.peakDemandCharges,
                    null
                )
            }
        }

        TableCell {
            className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
            +BillingHeaders.BILL_ESTIMATED.header
        }

        TableCell {
            className = BillingTableStyles.TABLE_CELL.cssClass
            if (editRow) {
                BillEstimated {
                    stateBillEstimated = state
                    setStateBillEstimated = setState
                }
            } else {
                if (bill.utilityBill.billEstimated == true) +YesNoEnum.YES.description else +YesNoEnum.NO.description
            }
        }
    }

    TableRow {
        TableCell {
            className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
            +BillingHeaders.BILL_ADJUSTED.header
        }

        TableCell {
            className = BillingTableStyles.TABLE_CELL.cssClass
            if (editRow) {
                BillAdjusted {
                    stateBillAdjusted = state
                    setStateBillAdjusted = setState
                }
            } else {
                if (bill.utilityBill.billAdjusted == true) +YesNoEnum.YES.description else +YesNoEnum.NO.description
            }
        }

        TableCell {
            className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
            +formatBillingLabels(BillingHeaders.TOTAL_DELIVERY_DISTRIBUTION, editMode)
        }

        TableCell {
            className = BillingTableStyles.TABLE_CELL.cssClass
            if (editRow) {
                EditableTextFieldWrapper {
                    type = InputType.number
                    value = state.totalDeliveryDistribution
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setState(
                            state.copy(
                                totalDeliveryDistribution = target.value.toFloatOrNull()
                            )
                        )
                    }
                    error =
                        validation.electricBillValidationState.totalDeliveryDistributionErrorState
                    helperText =
                        validation.electricBillValidationState.totalDeliveryDistributionErrorMessage
                    onBlur = {
                        validateTotalDeliveryDistributionElectric(state, validation, setValidation)
                    }
                }
            } else {
                +formatBillingValues(
                    bill.utilityBill.totalDeliveryDistribution,
                    null
                )
            }
        }

        TableCell {
            className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
            +BillingHeaders.ON_PEAK_DEMAND.header
        }

        TableCell {
            className = BillingTableStyles.TABLE_CELL.cssClass
            if (editRow) {
                EditableTextFieldWrapper {
                    type = InputType.number
                    value = state.electricAdditional.onPeakDemand
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setState(
                            state.copy(
                                electricAdditional = state.electricAdditional.copy(
                                    onPeakDemand = target.value.toFloatOrNull()
                                )
                            )
                        )
                    }
                    error =
                        validation.electricBillValidationState.onPeakDemandErrorState
                    helperText =
                        validation.electricBillValidationState.onPeakDemandErrorMessage
                    onBlur = {
                        validateOnPeakDemand(state, validation, setValidation)
                    }
                }
            } else {
                +formatBillingValues(
                    bill.utilityBill.electricAdditional.onPeakDemand,
                    bill.utilityBill.demandUnit?.description ?: ""
                )
            }
        }
    }

    TableRow {
        TableCell {
            className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
            +BillingHeaders.OFF_PEAK_USAGE.header
        }

        TableCell {
            className = BillingTableStyles.TABLE_CELL.cssClass
            if (editRow) {
                EditableTextFieldWrapper {
                    type = InputType.number
                    value = state.electricAdditional.offPeakUsage
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setState(
                            state.copy(
                                electricAdditional = state.electricAdditional.copy(
                                    offPeakUsage = target.value.toFloatOrNull()
                                )
                            )
                        )
                    }
                    error =
                        validation.electricBillValidationState.offPeakUsageErrorState
                    helperText =
                        validation.electricBillValidationState.offPeakUsageErrorMessage
                    onBlur = {
                        validateOffPeakUsage(state, validation, setValidation)
                    }
                }
            } else {
                +formatBillingValues(
                    bill.utilityBill.electricAdditional.offPeakUsage,
                    bill.utilityBill.usageUnit.description
                )
            }
        }

        TableCell {
            className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
            +formatBillingLabels(BillingHeaders.OFF_PEAK_USAGE_CHARGES, editMode)
        }

        TableCell {
            className = BillingTableStyles.TABLE_CELL.cssClass
            if (editRow) {
                EditableTextFieldWrapper {
                    type = InputType.number
                    value = state.electricAdditional.offPeakUsageCharges
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setState(
                            state.copy(
                                electricAdditional = state.electricAdditional.copy(
                                    offPeakUsageCharges = target.value.toFloatOrNull()
                                )
                            )
                        )
                    }
                    error =
                        validation.electricBillValidationState.offPeakUsageChargesErrorState
                    helperText =
                        validation.electricBillValidationState.offPeakUsageChargesErrorMessage
                    onBlur = {
                        validateOffPeakUsageCharges(state, validation, setValidation)
                    }
                }
            } else {
                +formatBillingValues(
                    bill.utilityBill.electricAdditional.offPeakUsageCharges,
                    null
                )
            }
        }

        TableCell {
            className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
            +formatBillingLabels(BillingHeaders.ON_PEAK_USAGE_CHARGES, editMode)
        }

        TableCell {
            className = BillingTableStyles.TABLE_CELL.cssClass
            if (editRow) {
                EditableTextFieldWrapper {
                    type = InputType.number
                    value = state.electricAdditional.onPeakUsageCharges
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setState(
                            state.copy(
                                electricAdditional = state.electricAdditional.copy(
                                    onPeakUsageCharges = target.value.toFloatOrNull()
                                )
                            )
                        )
                    }
                    error =
                        validation.electricBillValidationState.onPeakUsageChargesErrorState
                    helperText =
                        validation.electricBillValidationState.onPeakUsageChargesErrorMessage
                    onBlur = {
                        validateOnPeakUsageCharges(state, validation, setValidation)
                    }
                }
            } else {
                +formatBillingValues(
                    bill.utilityBill.electricAdditional.onPeakUsageCharges,
                    null
                )
            }
        }
    }

    TableRow {
        TableCell {
            className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
            +BillingHeaders.OFF_PEAK_DEMAND.header
        }

        TableCell {
            className = BillingTableStyles.TABLE_CELL.cssClass
            if (editRow) {
                EditableTextFieldWrapper {
                    type = InputType.number
                    value = state.electricAdditional.offPeakDemand
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setState(
                            state.copy(
                                electricAdditional = state.electricAdditional.copy(
                                    offPeakDemand = target.value.toFloatOrNull()
                                )
                            )
                        )
                    }
                    error =
                        validation.electricBillValidationState.offPeakDemandErrorState
                    helperText =
                        validation.electricBillValidationState.offPeakDemandErrorMessage
                    onBlur = {
                        validateOffPeakDemand(state, validation, setValidation)
                    }
                }
            } else {
                +formatBillingValues(
                    bill.utilityBill.electricAdditional.offPeakDemand,
                    bill.utilityBill.demandUnit?.description ?: ""
                )
            }
        }

        TableCell {
            className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
            +BillingHeaders.ON_PEAK_USAGE.header
        }

        TableCell {
            className = BillingTableStyles.TABLE_CELL.cssClass
            if (editRow) {
                EditableTextFieldWrapper {
                    type = InputType.number
                    value = state.electricAdditional.onPeakUsage
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setState(
                            state.copy(
                                electricAdditional = state.electricAdditional.copy(
                                    onPeakUsage = target.value.toFloatOrNull()
                                )
                            )
                        )
                    }
                    error =
                        validation.electricBillValidationState.onPeakUsageErrorState
                    helperText =
                        validation.electricBillValidationState.onPeakUsageErrorMessage
                    onBlur = {
                        validateOnPeakUsage(state, validation, setValidation)
                    }
                }
            } else {
                +formatBillingValues(
                    bill.utilityBill.electricAdditional.onPeakUsage,
                    bill.utilityBill.usageUnit.description
                )
            }
        }

        if (editRow) {
            UsageUnit {
                stateUsageUnit = state
                setStateUsageUnit = setState
                usageUnits = UtilityType.ELECTRIC.usageUnits
            }
        }
    }

    TableRow {
        if (editRow) {
            DemandUnit {
                stateDemandUnit = state
                setStateDemandUnit = setState
                demandUnits = UtilityType.ELECTRIC.demandUnits ?: listOf()
            }
        }
    }
}