package com.ecosave.watch.portal.components.utilityaccounts

import com.ecosave.watch.portal.components.common.ConfirmationDialog
import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.helpers.billing.BillingConstants
import com.ecosave.watch.portal.helpers.billing.BillingWaterAndSewerAnnotations
import com.ecosave.watch.portal.helpers.billing.BillingWaterHeaders
import com.ecosave.watch.portal.helpers.billing.UsageUnit
import com.ecosave.watch.portal.helpers.billing.UtilityType
import com.ecosave.watch.portal.helpers.billing.clearBillStartAndEndDate
import com.ecosave.watch.portal.helpers.billing.formatWaterBillingLabels
import com.ecosave.watch.portal.helpers.billing.isBillingWaterFormValid
import com.ecosave.watch.portal.helpers.billing.mainScope
import com.ecosave.watch.portal.helpers.billing.validateBioChemicalOxygenDemand
import com.ecosave.watch.portal.helpers.billing.validateBodCharges
import com.ecosave.watch.portal.helpers.billing.validateBodSurchargeFactor
import com.ecosave.watch.portal.helpers.billing.validateFlow
import com.ecosave.watch.portal.helpers.billing.validateFlowUsedForBilling
import com.ecosave.watch.portal.helpers.billing.validateIndustrialSurcharge
import com.ecosave.watch.portal.helpers.billing.validateServiceCharges
import com.ecosave.watch.portal.helpers.billing.validateSrf
import com.ecosave.watch.portal.helpers.billing.validateStormWaterCharges
import com.ecosave.watch.portal.helpers.billing.validateTotalSurchargeBill
import com.ecosave.watch.portal.helpers.billing.validateTotalSuspendedSolids
import com.ecosave.watch.portal.helpers.billing.validateTssCharges
import com.ecosave.watch.portal.helpers.billing.validateTssSurchargeFactor
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.models.billing.ElectricBillValidationState
import com.ecosave.watch.portal.models.billing.NaturalGasBillValidationState
import com.ecosave.watch.portal.models.billing.SolarBillValidationState
import com.ecosave.watch.portal.models.billing.SteamBillValidationState
import com.ecosave.watch.portal.models.billing.UtilityBillValidationState
import com.ecosave.watch.portal.models.billing.WaterBillValidationState
import com.ecosave.watch.portal.models.billing.utilitybillstate.ElectricAdditional
import com.ecosave.watch.portal.models.billing.utilitybillstate.NaturalGasAdditional
import com.ecosave.watch.portal.models.billing.utilitybillstate.SolarBillAdditional
import com.ecosave.watch.portal.models.billing.utilitybillstate.SteamBillAdditional
import com.ecosave.watch.portal.models.billing.utilitybillstate.UtilityBillState
import com.ecosave.watch.portal.models.billing.utilitybillstate.WaterBillAdditional
import com.ecosave.watch.portal.services.billing.createBill
import com.ecosave.watch.portal.styles.BillingInputFormStyles
import com.ecosave.watch.portal.styles.CommonStyles
import com.ecosave.watch.portal.useGlobalState
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import react.FC
import react.Props
import react.ReactNode
import react.StateSetter
import react.dom.onChange
import react.useState
import web.html.HTMLInputElement
import web.html.InputType

external interface WaterFormProps : Props {
    var setShowInputForm: StateSetter<Boolean>
    var showNotification: (NotificationStatus, String) -> Unit
}

val WaterForm = FC<WaterFormProps> { props ->
    val globalState = useGlobalState()
    val (formValidationState, setFormValidationState) = useState(
        UtilityBillValidationState(
            electricBillValidationState = ElectricBillValidationState(),
            naturalGasBillValidationState = NaturalGasBillValidationState(),
            waterBillValidationState = WaterBillValidationState(),
            steamBillValidationState = SteamBillValidationState(),
            solarBillValidationState = SolarBillValidationState()
        )
    )
    val (formState, setFormState) = useState(
        UtilityBillState(
            utilityAccountId = globalState.utilityAccountData.accountId,
            utilityType = UtilityType.WATER.name,
            usageUnit = UsageUnit.CCF.name,
            electricAdditional = ElectricAdditional(),
            naturalGasAdditional = NaturalGasAdditional(),
            waterBillAdditional = WaterBillAdditional(),
            steamBillAdditional = SteamBillAdditional(),
            solarBillAdditional = SolarBillAdditional()
        )
    )
    var isSubmitting by useState(false)
    var submitButtonText by useState("Submit")
    val (openDialog, setOpenDialog) = useState(false)

    Box {
        InputFormTitleSector {
            title = "Add Water Bill"
            subtitle = "Account Number: ${globalState.utilityAccountData.accountNumber}"
        }
        Box {
            className = BillingInputFormStyles.MAIN_FORM.cssClass
            Box {
                className = BillingInputFormStyles.INPUT_WRAPPER.cssClass
                BillStartDate {
                    state = formState
                    setState = setFormState
                    validation = formValidationState
                    setValidation = setFormValidationState
                    isSolarStartDate = false
                    setDialogState = setOpenDialog
                }

                BillEndDate {
                    state = formState
                    setState = setFormState
                    validation = formValidationState
                    setValidation = setFormValidationState
                    isSolarEndDate = false
                    setDialogState = setOpenDialog
                }

                AddTotalUsage {
                    state = formState
                    setState = setFormState
                    validation = formValidationState
                    setValidation = setFormValidationState
                }

                AddUsageUnit {
                    stateUsageUnit = formState
                    setStateUsageUnit = setFormState
                    usageUnits = UtilityType.WATER.usageUnits
                }

                AddTotalBillCost {
                    state = formState
                    setState = setFormState
                    validation = formValidationState
                    setValidation = setFormValidationState
                }

                AddBillEstimated {
                    state = formState
                    setState = setFormState
                    validation = formValidationState
                    setValidation = setFormValidationState
                }

                AddBillAdjusted {
                    state = formState
                    setState = setFormState
                    validation = formValidationState
                    setValidation = setFormValidationState
                }

                TextFieldWrapper {
                    label = ReactNode(
                        formatWaterBillingLabels(BillingWaterHeaders.SERVICE_CHARGES, true)
                    )
                    type = InputType.number
                    annotation = BillingWaterAndSewerAnnotations.SERVICE_CHARGES
                    value = formState.waterBillAdditional.serviceCharges
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setFormState(
                            formState.copy(
                                waterBillAdditional = formState.waterBillAdditional.copy(
                                    serviceCharges = target.value.toFloatOrNull()
                                )
                            )
                        )
                    }
                    error = formValidationState.waterBillValidationState.serviceChargesErrorState
                    helperText = formValidationState.waterBillValidationState.serviceChargesErrorMessage
                    onBlur = {
                        validateServiceCharges(
                            formState,
                            formValidationState,
                            setFormValidationState
                        )
                    }
                }

                TextFieldWrapper {
                    label = ReactNode(
                        formatWaterBillingLabels(BillingWaterHeaders.STORMWATER_CHARGES, true)
                    )
                    type = InputType.number
                    annotation = BillingWaterAndSewerAnnotations.STORMWATER_CHARGES
                    value = formState.waterBillAdditional.stormWaterCharges
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setFormState(
                            formState.copy(
                                waterBillAdditional = formState.waterBillAdditional.copy(
                                    stormWaterCharges = target.value.toFloatOrNull()
                                )
                            )
                        )
                    }
                    error = formValidationState.waterBillValidationState.stormWaterChargesErrorState
                    helperText =
                        formValidationState.waterBillValidationState.stormWaterChargesErrorMessage
                    onBlur = {
                        validateStormWaterCharges(
                            formState,
                            formValidationState,
                            setFormValidationState
                        )
                    }
                }

                if (globalState.utilityAccountData.surchargeBillExists) {

                    TextFieldWrapper {
                        label = ReactNode(
                            "${BillingWaterHeaders.INDUSTRIAL_SURCHARGE.header} ($)"
                        )
                        type = InputType.number
                        annotation = BillingWaterAndSewerAnnotations.INDUSTRIAL_SURCHARGE
                        value = formState.waterBillAdditional.industrialSurcharge
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setFormState(
                                formState.copy(
                                    waterBillAdditional = formState.waterBillAdditional.copy(
                                        industrialSurcharge = target.value.toFloatOrNull()
                                    )
                                )
                            )
                        }
                        error =
                            formValidationState.waterBillValidationState.industrialSurchargeErrorState
                        helperText =
                            formValidationState.waterBillValidationState.industrialSurchargeErrorMessage
                        onBlur = {
                            validateIndustrialSurcharge(
                                formState,
                                formValidationState,
                                setFormValidationState
                            )
                        }
                    }

                    TextFieldWrapper {
                        label = ReactNode(BillingWaterHeaders.FLOW.header)
                        type = InputType.number
                        annotation = BillingWaterAndSewerAnnotations.FLOW
                        value = formState.waterBillAdditional.flow
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setFormState(
                                formState.copy(
                                    waterBillAdditional = formState.waterBillAdditional.copy(
                                        flow = target.value.toFloatOrNull()
                                    )
                                )
                            )
                        }
                        error = formValidationState.waterBillValidationState.flowErrorState
                        helperText = formValidationState.waterBillValidationState.flowErrorMessage
                        onBlur = {
                            validateFlow(
                                formState,
                                formValidationState,
                                setFormValidationState
                            )
                        }
                    }

                    TextFieldWrapper {
                        label = ReactNode(BillingWaterHeaders.SRF.header)
                        type = InputType.number
                        annotation = BillingWaterAndSewerAnnotations.SRF
                        value = formState.waterBillAdditional.srf
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setFormState(
                                formState.copy(
                                    waterBillAdditional = formState.waterBillAdditional.copy(
                                        srf = target.value.toFloatOrNull()
                                    )
                                )
                            )
                        }
                        error = formValidationState.waterBillValidationState.srfErrorState
                        helperText = formValidationState.waterBillValidationState.srfErrorMessage
                        onBlur = {
                            validateSrf(
                                formState,
                                formValidationState,
                                setFormValidationState
                            )
                        }
                    }

                    TextFieldWrapper {
                        label = ReactNode(BillingWaterHeaders.FLOW_USED_FOR_BILLING.header)
                        type = InputType.number
                        annotation = BillingWaterAndSewerAnnotations.FLOW_USED_FOR_BILLING
                        value = formState.waterBillAdditional.flowUsedForBilling
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setFormState(
                                formState.copy(
                                    waterBillAdditional = formState.waterBillAdditional.copy(
                                        flowUsedForBilling = target.value.toFloatOrNull()
                                    )
                                )
                            )
                        }
                        error =
                            formValidationState.waterBillValidationState.flowUsedForBillingErrorState
                        helperText =
                            formValidationState.waterBillValidationState.flowUsedForBillingErrorMessage
                        onBlur = {
                            validateFlowUsedForBilling(
                                formState,
                                formValidationState,
                                setFormValidationState
                            )
                        }
                    }

                    TextFieldWrapper {
                        label = ReactNode(BillingWaterHeaders.BIO_CHEMICAL_OXYGEN_DEMAND.header)
                        type = InputType.number
                        annotation = BillingWaterAndSewerAnnotations.BIO_CHEMICAL_OXYGEN_DEMAND
                        value = formState.waterBillAdditional.biochemicalOxygenDemand
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setFormState(
                                formState.copy(
                                    waterBillAdditional = formState.waterBillAdditional.copy(
                                        biochemicalOxygenDemand = target.value.toFloatOrNull()
                                    )
                                )
                            )
                        }
                        error =
                            formValidationState.waterBillValidationState.bioChemicalOxygenDemandErrorState
                        helperText =
                            formValidationState.waterBillValidationState.bioChemicalOxygenDemandErrorMessage
                        onBlur = {
                            validateBioChemicalOxygenDemand(
                                formState,
                                formValidationState,
                                setFormValidationState
                            )
                        }
                    }

                    TextFieldWrapper {
                        label = ReactNode(BillingWaterHeaders.BOD_SURCHARGE_FACTOR.header)
                        type = InputType.number
                        value = formState.waterBillAdditional.bodSurchargeFactor
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setFormState(
                                formState.copy(
                                    waterBillAdditional = formState.waterBillAdditional.copy(
                                        bodSurchargeFactor = target.value.toFloatOrNull()
                                    )
                                )
                            )
                        }
                        error =
                            formValidationState.waterBillValidationState.bodSurchargeFactorErrorState
                        helperText =
                            formValidationState.waterBillValidationState.bodSurchargeFactorErrorMessage
                        onBlur = {
                            validateBodSurchargeFactor(
                                formState,
                                formValidationState,
                                setFormValidationState
                            )
                        }
                    }

                    TextFieldWrapper {
                        label = ReactNode(
                            "${BillingWaterHeaders.BOD_CHARGES.header} ($)"
                        )
                        type = InputType.number
                        value = formState.waterBillAdditional.bodCharges
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setFormState(
                                formState.copy(
                                    waterBillAdditional = formState.waterBillAdditional.copy(
                                        bodCharges = target.value.toFloatOrNull()
                                    )
                                )
                            )
                        }
                        error = formValidationState.waterBillValidationState.bodChargesErrorState
                        helperText = formValidationState.waterBillValidationState.bodChargesErrorMessage
                        onBlur = {
                            validateBodCharges(
                                formState,
                                formValidationState,
                                setFormValidationState
                            )
                        }
                    }

                    TextFieldWrapper {
                        label = ReactNode(BillingWaterHeaders.TOTAL_SUSPENDED_SOLIDS.header)
                        type = InputType.number
                        annotation = BillingWaterAndSewerAnnotations.TOTAL_SUSPENDED_SOLIDS
                        value = formState.waterBillAdditional.totalSuspendedSolids
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setFormState(
                                formState.copy(
                                    waterBillAdditional = formState.waterBillAdditional.copy(
                                        totalSuspendedSolids = target.value.toFloatOrNull()
                                    )
                                )
                            )
                        }
                        error =
                            formValidationState.waterBillValidationState.totalSuspendedSolidsErrorState
                        helperText =
                            formValidationState.waterBillValidationState.totalSuspendedSolidsErrorMessage
                        onBlur = {
                            validateTotalSuspendedSolids(
                                formState,
                                formValidationState,
                                setFormValidationState
                            )
                        }
                    }

                    TextFieldWrapper {
                        label = ReactNode(BillingWaterHeaders.TSS_SURCHARGE_FACTOR.header)
                        type = InputType.number
                        value = formState.waterBillAdditional.tssSurchargeFactor
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setFormState(
                                formState.copy(
                                    waterBillAdditional = formState.waterBillAdditional.copy(
                                        tssSurchargeFactor = target.value.toFloatOrNull()
                                    )
                                )
                            )
                        }
                        error =
                            formValidationState.waterBillValidationState.tssSurchargeFactorErrorState
                        helperText =
                            formValidationState.waterBillValidationState.tssSurchargeFactorErrorMessage
                        onBlur = {
                            validateTssSurchargeFactor(
                                formState,
                                formValidationState,
                                setFormValidationState
                            )
                        }
                    }

                    TextFieldWrapper {
                        label = ReactNode(
                            "${BillingWaterHeaders.TSS_CHARGES.header} ($)"
                        )
                        type = InputType.number
                        value = formState.waterBillAdditional.tssCharges
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setFormState(
                                formState.copy(
                                    waterBillAdditional = formState.waterBillAdditional.copy(
                                        tssCharges = target.value.toFloatOrNull()
                                    )
                                )
                            )
                        }
                        error = formValidationState.waterBillValidationState.tssChargesErrorState
                        helperText = formValidationState.waterBillValidationState.tssChargesErrorMessage
                        onBlur = {
                            validateTssCharges(
                                formState,
                                formValidationState,
                                setFormValidationState
                            )
                        }
                    }

                    TextFieldWrapper {
                        label = ReactNode(
                            "${BillingWaterHeaders.TOTAL_SURCHARGE_BILL.header} ($)"
                        )
                        type = InputType.number
                        value = formState.waterBillAdditional.totalSurchargeBill
                        annotation = BillingWaterAndSewerAnnotations.TOTAL_SURCHARGE_BILL
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setFormState(
                                formState.copy(
                                    waterBillAdditional = formState.waterBillAdditional.copy(
                                        totalSurchargeBill = target.value.toFloatOrNull()
                                    )
                                )
                            )
                        }
                        error =
                            formValidationState.waterBillValidationState.totalSurchargeBillErrorState
                        helperText =
                            formValidationState.waterBillValidationState.totalSurchargeBillErrorMessage
                        onBlur = {
                            validateTotalSurchargeBill(
                                formState,
                                formValidationState,
                                setFormValidationState
                            )
                        }
                    }
                    Box {
                        className = BillingInputFormStyles.INPUT_ITEM.cssClass
                    }
                }
            }
        }
        Box {
            className = CommonStyles.CENTERED_BUTTON.cssClass
            Button {
                className = CommonStyles.MARGIN_RIGHT_BUTTON.cssClass
                +"Cancel"
                onClick = {
                    props.setShowInputForm(false)
                }
            }
            Button {
                +submitButtonText
                disabled = isSubmitting
                variant = ButtonVariant.contained
                onClick = {
                    if (isBillingWaterFormValid(
                            formState,
                            formValidationState,
                            setFormValidationState,
                            globalState.utilityAccountData.surchargeBillExists
                        )
                    ) {
                        mainScope.launch {
                            isSubmitting = true
                            submitButtonText = "Submitting..."
                            val apiCallStatus = createBill(formState)
                            if (apiCallStatus == ApiCallStatus.SUCCESS) {
                                props.showNotification(
                                    NotificationStatus.SUCCESS,
                                    BillingConstants.BILL_SUBMITTED_NOTIFICATION_MESSAGE
                                )
                                props.setShowInputForm(false)
                            } else {
                                props.showNotification(NotificationStatus.ERROR, Constants.NOTIFICATION_ERROR_MESSAGE)
                            }
                            submitButtonText = "Submit"
                            isSubmitting = false
                        }
                    }
                }
            }
        }
    }
    ConfirmationDialog {
        open = openDialog
        title = "Confirmation"
        body = BillingConstants.BILL_START_END_DATE_DIFF_DIALOG_MESSAGE
        actionResult = { value ->
            setOpenDialog(false)
            if (!value) {
                clearBillStartAndEndDate(formState, setFormState)
            }
        }
    }
}