package com.ecosave.watch.portal.components.privacypolicy

import com.ecosave.watch.portal.components.privacypolicy.TermsOfServiceConstants.ecosaveSupportEmail
import com.ecosave.watch.portal.helpers.privacypolicy.getMailToWithSubject
import react.FC
import react.Props
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.ul

val WhenAndWhyWeShareOrDisclosePersonalInformation = FC<Props> {
    HeadingWrapper {
        heading = "When and Why We Share or Disclose Personal Information"
    }
    TypographyWrapper {
        +"Except to the extent necessary to fulfill our business obligations, to accomplish one of the lawful purposes described in this Privacy Statement, or pursuant to your express instructions, we do not sell, transfer, or otherwise disclose Personal Information that we collect from or about you."
    }
    HeadingWrapper {
        heading = "We may share your information in the following ways:"
        subHeading = true
    }
    HeadingWrapper {
        heading = "With Your Express Consent: "
        subHeading = true
    }
    TypographyWrapper {
        +"We will share your Personal Information with companies, organizations, or individuals outside of Ecosave when we have your consent to do so."
    }
    HeadingWrapper {
        heading = "When You Choose to Directly Share Your Information While Using Our Services:"
        subHeading = true
    }
    TypographyWrapper {
        +"When you use our Services, certain features allow you to make some of your content accessible to the public or other users of the Services. We urge you to consider the sensitivity of any information prior to sharing it publicly or with other users."
    }
    HeadingWrapper {
        heading = "When Your Account Is Accessed by Your Organization’s Designated Ecosave Administrator: "
        subHeading = true
    }
    TypographyWrapper {
        +"Your Ecosave account owners and administrators may be able to:"
    }
    TypographyWrapper {
        ul {
            li {
                +"Access information in and about your Ecosave account;"
            }
            li {
                +"Disclose, restrict, or access information that you have provided or that is made available to you when using your Ecosave account, including your content; and"
            }
            li {
                +"Control how your Ecosave account may be configured, accessed, or deleted."
            }
        }
    }
    HeadingWrapper {
        heading = "With our vendors and business partners, to accomplish our business purposes:"
        subHeading = true
    }
    TypographyWrapper {
        +"We may share your information with our service providers and other third parties who perform services on our behalf. We provide your payment information to our service providers for payment processing and verification. Service providers such as analytics providers may collect information about your online activities over time and across different online services when you use our Services. We also work with third-party service providers to provide the cloud-based tools that our customers use to create their secure storage containers and securely store their sensitive information, including Personal Information."
    }
    HeadingWrapper {
        heading =
            "When Necessary to Comply with Laws and Law Enforcement Requests, or Otherwise to Protect Our Rights or Those of Individuals:"
        subHeading = true
    }
    TypographyWrapper {
        +"We may disclose your information (including your Personal Information) to a third party if:"
    }
    TypographyWrapper {
        ul {
            li {
                +"We believe that disclosure is reasonably necessary to comply with any applicable law, regulation, legal process or governmental request"
            }
            li {
                +"To enforce our agreements, policies and terms of service"
            }
            li {
                +"To protect the security or integrity of Ecosave’s products and services"
            }
            li {
                +"To respond to an incident involving personal data for which Ecosave has direct or indirect responsibility"
            }
            li {
                +"To protect the property, rights, and safety of Ecosave, our customers or the public from harm or illegal activities"
            }
            li {
                +"To respond to an emergency which we believe in the good faith requires us to disclose information to assist in preventing the death or serious bodily injury of any person"
            }
            li {
                +"To investigate and defend ourselves against any third-party claims or allegations."
            }
        }
    }
    HeadingWrapper {
        heading = "As the result of a business transition:"
        subHeading = true
    }
    TypographyWrapper {
        +"We may share or transfer your information (including your Personal Information) in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company. We will take reasonable steps to assure that any other entity involved continues to comply with the terms of this Privacy Statement. We will notify you of such a change in ownership or transfer of assets by posting a notice on our website."
    }
    HeadingWrapper {
        heading = "Sharing aggregated, anonymized, de-identified, or otherwise non-personal data:"
        subHeading = true
    }
    TypographyWrapper {
        +"We may share aggregated, anonymized, de-identified, or otherwise non-Personal Information that does not directly or indirectly identify you and that cannot with reasonable effort, be used to re-identify you in order to improve the overall experience of our Services. Such aggregated, anonymized, de-identified, or otherwise not re-identifiable information is not Personal Information within the scope of this Privacy Statement."
    }
    HeadingWrapper {
        heading = "NOTE: Ecosave does not sell your Personal Information"
        subHeading = true
    }
    HeadingWrapper {
        heading = "How we respond to compelled disclosure"
    }
    TypographyWrapper {
        +"Ecosave may disclose Personal Information or other information we collect about you to law enforcement in response to a valid subpoena, court order, warrant, or similar government order, or when we believe in good faith that disclosure is reasonably necessary to protect our property or rights, or those of third parties or the public at large."
    }
    TypographyWrapper {
        +"In complying with court orders and similar legal processes, Ecosave strives for transparency. When permitted, we will make a reasonable effort to notify users of any disclosure of their information, unless we are prohibited by law or court order from doing so, or in rare, exigent circumstances."
    }
    HeadingWrapper {
        heading = "How we, and others, communicate with you"
    }
    TypographyWrapper {
        +"We may use your email address to communicate with you, if you have expressly allowed us to do so, and only for the reasons you have allowed. For example, if you contact our support team with a request, we will respond to you via email. You cannot opt out of receiving important communications from us, such as emails from our support team or system emails, but you can manage your communication preferences and limitations in your user profile, as described in greater detail in the section below."
    }
    TypographyWrapper {
        +"Depending on your email settings, Ecosave may occasionally send notification emails about changes in a repository you are watching, new features, requests for feedback, important policy changes, or offer customer support. We also send marketing emails, based on your choices and in accordance with applicable laws and regulations. We will provide an unsubscribe link located at the bottom of each of the marketing emails we send you."
    }
    TypographyWrapper {
        +"Our emails may contain a pixel tag, which is a small, clear image that can tell us whether or not you have opened an email and what your IP address is. We use this pixel tag to make our email more effective for you and to make sure we’re not sending you unwanted email."
    }
    TypographyWrapper {
        +"In addition, if you access our platform or Services with your mobile device, the Company may send text messages (including SMS and MMS) to that mobile device. The subject of our text messages to you will be limited to those matters for which you have opted in. To stop receiving text messages, text STOP in response to any message you receive."
    }
    HeadingWrapper {
        heading = "Your Control Over Your Personal Information"
    }
    TypographyWrapper {
        +"You maintain control over the data you share with us, as described below."
    }
    TypographyWrapper {
        +"You may decline to share certain Personal Information with us, in which case we may not be able to provide to you (or our customers) some of the features and functionality of our Services or fulfill your requests. For example, we need your email address to authenticate you and perform account services such as password resets, or to provide you with customer support."
    }
    TypographyWrapper {
        +"You may decline to accept cookies, but that decision may affect the functionality and performance of our Services."
    }
    TypographyWrapper {
        +"You may update or correct your Personal Information at any time by accessing the account settings page on the website or within our platform."
    }
    TypographyWrapper {
        +"You may opt out of receiving promotional communications from Ecosave by using the unsubscribe link within each email. Note that, as long as you maintain an account with us, you will continue to receive administrative messages from us regarding the Services."
    }
    TypographyWrapper {
        +"You may request information about, and access to, the personal data that we collect from you."
    }
    TypographyWrapper {
        +"You may ask questions or make complaints about our privacy and data security practices with regard to your personal data."
    }
    TypographyWrapper {
        +"You may request that we delete information that we have collected about you."
    }
    TypographyWrapper {
        +"You may ask us for a copy of the information that we collect from you."
    }
    TypographyWrapper {
        +"To exercise any of these options, or for additional information about our privacy and data security practices, contact us at "
        CustomLink {
            to = TermsOfServiceConstants.ecosavePrivacyEmail.getMailToWithSubject("Privacy and Data Security")
            text = TermsOfServiceConstants.ecosavePrivacyEmail
        }
    }
    HeadingWrapper {
        heading = "Security"
    }
    TypographyWrapper {
        +"Unfortunately, no data transmission over the Internet or data storage system can be guaranteed to be 100% secure. That said, we employ a variety of organizational, technical and administrative measures to provide a level of security appropriate to the risk associated with the Personal Information you trust us with."
    }
    TypographyWrapper {
        +"To that end, we manage our data protection program consistent with ISO 27001 ("
        CustomLink {
            to = "https://www.iso.org/isoiec-27001-information-security.html"
        }
        +")"
    }
    TypographyWrapper {
        +"Ecosave protects Personal Information under its control and requires its service providers to also protect against accidental or unlawful destruction, loss, alteration, unauthorized disclosure of or access to, personal data transmitted, stored, or otherwise processed."
    }
    TypographyWrapper {
        +"If you have concerns about the security of your information with Ecosave, please contact us immediately at "

        CustomLink {
            to = ecosaveSupportEmail.getMailToWithSubject("Support")
            text = ecosaveSupportEmail
        }
        +" to report an issue."
    }
    HeadingWrapper {
        heading = "Data Retention"
    }
    TypographyWrapper {
        +"We retain your Personal Information only as long as necessary to accomplish the business purpose for which it was collected or to comply with our legal and contractual obligations, and then securely dispose of that information."
    }
    HeadingWrapper {
        heading = "Children’s Privacy"
    }
    TypographyWrapper {
        +"Our Services are not directed to individuals under 13. We do not knowingly collect Personal Information from children under 13. If we become aware that a child under 13 has provided us with Personal Information, we will take steps to delete such information. If you become aware that a child has provided us with Personal Information, please contact us at "
        CustomLink {
            to = TermsOfServiceConstants.ecosaveComplicanceEmail.getMailToWithSubject("Compliance")
            text = TermsOfServiceConstants.ecosaveComplicanceEmail
        }
    }
    HeadingWrapper {
        heading = "Ecosave Sub-vendor Directory"
    }
    TypographyWrapper {
        +"(Last updated: March 2023)"
    }
    TypographyWrapper {
        +"Ecosave shares information with service providers and other third parties who perform services on our behalf."
    }
    TypographyWrapper {
        +"This page provides a list of vendors with whom we share Personal Information as well as describes where each is located and what services these vendors provide for us."
    }
    HeadingWrapper {
        heading = "Subvendor — Service Provided — Headquarters Location"
        subHeading = true
    }
    TypographyWrapper {
        +"AWS — Cloud Infrastructure — US"
    }
}
