package com.ecosave.watch.portal.styles

import web.cssom.ClassName

enum class EnergyManagementStyles(val cssClass: ClassName) {
    CONTAINER(ClassName("energy-management-container")),
    SUMMARY_CONTAINER(ClassName("energy-management-summary-container")),
    SUMMARY_TABLE(ClassName("energy-management-summary-table")),
    SUMMARY_PIE_CONTAINER(ClassName("energy-management-summary-pie-container")),
    SUMMARY_PIE(ClassName("energy-management-summary-pie")),
    SUMMARY_PIE_CHART(ClassName("energy-management-summary-pie-chart")),
    SUMMARY_TITLE_CENTER(ClassName("energy-summary-title-center")),
    SUMMARY_TABLE_TOTAL_CELL(ClassName("end-table-cell")),
    SUMMARY_COMPARE_CONTAINER(ClassName(("energy-summary-compare-container"))),
    CHART_SECTION_ONE(ClassName("energy-management-chart-sector-one")),
    CHART_SECTION_TWO(ClassName("energy-management-chart-sector-two")),
    CHART_SUMMARY_SECTION_CONTENT(ClassName("energy-management-chart-sector-content")),
    CHART_SUMMARY_SECTION_ONE(ClassName("energy-management-chart-summary-sector-one")),
    CHART_SUMMARY_SECTION_TWO(ClassName("energy-management-chart-summary-sector-two")),
    NON_BORDER_CELL(ClassName("non-border-table-cell")),
    BASE_LINE_MODAL_CONTAINER(ClassName("base-line-modal-container")),
    BASE_LINE_MODAL_CONTENT(ClassName("base-line-modal-content")),
    GROUP_SELECT_MODAL_CONTENT(ClassName("group-select-modal-content")),
    BASE_LINE_CLOSE_ICON(ClassName("base-line-close-icon")),
    RANKING_CONTAINER(ClassName("ranking-container")),
    BASE_LINE_TARGET_CHART(ClassName("base-line-target-chart")),
    RANKING_CHART(ClassName("ranking-chart")),
    RANKING_MODAL_CONTENT(ClassName("ranking-modal-content")),
    TOOLTIP_CONTENT(ClassName("tooltip-content")),
    TARGET_MODAL_CONTAINER(ClassName("target-modal-container")),
    TARGET_MODAL_CONTENT(ClassName("target-modal-content")),
    BASELINE_TARGET_CONTENT(ClassName("baseline-target-content")),
    TARGET_TABLE_CELL(ClassName("target-table-cell")),
    TARGET_TABLE_FIRST_CELL(ClassName("target-table-first-cell")),
    TARGET_INPUT(ClassName("target-input"))
}