package com.ecosave.watch.portal.pages

import com.ecosave.watch.portal.components.common.TableHeader
import com.ecosave.watch.portal.components.facilitymanagement.AddOrEditFacility
import com.ecosave.watch.portal.components.facilitymanagement.ConnectEnergyStarAndImportFacilities
import com.ecosave.watch.portal.components.facilitymanagement.FacilityManagementTableRow
import com.ecosave.watch.portal.helpers.billing.BillingConstants
import com.ecosave.watch.portal.helpers.common.FormMode
import com.ecosave.watch.portal.helpers.common.PageTitles
import com.ecosave.watch.portal.helpers.common.isOnboardingCompleted
import com.ecosave.watch.portal.helpers.facilitymanagement.FacilityManagementTableHeaders
import com.ecosave.watch.portal.helpers.usermanagement.OnBoardingStatus
import com.ecosave.watch.portal.models.facilitymanagement.Facility
import com.ecosave.watch.portal.services.facilitymanagement.getFacilities
import com.ecosave.watch.portal.styles.FacilityManagementStyles
import com.ecosave.watch.portal.useGlobalState
import kotlinx.coroutines.launch
import mui.icons.material.Add
import mui.icons.material.ArrowDownward
import mui.icons.material.ArrowRight
import mui.material.Alert
import mui.material.AlertColor
import mui.material.AlertTitle
import mui.material.AlertVariant
import mui.material.Box
import mui.material.Button
import mui.material.ButtonColor
import mui.material.ButtonVariant
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableCellAlign
import mui.material.TableContainer
import mui.material.TableHead
import mui.material.TableRow
import mui.system.sx
import react.FC
import react.Props
import react.create
import react.useEffectOnce
import react.useState
import web.cssom.ClassName
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.px

val FacilityManagement = FC<Props> {

    val (facilities, setFacilities) = useState(mutableListOf<Facility>())
    val (formMode, setFormMode) = useState(FormMode.ADD)
    val (openAddOrEditFacilityForm, setOpenAddOrEditFacilityForm) = useState(false)
    val (openConnectEnergyStarAndImportFacilitiesForm, setOpenConnectEnergyStarAndImportFacilitiesForm) = useState(false)
    val (idOfFacilityToBeUpdated, setIdOfFacilityToBeUpdated) = useState<Int?>(null)
    var isLoading by useState(true)
    var helperMessage by useState("")
    val globalState = useGlobalState()

    val refreshFacilities: () -> Unit = {
        mainScope.launch {
            isLoading = true
            val facilities = getFacilities()

            if (facilities == null) {
                helperMessage = "There has been an issue fetching facilities, please retry."
                setFacilities(mutableListOf())
            } else if (facilities.isEmpty()) {
                helperMessage = "No facilities found, please register facilities."
            } else {
                setFacilities(facilities)
            }
            isLoading = false
        }
    }

    useEffectOnce {
        globalState.updatePageTitle(PageTitles.FACILITY_MANAGEMENT.title)
        refreshFacilities()
    }

    Box {
        className = ClassName("facility-management-container")
        // Onboarding Logic Start
        if (!isOnboardingCompleted(globalState.userData.onBoardingStatus) && !isLoading) {
            if (facilities.isEmpty()) {
                Alert {
                    color = AlertColor.warning
                    severity = AlertColor.info
                    variant = AlertVariant.outlined
                    +"You have to add at least one facility to complete facility registration."
                }
            }
            if (facilities.isNotEmpty()) {
                Alert {
                    color = AlertColor.success
                    severity = AlertColor.success
                    variant = AlertVariant.outlined
                    AlertTitle {
                        +"Continue Onboarding"
                    }
                    Box {
                        sx {
                            display = Display.flex
                            flexDirection = FlexDirection.column
                            gap = 10.px
                        }
                        Box {
                            +"You have added a facility, please continue onboarding to add utility accounts and you can register other facilities later."
                        }
                        Box {
                            Button {
                                variant = ButtonVariant.contained
                                color = ButtonColor.success
                                onClick = {
                                    globalState.handleUpdateUserData(
                                        globalState.userData.copy(
                                            onBoardingStatus = OnBoardingStatus.UTILITY_ACCOUNT_PENDING
                                        )
                                    )
                                }
                                +"Utility Accounts"
                                endIcon = ArrowRight.create()
                            }
                        }
                    }
                }
            }
        }

        // Onboarding Logic End
        Box {
            Button {
                sx {
                    marginRight = 15.px
                }
                variant = ButtonVariant.contained
                startIcon = startIcon.also {
                    ArrowDownward()
                }
                onClick = {
                    setOpenConnectEnergyStarAndImportFacilitiesForm(true)
                }
                +"Import from ${BillingConstants.ENERGY_STAR}"
            }
            Button {
                variant = ButtonVariant.contained
                startIcon = startIcon.also {
                    Add()
                }
                onClick = {
                    setFormMode(FormMode.ADD)
                    setOpenAddOrEditFacilityForm(true)
                }
                +"Add Manually"
            }
        }

        TableContainer {
            if (isOnboardingCompleted(globalState.userData.onBoardingStatus)) {
                className = FacilityManagementStyles.TABLE_HEIGHT.cssClass
            }
            Table {
                stickyHeader = true
                TableHead {
                    TableRow {
                        TableCell {}
                        for (header in FacilityManagementTableHeaders.entries) {
                            TableHeader {
                                tableHeaderName = header.header
                            }
                        }
                    }
                }
                TableBody {
                    if (isLoading || facilities.isEmpty()) {
                        TableCell {
                            align = TableCellAlign.center
                            colSpan = FacilityManagementTableHeaders.entries.size + 1
                            if (isLoading) +"Fetching Facilities..." else +helperMessage
                        }
                    } else {
                        for (facility in facilities) {
                            FacilityManagementTableRow {
                                this.facility = facility
                                this.setFormMode = setFormMode
                                this.setIdOfFacilityToBeUpdated = setIdOfFacilityToBeUpdated
                                this.setOpenAddOrEditFacilityForm = setOpenAddOrEditFacilityForm
                            }
                        }
                    }
                }
            }
        }
    }
    AddOrEditFacility {
        this.formMode = formMode
        this.openAddOrEditFacilityForm = openAddOrEditFacilityForm
        this.setOpenAddOrEditFacilityForm = setOpenAddOrEditFacilityForm
        this.facilities = facilities
        this.setFacilities = setFacilities
        this.idOfFacilityToBeUpdated = idOfFacilityToBeUpdated
        this.setIdOfFacilityToBeUpdated = setIdOfFacilityToBeUpdated
    }
    ConnectEnergyStarAndImportFacilities {
        this.openConnectEnergyStarAndImportFacilitiesForm = openConnectEnergyStarAndImportFacilitiesForm
        this.setOpenConnectEnergyStarAndImportFacilitiesForm = setOpenConnectEnergyStarAndImportFacilitiesForm
        this.refreshFacilities = refreshFacilities
    }
}