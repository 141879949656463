package com.ecosave.watch.portal.components.utilityaccounts

import com.ecosave.watch.portal.styles.BillingInputFormStyles
import mui.material.BaseTextFieldProps
import mui.material.Tooltip
import react.FC
import mui.material.TextField
import mui.material.Size
import mui.material.FormControlVariant
import react.ReactNode

external interface TextFieldWrapperProps : BaseTextFieldProps {
    var annotation: String
}

val TextFieldWrapper = FC<TextFieldWrapperProps> { props ->
    Tooltip {
        title = ReactNode(props.annotation)
        TextField {
            +props
            variant = FormControlVariant.outlined
            size = Size.medium
            className = BillingInputFormStyles.INPUT_ITEM.cssClass
        }
    }
}