package com.ecosave.watch.portal.components.resourcecenter

import com.ecosave.watch.portal.styles.NavigationStyles
import com.ecosave.watch.portal.Themes
import com.ecosave.watch.portal.components.resourcecenter.assetmanagement.AssetManagementComponent
import com.ecosave.watch.portal.components.resourcecenter.controlcenter.ControlCenterComponent
import com.ecosave.watch.portal.components.resourcecenter.energymanagement.EnergyManagementComponent
import com.ecosave.watch.portal.components.resourcecenter.facilitygroupmanagement.FacilityGroupManagementComponent
import com.ecosave.watch.portal.components.resourcecenter.facilitymanagement.FacilityManagementComponent
import com.ecosave.watch.portal.components.resourcecenter.initialonboarding.InitialOnboardingComponent
import com.ecosave.watch.portal.components.resourcecenter.usermanagement.UserManagementComponent
import com.ecosave.watch.portal.components.resourcecenter.utilityinformation.UtilityInformationContentComponent
import com.ecosave.watch.portal.components.resourcecenter.workorders.WorkOrdersComponent
import com.ecosave.watch.portal.helpers.common.PageRoutes
import com.ecosave.watch.portal.helpers.common.ScreenBreakPoints
import com.ecosave.watch.portal.helpers.common.sm
import com.ecosave.watch.portal.helpers.common.xs
import com.ecosave.watch.portal.helpers.resourcecenter.ModuleName
import com.ecosave.watch.portal.helpers.resourcecenter.ModulePath
import kotlinx.browser.window
import mui.icons.material.ArrowLeft
import mui.icons.material.Menu
import mui.material.Box
import mui.material.Button
import mui.material.Drawer
import mui.material.Grid
import mui.material.IconButton
import mui.material.Size
import mui.material.useMediaQuery
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.create
import react.router.useNavigate
import react.useEffect
import react.useState
import web.cssom.Cursor
import web.cssom.Position
import web.cssom.pct
import web.cssom.px
import web.cssom.translatey
import web.cssom.unaryMinus

val ModuleContent = FC<Props> {

    val isLaptop = useMediaQuery("(min-width:${ScreenBreakPoints.laptop})")
    val (openModuleNavigation, openModuleNavigationStateSetter) = useState(false)
    var moduleName by useState("")
    val path = window.location.pathname
    val navigate = useNavigate()

    useEffect(path) {
        moduleName = when (path) {
            ModulePath.UTILITY_INFORMATION.route -> ModuleName.UTILITY_INFORMATION.title
            ModulePath.INITIAL_ONBOARDING.route -> ModuleName.INITIAL_ONBOARDING.title
            ModulePath.ENERGY_MANAGEMENT.route -> ModuleName.ENERGY_MANAGEMENT.title
            ModulePath.ASSET_MANAGEMENT.route -> ModuleName.ASSET_MANAGEMENT.title
            ModulePath.CONTROL_CENTER.route -> ModuleName.CONTROL_CENTER.title
            ModulePath.FACILITY_MANAGEMENT.route -> ModuleName.FACILITY_MANAGEMENT.title
            ModulePath.USER_MANAGEMENT.route -> ModuleName.USER_MANAGEMENT.title
            ModulePath.FACILITY_GROUP_MANAGEMENT.route -> ModuleName.FACILITY_GROUP_MANAGEMENT.title
            ModulePath.WORK_ORDERS.route -> ModuleName.WORK_ORDERS.title
            else -> ""
        }
    }

    Box {
        Grid {
            container = true
            spacing = responsive(3)
            Grid {
                item = true
                xs = 12
                ModuleContentTitle {
                    title = moduleName
                }
            }
            Grid {
                item = true
                xs = 12
                Button {
                    startIcon = ArrowLeft.create()
                    +"Resource Center"
                    onClick = {
                        navigate.invoke(PageRoutes.RESOURCE_CENTER.route)
                    }
                }
            }
            if (isLaptop) {
                Grid {
                    item = true
                    sm = 2
                    ModuleNavigation {
                        this.moduleName = moduleName
                        this.openModuleNavigationStateSetter = openModuleNavigationStateSetter
                        this.isLaptop = isLaptop
                    }
                }
            } else {
                Grid {
                    item = true
                    sm = 12
                    IconButton {
                        sx {
                            position = Position.fixed
                            left = 0.px
                            top = 25.pct
                            transform = translatey(-50.pct)
                            backgroundColor = Themes.premiumGreen
                            borderRadius = 5.px
                            cursor = Cursor.pointer
                        }
                        size = Size.large
                        Menu()
                        onClick = {
                            openModuleNavigationStateSetter(!openModuleNavigation)
                        }
                    }
                    Drawer {
                        open = openModuleNavigation
                        onBackdropClick = {
                            openModuleNavigationStateSetter(!openModuleNavigation)
                        }
                        ModuleNavigation {
                            this.moduleName = moduleName
                            this.openModuleNavigationStateSetter = openModuleNavigationStateSetter
                            this.isLaptop = isLaptop
                        }

                    }
                }
            }
            Grid {
                item = true
                sm = if (isLaptop) 10 else 12
                when (path) {
                    ModulePath.UTILITY_INFORMATION.route -> UtilityInformationContentComponent {
                        listPadding = if (isLaptop) 100 else 0
                    }

                    ModulePath.INITIAL_ONBOARDING.route -> InitialOnboardingComponent {
                        listPadding = if (isLaptop) 100 else 0
                    }

                    ModulePath.ENERGY_MANAGEMENT.route -> EnergyManagementComponent {
                        listPadding = if (isLaptop) 100 else 0
                    }

                    ModulePath.CONTROL_CENTER.route -> ControlCenterComponent {
                        listPadding = if (isLaptop) 100 else 0
                    }

                    ModulePath.FACILITY_MANAGEMENT.route -> FacilityManagementComponent {
                        listPadding = if (isLaptop) 100 else 0
                    }

                    ModulePath.FACILITY_GROUP_MANAGEMENT.route -> FacilityGroupManagementComponent {
                        listPadding = if (isLaptop) 100 else 0
                    }

                    ModulePath.USER_MANAGEMENT.route -> UserManagementComponent {
                        listPadding = if (isLaptop) 100 else 0
                    }

                    ModulePath.ASSET_MANAGEMENT.route -> AssetManagementComponent {
                        listPadding = if (isLaptop) 100 else 0
                    }

                    ModulePath.WORK_ORDERS.route -> WorkOrdersComponent {
                        listPadding = if (isLaptop) 100 else 0
                    }
                }
            }
        }
    }
}