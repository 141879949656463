package com.ecosave.watch.portal.pages

import com.ecosave.watch.portal.Themes
import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.helpers.Colors
import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.setUserContext
import com.ecosave.watch.portal.helpers.common.xs
import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.services.auth.loginService
import com.ecosave.watch.portal.styles.CommonStyles
import com.ecosave.watch.portal.useGlobalState
import emotion.react.css
import io.ktor.http.*
import js.core.jso
import kotlinx.coroutines.launch
import mui.icons.material.Visibility
import mui.icons.material.VisibilityOff
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.FormControlVariant
import mui.material.Grid
import mui.material.IconButton
import mui.material.InputAdornment
import mui.material.InputAdornmentPosition
import mui.material.InputBaseProps
import mui.material.Paper
import mui.material.Size
import mui.material.TextField
import mui.material.Typography
import mui.material.TypographyAlign
import mui.material.styles.ThemeProvider
import mui.material.styles.TypographyVariant
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.create
import react.dom.html.ReactHTML.img
import react.dom.onChange
import react.router.dom.Link
import react.router.useNavigate
import react.useState
import web.cssom.Color
import web.cssom.Padding
import web.cssom.Position
import web.cssom.TextAlign
import web.cssom.pct
import web.cssom.px
import web.cssom.unaryMinus
import web.html.HTMLInputElement
import web.html.InputType
import com.ecosave.watch.portal.styles.SignInPageStyles as styles

val SignIn = FC<Props> {

    val navigate = useNavigate()
    var email by useState("")
    var password by useState("")
    var isEmailEmpty by useState(false)
    var isPasswordEmpty by useState(false)
    var isSubmitting by useState(false)
    var showPassword by useState(false)
    val (notificationState, setNotificationState) = useState(NotificationState())
    val stateContextValue = useGlobalState()

    Box {
        className = CommonStyles.BASIC_BACK.cssClass
        ThemeProvider {
            theme = Themes.lightTheme
            Paper {
                className = styles.CENTER.cssClass
                sx {
                    position = Position.relative
                }
                img {
                    css {
                        width = 100.pct
                    }
                    src = "/images/logo/company-logo-dark.jpg"
                }
                Grid {
                    container = true
                    spacing = responsive(5)
                    sx {
                        padding = Padding(30.px, 50.px)
                    }
                    Grid {
                        item = true
                        xs = 12
                        Typography {
                            sx {
                                color = Color(Colors.MAIN_BLUE)
                                marginBottom = -16.px
                            }
                            align = TypographyAlign.center
                            variant = TypographyVariant.h3
                            +"Welcome!"
                        }
                    }
                    Grid {
                        item = true
                        xs = 12
                        Typography {
                            sx {
                                color = Color(Colors.DARK_BLUE)
                            }
                            variant = TypographyVariant.h6
                            align = TypographyAlign.center
                            +"Please sign into your Account"
                        }
                    }
                    Grid {
                        item = true
                        xs = 12
                        TextField {
                            type = InputType.email
                            label = ReactNode("Email Address")
                            fullWidth = true
                            variant = FormControlVariant.outlined
                            error = isEmailEmpty
                            value = email
                            helperText = if (isEmailEmpty) ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE) else null
                            onChange = {
                                val target = it.target as HTMLInputElement
                                email = target.value
                            }
                            onBlur = {
                                isEmailEmpty = email.isBlank()
                            }
                        }
                    }
                    Grid {
                        item = true
                        xs = 12
                        TextField {
                            type = if (showPassword) InputType.text else InputType.password
                            label = ReactNode("Password")
                            fullWidth = true
                            variant = FormControlVariant.outlined
                            error = isPasswordEmpty
                            value = password
                            helperText = if (isPasswordEmpty) ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE) else null
                            onChange = {
                                val target = it.target as HTMLInputElement
                                password = target.value
                            }
                            onBlur = {
                                isPasswordEmpty = password.isBlank()
                            }
                            asDynamic().InputProps = jso<InputBaseProps> {
                                endAdornment = InputAdornment.create {
                                    position = InputAdornmentPosition.end
                                    IconButton {
                                        onClick = {
                                            showPassword = !showPassword
                                        }
                                        if (showPassword) Visibility() else VisibilityOff()
                                    }
                                }
                            }
                        }
                    }
                    Grid {
                        item = true
                        xs = 12
                        Box {
                            sx {
                                textAlign = TextAlign.right
                            }
                            Link {
                                to = "/recover-password"
                                className = styles.LINK.cssClass
                                css {
                                    color = Color(Colors.DARK_BLUE)
                                }
                                +"Recover password"
                            }
                        }

                    }
                    Grid {
                        item = true
                        xs = 12
                        Button {
                            variant = ButtonVariant.contained
                            fullWidth = true
                            size = Size.large
                            disabled = isSubmitting
                            if (isSubmitting) +"Signing In..." else +"Sign In"
                            onClick = {
                                isEmailEmpty = email.isBlank()
                                isPasswordEmpty = password.isBlank()
                                if (email.isNotBlank() && password.isNotBlank()) {
                                    mainScope.launch {
                                        isSubmitting = true
                                        val response = loginService(email, password)
                                        if (response == null) {
                                            setNotificationState(
                                                notificationState.copy(
                                                    status = NotificationStatus.ERROR,
                                                    message = Constants.NOTIFICATION_ERROR_MESSAGE,
                                                    visible = true
                                                )
                                            )
                                        } else if (response.httpStatusCode == HttpStatusCode.Unauthorized) {
                                            setNotificationState(
                                                notificationState.copy(
                                                    status = NotificationStatus.ERROR,
                                                    message = "Invalid Email or Password.",
                                                    visible = true
                                                )
                                            )
                                        } else if (response.httpStatusCode == HttpStatusCode.OK) {
                                            setUserContext(response.loginResponse!!.token, response.loginResponse.onBoardingStatus, stateContextValue, navigate)
                                        }
                                        isSubmitting = false
                                    }
                                }
                            }
                        }

                    }
                    Grid {
                        item = true
                        xs = 12
                        Box {
                            className = styles.LINK.cssClass
                            +"Don't have an account?"
                            Link {
                                to = "/register"
                                css {
                                    color = Color(Colors.DARK_BLUE)
                                    marginLeft = 10.px
                                }
                                +"Create Account"
                            }
                        }
                    }
                }
            }
        }
        AlertNotifications {
            open = notificationState.visible
            status = notificationState.status
            message = notificationState.message
            closeNotification = {
                setNotificationState(
                    notificationState.copy(
                        visible = false
                    )
                )
            }
        }
    }
}