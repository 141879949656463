package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class TrainingAndEducationState(
    var breakdownOf4041List: MutableList<BreakdownOf4041> = mutableListOf(),

    var section4042A1: String? = null,
    var section4042A2: String? = null,

    var breakdownOf4043List: MutableList<BreakdownOf4043> = mutableListOf(),

    var section3_3_404A1: String? = null,
    var section3_3_404A2: String? = null,
    var section3_3_404A3: String? = null,
    var section3_3_404A4: String? = null,
    var section3_3_404A5: String? = null,
    var section3_3_404A6: String? = null
)

@Serializable
data class BreakdownOf4043(
    var section4043Current1: String? = null,
    var section4043Current2: String? = null,
    var section4043Current3: Double? = null
) : DynamicTableItemKey()

@Serializable
data class BreakdownOf4041(
    var section4041Current1: String? = null,
    var section4041Current2: String? = null,
    var section4041Current3: Long? = null
) : DynamicTableItemKey()