package com.ecosave.watch.portal.components.privacypolicy

import com.ecosave.watch.portal.helpers.privacypolicy.getMailToWithSubject
import react.FC
import react.Props

val CollectionFromOtherSources = FC<Props> {
    HeadingWrapper {
        heading = "Collection from Other Sources"
    }
    TypographyWrapper {
        +"Information from Third Parties: We may obtain information, including Personal Information, from our business partners and service providers. This information includes, but is not limited to, information that we receive from our direct marketing providers, product referrals, and other interactions. We also may combine information we receive from third parties with other information we collect from you through our Services as described in this Privacy Statement. If we use this information to provide you with opportunities that we think may be of interest to you, you will have the ability to inform us that you do not wish to receive such offers, and you may unsubscribe from our marketing and other email communications by clicking on the link in the email, sending an email to "
        CustomLink {
            to = TermsOfServiceConstants.ecosaveComplicanceEmail.getMailToWithSubject("Compliance")
            text = TermsOfServiceConstants.ecosaveComplicanceEmail
        }
        +" or accessing your user account and changing your distribution preferences."
    }
    HeadingWrapper {
        heading = "Information Provided by Other Individuals:"
        subHeading = true
    }
    TypographyWrapper {
        +"While using our Services, individuals may provide information about another individual, or an authorized user (such as an account administrator) creating an account on your behalf may provide information about you. When one individual provides us with information (including Personal Information) about another individual, we assume that the individual has permission and authority to do so and to consent on behalf of that individual to the collection and use of Personal Information as described in this Privacy Statement. Please contact us immediately at "
        CustomLink {
            to = TermsOfServiceConstants.ecosaveComplicanceEmail.getMailToWithSubject("Compliance")
            text = TermsOfServiceConstants.ecosaveComplicanceEmail
        }
        +" if you become aware of an individual providing us with Personal Information about another individual without being authorized to do so, and we will act consistently with this Privacy Statement."
    }
}
