package com.ecosave.watch.portal.pages

import com.ecosave.watch.portal.components.privacypolicy.TypographyWrapper
import com.ecosave.watch.portal.components.privacypolicy.AutomaticCollection
import com.ecosave.watch.portal.components.privacypolicy.CaliforniaResidentsPrivacyPolicy
import com.ecosave.watch.portal.components.privacypolicy.CanadianResidentsPrivacyPolicy
import com.ecosave.watch.portal.components.privacypolicy.CollectionFromOtherSources
import com.ecosave.watch.portal.components.privacypolicy.ColoradoResidentsPrivacyPolicy
import com.ecosave.watch.portal.components.privacypolicy.ConnecticutPrivacyPolicy
import com.ecosave.watch.portal.components.privacypolicy.CustomLink
import com.ecosave.watch.portal.components.privacypolicy.DataProvidedByCustomers
import com.ecosave.watch.portal.components.privacypolicy.EUResidentsPrivacyPolicy
import com.ecosave.watch.portal.components.privacypolicy.MainContentScopeAndGeography
import com.ecosave.watch.portal.components.privacypolicy.PersonalInformationDefinitions
import com.ecosave.watch.portal.components.privacypolicy.TermsOfServiceConstants
import com.ecosave.watch.portal.components.privacypolicy.UKResidentsPrivacyPolicy
import com.ecosave.watch.portal.components.privacypolicy.UtahResidentsPrivacyPolicy
import com.ecosave.watch.portal.components.privacypolicy.VirginiaResidentsPrivacyPolicy
import com.ecosave.watch.portal.components.privacypolicy.WhenAndWhyWeShareOrDisclosePersonalInformation
import com.ecosave.watch.portal.components.privacypolicy.WhyWeCollectDataFromAndAboutYou
import com.ecosave.watch.portal.helpers.Colors
import com.ecosave.watch.portal.helpers.privacypolicy.getMailToWithSubject
import mui.material.Box
import mui.material.Typography
import mui.material.TypographyAlign
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import web.cssom.Color
import web.cssom.FontWeight
import web.cssom.Padding
import web.cssom.px

val PrivacyPolicyPage = FC<Props> {
    Box {
        sx {
            padding = Padding(50.px, 150.px, 50.px, 150.px)
            backgroundColor = Color(Colors.WHITE)
            color = Color(Colors.MAIN_BLUE)
        }
        Box {
            sx {
                marginBottom = 30.px
            }
            Typography {
                sx {
                    fontWeight = FontWeight.bolder
                }
                variant = TypographyVariant.h3
                align = TypographyAlign.center
                +"EcosaveWatch's Privacy Policy"
            }
        }
        Box {
            Typography {
                sx {
                    fontWeight = FontWeight.bold
                }
                variant = TypographyVariant.subtitle1
                +"(Last updated December 2023)"
            }
            MainContentScopeAndGeography()
            UKResidentsPrivacyPolicy()
            EUResidentsPrivacyPolicy()
            CanadianResidentsPrivacyPolicy()
            CaliforniaResidentsPrivacyPolicy()
            ColoradoResidentsPrivacyPolicy()
            ConnecticutPrivacyPolicy()
            UtahResidentsPrivacyPolicy()
            VirginiaResidentsPrivacyPolicy()
            TypographyWrapper {
                +"If you have any questions or concerns about this Privacy Statement or about our privacy or data security practices, please contact us at "
                CustomLink {
                    to = TermsOfServiceConstants.ecosavePrivacyEmail.getMailToWithSubject("Privacy Statement")
                    text = TermsOfServiceConstants.ecosavePrivacyEmail
                }
            }
            PersonalInformationDefinitions()
            AutomaticCollection()
            CollectionFromOtherSources()
            DataProvidedByCustomers()
            WhyWeCollectDataFromAndAboutYou()
            WhenAndWhyWeShareOrDisclosePersonalInformation()
        }
    }
}