package com.ecosave.watch.portal.components.controlcenter

import com.ecosave.watch.portal.helpers.Colors
import com.ecosave.watch.portal.helpers.controlcenter.getValues
import mui.material.Box
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import web.cssom.Color
import web.cssom.px

external interface TooltipContentProps : Props {
    var active: Boolean
    var payload: Array<dynamic>
    var label: String?
}

val TooltipContent = FC<TooltipContentProps> { props ->

    if (props.active && props.payload.isNotEmpty() && props.label is String) {
        val items = getValues(props.payload)
        Box {
            sx {
                paddingTop = 10.px
                paddingBottom = 10.px
                paddingLeft = 8.px
                paddingRight = 8.px
                borderRadius = 3.px
                backgroundColor = Color(Colors.DARK_GRAY)
            }
            Typography {
                sx {
                    color = Color(Colors.WHITE)
                }
                variant = TypographyVariant.h6
                +props.label
            }
            for (item in items) {
                Typography {
                    sx {
                        marginTop = 2.px
                        color = Color(item.color)
                    }
                    +item.value
                }
            }
        }
    }
}