package com.ecosave.watch.portal.models.billing

import com.ecosave.watch.portal.helpers.common.AutoCompleteOption

data class UtilityBillSummaryTableFilter(
    var utilityType: MutableList<AutoCompleteOption> = mutableListOf(),
    var serviceName: MutableList<AutoCompleteOption> = mutableListOf(),
    var accountNumber: MutableList<AutoCompleteOption> = mutableListOf(),
    var billStartDate: String = "",
    var billEndDate: String = "",
)

data class UtilityBillSummaryFilterParams(
    var params: String = "",
    var counter: Int = 0
)