package com.ecosave.watch.portal.helpers.resourcecenter

val moduleItemToRouteMap = mapOf(
    "Choose Bill" to "choose-bill",
    "Confirm Selection" to "confirm-selection",
    "Asset Management Overview" to "asset-management-overview",
    "Add Asset" to "add-asset",
    "Column Actions" to "column-actions",
    "Select Asset Management" to "select-asset-management",
    "Show/Hide Search" to "show-hide-search",
    "Show/Hide Filters" to "show-hide-filters",
    "Show/Hide Columns" to "show-hide-columns",
    "Create Company/Admin Account" to "create-company-admin-account",
    "Verify Account" to "verify-account",
    "Sign In" to "sign-in",
    "Energy Management Overview - Default View" to "energy-management-default-view",
    "Energy Management Overview - Baseline Comparison" to "energy-management-overview-baseline-comparison",
    "Set Savings Target" to "set-savings-target",
    "Selection of Baseline" to "selection-of-baseline",
    "Energy Management - Portfolio Overview" to "energy-management-portfolio-overview",
    "Select Portfolio Overview" to "select-portfolio-overview",
    "Facility-Group" to "facility-group",
    "Control Center Overview" to "control-center-overview",
    "Facility Management Overview" to "facility-management-overview",
    "Facility Management Overview - Expanded" to "facility-management-overview-expanded",
    "Edit Operating Hours" to "edit-operating-hours",
    "Edit Facility" to "edit-facility",
    "Facility Group Management Overview" to "facility-group-management-overview",
    "Add Facility Group" to "add-facility-group",
    "User Management Overview" to "user-management-overview",
    "Edit User Role" to "edit-user-role",
    "Invite Users" to "invite-users",
    "Esg Reporting Overview" to "esg-reporting-overview",
    "Creating a Report" to "creating-a-report",
    "Downloading Data" to "downloading-data",
    "Action Management Overview" to "action-management-overview",
    "Action Management Filter" to "action-management-filter",
    "Observation Summary Overview" to "observation-summary-overview",
    "Observation Summary Filter" to "observation-summary-filter",
)