package com.ecosave.watch.portal.models.workorder

import kotlinx.serialization.Serializable

@Serializable
data class ActionUpdateResponseDataClass(
    val closedDate: String?,
    val assignedUsername: String?,
    val assignedEmailAddress: String?,
    var closedByUsername: String?,
    var closedByEmailAddress: String?
)