package com.ecosave.watch.portal.components.esg.anticorruption

import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val Disclosure_205_2 = FC<EsgSubFormComponentProps> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea
    val onChangeText = props.onChangeText
    val onBlurText = props.onBlurText
    val onChangeNumber = props.onChangeNumber
    val onBlurNumber = props.onBlurNumber
    val onChangeDecimalNumber = props.onChangeDecimalNumber
    val onBlurDecimalNumber = props.onBlurDecimalNumber

    props.esgReportState.report?.antiCorruption?.let { state ->

        val communicationAndTrainingListName = state::communicationAndTrainingList.name
        val employeeCategoryListName = state::employeeCategoryList.name
        val businessPartnerTypeListName = state::businessPartnerTypeList.name
        val governanceBodyTrainingListName = state::governanceBodyTrainingList.name
        val employeeCategoryTrainingListName = state::employeeCategoryTrainingList.name

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_205_2
        }

        DisclosureSubHeading {
            subHeading =
                "Total number and percentage of governance body members that the organization’s anti - corruption policies and procedures have been communicated to, broken down by region."
        }

        Box {
            className = ESGCommonStyles.TABLE_WRAPPER.cssClass
            DynamicTableWrapper {
                addRowOrSection = {
                    props.addRowOrSection(communicationAndTrainingListName)
                }
                items = state.communicationAndTrainingList
                action = DynamicTableAction.ADD
                addRowOrSectionBtnText = ReportConstants.ADD_ROW_MESSAGE
                Box {
                    className = ESGCommonStyles.THREE_COLUMN_TABLE.cssClass
                    TableHeader {
                        header = "Region"
                    }
                    TableHeader {
                        header = "Number of Governance Body Members"
                    }
                    TableHeader {
                        header = "Percentage of Governance Body Members"
                    }
                }
            }
            state.communicationAndTrainingList.forEachIndexed { index, it ->
                DynamicTableWrapper {
                    key = it.key
                    deleteRowOrSection = {
                        props.deleteRowOrSection(communicationAndTrainingListName, index)
                    }
                    items = state.communicationAndTrainingList
                    action = DynamicTableAction.DELETE
                    variant = DynamicTableVariant.TABLE
                    Box {
                        className = ESGCommonStyles.THREE_COLUMN_TABLE.cssClass
                        ESGTextFieldWrapper {
                            name = it::section2052Part2A1.name
                            value = it.section2052Part2A1 ?: ""
                            onChange = { event -> onChangeText(event, index, communicationAndTrainingListName) }
                            onBlur = { event -> onBlurText(event, index, communicationAndTrainingListName) }
                        }
                        ESGNumberFieldWrapper {
                            name = it::section2052Part2B1.name
                            value = it.section2052Part2B1 ?: ""
                            onChange = { event -> onChangeNumber(event, index, communicationAndTrainingListName) }
                            onBlur = { event -> onBlurNumber(event, index, communicationAndTrainingListName) }
                        }
                        ESGNumberFieldWrapper {
                            name = it::section2052Part2C1.name
                            value = it.section2052Part2C1 ?: ""
                            onChange = { event -> onChangeDecimalNumber(event, index, communicationAndTrainingListName) }
                            onBlur = { event -> onBlurDecimalNumber(event, index, communicationAndTrainingListName) }
                        }
                    }
                }
            }
        }

        DisclosureSubHeading {
            subHeading =
                "Total number and percentage of employees that the organization’s anti-corruption policies and procedures have been communicated to, broken down by employee category and region."
        }

        Box {
            className = ESGCommonStyles.TABLE_WRAPPER.cssClass
            DynamicTableWrapper {
                addRowOrSection = {
                    props.addRowOrSection(employeeCategoryListName)
                }
                items = state.employeeCategoryList
                action = DynamicTableAction.ADD
                addRowOrSectionBtnText = ReportConstants.ADD_ROW_MESSAGE
                Box {
                    className = ESGCommonStyles.FOUR_COLUMN_TABLE.cssClass
                    TableHeader {
                        header = "Employee Category"
                    }
                    TableHeader {
                        header = "Region"
                    }
                    TableHeader {
                        header = "Number of Employees"
                    }
                    TableHeader {
                        header = "Percentage of Employees"
                    }
                }
            }
            state.employeeCategoryList.forEachIndexed { index, it ->
                DynamicTableWrapper {
                    key = it.key
                    deleteRowOrSection = {
                        props.deleteRowOrSection(employeeCategoryListName, index)
                    }
                    items = state.employeeCategoryList
                    action = DynamicTableAction.DELETE
                    variant = DynamicTableVariant.TABLE
                    Box {
                        className = ESGCommonStyles.FOUR_COLUMN_TABLE.cssClass
                        ESGTextFieldWrapper {
                            name = it::section2052Part3A1.name
                            value = it.section2052Part3A1 ?: ""
                            onChange = { event -> onChangeText(event, index, employeeCategoryListName) }
                            onBlur = { event -> onBlurText(event, index, employeeCategoryListName) }
                        }
                        ESGTextFieldWrapper {
                            name = it::section2052Part3B1.name
                            value = it.section2052Part3B1 ?: ""
                            onChange = { event -> onChangeText(event, index, employeeCategoryListName) }
                            onBlur = { event -> onBlurText(event, index, employeeCategoryListName) }
                        }
                        ESGNumberFieldWrapper {
                            name = it::section2052Part3C1.name
                            value = it.section2052Part3C1 ?: ""
                            onChange = { event -> onChangeNumber(event, index, employeeCategoryListName) }
                            onBlur = { event -> onBlurNumber(event, index, employeeCategoryListName) }
                        }
                        ESGNumberFieldWrapper {
                            name = it::section2052Part3D1.name
                            value = it.section2052Part3D1 ?: ""
                            onChange = { event -> onChangeDecimalNumber(event, index, employeeCategoryListName) }
                            onBlur = { event -> onBlurDecimalNumber(event, index, employeeCategoryListName) }
                        }
                    }
                }
            }
        }

        DisclosureSubHeading {
            subHeading =
                "Total number and percentage of business partners that the organization’s anti-corruption policies and procedures have been communicated to, broken down by type of business partner and region."
        }

        Box {
            className = ESGCommonStyles.TABLE_WRAPPER.cssClass
            DynamicTableWrapper {
                addRowOrSection = {
                    props.addRowOrSection(businessPartnerTypeListName)
                }
                items = state.businessPartnerTypeList
                action = DynamicTableAction.ADD
                addRowOrSectionBtnText = ReportConstants.ADD_ROW_MESSAGE
                Box {
                    className = ESGCommonStyles.FOUR_COLUMN_TABLE.cssClass
                    TableHeader {
                        header = "Business Partner Type"
                    }
                    TableHeader {
                        header = "Region"
                    }
                    TableHeader {
                        header = "Number of Business Partners"
                    }
                    TableHeader {
                        header = "Percentage of Business Partners "
                    }
                }
            }
            state.businessPartnerTypeList.forEachIndexed { index, it ->
                DynamicTableWrapper {
                    key = it.key
                    deleteRowOrSection = {
                        props.deleteRowOrSection(businessPartnerTypeListName, index)
                    }
                    items = state.businessPartnerTypeList
                    action = DynamicTableAction.DELETE
                    variant = DynamicTableVariant.TABLE
                    Box {
                        className = ESGCommonStyles.FOUR_COLUMN_TABLE.cssClass
                        ESGTextFieldWrapper {
                            name = it::section2052Part4A1.name
                            value = it.section2052Part4A1 ?: ""
                            onChange = { event -> onChangeText(event, index, businessPartnerTypeListName) }
                            onBlur = { event -> onBlurText(event, index, businessPartnerTypeListName) }
                        }
                        ESGTextFieldWrapper {
                            name = it::section2052Part4B1.name
                            value = it.section2052Part4B1 ?: ""
                            onChange = { event -> onChangeText(event, index, businessPartnerTypeListName) }
                            onBlur = { event -> onBlurText(event, index, businessPartnerTypeListName) }
                        }
                        ESGNumberFieldWrapper {
                            name = it::section2052Part4C1.name
                            value = it.section2052Part4C1 ?: ""
                            onChange = { event -> onChangeNumber(event, index, businessPartnerTypeListName) }
                            onBlur = { event -> onBlurNumber(event, index, businessPartnerTypeListName) }
                        }
                        ESGNumberFieldWrapper {
                            name = it::section2052Part4D1.name
                            value = it.section2052Part4D1 ?: ""
                            onChange = { event -> onChangeDecimalNumber(event, index, businessPartnerTypeListName) }
                            onBlur = { event -> onBlurDecimalNumber(event, index, businessPartnerTypeListName) }
                        }
                    }
                }
            }
        }

        Box {
            label {
                +"Describe if the organization’s anti-corruption policies and procedures have been communicated to any other persons or organizations."
            }
            ESGTextAreaFieldWrapper {
                name = state::section2052Part4Field1.name
                value = state.section2052Part4Field1
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        DisclosureSubHeading {
            subHeading =
                "Total number and percentage of governance body members that have received training on anti-corruption, broken down by region."
        }

        Box {
            className = ESGCommonStyles.TABLE_WRAPPER.cssClass
            DynamicTableWrapper {
                addRowOrSection = {
                    props.addRowOrSection(governanceBodyTrainingListName)
                }
                items = state.governanceBodyTrainingList
                action = DynamicTableAction.ADD
                addRowOrSectionBtnText = ReportConstants.ADD_ROW_MESSAGE
                Box {
                    className = ESGCommonStyles.THREE_COLUMN_TABLE.cssClass
                    TableHeader {
                        header = "Region"
                    }
                    TableHeader {
                        header = "Number of Governance Body Members"
                    }
                    TableHeader {
                        header = "Percentage of Governance Body Members"
                    }
                }
            }
            state.governanceBodyTrainingList.forEachIndexed { index, it ->
                DynamicTableWrapper {
                    key = it.key
                    deleteRowOrSection = {
                        props.deleteRowOrSection(governanceBodyTrainingListName, index)
                    }
                    items = state.governanceBodyTrainingList
                    action = DynamicTableAction.DELETE
                    variant = DynamicTableVariant.TABLE
                    Box {
                        className = ESGCommonStyles.THREE_COLUMN_TABLE.cssClass
                        ESGTextFieldWrapper {
                            name = it::section2052Part5A1.name
                            value = it.section2052Part5A1 ?: ""
                            onChange = { event -> onChangeText(event, index, governanceBodyTrainingListName) }
                            onBlur = { event -> onBlurText(event, index, governanceBodyTrainingListName) }
                        }
                        ESGNumberFieldWrapper {
                            name = it::section2052Part5B1.name
                            value = it.section2052Part5B1 ?: ""
                            onChange = { event -> onChangeNumber(event, index, governanceBodyTrainingListName) }
                            onBlur = { event -> onBlurNumber(event, index, governanceBodyTrainingListName) }
                        }
                        ESGNumberFieldWrapper {
                            name = it::section2052Part5C1.name
                            value = it.section2052Part5C1 ?: ""
                            onChange = { event -> onChangeDecimalNumber(event, index, governanceBodyTrainingListName) }
                            onBlur = { event -> onBlurDecimalNumber(event, index, governanceBodyTrainingListName) }
                        }
                    }
                }
            }
        }

        DisclosureSubHeading {
            subHeading =
                "Total number and percentage of employees that have received training on anti- corruption, broken down by employee category and region."
        }

        Box {
            className = ESGCommonStyles.TABLE_WRAPPER.cssClass
            DynamicTableWrapper {
                addRowOrSection = {
                    props.addRowOrSection(employeeCategoryTrainingListName)
                }
                items = state.employeeCategoryTrainingList
                action = DynamicTableAction.ADD
                addRowOrSectionBtnText = ReportConstants.ADD_ROW_MESSAGE
                Box {
                    className = ESGCommonStyles.FOUR_COLUMN_TABLE.cssClass
                    TableHeader {
                        header = "Employee Category"
                    }
                    TableHeader {
                        header = "Region"
                    }
                    TableHeader {
                        header = "Number of Employees"
                    }
                    TableHeader {
                        header = "Employees Percentage"
                    }
                }
            }
            state.employeeCategoryTrainingList.forEachIndexed { index, it ->
                DynamicTableWrapper {
                    key = it.key
                    deleteRowOrSection = {
                        props.deleteRowOrSection(employeeCategoryTrainingListName, index)
                    }
                    items = state.employeeCategoryTrainingList
                    action = DynamicTableAction.DELETE
                    variant = DynamicTableVariant.TABLE
                    Box {
                        className = ESGCommonStyles.FOUR_COLUMN_TABLE.cssClass
                        ESGTextFieldWrapper {
                            name = it::section2052Part6A1.name
                            value = it.section2052Part6A1 ?: ""
                            onChange = { event -> onChangeText(event, index, employeeCategoryTrainingListName) }
                            onBlur = { event -> onBlurText(event, index, employeeCategoryTrainingListName) }
                        }
                        ESGTextFieldWrapper {
                            name = it::section2052Part6B1.name
                            value = it.section2052Part6B1 ?: ""
                            onChange = { event -> onChangeText(event, index, employeeCategoryTrainingListName) }
                            onBlur = { event -> onBlurText(event, index, employeeCategoryTrainingListName) }
                        }
                        ESGNumberFieldWrapper {
                            name = it::section2052Part6C1.name
                            value = it.section2052Part6C1 ?: ""
                            onChange = { event -> onChangeNumber(event, index, employeeCategoryTrainingListName) }
                            onBlur = { event -> onBlurNumber(event, index, employeeCategoryTrainingListName) }
                        }
                        ESGNumberFieldWrapper {
                            name = it::section2052Part6D1.name
                            value = it.section2052Part6D1 ?: ""
                            onChange = { event -> onChangeDecimalNumber(event, index, employeeCategoryTrainingListName) }
                            onBlur = { event -> onBlurDecimalNumber(event, index, employeeCategoryTrainingListName) }
                        }
                    }
                }
            }
        }
    }
}