package com.ecosave.watch.portal.models.auth

import com.ecosave.watch.portal.helpers.usermanagement.OnBoardingStatus
import com.ecosave.watch.portal.helpers.usermanagement.Subscription

data class UserData(
    val firstName: String,
    var lastName: String,
    var emailAddress: String,
    var userRole: String,
    val onBoardingStatus: OnBoardingStatus,
    val subscriptions: List<Subscription>
)