package com.ecosave.watch.portal.components.energymanagement

import com.ecosave.watch.portal.components.npm.Bar
import com.ecosave.watch.portal.components.npm.CartesianGrid
import com.ecosave.watch.portal.components.npm.ComposedChart
import com.ecosave.watch.portal.components.npm.Legend
import com.ecosave.watch.portal.components.npm.Line
import com.ecosave.watch.portal.components.npm.ResponsiveContainer
import com.ecosave.watch.portal.components.npm.XAxis
import com.ecosave.watch.portal.components.npm.YAxis
import com.ecosave.watch.portal.helpers.Colors
import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.helpers.billing.UtilityType
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.formatTick
import com.ecosave.watch.portal.helpers.energymanagement.INITIAL_MONTH_PERCENT
import com.ecosave.watch.portal.helpers.energymanagement.MONTHS
import com.ecosave.watch.portal.helpers.energymanagement.RadioLabels
import com.ecosave.watch.portal.helpers.energymanagement.getColorForUtility
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.models.energymanagement.BaselineSummary
import com.ecosave.watch.portal.models.energymanagement.BaselineTargetDataPerUtility
import com.ecosave.watch.portal.models.energymanagement.FacilityTargetSummary
import com.ecosave.watch.portal.models.energymanagement.UtilityData
import com.ecosave.watch.portal.services.energymanagement.saveTarget
import js.core.jso
import js.core.push
import kotlinx.coroutines.launch
import moment.moment
import mui.icons.material.Close
import mui.material.BaseSize
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.FormControlLabel
import mui.material.FormControlVariant
import mui.material.IconButton
import mui.material.Modal
import mui.material.Radio
import mui.material.RadioGroup
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableCellAlign
import mui.material.TableCellPadding
import mui.material.TableContainer
import mui.material.TableHead
import mui.material.TableRow
import mui.material.TextField
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.StateSetter
import react.create
import react.dom.html.ReactHTML
import react.dom.onChange
import react.useEffect
import react.useState
import web.cssom.Color
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.FontWeight
import web.cssom.JustifyContent
import web.cssom.pct
import web.cssom.px
import web.html.HTMLInputElement
import com.ecosave.watch.portal.components.npm.Tooltip as TTip
import com.ecosave.watch.portal.styles.EnergyManagementStyles as styles

fun generateTargetMap(utilities: List<UtilityType>): Map<String, Map<String, Int>> {
    var result = mapOf<String, Map<String, Int>>()
    for (utility in utilities) {
        result = result + mapOf(utility.name to INITIAL_MONTH_PERCENT)
    }
    return result
}

fun generateAllTargetMap(utilities: List<UtilityType>, values: Map<String, Map<String, Int>>?): Map<String, Int> {
    var result = mapOf<String, Int>()
    for (utility in utilities) {
        if (!values.isNullOrEmpty() && values[utility.name].isNullOrEmpty()) {
            val months = values[utility.name]!!.values
            if (months.all { it === months.first() }) {
                result = result + mapOf(utility.name to months.first())
            } else {
                result = result + mapOf(utility.name to 0)
            }
        } else {
            result = result + mapOf(utility.name to 0)
        }
    }
    return result
}

fun generateNewMonths(value: Int): Map<String, Int> {
    return mapOf(
        "JANUARY" to value,
        "FEBRUARY" to value,
        "MARCH" to value,
        "APRIL" to value,
        "MAY" to value,
        "JUNE" to value,
        "JULY" to value,
        "AUGUST" to value,
        "SEPTEMBER" to value,
        "OCTOBER" to value,
        "NOVEMBER" to value,
        "DECEMBER" to value,
    )
}

external interface SavingsTargetComponentProps : Props {
    var isTargetOpen: Boolean
    var closeTarget: () -> Unit
    var facilityId: Int
    var refetchTarget: () -> Unit
    var notification: NotificationState
    var notify: StateSetter<NotificationState>
    var targetSummary: FacilityTargetSummary?
    var utilities: List<UtilityType>
    var baselineData: BaselineSummary
}

val SavingsTargetComponent = FC<SavingsTargetComponentProps> { props ->

    var activeLabel by useState(RadioLabels.USAGE)
    val (saving, setSaving) = useState(false)
    var currentTargetSuumary by useState(generateTargetMap(props.utilities))
    var currentAllTarget by useState(generateAllTargetMap(props.utilities, null))

    useEffect(props.targetSummary, props.utilities, props.facilityId) {
        if (props.utilities.isNotEmpty()) {
            if (props.targetSummary?.targetMap?.get(props.utilities[0].name)?.get("JANUARY") != null) {
                currentTargetSuumary = props.targetSummary!!.targetMap
                currentAllTarget = generateAllTargetMap(props.utilities, props.targetSummary!!.targetMap)
            } else {
                currentTargetSuumary = generateTargetMap(props.utilities)
                currentAllTarget = generateAllTargetMap(props.utilities, null)
            }
        }
    }

    var totalData: Array<BaselineTargetDataPerUtility> = emptyArray()

    for ((index, utility) in props.baselineData.utilityBaselineList.withIndex()) {
        var convertedData = arrayOf<UtilityData>()
        for (data in utility.selectedEnergyBaseline) {
            val month = moment(data.billingMonth, "MMM YYYY").format("MMMM").uppercase()
            val percent = 100 - (currentTargetSuumary[utility.selectedEnergyBaseline[index].utilityType.name]?.get(month) ?: 0)
            val targetUsage = data.utilityUsage * percent / 100.0
            val targetCost = data.utilityCost * percent / 100.0
            val targetCo2Emission = data.utilityCo2Emission * percent / 100.0
            convertedData.push(
                jso {
                    utilityType = utility.selectedEnergyBaseline[index].utilityType.type
                    billingMonthTwo = data.billingMonth
                    utilityUsageTwo = targetUsage
                    utilityCostTwo = targetCost
                    utilityCo2EmissionTwo = targetCo2Emission
                    billingMonth = data.billingMonth
                    utilityUsage = data.utilityUsage
                    utilityCost = data.utilityCost
                    utilityCo2Emission = data.utilityCo2Emission
                    dataKey = data.billingMonth
                }
            )
        }
        totalData.push(BaselineTargetDataPerUtility(props.baselineData.utilityBaselineList[index].utilityType, convertedData))
    }

    Modal {
        open = props.isTargetOpen
        Box {
            className = styles.TARGET_MODAL_CONTAINER.cssClass
            Box {
                className = styles.TARGET_MODAL_CONTENT.cssClass
                sx {
                    backgroundColor = Color(Colors.MAIN_BLUE)
                }
                IconButton {
                    className = styles.BASE_LINE_CLOSE_ICON.cssClass
                    onClick = {
                        props.closeTarget()
                    }
                    Close()
                }
                Typography {
                    sx {
                        fontWeight = FontWeight.bold
                    }
                    variant = TypographyVariant.h5
                    +"What's the percent of savings you'd want"
                }
                Box {
                    sx {
                        display = Display.flex
                        justifyContent = JustifyContent.end
                        width = 100.pct
                    }
                    RadioGroup {
                        sx {
                            flexDirection = FlexDirection.row
                        }
                        value = activeLabel.value
                        onChange = { _, value ->
                            when (value) {
                                RadioLabels.USAGE.value -> {
                                    activeLabel = RadioLabels.USAGE
                                }

                                RadioLabels.COST.value -> {
                                    activeLabel = RadioLabels.COST
                                }

                                else -> {
                                    activeLabel = RadioLabels.CO2
                                }
                            }
                        }
                        for (radioLabel in RadioLabels.values()) {
                            FormControlLabel {
                                value = radioLabel.value
                                control = Radio.create()
                                label = ReactNode(radioLabel.label)
                            }
                        }
                    }
                }
                Box {
                    sx {
                        display = Display.flex
                        justifyContent = JustifyContent.center
                        marginBottom = 30.px
                    }
                    TableContainer {
                        Table {
                            TableHead {
                                TableRow {
                                    TableCell {

                                    }
                                    TableCell {
                                        align = TableCellAlign.center
                                        +"All"
                                    }
                                    for (month in MONTHS) {
                                        TableCell {
                                            align = TableCellAlign.center
                                            +month.take(3)
                                        }
                                    }
                                }
                            }
                            TableBody {
                                for (utility in props.utilities) {
                                    var allValue = 0
                                    val monthValues = currentTargetSuumary[utility.name]?.values
                                    if (!monthValues.isNullOrEmpty()) {
                                        if (monthValues.all { it === monthValues.first() }) {
                                            allValue = monthValues.first()
                                        }
                                    }
                                    TableRow {
                                        sx {
                                            borderWidth = 0.px
                                        }
                                        TableCell {
                                            className = styles.TARGET_TABLE_FIRST_CELL.cssClass
                                            +"${utility.type} (%)"
                                        }
                                        TableCell {
                                            padding = TableCellPadding.none
                                            className = styles.TARGET_TABLE_CELL.cssClass
                                            TextField {
                                                className = styles.TARGET_INPUT.cssClass
                                                value = if (allValue != 0) {
                                                    allValue
                                                } else {
                                                    ""
                                                }
                                                variant = FormControlVariant.standard
                                                size = "large" as BaseSize
                                                onChange = {
                                                    val target = it.target as HTMLInputElement
                                                    var value = 0
                                                    if (target.value != "") {
                                                        var result = target.value.filter { it.isDigit() }
                                                        value = if (result == "" || target.value == "") {
                                                            0
                                                        } else {
                                                            result.toInt()
                                                        }
                                                    }
                                                    if (value > -1 && value < 100) {
                                                        currentTargetSuumary = currentTargetSuumary + mapOf(utility.name to generateNewMonths(value))
                                                        currentAllTarget = currentAllTarget + mapOf(utility.name to value)
                                                    }
                                                }
                                            }
                                        }
                                        for (month in MONTHS) {
                                            TableCell {
                                                padding = TableCellPadding.none
                                                className = styles.TARGET_TABLE_CELL.cssClass
                                                TextField {
                                                    className = styles.TARGET_INPUT.cssClass
                                                    value = currentTargetSuumary[utility.name]?.get(month) ?: ""
                                                    variant = FormControlVariant.standard
                                                    size = "large" as BaseSize
                                                    onChange = {
                                                        val target = it.target as HTMLInputElement
                                                        var value = 0
                                                        if (target.value != "") {
                                                            var result = target.value.filter { it.isDigit() }
                                                            value = if (result == "" || target.value == "") {
                                                                0
                                                            } else {
                                                                result.toInt()
                                                            }
                                                        }
                                                        if (value > -1 && value < 100) {
                                                            var months = currentTargetSuumary?.get(utility.name) ?: INITIAL_MONTH_PERCENT
                                                            var newMonths = months + mapOf(month to value)
                                                            currentTargetSuumary = currentTargetSuumary + mapOf(utility.name to newMonths)
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                Box {
                    className = styles.BASELINE_TARGET_CONTENT.cssClass
                    for (item in totalData) {
                        Box {
                            Typography {
                                variant = TypographyVariant.h6
                                component = ReactHTML.h6
                                sx {
                                    marginBottom = 10.px
                                    marginLeft = 30.px
                                }
                                +"${item.utilityType.type}"
                            }
                            Box {
                                className = styles.BASE_LINE_TARGET_CHART.cssClass
                                ResponsiveContainer {
                                    width = "100%"
                                    height = "100%"
                                    ComposedChart {
                                        width = 500
                                        height = 300
                                        data = JSON.parse(JSON.stringify(item.baselineTargetData))
                                        CartesianGrid {
                                            stroke = Colors.WHITE_SMOKE
                                        }
                                        XAxis {
                                            dataKey = "dataKey"
                                            height = 50
                                            tick = jso {
                                                fill = Colors.WHITE
                                            }
                                        }
                                        YAxis {
                                            type = "number"
                                            tickCount = 10
                                            width = 90
                                            tickFormatter = ::formatTick
                                            tick = jso {
                                                fill = Colors.WHITE
                                            }
                                        }
                                        TTip {
                                            content = TooltipContent
                                            labelStyle = jso {
                                                color = Colors.BLACK
                                            }
                                        }
                                        Bar {
                                            dataKey = activeLabel.value
                                            fill = getColorForUtility(item.utilityType.name)
                                        }
                                        Line {
                                            dataKey = "${activeLabel.value}Two"
                                            stroke = Colors.SCARLET_RED
                                            strokeWidth = 3
                                        }
                                        Legend {
                                            iconSize = 20
                                            iconType = "wye"
                                            formatter = { name: String ->
                                                if (name.contains("Two")) {
                                                    "Target"
                                                } else {
                                                    "Baseline"
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                Box {
                    sx {
                        marginTop = 30.px
                        display = Display.flex
                        justifyContent = JustifyContent.center
                    }
                    Button {
                        variant = ButtonVariant.contained
                        disabled = saving
                        onClick = {
                            mainScope.launch {
                                setSaving(true)
                                val targetSummary = currentTargetSuumary ?: mapOf()
                                val response = saveTarget(FacilityTargetSummary(props.facilityId, targetSummary))
                                if (response === ApiCallStatus.FAILURE) {
                                    props.notify(
                                        props.notification.copy(
                                            status = NotificationStatus.ERROR,
                                            message = Constants.NOTIFICATION_ERROR_MESSAGE,
                                            visible = true
                                        )
                                    )
                                } else {
                                    props.notify(
                                        props.notification.copy(
                                            status = NotificationStatus.SUCCESS,
                                            message = "Target Submitted Successfully",
                                            visible = true
                                        )
                                    )
                                    props.closeTarget()
                                    props.refetchTarget()
                                }
                                setSaving(false)
                            }
                        }
                        if (saving) +"Submitting..." else +"Submit"
                    }
                }
            }
        }
    }
}