package com.ecosave.watch.portal.models.usermanagement

import io.ktor.http.*
import kotlinx.serialization.Serializable

@Serializable
data class UserDeletionState(
    var username: String,
    var emailAddress: String,
    var actionAssigned: Int,
    var actionInProgress: Int
)

data class UserDeletionApiResponseWrapper(
    var userDeletionState: UserDeletionState?,
    var httpStatusCode: HttpStatusCode
)
