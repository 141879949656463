package com.ecosave.watch.portal.components.esg.employment

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val GRI_401_3 = FC<EsgSubFormComponentProps> { props ->
    val onChangeNumber = props.onChangeNumber
    val onBlurNumber = props.onBlurNumber

    props.esgReportState.report?.employment?.let { state ->

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_401_3
        }

        Box {
            className = ESGCommonStyles.THREE_COLUMN_TABLE_8_2_2.cssClass

            TableHeader {
                header = ""
            }
            TableHeader {
                header = "Current Year"
            }
            TableHeader {
                header = "Previous Year"
            }

            label {
                +"Total Number of employees entitled to parental leave"
            }
            ESGNumberFieldWrapper {
                name = state::section4013Current1.name
                value = state.section4013Current1
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section4013Previous1.name
                value = state.section4013Previous1
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }

            label {
                +"Number of male employees entitled to parental leave"
            }
            ESGNumberFieldWrapper {
                name = state::section4013Current2.name
                value = state.section4013Current2
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section4013Previous2.name
                value = state.section4013Previous2
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }

            label {
                +"Number of female employees entitled to parental leave"
            }
            ESGNumberFieldWrapper {
                name = state::section4013Current3.name
                value = state.section4013Current3
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section4013Previous3.name
                value = state.section4013Previous3
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }

            label {
                +"Total Number of employees who took parental leave"
            }
            ESGNumberFieldWrapper {
                name = state::section4013Current4.name
                value = state.section4013Current4
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section4013Previous4.name
                value = state.section4013Previous4
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }

            label {
                +"Number of male employees who took parental leave"
            }
            ESGNumberFieldWrapper {
                name = state::section4013Current5.name
                value = state.section4013Current5
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section4013Previous5.name
                value = state.section4013Previous5
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }

            label {
                +"Number of female employees who took parental leave"
            }
            ESGNumberFieldWrapper {
                name = state::section4013Current6.name
                value = state.section4013Current6
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section4013Previous6.name
                value = state.section4013Previous6
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }

            label {
                +"Total Number of employees that returned to work after parental leave ended"
            }
            ESGNumberFieldWrapper {
                name = state::section4013Current7.name
                value = state.section4013Current7
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section4013Previous7.name
                value = state.section4013Previous7
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }

            label {
                +"Number of male employees that returned to work after parental leave ended"
            }
            ESGNumberFieldWrapper {
                name = state::section4013Current8.name
                value = state.section4013Current8
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section4013Previous8.name
                value = state.section4013Previous8
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }

            label {
                +"Number of female employees that returned to work after parental leave ended"
            }
            ESGNumberFieldWrapper {
                name = state::section4013Current9.name
                value = state.section4013Current9
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section4013Previous9.name
                value = state.section4013Previous9
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }

            label {
                +("Total Number of employees that returned to work after parental leave ended that were still " +
                    "employed 12 months after their return to work")
            }
            ESGNumberFieldWrapper {
                name = state::section4013Current10.name
                value = state.section4013Current10
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section4013Previous10.name
                value = state.section4013Previous10
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }

            label {
                +("Number of male employees that returned to work after parental leave ended that were still " +
                    "employed 12 months after their return to work")
            }
            ESGNumberFieldWrapper {
                name = state::section4013Current11.name
                value = state.section4013Current11
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section4013Previous11.name
                value = state.section4013Previous11
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }

            label {
                +("Number of female employees that returned to work after parental leave ended that were still " +
                    "employed 12 months after their return to work")
            }
            ESGNumberFieldWrapper {
                name = state::section4013Current12.name
                value = state.section4013Current12
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section4013Previous12.name
                value = state.section4013Previous12
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }

            label {
                +"What's the return to work rate of all employees that took parental leave"
            }
            ESGNumberFieldWrapper {
                name = state::section4013Current13.name
                value = state.section4013Current13
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section4013Previous13.name
                value = state.section4013Previous13
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }

            label {
                +"Return to work rate of male employees that took parental leave"
            }
            ESGNumberFieldWrapper {
                name = state::section4013Current14.name
                value = state.section4013Current14
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section4013Previous14.name
                value = state.section4013Previous14
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }

            label {
                +"Return to work rate of female employees that took parental leave"
            }
            ESGNumberFieldWrapper {
                name = state::section4013Current15.name
                value = state.section4013Current15
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section4013Previous15.name
                value = state.section4013Previous15
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }

            label {
                +"What's the retention rate of all employees that took parental leave"
            }
            ESGNumberFieldWrapper {
                name = state::section4013Current16.name
                value = state.section4013Current16
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section4013Previous16.name
                value = state.section4013Previous16
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }

            label {
                +"Retention rate of male employees that took parental leave"
            }
            ESGNumberFieldWrapper {
                name = state::section4013Current17.name
                value = state.section4013Current17
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section4013Previous17.name
                value = state.section4013Previous17
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }


            label {
                +"Retention rate of female employees that took parental leave"
            }
            ESGNumberFieldWrapper {
                name = state::section4013Current18.name
                value = state.section4013Current18
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section4013Previous18.name
                value = state.section4013Previous18
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }

        }

    }
}