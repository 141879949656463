package com.ecosave.watch.portal.components.energymanagement

import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.helpers.billing.UtilityType
import com.ecosave.watch.portal.helpers.common.AutoCompleteOption
import com.ecosave.watch.portal.helpers.energymanagement.RadioLabels
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.assetmanagement.FacilityClass
import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.models.energymanagement.BaselineSelectionStatus
import com.ecosave.watch.portal.models.energymanagement.BaselineSummary
import com.ecosave.watch.portal.models.energymanagement.FacilityDetails
import com.ecosave.watch.portal.models.energymanagement.FacilityTargetSummary
import com.ecosave.watch.portal.models.energymanagement.TargetSummary
import com.ecosave.watch.portal.services.energymanagement.getFacilityImage
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.Button
import mui.material.ButtonColor
import mui.material.ButtonVariant
import mui.material.FormControlLabel
import mui.material.Radio
import mui.material.RadioGroup
import mui.material.Tooltip
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.StateSetter
import react.create
import react.dom.html.ReactHTML
import react.useEffect
import react.useState
import web.cssom.AlignItems
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.JustifyContent
import web.cssom.TextTransform
import web.cssom.px

external interface EnergyManagementHeaderProps : Props {
    var facility: FacilityDetails
    var options: Array<AutoCompleteOption>
    var option: AutoCompleteOption?
    var setActiveOption: (item: AutoCompleteOption) -> Unit
    var activeRadio: RadioLabels
    var setActiveRadio: StateSetter<RadioLabels>
    var data: String
    var baselineStatus: BaselineSelectionStatus
    var baselineProp: BaselineSummary
    var targetProp: TargetSummary
    var refetchBaseline: () -> Unit
    var refetchTarget: () -> Unit
    var isGroup: Boolean?
    var groupName: String?
    var selectGroup: (id: Int, name: String?) -> Unit
    var targetSummary: FacilityTargetSummary?
    var utilities: List<UtilityType>
}

val EnergyManagementHeader = FC<EnergyManagementHeaderProps> { props ->

    var secondary by useState(props.baselineStatus == BaselineSelectionStatus.NOT_SELECTED)
    val (notificationState, setNotificationState) = useState(NotificationState())
    var openTarget by useState(false)
    var openBaseline by useState(false)
    val (photoUpload, setPhotoUpload) = useState(false)
    val (facility, setFacility) = useState(FacilityClass())
    val (facilityImage, setFacilityImage) = useState("")

    useEffect(props.facility.facilityId) {
        mainScope.launch {
            // Below if condition is a temp fix to not call the api for facilityId = 0, facilityId should be initialed and handled properly.
            if (props.facility.facilityId != 0) {
                setFacility(getFacilityImage(props.facility.facilityId))
                facility.imageUrl?.let {
                    setFacilityImage(it)
                }
            }
        }
    }
    useEffect(props.baselineStatus) {
        secondary = props.baselineStatus == BaselineSelectionStatus.NOT_SELECTED
    }

    Box {
        Box {
            sx {
                display = Display.flex
                justifyContent = JustifyContent.spaceBetween
                alignItems = AlignItems.flexEnd
            }
            Box {
                if (props.isGroup == true) {
                    Typography {
                        sx {
                            textTransform = TextTransform.capitalize
                        }
                        variant = TypographyVariant.h6
                        component = ReactHTML.h6
                        +"Selected Group: ${props.groupName}"
                    }
                } else {
                    Box {
                        sx {
                            display = Display.flex
                        }
                        Typography {
                            variant = TypographyVariant.h6
                            component = ReactHTML.h6
                            sx {
                                marginRight = 20.px
                            }
                            +"Facility: "
                        }
                        Box {
                            FacilitySelectionComponent {
                                name = "facility"
                                options = props.options
                                value = props.option ?: AutoCompleteOption("", "")
                                onSelect = {
                                    props.setActiveOption(it)
                                }
                            }
                        }
                    }
                }
            }
            Box {
                if (props.isGroup != true) {
                    Button {
                        sx {
                            marginRight = 15.px
                        }
                        disabled = props.baselineStatus != BaselineSelectionStatus.SELECTED
                        variant = ButtonVariant.outlined
                        onClick = {
                            openTarget = true
                        }
                        +"Set Savings Target"
                    }
                    Tooltip {
                        arrow = true
                        title = ReactNode("You can manage the baseline")
                        disableHoverListener = true
                        Button {
                            sx {
                                marginRight = 15.px
                            }
                            color = if (secondary) {
                                ButtonColor.warning
                            } else {
                                ButtonColor.primary
                            }
                            disabled = props.baselineStatus == BaselineSelectionStatus.NOT_AVAILABLE
                            variant = ButtonVariant.outlined
                            onClick = {
                                openBaseline = true
                            }
                            +"Select Baseline"
                        }
                    }
                }
                if (props.isGroup != true) {
                    UploadPhoto {
                        facilityChild = facility
                        setFacilityChild = setFacility
                        photoUploaded = photoUpload
                        setPhotoUploaded = setPhotoUpload
                    }
                } else {
                    GroupSelectComponent {
                        onSelect = { groupId, name ->
                            props.selectGroup(groupId, name)
                        }
                    }
                }
            }
        }
        Box {
            sx {
                display = Display.flex
                justifyContent = JustifyContent.center
            }
            RadioGroup {
                sx {
                    flexDirection = FlexDirection.row
                }
                value = props.activeRadio.value
                onChange = { _, value ->
                    when (value) {
                        RadioLabels.USAGE.value -> {
                            props.setActiveRadio(RadioLabels.USAGE)
                        }

                        RadioLabels.COST.value -> {
                            props.setActiveRadio(RadioLabels.COST)
                        }

                        else -> {
                            props.setActiveRadio(RadioLabels.CO2)
                        }
                    }
                }
                for (radioLabel in RadioLabels.values()) {
                    FormControlLabel {
                        value = radioLabel.value
                        control = Radio.create()
                        label = ReactNode(radioLabel.label)
                    }
                }
            }
        }
    }
    if (props.isGroup != true) {
        if (props.baselineProp.utilityBaselineList.isNotEmpty()) {
            SavingsTargetComponent {
                facilityId = props.facility.facilityId
                refetchTarget = {
                    props.refetchTarget()
                }
                isTargetOpen = openTarget
                closeTarget = {
                    openTarget = false
                }
                notification = notificationState
                notify = setNotificationState
                targetSummary = props.targetSummary
                utilities = props.utilities
                baselineData = props.baselineProp
            }
        }
        BaseLineComponent {
            isBaselineOpen = openBaseline
            closeBaseline = {
                openBaseline = false
            }
            isSecondary = secondary
            baselineStatus = props.baselineStatus
            data = props.data
            baselineStartMonth = props.baselineProp.startDate
            refetchBaseline = {
                secondary = false
                props.refetchBaseline()
                if (props.targetProp.utilityTargetList?.isNotEmpty() == true) {
                    props.refetchTarget()
                } else {
                    openTarget = true
                }
            }
            facilityId = props.facility.facilityId
            notification = notificationState
            notify = setNotificationState
        }
    }
    AlertNotifications {
        open = notificationState.visible
        status = notificationState.status
        message = notificationState.message
        closeNotification = {
            setNotificationState(
                notificationState.copy(
                    visible = false
                )
            )
        }
    }
}