package com.ecosave.watch.portal.components.utilityaccounts

import com.ecosave.watch.portal.helpers.billing.BillingCommonAnnotations
import com.ecosave.watch.portal.helpers.billing.BillingHeaders
import com.ecosave.watch.portal.helpers.billing.validateBillEstimated
import com.ecosave.watch.portal.models.billing.utilitybillstate.UtilityBillState
import com.ecosave.watch.portal.models.billing.UtilityBillValidationState
import com.ecosave.watch.portal.helpers.common.yesNoDropdown
import com.ecosave.watch.portal.styles.BillingInputFormStyles
import mui.material.FormControl
import mui.material.FormHelperText
import mui.material.InputLabel
import mui.material.MenuItem
import mui.material.Select
import mui.material.Size
import mui.material.Tooltip
import mui.material.TooltipPlacement
import react.FC
import react.Props
import react.ReactNode
import react.StateSetter

external interface AddBillEstimatedProps : Props {
    var state: UtilityBillState
    var setState: StateSetter<UtilityBillState>
    var validation: UtilityBillValidationState
    var setValidation: StateSetter<UtilityBillValidationState>
}

val AddBillEstimated = FC<AddBillEstimatedProps> { props ->
    Tooltip {
        title = ReactNode(BillingCommonAnnotations.BILL_ESTIMATED)
        placement = TooltipPlacement.top
        FormControl {
            className = BillingInputFormStyles.INPUT_ITEM.cssClass
            InputLabel {
                +BillingHeaders.BILL_ESTIMATED.header
            }
            size = Size.medium
            error = props.validation.billEstimatedErrorState
            Select {
                label = ReactNode(BillingHeaders.BILL_ESTIMATED.header)
                value = props.state.billEstimated
                onChange = { event, _ ->
                    props.setState(
                        props.state.copy(
                            billEstimated = event.target.value.toBoolean()
                        )
                    )
                }
                onBlur = {
                    validateBillEstimated(props.state, props.validation, props.setValidation)
                }
                for (item in yesNoDropdown) {
                    MenuItem {
                        value = item.value
                        +item.key
                    }
                }
            }
            FormHelperText {
                +props.validation.billEstimatedErrorMessage
            }
        }
    }
}