package com.ecosave.watch.portal.services.facilitymanagement

import com.ecosave.watch.portal.helpers.BackendServerURL
import com.ecosave.watch.portal.models.facilitymanagement.Facility
import com.ecosave.watch.portal.models.facilitymanagement.FacilityApiResponse
import com.ecosave.watch.portal.services.httpClient
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import kotlinx.datetime.LocalDateTime

suspend fun getFacilities(): MutableList<Facility>? {
    try {
        val response: HttpResponse = httpClient.get("$BackendServerURL/facility/get-facilities")
        if (response.status == HttpStatusCode.OK) {
            return response.body()
        }
    } catch (e: dynamic) {
        console.log(e)
    }
    return null
}

suspend fun createFacility(requestBody: Facility): FacilityApiResponse {
    try {
        val response: HttpResponse = httpClient.post("$BackendServerURL/facility") {
            setBody(requestBody)
        }
        when (response.status) {
            HttpStatusCode.Created -> {
                return FacilityApiResponse(
                    facility = response.body(),
                    statusCode = response.status
                )
            }

            HttpStatusCode.NotFound -> {
                return FacilityApiResponse(
                    facility = null,
                    statusCode = response.status
                )
            }

            HttpStatusCode.Forbidden -> {
                return FacilityApiResponse(
                    facility = null,
                    statusCode = response.status
                )
            }

            HttpStatusCode.ServiceUnavailable -> {
                return FacilityApiResponse(
                    facility = null,
                    statusCode = response.status
                )
            }
        }
    } catch (e: dynamic) {
        console.log("Error: ", e)
    }
    return FacilityApiResponse(
        facility = null,
        statusCode = null
    )
}

suspend fun updateFacility(requestBody: Facility): FacilityApiResponse {
    try {
        val response: HttpResponse = httpClient.put("$BackendServerURL/facility") {
            setBody(requestBody)
        }
        when (response.status) {
            HttpStatusCode.OK -> {
                return FacilityApiResponse(
                    facility = response.body(),
                    statusCode = response.status
                )
            }

            HttpStatusCode.NotFound -> {
                return FacilityApiResponse(
                    facility = null,
                    statusCode = response.status
                )
            }

            HttpStatusCode.Forbidden -> {
                return FacilityApiResponse(
                    facility = null,
                    statusCode = response.status
                )
            }

            HttpStatusCode.ServiceUnavailable -> {
                return FacilityApiResponse(
                    facility = null,
                    statusCode = response.status
                )
            }
        }
    } catch (e: dynamic) {
        console.log("Error: ", e)
    }
    return FacilityApiResponse(
        facility = null,
        statusCode = null
    )
}

suspend fun getEnergyManagementPortalTrialEndDate(): LocalDateTime? {
    try {
        val response: HttpResponse = httpClient.get("$BackendServerURL/payment/getEnergyManagementPortalTrialEndDate")
        if (response.status == HttpStatusCode.OK) {
            return response.body()
        }
    } catch (e: dynamic) {
        console.log(e)
    }
    return null
}

suspend fun connectToEnergyStar(energyStarAccountId: Long): HttpStatusCode? {
    try {
        val response: HttpResponse = httpClient.post("$BackendServerURL/facility/energy-star-account?energyStarAccountId=$energyStarAccountId") {
        }
        if (response.status == HttpStatusCode.Created || response.status == HttpStatusCode.BadRequest || response.status == HttpStatusCode.Found) {
            return response.status
        }
    } catch (e: dynamic) {
        console.log("Error: ", e)
    }
    return null
}

suspend fun getEnergyStarConnectedAccounts(): List<Long>? {
    try {
        val response: HttpResponse = httpClient.get("$BackendServerURL/facility/get-energy-star-account-id")
        if (response.status == HttpStatusCode.OK) {
            return response.body()
        }
    } catch (e: dynamic) {
        console.log(e)
    }
    return null
}