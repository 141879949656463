package com.ecosave.watch.portal.components.resourcecenter

import com.ecosave.watch.portal.styles.NavigationStyles
import com.ecosave.watch.portal.Themes
import com.ecosave.watch.portal.helpers.resourcecenter.ModuleNavigationHelper
import com.ecosave.watch.portal.helpers.resourcecenter.ResourceCenterConstants.moduleContentAssetManagement
import com.ecosave.watch.portal.helpers.resourcecenter.ResourceCenterConstants.moduleContentControlCenter
import com.ecosave.watch.portal.helpers.resourcecenter.ResourceCenterConstants.moduleContentEnergyManagement
import com.ecosave.watch.portal.helpers.resourcecenter.ResourceCenterConstants.moduleContentFacilityGroupManagement
import com.ecosave.watch.portal.helpers.resourcecenter.ResourceCenterConstants.moduleContentFacilityManagement
import com.ecosave.watch.portal.helpers.resourcecenter.ResourceCenterConstants.moduleContentInitialOnboarding
import com.ecosave.watch.portal.helpers.resourcecenter.ResourceCenterConstants.moduleContentUserManagement
import com.ecosave.watch.portal.helpers.resourcecenter.ResourceCenterConstants.moduleContentUtilityInformation
import com.ecosave.watch.portal.helpers.resourcecenter.ResourceCenterConstants.moduleContentWorkOrders
import com.ecosave.watch.portal.styles.ResourceCenterStyles
import mui.material.Box
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.StateSetter
import web.cssom.FontWeight
import web.cssom.px

external interface ModuleNavigationProps : Props {
    var moduleName: String
    var openModuleNavigationStateSetter: StateSetter<Boolean>
    var isLaptop: Boolean
}

val ModuleNavigation = FC<ModuleNavigationProps> {
    Box {
        if (it.isLaptop) {
            className = ResourceCenterStyles.RESOURCE_SIDEBAR.cssClass
        }
        mui.material.List {
            className = NavigationStyles.NAV_OVERFLOW.cssClass
            dense = true
            sx {
                maxWidth = 500.px
                padding = 0.px
            }
            Typography {
                sx {
                    fontWeight = FontWeight.bolder
                    padding = 20.px
                    backgroundColor = Themes.ecosaveDarkLogoBG
                }
                gutterBottom = true
                variant = TypographyVariant.h5
                +"Topics"
            }
            when (it.moduleName) {
                "Initial Onboarding" ->
                    ModuleNavigationHelper {
                        moduleNavigationItemList = moduleContentInitialOnboarding.moduleNavigationItem
                        this.openModuleNavigationStateSetter = it.openModuleNavigationStateSetter
                    }

                "Utility Information" ->
                    ModuleNavigationHelper {
                        moduleNavigationItemList = moduleContentUtilityInformation.moduleNavigationItem
                        this.openModuleNavigationStateSetter = it.openModuleNavigationStateSetter
                    }

                "Energy Management" ->
                    ModuleNavigationHelper {
                        moduleNavigationItemList = moduleContentEnergyManagement.moduleNavigationItem
                        this.openModuleNavigationStateSetter = it.openModuleNavigationStateSetter
                    }

                "Asset Management" ->
                    ModuleNavigationHelper {
                        moduleNavigationItemList = moduleContentAssetManagement.moduleNavigationItem
                        this.openModuleNavigationStateSetter = it.openModuleNavigationStateSetter
                    }

                "Control Center" ->
                    ModuleNavigationHelper {
                        moduleNavigationItemList = moduleContentControlCenter.moduleNavigationItem
                        this.openModuleNavigationStateSetter = it.openModuleNavigationStateSetter
                    }

                "Facility Management" ->
                    ModuleNavigationHelper {
                        moduleNavigationItemList = moduleContentFacilityManagement.moduleNavigationItem
                        this.openModuleNavigationStateSetter = it.openModuleNavigationStateSetter
                    }

                "User Management" ->
                    ModuleNavigationHelper {
                        moduleNavigationItemList = moduleContentUserManagement.moduleNavigationItem
                        this.openModuleNavigationStateSetter = it.openModuleNavigationStateSetter
                    }

                "Facility Group Management" ->
                    ModuleNavigationHelper {
                        moduleNavigationItemList = moduleContentFacilityGroupManagement.moduleNavigationItem
                        this.openModuleNavigationStateSetter = it.openModuleNavigationStateSetter
                    }

                "Work Orders" ->
                    ModuleNavigationHelper {
                        moduleNavigationItemList = moduleContentWorkOrders.moduleNavigationItem
                        this.openModuleNavigationStateSetter = it.openModuleNavigationStateSetter
                    }
            }
        }
    }
}