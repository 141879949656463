package com.ecosave.watch.portal.helpers.facilitymanagement

enum class BuildingCategory(val description: String) : BuildingCategoryService {
    BANKING_FINANCIAL("Banking/financial services") {
        override fun getAllBuildingTypes() = BuildingType.getAllBuildingTypes(BANKING_FINANCIAL)
    },
    EDUCATION("Education") {
        override fun getAllBuildingTypes() = BuildingType.getAllBuildingTypes(EDUCATION)
    },
    ENTERTAINMENT_PUBLIC("Entertainment/public assembly") {
        override fun getAllBuildingTypes() = BuildingType.getAllBuildingTypes(ENTERTAINMENT_PUBLIC)
    },
    FOOD("Food sales and service") {
        override fun getAllBuildingTypes() = BuildingType.getAllBuildingTypes(FOOD)
    },
    HEALTHCARE("Healthcare") {
        override fun getAllBuildingTypes() = BuildingType.getAllBuildingTypes(HEALTHCARE)
    },
    LODGING_RESIDENTIAL("Lodging/residential") {
        override fun getAllBuildingTypes() = BuildingType.getAllBuildingTypes(LODGING_RESIDENTIAL)
    },
    MANUFACTURING_INDUSTRIAL("Manufacturing/industrial") {
        override fun getAllBuildingTypes() = BuildingType.getAllBuildingTypes(MANUFACTURING_INDUSTRIAL)
    },
    MIXED_USE("Mixed use") {
        override fun getAllBuildingTypes() = BuildingType.getAllBuildingTypes(MIXED_USE)
    },
    OFFICE("Office") {
        override fun getAllBuildingTypes() = BuildingType.getAllBuildingTypes(OFFICE)
    },
    PARKING("Parking") {
        override fun getAllBuildingTypes() = BuildingType.getAllBuildingTypes(PARKING)
    },
    PUBLIC_SERVICES("Public services") {
        override fun getAllBuildingTypes() = BuildingType.getAllBuildingTypes(PUBLIC_SERVICES)
    },
    RELIGIOUS_WORSHIP("Religious worship") {
        override fun getAllBuildingTypes() = BuildingType.getAllBuildingTypes(RELIGIOUS_WORSHIP)
    },
    RETAIL("Retail") {
        override fun getAllBuildingTypes() = BuildingType.getAllBuildingTypes(RETAIL)
    },
    TECHNOLOGY_SCIENCE("Technology/science") {
        override fun getAllBuildingTypes() = BuildingType.getAllBuildingTypes(TECHNOLOGY_SCIENCE)
    },
    SERVICES("Services") {
        override fun getAllBuildingTypes() = BuildingType.getAllBuildingTypes(SERVICES)
    },
    UTILITY("Utility") {
        override fun getAllBuildingTypes() = BuildingType.getAllBuildingTypes(UTILITY)
    },
    WAREHOUSE_STORAGE("Warehouse/storage") {
        override fun getAllBuildingTypes() = BuildingType.getAllBuildingTypes(WAREHOUSE_STORAGE)
    };

    companion object {
        fun getBuildingCategory(description: String)  = values().find { it.description == description }
    }
}