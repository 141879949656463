package com.ecosave.watch.portal.components.privacypolicy

import react.FC
import react.Props

val Payment = FC<Props> {
    HeadingWrapper {
        heading = "6. Payment"
    }
    TypographyWrapper {
        +"Pricing: Unless the parties agree otherwise in a separately executed written agreement for a Paying Plan, all fees including taxes for the Service (\"Fees\") will be initially based on the pricing published at the Website as of the Subscription Date for the Initial Period. In order to maintain the quality of the Service, pricing may automatically increase by the greater of 3% or CPI to adjust for inflation, which can increase the cost of improving and maintaining the Service. All or certain of the Fees may be calculated on the basis of the number of users in your organization, number of synchronized repositories, number of executed workflows and automations, and duration of data retention."
    }
    HeadingWrapper {
        heading = "Authorization for payment"
        subHeading = true
    }
    TypographyWrapper {
        +"You agree to give Ecosave permission to charge you using that payment method for any services used during the Service Period. As indicated in a Subscription, Ecosave may bill: in advance; at the time of purchase; shortly after purchase; or on a recurring time- or usage-based basis."
    }
    HeadingWrapper {
        heading = "Responsibility for payment "
        subHeading = true
    }
    TypographyWrapper {
        +"You agree that you are authorized to use the payment method you entered when creating a billing account. You must keep all information in your billing account current. You can access and modify your billing account information through the Website and may change your payment method at any time. If you notify Ecosave to stop using your previously designated payment method and fail to designate an alternative, Ecosave may immediately suspend use and access to the Service. Any notice from you changing your billing account will not affect charges Ecosave submits to your billing account before Ecosave reasonably could act on your request."
    }
    HeadingWrapper {
        heading = "Billing schedule; no refunds"
        subHeading = true
    }
    TypographyWrapper {
        +"Payments for all accounts registered to pay via credit card are due the date the invoice is posted on your account. Payments for all accounts registered to pay via check, wire transfer, or Automated Clearing House (ACH) are due within 30 days of the invoice date unless otherwise agreed-to by the parties in writing. If any payment is not made when due, Ecosave may immediately suspend use and access to the Service. All prepayments, if any, for the Service (monthly, yearly, or otherwise) will be deemed fully earned upon payment and are non-refundable"
    }
    HeadingWrapper {
        heading = "Missed payments"
        subHeading = true
    }
    TypographyWrapper {
        +"Any amount not paid when due will bear interest at the rate of 1.5% per month or the maximum rate permitted by applicable law, whichever is less, computed and compounded daily from the date due until the date paid. Further, in the event of any action by Ecosave to collect any amount not paid when due, you will pay or reimburse Ecosave's costs of collection (including, without limitation, any attorneys' fees and court costs)."
    }
    HeadingWrapper {
        heading = "Pricing changes"
        subHeading = true
    }
    TypographyWrapper {
        +"Except for automatic increases to adjust for inflation pursuant to Section 6.1, Ecosave will notify you in advance, either through the Service or by email pursuant to Section 15.6, if Ecosave changes Fees that would apply to you in a Renewal Period. If you do not agree to these changes, you must give notice of your intent to not renew the Agreement for such Renewal Period and stop using the Service on or before the effective date of termination. If you fail to give notice of non-renewal, your payment information on file will be charged according to the new Fees thereafter."
    }
    HeadingWrapper {
        heading = "Cancellation"
        subHeading = true
    }
    TypographyWrapper {
        +"If you terminate this Agreement early or if it's terminated early by Ecosave pursuant to Section 7, you will not be obligated to pay the Fees following the effective date of termination. In all other cases, and regardless of whether you and your Authorized Users access or use the Service at the levels reflected in the Subscription or otherwise, you are responsible for paying all Fees through expiration of the Service Period."
    }
}
