package com.ecosave.watch.portal.components.facilitymanagement

import com.ecosave.watch.portal.helpers.common.xs
import com.ecosave.watch.portal.helpers.facilitymanagement.FacilityDays
import com.ecosave.watch.portal.helpers.facilitymanagement.facilityTimeMarks
import com.ecosave.watch.portal.models.facilitymanagement.FacilityOperatingScheduleState
import com.ecosave.watch.portal.styles.FacilityManagementStyles
import mui.material.Box
import mui.material.Checkbox
import mui.material.FormControlLabel
import mui.material.Grid
import mui.material.Orientation
import mui.material.Slider
import mui.material.Typography
import mui.material.TypographyAlign
import mui.material.styles.TypographyVariant
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.StateSetter
import react.create
import react.dom.html.ReactHTML
import web.cssom.Display
import web.cssom.FontWeight
import web.cssom.JustifyContent
import web.cssom.pct
import web.cssom.px

external interface FacilityOperatingScheduleProps : Props {
    var state: FacilityOperatingScheduleState
    var setState: StateSetter<FacilityOperatingScheduleState>
}

val FacilityOperatingSchedule = FC<FacilityOperatingScheduleProps> { props ->

    val state = props.state
    val setState = props.setState

    Grid {
        container = true
        spacing = responsive(3)
        Grid {
            item = true
            xs = 12
            Typography {
                variant = TypographyVariant.h6
                align = TypographyAlign.center
                +"Operating Hours"
            }
        }
        Grid {
            item = true
            xs = 12
            FormControlLabel {
                sx {
                    marginBottom = 10.px
                    width = 100.pct
                    justifyContent = JustifyContent.center
                }
                label = ReactNode("This Facility Operates 24/7")
                control = Checkbox.create {
                    checked = state.isOperating24x7
                    onChange = { _, value ->
                        setState(
                            state.copy(
                                isOperating24x7 = value
                            )
                        )
                    }
                }
            }
        }
        Grid {
            item = true
            xs = 12
            Box {
                className = FacilityManagementStyles.FACILITY_TIME_WRAPPER.cssClass
                Box {
                    className = FacilityManagementStyles.FACILITY_TIME_PARENT.cssClass
                    Box {
                        sx {
                            width = 7.pct
                        }
                        Box {
                            sx {
                                width = 100.pct
                                height = 30.pct
                                display = Display.block
                                marginRight = 20.px
                            }
                        }
                        Box {
                            className = FacilityManagementStyles.TIME_BOX_MARKS.cssClass
                            Slider {
                                disabled = true
                                value = arrayOf(0.00, -24.00)
                                orientation = Orientation.vertical
                                marks = facilityTimeMarks
                                min = -23.75
                                max = 00.00
                                step = 0.25
                                scale = { x -> -x.toDouble() }
                            }
                        }
                    }
                    for (day in FacilityDays.values()) {
                        FacilityDailyOperatingSchedule {
                            this.state = state
                            this.setState = setState
                            this.day = day
                        }
                    }
                }
            }
        }
    }
}