package com.ecosave.watch.portal.components.utilitybills

import com.ecosave.watch.portal.helpers.billing.BillingHeaders
import com.ecosave.watch.portal.helpers.billing.BillingSolarHeaders
import com.ecosave.watch.portal.helpers.billing.UtilityType
import com.ecosave.watch.portal.helpers.billing.formatBillingValues
import com.ecosave.watch.portal.helpers.billing.formatSolarBillingLabels
import com.ecosave.watch.portal.helpers.billing.validateSolarExported
import com.ecosave.watch.portal.helpers.billing.validateSolarExportedCredit
import com.ecosave.watch.portal.helpers.billing.validateTotalSolarCost
import com.ecosave.watch.portal.helpers.billing.validateTotalSolarGenerated
import com.ecosave.watch.portal.helpers.common.YesNoEnum
import com.ecosave.watch.portal.models.billing.UtilityBillSummary
import com.ecosave.watch.portal.models.billing.UtilityBillValidationState
import com.ecosave.watch.portal.models.billing.utilitybillstate.UtilityBillState
import com.ecosave.watch.portal.styles.BillingTableStyles
import mui.material.TableCell
import mui.material.TableRow
import react.FC
import react.Props
import react.StateSetter
import react.dom.onChange
import web.html.HTMLInputElement
import web.html.InputType

external interface SolarDataRowProps : Props {
    var editModeSolar: (Boolean)
    var editRowSolar: Boolean
    var stateSolar: UtilityBillState
    var validationSolar: UtilityBillValidationState
    var billSolar: UtilityBillSummary
    var setStateSolar: StateSetter<UtilityBillState>
    var setValidationSolar: StateSetter<UtilityBillValidationState>
}

val SolarDataRow = FC<SolarDataRowProps> { props ->

    val editMode = props.editModeSolar
    val editRow = props.editRowSolar
    val state = props.stateSolar
    val setState = props.setStateSolar
    val validation = props.validationSolar
    val setValidation = props.setValidationSolar
    val bill = props.billSolar

    fun totalSolarCost() {
        TableCell {
            className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
            +"${formatSolarBillingLabels(BillingSolarHeaders.TOTAL_SOLAR_COST, editMode)} *"
        }

        TableCell {
            className = BillingTableStyles.TABLE_CELL.cssClass
            if (editRow) {
                EditableTextFieldWrapper {
                    type = InputType.number
                    value = state.solarBillAdditional.totalSolarCost
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setState(
                            state.copy(
                                solarBillAdditional = state.solarBillAdditional.copy(
                                    totalSolarCost = target.value.toFloatOrNull()
                                )
                            )
                        )
                    }
                    error =
                        validation.solarBillValidationState.totalSolarCostErrorState
                    helperText =
                        validation.solarBillValidationState.totalSolarCostErrorMessage
                    onBlur = {
                        validateTotalSolarCost(state, validation, setValidation)
                    }
                }
            } else {
                +formatBillingValues(
                    bill.utilityBill.solarBillAdditional.totalSolarCost,
                    null
                )
            }
        }
    }

    fun totalSolarGenerated() {
        TableCell {
            className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
            +BillingSolarHeaders.TOTAL_SOLAR_GENERATED.header
        }

        TableCell {
            className = BillingTableStyles.TABLE_CELL.cssClass
            if (editRow) {
                EditableTextFieldWrapper {
                    type = InputType.number
                    value = state.solarBillAdditional.totalSolarGenerated
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setState(
                            state.copy(
                                solarBillAdditional = state.solarBillAdditional.copy(
                                    totalSolarGenerated = target.value.toFloatOrNull()
                                )
                            )
                        )
                    }
                    error =
                        validation.solarBillValidationState.totalSolarGeneratedErrorState
                    helperText =
                        validation.solarBillValidationState.totalSolarGeneratedErrorMessage
                    onBlur = {
                        validateTotalSolarGenerated(state, validation, setValidation)
                    }
                }
            } else {
                +formatBillingValues(
                    bill.utilityBill.solarBillAdditional.totalSolarGenerated,
                    bill.utilityBill.usageUnit.description
                )
            }
        }
    }

    fun billEstimated() {
        TableCell {
            className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
            +BillingHeaders.BILL_ESTIMATED.header
        }

        TableCell {
            className = BillingTableStyles.TABLE_CELL.cssClass
            if (editRow) {
                BillEstimated {
                    stateBillEstimated = state
                    setStateBillEstimated = setState
                }
            } else {
                if (bill.utilityBill.billEstimated == true) +YesNoEnum.YES.description else +YesNoEnum.NO.description
            }
        }
    }

    fun billAdjusted() {
        TableCell {
            className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
            +BillingHeaders.BILL_ADJUSTED.header
        }

        TableCell {
            className = BillingTableStyles.TABLE_CELL.cssClass
            if (editRow) {
                BillAdjusted {
                    stateBillAdjusted = state
                    setStateBillAdjusted = setState
                }
            } else {
                if (bill.utilityBill.billAdjusted == true) +YesNoEnum.YES.description else +YesNoEnum.NO.description
            }
        }
    }

    fun usageUnit() {
        if (editRow) {
            UsageUnit {
                stateUsageUnit = state
                setStateUsageUnit = setState
                usageUnits = UtilityType.SOLAR.usageUnits
            }
        }
    }

    fun solarExported() {
        TableCell {
            className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
            +BillingSolarHeaders.SOLAR_EXPORTED.header
        }

        TableCell {
            className = BillingTableStyles.TABLE_CELL.cssClass
            if (editRow) {
                EditableTextFieldWrapper {
                    type = InputType.number
                    value = state.solarBillAdditional.solarExported
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setState(
                            state.copy(
                                solarBillAdditional = state.solarBillAdditional.copy(
                                    solarExported = target.value.toFloatOrNull()
                                )
                            )
                        )
                    }
                    error =
                        validation.solarBillValidationState.solarExportedErrorState
                    helperText =
                        validation.solarBillValidationState.solarExportedErrorMessage
                    onBlur = {
                        validateSolarExported(state, validation, setValidation)
                    }
                }
            } else {
                +formatBillingValues(
                    bill.utilityBill.solarBillAdditional.solarExported,
                    bill.utilityBill.usageUnit.description
                )
            }
        }
    }

    fun solarExportedCredit() {
        TableCell {
            className = BillingTableStyles.COLLAPSED_TABLE_HEADERS.cssClass
            +"${formatSolarBillingLabels(BillingSolarHeaders.SOLAR_EXPORTED_CREDIT, editMode)} *"
        }

        TableCell {
            className = BillingTableStyles.TABLE_CELL.cssClass
            if (editRow) {
                EditableTextFieldWrapper {
                    type = InputType.number
                    value = state.solarBillAdditional.solarExportedCredit
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setState(
                            state.copy(
                                solarBillAdditional = state.solarBillAdditional.copy(
                                    solarExportedCredit = target.value.toFloatOrNull()
                                )
                            )
                        )
                    }
                    error =
                        validation.solarBillValidationState.solarExportedCreditErrorState
                    helperText =
                        validation.solarBillValidationState.solarExportedCreditErrorMessage
                    onBlur = {
                        validateSolarExportedCredit(state, validation, setValidation)
                    }
                }
            } else {
                +formatBillingValues(
                    bill.utilityBill.solarBillAdditional.solarExportedCredit,
                    null
                )
            }
        }
    }


    if (bill.solarNetMetered == true && bill.solarPPAInPlace == true) {
        TableRow {
            totalSolarGenerated()
            totalSolarCost()
            solarExported()
        }
        TableRow {
            solarExportedCredit()
            billEstimated()
            billAdjusted()
        }
        TableRow {
            usageUnit()
        }
    } else if (bill.solarNetMetered == true && bill.solarPPAInPlace == false) {
        TableRow {
            totalSolarGenerated()
            solarExported()
            solarExportedCredit()
        }
        TableRow {
            billEstimated()
            billAdjusted()
            usageUnit()
        }
    } else if (bill.solarNetMetered == false && bill.solarPPAInPlace == true) {
        TableRow {
            totalSolarGenerated()
            totalSolarCost()
            usageUnit()
        }
    } else if (bill.solarNetMetered == false && bill.solarPPAInPlace == false) {
        TableRow {
            totalSolarGenerated()
            usageUnit()
        }
    }

}