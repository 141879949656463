package com.ecosave.watch.portal.components.privacypolicy

import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import web.cssom.FontWeight

external interface HeadingProps : Props {
    var id: String
    var heading: String
    var subHeading: Boolean
}

val HeadingWrapper = FC<HeadingProps> {
    Typography {
        id = it.id
        sx {
            fontWeight = FontWeight.bolder
        }
        paragraph = true
        variant = if (it.subHeading) TypographyVariant.h5 else TypographyVariant.h4
        +it.heading
    }
}