package com.ecosave.watch.portal.components.facilitymanagement

import com.ecosave.watch.portal.helpers.facilitymanagement.FacilityFields
import com.ecosave.watch.portal.helpers.facilitymanagement.USAStates
import com.ecosave.watch.portal.helpers.facilitymanagement.validateState
import com.ecosave.watch.portal.models.facilitymanagement.FacilityState
import com.ecosave.watch.portal.models.facilitymanagement.FacilityValidationState
import mui.material.FormControl
import mui.material.FormHelperText
import mui.material.InputLabel
import mui.material.MenuItem
import mui.material.Select
import react.FC
import react.Props
import react.ReactNode
import react.StateSetter

external interface FacilityStateProps : Props {
    var state: FacilityState
    var setState: StateSetter<FacilityState>
    var validationState: FacilityValidationState
    var setValidationState: StateSetter<FacilityValidationState>
}

val FacilityState = FC<FacilityStateProps> { props ->
    FormControl {
        fullWidth = true
        InputLabel {
            +FacilityFields.STATE.description
        }
        error = props.validationState.stateErrorState
        Select {
            label = ReactNode(FacilityFields.STATE.description)
            value = props.state.state
            onChange = { event, _ ->
                props.setState(
                    props.state.copy(
                        state = event.target.value
                    )
                )
            }
            onBlur = {
                validateState(props.state, props.validationState, props.setValidationState)
            }
            for (state in USAStates.entries) {
                MenuItem {
                    key = state.postalAbbreviation
                    value = state.postalAbbreviation
                    +state.state
                }
            }
        }
        FormHelperText {
            +props.validationState.stateErrorMessage
        }
    }
}

