package com.ecosave.watch.portal.components.common

import mui.material.CircularProgress
import mui.material.Dialog
import mui.material.DialogContent
import mui.material.DialogContentText
import mui.system.sx
import react.FC
import react.Props
import web.cssom.AlignItems
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.px
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles as styles

external interface DialogSpinnerProps : Props {
    var open: Boolean
    var message: String
}

val DialogSpinner = FC<DialogSpinnerProps> { props ->
    Dialog {
        open = props.open
        DialogContent {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.column
                gap = 10.px
                alignItems = AlignItems.center
            }
            CircularProgress {
                className = styles.ESG_CIRCULAR_PROGRESS.cssClass
            }
            DialogContentText {
                +props.message
            }
        }
    }
}