package com.ecosave.watch.portal.components.resourcecenter.facilitymanagement

import com.ecosave.watch.portal.components.resourcecenter.ResourcesSection
import com.ecosave.watch.portal.helpers.resourcecenter.EnergyManagementImages
import com.ecosave.watch.portal.helpers.resourcecenter.FacilityManagementImages
import com.ecosave.watch.portal.styles.ResourceCenterStyles
import mui.material.Box
import mui.material.List
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML
import web.cssom.px

external interface FacilityManagementComponentProps : Props {
    var listPadding: Number
}

val FacilityManagementComponent = FC<FacilityManagementComponentProps> {

    List {
        sx {
            paddingLeft = it.listPadding.px
        }
        Box {
            id = "facility-management-overview"
            ResourcesSection {
                title = "Facility Management Overview"
                sentence =
                    "This section offers the ability to edit facility data, such as it's name, address or operating hours"
            }
            ReactHTML.img {
                src = FacilityManagementImages.FacilityManagementOverviewImage
                className = ResourceCenterStyles.RESOURCES_DISPLAY_OVERVIEW.cssClass
            }
        }
        Box {
            id = "facility-management-overview-expanded"
            ResourcesSection {
                title = "Facility Management Overview - Expanded"
            }
            ReactHTML.img {
                src = FacilityManagementImages.FacilityManagementOverviewExpandedImage
                className = ResourceCenterStyles.RESOURCES_DISPLAY_OVERVIEW.cssClass
            }
        }

        Box {
            id = "edit-operating-hours"
            ResourcesSection {
                title = "Edit Operating Hours"
                category = "To Adjust Your Selected Facility’s Hours: "
            }
            for (sentence in facilityManagementHoursSentences) {
                ResourcesSection {
                    subCategory = sentence
                }
            }
            ResourcesSection {
                note = "Note: Military Time Used"
            }
            ReactHTML.img {
                src = FacilityManagementImages.EditOperatingHoursImage
                className = ResourceCenterStyles.RESOURCES_DISPLAY_OVERVIEW_BOX.cssClass
            }
        }
        Box {
            id = "edit-facility"
            ResourcesSection {
                title = "Edit Facility"
                category = "To Adjust a Facility’s Data:"
            }
            ReactHTML.img {
                src = EnergyManagementImages.EditFacilityImage
                className = ResourceCenterStyles.RESOURCES_DISPLAY_OVERVIEW_BOX.cssClass
            }
        }
    }
}