package com.ecosave.watch.portal.components.facilitymanagement

import com.ecosave.watch.portal.helpers.common.FormMode
import com.ecosave.watch.portal.helpers.facilitymanagement.FacilityConstants
import com.ecosave.watch.portal.helpers.facilitymanagement.FacilityManagementAddressHeaders
import com.ecosave.watch.portal.helpers.facilitymanagement.FacilityOperatingHoursDefaultValues
import com.ecosave.watch.portal.models.facilitymanagement.Facility
import com.ecosave.watch.portal.styles.CommonStyles
import mui.icons.material.Edit
import mui.icons.material.KeyboardArrowDown
import mui.icons.material.KeyboardArrowUp
import mui.material.Box
import mui.material.Collapse
import mui.material.IconButton
import mui.material.Size
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableRow
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.StateSetter
import react.dom.html.TdAlign
import react.useState
import web.cssom.pct

external interface FacilityManagementTableRowProps : Props {
    var facility: Facility
    var setFormMode: StateSetter<FormMode>
    var setIdOfFacilityToBeUpdated: StateSetter<Int?>
    var setOpenAddOrEditFacilityForm: StateSetter<Boolean>
}

val FacilityManagementTableRow = FC<FacilityManagementTableRowProps> { props ->

    val facility = props.facility
    val (openFacilityRow, setOpenFacilityRow) = useState(false)

    TableRow {
        hover = true
        TableCell {
            IconButton {
                size = Size.small
                onClick = {
                    setOpenFacilityRow(!openFacilityRow)
                }
                when (openFacilityRow) {
                    true -> {
                        KeyboardArrowUp {}
                    }

                    else -> {
                        KeyboardArrowDown {}
                    }
                }
            }
        }
        TableCell {
            IconButton {
                onClick = {
                    props.setFormMode(FormMode.EDIT)
                    props.setIdOfFacilityToBeUpdated(facility.facilityId)
                    props.setOpenAddOrEditFacilityForm(true)
                }
                Edit()
            }
        }
        TableCell {
            +facility.facilityName
        }
        TableCell {
            +facility.buildingCategory.description
        }
        TableCell {
            +facility.buildingType.description
        }
        TableCell {
            +"${facility.buildingTotalArea}"
        }
        TableCell {
            +"${facility.buildingOccupancy} %"
        }
        TableCell {
            +facility.buildingYearBuilt.toString()
        }
        TableCell {
            showEnergyStarSubscriptionStatus(facility.energyStarSubscription, facility.cancellationEffectiveOn)
        }
        TableCell {
            +facility.facilityDescription
        }
    }
    TableRow {
        TableCell {
            sx {
                paddingBottom = 0.pct
                paddingTop = 0.pct
            }
            colSpan = 14
            Collapse {
                `in` = openFacilityRow
                timeout
                Box {
                    sx {
                        margin = 1.pct
                    }
                    Typography {
                        variant = TypographyVariant.h5
                        +"Address"
                    }
                    TableRow {
                        for (addressHeaders in FacilityManagementAddressHeaders.entries) {
                            TableCell {
                                className = CommonStyles.REMOVED_CELL_BOTTOM_BORDER.cssClass
                                +addressHeaders.header
                            }
                        }
                    }
                    TableRow {
                        facility.buildingAddress.let {
                            TableCell {
                                className = CommonStyles.REMOVED_CELL_BOTTOM_BORDER.cssClass
                                +it.street
                            }
                            TableCell {
                                className = CommonStyles.REMOVED_CELL_BOTTOM_BORDER.cssClass
                                +it.city
                            }
                            TableCell {
                                className = CommonStyles.REMOVED_CELL_BOTTOM_BORDER.cssClass
                                +it.state
                            }
                            TableCell {
                                className = CommonStyles.REMOVED_CELL_BOTTOM_BORDER.cssClass
                                +it.zipcode
                            }
                        }
                    }
                    Typography {
                        variant = TypographyVariant.h5
                        +"Operating Hours"
                    }
                    TableRow {
                        TableCell {
                            align = TdAlign.left
                            className = CommonStyles.REMOVED_CELL_BOTTOM_BORDER.cssClass
                            +""
                        }
                        for (day in FacilityConstants.weekDaysList) {
                            TableCell {
                                align = TdAlign.left
                                className = CommonStyles.REMOVED_CELL_BOTTOM_BORDER.cssClass
                                +day.dayAbbreviation
                            }
                        }
                    }
                    TableBody {
                        if (facility.is24x7) {
                            TableRow {
                                TableCell {
                                    align = TdAlign.left
                                    className = CommonStyles.REMOVED_CELL_BOTTOM_BORDER.cssClass
                                    +"Start Time"
                                }
                                for (i in 1..7) {
                                    TableCell {
                                        align = TdAlign.left
                                        className = CommonStyles.REMOVED_CELL_BOTTOM_BORDER.cssClass
                                        +"${FacilityOperatingHoursDefaultValues.startingHour}:${FacilityOperatingHoursDefaultValues.startingMinute}"
                                    }
                                }
                            }
                            TableRow {
                                TableCell {
                                    align = TdAlign.left
                                    className = CommonStyles.REMOVED_CELL_BOTTOM_BORDER.cssClass
                                    +"End Time"
                                }
                                for (i in 1..7) {
                                    TableCell {
                                        align = TdAlign.left
                                        className = CommonStyles.REMOVED_CELL_BOTTOM_BORDER.cssClass
                                        +"${FacilityOperatingHoursDefaultValues.endingHour}:${FacilityOperatingHoursDefaultValues.endingMinute}"
                                    }
                                }
                            }
                        } else {
                            TableRow {
                                TableCell {
                                    align = TdAlign.left
                                    className = CommonStyles.REMOVED_CELL_BOTTOM_BORDER.cssClass
                                    +"Start Time"
                                }
                                FacilityConstants.weekDaysList.forEach { day ->
                                    TableCell {
                                        align = TdAlign.left
                                        className = CommonStyles.REMOVED_CELL_BOTTOM_BORDER.cssClass
                                        +facility.facilityDailyOperations?.find { it.dayOfTheWeek == day.dayEnum }?.startTime
                                    }
                                }
                            }
                            TableRow {
                                TableCell {
                                    align = TdAlign.left
                                    className = CommonStyles.REMOVED_CELL_BOTTOM_BORDER.cssClass
                                    +"End Time"
                                }
                                FacilityConstants.weekDaysList.forEach { day ->
                                    TableCell {
                                        align = TdAlign.left
                                        className = CommonStyles.REMOVED_CELL_BOTTOM_BORDER.cssClass
                                        +facility.facilityDailyOperations?.find { it.dayOfTheWeek == day.dayEnum }?.endTime
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}