package com.ecosave.watch.portal.components.esg.formcontrols

import com.ecosave.watch.portal.components.common.RomanListWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import mui.material.Box
import react.ChildrenBuilder
import react.dom.html.ReactHTML
import react.dom.onChange

fun ChildrenBuilder.ESGBaseFormBuilder(
    inputList: List<GenericFieldMapper<out Comparable<*>>>,
    props: EsgSubFormComponentProps
) {
    inputList.forEach {
        Box {
            if (it.label.listHeaders.isEmpty()) {
                ReactHTML.label {
                    +it.label.mainHeader
                }
            } else {
                RomanListWrapper(it.label.mainHeader, it.label.listHeaders)
            }
            when (it.kClass) {
                String::class -> {
                    ESGTextAreaFieldWrapper {
                        name = it.name
                        value = it.value
                        onChange = { event -> props.onChangeTextArea(event, null, null) }
                        onBlur = { event -> props.onBlurTextArea(event, null, null) }
                    }
                }

                Long::class -> {
                    ESGNumberFieldWrapper {
                        name = it.name
                        value = it.value
                        onChange = { event -> props.onChangeNumber(event, null, null) }
                        onBlur = { event -> props.onBlurNumber(event, null, null) }
                    }
                }

                Double::class -> {
                    ESGNumberFieldWrapper {
                        name = it.name
                        value = it.value
                        onChange = { event -> props.onChangeDecimalNumber(event, null, null) }
                        onBlur = { event -> props.onBlurDecimalNumber(event, null, null) }
                    }
                }
            }
        }
    }
}