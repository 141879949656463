package com.ecosave.watch.portal.services.workorder

import com.ecosave.watch.portal.helpers.BackendServerURL
import com.ecosave.watch.portal.models.workorder.ActionsAssignmentState
import com.ecosave.watch.portal.models.workorder.AssignActionsRequestDataClass
import com.ecosave.watch.portal.models.workorder.AssignActionsResponseDataClass
import com.ecosave.watch.portal.models.workorder.Observation
import com.ecosave.watch.portal.models.workorder.WorkForce
import com.ecosave.watch.portal.services.httpClient
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*

suspend fun getObservations(params: String = ""): MutableList<Observation>? {
    try {
        val response: HttpResponse = httpClient.get("$BackendServerURL/work-force-management/observations$params") {
            contentType(ContentType.Application.Json)
        }
        if (response.status == HttpStatusCode.OK) {
            return response.body()
        }
    } catch (_: dynamic) {

    }
    return null
}

suspend fun getWorkForce(): MutableList<WorkForce> {
    try {
        val response: HttpResponse = httpClient.get("$BackendServerURL/work-force-management/action-status") {
            contentType(ContentType.Application.Json)
        }
        if (response.status == HttpStatusCode.OK) {
            return response.body()
        }
    } catch (_: dynamic) {

    }
    return mutableListOf()
}

suspend fun assignActions(requestBody: ActionsAssignmentState): AssignActionsResponseDataClass? {
    try {
        val response: HttpResponse = httpClient.post("$BackendServerURL/work-force-management/assign") {
            contentType(ContentType.Application.Json)
            setBody(
                AssignActionsRequestDataClass(
                    assignTo = requestBody.assignTo,
                    actionIds = requestBody.actionIds
                )
            )
        }
        if (response.status == HttpStatusCode.OK) {
            return response.body()
        }
    } catch (_: dynamic) {

    }
    return null
}