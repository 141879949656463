package com.ecosave.watch.portal.helpers.controlcenter

import com.ecosave.watch.portal.helpers.Colors
import com.ecosave.watch.portal.helpers.common.removeDecimalAndAddComma
import com.ecosave.watch.portal.models.common.CustomTooltipLabel
import com.ecosave.watch.portal.models.controlcenter.DataKeys
import js.core.push
import kotlin.math.roundToInt

fun labelFormatter(name: String): String {
    return when (name) {
        DataKeys.BASELINE_USAGE.value -> DataKeys.BASELINE_USAGE.label
        DataKeys.STEAM_USAGE.value -> DataKeys.STEAM_USAGE.label
        DataKeys.NATURAL_GAS_USAGE.value -> DataKeys.NATURAL_GAS_USAGE.label
        DataKeys.ELECTRIC_USAGE.value -> DataKeys.ELECTRIC_USAGE.label
        DataKeys.BASELINE_COST.value -> DataKeys.BASELINE_COST.label
        DataKeys.STEAM_COST.value -> DataKeys.STEAM_COST.label
        DataKeys.NATURAL_GAS_COST.value -> DataKeys.NATURAL_GAS_COST.label
        DataKeys.ELECTRIC_COST.value -> DataKeys.ELECTRIC_COST.label
        DataKeys.ELECTRIC.value -> DataKeys.ELECTRIC.label
        DataKeys.NATURAL_GAS.value -> DataKeys.NATURAL_GAS.label
        DataKeys.STEAM.value -> DataKeys.STEAM.label
        else -> ""
    }
}

fun getDataKey(label: String): DataKeys {
    return when (label) {
        DataKeys.BASELINE_USAGE.value -> DataKeys.BASELINE_USAGE
        DataKeys.STEAM_USAGE.value -> DataKeys.STEAM_USAGE
        DataKeys.NATURAL_GAS_USAGE.value -> DataKeys.NATURAL_GAS_USAGE
        DataKeys.ELECTRIC_USAGE.value -> DataKeys.ELECTRIC_USAGE
        DataKeys.BASELINE_COST.value -> DataKeys.BASELINE_COST
        DataKeys.STEAM_COST.value -> DataKeys.STEAM_COST
        DataKeys.NATURAL_GAS_COST.value -> DataKeys.NATURAL_GAS_COST
        DataKeys.ELECTRIC_COST.value -> DataKeys.ELECTRIC_COST
        else -> DataKeys.EMPTY
    }
}

fun totalLabelFormatter(name: String): String {
    return when (name) {
        DataKeys.BASELINE_USAGE.value -> "Total Usage"
        DataKeys.STEAM_USAGE.value -> "Total Usage"
        DataKeys.NATURAL_GAS_USAGE.value -> "Total Usage"
        DataKeys.ELECTRIC_USAGE.value -> "Total Usage"
        DataKeys.BASELINE_COST.value -> "Total Cost"
        DataKeys.STEAM_COST.value -> "Total Cost"
        DataKeys.NATURAL_GAS_COST.value -> "Total Cost"
        DataKeys.ELECTRIC_COST.value -> "Total Cost"
        else -> ""
    }
}

fun getValues(payload: Array<dynamic>): Array<CustomTooltipLabel> {
    val values = emptyArray<CustomTooltipLabel>()
    var totalValue = 0.0
    var label = ""
    var prefix = ""
    var surfix = ""
    for (item in payload) {
        val dataKey = getDataKey(item.dataKey)
        val doubleVal = item.value.toString().toDouble()
        totalValue += doubleVal
        label = totalLabelFormatter(item.dataKey)
        prefix = dataKey.prefix
        surfix = dataKey.surfix
        values.push(CustomTooltipLabel("${dataKey.label}: ${dataKey.prefix}${removeDecimalAndAddComma(doubleVal)} ${dataKey.surfix}", item.color))
    }

    values.push(CustomTooltipLabel("${label}: ${prefix}${removeDecimalAndAddComma(totalValue)} ${surfix}", Colors.SCARLET_RED))
    return values
}