package com.ecosave.watch.portal.components.common

import com.ecosave.watch.portal.styles.ControlCenterPageStyles
import com.ecosave.watch.portal.Themes
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props

const val API_ERROR_MESSAGE = "We can't fetch data. Please try again later!"

val ApiFailedMessage = FC<Props> {
    Typography {
        className = ControlCenterPageStyles.TEXT_CENTER.cssClass
        variant = TypographyVariant.subtitle1
        sx {
            color = Themes.darkTheme.palette.error.dark
        }
        +API_ERROR_MESSAGE
    }
}