package com.ecosave.watch.portal.services.usermanagement

import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.BackendServerURL
import com.ecosave.watch.portal.helpers.usermanagement.TokenVerificationStatus
import com.ecosave.watch.portal.models.usermanagement.InvitedUser
import com.ecosave.watch.portal.models.usermanagement.InvitedUserDetail
import com.ecosave.watch.portal.models.usermanagement.InvitedUserRegistration
import com.ecosave.watch.portal.models.usermanagement.InvitedUserVerificationApiResponse
import com.ecosave.watch.portal.models.usermanagement.UserDeletionApiResponseWrapper
import com.ecosave.watch.portal.services.httpClient
import com.ecosave.watch.portal.services.nonAuthClient
import com.ecosave.watch.portal.models.usermanagement.RegisterCompanyOrUserState
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*

suspend fun inviteUsers(invitedUsers: MutableList<InvitedUser>): HttpStatusCode? {
    try {
        val response: HttpResponse = httpClient.post("$BackendServerURL/userAccount/invite-users") {
            contentType(ContentType.Application.Json)
            setBody(invitedUsers)
        }
        return when (response.status) {
            HttpStatusCode.Accepted -> HttpStatusCode.Accepted
            HttpStatusCode.Conflict -> HttpStatusCode.Conflict
            else -> null
        }

    } catch (_: dynamic) {

    }
    return null
}

suspend fun getUsers(): MutableList<InvitedUserDetail>? {
    try {
        val response: HttpResponse = httpClient.get("$BackendServerURL/userAccount/all") {
            contentType(ContentType.Application.Json)
        }
        if (response.status == HttpStatusCode.OK) {
            return response.body()
        }
    } catch (_: dynamic) {

    }
    return null
}

suspend fun verifyInvitedUser(token: String?): InvitedUserVerificationApiResponse {
    try {
        val response: HttpResponse = nonAuthClient.get("$BackendServerURL/userAccount/invited-user?inviteToken=$token") {
            contentType(ContentType.Application.Json)
        }
        return when (response.status) {
            HttpStatusCode.OK -> {
                InvitedUserVerificationApiResponse(
                    TokenVerificationStatus.VERIFIED,
                    response.body()
                )
            }

            HttpStatusCode.Forbidden -> {
                InvitedUserVerificationApiResponse(
                    TokenVerificationStatus.INVALID_OR_EXPIRED,
                    null
                )
            }

            HttpStatusCode.Conflict -> {
                InvitedUserVerificationApiResponse(
                    TokenVerificationStatus.ALREADY_VERIFIED,
                    null
                )
            }

            else -> {
                InvitedUserVerificationApiResponse(
                    TokenVerificationStatus.EXCEPTION,
                    null
                )
            }
        }

    } catch (_: dynamic) {

    }
    return InvitedUserVerificationApiResponse(
        TokenVerificationStatus.EXCEPTION,
        null
    )
}

suspend fun registerInvitedUser(invitedUser: RegisterCompanyOrUserState, token: String): ApiCallStatus {
    try {
        val response: HttpResponse = httpClient.post("$BackendServerURL/userAccount/register-invited-user") {
            contentType(ContentType.Application.Json)
            setBody(
                InvitedUserRegistration(
                    inviteToken = token,
                    firstName = invitedUser.firstName,
                    lastName = invitedUser.lastName,
                    jobTitle = invitedUser.jobTitle,
                    password = invitedUser.password
                )
            )
        }
        if (response.status == HttpStatusCode.Created) {
            return ApiCallStatus.SUCCESS
        }
    } catch (e: dynamic) {
        console.log(e)
    }
    return ApiCallStatus.FAILURE
}

suspend fun editUserRole(user: InvitedUser): ApiCallStatus {
    try {
        val response: HttpResponse = httpClient.put("$BackendServerURL/userAccount") {
            contentType(ContentType.Application.Json)
            setBody(user)
        }
        if (response.status == HttpStatusCode.NoContent || response.status == HttpStatusCode.Conflict) {
            return ApiCallStatus.SUCCESS
        }
    } catch (_: dynamic) {

    }
    return ApiCallStatus.FAILURE
}

suspend fun deleteUser(email: String): UserDeletionApiResponseWrapper? {
    try {
        val response: HttpResponse = httpClient.delete("$BackendServerURL/userAccount?emailAddress=$email")
        if (response.status == HttpStatusCode.NoContent) {
            return UserDeletionApiResponseWrapper(
                httpStatusCode = response.status,
                userDeletionState = null
            )
        } else if (response.status == HttpStatusCode.Found) {
            return UserDeletionApiResponseWrapper(
                httpStatusCode = response.status,
                userDeletionState = response.body()
            )
        }
    } catch (e: dynamic) {
    }
    return null
}

suspend fun reassignActionsToAdminAndDeleteUser(email: String): ApiCallStatus {
    try {
        val response: HttpResponse = httpClient.post("$BackendServerURL/work-force-management/reassign-action?email=$email") {
            contentType(ContentType.Application.Json)
        }
        if (response.status == HttpStatusCode.OK) {
            return ApiCallStatus.SUCCESS
        }
    } catch (_: dynamic) {

    }
    return ApiCallStatus.FAILURE
}


suspend fun resendInvitationEmail(email: String): ApiCallStatus {
    try {
        val response: HttpResponse = httpClient.post("$BackendServerURL/userAccount/resend-invitation-email?emailAddress=$email") {
            contentType(ContentType.Application.Json)
        }
        if (response.status == HttpStatusCode.Accepted) {
            return ApiCallStatus.SUCCESS
        }
    } catch (_: dynamic) {

    }
    return ApiCallStatus.FAILURE
}

