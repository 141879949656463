package com.ecosave.watch.portal.components.facilitymanagement

import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.components.common.DialogSpinner
import com.ecosave.watch.portal.helpers.billing.BillingConstants
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.showNotification
import com.ecosave.watch.portal.helpers.facilitymanagement.ImportFacilitiesComponents
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.models.facilitymanagement.EnergyStarSelectedCandidateFacility
import com.ecosave.watch.portal.services.facilitymanagement.importFacilities
import com.ecosave.watch.portal.styles.CommonStyles
import js.core.jso
import kotlinx.coroutines.launch
import mui.icons.material.ArrowDownward
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Dialog
import mui.material.DialogActions
import mui.material.DialogContent
import mui.material.DialogTitle
import mui.material.Step
import mui.material.StepLabel
import mui.material.Stepper
import mui.material.Typography
import mui.material.TypographyAlign
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.StateSetter
import react.useState
import web.cssom.ClassName
import web.cssom.Display
import web.cssom.Float
import web.cssom.FontWeight
import web.cssom.JustifyContent
import web.cssom.Margin
import web.cssom.Transition
import web.cssom.pct
import web.cssom.px

external interface ConnectEnergyStarAndImportFacilityProps : Props {
    var openConnectEnergyStarAndImportFacilitiesForm: Boolean
    var setOpenConnectEnergyStarAndImportFacilitiesForm: StateSetter<Boolean>
    var refreshFacilities: () -> Unit
    var showConnectEnergyStarFormOnly: Boolean
}

val ConnectEnergyStarAndImportFacilities = FC<ConnectEnergyStarAndImportFacilityProps> { props ->

    var activeComponent by useState(ImportFacilitiesComponents.CONNECT_TO_ENERGY_STAR)
    val (notificationState, setNotificationState) = useState(NotificationState())
    val (energyStarConnectedAccounts, setEnergyStarConnectedAccounts) = useState<List<Long>>(emptyList())
    val (facilitiesToBeImported, setFacilitiesToBeImported) = useState(mutableListOf<EnergyStarSelectedCandidateFacility>())
    var importingFacilities by useState(false)

    val closeConnectEnergyStarAndImportFacilitiesForm: () -> Unit = {
        setEnergyStarConnectedAccounts(emptyList())
        activeComponent = ImportFacilitiesComponents.CONNECT_TO_ENERGY_STAR
        setFacilitiesToBeImported(mutableListOf())
        props.setOpenConnectEnergyStarAndImportFacilitiesForm(false)
    }

    Dialog {
        open = props.openConnectEnergyStarAndImportFacilitiesForm
        PaperProps = jso {
            className = CommonStyles.DIALOG_BORDER_RADIUS.cssClass
            sx {
                maxWidth = 900.px
            }
        }
        Box {
            className = ClassName("dialog-gradient-strip")
        }
        DialogTitle {
            if(props.showConnectEnergyStarFormOnly) {
                Typography {
                    sx {
                        margin = Margin(10.px, 0.px, (-10).px, 0.px)
                        fontWeight = FontWeight.bold
                    }
                    variant = TypographyVariant.h5
                    align = TypographyAlign.center
                    +"Connect to ${BillingConstants.ENERGY_STAR}"
                }
            } else {
                Box {
                    sx {
                        display = Display.flex
                        transition = Transition.ease
                        justifyContent = JustifyContent.center
                        marginTop = 20.px
                    }
                    Stepper {
                        activeStep = activeComponent.ordinal
                        Step {
                            completed = energyStarConnectedAccounts.isNotEmpty()
                            StepLabel {
                                +"Connect to ${BillingConstants.ENERGY_STAR}"
                            }
                        }
                        Step {
                            StepLabel {
                                +"Import Facilities"
                            }
                        }
                    }
                }
            }
        }
        DialogContent {
            sx {
                margin = 20.px
            }
            when (activeComponent) {
                ImportFacilitiesComponents.CONNECT_TO_ENERGY_STAR -> ConnectToEnergyStar {
                    this.energyStarConnectedAccounts = energyStarConnectedAccounts
                    this.setEnergyStarConnectedAccounts = setEnergyStarConnectedAccounts
                }

                ImportFacilitiesComponents.IMPORT_FACILITIES -> ImportFacilities {
                    this.notificationState = notificationState
                    this.setNotificationState = setNotificationState
                    this.facilitiesToBeImported = facilitiesToBeImported
                    this.setFacilitiesToBeImported = setFacilitiesToBeImported
                }
            }

        }
        if(props.showConnectEnergyStarFormOnly) {
            DialogActions {
                sx {
                    display = Display.flex
                    justifyContent = JustifyContent.center
                }
                Button {
                    +"Cancel"
                    onClick = {
                        closeConnectEnergyStarAndImportFacilitiesForm()
                    }
                }
            }
        } else {
            DialogActions {
                sx {
                    marginLeft = 40.px
                    marginRight = 40.px
                }
                if (activeComponent == ImportFacilitiesComponents.CONNECT_TO_ENERGY_STAR) {
                    Box {
                        sx {
                            display = Display.flex
                            justifyContent = JustifyContent.center
                            width = 100.pct
                        }
                        Button {
                            +"Cancel"
                            onClick = {
                                closeConnectEnergyStarAndImportFacilitiesForm()
                            }
                        }
                        Button {
                            variant = ButtonVariant.contained
                            onClick = {
                                if (energyStarConnectedAccounts.isEmpty()) {
                                    showNotification(
                                        "Please connect to at least one ${BillingConstants.ENERGY_STAR} account.",
                                        NotificationStatus.INFO,
                                        notificationState,
                                        setNotificationState
                                    )
                                } else {
                                    activeComponent = ImportFacilitiesComponents.IMPORT_FACILITIES
                                }
                            }
                            +"Next"
                        }
                    }
                }
                if (activeComponent == ImportFacilitiesComponents.IMPORT_FACILITIES) {
                    Box {
                        sx {
                            display = Display.flex
                            width = 100.pct
                        }
                        Box {
                            sx {
                                float = Float.left
                                marginLeft = 16.px
                            }
                            Button {
                                variant = ButtonVariant.contained
                                onClick = {
                                    activeComponent = ImportFacilitiesComponents.CONNECT_TO_ENERGY_STAR
                                }
                                +"Back"
                            }
                        }
                        Box {
                            sx {
                                display = Display.flex
                                justifyContent = JustifyContent.center
                                width = 100.pct
                            }
                            Button {
                                +"Cancel"
                                onClick = {
                                    closeConnectEnergyStarAndImportFacilitiesForm()
                                }
                            }
                            Button {
                                disabled = facilitiesToBeImported.isEmpty()
                                variant = ButtonVariant.contained
                                startIcon = startIcon.also {
                                    ArrowDownward()
                                }
                                onClick = {
                                    mainScope.launch {
                                        importingFacilities = true
                                        val apiCallStatus = importFacilities(facilitiesToBeImported)
                                        if (apiCallStatus == ApiCallStatus.SUCCESS) {
                                            showNotification(
                                                "Facilities have been imported successfully.",
                                                NotificationStatus.SUCCESS,
                                                notificationState,
                                                setNotificationState
                                            )
                                            props.refreshFacilities()
                                            closeConnectEnergyStarAndImportFacilitiesForm()
                                        } else {
                                            showNotification(
                                                "There has been an issue importing facilities, please retry.",
                                                NotificationStatus.ERROR,
                                                notificationState,
                                                setNotificationState
                                            )
                                        }
                                        importingFacilities = false
                                    }
                                }
                                +"Import ${facilitiesToBeImported.size} facility(s)"
                            }
                        }
                    }
                }
            }
        }
    }
    AlertNotifications {
        open = notificationState.visible
        status = notificationState.status
        message = notificationState.message
        closeNotification = {
            setNotificationState(
                notificationState.copy(
                    visible = false
                )
            )
        }
    }
    DialogSpinner {
        open = importingFacilities
        message = "Importing facilities..."
    }
}