package com.ecosave.watch.portal.helpers.facilitygroupmanagement

import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.models.facilitygroupmanagement.ChildGroupStateDataClass
import com.ecosave.watch.portal.models.facilitygroupmanagement.ChildGroupValidationStateDataClass
import react.ReactNode
import react.StateSetter

fun validateChildGroupName(
    state: ChildGroupStateDataClass,
    validation: ChildGroupValidationStateDataClass,
    validationStateSetter: StateSetter<ChildGroupValidationStateDataClass>
) {
    if (state.childGroupName.isBlank()) {
        validation.childGroupNameErrorState = true
        validation.childGroupNameErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else {
        validation.childGroupNameErrorState = false
        validation.childGroupNameErrorMessage = null
    }

    updateFacilityGroupValidationState(validation, validationStateSetter)
}

fun validateChildGroupFacilities(
    validation: ChildGroupValidationStateDataClass,
    validationStateSetter: StateSetter<ChildGroupValidationStateDataClass>,
    facilitiesList: MutableList<Int>
) {
    if (facilitiesList.isEmpty()) {
        validation.childGroupFacilitiesErrorState = true
        validation.childGroupFacilitiesErrorMessage = ReactNode("Please select at least one facility")
    } else {
        validation.childGroupFacilitiesErrorState = false
        validation.childGroupFacilitiesErrorMessage = null
    }

    updateFacilityGroupValidationState(validation, validationStateSetter)
}


fun updateFacilityGroupValidationState(
    validation: ChildGroupValidationStateDataClass,
    validationStateSetter: StateSetter<ChildGroupValidationStateDataClass>
) {
    validationStateSetter(
        validation.copy()
    )
}

fun isChildGroupValid(
    state: ChildGroupStateDataClass,
    validation: ChildGroupValidationStateDataClass,
    validationStateSetter: StateSetter<ChildGroupValidationStateDataClass>
): Boolean {
    validateChildGroupName(state, validation, validationStateSetter)
    validateChildGroupFacilities(validation, validationStateSetter, state.childGroupFacilitiesList)

    return !(validation.childGroupNameErrorState || validation.childGroupFacilitiesErrorState)
}
