package com.ecosave.watch.portal.models.facilitymanagement

import react.ReactNode

data class FacilityValidationState(
    var facilityNameErrorState: Boolean = false,
    var facilityNameErrorMessage: ReactNode? = null,
    var buildingCategoryErrorState: Boolean = false,
    var buildingCategoryErrorMessage: ReactNode? = null,
    var buildingTypeErrorState: Boolean = false,
    var buildingTypeErrorMessage: ReactNode? = null,
    var otherBuildingTypeErrorState: Boolean = false,
    var otherBuildingTypeErrorMessage: ReactNode? = null,
    var buildingTotalAreaErrorState: Boolean = false,
    var buildingTotalAreaErrorMessage: ReactNode? = null,
    var streetErrorState: Boolean = false,
    var streetErrorMessage: ReactNode? = null,
    var stateErrorState: Boolean = false,
    var stateErrorMessage: ReactNode? = null,
    var cityErrorState: Boolean = false,
    var cityErrorMessage: ReactNode? = null,
    var zipcodeErrorState: Boolean = false,
    var zipcodeErrorMessage: ReactNode? = null,
    var buildingYearBuiltErrorState: Boolean = false,
    var buildingYearBuiltErrorMessage: ReactNode? = null,
    var buildingOccupancyErrorState: Boolean = false,
    var buildingOccupancyErrorMessage: ReactNode? = null,
    var propertyIdErrorState: Boolean = false,
    var propertyIdErrorMessage: ReactNode? = null
)
