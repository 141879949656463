package com.ecosave.watch.portal.components.energymanagement

import com.ecosave.watch.portal.components.facilitygroupmanagement.facilityGroupsTreeView
import com.ecosave.watch.portal.helpers.Colors
import com.ecosave.watch.portal.helpers.common.lg
import com.ecosave.watch.portal.helpers.common.md
import com.ecosave.watch.portal.helpers.facilitygroupmanagement.findGroupById
import com.ecosave.watch.portal.helpers.facilitygroupmanagement.findParentFacilities
import com.ecosave.watch.portal.models.facilitygroupmanagement.FacilityGroup
import com.ecosave.watch.portal.models.facilitygroupmanagement.FacilitySummary
import com.ecosave.watch.portal.pages.mainScope
import com.ecosave.watch.portal.services.facilitygroupmanagement.getFacilityGroups
import kotlinx.coroutines.launch
import mui.icons.material.ChevronRight
import mui.icons.material.Close
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Grid
import mui.material.IconButton
import mui.material.Modal
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.router.useNavigate
import react.useEffect
import react.useEffectOnce
import react.useState
import web.cssom.Color
import web.cssom.Display
import web.cssom.FontWeight
import web.cssom.JustifyContent
import web.cssom.TextAlign
import web.cssom.px
import com.ecosave.watch.portal.styles.EnergyManagementStyles as styles

external interface GroupSelectComponentProps : Props {
    var onSelect: (selectedGroupId: Int, name: String?) -> Unit
}

var GroupSelectComponent = FC<GroupSelectComponentProps> { props ->

    val (isOpen, setIsOpen) = useState(false)
    val (mainGroup, mainGroupStateSetter) = useState(FacilityGroup())
    val (selectedGroupId, selectedGroupIdStateSetter) = useState(mainGroup.facilityGroupId)
    var selectedGroup by useState<FacilityGroup?>(null)
    var isLoading by useState(false)
    var parentGroupFacilities by useState<List<FacilitySummary>>(emptyList())
    val navigate = useNavigate()

    useEffectOnce {
        mainScope.launch {
            isLoading = true
            val result = getFacilityGroups()
            if (result != null) {
                mainGroupStateSetter(result)
                selectedGroupIdStateSetter(result.facilityGroupId)
                props.onSelect(result.facilityGroupId, result.facilityGroupName)
            }
            isLoading = false
        }
    }

    useEffect(selectedGroupId) {
        selectedGroup = if (selectedGroupId == mainGroup.facilityGroupId) {
            FacilityGroup(
                facilityGroupId = mainGroup.facilityGroupId,
                facilityGroupName = mainGroup.facilityGroupName,
                facilities = mainGroup.facilities,
                childGroups = mainGroup.childGroups
            )
        } else {
            mainGroup.childGroups?.let { it1 -> findGroupById(it1, selectedGroupId) }
        }

        parentGroupFacilities = findParentFacilities(mainGroup, selectedGroupId)
    }

    Button {
        variant = ButtonVariant.contained
        onClick = {
            setIsOpen(true)
        }
        +"Select Group"
    }
    Modal {
        open = isOpen
        onClose = { _, _ ->
            setIsOpen(false)
        }
        Box {
            className = styles.BASE_LINE_MODAL_CONTAINER.cssClass
            Box {
                className = styles.GROUP_SELECT_MODAL_CONTENT.cssClass
                sx {
                    backgroundColor = Color(Colors.MAIN_BLUE)
                }
                Box {
                    IconButton {
                        className = styles.BASE_LINE_CLOSE_ICON.cssClass
                        onClick = {
                            setIsOpen(false)
                        }
                        Close()
                    }
                    Typography {
                        sx {
                            textAlign = TextAlign.center
                            fontWeight = FontWeight.bold
                        }
                        variant = TypographyVariant.h5
                        +"Selection of Facility Group"
                    }
                }
                Box {
                    sx {
                        marginTop = 30.px
                        display = Display.flex
                        justifyContent = JustifyContent.end
                    }
                    Button {
                        +"Manage Groups"
                        variant = ButtonVariant.text
                        endIcon = endIcon.also {
                            ChevronRight()
                        }
                        onClick = {
                            navigate.invoke("/facility-groups")
                        }

                    }
                }
                Grid {
                    container = true
                    spacing = responsive(3)
                    sx {
                        marginTop = 20.px
                    }
                    Grid {
                        item = true
                        md = 12
                        lg = 3
                        Typography {
                            variant = TypographyVariant.h6
                            sx {
                                marginBottom = 20.px
                                fontWeight = FontWeight.bold
                            }
                            +"Facility Groups"
                        }
                        facilityGroupsTreeView(mainGroup, selectedGroupIdStateSetter)
                    }
                    Grid {
                        item = true
                        md = 12
                        lg = 9
                        Typography {
                            variant = TypographyVariant.h6
                            sx {
                                marginBottom = 20.px
                                fontWeight = FontWeight.bold
                            }
                            +"Selected Group: ${selectedGroup?.facilityGroupName}"
                        }
                        StickyTable {
                            selectedGroupProp = selectedGroup
                        }
                    }
                }
                Box {
                    sx {
                        marginTop = 30.px
                        display = Display.flex
                        justifyContent = JustifyContent.center
                    }
                    Button {
                        variant = ButtonVariant.contained
                        onClick = {
                            setIsOpen(false)
                            props.onSelect(selectedGroupId, selectedGroup?.facilityGroupName)
                        }
                        +"Apply"
                    }
                }
            }
        }
    }
}