package com.ecosave.watch.portal.helpers.usermanagement

import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.components.npm.isStrongPassword
import com.ecosave.watch.portal.models.recoverpassword.ChangePasswordState
import com.ecosave.watch.portal.models.recoverpassword.ChangePasswordValidationState
import react.ReactNode
import react.StateSetter

fun validatePassword(
    state: ChangePasswordState,
    validation: ChangePasswordValidationState,
    setValidation: StateSetter<ChangePasswordValidationState>
) {
    if (state.password.isBlank()) {
        validation.passwordErrorState = true
        validation.passwordErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else if (!isStrongPassword(state.password)) {
        validation.passwordErrorState = true
        validation.passwordErrorMessage =
            ReactNode("Password should be strong.")
    } else {
        validation.passwordErrorState = false
        validation.passwordErrorMessage = null
    }

    updateCreatePasswordValidationState(validation, setValidation)
}

fun validateConfirmPassword(
    state: ChangePasswordState,
    validation: ChangePasswordValidationState,
    setValidation: StateSetter<ChangePasswordValidationState>
) {
    if (state.confirmPassword.isBlank()) {
        validation.confirmPasswordErrorState = true
        validation.confirmPasswordErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else if (state.confirmPassword != state.password) {
        validation.confirmPasswordErrorState = true
        validation.confirmPasswordErrorMessage = ReactNode("Passwords do not match.")
    } else {
        validation.confirmPasswordErrorState = false
        validation.confirmPasswordErrorMessage = null
    }

    updateCreatePasswordValidationState(validation, setValidation)
}

fun isPasswordValid(
    state: ChangePasswordState,
    validation: ChangePasswordValidationState,
    setValidation: StateSetter<ChangePasswordValidationState>
): Boolean {
    validatePassword(state, validation, setValidation)
    validateConfirmPassword(state, validation, setValidation)

    return !(validation.passwordErrorState || validation.confirmPasswordErrorState)
}

fun updateCreatePasswordValidationState(
    validation: ChangePasswordValidationState,
    setValidation: StateSetter<ChangePasswordValidationState>
) {
    setValidation(
        validation.copy()
    )
}