package com.ecosave.watch.portal.services.facilitygroupmanagement

import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.BackendServerURL
import com.ecosave.watch.portal.models.facilitygroupmanagement.ChildGroupRequestDataClass
import com.ecosave.watch.portal.models.facilitygroupmanagement.ChildGroupStateDataClass
import com.ecosave.watch.portal.models.facilitygroupmanagement.EditGroupRequestDataClass
import com.ecosave.watch.portal.models.facilitygroupmanagement.FacilityGroup
import com.ecosave.watch.portal.services.httpClient
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*

suspend fun getFacilityGroups(): FacilityGroup? {
    try {
        val response: HttpResponse = httpClient.get("$BackendServerURL/facility-groups")
        if (response.status == HttpStatusCode.OK) {
            return response.body()
        }
    } catch (_: dynamic) {

    }
    return null
}

suspend fun createChildGroup(state: ChildGroupStateDataClass): FacilityGroup? {
    try {
        val response: HttpResponse = httpClient.post("$BackendServerURL/facility-groups") {
            setBody(
                ChildGroupRequestDataClass(
                    parentFacilityGroupId = state.parentGroupId,
                    childFacilityGroupName = state.childGroupName,
                    childFacilitiesList = state.childGroupFacilitiesList
                )
            )
        }
        if (response.status == HttpStatusCode.Created) {
            return response.body()
        }
    } catch (e: dynamic) {
       console.log(e)
    }
    return null
}

suspend fun editGroup(groupId: Int, group: ChildGroupStateDataClass): FacilityGroup? {
    try {
        val response: HttpResponse = httpClient.put("$BackendServerURL/facility-groups") {
            setBody(
                EditGroupRequestDataClass(
                    facilityGroupId = groupId,
                    facilityGroupName = group.childGroupName,
                    facilitiesList = group.childGroupFacilitiesList
                )
            )
        }
        if (response.status == HttpStatusCode.Accepted) {
            return response.body()
        }
    } catch (_: dynamic) {

    }
    return null
}

suspend fun deleteGroup(groupId: Int): ApiCallStatus {
    try {
        val response: HttpResponse = httpClient.delete("$BackendServerURL/facility-groups?facilityGroupId=$groupId") {
        }
        if (response.status == HttpStatusCode.OK) {
            return ApiCallStatus.SUCCESS
        }
    } catch (_: dynamic) {

    }
    return ApiCallStatus.FAILURE
}