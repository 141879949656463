package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class RightsOfIndigenousPeoplesState(
    var section4111Field1: Long? = null,
    var actionAndStatusOfIncidentsList: MutableList<ActionAndStatusOfIncidents> = mutableListOf(),

    var section3_3_411A1: String? = null,
    var section3_3_411A2: String? = null,
    var section3_3_411A3: String? = null,
    var section3_3_411A4: String? = null,
    var section3_3_411A5: String? = null,
    var section3_3_411A6: String? = null
)

@Serializable
data class ActionAndStatusOfIncidents(
    var section4111A1: String? = null,
    var section4111A2: String? = null,
    var section4111A3: String? = null,
    var section4111A4: String? = null
) : DynamicTableItemKey()