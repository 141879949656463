package com.ecosave.watch.portal.models.assetmanagement

import react.ReactNode

data class AssetValidationState(
    var facilityErrorState: Boolean = false,
    var facilityErrorMessage: ReactNode? = null,
    var equipmentIdErrorState: Boolean = false,
    var equipmentIdErrorMessage: ReactNode? = null,
    var equipmentTypeErrorState: Boolean = false,
    var equipmentTypeErrorMessage: ReactNode? = null,
    var equipmentLocationErrorState: Boolean = false,
    var equipmentLocationErrorMessage: ReactNode? = null,
    var quantityErrorState: Boolean = false,
    var quantityErrorMessage: ReactNode? = null,
    var manufacturerErrorState: Boolean = false,
    var manufacturerErrorMessage: ReactNode? = null,
    var equipmentMaintenanceProviderErrorState: Boolean = false,
    var equipmentMaintenanceProviderErrorMessage: ReactNode? = null,
    var modelErrorState: Boolean = false,
    var modelErrorMessage: ReactNode? = null,
    var serialNumberErrorState: Boolean = false,
    var serialNumberErrorMessage: ReactNode? = null,
    var systemTypeErrorState: Boolean = false,
    var systemTypeErrorMessage: ReactNode? = null,
    var yearInstalledErrorState: Boolean = false,
    var yearInstalledErrorMessage: ReactNode? = null,
    var warrantyEndDateErrorState: Boolean = false,
    var warrantyEndDateErrorMessage: ReactNode? = null,
    var omDocumentErrorState: Boolean = false,
    var omDocumentErrorMessage: ReactNode? = null
)
