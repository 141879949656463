package com.ecosave.watch.portal.components.esg.customerprivacy

import com.ecosave.watch.portal.components.esg.EsgCoreComponentProps
import com.ecosave.watch.portal.components.esg.GenericSection_3_3
import com.ecosave.watch.portal.components.esg.formcontrols.GRIStandardName
import com.ecosave.watch.portal.helpers.common.isValidLongNumber
import com.ecosave.watch.portal.helpers.esg.EsgSection
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.omitDisclosureFromUI
import com.ecosave.watch.portal.models.esg.CurrentInputState
import com.ecosave.watch.portal.models.esg.CustomerPrivacyState
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import kotlinx.serialization.json.JsonPrimitive
import mui.material.Box
import react.FC
import react.dom.events.FocusEvent
import react.dom.events.FormEvent
import web.html.HTMLDivElement
import web.html.HTMLElement
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement

val CustomerPrivacyComponent = FC<EsgCoreComponentProps> { props ->

    val reportState = props.reportState
    val setReportState = props.setReportState

    reportState.report?.customerPrivacy?.let { state ->

        fun stateSetter(state: CustomerPrivacyState) {
            setReportState(
                reportState.copy(
                    report = reportState.report.copy(
                        customerPrivacy = state
                    )
                )
            )
        }

        val onChangeNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidLongNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toLongOrNull()

                val newState = state.copy()

                when (propertyName) {
                    state::section4181A1.name -> newState.section4181A1 = propertyValue
                    state::section4181A2.name -> newState.section4181A2 = propertyValue
                    state::section4181A3.name -> newState.section4181A3 = propertyValue
                    state::section4181B1.name -> newState.section4181B1 = propertyValue
                    state::section4181B2.name -> newState.section4181B2 = propertyValue
                    state::section4181B3.name -> newState.section4181B3 = propertyValue
                }

                stateSetter(newState)

                props.setInputState(
                    CurrentInputState(
                        parentPath = field,
                        childPath = propertyName,
                        index = changeIndex,
                        value = JsonPrimitive(propertyValue),
                        objectName = EsgSection.CUSTOMER_PRIVACY.name,
                        reportName = reportState.reportFileName
                    )
                )
            }
        }

        val onBlurNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidLongNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toLongOrNull()
                props.onBlurApiCall(
                    propertyName,
                    JsonPrimitive(propertyValue),
                    changeIndex,
                    field,
                    EsgSection.CUSTOMER_PRIVACY.name
                )
            }
        }

        val onChangeTextArea: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value

            val newState = state.copy()

            when (propertyName) {
                state::section4181A4.name -> newState.section4181A4 = propertyValue
                state::section4181B4.name -> newState.section4181B4 = propertyValue

                state::section3_3_418A1.name -> newState.section3_3_418A1 = propertyValue
                state::section3_3_418A2.name -> newState.section3_3_418A2 = propertyValue
                state::section3_3_418A3.name -> newState.section3_3_418A3 = propertyValue
                state::section3_3_418A4.name -> newState.section3_3_418A4 = propertyValue
                state::section3_3_418A5.name -> newState.section3_3_418A5 = propertyValue
                state::section3_3_418A6.name -> newState.section3_3_418A6 = propertyValue
            }

            stateSetter(newState)

            props.setInputState(
                CurrentInputState(
                    parentPath = field,
                    childPath = propertyName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue),
                    objectName = EsgSection.CUSTOMER_PRIVACY.name,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurTextArea: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value
            props.onBlurApiCall(
                propertyName,
                JsonPrimitive(propertyValue),
                changeIndex,
                field,
                EsgSection.CUSTOMER_PRIVACY.name
            )
        }

        Box {
            className = ESGCommonStyles.SECTION_MAIN_LAYOUT.cssClass

            GRIStandardName {
                griStandard = EsgSection.CUSTOMER_PRIVACY
            }

            GenericSection_3_3 {
                this.onBlurTextArea = onBlurTextArea
                this.onChangeTextArea = onChangeTextArea
                this.materialTopicName = EsgSection.CUSTOMER_PRIVACY.description

                this.section3_3_A1_name = state::section3_3_418A1.name
                this.section3_3_A1_value = state.section3_3_418A1

                this.section3_3_A2_name = state::section3_3_418A2.name
                this.section3_3_A2_value = state.section3_3_418A2

                this.section3_3_A3_name = state::section3_3_418A3.name
                this.section3_3_A3_value = state.section3_3_418A3

                this.section3_3_A4_name = state::section3_3_418A4.name
                this.section3_3_A4_value = state.section3_3_418A4

                this.section3_3_A5_name = state::section3_3_418A5.name
                this.section3_3_A5_value = state.section3_3_418A5

                this.section3_3_A6_name = state::section3_3_418A6.name
                this.section3_3_A6_value = state.section3_3_418A6
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_418_1)) {
                GRI_418_1 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeNumber = onChangeNumber
                    this.onBlurNumber = onBlurNumber
                }
            }
        }
    }
}