package com.ecosave.watch.portal.components.esg.diversityandequalopportunity

import com.ecosave.watch.portal.components.esg.EsgCoreComponentProps
import com.ecosave.watch.portal.components.esg.GenericSection_3_3
import com.ecosave.watch.portal.components.esg.formcontrols.GRIStandardName
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.isValidDecimalNumber
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.EsgSection
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import com.ecosave.watch.portal.helpers.esg.failureErrorMessage
import com.ecosave.watch.portal.helpers.esg.omitDisclosureFromUI
import com.ecosave.watch.portal.helpers.esg.resetPreviousPatchState
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.esg.BasicSalaryAndRemunerationRatio
import com.ecosave.watch.portal.models.esg.CurrentInputState
import com.ecosave.watch.portal.models.esg.DiversityAndEqualOpportunityState
import com.ecosave.watch.portal.models.esg.EsgCollectionAddRow
import com.ecosave.watch.portal.models.esg.EsgCollectionDeleteRow
import com.ecosave.watch.portal.models.esg.RowPatch
import com.ecosave.watch.portal.services.esg.addDynamicRowOrSection
import com.ecosave.watch.portal.services.esg.deleteDynamicRowOrSection
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import kotlinx.coroutines.launch
import kotlinx.serialization.json.JsonPrimitive
import mui.material.Box
import react.FC
import react.dom.events.FocusEvent
import react.dom.events.FormEvent
import web.html.HTMLDivElement
import web.html.HTMLElement
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement

val DiversityAndEqualOpportunityComponent = FC<EsgCoreComponentProps> { props ->
    val reportState = props.reportState
    val setReportState = props.setReportState

    reportState.report?.diversityAndEqualOpportunity?.let { state ->
        fun stateSetter(state: DiversityAndEqualOpportunityState) {
            setReportState(
                reportState.copy(
                    report = reportState.report.copy(
                        diversityAndEqualOpportunity = state
                    )
                )
            )
        }

        val addRowOrSection: (String) -> Unit = { fieldName ->
            resetPreviousPatchState(props.setInputState)
            mainScope.launch {
                props.setAddDeleteRowOrSection(DynamicTableAction.ADD)
                props.setAddDeleteRowOrSectionApiCallInProgress(true)
                val apiCallStatus = addDynamicRowOrSection(
                    EsgCollectionAddRow(
                        objectName = EsgSection.DIVERSITY_AND_EQUAL_OPPORTUNITY.name,
                        reportFileName = reportState.reportFileName,
                        patchCollection = RowPatch(
                            pathFirst = fieldName
                        )
                    )
                )
                if (apiCallStatus == ApiCallStatus.SUCCESS) {
                    val newState = state.copy()

                    when (fieldName) {
                        state::basicSalaryAndRemunerationRatioList.name -> {
                            newState.basicSalaryAndRemunerationRatioList.add(BasicSalaryAndRemunerationRatio())
                            props.setNotificationMessage(ReportConstants.ADD_ROW_NOTIFICATION_MESSAGE)
                        }
                    }

                    stateSetter(newState)

                    props.setNotificationStatus(NotificationStatus.SUCCESS)
                    props.setOpenAlertNotifications(true)
                } else {
                    failureErrorMessage(
                        props.setNotificationMessage,
                        props.setNotificationStatus,
                        props.setOpenAlertNotifications
                    )
                }
                props.setAddDeleteRowOrSectionApiCallInProgress(false)
            }
        }

        val deleteRowOrSection: (String, Int) -> Unit = { fieldName, index ->
            resetPreviousPatchState(props.setInputState)
            mainScope.launch {
                props.setAddDeleteRowOrSection(DynamicTableAction.DELETE)
                props.setAddDeleteRowOrSectionApiCallInProgress(true)
                val apiCallStatus = deleteDynamicRowOrSection(
                    EsgCollectionDeleteRow(
                        objectPatchEnum = EsgSection.DIVERSITY_AND_EQUAL_OPPORTUNITY,
                        reportFileName = reportState.reportFileName,
                        pathFirst = fieldName,
                        indexAt = index
                    )
                )
                if (apiCallStatus == ApiCallStatus.SUCCESS) {
                    val newState = state.copy()

                    when (fieldName) {
                        state::basicSalaryAndRemunerationRatioList.name -> {
                            newState.basicSalaryAndRemunerationRatioList.removeAt(index)
                            props.setNotificationMessage(ReportConstants.DELETE_ROW_NOTIFICATION_MESSAGE)
                        }
                    }

                    stateSetter(newState)

                    props.setNotificationStatus(NotificationStatus.SUCCESS)
                    props.setOpenAlertNotifications(true)
                } else {
                    failureErrorMessage(
                        props.setNotificationMessage,
                        props.setNotificationStatus,
                        props.setOpenAlertNotifications
                    )
                }
                props.setAddDeleteRowOrSectionApiCallInProgress(false)
            }
        }

        val onChangeDecimalNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidDecimalNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toDoubleOrNull()

                val newState = state.copy()

                if (field == state::basicSalaryAndRemunerationRatioList.name && changeIndex != null) {
                    newState.basicSalaryAndRemunerationRatioList[changeIndex].let {
                        when (propertyName) {
                            it::section4052A3.name -> it.section4052A3 = propertyValue
                            it::section4052A4.name -> it.section4052A4 = propertyValue
                        }
                    }
                } else {
                    newState.let {
                        when (propertyName) {
                            it::section4051A1.name -> it.section4051A1 = propertyValue
                            it::section4051A2.name -> it.section4051A2 = propertyValue
                            it::section4051A3.name -> it.section4051A3 = propertyValue
                            it::section4051A4.name -> it.section4051A4 = propertyValue
                            it::section4051A5.name -> it.section4051A5 = propertyValue
                            it::section4051A6.name -> it.section4051A6 = propertyValue
                            it::section4051A7.name -> it.section4051A7 = propertyValue
                            it::section4051A8.name -> it.section4051A8 = propertyValue
                            it::section4051A9.name -> it.section4051A9 = propertyValue
                            it::section4051A10.name -> it.section4051A10 = propertyValue
                            it::section4051A11.name -> it.section4051A11 = propertyValue
                            it::section4051A12.name -> it.section4051A12 = propertyValue
                            it::section4051A13.name -> it.section4051A13 = propertyValue
                            it::section4051A14.name -> it.section4051A14 = propertyValue
                            it::section4051A15.name -> it.section4051A15 = propertyValue
                            it::section4051A16.name -> it.section4051A16 = propertyValue
                            it::section4051A17.name -> it.section4051A17 = propertyValue
                            it::section4051A18.name -> it.section4051A18 = propertyValue
                            it::section4051A19.name -> it.section4051A19 = propertyValue
                            it::section4051A20.name -> it.section4051A20 = propertyValue
                            it::section4051A21.name -> it.section4051A21 = propertyValue
                        }
                    }
                }

                stateSetter(newState)

                props.setInputState(
                    CurrentInputState(
                        parentPath = field,
                        childPath = propertyName,
                        index = changeIndex,
                        value = JsonPrimitive(propertyValue),
                        objectName = EsgSection.DIVERSITY_AND_EQUAL_OPPORTUNITY.name,
                        reportName = reportState.reportFileName
                    )
                )
            }
        }

        val onBlurDecimalNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidDecimalNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toDoubleOrNull()
                props.onBlurApiCall(
                    propertyName,
                    JsonPrimitive(propertyValue),
                    changeIndex,
                    field,
                    EsgSection.DIVERSITY_AND_EQUAL_OPPORTUNITY.name
                )
            }
        }

        val onChangeTextArea: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value

            val newState = state.copy()

            if (field == DiversityAndEqualOpportunityState::basicSalaryAndRemunerationRatioList.name && changeIndex != null) {
                newState.basicSalaryAndRemunerationRatioList[changeIndex].let {
                    when (propertyName) {
                        it::section4052A1.name -> it.section4052A1 = propertyValue
                        it::section4052A2.name -> it.section4052A2 = propertyValue
                    }
                }
            } else {
                newState.let {
                    when (propertyName) {
                        it::section4052Field1.name -> it.section4052Field1 = propertyValue

                        it::section3_3_405A1.name -> it.section3_3_405A1 = propertyValue
                        it::section3_3_405A2.name -> it.section3_3_405A2 = propertyValue
                        it::section3_3_405A3.name -> it.section3_3_405A3 = propertyValue
                        it::section3_3_405A4.name -> it.section3_3_405A4 = propertyValue
                        it::section3_3_405A5.name -> it.section3_3_405A5 = propertyValue
                        it::section3_3_405A6.name -> it.section3_3_405A6 = propertyValue
                    }
                }
            }

            stateSetter(newState)

            props.setInputState(
                CurrentInputState(
                    parentPath = field,
                    childPath = propertyName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue),
                    objectName = EsgSection.DIVERSITY_AND_EQUAL_OPPORTUNITY.name,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurTextArea: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value

            props.onBlurApiCall(
                propertyName,
                JsonPrimitive(propertyValue),
                changeIndex,
                field,
                EsgSection.DIVERSITY_AND_EQUAL_OPPORTUNITY.name
            )
        }

        Box {
            className = ESGCommonStyles.SECTION_MAIN_LAYOUT.cssClass

            GRIStandardName {
                griStandard = EsgSection.DIVERSITY_AND_EQUAL_OPPORTUNITY
            }

            GenericSection_3_3 {
                this.onBlurTextArea = onBlurTextArea
                this.onChangeTextArea = onChangeTextArea
                this.materialTopicName = EsgSection.DIVERSITY_AND_EQUAL_OPPORTUNITY.description

                this.section3_3_A1_name = state::section3_3_405A1.name
                this.section3_3_A1_value = state.section3_3_405A1

                this.section3_3_A2_name = state::section3_3_405A2.name
                this.section3_3_A2_value = state.section3_3_405A2

                this.section3_3_A3_name = state::section3_3_405A3.name
                this.section3_3_A3_value = state.section3_3_405A3

                this.section3_3_A4_name = state::section3_3_405A4.name
                this.section3_3_A4_value = state.section3_3_405A4

                this.section3_3_A5_name = state::section3_3_405A5.name
                this.section3_3_A5_value = state.section3_3_405A5

                this.section3_3_A6_name = state::section3_3_405A6.name
                this.section3_3_A6_value = state.section3_3_405A6
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_405_1)) {
                GRI_405_1 {
                    this.esgReportState = reportState
                    this.onChangeDecimalNumber = onChangeDecimalNumber
                    this.onBlurDecimalNumber = onBlurDecimalNumber
                }
            }
            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_405_2)) {
                GRI_405_2 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeDecimalNumber = onChangeDecimalNumber
                    this.onBlurDecimalNumber = onBlurDecimalNumber
                    this.addRowOrSection = addRowOrSection
                    this.deleteRowOrSection = deleteRowOrSection
                }
            }
        }
    }
}