package com.ecosave.watch.portal.services.auth

import com.ecosave.watch.portal.helpers.BackendServerURL
import com.ecosave.watch.portal.models.auth.Credential
import com.ecosave.watch.portal.models.auth.LoginApiResponse
import com.ecosave.watch.portal.models.auth.LoginResponse
import com.ecosave.watch.portal.services.nonAuthClient
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*

suspend fun loginService(email: String, password: String): LoginApiResponse? {
    try {
        val response: HttpResponse = nonAuthClient.post("$BackendServerURL/userAccount/login") {
            contentType(ContentType.Application.Json)
            setBody(Credential(email.trim(), password))
        }
        when (response.status) {
            HttpStatusCode.OK -> {
                val loginResponse: LoginResponse = response.body()
                return LoginApiResponse(
                    HttpStatusCode.OK,
                    loginResponse
                )
            }

            HttpStatusCode.Unauthorized -> {
                return LoginApiResponse(
                    HttpStatusCode.Unauthorized,
                    null
                )
            }
        }
    } catch (e: dynamic) {
        console.log("Error Message: ", e)
    }
    return null
}
