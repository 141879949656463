package com.ecosave.watch.portal.components.esg.energy

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val GRI_302_2 = FC<EsgSubFormComponentProps> { props ->
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea
    val onChangeText = props.onChangeText
    val onBlurText = props.onBlurText
    val onChangeDecimalNumber = props.onChangeDecimalNumber
    val onBlurDecimalNumber = props.onBlurDecimalNumber

    props.esgReportState.report?.energy?.let { state ->
        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_302_2
        }

        DisclosureSubHeading {
            subHeading = "Upstream"
        }
        Box {
            className = ESGCommonStyles.THREE_COLUMN_TABLE_8_2_2.cssClass

            TableHeader {
                header = "Renewable energy categories/activities"
            }
            TableHeader {
                header = "Unit"
            }
            TableHeader {
                header = "Value"
            }

            label {
                +"Purchased goods and services"
            }
            ESGTextFieldWrapper {
                name = state::section3022UpstreamUnit2.name
                value = state.section3022UpstreamUnit2
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section3022UpstreamCurrent2.name
                value = state.section3022UpstreamCurrent2
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Capital goods"
            }
            ESGTextFieldWrapper {
                name = state::section3022UpstreamUnit3.name
                value = state.section3022UpstreamUnit3
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section3022UpstreamCurrent3.name
                value = state.section3022UpstreamCurrent3
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Fuel- and energy-related activities"
            }
            ESGTextFieldWrapper {
                name = state::section3022UpstreamUnit4.name
                value = state.section3022UpstreamUnit4
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section3022UpstreamCurrent4.name
                value = state.section3022UpstreamCurrent4
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Upstream transportation and distribution"
            }
            ESGTextFieldWrapper {
                name = state::section3022UpstreamUnit5.name
                value = state.section3022UpstreamUnit5
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section3022UpstreamCurrent5.name
                value = state.section3022UpstreamCurrent5
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Waste generated in operations"
            }
            ESGTextFieldWrapper {
                name = state::section3022UpstreamUnit6.name
                value = state.section3022UpstreamUnit6
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section3022UpstreamCurrent6.name
                value = state.section3022UpstreamCurrent6
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Business travel"
            }
            ESGTextFieldWrapper {
                name = state::section3022UpstreamUnit7.name
                value = state.section3022UpstreamUnit7
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section3022UpstreamCurrent7.name
                value = state.section3022UpstreamCurrent7
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Employee commuting"
            }
            ESGTextFieldWrapper {
                name = state::section3022UpstreamUnit8.name
                value = state.section3022UpstreamUnit8
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section3022UpstreamCurrent8.name
                value = state.section3022UpstreamCurrent8
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Upstream leased assets"
            }
            ESGTextFieldWrapper {
                name = state::section3022UpstreamUnit9.name
                value = state.section3022UpstreamUnit9
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section3022UpstreamCurrent9.name
                value = state.section3022UpstreamCurrent9
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Other upstream"
            }
            ESGTextFieldWrapper {
                name = state::section3022UpstreamUnit10.name
                value = state.section3022UpstreamUnit10
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section3022UpstreamCurrent10.name
                value = state.section3022UpstreamCurrent10
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }
        }

        DisclosureSubHeading {
            subHeading = "Downstream"
        }
        Box {
            className = ESGCommonStyles.THREE_COLUMN_TABLE_8_2_2.cssClass

            TableHeader {
                header = "Renewable energy categories/activities"
            }
            TableHeader {
                header = "Unit"
            }
            TableHeader {
                header = "Value"
            }

            label {
                +"Downstream transportation and distribution"
            }
            ESGTextFieldWrapper {
                name = state::section3022DownstreamUnit12.name
                value = state.section3022DownstreamUnit12
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section3022DownstreamCurrent12.name
                value = state.section3022DownstreamCurrent12
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Processing of sold products"
            }
            ESGTextFieldWrapper {
                name = state::section3022DownstreamUnit13.name
                value = state.section3022DownstreamUnit13
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section3022DownstreamCurrent13.name
                value = state.section3022DownstreamCurrent13
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Use of sold products"
            }
            ESGTextFieldWrapper {
                name = state::section3022DownstreamUnit14.name
                value = state.section3022DownstreamUnit14
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section3022DownstreamCurrent14.name
                value = state.section3022DownstreamCurrent14
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"End-of-life treatment of sold products"
            }
            ESGTextFieldWrapper {
                name = state::section3022DownstreamUnit15.name
                value = state.section3022DownstreamUnit15
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section3022DownstreamCurrent15.name
                value = state.section3022DownstreamCurrent15
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Downstream leased assets"
            }
            ESGTextFieldWrapper {
                name = state::section3022DownstreamUnit16.name
                value = state.section3022DownstreamUnit16
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section3022DownstreamCurrent16.name
                value = state.section3022DownstreamCurrent16
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Franchises"
            }
            ESGTextFieldWrapper {
                name = state::section3022DownstreamUnit17.name
                value = state.section3022DownstreamUnit17
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section3022DownstreamCurrent17.name
                value = state.section3022DownstreamCurrent17
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Investments"
            }
            ESGTextFieldWrapper {
                name = state::section3022DownstreamUnit18.name
                value = state.section3022DownstreamUnit18
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section3022DownstreamCurrent18.name
                value = state.section3022DownstreamCurrent18
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Other downstream"
            }
            ESGTextFieldWrapper {
                name = state::section3022DownstreamUnit19.name
                value = state.section3022DownstreamUnit19
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section3022DownstreamCurrent19.name
                value = state.section3022DownstreamCurrent19
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }
        }

        Box {
            label {
                +"Total energy consumption outside the organization"
            }
            ESGNumberFieldWrapper {
                name = state::section3022Field3.name
                value = state.section3022Field3
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }
        }
        Box {
            label {
                +"What is the source of the conversion factors used?"
            }
            ESGTextAreaFieldWrapper {
                name = state::section3022Field2.name
                value = state.section3022Field2
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
        Box {
            label {
                +"Standards, methodologies, assumptions, and/or calculation tools used."
            }
            ESGTextAreaFieldWrapper {
                name = state::section3022Field1.name
                value = state.section3022Field1
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}