package com.ecosave.watch.portal.pages

import com.ecosave.watch.portal.components.facilitygroupmanagement.GroupActionsComponent
import com.ecosave.watch.portal.components.facilitygroupmanagement.GroupsTreeViewComponent
import com.ecosave.watch.portal.helpers.common.PageTitles
import com.ecosave.watch.portal.helpers.common.md
import com.ecosave.watch.portal.helpers.common.sm
import com.ecosave.watch.portal.helpers.common.xs
import com.ecosave.watch.portal.helpers.facilitygroupmanagement.findGroupById
import com.ecosave.watch.portal.helpers.facilitygroupmanagement.findParentFacilities
import com.ecosave.watch.portal.models.facilitygroupmanagement.FacilityGroup
import com.ecosave.watch.portal.models.facilitygroupmanagement.FacilitySummary
import com.ecosave.watch.portal.services.facilitygroupmanagement.getFacilityGroups
import com.ecosave.watch.portal.useGlobalState
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.Grid
import mui.system.responsive
import react.FC
import react.Props
import react.useEffect
import react.useEffectOnce
import react.useState


val FacilityGroupManagementPage = FC<Props> {

    val (mainGroup, mainGroupStateSetter) = useState(FacilityGroup())
    val (selectedGroupId, selectedGroupIdStateSetter) = useState(mainGroup.facilityGroupId)
    var selectedGroup by useState<FacilityGroup?>(null)
    var isLoading by useState(false)
    var parentGroupFacilities by useState<List<FacilitySummary>>(emptyList())
    val globalState = useGlobalState()

    useEffectOnce {
        globalState.updatePageTitle(PageTitles.FACILITY_GROUP_MANAGEMENT.title)
        mainScope.launch {
            isLoading = true
            val result = getFacilityGroups()
            if (result != null) {
                mainGroupStateSetter(result)
                selectedGroupIdStateSetter(result.facilityGroupId)
            }
            isLoading = false
        }
    }

    useEffect(selectedGroupId) {
        selectedGroup = if (selectedGroupId == mainGroup.facilityGroupId) {
            FacilityGroup(
                facilityGroupId = mainGroup.facilityGroupId,
                facilityGroupName = mainGroup.facilityGroupName,
                facilities = mainGroup.facilities,
                childGroups = mainGroup.childGroups
            )
        } else {
            mainGroup.childGroups?.let { it1 -> findGroupById(it1, selectedGroupId) }
        }

        parentGroupFacilities = findParentFacilities(mainGroup, selectedGroupId)
    }

    Box {
        Grid {
            container = true
            spacing = responsive(3)
            Grid {
                item = true
                xs = 12
                sm = 12
                md = 3
                GroupsTreeViewComponent {
                    group = mainGroup
                    selectedGroupIdStateSetterProps = selectedGroupIdStateSetter
                    isLoadingProp = isLoading
                }
            }
            Grid {
                item = true
                xs = 12
                sm = 12
                md = 9
                GroupActionsComponent {
                    selectedGroupProp = selectedGroup
                    group = mainGroup
                    groupStateSetter = mainGroupStateSetter
                    selectedGroupIdStateSetterProps = selectedGroupIdStateSetter
                    isLoadingProp = isLoading
                    parentFacilities = parentGroupFacilities
                }
            }
        }
    }
}



