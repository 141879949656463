package com.ecosave.watch.portal.components.esg.generaldisclosures.strategypoliciesandpractices

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val Disclosure_2_22 = FC<EsgSubFormComponentProps> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.strategyPoliciesPractices?.let { state ->

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_2_22
        }

        Box {
            label {
                +"Report a statement from the highest governance body or most senior executive of the organization about the relevance of sustainable development to the organization and its strategy for contributing to sustainable development."
            }
            ESGTextAreaFieldWrapper {
                name = state::section222A1.name
                value = state.section222A1 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}