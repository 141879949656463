package com.ecosave.watch.portal.components.esg.waterandeffluents

import com.ecosave.watch.portal.components.esg.EsgCoreComponentProps
import com.ecosave.watch.portal.components.esg.GenericSection_3_3
import com.ecosave.watch.portal.components.esg.formcontrols.GRIStandardName
import com.ecosave.watch.portal.helpers.common.isValidDecimalNumber
import com.ecosave.watch.portal.helpers.esg.EsgSection
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.omitDisclosureFromUI
import com.ecosave.watch.portal.models.esg.CurrentInputState
import com.ecosave.watch.portal.models.esg.WaterAndEffluentsState
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import kotlinx.serialization.json.JsonPrimitive
import mui.material.Box
import react.FC
import react.dom.events.FocusEvent
import react.dom.events.FormEvent
import web.html.HTMLDivElement
import web.html.HTMLElement
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement

val WaterAndEffluentsComponent = FC<EsgCoreComponentProps> { props ->

    val reportState = props.reportState
    val setReportState = props.setReportState
    val griStandardName = EsgSection.WATER_AND_EFFLUENTS

    reportState.report?.waterAndEffluents?.let { state ->

        fun stateSetter(state: WaterAndEffluentsState) {
            setReportState(
                reportState.copy(
                    report = reportState.report.copy(
                        waterAndEffluents = state
                    )
                )
            )
        }


        val onChangeTextArea: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value

            val newState = state.copy()

            newState.let {
                when (propertyName) {
                    it::section3_3_303A1.name -> it.section3_3_303A1 = propertyValue
                    it::section3_3_303A2.name -> it.section3_3_303A2 = propertyValue
                    it::section3_3_303A3.name -> it.section3_3_303A3 = propertyValue
                    it::section3_3_303A4.name -> it.section3_3_303A4 = propertyValue
                    it::section3_3_303A5.name -> it.section3_3_303A5 = propertyValue
                    it::section3_3_303A6.name -> it.section3_3_303A6 = propertyValue

                    it::section3031A1.name -> it.section3031A1 = propertyValue
                    it::section3031A2.name -> it.section3031A2 = propertyValue
                    it::section3031A3.name -> it.section3031A3 = propertyValue
                    it::section3031A4.name -> it.section3031A4 = propertyValue

                    it::section3032B1.name -> it.section3032B1 = propertyValue
                    it::section3033Field1.name -> it.section3033Field1 = propertyValue

                    state::section3034Field1.name -> it.section3034Field1 = propertyValue
                    state::section3034Field2.name -> it.section3034Field2 = propertyValue
                    state::section3034Field3.name -> it.section3034Field3 = propertyValue
                    state::section3034Field4.name -> it.section3034Field4 = propertyValue

                    state::section3035Current3.name -> it.section3035Current3 = propertyValue
                    state::section3035Current4.name -> it.section3035Current4 = propertyValue
                }
            }

            stateSetter(newState)

            props.setInputState(
                CurrentInputState(
                    parentPath = field,
                    childPath = propertyName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue),
                    objectName = griStandardName.name,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurTextArea: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value

            props.onBlurApiCall(
                propertyName,
                JsonPrimitive(propertyValue),
                changeIndex,
                field,
                griStandardName.name
            )
        }

        val onChangeDecimalNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement

            if (isValidDecimalNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toDoubleOrNull()

                val newState = state.copy()

                when (propertyName) {
                    state::section3033Current1.name -> newState.section3033Current1 = propertyValue
                    state::section3033Current2.name -> newState.section3033Current2 = propertyValue
                    state::section3033Current3.name -> newState.section3033Current3 = propertyValue
                    state::section3033Current4.name -> newState.section3033Current4 = propertyValue
                    state::section3033Current5.name -> newState.section3033Current5 = propertyValue
                    state::section3033Current6.name -> newState.section3033Current6 = propertyValue
                    state::section3033Current7.name -> newState.section3033Current7 = propertyValue
                    state::section3033Current8.name -> newState.section3033Current8 = propertyValue
                    state::section3033Current9.name -> newState.section3033Current9 = propertyValue
                    state::section3033Current10.name -> newState.section3033Current10 = propertyValue
                    state::section3033Current11.name -> newState.section3033Current11 = propertyValue
                    state::section3033Current12.name -> newState.section3033Current12 = propertyValue

                    state::section3033Current13.name -> newState.section3033Current13 = propertyValue
                    state::section3033Current14.name -> newState.section3033Current14 = propertyValue
                    state::section3033Current15.name -> newState.section3033Current15 = propertyValue
                    state::section3033Current16.name -> newState.section3033Current16 = propertyValue
                    state::section3033Current17.name -> newState.section3033Current17 = propertyValue
                    state::section3033Current18.name -> newState.section3033Current18 = propertyValue
                    state::section3033Current19.name -> newState.section3033Current19 = propertyValue
                    state::section3033Current20.name -> newState.section3033Current20 = propertyValue
                    state::section3033Current21.name -> newState.section3033Current21 = propertyValue
                    state::section3033Current22.name -> newState.section3033Current22 = propertyValue
                    state::section3033Current23.name -> newState.section3033Current23 = propertyValue
                    state::section3033Current24.name -> newState.section3033Current24 = propertyValue
                    state::section3033Current25.name -> newState.section3033Current25 = propertyValue
                    state::section3033Current26.name -> newState.section3033Current26 = propertyValue
                    state::section3033Current27.name -> newState.section3033Current27 = propertyValue
                    state::section3033Current28.name -> newState.section3033Current28 = propertyValue
                    state::section3033Current29.name -> newState.section3033Current29 = propertyValue
                    state::section3033Current30.name -> newState.section3033Current30 = propertyValue
                    state::section3033Current31.name -> newState.section3033Current31 = propertyValue
                    state::section3033Current32.name -> newState.section3033Current32 = propertyValue
                    state::section3033Current33.name -> newState.section3033Current33 = propertyValue
                    state::section3033Current34.name -> newState.section3033Current34 = propertyValue
                    state::section3033Current35.name -> newState.section3033Current35 = propertyValue
                    state::section3033Current36.name -> newState.section3033Current36 = propertyValue

                    state::section3034Current1.name ->  newState.section3034Current1 = propertyValue
                    state::section3034Current2.name ->  newState.section3034Current2 = propertyValue
                    state::section3034Current3.name ->  newState.section3034Current3 = propertyValue
                    state::section3034Current4.name ->  newState.section3034Current4 = propertyValue
                    state::section3034Current5.name ->  newState.section3034Current5 = propertyValue
                    state::section3034Current6.name ->  newState.section3034Current6 = propertyValue
                    state::section3034Current7.name ->  newState.section3034Current7 = propertyValue
                    state::section3034Current8.name ->  newState.section3034Current8 = propertyValue
                    state::section3034Current9.name ->  newState.section3034Current9 = propertyValue
                    state::section3034Current10.name -> newState.section3034Current10 = propertyValue
                    state::section3034Current11.name -> newState.section3034Current11 = propertyValue
                    state::section3034Current12.name -> newState.section3034Current12 = propertyValue
                    state::section3034Current13.name -> newState.section3034Current13 = propertyValue
                    state::section3034Current14.name -> newState.section3034Current14 = propertyValue
                    state::section3034Current15.name -> newState.section3034Current15 = propertyValue
                    state::section3034Current16.name -> newState.section3034Current16 = propertyValue
                    state::section3034Current17.name -> newState.section3034Current17 = propertyValue
                    state::section3034Current18.name -> newState.section3034Current18 = propertyValue
                    state::section3034Current19.name -> newState.section3034Current19 = propertyValue
                    state::section3034Current20.name -> newState.section3034Current20 = propertyValue
                    state::section3034Current21.name -> newState.section3034Current21 = propertyValue
                    state::section3034Current22.name -> newState.section3034Current22 = propertyValue
                    state::section3034Current23.name -> newState.section3034Current23 = propertyValue
                    state::section3034Current24.name -> newState.section3034Current24 = propertyValue
                    state::section3034Current25.name -> newState.section3034Current25 = propertyValue
                    state::section3034Current26.name -> newState.section3034Current26 = propertyValue
                    state::section3034Current27.name -> newState.section3034Current27 = propertyValue
                    state::section3034Current28.name -> newState.section3034Current28 = propertyValue
                    state::section3034Current29.name -> newState.section3034Current29 = propertyValue
                    state::section3034Current30.name -> newState.section3034Current30 = propertyValue
                    state::section3034Current31.name -> newState.section3034Current31 = propertyValue
                    state::section3034Current32.name -> newState.section3034Current32 = propertyValue
                    state::section3034Current33.name -> newState.section3034Current33 = propertyValue
                    state::section3034Current34.name -> newState.section3034Current34 = propertyValue
                    state::section3034Current35.name -> newState.section3034Current35 = propertyValue
                    state::section3034Current36.name -> newState.section3034Current36 = propertyValue

                    state::section3035Current1.name -> newState.section3035Current1 = propertyValue
                    state::section3035Current2.name -> newState.section3035Current2 = propertyValue
                }

                stateSetter(newState)

                props.setInputState(
                    CurrentInputState(
                        parentPath = field,
                        childPath = propertyName,
                        index = changeIndex,
                        value = JsonPrimitive(propertyValue),
                        objectName = griStandardName.name,
                        reportName = reportState.reportFileName
                    )
                )
            }
        }

        val onBlurDecimalNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidDecimalNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toDoubleOrNull()
                props.onBlurApiCall(
                    propertyName,
                    JsonPrimitive(propertyValue),
                    changeIndex,
                    field,
                    griStandardName.name
                )
            }
        }


        Box {
            className = ESGCommonStyles.SECTION_MAIN_LAYOUT.cssClass
            GRIStandardName {
                griStandard = griStandardName
            }

            GenericSection_3_3 {
                this.onBlurTextArea = onBlurTextArea
                this.onChangeTextArea = onChangeTextArea
                this.materialTopicName = griStandardName.description

                this.section3_3_A1_name = state::section3_3_303A1.name
                this.section3_3_A1_value = state.section3_3_303A1

                this.section3_3_A2_name = state::section3_3_303A2.name
                this.section3_3_A2_value = state.section3_3_303A2

                this.section3_3_A3_name = state::section3_3_303A3.name
                this.section3_3_A3_value = state.section3_3_303A3

                this.section3_3_A4_name = state::section3_3_303A4.name
                this.section3_3_A4_value = state.section3_3_303A4

                this.section3_3_A5_name = state::section3_3_303A5.name
                this.section3_3_A5_value = state.section3_3_303A5

                this.section3_3_A6_name = state::section3_3_303A6.name
                this.section3_3_A6_value = state.section3_3_303A6
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_303_1)) {
                GRI_303_1 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                }
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_303_2)) {
                GRI_303_2 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                }
            }
            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_303_3)) {
                GRI_303_3 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeDecimalNumber = onChangeDecimalNumber
                    this.onBlurDecimalNumber = onBlurDecimalNumber
                }
            }
            if(omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_303_4)) {
                GRI_303_4 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeDecimalNumber = onChangeDecimalNumber
                    this.onBlurDecimalNumber = onBlurDecimalNumber
                }
            }
            if(omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_303_5)) {
                GRI_303_5 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeDecimalNumber = onChangeDecimalNumber
                    this.onBlurDecimalNumber = onBlurDecimalNumber
                }
            }
        }
    }
}