package com.ecosave.watch.portal.models.userprofile

import react.ReactNode

data class UserProfileValidationState(
    var firstNameErrorState: Boolean = false,
    var firstNameErrorMessage: ReactNode? = null,
    var lastNameErrorState: Boolean = false,
    var lastNameErrorMessage: ReactNode? = null,
    var jobTitleErrorState: Boolean = false,
    var jobTitleErrorMessage: ReactNode? = null,
)
