package com.ecosave.watch.portal.components.resourcecenter.usermanagement

val userManagementInviteUsersSentences = listOf(
    "On the  User Management Screen Click the Button “+ Invite Users”",
    "Type in the email address of the user",
    "Assign the role (Admin, User)",
    "Repeat steps 2-3 as many times as necessary",
    "When finished click invite"
)
val userManagementEditRoleSentences = listOf(
    "On the User Management Screen click the pencil icon in the Actions Column of the user whose role you want to edit",
    "Select the new role",
    "Click Submit"
)