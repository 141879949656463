package com.ecosave.watch.portal.components.esg.energy

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val GRI_302_4 = FC<EsgSubFormComponentProps> { props ->
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea
    val onChangeDecimalNumber = props.onChangeDecimalNumber
    val onBlurDecimalNumber = props.onBlurDecimalNumber

    props.esgReportState.report?.energy?.let { state ->
        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_302_4
        }

        Box {
            className = ESGCommonStyles.TWO_COLUMN_TABLE_8_4.cssClass
            label {
                +"Amount of reductions in energy consumption achieved as a direct result of conservation and efficiency initiatives, in joules or multiples."
            }
            ESGNumberFieldWrapper {
                name = state::section3024Field1.name
                value = state.section3024Field1
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }
        }
        Box {
            label {
                +"Types of energy included in the reductions; whether fuel, electricity, heating, cooling,steam, or all"
            }
            ESGTextAreaFieldWrapper {
                name = state::section3024Field2.name
                value = state.section3024Field2
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
        Box {
            label {
                +"Basis for calculating reductions in energy consumption, such as base year or baseline, including the rationale for choosing it."
            }
            ESGTextAreaFieldWrapper {
                name = state::section3024Field3.name
                value = state.section3024Field3
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
        Box {
            label {
                +"Standards, methodologies, assumptions, and/or calculation tools used"
            }
            ESGTextAreaFieldWrapper {
                name = state::section3024Field4.name
                value = state.section3024Field4
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
        Box {
            label {
                +"Describe whether energy reduction is estimated, modeled, or sourced from direct measurements. If estimation or modeling is used, "
                +"the organization shall disclose the methods used"
            }
            ESGTextAreaFieldWrapper {
                name = state::section3024Field5.name
                value = state.section3024Field5
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}