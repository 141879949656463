package com.ecosave.watch.portal.components.esg

import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.components.common.DialogWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.xs
import com.ecosave.watch.portal.helpers.esg.DisclosureOmissionReason
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.models.esg.EsgReportState
import com.ecosave.watch.portal.models.esg.ItemStatusTracker
import com.ecosave.watch.portal.models.esg.OmittedDetail
import com.ecosave.watch.portal.services.esg.omitOrIncludeDisclosure
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.DialogActions
import mui.material.DialogContent
import mui.material.FormControl
import mui.material.Grid
import mui.material.InputLabel
import mui.material.MenuItem
import mui.material.Select
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.StateSetter
import react.dom.onChange
import react.useEffect
import react.useState
import web.cssom.JustifyContent
import web.cssom.px
import web.html.HTMLTextAreaElement

external interface DisclosureOmissionComponentProps : Props {
    var showDisclosureOmissionDialog: Boolean
    var setShowDisclosureOmissionDialog: StateSetter<Boolean>
    var reportState: EsgReportState
    var setReportState: StateSetter<EsgReportState>
    var itemStatusTracker: ItemStatusTracker
}

val DisclosureOmissionComponent = FC<DisclosureOmissionComponentProps> { props ->

    val (omissionState, setOmissionState) = useState(
        OmittedDetail(
            reason = null,
            explanation = null,
        )
    )
    var isSaving by useState(false)
    val (notificationState, setNotificationState) = useState(NotificationState())

    fun closeDisclosureOmissionDialog() {
        props.setShowDisclosureOmissionDialog(false)
        isSaving = false
        setOmissionState(
            OmittedDetail(
                reason = null,
                explanation = null
            )
        )
    }

    useEffect(props.showDisclosureOmissionDialog) {
        // Populate form for edit
        if (props.showDisclosureOmissionDialog) {
            setOmissionState(
                omissionState.copy(
                    reason = props.itemStatusTracker.omittedDetail?.reason,
                    explanation = props.itemStatusTracker.omittedDetail?.explanation,
                )
            )
        }
    }

    DialogWrapper {
        open = props.showDisclosureOmissionDialog
        dialogTitle = "Omit Disclosure"
        DialogContent {
            Grid {
                sx {
                    padding = 20.px
                }
                container = true
                spacing = responsive(3)

                Grid {
                    item = true
                    xs = 12
                    +props.itemStatusTracker.disclosure.disclosureTitle
                }

                Grid {
                    item = true
                    xs = 12
                    FormControl {
                        fullWidth = true
                        InputLabel {
                            +"Reason For Omission"
                        }
                        Select {
                            label = ReactNode("Reason For Omission")
                            value = omissionState.reason?.name ?: ""
                            onChange = { event, _ ->
                                val value = event.target.value
                                setOmissionState(
                                    omissionState.copy(
                                        reason = DisclosureOmissionReason.valueOf(value)
                                    )
                                )
                            }
                            for (item in DisclosureOmissionReason.entries) {
                                MenuItem {
                                    value = item.name
                                    +item.omissionReason
                                }
                            }
                        }
                    }
                }

                omissionState.reason?.let {
                    Grid {
                        item = true
                        xs = 12
                        Box {
                            Typography {
                                sx {
                                    marginBottom = 20.px
                                }
                                variant = TypographyVariant.h6
                                +omissionState.reason.requiredExplanation
                            }

                            ESGTextAreaFieldWrapper {
                                value = omissionState.explanation
                                onChange = { event ->
                                    val target = event.target as HTMLTextAreaElement
                                    setOmissionState(
                                        omissionState.copy(
                                            explanation = target.value
                                        )
                                    )
                                }
                            }
                        }
                    }
                }
            }
        }
        DialogActions {
            sx {
                justifyContent = JustifyContent.center
            }
            Button {
                +"Cancel"
                onClick = {
                    closeDisclosureOmissionDialog()
                }
            }
            Button {
                if (isSaving) +"Saving" else +"Save"
                disabled = isSaving || (omissionState.reason == null || omissionState.explanation.isNullOrBlank())
                variant = ButtonVariant.contained
                onClick = {
                    mainScope.launch {
                        isSaving = true
                        val omittedDetail = OmittedDetail(
                            reason = omissionState.reason,
                            explanation = omissionState.explanation
                        )
                        val response =
                            omitOrIncludeDisclosure(omittedDetail, props.reportState.reportFileName, props.itemStatusTracker.disclosure)
                        if (response != null) {
                            props.setReportState(
                                props.reportState.copy(
                                    mainTableOfContents = response.mainTableOfContents,
                                    omittedDisclosuresList = response.omittedDisclosuresList
                                )
                            )
                            setNotificationState(
                                notificationState.copy(
                                    status = NotificationStatus.SUCCESS,
                                    message = if (props.itemStatusTracker.omittedDetail == null) "Disclosure has been omitted successfully." else
                                        "Details have been successfully modified.",
                                    visible = true
                                )
                            )
                            closeDisclosureOmissionDialog()
                        } else {
                            setNotificationState(
                                notificationState.copy(
                                    status = NotificationStatus.ERROR,
                                    message = Constants.NOTIFICATION_ERROR_MESSAGE,
                                    visible = true
                                )
                            )
                        }
                        isSaving = false
                    }
                }
            }
        }
    }
    AlertNotifications {
        open = notificationState.visible
        status = notificationState.status
        message = notificationState.message
        closeNotification = {
            setNotificationState(
                notificationState.copy(
                    visible = false
                )
            )
        }
    }
}