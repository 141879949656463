package com.ecosave.watch.portal.components.common

import com.ecosave.watch.portal.styles.ControlCenterPageStyles
import com.ecosave.watch.portal.helpers.Colors
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import web.cssom.Color

val EmptyDataMessage = FC<Props> {
    Typography {
        className = ControlCenterPageStyles.TEXT_CENTER.cssClass
        variant = TypographyVariant.subtitle1
        sx {
            color = Color(Colors.ORANGE_RED)
        }
        +"No data found"
    }
}