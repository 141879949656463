package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class SocioeconomicComplianceState(
    var section4191A1: String? = null,
    var section4191A2: String? = null,
    var section4191A3: String? = null,

    var section4191B1: String? = null,
    var section4191B2: String? = null,
    var section4191B3: String? = null,

    var section4191C1: String? = null,
    var section4191C2: String? = null,
    var section4191C3: String? = null,

    var section4191D1: String? = null,
    var section4191D2: String? = null,
    var section4191D3: String? = null,

    var section4191Part2A1: String? = null,
    var section4191Part2A2: String? = null,
    var section4191Part2A3: String? = null,

    var section4191Part2B1: String? = null,
    var section4191Part2B2: String? = null,
    var section4191Part2B3: String? = null,

    var section4191Part2C1: String? = null,
    var section4191Part2C2: String? = null,
    var section4191Part2C3: String? = null,

    var section4191Part2D1: String? = null,
    var section4191Part2D2: String? = null,
    var section4191Part2D3: String? = null
)
