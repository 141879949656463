package com.ecosave.watch.portal.pages

import com.ecosave.watch.portal.components.privacypolicy.TypographyWrapper
import com.ecosave.watch.portal.components.privacypolicy.AcceptableUse
import com.ecosave.watch.portal.components.privacypolicy.AgentsThirdPartyCommitment
import com.ecosave.watch.portal.components.privacypolicy.CancellationAndTermination
import com.ecosave.watch.portal.components.privacypolicy.CustomerData
import com.ecosave.watch.portal.components.privacypolicy.HeadingWrapper
import com.ecosave.watch.portal.components.privacypolicy.IntellectualProperty
import com.ecosave.watch.portal.components.privacypolicy.LimitationsOfLiability
import com.ecosave.watch.portal.components.privacypolicy.Miscellaneous
import com.ecosave.watch.portal.components.privacypolicy.NonUseAndNonDisclosureOfConfidentialInfo
import com.ecosave.watch.portal.components.privacypolicy.Payment
import com.ecosave.watch.portal.components.privacypolicy.RepresentationsAndIndemnities
import com.ecosave.watch.portal.components.privacypolicy.ServiceCommitmentAndSupport
import com.ecosave.watch.portal.components.privacypolicy.DisclaimerOfWarranty
import com.ecosave.watch.portal.components.privacypolicy.IllustrativeCalculations
import com.ecosave.watch.portal.components.privacypolicy.EcosaveUseOfCustomerData
import com.ecosave.watch.portal.components.privacypolicy.TermsOfServiceConstants
import com.ecosave.watch.portal.helpers.Colors
import mui.material.Box
import mui.material.Typography
import mui.material.TypographyAlign
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import web.cssom.Color
import web.cssom.FontWeight
import web.cssom.Padding
import web.cssom.px


val TermsOfServicePage = FC<Props> {
    Box {
        sx {
            padding = Padding(50.px, 150.px, 50.px, 150.px)
            backgroundColor = Color(Colors.WHITE)
            color = Color(Colors.MAIN_BLUE)
        }
        Box {
            sx {
                marginBottom = 30.px
            }
            Typography {
                sx {
                    fontWeight = FontWeight.bolder
                }
                variant = TypographyVariant.h3
                align = TypographyAlign.center
                +"EcosaveWatch's Terms Of Service"
            }
        }
        Box {
            Typography {
                sx {
                    fontWeight = FontWeight.bold
                }
                variant = TypographyVariant.subtitle1
                +"(Last Updated: July 11, 2024) "
            }
            TypographyWrapper {
                +"Thank you for visiting ${TermsOfServiceConstants.ecosaveWebsiteURL}. Together with the Privacy Policy, these Terms of Service are a contractual Agreement between Ecosave, LLC (“Ecosave”) and our users, so please read them carefully before accessing or using any of the services being offered on ${TermsOfServiceConstants.ecosaveWebsiteURL}. In consideration of the mutual promises contained in this Agreement, you and Ecosave agree as follows:"
            }
            HeadingWrapper {
                heading = "1. Definitions"
            }
            for (definition in TermsOfServiceConstants.definitonsList) {
                TypographyWrapper {
                    +definition
                }
            }
            HeadingWrapper {
                heading = "2. Description"
            }
            TypographyWrapper {
                +"Ecosave's mission is to transform software development by analyzing data across the stack to quantify the craft of development. Features and functionality are as described on our Website on the Subscription Date, as such features and functionality may change periodically. We operate our Service on our providers' hosting servers to enable Authorized Users to access and use our Service via the web. By using or accessing any part of our Service, you are representing that you have the capacity and authority to enter into this agreement and are consenting on behalf of yourself and/or as an authorized representative of your company, as applicable, to be bound by this agreement."
            }
            HeadingWrapper {
                heading = "3. Account Terms"
            }
            HeadingWrapper {
                heading = "3.1 Access to Services"
                subHeading = true
            }
            TypographyWrapper {
                +"Ecosave distributes the Service online. You are solely responsible for providing, installing, and maintaining at your own expense all equipment, facilities, and services necessary to enable Authorized Users' access and use of the Service through the interface, including, without limitation all computer hardware, software, and Internet access."
            }
            HeadingWrapper {
                heading = "3.2 User Account Security"
                subHeading = true
            }
            TypographyWrapper {
                +"You are solely responsible for tracking and for ensuring the security and confidentiality of all user identifiers and passwords. Ecosave has no liability with respect to any use or misuse of such identifiers or passwords, and any use other than as provided in this Agreement will be considered a breach of this Agreement by you."
            }
            TypographyWrapper {
                +"Ecosave has implemented commercially reasonable, industry-standard technical and organizational measures designed to secure your data from accidental loss and from unauthorized access, use, alteration, or disclosure. Each hosting provider for the Service has or will have similar contractual obligations to Ecosave. Ecosave does not represent that it meets the standards for operational compliance or certification in any specific area, including any government or industry-association requirements (see Section 15.3)."
            }
            AcceptableUse()
            HeadingWrapper {
                heading = "5. Service Period"
            }
            TypographyWrapper {
                +"This Agreement will commence on the Subscription Date and, unless terminated early in accordance with Section 7, continue for the number of months or year(s) specified in the Subscription as measured from the Subscription Date (the \"Initial Period\")."
            }
            TypographyWrapper {
                +"If you are using a Paying Plan, this Agreement will automatically renew for successive renewal periods of the same duration of the Initial Period (each, a \"Renewal Period\") unless one party gives notice of termination or non-renewal. The Initial Period and all subsequent Renewal Periods are collectively the \"Service Period.\""
            }
            TypographyWrapper {
                +"If you are using the Service under a Free Plan, this Agreement will be deemed month-to-month and either party will be free to not renew, or to terminate, this Agreement immediately upon notice to the other."
            }
            Payment()
            CancellationAndTermination()
            ServiceCommitmentAndSupport()
            AgentsThirdPartyCommitment()
            NonUseAndNonDisclosureOfConfidentialInfo()
            CustomerData()
            IntellectualProperty()
            LimitationsOfLiability()
            DisclaimerOfWarranty()
            IllustrativeCalculations()
            EcosaveUseOfCustomerData()
            RepresentationsAndIndemnities()
            Miscellaneous()

        }
    }
}