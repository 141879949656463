package com.ecosave.watch.portal.components.esg.generaldisclosures.strategypoliciesandpractices

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import mui.material.Box
import react.FC
import react.dom.html.OlType
import react.dom.html.ReactHTML.label
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.ol
import react.dom.onChange

val Disclosure_2_23 = FC<EsgSubFormComponentProps> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.strategyPoliciesPractices?.let { state ->

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_2_23
        }

        Box {
            label {
                +"Describe the policy commitment that's responsible for business conduct, including:"
                ol {
                    type = OlType.i
                    li {
                        +"the authoritative intergovernmental instruments that the commitments reference."
                    }
                    li {
                        +"whether the commitments stipulate conducting due diligence."
                    }
                    li {
                        +"whether the commitments stipulate applying the precautionary principle."
                    }
                    li {
                        +"whether the commitments stipulate respecting human rights."
                    }
                }
            }
            ESGTextAreaFieldWrapper {
                name = state::section223A1.name
                value = state.section223A1 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"Describe its specific policy commitment to respect human rights, including:"
                ol {
                    type = OlType.i
                    li {
                        +"the internationally recognized human rights that the commitment covers."
                    }
                    li {
                        +"the categories of stakeholders, including at-risk or vulnerable groups, that the organization gives particular attention to in the commitment."
                    }
                }
            }
            ESGTextAreaFieldWrapper {
                name = state::section223A2.name
                value = state.section223A2 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"Provide links to the policy commitments if publicly available, or, if the policy commitments are not publicly available, explain the reason for this."
            }
            ESGTextAreaFieldWrapper {
                name = state::section223A3.name
                value = state.section223A3 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"Report the level at which each of the policy commitments was approved within the organization, including whether this is the most senior level."
            }
            ESGTextAreaFieldWrapper {
                name = state::section223A4.name
                value = state.section223A4 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
        Box {
            label {
                +"Report the extent to which the policy commitments apply to the organization’s activities and to its business relationships."
            }
            ESGTextAreaFieldWrapper {
                name = state::section223A5.name
                value = state.section223A5 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
        Box {
            label {
                +"Describe how the policy commitments are communicated to workers, business partners, and other relevant parties."
            }
            ESGTextAreaFieldWrapper {
                name = state::section223Field1.name
                value = state.section223Field1 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}