package com.ecosave.watch.portal.components.utilityaccounts

import com.ecosave.watch.portal.components.common.ConfirmationDialog
import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.helpers.billing.BillingConstants
import com.ecosave.watch.portal.helpers.billing.BillingSteamAnnotations
import com.ecosave.watch.portal.helpers.billing.BillingSteamHeaders
import com.ecosave.watch.portal.helpers.billing.DemandUnit
import com.ecosave.watch.portal.helpers.billing.UsageUnit
import com.ecosave.watch.portal.helpers.billing.UtilityType
import com.ecosave.watch.portal.helpers.billing.clearBillStartAndEndDate
import com.ecosave.watch.portal.helpers.billing.isBillingSteamFormValid
import com.ecosave.watch.portal.helpers.billing.mainScope
import com.ecosave.watch.portal.helpers.billing.validateCoolingSteamUsage
import com.ecosave.watch.portal.helpers.billing.validateDemandBilled
import com.ecosave.watch.portal.helpers.billing.validateDemandMeasured
import com.ecosave.watch.portal.helpers.billing.validateHeatingDemandCharges
import com.ecosave.watch.portal.helpers.billing.validateHeatingSteamUsage
import com.ecosave.watch.portal.helpers.billing.validateHeatingUsageCharges
import com.ecosave.watch.portal.helpers.billing.validateTotalCoolingSteamCharges
import com.ecosave.watch.portal.helpers.billing.validateTotalHeatingSteamCharges
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.models.billing.ElectricBillValidationState
import com.ecosave.watch.portal.models.billing.NaturalGasBillValidationState
import com.ecosave.watch.portal.models.billing.SolarBillValidationState
import com.ecosave.watch.portal.models.billing.SteamBillValidationState
import com.ecosave.watch.portal.models.billing.UtilityBillValidationState
import com.ecosave.watch.portal.models.billing.WaterBillValidationState
import com.ecosave.watch.portal.models.billing.utilitybillstate.ElectricAdditional
import com.ecosave.watch.portal.models.billing.utilitybillstate.NaturalGasAdditional
import com.ecosave.watch.portal.models.billing.utilitybillstate.SolarBillAdditional
import com.ecosave.watch.portal.models.billing.utilitybillstate.SteamBillAdditional
import com.ecosave.watch.portal.models.billing.utilitybillstate.UtilityBillState
import com.ecosave.watch.portal.models.billing.utilitybillstate.WaterBillAdditional
import com.ecosave.watch.portal.services.billing.createBill
import com.ecosave.watch.portal.styles.BillingInputFormStyles
import com.ecosave.watch.portal.styles.CommonStyles
import com.ecosave.watch.portal.useGlobalState
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.StateSetter
import react.dom.html.ReactHTML
import react.dom.onChange
import react.useState
import web.cssom.pct
import web.html.HTMLInputElement
import web.html.InputType

external interface SteamFormProps : Props {
    var setShowInputForm: StateSetter<Boolean>
    var showNotification: (NotificationStatus, String) -> Unit
}

val SteamForm = FC<SteamFormProps> { props ->
    val globalState = useGlobalState()
    val (formValidationState, setFormValidationState) = useState(
        UtilityBillValidationState(
            electricBillValidationState = ElectricBillValidationState(),
            naturalGasBillValidationState = NaturalGasBillValidationState(),
            waterBillValidationState = WaterBillValidationState(),
            steamBillValidationState = SteamBillValidationState(),
            solarBillValidationState = SolarBillValidationState()
        )
    )
    val (formState, setFormState) = useState(
        UtilityBillState(
            utilityAccountId = globalState.utilityAccountData.accountId,
            utilityType = UtilityType.STEAM.name,
            usageUnit = UsageUnit.MLBS.name,
            demandUnit = if (
                (globalState.utilityAccountData.coolingSteamUsed && globalState.utilityAccountData.heatingSteamUsed) ||
                (globalState.utilityAccountData.heatingSteamUsed)
            ) DemandUnit.LBSH.name else "",
            electricAdditional = ElectricAdditional(),
            naturalGasAdditional = NaturalGasAdditional(),
            waterBillAdditional = WaterBillAdditional(),
            steamBillAdditional = SteamBillAdditional(),
            solarBillAdditional = SolarBillAdditional()
        )
    )
    var isSubmitting by useState(false)
    var submitButtonText by useState("Submit")
    val (openDialog, setOpenDialog) = useState(false)

    Box {
        InputFormTitleSector {
            title = "Add Steam Bill"
            subtitle = "Account Number: ${globalState.utilityAccountData.accountNumber}"
        }
        Box {
            className = BillingInputFormStyles.MAIN_FORM.cssClass
            Box {
                className = BillingInputFormStyles.INPUT_WRAPPER.cssClass

                fun billStartDate() {
                    BillStartDate {
                        state = formState
                        setState = setFormState
                        validation = formValidationState
                        setValidation = setFormValidationState
                        isSolarStartDate = false
                        setDialogState = setOpenDialog
                    }
                }

                fun billEndDate() {
                    BillEndDate {
                        state = formState
                        setState = setFormState
                        validation = formValidationState
                        setValidation = setFormValidationState
                        isSolarEndDate = false
                        setDialogState = setOpenDialog
                    }
                }

                fun billEstimated() {
                    AddBillEstimated {
                        state = formState
                        setState = setFormState
                        validation = formValidationState
                        setValidation = setFormValidationState
                    }
                }

                fun billAdjusted() {
                    AddBillAdjusted {
                        state = formState
                        setState = setFormState
                        validation = formValidationState
                        setValidation = setFormValidationState
                    }
                }

                fun usageUnit() {
                    AddUsageUnit {
                        stateUsageUnit = formState
                        setStateUsageUnit = setFormState
                        usageUnits = UtilityType.STEAM.usageUnits
                    }
                }

                fun demandUnit() {
                    AddDemandUnit {
                        stateDemandUnit = formState
                        setStateDemandUnit = setFormState
                        demandUnits = UtilityType.STEAM.demandUnits ?: listOf()
                    }
                }

                fun totalHeatingSteamCharges() {
                    TextFieldWrapper {
                        label = ReactNode(
                            "${BillingSteamHeaders.TOTAL_HEATING_STEAM_CHARGES.header} ($) *"
                        )
                        type = InputType.number
                        value = formState.steamBillAdditional.totalHeatingSteamCharges
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setFormState(
                                formState.copy(
                                    steamBillAdditional = formState.steamBillAdditional.copy(
                                        totalHeatingSteamCharges = target.value.toFloatOrNull()
                                    )
                                )
                            )
                        }
                        error =
                            formValidationState.steamBillValidationState.totalHeatingSteamChargesErrorState
                        helperText =
                            formValidationState.steamBillValidationState.totalHeatingSteamChargesErrorMessage
                        onBlur = {
                            validateTotalHeatingSteamCharges(
                                formState,
                                formValidationState,
                                setFormValidationState
                            )
                        }
                    }
                }

                fun demandMeasured() {
                    TextFieldWrapper {
                        label = ReactNode(BillingSteamHeaders.DEMAND_MEASURED.header)
                        type = InputType.number
                        annotation = BillingSteamAnnotations.DEMAND_MEASURED
                        value = formState.steamBillAdditional.demandMeasured
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setFormState(
                                formState.copy(
                                    steamBillAdditional = formState.steamBillAdditional.copy(
                                        demandMeasured = target.value.toFloatOrNull()
                                    )
                                )
                            )
                        }
                        error = formValidationState.steamBillValidationState.demandMeasuredErrorState
                        helperText =
                            formValidationState.steamBillValidationState.demandMeasuredErrorMessage
                        onBlur = {
                            validateDemandMeasured(
                                formState,
                                formValidationState,
                                setFormValidationState
                            )
                        }
                    }
                }

                fun demandBilled() {
                    TextFieldWrapper {
                        label = ReactNode(BillingSteamHeaders.DEMAND_BILLED.header)
                        type = InputType.number
                        annotation = BillingSteamAnnotations.DEMAND_BILLED
                        value = formState.steamBillAdditional.demandBilled
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setFormState(
                                formState.copy(
                                    steamBillAdditional = formState.steamBillAdditional.copy(
                                        demandBilled = target.value.toFloatOrNull()
                                    )
                                )
                            )
                        }
                        error = formValidationState.steamBillValidationState.demandBilledErrorState
                        helperText =
                            formValidationState.steamBillValidationState.demandBilledErrorMessage
                        onBlur = {
                            validateDemandBilled(
                                formState,
                                formValidationState,
                                setFormValidationState
                            )
                        }
                    }
                }

                fun heatingSteamUsage() {
                    TextFieldWrapper {
                        label = ReactNode(BillingSteamHeaders.HEATING_STEAM_USAGE.header)
                        type = InputType.number
                        annotation = BillingSteamAnnotations.HEATING_STEAM_USAGE
                        value = formState.steamBillAdditional.heatingSteamUsage
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setFormState(
                                formState.copy(
                                    steamBillAdditional = formState.steamBillAdditional.copy(
                                        heatingSteamUsage = target.value.toFloatOrNull()
                                    )
                                )
                            )
                        }
                        error =
                            formValidationState.steamBillValidationState.heatingSteamUsageErrorState
                        helperText =
                            formValidationState.steamBillValidationState.heatingSteamUsageErrorMessage
                        onBlur = {
                            validateHeatingSteamUsage(
                                formState,
                                formValidationState,
                                setFormValidationState
                            )
                        }
                    }
                }

                fun heatingUsageCharges() {
                    TextFieldWrapper {
                        label = ReactNode(
                            "${BillingSteamHeaders.HEATING_USAGE_CHARGES.header} ($) *"
                        )
                        annotation = BillingSteamAnnotations.HEATING_USAGE_CHARGES
                        type = InputType.number
                        value = formState.steamBillAdditional.heatingUsageCharges
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setFormState(
                                formState.copy(
                                    steamBillAdditional = formState.steamBillAdditional.copy(
                                        heatingUsageCharges = target.value.toFloatOrNull()
                                    )
                                )
                            )
                        }
                        error =
                            formValidationState.steamBillValidationState.heatingUsageChargesErrorState
                        helperText =
                            formValidationState.steamBillValidationState.heatingUsageChargesErrorMessage
                        onBlur = {
                            validateHeatingUsageCharges(
                                formState,
                                formValidationState,
                                setFormValidationState
                            )
                        }
                    }
                }

                fun heatingDemandCharges() {
                    TextFieldWrapper {
                        label = ReactNode(
                            "${BillingSteamHeaders.HEATING_DEMAND_CHARGES.header} ($) *"
                        )
                        type = InputType.number
                        annotation = BillingSteamAnnotations.HEATING_DEMAND_CHARGES
                        value = formState.steamBillAdditional.heatingDemandCharges
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setFormState(
                                formState.copy(
                                    steamBillAdditional = formState.steamBillAdditional.copy(
                                        heatingDemandCharges = target.value.toFloatOrNull()
                                    )
                                )
                            )
                        }
                        error =
                            formValidationState.steamBillValidationState.heatingDemandChargesErrorState
                        helperText =
                            formValidationState.steamBillValidationState.heatingDemandChargesErrorMessage
                        onBlur = {
                            validateHeatingDemandCharges(
                                formState,
                                formValidationState,
                                setFormValidationState
                            )
                        }
                    }
                }

                fun coolingSteamUsage() {
                    TextFieldWrapper {
                        label = ReactNode(BillingSteamHeaders.COOLING_STEAM_USAGE.header)
                        type = InputType.number
                        annotation = BillingSteamAnnotations.COOLING_STEAM_USAGE
                        value = formState.steamBillAdditional.coolingSteamUsage
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setFormState(
                                formState.copy(
                                    steamBillAdditional = formState.steamBillAdditional.copy(
                                        coolingSteamUsage = target.value.toFloatOrNull()
                                    )
                                )
                            )
                        }
                        error =
                            formValidationState.steamBillValidationState.coolingSteamUsageErrorState
                        helperText =
                            formValidationState.steamBillValidationState.coolingSteamUsageErrorMessage
                        onBlur = {
                            validateCoolingSteamUsage(
                                formState,
                                formValidationState,
                                setFormValidationState
                            )
                        }
                    }
                }

                fun totalCoolingSteamCharges() {
                    TextFieldWrapper {
                        label = ReactNode(
                            "${BillingSteamHeaders.TOTAL_COOLING_STEAM_CHARGES.header} ($) *"
                        )
                        type = InputType.number
                        annotation = BillingSteamAnnotations.TOTAL_COOLING_STEAM_CHARGES
                        value = formState.steamBillAdditional.totalCoolingSteamCharges
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setFormState(
                                formState.copy(
                                    steamBillAdditional = formState.steamBillAdditional.copy(
                                        totalCoolingSteamCharges = target.value.toFloatOrNull()
                                    )
                                )
                            )
                        }
                        error =
                            formValidationState.steamBillValidationState.totalCoolingSteamChargesErrorState
                        helperText =
                            formValidationState.steamBillValidationState.totalCoolingSteamChargesErrorMessage
                        onBlur = {
                            validateTotalCoolingSteamCharges(
                                formState,
                                formValidationState,
                                setFormValidationState
                            )
                        }
                    }
                }

                if (globalState.utilityAccountData.coolingSteamUsed && globalState.utilityAccountData.heatingSteamUsed) {
                    billStartDate()
                    billEndDate()
                    billEstimated()
                    billAdjusted()
                    usageUnit()
                    demandUnit()
                    repeat(2) {
                        Box {
                            className = BillingInputFormStyles.INPUT_ITEM.cssClass
                        }
                    }
                    Box {
                        className = BillingInputFormStyles.INPUT_ITEM.cssClass
                        sx {
                            minWidth = 100.pct
                        }
                        Typography {
                            component = ReactHTML.h6
                            variant = TypographyVariant.h6
                            +"Heating Steam Bill"
                        }
                    }
                    // heating steam
                    heatingSteamUsage()
                    totalHeatingSteamCharges()
                    demandBilled()
                    demandMeasured()
                    heatingUsageCharges()
                    heatingDemandCharges()
                    repeat(2) {
                        Box {
                            className = BillingInputFormStyles.INPUT_ITEM.cssClass
                        }
                    }
                    Box {
                        className = BillingInputFormStyles.INPUT_ITEM.cssClass
                        sx {
                            minWidth = 100.pct
                        }
                        Typography {
                            component = ReactHTML.h6
                            variant = TypographyVariant.h6
                            +"Cooling Steam Bill"
                        }
                    }
                    // cooling steam
                    coolingSteamUsage()
                    totalCoolingSteamCharges()
                } else if (globalState.utilityAccountData.heatingSteamUsed) {
                    billStartDate()
                    billEndDate()
                    billEstimated()
                    billAdjusted()
                    heatingSteamUsage()
                    usageUnit()
                    totalHeatingSteamCharges()
                    demandBilled()
                    demandMeasured()
                    demandUnit()
                    heatingUsageCharges()
                    heatingDemandCharges()
                } else {
                    billStartDate()
                    billEndDate()
                    billEstimated()
                    billAdjusted()
                    coolingSteamUsage()
                    usageUnit()
                    totalCoolingSteamCharges()
                }

                repeat(2) {
                    Box {
                        className = BillingInputFormStyles.INPUT_ITEM.cssClass
                    }
                }
            }
        }
        Box {
            className = CommonStyles.CENTERED_BUTTON.cssClass
            Button {
                className = CommonStyles.MARGIN_RIGHT_BUTTON.cssClass
                +"Cancel"
                onClick = {
                    props.setShowInputForm(false)
                }
            }
            Button {
                +submitButtonText
                disabled = isSubmitting
                variant = ButtonVariant.contained
                onClick = {
                    if (isBillingSteamFormValid(
                            formState,
                            formValidationState,
                            setFormValidationState,
                            globalState.utilityAccountData.coolingSteamUsed,
                            globalState.utilityAccountData.heatingSteamUsed
                        )
                    ) {
                        mainScope.launch {
                            isSubmitting = true
                            submitButtonText = "Submitting..."
                            val apiCallStatus = createBill(formState)
                            if (apiCallStatus == ApiCallStatus.SUCCESS) {
                                props.showNotification(
                                    NotificationStatus.SUCCESS,
                                    BillingConstants.BILL_SUBMITTED_NOTIFICATION_MESSAGE
                                )
                                props.setShowInputForm(false)
                            } else {
                                props.showNotification(NotificationStatus.ERROR, Constants.NOTIFICATION_ERROR_MESSAGE)
                            }
                            submitButtonText = "Submit"
                            isSubmitting = false
                        }
                    }
                }
            }
        }
    }
    ConfirmationDialog {
        open = openDialog
        title = "Confirmation"
        body = BillingConstants.BILL_START_END_DATE_DIFF_DIALOG_MESSAGE
        actionResult = { value ->
            setOpenDialog(false)
            if (!value) {
                clearBillStartAndEndDate(formState, setFormState)
            }
        }
    }
}
