package com.ecosave.watch.portal.components.esg.generaldisclosures.organizationanditsreportingpractices

import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import mui.system.sx
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange
import web.cssom.px


val Disclosure_2_4 = FC<EsgSubFormComponentProps> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.theOrgAndItsRepPractices?.let { state ->

        val restatementsListName = state::restatementOfInformationItems.name

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_2_4
        }

        Box {
            className = ESGCommonStyles.STICKY_HEADER.cssClass
            DynamicTableWrapper {
                addRowOrSection = {
                    props.addRowOrSection(restatementsListName)
                }
                items = state.restatementOfInformationItems
                action = DynamicTableAction.ADD
                variant = DynamicTableVariant.TABLE
                addRowOrSectionBtnText = "Add a new restatement"
                Box {
                    sx {
                        padding = 20.px
                    }
                    DisclosureSubHeading {
                        subHeading = "Report restatements of information made from previous reporting periods and explain."
                    }
                }
            }
        }

        state.restatementOfInformationItems.forEachIndexed { index, it ->
            DynamicTableWrapper {
                key = it.key
                deleteRowOrSection = {
                    props.deleteRowOrSection(restatementsListName, index)
                }
                items = state.restatementOfInformationItems
                action = DynamicTableAction.DELETE
                Box {
                    className = ESGCommonStyles.DYNAMIC_TABLE_SECTION_LAYOUT.cssClass
                    DisclosureSubHeading {
                        subHeading = "Restatement ${index + 1}"
                    }
                    Box {
                        label {
                            +"Restatement"
                        }
                        ESGTextAreaFieldWrapper {
                            name = it::section24FieldA1.name
                            value = it.section24FieldA1
                            onChange = { event -> onChangeTextArea(event, index, restatementsListName) }
                            onBlur = { event -> onBlurTextArea(event, index, restatementsListName) }
                        }
                    }
                    Box {
                        label {
                            +"Reasoning for Restatement"
                        }
                        ESGTextAreaFieldWrapper {
                            name = it::section24FieldA2.name
                            value = it.section24FieldA2
                            onChange = { event -> onChangeTextArea(event, index, restatementsListName) }
                            onBlur = { event -> onBlurTextArea(event, index, restatementsListName) }
                        }
                    }
                    Box {
                        label {
                            +"Effect of Restatement"
                        }
                        ESGTextAreaFieldWrapper {
                            name = it::section24FieldA3.name
                            value = it.section24FieldA3
                            onChange = { event -> onChangeTextArea(event, index, restatementsListName) }
                            onBlur = { event -> onBlurTextArea(event, index, restatementsListName) }
                        }
                    }
                }
            }
        }
    }
}