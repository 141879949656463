package com.ecosave.watch.portal.components.esg.generaldisclosures.strategypoliciesandpractices

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val Disclosure_2_25 = FC<EsgSubFormComponentProps> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.strategyPoliciesPractices?.let { state ->

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_2_25
        }

        Box {
            label {
                +"Describe its commitments to provide for or cooperate in the remediation of negative impacts that the organization identifies it has caused or contributed to."
            }
            ESGTextAreaFieldWrapper {
                name = state::section225A1.name
                value = state.section225A1 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"Describe its approach to identify and address grievances, including the grievance mechanisms that the organization has established or participates in."
            }
            ESGTextAreaFieldWrapper {
                name = state::section225A2.name
                value = state.section225A2 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"Describe other processes by which the organization provides for or cooperates in the remediation of negative impacts that it identifies it has caused or contributed to."
            }
            ESGTextAreaFieldWrapper {
                name = state::section225A3.name
                value = state.section225A3 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"Describe how the stakeholders who are the intended users of the grievance mechanisms are involved in the design, review, operation, and improvement of these mechanisms."
            }
            ESGTextAreaFieldWrapper {
                name = state::section225A4.name
                value = state.section225A4 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"Describe how the organization tracks the effectiveness of the grievance mechanisms and other remediation processes, and report examples of their effectiveness, including stakeholder feedback."
            }
            ESGTextAreaFieldWrapper {
                name = state::section225A5.name
                value = state.section225A5 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}