package com.ecosave.watch.portal.helpers.billing

import com.ecosave.watch.portal.helpers.common.AutoCompleteOption
import com.ecosave.watch.portal.models.common.TableCell

object BillingConstants {
    const val MAX_COST = 10000000
    const val MAX_VALUE = 1000000000
    const val MAX_COST_DIGITS = 7
    const val MAX_VALUE_DIGITS = 9
    const val BILL_START_AND_END_DATE_DAYS_DIFF = 40

    const val ENERGY_STAR = "Energy Star®"

    // other utility service provider id should be same in database
    const val OTHER_UTILITY_SERVICE_PROVIDER_ID = 0
    const val INVALID_COST_ERROR_MESSAGE = "Value should be less than 8 digits"
    const val INVALID_VALUE_ERROR_MESSAGE = "Value should be less than 10 digits"
    const val EDIT_BILL_SUCCESS_NOTIFICATION_MESSAGE = "Bill Updated Successfully"
    const val DELETE_BILL_SUCCESS_NOTIFICATION_MESSAGE = "Bill Deleted Successfully"
    const val BILL_SUBMITTED_NOTIFICATION_MESSAGE = "Bill Submitted Successfully"
    const val BILL_START_END_DATE_DIFF_DIALOG_MESSAGE =
        "The difference between bill start date and bill end date is more than 40 days, are you sure this is the correct bill period?"
    const val SOLAR_START_END_DATE_DIFF_DIALOG_MESSAGE =
        "The difference between start date and end date is more than 40 days, are you sure this is the correct period?"

    var solarCapacityUnits = listOf(
        DemandUnit.KW,
        DemandUnit.MW
    )

    val utilityAccountSummaryHeadersList = listOf(
        UtilityAccountSummaryHeaders.FACILITY_NAME.header,
        UtilityAccountSummaryHeaders.FACILITY_ADDRESS.header,
        UtilityAccountSummaryHeaders.FACILITY_SIZE.header,
        UtilityAccountSummaryHeaders.ENERGY_STAR_PORTFOLIO_MANAGER.header
    )

    val utilityAccountTableHeadersList = listOf(
        UtilityAccountTableHeaders.UTILITY_TYPE.header,
        UtilityAccountTableHeaders.UTILITY_PROVIDER.header,
        UtilityAccountTableHeaders.ACCOUNT_NUMBER.header,
        UtilityAccountTableHeaders.SERVICE_ADDRESS.header,
        UtilityAccountTableHeaders.COLLECTION_METHOD.header,
        UtilityAccountTableHeaders.COLLECTION_STATUS.header,
        UtilityAccountTableHeaders.LATEST_BILL_MONTH.header,
        UtilityAccountTableHeaders.ACCOUNT_STATUS.header,
        "Edit Account",
        "Manage Bills"
    )

    val utilityDataCollectionMethodLists = listOf(
        UtilityDataCollectionMethod.ECOSAVE_WATCH,
        UtilityDataCollectionMethod.MANUAL_UTILITY_DATA_ENTRY
    )

    val utilityTypeList = listOf(
        UtilityType.ELECTRIC,
        UtilityType.NATURAL_GAS,
        UtilityType.WATER,
        UtilityType.STEAM,
        UtilityType.HOT_WATER,
        UtilityType.CHILLED_WATER,
        UtilityType.SOLAR
    )

    val energyUtilityTypeList = listOf(
        UtilityType.NATURAL_GAS,
        UtilityType.ELECTRIC,
        UtilityType.STEAM,
        UtilityType.HOT_WATER,
        UtilityType.CHILLED_WATER
    )

    val utilityBillSummaryTableHeaders = listOf(
        TableCell("utilityType", BillingHeaders.UTILITY_TYPE.header),
        TableCell("serviceName", BillingHeaders.SERVICE_NAME.header),
        TableCell("accountNumber", BillingHeaders.ACCOUNT_NUMBER.header),
        TableCell("billStartDate", BillingHeaders.BILL_START_DATE.header),
        TableCell("billEndDate", BillingHeaders.BILL_END_DATE.header),
        TableCell("totalUsage", BillingHeaders.TOTAL_USAGE.header),
        TableCell("totalBillCost", BillingHeaders.TOTAL_BILL_COST.header),
    )

    val utilityTypeOptions = arrayOf(
        AutoCompleteOption(UtilityType.ELECTRIC.type, UtilityType.ELECTRIC.name),
        AutoCompleteOption(UtilityType.NATURAL_GAS.type, UtilityType.NATURAL_GAS.name),
        AutoCompleteOption(UtilityType.WATER.type, UtilityType.WATER.name),
        AutoCompleteOption(UtilityType.STEAM.type, UtilityType.STEAM.name),
        AutoCompleteOption(UtilityType.HOT_WATER.type, UtilityType.HOT_WATER.name),
        AutoCompleteOption(UtilityType.CHILLED_WATER.type, UtilityType.CHILLED_WATER.name),
        AutoCompleteOption(UtilityType.SOLAR.type, UtilityType.SOLAR.name)
    )
}