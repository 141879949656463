package com.ecosave.watch.portal.components.privacypolicy

import react.FC
import react.Props
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.ul

val RepresentationsAndIndemnities = FC<Props> {
    HeadingWrapper {
        heading = "17. Representations and Indemnities"
    }
    TypographyWrapper {
        +"This section states Ecosave's entire liability and your exclusive remedies for intellectual property rights infringement. You hereby represent and warrant to Ecosave that you have the authority to enter and perform this Agreement, and that performance of the Agreement's obligations and exercise of its rights do not and will not violate any applicable laws, regulations, or orders."
    }
    TypographyWrapper {
        +"You hereby represent, warrant, and covenant that:"
    }
    TypographyWrapper {
        ul {
            li {
                +"You or your licensors owns all right, title, and interest in and to Customer Data."
            }
            li {
                +"You have all rights to Customer Data necessary to grant the rights contemplated by this Agreement."
            }
            li {
                +"You have obtained any necessary third-party approvals, including without limitations applicable vendors and licensors, in relation to third-party content to be used by you in connection with the Service or will obtain such approvals prior to such use."
            }
        }
    }
    TypographyWrapper {
        +"Ecosave hereby represents, warrants, and covenants to you, if under a Paying Plan, that:"
    }
    TypographyWrapper {
        ul {
            li {
                +"Ecosave has all rights in the Service and Technology necessary to grant the rights contemplated by this Agreement."
            }
        }
    }
    TypographyWrapper {
        +"You agree to defend, indemnify, and hold harmless Ecosave and its employees, contractors, agents, officers, and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs, or debt and expenses (including without limitation attorneys' fees) arising out of or related to:"
    }
    TypographyWrapper {
        ul {
            li {
                +"Your or Authorized Users' use of and access to the Service."
            }
            li {
                +"Your or an Authorized User's violation of any term of this Agreement."
            }
            li {
                +"Your or an Authorized User's violation of any third-party right, including without limitation any right of privacy, publicity rights, or intellectual property rights."
            }
            li {
                +"Your or an Authorized User's violation of any law, rule, or regulation."
            }
            li {
                +"Any claim or damages that arise as a result of any Customer Data."
            }
            li {
                +"Any other party's access and use of the Service with provided identifier(s) and password(s)."
            }
        }
    }
    TypographyWrapper {
        +"Ecosave agrees to defend, indemnify, and hold harmless you, if under a Paying Plan, from and against any and all claims, damages, obligations, losses, liabilities, costs, or debt and expenses (including without limitation attorneys' fees) arising out of or related to:"
    }
    TypographyWrapper {
        ul {
            li {
                +"Ecosave's breach of any representation, warranty, or obligation in this Agreement"
            }
            li {
                +"Ecosave's violation of any law, rule, or regulation."
            }
        }
    }
    TypographyWrapper {
        +"In addition, if the Service or Technology becomes the subject of a claim of direct infringement of a U.S. copyright or patent, Ecosave will indemnify you, if under a Paying Plan, against such claim provided that you give Ecosave prompt written notice of the claim, allow Ecosave to direct the defense and settlement of the claim, and cooperate with Ecosave as necessary, at Ecosave's expense, for defense and settlement of the claim. If the Service or Technology become, or, in Ecosave's opinion is likely to become, the subject of such a claim, Ecosave will have the right to obtain for you the right to continue using the Service or Technology, replace or modify the Service or Technology so that it becomes non-infringing, or terminate the rights granted here to such Service or Technology with refund to you of any fees paid for such Service and Technology (less a reasonable charge for the period during which you has had available to it the use of such Service and Technology)."
    }
    TypographyWrapper {
        +"Ecosave will have no liability for any infringement claim to the extent it:"
    }
    TypographyWrapper {
        ul {
            li {
                +"Is based on modification of the Service or Technology other than by Ecosave."
            }
            li {
                +"Results from failure of you to use any updated version of Service or Technology provided by Ecosave to you."
            }
            li {
                +"Is based on the combination or use of the Service or Technology with any other software, program, or device not provided by Ecosave if such infringement would not have arisen but for such use or combination."
            }
            li {
                +"Results from compliance by Ecosave with designs, plans, or specifications furnished by you."
            }
            li {
                +"Results from your operation of the Service or Technology in a manner that is inconsistent with its intended use."
            }
        }
    }
    TypographyWrapper {
        +"Except as expressly provided in this agreement, the services, technology, support, and all other items provided in connection therewith are provided on an \"as is\" basis without warranties of any kind, either express or implied. Ecosave disclaims all warranties, express or implied, arising by law or otherwise, with respect to any error, defect, deficiency, infringement, or noncompliance in the services, technology, support, or any other items provided by, through, or on behalf of Ecosave under this agreement (including without limitation any implied warranty of merchantability, fitness for a particular purpose, or non-infringement and any implied warranty arising from course of performance, course of dealing, or usage of trade)."
    }
}
