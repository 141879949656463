package com.ecosave.watch.portal.helpers.navigation

import com.ecosave.watch.portal.helpers.common.PageTitles
import mui.icons.material.AssessmentOutlined
import mui.icons.material.Bolt
import mui.icons.material.PendingActionsOutlined
import mui.icons.material.SummarizeOutlined
import mui.icons.material.SvgIconComponent

enum class ReportsLinksNew(val linkTo: String, val navIcon: SvgIconComponent, val navText: String) {
    ESG_FORM("/summary", AssessmentOutlined, PageTitles.ESG_REPORTING.title)
}

enum class UtilityLinks(val linkTo: String, val navIcon: SvgIconComponent, val navText: String) {
    UTILITY_ACCOUNTS("/utility-accounts", SummarizeOutlined, PageTitles.UTILITY_ACCOUNTS.title),
    UTILITY_BILLS("/utility-bills", SummarizeOutlined, PageTitles.UTILITY_BILLS.title)
}

enum class WorkOrderLinks(val linkTo: String, val navIcon: SvgIconComponent, val navText: String) {
    OBSERVATION_SUMMARY("/observations", PendingActionsOutlined, PageTitles.OBSERVATION_SUMMARY.title),
    ACTION_MANAGEMENT("/action-management", PendingActionsOutlined, PageTitles.ACTION_MANAGEMENT.title)
}

enum class EnergyManagementLinks(val linkTo: String, val navIcon: SvgIconComponent, val navText: String) {
    FACILITY_OVERVIEW("/facility-overview", Bolt, "Facility Overview"),
    PORTFOLIO_OVERVIEW("/portfolio-overview", Bolt, "Portfolio Overview")
}