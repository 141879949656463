package com.ecosave.watch.portal.components.esg.energy

import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val GRI_302_1 = FC<EsgSubFormComponentProps> { props ->
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea
    val onChangeText = props.onChangeText
    val onBlurText = props.onBlurText
    val onChangeDecimalNumber = props.onChangeDecimalNumber
    val onBlurDecimalNumber = props.onBlurDecimalNumber

    props.esgReportState.report?.energy?.let { state ->
        val renewableFuelTypeListName = state::renewableFuelTypeList.name
        val nonRenewableFuelTypeListName = state::nonRenewableFuelTypeList.name

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_302_1
        }
        DisclosureSubHeading {
            subHeading = "If the organization generates electricity from a nonrenewable or renewable fuel source and then consumes the generated " +
                    "electricity, the energy consumption shall be counted once under fuel consumption."
        }

        Box {
            className = ESGCommonStyles.TABLE_WRAPPER.cssClass
            DynamicTableWrapper {
                addRowOrSection = { props.addRowOrSection(renewableFuelTypeListName) }
                items = state.renewableFuelTypeList
                action = DynamicTableAction.ADD
                addRowOrSectionBtnText = ReportConstants.ADD_ROW_MESSAGE
                Box {
                    className = ESGCommonStyles.THREE_COLUMN_TABLE_8_2_2.cssClass
                    TableHeader {
                        header = "Consumption by fuel type (renewable)"
                    }
                    TableHeader {
                        header = "Unit"
                    }
                    TableHeader {
                        header = "Value"
                    }
                }
            }
            state.renewableFuelTypeList.forEachIndexed { index, it ->
                DynamicTableWrapper {
                    key = it.key
                    deleteRowOrSection = { props.deleteRowOrSection(renewableFuelTypeListName, index) }
                    items = state.renewableFuelTypeList
                    action = DynamicTableAction.DELETE
                    variant = DynamicTableVariant.TABLE
                    Box {
                        className = ESGCommonStyles.THREE_COLUMN_TABLE_8_2_2.cssClass
                        ESGTextFieldWrapper {
                            name = it::section3021RenewableFuelType.name
                            value = it.section3021RenewableFuelType
                            onChange = { event -> onChangeText(event, index, renewableFuelTypeListName) }
                            onBlur = { event -> onBlurText(event, index, renewableFuelTypeListName) }
                        }
                        ESGTextFieldWrapper {
                            name = it::section3021RenewableUnit.name
                            value = it.section3021RenewableUnit
                            onChange = { event -> onChangeText(event, index, renewableFuelTypeListName) }
                            onBlur = { event -> onBlurText(event, index, renewableFuelTypeListName) }
                        }
                        ESGNumberFieldWrapper {
                            name = it::section3021RenewableCurrent.name
                            value = it.section3021RenewableCurrent
                            onChange = { event -> onChangeDecimalNumber(event, index, renewableFuelTypeListName) }
                            onBlur = { event -> onBlurDecimalNumber(event, index, renewableFuelTypeListName) }
                        }
                    }
                }
            }
        }

        Box {
            className = ESGCommonStyles.TABLE_WRAPPER.cssClass
            DynamicTableWrapper {
                addRowOrSection = { props.addRowOrSection(nonRenewableFuelTypeListName) }
                items = state.renewableFuelTypeList
                action = DynamicTableAction.ADD
                addRowOrSectionBtnText = ReportConstants.ADD_ROW_MESSAGE
                Box {
                    className = ESGCommonStyles.THREE_COLUMN_TABLE_8_2_2.cssClass
                    TableHeader {
                        header = "Consumption by fuel type (non-renewable)"
                    }
                    TableHeader {
                        header = "Unit"
                    }
                    TableHeader {
                        header = "Value"
                    }
                }
            }
            state.nonRenewableFuelTypeList.forEachIndexed { index, it ->
                DynamicTableWrapper {
                    key = it.key
                    deleteRowOrSection = { props.deleteRowOrSection(nonRenewableFuelTypeListName, index) }
                    items = state.renewableFuelTypeList
                    action = DynamicTableAction.DELETE
                    variant = DynamicTableVariant.TABLE
                    Box {
                        className = ESGCommonStyles.THREE_COLUMN_TABLE_8_2_2.cssClass
                        ESGTextFieldWrapper {
                            name = it::section3021NonRenewableFuelType.name
                            value = it.section3021NonRenewableFuelType
                            onChange = { event -> onChangeText(event, index, nonRenewableFuelTypeListName) }
                            onBlur = { event -> onBlurText(event, index, nonRenewableFuelTypeListName) }
                        }
                        ESGTextFieldWrapper {
                            name = it::section3021NonRenewableUnit.name
                            value = it.section3021NonRenewableUnit
                            onChange = { event -> onChangeText(event, index, nonRenewableFuelTypeListName) }
                            onBlur = { event -> onBlurText(event, index, nonRenewableFuelTypeListName) }
                        }
                        ESGNumberFieldWrapper {
                            name = it::section3021NonRenewableCurrent.name
                            value = it.section3021NonRenewableCurrent
                            onChange = { event -> onChangeDecimalNumber(event, index, nonRenewableFuelTypeListName) }
                            onBlur = { event -> onBlurDecimalNumber(event, index, nonRenewableFuelTypeListName) }
                        }
                    }
                }
            }
        }

        DisclosureSubHeading {
            subHeading = "Energy Consumed"
        }
        Box {
            className = ESGCommonStyles.THREE_COLUMN_TABLE_8_2_2.cssClass

            TableHeader {
                header = "Renewable energy categories/activities"
            }
            TableHeader {
                header = "Unit"
            }
            TableHeader {
                header = "Value"
            }

            label {
                +"Electricity"
            }
            ESGTextFieldWrapper {
                name = state::section3021ConsumedUnit1.name
                value = state.section3021ConsumedUnit1
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section3021ConsumedCY1.name
                value = state.section3021ConsumedCY1
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Heating"
            }
            ESGTextFieldWrapper {
                name = state::section3021ConsumedUnit2.name
                value = state.section3021ConsumedUnit2
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section3021ConsumedCY2.name
                value = state.section3021ConsumedCY2
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Cooling"
            }
            ESGTextFieldWrapper {
                name = state::section3021ConsumedUnit3.name
                value = state.section3021ConsumedUnit3
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section3021ConsumedCY3.name
                value = state.section3021ConsumedCY3
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Steam"
            }
            ESGTextFieldWrapper {
                name = state::section3021ConsumedUnit4.name
                value = state.section3021ConsumedUnit4
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section3021ConsumedCY4.name
                value = state.section3021ConsumedCY4
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }
        }

        DisclosureSubHeading {
            subHeading = "Energy Sold"
        }
        Box {
            className = ESGCommonStyles.THREE_COLUMN_TABLE_8_2_2.cssClass

            TableHeader {
                header = "Renewable energy categories/activities"
            }
            TableHeader {
                header = "Unit"
            }
            TableHeader {
                header = "Value"
            }

            label {
                +"Electricity"
            }
            ESGTextFieldWrapper {
                name = state::section3021SoldUnit1.name
                value = state.section3021SoldUnit1
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section3021SoldCY1.name
                value = state.section3021SoldCY1
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Heating"
            }
            ESGTextFieldWrapper {
                name = state::section3021SoldUnit2.name
                value = state.section3021SoldUnit2
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section3021SoldCY2.name
                value = state.section3021SoldCY2
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Cooling"
            }
            ESGTextFieldWrapper {
                name = state::section3021SoldUnit3.name
                value = state.section3021SoldUnit3
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section3021SoldCY3.name
                value = state.section3021SoldCY3
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Steam"
            }
            ESGTextFieldWrapper {
                name = state::section3021SoldUnit4.name
                value = state.section3021SoldUnit4
                onChange = { event -> onChangeText(event, null, null) }
                onBlur = { event -> onBlurText(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section3021SoldCY4.name
                value = state.section3021SoldCY4
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }
        }

        Box {
            label {
                +"Total energy consumption within the organization"
            }
            ESGNumberFieldWrapper {
                name = state::section3021Field3.name
                value = state.section3021Field3
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }
        }

        Box {
            label {
                +"What is the source of the conversion factors used?"
            }
            ESGTextAreaFieldWrapper {
                name = state::section3021Field2.name
                value = state.section3021Field2
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"Standards, methodologies, assumptions, and/or calculation tools used."
            }
            ESGTextAreaFieldWrapper {
                name = state::section3021Field1.name
                value = state.section3021Field1
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}