package com.ecosave.watch.portal.components.esg.tax

import com.ecosave.watch.portal.helpers.esg.EsgSection
import com.ecosave.watch.portal.helpers.esg.TaxField
import com.ecosave.watch.portal.models.esg.CurrentInputState
import com.ecosave.watch.portal.models.esg.EsgReportState
import com.ecosave.watch.portal.models.esg.TaxState
import kotlinx.serialization.json.JsonPrimitive
import mui.material.Box
import react.FC
import react.Props
import react.StateSetter
import react.dom.events.FormEvent
import web.html.HTMLDivElement
import web.html.HTMLTextAreaElement
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles as styles


external interface TaxComponentProps : Props {
    var esgReportStateProps: EsgReportState
    var esgReportStateSetterProps: StateSetter<EsgReportState>
    var setInputState: StateSetter<CurrentInputState>
}


val TaxComponent = FC<TaxComponentProps> { props ->

    val esgState = props.esgReportStateProps
    val esgStateSetter = props.esgReportStateSetterProps

    esgState.report?.tax?.let { state ->
        fun stateSetter(state: TaxState) {
            esgStateSetter(
                esgState.copy(
                    report = esgState.report.copy(
                        tax = state
                    )
                )
            )
        }

        val onChangeFunction: (FormEvent<HTMLDivElement>) -> Unit = { event ->
            when (event.target) {
                is HTMLTextAreaElement -> {
                    val target = event.target as HTMLTextAreaElement
                    val propertyName = TaxField.valueOf(target.name)
                    val propertyValue = target.value
                    stateSetter(
                        state.copy(
                            section2071A1 = if (propertyName == TaxField.SECTION_207_1_A1) propertyValue else state.section2071A1,
                            section2071A2 = if (propertyName == TaxField.SECTION_207_1_A2) propertyValue else state.section2071A2,
                            section2071A3 = if (propertyName == TaxField.SECTION_207_1_A3) propertyValue else state.section2071A3,
                            section2071A4 = if (propertyName == TaxField.SECTION_207_1_A4) propertyValue else state.section2071A4,
                            section2071A5 = if (propertyName == TaxField.SECTION_207_1_A5) propertyValue else state.section2071A5,
                            section2071A6 = if (propertyName == TaxField.SECTION_207_1_A6) propertyValue else state.section2071A6,

                            section2072A1 = if (propertyName == TaxField.SECTION_207_2_A1) propertyValue else state.section2072A1,
                            section2072A2 = if (propertyName == TaxField.SECTION_207_2_A2) propertyValue else state.section2072A2,
                            section2072A3 = if (propertyName == TaxField.SECTION_207_2_A3) propertyValue else state.section2072A3,
                            section2072A4 = if (propertyName == TaxField.SECTION_207_2_A4) propertyValue else state.section2072A4,
                            section2072A5 = if (propertyName == TaxField.SECTION_207_2_A5) propertyValue else state.section2072A5,
                            section2072A6 = if (propertyName == TaxField.SECTION_207_2_A6) propertyValue else state.section2072A6,

                            section2073A1 = if (propertyName == TaxField.SECTION_207_3_A1) propertyValue else state.section2073A1,
                            section2073A2 = if (propertyName == TaxField.SECTION_207_3_A2) propertyValue else state.section2073A2,
                            section2073A3 = if (propertyName == TaxField.SECTION_207_3_A3) propertyValue else state.section2073A3,
                        )
                    )
                    props.setInputState(
                        CurrentInputState(
                            childPath = propertyName.fieldName,
                            value = JsonPrimitive(propertyValue.trim()),
                            objectName = EsgSection.TAX.name,
                            reportName = esgState.reportFileName
                        )
                    )
                }
            }
        }

        Box {
            className = styles.SECTION_MAIN_LAYOUT.cssClass
            Section207_1 {
                this.state = state
                onChange = onChangeFunction
            }
            Section207_2 {
                this.state = state
                onChange = onChangeFunction
            }
            Section207_3 {
                this.state = state
                onChange = onChangeFunction
            }

//            TODO: Section 207-4 is not implement - repeating/dynamic structure
        }
    }
}

