package com.ecosave.watch.portal.models.assetmanagement

import js.core.jso

val assetColumns:Array<dynamic>  = arrayOf(
    jso {
        id = 1
        accessorKey = "facilityName"
        header = "Facility Name"
        size = 300
    },
    jso {
        id = 2
        accessorKey = "equipmentId"
        header = "Equipment ID"
        size = 250
    } ,
    jso {
        id = 3
        accessorKey = "equipmentType"
        header = "Equipment Type"
        size = 300
    },
    jso {
        id = 4
        accessorKey = "systemType"
        header = "System Type"
        size = 250
    },
    jso {
        id = 5
        accessorKey = "equipmentLocation"
        header = "Equipment Location"
        size = 350
    },
    jso {
        id = 6
        accessorKey = "quantity"
        header = "Quantity"
        size = 250
    },
    jso {
        id = 7
        accessorKey = "serves"
        header = "Serves"
        size = 300
    },
    jso {
        id = 8
        accessorKey = "ownership"
        header = "Ownership"
        size = 300
    },
    jso {
        id = 9
        accessorKey = "manufacturer"
        header = "Manufacturer"
        size = 300
    },
    jso {
        id = 10
        accessorKey = "equipmentMaintenanceProvider"
        header = " Equipment Maintenance Provider"
        size = 350
    },
    jso {
        id = 12
        accessorKey = "modelNumber"
        header = "Make/Model #"
        size = 300
    },
    jso {
        id = 13
        accessorKey = "serialNumber"
        header = "Serial #"
        size = 300
    },
    jso {
        id = 14
        accessorKey = "yearInstalled"
        header = "Installed On"
        size = 300
    },
    jso {
        id = 15
        accessorKey = "warrantyEndDate"
        header = "Warranty End Date"
        size = 350
    },
    jso {
        id = 16
        accessorKey = "oMManual"
        header = "O&M Manual"
        size = 300
    },
    jso {
        id = 17
        accessorKey = "comments"
        header = "Comments"
        size = 300
    }
)
