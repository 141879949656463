package com.ecosave.watch.portal.components.resourcecenter.energymanagement

val defaultSentences = listOf(
    "This section offers a comprehensive overview of energy consumption and associated costs for the specified facility.",
    "By default, it presents data from the most recent 12 months of utility bills.",
    "The control options enable users to customize the displayed range and facilitate comparative analysis.",
)
val selectionOfBaselineSentences = listOf(
    "Select a View (Energy or Cost)",
    "Select a period by dragging the square (located on the bottom left corner)",
    "Click Update Baseline",
)
val selectPortfolioSentences = listOf(
    "This section shows aggregated data for all the facilities",
    "A user can use the toggle to view data as Energy, CO2, or $",
    "Utilize Comparison Controls to compare data between time periods.",
    "The Energy Ranking section displays the top 5 most energy consuming facilities.",
    "Click on Read More to display additional data.",

    )
val facilityGroupSentences = listOf(
    "To Select a Facility Group",
    "On the Energy Management Page, click on the SELECT GROUP button",
    "Under FacilityGroups, Select a Group Category or Subcategory",
    "Click Manage Groups to be Redirected to Facility Group Management Page",
)