package com.ecosave.watch.portal.services.energymanagement

import com.ecosave.watch.portal.helpers.BackendServerURL
import com.ecosave.watch.portal.helpers.common.getAccessTokenFromLocalStorage
import com.ecosave.watch.portal.models.assetmanagement.FacilityClass
import com.ecosave.watch.portal.models.assetmanagement.ResponseAndFacilityClass
import com.ecosave.watch.portal.services.httpClient
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import js.core.jso
import js.promise.await
import kotlin.js.json
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.await
import kotlinx.serialization.Serializable
import org.w3c.dom.HTMLAnchorElement
import org.w3c.fetch.RequestInit
import web.buffer.Blob
import web.buffer.BlobPropertyBag
import web.file.File
import web.file.FileList
import web.http.FormData

suspend fun getFacilityImage(facilityId: Int): FacilityClass {
    try {
        val response: HttpResponse = httpClient.get("$BackendServerURL/facility?facilityId=$facilityId")
        if (response.status == HttpStatusCode.OK) {
            return response.body()
        }
    } catch (e: dynamic) {
        console.log(e)
    }
    return FacilityClass()
}

suspend fun deleteFacilityImage(facilityId: Int): ResponseAndFacilityClass {
    try {
        val response: HttpResponse = httpClient.post("$BackendServerURL/facility/delete-image?facilityId=$facilityId") {
        }
        return if (response.status == HttpStatusCode.OK) {
            ResponseAndFacilityClass(
                facilityClass = FacilityClass(imageUrl = null),
                response = true
            )
        } else {
            ResponseAndFacilityClass(
                response = false
            )
        }
    } catch (e: dynamic) {
        console.log(e)
    }
    return ResponseAndFacilityClass()
}

suspend fun uploadFacilityImage(facilityId: Int, fileList: FileList?): FacilityClass {
    try {
        val file = fileList?.get(0)
        file?.let {
            val response = uploadImage("/facility/upload-image", Pair("facilityId", facilityId.toString()), it, "POST")
            if (response.response) {
                return FacilityClass(imageUrl = response.url)
            }
        }
    } catch (e: dynamic) {
        console.log(e)
    }
    return FacilityClass()
}

suspend fun uploadImage(path: String, info: Pair<String, String>, file: File, method:String): ResponseAndImage {
    try {
        val accessToken = getAccessTokenFromLocalStorage()
        val formData = FormData()
        var imageUrl = ""
        formData.append(info.first, info.second)
        formData.append("imageType", convertToEnum(file.type))
        val buffer = file.arrayBuffer().await()
        val options: BlobPropertyBag = jso {
            type = file.type
        }
        val blob = Blob(arrayOf(buffer), options)
        formData.append("uploadDocument", blob, file.name);

        val response = window.fetch("$BackendServerURL/${path}",
            RequestInit(
                method,
                headers = json(
                    "authorization" to "Bearer $accessToken",
                ),
                body = formData
            )
        ).await()

        response.json().then { data ->
            val jsonString = JSON.stringify(data).removeRange(0,13).dropLast(2)
            imageUrl = jsonString
        }.await()

        return ResponseAndImage(
            url = imageUrl,
            response = response.ok
        )
    } catch (error: dynamic) {
        console.log(error)
        return ResponseAndImage()
    }
    return ResponseAndImage()
}

fun downloadFacilityImage(facility: FacilityClass) {
    facility.imageUrl?.let { url ->
        val a = document.createElement("a") as HTMLAnchorElement
        a.href = url
        a.download = "${facility.facilityName}_image"
        a.click()
    }
}

@Serializable
data class ResponseAndImage(
    var response: Boolean = false,
    var url: String? = null
)

fun convertToEnum(fileType: String): String {
    return when (fileType) {
        "image/png" -> "PNG"
        "image/jpeg" -> "JPEG"
        "image/jpg" -> "JPG"
        "image/BMP" -> "BMP"
        "image/WEBP" -> "WEBP"
        else -> throw AssertionError()
    }
}