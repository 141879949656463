package com.ecosave.watch.portal.helpers.common

import mui.material.GridProps

inline var GridProps.xs: Int
    get() = TODO("Prop is write-only!")
    set(value) {
        asDynamic().xs = value
    }

inline var GridProps.sm: Int
    get() = TODO("Prop is write-only!")
    set(value) {
        asDynamic().sm = value
    }

inline var GridProps.md: Int
    get() = TODO("Prop is write-only!")
    set(value) {
        asDynamic().md = value
    }

inline var GridProps.lg: Int
    get() = TODO("Prop is write-only!")
    set(value) {
        asDynamic().lg = value
    }