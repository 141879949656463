package com.ecosave.watch.portal.components.common

import com.ecosave.watch.portal.components.npm.CalendarPicker
import com.ecosave.watch.portal.components.npm.CalendarPickerProps
import js.core.jso
import moment.Moment
import moment.moment
import mui.icons.material.CalendarMonthOutlined
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.IconButton
import mui.material.InputAdornment
import mui.material.InputAdornmentPosition
import mui.material.InputBaseProps
import mui.material.Popover
import mui.material.TextField
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import muix.pickers.CalendarPickerView
import react.FC
import react.Props
import react.create
import react.dom.aria.ariaDescribedBy
import react.dom.aria.ariaReadOnly
import react.dom.html.ReactHTML
import react.useState
import web.cssom.Display
import web.cssom.JustifyContent
import web.cssom.px
import web.html.HTMLButtonElement

external interface MonthsRangePickerProps : Props {
    var id: String
    var value: String?
    var submit: (start: String, end: String) -> Unit
}

val MonthsRangePicker = FC<MonthsRangePickerProps> { props ->
    var startMonth by useState<Moment?>(null)
    var endMonth by useState<Moment?>(null)
    var startView by useState(CalendarPickerView.year)
    var endView by useState(CalendarPickerView.year)

    var bAnchorEl: HTMLButtonElement? by useState(null)
    val isOpen = bAnchorEl != null

    val startLabel = startMonth?.format("MM/YYYY") ?: "Start"
    val endLabel = endMonth?.format("MM/YYYY") ?: "End"

    TextField {
        sx {
            width = 260.px
            marginRight = 30.px
        }
        id = props.id
        value = props.value
        placeholder = "MM/YYYY - MM/YYYY"
        ariaReadOnly = true

        asDynamic().InputProps = jso<InputBaseProps> {
            endAdornment = InputAdornment.create {
                position = InputAdornmentPosition.end
                IconButton {
                    ariaDescribedBy = "${props.id}-popover"
                    onClick = {
                        bAnchorEl = it.currentTarget
                    }
                    CalendarMonthOutlined()
                }
            }
        }
    }
    Popover {
        id = "${props.id}-popover"
        open = isOpen
        anchorEl = bAnchorEl
        onClose = { _, _ ->
            bAnchorEl = null
        }
        Box {
            sx {
                padding = 20.px
            }
            Typography {
                +"SELECT MONTH RANGE"
            }
            Typography {
                sx {
                    marginTop = 10.px
                }
                variant = TypographyVariant.h6
                component = ReactHTML.h6
                +"$startLabel - $endLabel"
            }
            Box {
                sx {
                    display = Display.flex
                }
                @Suppress("UPPER_BOUND_VIOLATED")
                (CalendarPicker<CalendarPickerProps<String>> {
                    view = startView
                    views = arrayOf(CalendarPickerView.year, CalendarPickerView.month)
                    date = startMonth
                    disableFuture = true
                    onViewChange = {
                        startView = it
                    }
                    onYearChange = {
                        startView = CalendarPickerView.month
                    }
                    onChange = { date, _ ->
                        startMonth = moment(date)
                    }
                    maxDate = endMonth?.format()
                })
                @Suppress("UPPER_BOUND_VIOLATED")
                (CalendarPicker<CalendarPickerProps<String>> {
                    view = endView
                    views = arrayOf(CalendarPickerView.year, CalendarPickerView.month)
                    date = endMonth
                    disableFuture = true
                    onViewChange = {
                        endView = it
                    }
                    onYearChange = {
                        endView = CalendarPickerView.month
                    }
                    onChange = { date, _ ->
                        endMonth = moment(date)
                    }
                    minDate = startMonth?.format()
                })
            }
            Box {
                sx {
                    display = Display.flex
                    justifyContent = JustifyContent.flexEnd
                }
                Button {
                    sx {
                        marginTop = 10.px
                    }
                    variant = ButtonVariant.contained
                    disabled = startMonth == null || endMonth == null
                    onClick = {
                        startMonth?.let { it1 -> endMonth?.let { it2 -> props.submit(it1.format(), it2.format()) } }
                        bAnchorEl = null
                    }
                    +"Done"
                }
            }
        }
    }
}