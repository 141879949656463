package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class DisclosuresOnMaterialTopicsState(
    var section31A1: String? = null,
    var section31A2: String? = null,
    var section31A3: String? = null,
    var section31A4: String? = null,
    var section31A5: String? = null,
    var section31A6: String? = null,
    var section31A7: String? = null,
    var section31A8: String? = null,
    var section31A9: String? = null,
    var section31A10: String? = null,
    var section31A11: String? = null,
    var section31A12: String? = null,

    var section32M1: String? = null,
    var section32M2: String? = null,
    var section32M3: String? = null,

    var changeInMaterialTopicsList: MutableList<ChangeInMaterialTopic>? = mutableListOf(),
    var selectedMaterialTopics: MutableList<MaterialTopicManagement>? = mutableListOf(),

    )

@Serializable
data class ChangeInMaterialTopic(
    var section32C1: String? = null
) : DynamicTableItemKey()

@Serializable
data class MaterialTopicManagement(
    var materialTopicName: String? = null,
    var section33A1: String? = null,
    var section33A2: String? = null,
    var section33A3: String? = null,
    var section33A4: String? = null,
    var section33A5: String? = null,
    var section33A6: String? = null
) : DynamicTableItemKey()
