package com.ecosave.watch.portal.components.energymanagement

import com.ecosave.watch.portal.components.common.MonthsRangePicker
import com.ecosave.watch.portal.models.energymanagement.BaselineSelectionStatus
import moment.moment
import mui.material.Box
import mui.material.Button
import mui.material.ButtonColor
import mui.material.ButtonVariant
import mui.material.Switch
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.useEffect
import react.useState
import web.cssom.AlignItems
import web.cssom.Display
import web.cssom.px

external interface RangeControllerProps : Props {
    var checked: Boolean
    var compare: (params: String) -> Unit
    var reset: () -> Unit
    var onChecked: (value: Boolean) -> Unit
    var baselineStatus: BaselineSelectionStatus
}

val RangeController = FC<RangeControllerProps> { props ->
    var startDate1 by useState<String?>(null)
    var endDate1 by useState<String?>(null)
    var startDate2 by useState<String?>(null)
    var endDate2 by useState<String?>(null)
    var diff by useState<Int?>(null)

    useEffect(startDate1, endDate1) {
        if (startDate1 != null && endDate1 != null) {
            diff = moment(startDate1).diff(moment(endDate1), "months").toInt()
        }
    }

    useEffect(diff) {
        if (startDate2 != null) {
            endDate2 = moment(startDate2).subtract(diff, "months").format()
        }
    }

    val range1TextValue = if (startDate1 != null && endDate1 != null) {
        "${moment(startDate1).format("MM/YYYY")} - ${moment(endDate1).format("MM/YYYY")}"
    } else {
        ""
    }

    val range2TextValue = if (startDate2 != null && endDate2 != null) {
        "${moment(startDate2).format("MM/YYYY")} - ${moment(endDate2).format("MM/YYYY")}"
    } else {
        ""
    }

    val isDisabled = startDate1 == null || endDate1 == null || (!props.checked && (startDate2 == null || endDate2 == null))

    Box {
        sx {
            marginTop = 30.px
        }
        if (props.baselineStatus == BaselineSelectionStatus.SELECTED) {
            Box {
                Typography {
                    variant = TypographyVariant.h6
                    component = ReactHTML.h6
                    +"Compare with:"
                }
            }
            Box {
                sx {
                    display = Display.flex
                    alignItems = AlignItems.center
                }
                Typography {
                    +"Custom"
                }
                Switch {
                    checked = props.checked
                    onChange = { _, checked ->
                        props.onChecked(checked)
                    }
                }
                Typography {
                    +"Baseline"
                }
            }
        }
        Box {
            sx {
                display = Display.flex
            }
            Box {
                sx {
                    display = Display.flex
                }
                MonthsRangePicker {
                    id = "first-range"
                    value = range1TextValue
                    submit = { start, end ->
                        startDate1 = start
                        endDate1 = end
                    }
                }
                SecondDuration {
                    id = "second-range"
                    value = range2TextValue
                    start = startDate2
                    duration = if (props.checked) {
                        null
                    } else {
                        diff
                    }
                    submit = { start, end ->
                        startDate2 = start
                        endDate2 = end
                    }
                }
            }
            Button {
                variant = ButtonVariant.outlined
                disabled = isDisabled
                onClick = {
                    val startDateRange1 = moment(startDate1).startOf("month").format("YYYY-MM-DD")
                    val endDateRange1 = moment(endDate1).endOf("month").format("YYYY-MM-DD")
                    var params = "&startDateRange1=${startDateRange1}&endDateRange1=${endDateRange1}"
                    if (!props.checked && startDate2 != null && endDate2 != null) {
                        val startDateRange2 = moment(startDate2).startOf("month").format("YYYY-MM-DD")
                        val endDateRange2 = moment(endDate2).endOf("month").format("YYYY-MM-DD")
                        params += "&startDateRange2=${startDateRange2}&endDateRange2=${endDateRange2}"
                    }
                    props.compare(params)
                }
                +"Compare"
            }
            Button {
                variant = ButtonVariant.outlined
                color = ButtonColor.warning
                sx {
                    marginLeft = 20.px
                }
                onClick = {
                    startDate1 = null
                    endDate1 = null
                    startDate2 = null
                    endDate2 = null
                    props.reset()
                }
                +"Reset"
            }
        }
    }
}