package com.ecosave.watch.portal.components.esg.generaldisclosures.organizationanditsreportingpractices

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange


val Disclosure_2_2 = FC<EsgSubFormComponentProps> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.theOrgAndItsRepPractices?.let { state ->

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_2_2
        }

        Box {
            label {
                +"List all its entities included in sustainability reporting."
            }
            ESGTextAreaFieldWrapper {
                name = state::section22Field1.name
                value = state.section22Field1
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"If the organization has audited consolidated financial statements or financial information filed on public record, specify the differences between the list of entities included in its financial reporting and the list included in its sustainability reporting."
            }
            ESGTextAreaFieldWrapper {
                name = state::section22Field2.name
                value = state.section22Field2
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"Does the organization consist of multiple entities? If yes, explain the approach for consolidating information."
            }
            ESGTextAreaFieldWrapper {
                name = state::section22Field3.name
                value = state.section22Field3
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            className = ESGCommonStyles.LEFT_INDENT.cssClass
            label {
                +"Does the approach involve adjustments to information for minority interests?"
            }
            ESGTextAreaFieldWrapper {
                name = state::section22Field4.name
                value = state.section22Field4
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            className = ESGCommonStyles.LEFT_INDENT.cssClass
            label {
                +"How does the approach consider mergers, acquisitions, and disposal of entities or parts of entities?"
            }
            ESGTextAreaFieldWrapper {
                name = state::section22Field5.name
                value = state.section22Field5
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            className = ESGCommonStyles.LEFT_INDENT.cssClass
            label {
                +"Does the approach differ across disclosures in this Standard and across material topics?"
            }
            ESGTextAreaFieldWrapper {
                name = state::section22Field6.name
                value = state.section22Field6
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}