package com.ecosave.watch.portal.pages

import com.ecosave.watch.portal.styles.CommonStyles
import com.ecosave.watch.portal.components.resourcecenter.ModuleCard
import com.ecosave.watch.portal.helpers.common.PageTitles
import com.ecosave.watch.portal.helpers.common.lg
import com.ecosave.watch.portal.helpers.common.md
import com.ecosave.watch.portal.helpers.common.sm
import com.ecosave.watch.portal.helpers.common.xs
import com.ecosave.watch.portal.helpers.resourcecenter.ResourceCenterConstants.modulesList
import com.ecosave.watch.portal.useGlobalState
import mui.material.Box
import mui.material.Chip
import mui.material.Grid
import mui.material.Typography
import mui.material.TypographyAlign
import mui.material.styles.TypographyVariant
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.useEffectOnce
import web.cssom.px


val ResourceCenterPage = FC<Props> {

    val globalState = useGlobalState()

    useEffectOnce {
        globalState.updatePageTitle(PageTitles.RESOURCE_CENTER.title)
    }

    Box {
        className = CommonStyles.BOX_CENTER_ALIGN_HORIZONTAL.cssClass
        sx {
            maxWidth = 2000.px
        }
        Box {
            sx {
                marginBottom = 30.px
            }
            Typography {
                variant = TypographyVariant.h6
                align = TypographyAlign.center
                +"Everything you need to work with EcosaveWatch"
            }
        }
        Box {
            sx {
                marginBottom = 30.px
            }
            Chip {
                label = ReactNode("Modules")
            }
        }
        Grid {
            container = true
            spacing = responsive(4)
            modulesList.forEach { module ->
                Grid {
                    item = true
                    lg = 3
                    md = 4
                    sm = 6
                    xs = 12
                    ModuleCard {
                        title = module.moduleTitle
                        body = module.moduleBody
                        route = module.moduleRoute
                    }
                }
            }
        }
    }
}
