package com.ecosave.watch.portal.helpers.billing

import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.models.billing.utilitybillstate.UtilityBillState
import com.ecosave.watch.portal.models.billing.UtilityBillValidationState
import react.ReactNode
import react.StateSetter

fun validateDemandMeasured(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.steamBillAdditional.demandMeasured == null) {
        validation.steamBillValidationState.demandMeasuredErrorState = true
        validation.steamBillValidationState.demandMeasuredErrorMessage =
            ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else if (getDigitsCountBeforeDecimal(state.steamBillAdditional.demandMeasured.toString()) > BillingConstants.MAX_VALUE_DIGITS) {
        validation.steamBillValidationState.demandMeasuredErrorState = true
        validation.steamBillValidationState.demandMeasuredErrorMessage =
            ReactNode(BillingConstants.INVALID_VALUE_ERROR_MESSAGE)
    } else {
        validation.steamBillValidationState.demandMeasuredErrorState = false
        validation.steamBillValidationState.demandMeasuredErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun validateDemandBilled(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.steamBillAdditional.demandBilled == null) {
        validation.steamBillValidationState.demandBilledErrorState = true
        validation.steamBillValidationState.demandBilledErrorMessage = ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else if (getDigitsCountBeforeDecimal(
            state.steamBillAdditional.demandBilled.toString()
        ) > BillingConstants.MAX_VALUE_DIGITS
    ) {
        validation.steamBillValidationState.demandBilledErrorState = true
        validation.steamBillValidationState.demandBilledErrorMessage =
            ReactNode(BillingConstants.INVALID_VALUE_ERROR_MESSAGE)
    } else {
        validation.steamBillValidationState.demandBilledErrorState = false
        validation.steamBillValidationState.demandBilledErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun validateTotalHeatingSteamCharges(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.steamBillAdditional.totalHeatingSteamCharges == null) {
        validation.steamBillValidationState.totalHeatingSteamChargesErrorState = true
        validation.steamBillValidationState.totalHeatingSteamChargesErrorMessage =
            ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else if (getDigitsCountBeforeDecimal(state.steamBillAdditional.totalHeatingSteamCharges.toString()) > BillingConstants.MAX_COST_DIGITS) {
        validation.steamBillValidationState.totalHeatingSteamChargesErrorState = true
        validation.steamBillValidationState.totalHeatingSteamChargesErrorMessage =
            ReactNode(BillingConstants.INVALID_COST_ERROR_MESSAGE)
    } else {
        validation.steamBillValidationState.totalHeatingSteamChargesErrorState = false
        validation.steamBillValidationState.totalHeatingSteamChargesErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun validateHeatingDemandCharges(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.steamBillAdditional.heatingDemandCharges == null) {
        validation.steamBillValidationState.heatingDemandChargesErrorState = true
        validation.steamBillValidationState.heatingDemandChargesErrorMessage =
            ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else if (getDigitsCountBeforeDecimal(state.steamBillAdditional.heatingDemandCharges.toString()) > BillingConstants.MAX_COST_DIGITS) {
        validation.steamBillValidationState.heatingDemandChargesErrorState = true
        validation.steamBillValidationState.heatingDemandChargesErrorMessage =
            ReactNode(BillingConstants.INVALID_COST_ERROR_MESSAGE)
    } else {
        validation.steamBillValidationState.heatingDemandChargesErrorState = false
        validation.steamBillValidationState.heatingDemandChargesErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun validateCoolingSteamUsage(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.steamBillAdditional.coolingSteamUsage == null) {
        validation.steamBillValidationState.coolingSteamUsageErrorState = true
        validation.steamBillValidationState.coolingSteamUsageErrorMessage =
            ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else if (getDigitsCountBeforeDecimal(state.steamBillAdditional.coolingSteamUsage.toString()) > BillingConstants.MAX_VALUE_DIGITS) {
        validation.steamBillValidationState.coolingSteamUsageErrorState = true
        validation.steamBillValidationState.coolingSteamUsageErrorMessage =
            ReactNode(BillingConstants.INVALID_VALUE_ERROR_MESSAGE)
    } else {
        validation.steamBillValidationState.coolingSteamUsageErrorState = false
        validation.steamBillValidationState.coolingSteamUsageErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun validateHeatingSteamUsage(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.steamBillAdditional.heatingSteamUsage == null) {
        validation.steamBillValidationState.heatingSteamUsageErrorState = true
        validation.steamBillValidationState.heatingSteamUsageErrorMessage =
            ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else if (getDigitsCountBeforeDecimal(state.steamBillAdditional.heatingSteamUsage.toString()) > BillingConstants.MAX_VALUE_DIGITS) {
        validation.steamBillValidationState.heatingSteamUsageErrorState = true
        validation.steamBillValidationState.heatingSteamUsageErrorMessage =
            ReactNode(BillingConstants.INVALID_VALUE_ERROR_MESSAGE)
    } else {
        validation.steamBillValidationState.heatingSteamUsageErrorState = false
        validation.steamBillValidationState.heatingSteamUsageErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun validateHeatingUsageCharges(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.steamBillAdditional.heatingUsageCharges == null) {
        validation.steamBillValidationState.heatingUsageChargesErrorState = true
        validation.steamBillValidationState.heatingUsageChargesErrorMessage =
            ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else if (getDigitsCountBeforeDecimal(state.steamBillAdditional.heatingUsageCharges.toString()) > BillingConstants.MAX_COST_DIGITS) {
        validation.steamBillValidationState.heatingUsageChargesErrorState = true
        validation.steamBillValidationState.heatingUsageChargesErrorMessage =
            ReactNode(BillingConstants.INVALID_COST_ERROR_MESSAGE)
    } else {
        validation.steamBillValidationState.heatingUsageChargesErrorState = false
        validation.steamBillValidationState.heatingUsageChargesErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun validateTotalCoolingSteamCharges(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.steamBillAdditional.totalCoolingSteamCharges == null) {
        validation.steamBillValidationState.totalCoolingSteamChargesErrorState = true
        validation.steamBillValidationState.totalCoolingSteamChargesErrorMessage =
            ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else if (getDigitsCountBeforeDecimal(state.steamBillAdditional.totalCoolingSteamCharges.toString()) > BillingConstants.MAX_COST_DIGITS) {
        validation.steamBillValidationState.totalCoolingSteamChargesErrorState = true
        validation.steamBillValidationState.totalCoolingSteamChargesErrorMessage =
            ReactNode(BillingConstants.INVALID_COST_ERROR_MESSAGE)
    } else {
        validation.steamBillValidationState.totalCoolingSteamChargesErrorState = false
        validation.steamBillValidationState.totalCoolingSteamChargesErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun isBillingSteamFormValid(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>,
    coolingSteamUsed: Boolean,
    heatingSteamUsed: Boolean
): Boolean {

    if (coolingSteamUsed && heatingSteamUsed) {
        validateCoolingSteamFields(state, validation, setValidation)
        validateHeatingSteamFields(state, validation, setValidation)
    } else if (coolingSteamUsed) {
        validateCoolingSteamFields(state, validation, setValidation)
    } else {
        validateHeatingSteamFields(state, validation, setValidation)
    }

    return isSteamFormFieldsValid(coolingSteamUsed, heatingSteamUsed, validation)

}

fun validateCoolingSteamFields(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    validateBillStartDate(state, validation, setValidation)
    validateBillEndDate(state, validation, setValidation)
    validateBillEstimated(state, validation, setValidation)
    validateBillAdjusted(state, validation, setValidation)
    validateCoolingSteamUsage(state, validation, setValidation)
    validateTotalCoolingSteamCharges(state, validation, setValidation)
}

fun validateHeatingSteamFields(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    validateBillStartDate(state, validation, setValidation)
    validateBillEndDate(state, validation, setValidation)
    validateBillEstimated(state, validation, setValidation)
    validateBillAdjusted(state, validation, setValidation)
    validateDemandMeasured(state, validation, setValidation)
    validateDemandBilled(state, validation, setValidation)
    validateHeatingSteamUsage(state, validation, setValidation)
    validateTotalHeatingSteamCharges(state, validation, setValidation)
    validateHeatingUsageCharges(state, validation, setValidation)
    validateHeatingDemandCharges(state, validation, setValidation)
}

fun isSteamFormFieldsValid(
    coolingSteamUsed: Boolean,
    heatingSteamUsed: Boolean,
    validation: UtilityBillValidationState
): Boolean {

    val commonFieldsValidation = !(validation.billStartDateErrorState || validation.billEndDateErrorState ||
            validation.billAdjustedErrorState || validation.billEstimatedErrorState)

    val heatingSteamFieldsValidation = !(
            validation.steamBillValidationState.demandMeasuredErrorState ||
                    validation.steamBillValidationState.demandBilledErrorState || validation.steamBillValidationState.totalHeatingSteamChargesErrorState ||
                    validation.steamBillValidationState.heatingDemandChargesErrorState || validation.steamBillValidationState.heatingSteamUsageErrorState ||
                    validation.steamBillValidationState.heatingUsageChargesErrorState)

    val coolingSteamFieldsValidation =
        !(validation.steamBillValidationState.coolingSteamUsageErrorState || validation.steamBillValidationState.totalCoolingSteamChargesErrorState)


    return if (coolingSteamUsed && heatingSteamUsed) {
        commonFieldsValidation && heatingSteamFieldsValidation && coolingSteamFieldsValidation
    } else if (coolingSteamUsed) {
        commonFieldsValidation && coolingSteamFieldsValidation
    } else {
        commonFieldsValidation && heatingSteamFieldsValidation
    }
}