package com.ecosave.watch.portal.helpers.billing

import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.models.billing.utilitybillstate.UtilityBillState
import com.ecosave.watch.portal.models.billing.UtilityBillValidationState
import react.ReactNode
import react.StateSetter

fun validateTotalPeakBilledDemand(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.electricAdditional.totalPeakBilledDemand == null) {
        validation.electricBillValidationState.totalPeakBilledDemandErrorState = true
        validation.electricBillValidationState.totalPeakBilledDemandErrorMessage =
            ReactNode(Constants.REQUIRED_FIELD_ERROR_MESSAGE)
    } else if (state.electricAdditional.totalPeakBilledDemand!!.compareTo(BillingConstants.MAX_VALUE) > 0) {
        validation.electricBillValidationState.totalPeakBilledDemandErrorState = true
        validation.electricBillValidationState.totalPeakBilledDemandErrorMessage =
            ReactNode(BillingConstants.INVALID_VALUE_ERROR_MESSAGE)
    } else {
        validation.electricBillValidationState.totalPeakBilledDemandErrorState = false
        validation.electricBillValidationState.totalPeakBilledDemandErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun validatePeakDemandCharges(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.electricAdditional.peakDemandCharges == null) {
        validation.electricBillValidationState.peakDemandChargesErrorState = false
        validation.electricBillValidationState.peakDemandChargesErrorMessage = null
    } else if (state.electricAdditional.peakDemandCharges!!.compareTo(BillingConstants.MAX_COST) > 0) {
        validation.electricBillValidationState.peakDemandChargesErrorState = true
        validation.electricBillValidationState.peakDemandChargesErrorMessage =
            ReactNode(BillingConstants.INVALID_COST_ERROR_MESSAGE)
    } else {
        validation.electricBillValidationState.peakDemandChargesErrorState = false
        validation.electricBillValidationState.peakDemandChargesErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun validateOnPeakUsage(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.electricAdditional.onPeakUsage == null) {
        validation.electricBillValidationState.onPeakUsageErrorState = false
        validation.electricBillValidationState.onPeakUsageErrorMessage = null
    } else if (state.electricAdditional.onPeakUsage!!.compareTo(BillingConstants.MAX_VALUE) > 0) {
        validation.electricBillValidationState.onPeakUsageErrorState = true
        validation.electricBillValidationState.onPeakUsageErrorMessage =
            ReactNode(BillingConstants.INVALID_VALUE_ERROR_MESSAGE)
    } else {
        validation.electricBillValidationState.onPeakUsageErrorState = false
        validation.electricBillValidationState.onPeakUsageErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun validateOnPeakUsageCharges(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.electricAdditional.onPeakUsageCharges == null) {
        validation.electricBillValidationState.onPeakUsageChargesErrorState = false
        validation.electricBillValidationState.onPeakUsageChargesErrorMessage = null
    } else if (state.electricAdditional.onPeakUsageCharges!!.compareTo(BillingConstants.MAX_COST) > 0) {
        validation.electricBillValidationState.onPeakUsageChargesErrorState = true
        validation.electricBillValidationState.onPeakUsageChargesErrorMessage =
            ReactNode(BillingConstants.INVALID_COST_ERROR_MESSAGE)
    } else {
        validation.electricBillValidationState.onPeakUsageChargesErrorState = false
        validation.electricBillValidationState.onPeakUsageChargesErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun validateOffPeakUsage(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.electricAdditional.offPeakUsage == null) {
        validation.electricBillValidationState.offPeakUsageErrorState = false
        validation.electricBillValidationState.offPeakUsageErrorMessage = null
    } else if (state.electricAdditional.offPeakUsage!!.compareTo(BillingConstants.MAX_VALUE) > 0) {
        validation.electricBillValidationState.offPeakUsageErrorState = true
        validation.electricBillValidationState.offPeakUsageErrorMessage =
            ReactNode(BillingConstants.INVALID_VALUE_ERROR_MESSAGE)
    } else {
        validation.electricBillValidationState.offPeakUsageErrorState = false
        validation.electricBillValidationState.offPeakUsageErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun validateOffPeakUsageCharges(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.electricAdditional.offPeakUsageCharges == null) {
        validation.electricBillValidationState.offPeakUsageChargesErrorState = false
        validation.electricBillValidationState.offPeakUsageChargesErrorMessage = null
    } else if (state.electricAdditional.offPeakUsageCharges!!.compareTo(BillingConstants.MAX_COST) > 0) {
        validation.electricBillValidationState.offPeakUsageChargesErrorState = true
        validation.electricBillValidationState.offPeakUsageChargesErrorMessage =
            ReactNode(BillingConstants.INVALID_COST_ERROR_MESSAGE)
    } else {
        validation.electricBillValidationState.offPeakUsageChargesErrorState = false
        validation.electricBillValidationState.offPeakUsageChargesErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun validateOnPeakDemand(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.electricAdditional.onPeakDemand == null) {
        validation.electricBillValidationState.onPeakDemandErrorState = false
        validation.electricBillValidationState.onPeakDemandErrorMessage = null
    } else if (state.electricAdditional.onPeakDemand!!.compareTo(BillingConstants.MAX_VALUE) > 0) {
        validation.electricBillValidationState.onPeakDemandErrorState = true
        validation.electricBillValidationState.onPeakDemandErrorMessage =
            ReactNode(BillingConstants.INVALID_VALUE_ERROR_MESSAGE)
    } else {
        validation.electricBillValidationState.onPeakDemandErrorState = false
        validation.electricBillValidationState.onPeakDemandErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun validateOffPeakDemand(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.electricAdditional.offPeakDemand == null) {
        validation.electricBillValidationState.offPeakDemandErrorState = false
        validation.electricBillValidationState.offPeakDemandErrorMessage = null
    } else if (state.electricAdditional.offPeakDemand!!.compareTo(BillingConstants.MAX_VALUE) > 0) {
        validation.electricBillValidationState.offPeakDemandErrorState = true
        validation.electricBillValidationState.offPeakDemandErrorMessage =
            ReactNode(BillingConstants.INVALID_VALUE_ERROR_MESSAGE)
    } else {
        validation.electricBillValidationState.offPeakDemandErrorState = false
        validation.electricBillValidationState.offPeakDemandErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun validateTotalDeliveryDistributionElectric(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    if (state.totalDeliveryDistribution == null) {
        validation.electricBillValidationState.totalDeliveryDistributionErrorState = false
        validation.electricBillValidationState.totalDeliveryDistributionErrorMessage = null
    } else if (state.totalDeliveryDistribution!!.compareTo(BillingConstants.MAX_VALUE) > 0) {
        validation.electricBillValidationState.totalDeliveryDistributionErrorState = true
        validation.electricBillValidationState.totalDeliveryDistributionErrorMessage =
            ReactNode(BillingConstants.INVALID_VALUE_ERROR_MESSAGE)
    } else {
        validation.electricBillValidationState.totalDeliveryDistributionErrorState = false
        validation.electricBillValidationState.totalDeliveryDistributionErrorMessage = null
    }

    updateUtilityBillValidationState(validation, setValidation)
}

fun updateUtilityBillValidationState(
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    setValidation(validation.copy())
}

fun isBillingElectricFormValid(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
): Boolean {
    validateBillingElectricForm(state, validation, setValidation)
    validateBillingCommonFields(state, validation, setValidation, UtilityType.ELECTRIC)
    return electricFormFieldsValid(validation) && isBillingCommonFieldsValid(
        validation,
        UtilityType.ELECTRIC
    )
}

fun validateBillingElectricForm(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>
) {
    validateTotalPeakBilledDemand(state, validation, setValidation)
    validatePeakDemandCharges(state, validation, setValidation)
}

fun electricFormFieldsValid(validation: UtilityBillValidationState): Boolean {
    return !(validation.electricBillValidationState.totalPeakBilledDemandErrorState ||
            validation.electricBillValidationState.peakDemandChargesErrorState ||
            validation.electricBillValidationState.onPeakUsageErrorState ||
            validation.electricBillValidationState.onPeakUsageChargesErrorState ||
            validation.electricBillValidationState.offPeakUsageErrorState ||
            validation.electricBillValidationState.offPeakUsageChargesErrorState ||
            validation.electricBillValidationState.onPeakDemandErrorState ||
            validation.electricBillValidationState.offPeakDemandErrorState ||
            validation.electricBillValidationState.totalDeliveryDistributionErrorState
            )
}