package com.ecosave.watch.portal.components.common

import react.FC
import react.PropsWithChildren
import react.ReactNode

val ApiLazyRender = FC<ApiLazyRenderProps> {
    if (it.dataFetched) + it.children
    else Loading {}
}

external interface ApiLazyRenderProps: PropsWithChildren {
    var dataFetched: Boolean
    override var children: ReactNode?
}