package com.ecosave.watch.portal.helpers.resourcecenter

enum class ModulePath(val route: String) {
    INITIAL_ONBOARDING("/resource-center/initial-onboarding"),
    UTILITY_INFORMATION("/resource-center/utility-information"),
    ENERGY_MANAGEMENT("/resource-center/energy-management"),
    ASSET_MANAGEMENT("/resource-center/asset-management"),
    CONTROL_CENTER("/resource-center/control-center"),
    FACILITY_MANAGEMENT("/resource-center/facility-management"),
    USER_MANAGEMENT("/resource-center/user-management"),
    ESG_REPORTING("/resource-center/esg-reporting"),
    FACILITY_GROUP_MANAGEMENT("/resource-center/facility-groups-management"),
    WORK_ORDERS("/resource-center/work-orders"),
}

enum class ModuleName(val title: String) {
    INITIAL_ONBOARDING("Initial Onboarding"),
    UTILITY_INFORMATION("Utility Information"),
    ENERGY_MANAGEMENT("Energy Management"),
    ASSET_MANAGEMENT("Asset Management"),
    CONTROL_CENTER("Control Center"),
    FACILITY_MANAGEMENT("Facility Management"),
    USER_MANAGEMENT("User Management"),
    ESG_REPORTING("ESG Reporting"),
    FACILITY_GROUP_MANAGEMENT("Facility Group Management"),
    TITLE("Title"),
    WORK_ORDERS("Work Orders"),
}
