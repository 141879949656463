package com.ecosave.watch.portal.components.common

import com.ecosave.watch.portal.helpers.Colors
import com.ecosave.watch.portal.styles.SignInPageStyles
import emotion.react.css
import react.FC
import react.Props
import react.router.dom.Link
import web.cssom.Color
import web.cssom.TextAlign
import web.window.WindowTarget

external interface LinkWrapperProps : Props {
    var url: String
    var text: String
    var openInNewTab: Boolean
}

val LinkWrapper = FC<LinkWrapperProps> {
    Link {
        to = it.url
        className = SignInPageStyles.LINK.cssClass
        css {
            color = Color(Colors.DARK_BLUE)
            textAlign = TextAlign.center
        }
        if (it.openInNewTab) {
            target = WindowTarget._blank
        }
        +it.text
    }
}