package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class MarketPresenceState(
    var section2021Field2: String? = null,
    var section2021Field3: String? = null,

    var section2022A1: Double? = null,
    var section2022A2: String? = null,
    var section2022A3: String? = null,
    var section2022A4: String? = null,

    var section2021List: MutableList<Section2021> = mutableListOf(),
    var section2021_2List: MutableList<Section2021_2> = mutableListOf(),

    var section3_3_202A1: String? = null,
    var section3_3_202A2: String? = null,
    var section3_3_202A3: String? = null,
    var section3_3_202A4: String? = null,
    var section3_3_202A5: String? = null,
    var section3_3_202A6: String? = null
)

@Serializable
data class Section2021(
    var section2021A1: String? = null,
    var section2021A2: Double? = null,
    var section2021A3: Double? = null,
    var section2021A4: Double? = null,
    var section2021A5: Double? = null,
    var section2021A6: String? = null,
    var section2021A7: String? = null,
)

@Serializable
data class Section2021_2(
    var section2021_2A1: String? = null,
    var section2021_2A2: Double? = null,
    var section2021_2A3: Double? = null,
    var section2021_2A4: Double? = null,
    var section2021_2A5: Double? = null,
    var section2021_2A6: String? = null,
    var section2021_2A7: String? = null,
)