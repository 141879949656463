package com.ecosave.watch.portal.components.esg

import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.components.common.ConfirmationDialog
import com.ecosave.watch.portal.components.common.DialogSpinner
import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import com.ecosave.watch.portal.helpers.esg.TOCSectionsEnum
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.models.esg.EnrichedTableOfContent
import com.ecosave.watch.portal.models.esg.EsgReportState
import com.ecosave.watch.portal.models.esg.ItemStatusTracker
import com.ecosave.watch.portal.services.esg.omitOrIncludeDisclosure
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import kotlinx.coroutines.launch
import mui.icons.material.Edit
import mui.material.FormControlLabel
import mui.material.FormGroup
import mui.material.IconButton
import mui.material.Switch
import mui.material.Tooltip
import mui.material.TooltipPlacement
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.StateSetter
import react.create
import react.dom.html.ReactHTML.div
import react.useState
import web.cssom.Visibility

external interface TableOfContentsDisclosureComponentProps : Props {
    var reportState: EsgReportState
    var setReportState: StateSetter<EsgReportState>
    var itemStatusTracker: ItemStatusTracker
    var enrichedTableOfContent: EnrichedTableOfContent
    var tocClickedSection: (TOCSectionsEnum) -> Unit
}

val TableOfContentsDisclosureComponent = FC<TableOfContentsDisclosureComponentProps> { props ->

    val (showDisclosureOmissionDialog, setShowDisclosureOmissionDialog) = useState(false)
    var showDisclosureInclusionConfirmationDialog by useState(false)
    var isLoading by useState(false)
    val (notificationState, setNotificationState) = useState(NotificationState())

    FormGroup {
        row = true

        Tooltip {
            title = if (props.itemStatusTracker.omittedDetail == null) ReactNode("Omit Disclosure") else ReactNode("Include Disclosure")
            placement = TooltipPlacement.left
            Switch {
                sx {
                    visibility =
                        if (ReportConstants.mandatoryDisclosures.contains(props.itemStatusTracker.disclosure)) Visibility.hidden else Visibility.visible
                }
                checked = props.itemStatusTracker.omittedDetail == null
                onChange = { _, value ->
                    if (value) {
                        showDisclosureInclusionConfirmationDialog = true
                    } else {
                        setShowDisclosureOmissionDialog(true)
                    }
                }
            }
        }


        props.itemStatusTracker.omittedDetail?.let {
            Tooltip {
                title = ReactNode("Edit Omitted Details")
                placement = TooltipPlacement.left
                IconButton {
                    onClick = {
                        setShowDisclosureOmissionDialog(true)
                    }
                    Edit()
                }
            }
        }

        FormControlLabel {
            className = ESGCommonStyles.TOC_ICON_SECOND.cssClass
            label = ReactNode(props.itemStatusTracker.disclosure.disclosureTitle)
            control = div.create()
            onClick = {
                props.tocClickedSection(TOCSectionsEnum.valueOf(props.enrichedTableOfContent.mainTitleStatusTracker.esgSection.name))
            }
        }
    }
    DisclosureOmissionComponent {
        this.showDisclosureOmissionDialog = showDisclosureOmissionDialog
        this.setShowDisclosureOmissionDialog = setShowDisclosureOmissionDialog
        reportState = props.reportState
        setReportState = props.setReportState
        itemStatusTracker = props.itemStatusTracker
    }
    ConfirmationDialog {
        open = showDisclosureInclusionConfirmationDialog
        title = "Include Disclosure"
        body = "Are you sure you want to include this disclosure in your report?"
        actionResult = { value ->
            if (value) {
                mainScope.launch {
                    isLoading = true
                    val response =
                        omitOrIncludeDisclosure(null, props.reportState.reportFileName, props.itemStatusTracker.disclosure)
                    if (response != null) {
                        props.setReportState(
                            props.reportState.copy(
                                mainTableOfContents = response.mainTableOfContents,
                                omittedDisclosuresList = response.omittedDisclosuresList
                            )
                        )
                        setNotificationState(
                            notificationState.copy(
                                status = NotificationStatus.SUCCESS,
                                message = "Disclosure has been included.",
                                visible = true
                            )
                        )
                    } else {
                        setNotificationState(
                            notificationState.copy(
                                status = NotificationStatus.ERROR,
                                message = Constants.NOTIFICATION_ERROR_MESSAGE,
                                visible = true
                            )
                        )
                    }
                    isLoading = false
                }

            }
            showDisclosureInclusionConfirmationDialog = false
        }
    }
    DialogSpinner {
        open = isLoading
        message = "Including Disclosure..."
    }
    AlertNotifications {
        open = notificationState.visible
        status = notificationState.status
        message = notificationState.message
        closeNotification = {
            setNotificationState(
                notificationState.copy(
                    visible = false
                )
            )
        }
    }
}