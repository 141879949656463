package com.ecosave.watch.portal.components.esg.marketingandlabeling

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val GRI_417_1 = FC<EsgSubFormComponentProps> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea
    val onChangeDecimalNumber = props.onChangeDecimalNumber
    val onBlurDecimalNumber = props.onBlurDecimalNumber

    props.esgReportState.report?.marketingAndLabeling?.let { state ->
        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_417_1
        }

        Box {
            className = ESGCommonStyles.GRID_WRAPPER.cssClass
            Box {
                label {
                    +"The sourcing of components of the product or service is/not required for product and service information and labeling."
                }
                ESGTextAreaFieldWrapper {
                    name = state::section4171A1.name
                    value = state.section4171A1
                    onChange = { event -> onChangeTextArea(event, null, null) }
                    onBlur = { event -> onBlurTextArea(event, null, null) }
                }
            }

            Box {
                label {
                    +"Content, particularly with regard to substances that might produce an environmental or social impact is/not required for product and service information and labeling."
                }
                ESGTextAreaFieldWrapper {
                    name = state::section4171A2.name
                    value = state.section4171A2
                    onChange = { event -> onChangeTextArea(event, null, null) }
                    onBlur = { event -> onBlurTextArea(event, null, null) }
                }
            }
            Box {
                label {
                    +"Safe use of the product or service is/not required for product and service information and labeling."
                }
                ESGTextAreaFieldWrapper {
                    name = state::section4171A3.name
                    value = state.section4171A3
                    onChange = { event -> onChangeTextArea(event, null, null) }
                    onBlur = { event -> onBlurTextArea(event, null, null) }
                }
            }
            Box {
                label {
                    +"Disposal of the product and environmental or social impacts is/not required for product and service information and labeling."
                }
                ESGTextAreaFieldWrapper {
                    name = state::section4171A4.name
                    value = state.section4171A4
                    onChange = { event -> onChangeTextArea(event, null, null) }
                    onBlur = { event -> onBlurTextArea(event, null, null) }
                }
            }
            Box {
                label {
                    +"Other Information (explain)"
                }
                ESGTextAreaFieldWrapper {
                    name = state::section4171A5.name
                    value = state.section4171A5
                    onChange = { event -> onChangeTextArea(event, null, null) }
                    onBlur = { event -> onBlurTextArea(event, null, null) }
                }
            }
            Box {
                label {
                    +"What is the percentage of significant product or service categories covered by and assessed for compliance with such procedures?"
                }
                ESGNumberFieldWrapper {
                    name = state::section4171A6.name
                    value = state.section4171A6
                    onChange = { event -> onChangeDecimalNumber(event, null, null) }
                    onBlur = { event -> onBlurDecimalNumber(event, null, null) }
                }
            }
        }
    }
}