package com.ecosave.watch.portal.components.esg.customerhealthnsafety

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.*
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.ChildrenBuilder
import react.FC
import react.dom.events.FocusEvent
import react.dom.events.FormEvent
import react.dom.html.ReactHTML.label
import react.dom.onChange
import web.cssom.ClassName
import web.html.HTMLDivElement
import web.html.HTMLElement


val GRI_416_2 = FC<EsgSubFormComponentProps>("416-2") { props ->
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    val onChangeNumber = props.onChangeNumber
    val onBlurNumber = props.onBlurNumber

    props.esgReportState.report?.customerHealthAndSafety?.let { state ->

        DisclosureSubHeading {
            subHeading =
                "416-2 INCIDENTS OF NON-COMPLIANCE CONCERNING THE HEALTH AND SAFETY IMPACTS OF PRODUCTS AND SERVICES"
        }


        Box {
            className = ESGCommonStyles.GRID_WRAPPER.cssClass
            Box {
                className = ClassName("row-wrapper-416 three-column-table")
                Label {
                    label = ""
                }
                TableHeader {
                    header = "Current Year"
                }
                TableHeader {
                    header = "Previous Year"
                }
            }

            Build416RowWrapper(
                mainLabel = "Total number of incidents of non-compliance with regulations and/or voluntary codes" +
                    " concerning the health and safety impacts of products and services",
                currentName = state::section4162A1.name,
                currentValue = state.section4162A1,
                previousName = state::section4162B1.name,
                previousValue = state.section4162B1,
                onChangeNumber = onChangeNumber,
                onBlurNumber =  onBlurNumber
            )
            Build416RowWrapper(
                mainLabel = "Number of incidents of non-compliance with regulations resulting in a fine or penalty",
                currentName = state::section4162A2.name,
                currentValue = state.section4162A2,
                previousName = state::section4162B2.name,
                previousValue = state.section4162B2,
                onChangeNumber = onChangeNumber,
                onBlurNumber =  onBlurNumber
            )
            Build416RowWrapper(
                mainLabel = "Number of incidents of non-compliance with regulations resulting in a warning",
                currentName = state::section4162A3.name,
                currentValue = state.section4162A3,
                previousName = state::section4162B3.name,
                previousValue = state.section4162B3,
                onChangeNumber = onChangeNumber,
                onBlurNumber =  onBlurNumber
            )
            Build416RowWrapper(
                mainLabel = "Number of incidents of non-compliance with voluntary codes",
                currentName = state::section4162A4.name,
                currentValue = state.section4162A4,
                previousName = state::section4162B4.name,
                previousValue = state.section4162B4,
                onChangeNumber = onChangeNumber,
                onBlurNumber =  onBlurNumber            )
        }

        Box {
            label {
                +"If the organization has not identified any non-compliance with regulations and/or voluntary codes, a brief statement of this fact is sufficient."
            }
            ESGTextAreaFieldWrapper {
                name = state::section4162Field1.name
                value = state.section4162Field1
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}

fun ChildrenBuilder.Build416RowWrapper(
    mainLabel: String,
    currentName: String,
    currentValue: Any?,
    previousName: String,
    previousValue: Any?,
    onChangeNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit,
    onBlurNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit
): Unit = Box {
    className = ClassName("row-wrapper-416 three-column-table")
    Label {
        label = mainLabel
    }

    ESGNumberFieldWrapper {
        name = currentName
        value = currentValue
        onChange = { event -> onChangeNumber(event, null, null) }
        onBlur = { event -> onBlurNumber(event, null, null) }
    }
    ESGNumberFieldWrapper {
        name = previousName
        value = previousValue
        onChange = { event -> onChangeNumber(event, null, null) }
        onBlur = { event -> onBlurNumber(event, null, null) }
    }

}