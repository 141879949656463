package com.ecosave.watch.portal.components.facilitygroupmanagement

import com.ecosave.watch.portal.components.common.Loading
import com.ecosave.watch.portal.components.common.PaperWrapper
import com.ecosave.watch.portal.helpers.common.xs
import com.ecosave.watch.portal.models.facilitygroupmanagement.FacilityGroup
import mui.icons.material.ExpandLess
import mui.icons.material.ExpandMore
import mui.lab.SingleSelectTreeViewProps
import mui.lab.TreeItem
import mui.lab.TreeView
import mui.material.Grid
import mui.material.Typography
import mui.material.TypographyAlign
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.ChildrenBuilder
import react.FC
import react.Props
import react.ReactNode
import react.StateSetter
import react.create
import web.cssom.FontWeight
import web.cssom.Padding
import web.cssom.pct
import web.cssom.px

external interface GroupsTreeViewComponentProps : Props {
    var group: FacilityGroup
    var selectedGroupIdStateSetterProps: StateSetter<Int>
    var isLoadingProp: Boolean
}

val GroupsTreeViewComponent = FC<GroupsTreeViewComponentProps> { props ->
    PaperWrapper {
        sx {
            height = 100.pct
        }
        Grid {
            container = true
            sx {
                padding = Padding(20.px, 40.px)
            }
            Grid {
                item = true
                xs = 12
                sx {
                    marginBottom = 20.px
                }
                Typography {
                    sx {
                        fontWeight = FontWeight.bold
                    }
                    variant = TypographyVariant.h5
                    align = TypographyAlign.center
                    +"Facility Groups"
                }
            }
            Grid {
                item = true
                xs = 12
                sx {
                    if (props.isLoadingProp) padding = 50.px
                }
                if (props.isLoadingProp) {
                    Loading()
                } else {
                    facilityGroupsTreeView(group = props.group, selectedGroupIdStateSetterProps = props.selectedGroupIdStateSetterProps)
                }
            }
        }
    }
}


fun ChildrenBuilder.facilityGroupsTreeView(
    group: FacilityGroup,
    selectedGroupIdStateSetterProps: StateSetter<Int>,
): Unit = TreeView {
    @Suppress("UNCHECKED_CAST_TO_EXTERNAL_INTERFACE") this as SingleSelectTreeViewProps
    onNodeSelect = { _, nodeId ->
        selectedGroupIdStateSetterProps(nodeId.toInt())
    }
    defaultExpandIcon = ExpandMore.create()
    defaultCollapseIcon = ExpandLess.create()
    defaultExpanded = arrayOf(group.facilityGroupId.toString())
    facilityGroupTreeItem(group)
}

fun ChildrenBuilder.facilityGroupTreeItem(data: FacilityGroup): Unit = TreeItem {
    nodeId = data.facilityGroupId.toString()
    label = ReactNode(data.facilityGroupName)
    data.childGroups?.forEach { child ->
        facilityGroupTreeItem(child)
    }
}
