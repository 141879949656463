package com.ecosave.watch.portal.components.privacypolicy

import com.ecosave.watch.portal.helpers.privacypolicy.getMailToWithSubject
import react.FC
import react.Props
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.ul

val CaliforniaResidentsPrivacyPolicy = FC<Props> {
    HeadingWrapper {
        heading = "For those of you in California, please see our Notice to California Residents:"
    }
    HeadingWrapper {
        heading = "Notice to California Residents"
        subHeading = true
    }
    TypographyWrapper {
        +"The California Consumer Privacy Act of 2018 (Cal. Civ. Code §1798.100 et seq., as amended, “CCPA”, or “CPRA”), gives California residents rights and control over their Personal Information. Ecosave provides this statement to California residents in accordance with requirements under the CCPA/CPRA to make certain disclosures about the collection and processing of their Personal Information. This is Ecosave’s California-specific description of consumers’ privacy rights under the CCPA/CPRA."
    }
    HeadingWrapper {
        heading = "We Do Not Sell Your Personal Information"
        subHeading = true
    }
    TypographyWrapper {
        +"Under the CCPA/CPRA, a business that sells California residents’ Personal Information to others"
    }
    TypographyWrapper {
        ul {
            li {
                +"must give notice to California residents before selling their Personal Information to others; and"
            }
            li {
                +"must provide the right to opt out of the sale of their Personal Information."
            }
        }
    }
    TypographyWrapper {
        +"Ecosave does not sell Personal Information, including Personal Information of anyone under 16 years old, without consent."
    }
    HeadingWrapper {
        heading = "Your Rights Under the CCPA/CPRA"
        subHeading = true
    }
    HighlightedText {
        +"1. Right to know what Personal Information is being collected, for what purposes and with whom it is shared"
    }
    TypographyWrapper {
        +"California residents have the right to request from a business disclosure of the categories and specific pieces of Personal Information it has collected from them in the preceding 12 months, the categories of sources from which such Personal Information is collected, the business or commercial purpose for collecting or selling such Personal Information, and the categories of third parties with whom the business shares Personal Information."
    }
    TypographyWrapper {
        +"If you request that a business disclose categories and specific pieces of Personal Information collected about you, you have the right to receive that information, free of charge, twice a year. The information may be delivered by mail or electronically and, if provided electronically, shall be in a portable and, to the extent technically feasible, readily usable format that allows the California resident to relatively easily transmit this information to another entity."
    }
    HighlightedText {
        +"2. Right to know whether your Personal Information is sold or disclosed for a business purpose and to whom"
    }
    TypographyWrapper {
        +"California residents have the right to request from a business that sells or discloses Personal Information for a business purpose separate lists of the categories of Personal Information collected, sold or disclosed for a business purpose in the preceding 12 months, including the categories of third parties to whom the Personal Information was sold or disclosed for a business purpose."
    }
    HighlightedText {
        +"3. Right to say no to the sale of your Personal Information"
    }
    TypographyWrapper {
        +"As explained above, the CCPA/CPRA requires businesses that sell Personal Information to allow residents the ability to opt out of the selling of their information."
    }
    HighlightedText {
        +"4. Right to non-discrimination of service or price if you exercise your privacy rights"
    }
    TypographyWrapper {
        +"The CCPA/CPRA prohibits businesses from discriminating against a California resident for exercising any of their rights under the CCPA/CPRA, including by:"
    }
    TypographyWrapper {
        ul {
            li {
                +"denying goods or services to the resident;"
            }
            li {
                +"charging different prices or rates for goods or services, including through the use of discounts or other benefits or by imposing penalties;"
            }
            li {
                +"providing a different level or quality of goods or services; or"
            }
            li {
                +"suggesting that the person exercising their rights will receive a different price or rate for goods or services or a different level or quality of goods or services."
            }
        }
    }
    HighlightedText {
        +"5. Right to deletion"
    }
    TypographyWrapper {
        +"California residents have the right to request that a business delete any of their Personal Information that the business collected from them, subject to the exceptions in CCPA §1798.105."
    }
    HeadingWrapper {
        heading = "To Exercise Your CCPA/CPRA Rights"
        subHeading = true
    }
    TypographyWrapper {
        +"Contact us at "
        CustomLink {
            to = "tel: ${TermsOfServiceConstants.ecosavePhoneNumber}"
            text = TermsOfServiceConstants.ecosavePhoneNumber
        }
    }
    TypographyWrapper {
        +"Ecosave will verify your request within seven (7) days and complete the request within 45 days from receipt of the request, as required by law."
    }
    TypographyWrapper {
        +"If you have any questions specifically about CCPA/CPRA, you may reach out to us at "
        CustomLink {
            to = TermsOfServiceConstants.ecosavePrivacyEmail.getMailToWithSubject("Privacy")
            text = TermsOfServiceConstants.ecosavePrivacyEmail
        }
    }
}