package com.ecosave.watch.portal.components.esg.generaldisclosures.governance

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val Disclosure_2_17 = FC<EsgSubFormComponentProps> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.governance?.let { state ->

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_2_17
        }

        Box {
            label {
                +"Report measures taken to advance the collective knowledge, skills, and experience of the highest governance body on sustainable development."
            }
            ESGTextAreaFieldWrapper {
                name = state::section217A1.name
                value = state.section217A1 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}