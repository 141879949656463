package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class StakeholderEngagementState(
    var categoriesOfStakeholdersList: MutableList<CategoriesOfStakeholders> = mutableListOf(),
    var section229Field1: String? = null,

    var section230A1: Long? = null,
    var section230A2: Long? = null,
    var section230A3: Double? = null,
    var section230Field1: String? = null,
)

@Serializable
data class CategoriesOfStakeholders(
    var section229A1: String? = null,
    var section229A2: String? = null,
    var section229A3: String? = null,
) : DynamicTableItemKey()