package com.ecosave.watch.portal.components.esg.materials

import com.ecosave.watch.portal.components.esg.EsgCoreComponentProps
import com.ecosave.watch.portal.components.esg.GenericSection_3_3
import com.ecosave.watch.portal.components.esg.formcontrols.GRIStandardName
import com.ecosave.watch.portal.helpers.common.isValidDecimalNumber
import com.ecosave.watch.portal.helpers.esg.EsgSection
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.omitDisclosureFromUI
import com.ecosave.watch.portal.models.esg.CurrentInputState
import com.ecosave.watch.portal.models.esg.MaterialsState
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import kotlinx.serialization.json.JsonPrimitive
import mui.material.Box
import react.FC
import react.dom.events.FocusEvent
import react.dom.events.FormEvent
import web.html.HTMLDivElement
import web.html.HTMLElement
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement

val MaterialsComponent = FC<EsgCoreComponentProps> { props ->
    val reportState = props.reportState
    val setReportState = props.setReportState

    reportState.report?.materials?.let { state ->
        fun stateSetter(state: MaterialsState) {
            setReportState(
                reportState.copy(
                    report = reportState.report.copy(
                        materials = state
                    )
                )
            )
        }

        val onChangeDecimalNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement

            if (isValidDecimalNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toDoubleOrNull()

                val newState = state.copy()

                newState.let {
                    when (propertyName) {
                        it::section3011B1.name -> it.section3011B1 = propertyValue
                        it::section3011B2.name -> it.section3011B2 = propertyValue
                        it::section3011B3.name -> it.section3011B3 = propertyValue

                        it::section3011C1.name -> it.section3011C1 = propertyValue
                        it::section3011C2.name -> it.section3011C2 = propertyValue
                        it::section3011C3.name -> it.section3011C3 = propertyValue

                        it::section3012Current1.name -> it.section3012Current1 = propertyValue
                        it::section3012Current2.name -> it.section3012Current2 = propertyValue
                        it::section3012Current3.name -> it.section3012Current3 = propertyValue

                        it::section3013Current1.name -> it.section3013Current1 = propertyValue
                        it::section3013Current2.name -> it.section3013Current2 = propertyValue
                        it::section3013Current3.name -> it.section3013Current3 = propertyValue
                    }
                }

                stateSetter(newState)

                props.setInputState(
                    CurrentInputState(
                        parentPath = field,
                        childPath = propertyName,
                        index = changeIndex,
                        value = JsonPrimitive(propertyValue),
                        objectName = EsgSection.MATERIALS.name,
                        reportName = reportState.reportFileName
                    )
                )
            }
        }

        val onBlurDecimalNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidDecimalNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toDoubleOrNull()
                props.onBlurApiCall(
                    propertyName,
                    JsonPrimitive(propertyValue),
                    changeIndex,
                    field,
                    EsgSection.MATERIALS.name
                )
            }
        }

        val onChangeTextArea: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value


            val newState = state.copy()

            newState.let {
                when (propertyName) {
                    it::section3013Field1.name -> it.section3013Field1 = propertyValue

                    it::section3_3_301A1.name -> it.section3_3_301A1 = propertyValue
                    it::section3_3_301A2.name -> it.section3_3_301A2 = propertyValue
                    it::section3_3_301A3.name -> it.section3_3_301A3 = propertyValue
                    it::section3_3_301A4.name -> it.section3_3_301A4 = propertyValue
                    it::section3_3_301A5.name -> it.section3_3_301A5 = propertyValue
                    it::section3_3_301A6.name -> it.section3_3_301A6 = propertyValue
                }
            }

            stateSetter(newState)

            props.setInputState(
                CurrentInputState(
                    parentPath = field,
                    childPath = propertyName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue),
                    objectName = EsgSection.MATERIALS.name,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurTextArea: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value
            props.onBlurApiCall(
                propertyName,
                JsonPrimitive(propertyValue),
                changeIndex,
                field,
                EsgSection.MATERIALS.name
            )
        }

        val onChangeText: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            val propertyName = target.name
            val propertyValue = target.value

            val newState = state.copy()

            newState.let {
                when (propertyName) {
                    it::section3011A1.name -> it.section3011A1 = propertyValue
                    it::section3011A2.name -> it.section3011A2 = propertyValue
                    it::section3011A3.name -> it.section3011A3 = propertyValue
                    it::section3012Unit1.name -> it.section3012Unit1 = propertyValue
                    it::section3012Unit2.name -> it.section3012Unit2 = propertyValue
                    it::section3013Unit1.name -> it.section3013Unit1 = propertyValue
                    it::section3013Unit2.name -> it.section3013Unit2 = propertyValue
                }
            }

            stateSetter(newState)

            props.setInputState(
                CurrentInputState(
                    parentPath = field,
                    childPath = propertyName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue.trim()),
                    objectName = EsgSection.MATERIALS.name,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurText: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            val propertyName = target.name
            val propertyValue = target.value

            props.onBlurApiCall(
                propertyName,
                JsonPrimitive(propertyValue),
                changeIndex,
                field,
                EsgSection.MATERIALS.name
            )
        }

        Box {
            className = ESGCommonStyles.SECTION_MAIN_LAYOUT.cssClass

            GRIStandardName {
                griStandard = EsgSection.MATERIALS
            }

            GenericSection_3_3 {
                this.onBlurTextArea = onBlurTextArea
                this.onChangeTextArea = onChangeTextArea
                this.materialTopicName = EsgSection.MATERIALS.description

                this.section3_3_A1_name = state::section3_3_301A1.name
                this.section3_3_A1_value = state.section3_3_301A1

                this.section3_3_A2_name = state::section3_3_301A2.name
                this.section3_3_A2_value = state.section3_3_301A2

                this.section3_3_A3_name = state::section3_3_301A3.name
                this.section3_3_A3_value = state.section3_3_301A3

                this.section3_3_A4_name = state::section3_3_301A4.name
                this.section3_3_A4_value = state.section3_3_301A4

                this.section3_3_A5_name = state::section3_3_301A5.name
                this.section3_3_A5_value = state.section3_3_301A5

                this.section3_3_A6_name = state::section3_3_301A6.name
                this.section3_3_A6_value = state.section3_3_301A6
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_301_1)) {
                GRI_301_1 {
                    this.esgReportState = reportState
                    this.onChangeText = onChangeText
                    this.onBlurText = onBlurText
                    this.onChangeDecimalNumber = onChangeDecimalNumber
                    this.onBlurDecimalNumber = onBlurDecimalNumber
                }
            }
            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_301_2)) {
                GRI_301_2 {
                    this.esgReportState = reportState
                    this.onChangeText = onChangeText
                    this.onBlurText = onBlurText
                    this.onChangeDecimalNumber = onChangeDecimalNumber
                    this.onBlurDecimalNumber = onBlurDecimalNumber
                }
            }
            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_301_3)) {
                GRI_301_3 {
                    this.esgReportState = reportState
                    this.onChangeText = onChangeText
                    this.onBlurText = onBlurText
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeDecimalNumber = onChangeDecimalNumber
                    this.onBlurDecimalNumber = onBlurDecimalNumber
                }
            }
        }
    }
}