package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class EconomicPerformanceState(
    var section2011A1: String? = null,
    var section2011A2: String? = null,
    var section2011A3: String? = null,
    var section2011A4: String? = null,
    var section2011A5: String? = null,
    var section2011A6: String? = null,
    var section2011A7: String? = null,
    var section2011Field1: String? = null,
    var section2011Field2: String? = null,

    var financialRisksList: MutableList<FinancialRisks> = mutableListOf(),
    var financialOpportunitiesList: MutableList<FinancialOpportunities> = mutableListOf(),
    var section2012Field1: String? = null,

    var section2013A1: Double? = null,
    var section2013A2: String? = null,
    var section2013A3: String? = null,
    var section2013A4: String? = null,
    var section2013A5: String? = null,
    var retirementPlansList: MutableList<RetirementPlans> = mutableListOf(),

    var section2014A1: Double? = null,
    var section2014A2: Double? = null,
    var section2014A3: Double? = null,
    var section2014A4: Double? = null,
    var section2014A5: Double? = null,
    var section2014A6: Double? = null,
    var section2014A7: Double? = null,
    var section2014A8: Double? = null,
    var countriesAndValuesList: MutableList<CountriesAndValues> = mutableListOf(),
    var section2014Part2Field1: String? = null,

    var section3_3_201A1: String? = null,
    var section3_3_201A2: String? = null,
    var section3_3_201A3: String? = null,
    var section3_3_201A4: String? = null,
    var section3_3_201A5: String? = null,
    var section3_3_201A6: String? = null
)

@Serializable
data class FinancialRisks(
    var section2012RiskA1: String? = null,
    var section2012RiskA2: String? = null,
    var section2012RiskA3: String? = null,
    var section2012RiskA4: String? = null,
    var section2012RiskA5: String? = null
) : DynamicTableItemKey()

@Serializable
data class FinancialOpportunities(
    var section2012OpportunityA1: String? = null,
    var section2012OpportunityA2: String? = null,
    var section2012OpportunityA3: String? = null,
    var section2012OpportunityA4: String? = null,
    var section2012OpportunityA5: String? = null
) : DynamicTableItemKey()

@Serializable
data class RetirementPlans(
    var section2013A6: String? = null,
    var section2013A7: Double? = null,
    var section2013A8: Double? = null,
    var section2013A9: String? = null,
    var section2013A10: String? = null,
    var section2013A11: String? = null
) : DynamicTableItemKey()

@Serializable
data class CountriesAndValues(
    var section2014Part2A1: String? = null,
    var section2014Part2A3: Double? = null,
    var section2014Part2A2: Double? = null,
    var section2014Part2A4: Double? = null,
    var section2014Part2A5: Double? = null,
    var section2014Part2A6: Double? = null,
    var section2014Part2A7: Double? = null,
    var section2014Part2A8: Double? = null,
    var section2014Part2A9: Double? = null
) : DynamicTableItemKey()