package com.ecosave.watch.portal.components.esg.economicperformance

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.Label
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import com.ecosave.watch.portal.styles.esg.EconomicPerformanceStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val GRI_201_1 = FC<EsgSubFormComponentProps> { props ->
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.economicPerformance?.let { state ->
        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_201_1
        }
        DisclosureSubHeading {
            subHeading =
                "Direct economic value generated and distributed (EVG&D) on an accruals basis, including the basic components for the organization’s global operations as listed below. If data are presented on a cash basis, report the justification for this decision in addition to reporting the following basic components:"
        }

        Box {
            className = EconomicPerformanceStyles.SECTION_201_1_TABLE.cssClass
            TableHeader {
                header = "Total Organization / Country / Region / Market Level"
            }
            TableHeader {
                header = ""
            }
            Label {
                label = "Direct economic value generated (Revenue)"
            }
            ESGTextAreaFieldWrapper {
                name = state::section2011A1.name
                value = state.section2011A1
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
            Label {
                label = "Operating costs"
            }
            ESGTextAreaFieldWrapper {
                name = state::section2011A2.name
                value = state.section2011A2
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
            Label {
                label = "Employee wages and benefits"
            }
            ESGTextAreaFieldWrapper {
                name = state::section2011A3.name
                value = state.section2011A3
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
            Label {
                label = "Payments to providers of capital"
            }
            ESGTextAreaFieldWrapper {
                name = state::section2011A4.name
                value = state.section2011A4
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
            Label {
                label = "Payments to government by country"
            }
            ESGTextAreaFieldWrapper {
                name = state::section2011A5.name
                value = state.section2011A5
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
            Label {
                label = "Community investments"
            }
            ESGTextAreaFieldWrapper {
                name = state::section2011A6.name
                value = state.section2011A6
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
            Label {
                label = "Economic value retained (Profits/Losses)"
            }
            ESGTextAreaFieldWrapper {
                name = state::section2011A7.name
                value = state.section2011A7
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                className = ESGCommonStyles.LABEL.cssClass
                +"Where significant, report EVG&D separately at country, regional, or market levels, and the criteria used for defining significance."
            }
            ESGTextAreaFieldWrapper {
                name = state::section2011Field1.name
                value = state.section2011Field1
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
        Box {
            label {
                className = ESGCommonStyles.LABEL.cssClass
                +"Provide criteria for defining significance if EVG&D reports are separated into different categories. If applicable, data should come from the organization’s audited financial or profit and loss (P&L) statement."
            }
            ESGTextAreaFieldWrapper {
                name = state::section2011Field2.name
                value = state.section2011Field2
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}