package com.ecosave.watch.portal.components.esg.trainingandeducation

import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.onChange

val GRI_404_1 = FC<EsgSubFormComponentProps> { props ->
    val onChangeText = props.onChangeText
    val onBlurText = props.onBlurText
    val onChangeNumber = props.onChangeNumber
    val onBlurNumber = props.onBlurNumber

    props.esgReportState.report?.trainingAndEducation?.let { state ->
        val breakdownOf4041ListName = state::breakdownOf4041List.name

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_404_1
        }
        DisclosureSubHeading {
            subHeading = "Average hours of training that the organization’s employees have undertaken during the reporting period."
        }
        Box {
            className = ESGCommonStyles.TABLE_WRAPPER.cssClass
            DynamicTableWrapper {
                addRowOrSection = { props.addRowOrSection(breakdownOf4041ListName) }
                items = state.breakdownOf4041List
                action = DynamicTableAction.ADD
                addRowOrSectionBtnText = ReportConstants.ADD_ROW_MESSAGE

                Box {
                    className = ESGCommonStyles.THREE_COLUMN_TABLE.cssClass
                    TableHeader {
                        header = "Gender"
                    }
                    TableHeader {
                        header = "Employee Category"
                    }
                    TableHeader {
                        header = "Average hours of training"
                    }
                }
            }
            state.breakdownOf4041List.forEachIndexed { index, it ->
                DynamicTableWrapper {
                    key = it.key
                    deleteRowOrSection = { props.deleteRowOrSection(breakdownOf4041ListName, index) }
                    items = state.breakdownOf4041List
                    action = DynamicTableAction.DELETE
                    variant = DynamicTableVariant.TABLE
                    Box {
                        className = ESGCommonStyles.THREE_COLUMN_TABLE.cssClass
                        ESGTextFieldWrapper {
                            name = it::section4041Current1.name
                            value = it.section4041Current1
                            onChange = { event -> onChangeText(event, index, breakdownOf4041ListName) }
                            onBlur = { event -> onBlurText(event, index, breakdownOf4041ListName) }
                        }
                        ESGTextFieldWrapper {
                            name = it::section4041Current2.name
                            value = it.section4041Current2
                            onChange = { event -> onChangeText(event, index, breakdownOf4041ListName) }
                            onBlur = { event -> onBlurText(event, index, breakdownOf4041ListName) }
                        }
                        ESGNumberFieldWrapper {
                            name = it::section4041Current3.name
                            value = it.section4041Current3
                            onChange = { event -> onChangeNumber(event, index, breakdownOf4041ListName) }
                            onBlur = { event -> onBlurNumber(event, index, breakdownOf4041ListName) }
                        }
                    }
                }
            }
        }
    }
}